import React, { useContext, useState, useEffect } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import {
  Container,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import FormGroup from "reactstrap/lib/FormGroup";
import Button from "reactstrap/lib/Button";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import CardBody from "reactstrap/lib/CardBody";
import CardText from "reactstrap/lib/CardText";
import ReactCountryFlag from "react-country-flag";
import { Formik, Field, Form } from "formik";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import { Multiselect } from "multiselect-react-dropdown";
import {
  countryService,
  searchService,
  numberOfEmpService,
  industryService,
  profileService,
  spSeenService,
} from "../../../_services";
import "../../../assets/css/tooltip.css";
import { SearchProviderContext } from "../../../_context";
import { SearchListContext } from "../../../_context";
import Modal from "reactstrap/lib/Modal";
import { Link } from "react-router-dom";
import Badge from "reactstrap/lib/Badge";
import { Alert } from "reactstrap";
import { LanguageContext } from "_context";
import Tooltip from "@mui/material/Tooltip";
import { surveyService } from "_services/survey.service";
import { tokenService } from "_services";
import { requestPermissionAndGetToken } from "../../../firebase";

function SearchProviders({ history, match, location }) {
  const { path } = match;
  const fromPartner = location.state;

  const user = profileService.userValue;
  const [loading, setLoading] = useState(true);
  const [industriesAndServices, setIndustriesAndServices] = useState([]);
  const [selectedIndustriesAndServices, setSelectedIndustriesAndServices] =
    useState([]);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [searchModel, setSearchModel] = useContext(SearchProviderContext);
  const [searchList, setSearchList] = useContext(SearchListContext);
  const [searchResults, setSearchResults] = useState(null);
  const [services, setServices] = useState([]);
  const [show, setShow] = useState(
    searchModel.industries == null ? false : true
  );
  const servicesMultiselectRef = React.createRef();
  const countriesMultiselectRef = React.createRef();
  const providerTypeMultiselectRef = React.createRef();
  const nbOfEmployeesMultiselectRef = React.createRef();
  const [filterBy, setFilterBy] = useState(searchModel.filterBy);
  const [filterType, setFilterType] = useState(searchModel.filterType);
  const [showAlert, setShowAlert] = useState(
    sessionStorage.getItem("searchProviderMessage") === "true"
  );
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [countries, setCountries] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [totalCount, setCount] = useState(0);
  const [spTypeSelected, setSpTypeSelected] = useState([]);
  const [pendingSurveys, setPendingSurveys] = useState([]);
  const [showPendingAlert, setShowPendingAlert] = useState(false);
  const [spType] = useState([
    {
      spTypeName: t("signUp.providerType.corporate"),
      displaySpType: t("signUp.providerType.corporate"),
    },
    {
      spTypeName: "Start Up",
      displaySpType: t("signUp.providerType.startup"),
    },
  ]);
  const initialValues = {
    keyword: searchModel.searchKeyWord,
    openForPartnership: searchModel.openForPartnership,
    financing: searchModel.financing,
  };
  const [nbEmp, setNbEmp] = useState(null);
  const [empList, setEmpList] = useState([]);
  const [industries] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState(null);
  const [sortByLabel, setSortByLabel] = useState(
    t("createCompanyProfile.companyName")
  );

  useEffect(() => {
    const checkPermission = async () => {
      if (user != null) {
        if ("Notification" in window) {
          if (Notification.permission == "granted") {
            await requestPermissionAndGetToken();
            const token = localStorage.getItem("token");
            console.log(token);
            const model = {
              UserID: user.profile.userId,
              Token: token,
            };
            await tokenService.AddTokenAsync(model).then((x) => {
              localStorage.removeItem("token");
            });
          }
        }
      }
    };
    checkPermission();
  }, []);

  const onLoadMoreClick = async () => {
    const newModel = {
      userPerforminSearchId: user.profile.userId,
      searchIndex: searchModel.searchIndex + 1,
      openForPartnership:
        searchModel.openForPartnership === "All"
          ? null
          : searchModel.openForPartnership,
      financing: searchModel.financing === "All" ? null : searchModel.financing,
      searchKeyWord: searchModel.searchKeyWord,
      countries: searchModel.countries,
      industries: searchModel.industries,
      typeOfIndustriesAndServices: searchModel.typeOfIndustriesAndServices,
      spType: searchModel.spType,
      NumberOfEmployeesId: searchModel.NumberOfEmployeesId,
      filterType: searchModel.filterType,
      filterBy: searchModel.filterBy,
    };
    setSearchModel(newModel);
    var list = [...searchResults];
    await searchService
      .SearchServiceProvidersAsync(newModel, currentLan)
      .then((x) => {
        setCount(x.totalCount);
        x.requestedServiceProvidersList.forEach(async (provider) => {
          await list.push(provider);
        });

        setSearchResults(list);
        const model = {
          totalCount: x.totalCount,
          list: list,
        };
        setSearchList(model);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  };

  const fetchData = async (lan) => {
    if (searchList && searchList.list.length === 0) {
      const requestModel = {
        userPerforminSearchId: user.profile.userId,
        searchIndex: 0,
        searchKeyWord: searchModel.keyword,
        openForPartnership:
          searchModel.openForPartnership === "All"
            ? null
            : searchModel.openForPartnership,
        financing:
          searchModel.financing === "All" ? null : searchModel.financing,
        countries: countryList,
        industries: selectedIndustries,
        typeOfIndustriesAndServices: selectedIndustriesAndServices,
        spType: spTypeSelected,
        NumberOfEmployeesId: empList,
        filterType: filterType,
        filterBy: filterBy,
      };
      await searchService
        .SearchServiceProvidersAsync(requestModel, currentLan)
        .then((x) => {
          setCount(x.totalCount);
          const model = {
            totalCount: x.totalCount,
            list: x.requestedServiceProvidersList,
          };
          setSearchList(model);
          setSearchResults(x.requestedServiceProvidersList);
        });
    } else {
      setCount(searchList.totalCount);
      setSearchResults(searchList.list);
    }

    await surveyService
      .GetAllPendingSurveysCount(user.profile.userId)
      .then((response) => {
        setPendingSurveys(response);
        setShowPendingAlert(true);
      });

    await countryService
      .GetProfilesCountryByLanguageAsync(lan, true)
      .then((x) => {
        setCountries(x);
      });

    await numberOfEmpService.GetAllNumberOfEmployeesAsync().then((x) => {
      setNbEmp(x);
    });

    await industryService
      .GetProfilesIndustriesServicesByLanguageAsync(lan, true)
      .then((x) => {
        if (x != null) {
          setIndustriesAndServices(x);
          if (show)
            for (var i = 0; i < x.length; i++) {
              var array = [];
              if (
                x[i].typeOfIndustryId ===
                searchModel.industries.typeOfIndustryId
              ) {
                array.push({
                  typeOfIndustryId: x[i].typeOfIndustryId,
                  typeOfIndustryName: x[i].typeOfIndustryName,
                  typeOfServiceId: x[i].typeOfServiceId,
                  typeOfServiceName: x[i].typeOfServiceName,
                });
                setServices(array);
              }
            }
          for (var j = 0; j < x.length; j++) {
            if (
              !industries.some(
                (industry) =>
                  industry.typeOfIndustryId === x[j].typeOfIndustryId
              )
            ) {
              industries.push({
                typeOfIndustryId: x[j].typeOfIndustryId,
                typeOfIndustryName: x[j].typeOfIndustryName,
              });
            }
          }
        }
      });

    setLoading(false);
  };
  React.useEffect(() => {
    fetchData(currentLan);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [currentLan]);

  function onIndustryChange(e) {
    if (selectedIndustriesAndServices.length > 0) {
      servicesMultiselectRef.current.resetSelectedValues();
      setSelectedIndustriesAndServices([]);
    }
    setShow(false);
    if (e === "None") {
      setServices([]);
      setSelectedIndustries(null);
    } else {
      setSelectedIndustries({
        typeOfIndustryId: e,
      });
      setShow(true);
      var array = [];
      for (var i = 0; i < industriesAndServices.length; i++) {
        if (industriesAndServices[i].typeOfIndustryId === e) {
          array.push({
            typeOfIndustryId: industriesAndServices[i].typeOfIndustryId,
            typeOfIndustryName: industriesAndServices[i].typeOfIndustryName,
            typeOfServiceId: industriesAndServices[i].typeOfServiceId,
            typeOfServiceName: industriesAndServices[i].typeOfServiceName,
          });
          setServices(array);
        }
      }
    }
  }

  function onChange(e) {
    setCountryList(e);
  }

  function onSpTypeChange(e) {
    setSpTypeSelected(e);
  }

  function onNbEmpChange(e) {
    setEmpList(e);
  }

  function resetFilter() {
    setServices([]);
    setSelectedIndustries(null);
    setShow(false);
    if (selectedIndustriesAndServices.length > 0) {
      servicesMultiselectRef.current.resetSelectedValues();
      setSelectedIndustriesAndServices([]);
    }
    if (countryList.length > 0) {
      countriesMultiselectRef.current.resetSelectedValues();
      setCountryList([]);
    }
    if (spTypeSelected.length > 0) {
      providerTypeMultiselectRef.current.resetSelectedValues();
      setSpTypeSelected([]);
    }
    if (empList.length > 0) {
      nbOfEmployeesMultiselectRef.current.resetSelectedValues();
      setEmpList([]);
    }
  }

  async function sort() {
    await searchService
      .SearchServiceProvidersAsync(searchModel, currentLan)
      .then((x) => {
        setCount(x.totalCount);
        const model = {
          totalCount: x.totalCount,
          list: x.requestedServiceProvidersList,
        };
        setSearchList(model);
        setSearchResults(x.requestedServiceProvidersList);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    const model = {
      userPerforminSearchId: user.profile.userId,
      searchIndex: 0,
      searchKeyWord: fields.keyword,
      openForPartnership: fields.openForPartnership,
      financing: fields.financing,
      countries: countryList,
      industries: selectedIndustries,
      typeOfIndustriesAndServices: selectedIndustriesAndServices,
      spType: spTypeSelected,
      NumberOfEmployeesId: empList,
      filterType: filterType === "None" ? null : filterType,
      filterBy: filterBy === "None" ? null : filterBy,
    };
    setSearchModel(model);

    const requestModel = {
      userPerforminSearchId: user.profile.userId,
      searchIndex: 0,
      searchKeyWord: fields.keyword,
      openForPartnership:
        fields.openForPartnership === "All" ? null : fields.openForPartnership,
      financing: fields.financing === "All" ? null : fields.financing,
      countries: countryList,
      industries: selectedIndustries,
      typeOfIndustriesAndServices: selectedIndustriesAndServices,
      spType: spTypeSelected,
      NumberOfEmployeesId: empList,
      filterType: filterType === "None" ? null : filterType,
      filterBy: filterBy === "None" ? null : filterBy,
    };
    await searchService
      .SearchServiceProvidersAsync(requestModel, currentLan)
      .then((x) => {
        setCount(x.totalCount);
        const model = {
          totalCount: x.totalCount,
          list: x.requestedServiceProvidersList,
        };
        setSearchList(model);
        setSearchResults(x.requestedServiceProvidersList);
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function AddSpSeen(model) {
    await spSeenService
      .AddSpSeenAsync(model)
      .then((x) => {
        history.push({
          pathname: `${path}/search-details/${model.spId}`,
          state: fromPartner,
        });
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function ToggleSPBookmark(model) {
    await spSeenService
      .ToggleSpSeenBookmarkAsync(model)
      .then((x) => {
        handleBookmarkFromBookmarked(model.spId, model.bookmark);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function handleBookmarkFromBookmarked(index, flag) {
    let updatedList = searchResults.map((item) => {
      if (item.profile.userId === index) {
        item.bookmarked = flag;
        return item;
      } else {
        return item; // else return unmodified item
      }
    });
    setSearchResults(updatedList);
    let updatedSearchList = searchList.list.map((item) => {
      if (item.profile.userId === index) {
        item.bookmarked = flag;
        return item;
      } else {
        return item; // else return unmodified item
      }
    });
    const model = {
      totalCount: searchList.totalCount,
      list: updatedSearchList,
    };
    setSearchList(model);
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  console.log(pendingSurveys);

  return (
    <>
      <div className="wrapper" id="searchProvider">
        <div className="section">
          <Row className="justify-content-center" style={{ marginTop: "0px" }}>
            <Col md="12">
              <Alert color="danger" isOpen={showAlert}>
                <div className="container  text-center">
                  <i
                    className="now-ui-icons business_bank"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  ></i>
                  {t("searchProvider.message")}
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => {
                      setShowAlert(false);
                      sessionStorage.setItem("searchProviderMessage", "false");
                    }}
                  >
                    <span aria-hidden="true">
                      <i className="now-ui-icons ui-1_simple-remove"></i>
                    </span>
                  </button>
                </div>
              </Alert>
            </Col>

            {pendingSurveys > 0 && (
              <Col md="12">
                <Alert color="danger" isOpen={showPendingAlert}>
                  <div className="container  text-center">
                    You still have pending survey(s).
                    {"   "}
                    <Link
                      style={{
                        fontFamily: "SpaceGrotesk",
                        textDecoration: "underline",
                        color: "white",
                      }}
                      onClick={() => history.push(`pending`)}
                    >
                      See more
                    </Link>
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={() => {
                        setShowPendingAlert(false);
                      }}
                    >
                      <span aria-hidden="true">
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </span>
                    </button>
                  </div>
                </Alert>
              </Col>
            )}
          </Row>
          <Container>
            <Modal
              isOpen={errorAlert}
              style={rtl ? { textAlign: "right" } : {}}
            >
              <div
                className="modal-header text-center"
                style={{ margin: "0 auto" }}
              >
                <h5 className="modal-title">{t("error.alert")}</h5>
              </div>
              <div className="modal-body col-md-12">
                <p>{errorMessage}</p>
              </div>

              <div className="modal-footer">
                <Button
                  color="danger"
                  type="button"
                  style={{ margin: "auto" }}
                  onClick={(e) => setErrorAlert(!errorAlert)}
                >
                  {t("close.button")}
                </Button>
              </div>
            </Modal>
            <Row>
              <Col md="4">
                <div>
                  <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    {({ isSubmitting, setFieldValue }) => {
                      return (
                        <div className="col-md-12">
                          <Card>
                            <Form
                              autoComplete="off"
                              className={rtl ? "text-right" : ""}
                            >
                              <>
                                <Container>
                                  <FormGroup className="col-md-12">
                                    <h4>{t("filterSearchSp")}</h4>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #bbb",
                                        marginTop: "-1%",
                                      }}
                                    ></hr>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <label htmlFor="keyword">
                                      {t("filterSearch.keyword")}
                                    </label>
                                    <Field
                                      name="keyword"
                                      placeholder={t(
                                        "filterSearch.keyword.placeholder"
                                      )}
                                      type="text"
                                      className={"form-control"}
                                    />
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <label htmlFor="openForPartnership">
                                      {t(
                                        "createCompanyProfile.openForPartnership"
                                      )}
                                    </label>
                                    <Field
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      name="openForPartnership"
                                      placeholder={t(
                                        "createCompanyProfile.openForPartnership"
                                      )}
                                      as="select"
                                      className="form-control"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "openForPartnership",
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option value="All">{t("all")}</option>
                                      <option value={false}>
                                        {t("label.no")}
                                      </option>
                                      <option value={true}>
                                        {t("label.yes")}
                                      </option>
                                    </Field>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <label htmlFor="financing">
                                      {t("createCompanyProfile.Financing")}
                                    </label>
                                    <Field
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      name="financing"
                                      placeholder={t(
                                        "createCompanyProfile.Financing"
                                      )}
                                      as="select"
                                      className="form-control"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "financing",
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option value="All">{t("all")}</option>
                                      <option value={false}>
                                        {t("label.no")}
                                      </option>
                                      <option value={true}>
                                        {t("label.yes")}
                                      </option>
                                    </Field>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <label htmlFor="industry">
                                      {t("filterSearch.industries")}
                                    </label>
                                    <br></br>
                                    {industries && (
                                      <select
                                        style={{
                                          fontSize: "14px",
                                        }}
                                        className={"form-control"}
                                        value={
                                          selectedIndustries == null
                                            ? "None"
                                            : selectedIndustries.typeOfIndustryId
                                        }
                                        onChange={(e) => {
                                          onIndustryChange(e.target.value);
                                        }}
                                      >
                                        <option key={null} value={null}>
                                          None
                                        </option>
                                        {industries.map((ind) => (
                                          <option
                                            key={ind.typeOfIndustryId}
                                            value={ind.typeOfIndustryId}
                                          >
                                            {ind.typeOfIndustryName}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </FormGroup>
                                  {show ? (
                                    <FormGroup className="col-md-12">
                                      <label htmlFor="industry">
                                        {t("filterSearch.services")}
                                      </label>
                                      <Multiselect
                                        style={
                                          rtl
                                            ? {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },

                                                option: {
                                                  textAlign: "right",
                                                },
                                              }
                                            : {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },
                                              }
                                        }
                                        hidePlaceholder={true}
                                        closeOnSelect={false}
                                        options={services}
                                        selectedValues={
                                          searchModel.typeOfIndustriesAndServices
                                        }
                                        placeholder={t(
                                          "filterSearch.services.placeholder"
                                        )}
                                        displayValue="typeOfServiceName"
                                        groupBy="typeOfIndustryName"
                                        showCheckbox={true}
                                        ref={servicesMultiselectRef}
                                        onSelect={(e) => {
                                          setSelectedIndustriesAndServices(e);
                                        }}
                                        onRemove={(e) => {
                                          setSelectedIndustriesAndServices(e);
                                        }}
                                      />
                                    </FormGroup>
                                  ) : (
                                    ""
                                  )}
                                  <FormGroup className="col-md-12">
                                    <label htmlFor="industry">
                                      {t("filterSearch.country")}
                                    </label>

                                    {countries && (
                                      <Multiselect
                                        style={
                                          rtl
                                            ? {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },

                                                option: {
                                                  textAlign: "right",
                                                },
                                              }
                                            : {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },
                                              }
                                        }
                                        closeOnSelect={false}
                                        options={countries}
                                        placeholder={t(
                                          "createCompanyProfile.selectOption"
                                        )}
                                        hidePlaceholder={true}
                                        displayValue="countryName"
                                        selectedValues={searchModel.countries}
                                        ref={countriesMultiselectRef}
                                        showCheckbox={true}
                                        onSelect={(e) => {
                                          onChange(e);
                                        }}
                                        onRemove={(e) => {
                                          onChange(e);
                                        }}
                                      />
                                    )}
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <label htmlFor="industry">
                                      {t("filterSearch.provider")}
                                    </label>
                                    {spType && (
                                      <Multiselect
                                        style={
                                          rtl
                                            ? {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },

                                                option: {
                                                  textAlign: "right",
                                                },
                                              }
                                            : {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },
                                              }
                                        }
                                        closeOnSelect={false}
                                        options={spType}
                                        placeholder={t(
                                          "filterSearch.provider.type"
                                        )}
                                        hidePlaceholder={true}
                                        displayValue="displaySpType"
                                        selectedValues={searchModel.spType}
                                        ref={providerTypeMultiselectRef}
                                        showCheckbox={true}
                                        onSelect={(e) => {
                                          onSpTypeChange(e);
                                        }}
                                        onRemove={(e) => {
                                          onSpTypeChange(e);
                                        }}
                                      />
                                    )}
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <label htmlFor="industry">
                                      {t("filterSearch.companySize")}
                                    </label>
                                    {nbEmp && (
                                      <Multiselect
                                        style={
                                          rtl
                                            ? {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },

                                                option: {
                                                  textAlign: "right",
                                                },
                                              }
                                            : {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },
                                              }
                                        }
                                        closeOnSelect={false}
                                        options={nbEmp}
                                        placeholder={t(
                                          "filterSearch.companySize.placeholder"
                                        )}
                                        hidePlaceholder={true}
                                        selectedValues={
                                          searchModel.NumberOfEmployeesId
                                        }
                                        displayValue="description"
                                        ref={nbOfEmployeesMultiselectRef}
                                        showCheckbox={true}
                                        onSelect={(e) => {
                                          onNbEmpChange(e);
                                        }}
                                        onRemove={(e) => {
                                          onNbEmpChange(e);
                                        }}
                                      />
                                    )}
                                  </FormGroup>
                                  <center>
                                    <FormGroup className="col-md-12">
                                      <div className="form-row">
                                        <FormGroup className="col-md-6">
                                          <Button
                                            color="primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                          >
                                            {isSubmitting && (
                                              <span className="spinner-border spinner-border-sm mr-1"></span>
                                            )}
                                            {t("filterSearch.button")}
                                          </Button>
                                        </FormGroup>
                                        <FormGroup className="col-md-5">
                                          <Button
                                            color="primary"
                                            type="submit"
                                            onClick={() => {
                                              resetFilter();
                                              setFieldValue("keyword", "");
                                              setFieldValue("financing", "All");
                                              setFieldValue(
                                                "openForPartnership",
                                                "All"
                                              );
                                            }}
                                          >
                                            {isSubmitting && (
                                              <span className="spinner-border spinner-border-sm mr-1"></span>
                                            )}
                                            {t("filterSearch.resetButton")}
                                          </Button>
                                        </FormGroup>
                                      </div>
                                    </FormGroup>
                                  </center>
                                </Container>
                              </>
                            </Form>
                          </Card>
                        </div>
                      );
                    }}
                  </Formik>
                </div>
              </Col>
              <Col md="8">
                <Row>
                  <Col md="12">
                    <Card style={{ marginBottom: "10px", boxShadow: "none" }}>
                      <>
                        <div
                          style={
                            rtl
                              ? {
                                  display: "flex",
                                  float: "right",
                                  marginTop: "10px",
                                }
                              : {
                                  display: "flex",
                                  float: "left",
                                  marginTop: "10px",
                                }
                          }
                        >
                          <UncontrolledDropdown style={{ marginTop: "-10px" }}>
                            <DropdownToggle
                              aria-expanded={false}
                              aria-haspopup={true}
                              caret
                              color="primary"
                              data-toggle="dropdown"
                              id="dropdownMenuButton"
                              type="button"
                            >
                              {sortByLabel}
                            </DropdownToggle>
                            <DropdownMenu aria-labelledby="dropdownMenuButton">
                              <DropdownItem
                                //  href="#pablo"
                                onClick={(e) => {
                                  setFilterBy("CompanyName");
                                  setSortByLabel("Company Name");
                                }}
                              >
                                {t("createCompanyProfile.companyName")}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              caret
                              color="default"
                              href="#pablo"
                              nav
                              //  style={{ marginTop: "10px" }}
                            >
                              {filterType === "Ascending" ? (
                                <i className="fa fa-sort-alpha-down"></i>
                              ) : (
                                <i className="fa fa-sort-alpha-up"></i>
                              )}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  setFilterType("Ascending");
                                  searchModel.filterType = "Ascending";
                                  searchModel.searchIndex = 0;
                                  setSearchModel(searchModel);
                                  sort();
                                }}
                              >
                                <i className="fa fa-sort-alpha-down"></i>
                                {t("sort.ascending")}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setFilterType("Descending");
                                  searchModel.filterType = "Descending";
                                  searchModel.searchIndex = 0;
                                  setSearchModel(searchModel);
                                  sort();
                                }}
                              >
                                <i className="fa fa-sort-alpha-up"></i>
                                {t("sort.descending")}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </>
                    </Card>
                  </Col>
                  {searchResults &&
                    searchResults.map((result) => {
                      return (
                        <Col md="12">
                          <Card
                            style={
                              rtl
                                ? { marginBottom: "15px", direction: "ltr" }
                                : { marginBottom: "15px" }
                            }
                          >
                            <CardBody style={{ paddingBottom: "5px" }}>
                              <Row>
                                <Col md="3">
                                  <img
                                    src={result.logo.filePath}
                                    style={{
                                      margin: "0 0 auto auto",
                                      width: "123px",
                                      height: "123px",
                                      objectFit: "contain",
                                    }}
                                    alt=""
                                  />
                                </Col>
                                <Col md="8" style={{ top: "50%" }}>
                                  <Row style={{ marginTop: "10%" }}>
                                    <Col md="10">
                                      <Row>
                                        <Link
                                          tag="h4"
                                          style={{ fontSize: "18px" }}
                                          onClick={() => {
                                            const model = {
                                              userId: user.profile.userId,
                                              spId: result.profile.userId,
                                            };
                                            AddSpSeen(model);
                                          }}
                                        >
                                          {result.profile.companyName}
                                        </Link>

                                        {result.profile.openForPartnership ===
                                        true ? (
                                          <i
                                            id="partnership"
                                            className="fa fa-handshake"
                                            style={{
                                              paddingLeft: "6px",
                                              paddingRight: "6px",
                                              fontSize: "18px",
                                              color: "#0E6EB8",
                                            }}
                                          >
                                            <UncontrolledTooltip
                                              target="partnership"
                                              placement="right"
                                            >
                                              {t(
                                                "createCompanyProfile.openForPartnership"
                                              )}
                                            </UncontrolledTooltip>
                                          </i>
                                        ) : null}
                                        {result.profile.financing === true ? (
                                          <i
                                            id="financing"
                                            className="fa fa-dollar-sign"
                                            style={{
                                              paddingLeft: "5px",
                                              paddingRight: "5px",
                                              fontSize: "18px",
                                              color: "#0E6EB8",
                                            }}
                                          >
                                            <UncontrolledTooltip
                                              target="financing"
                                              placement="right"
                                            >
                                              {t(
                                                "createCompanyProfile.Financing"
                                              )}
                                            </UncontrolledTooltip>
                                          </i>
                                        ) : null}
                                        {result.profile &&
                                        result.profile.spType &&
                                        result.profile.spType === "Start Up" ? (
                                          <i
                                            id="spStartup"
                                            className="fa fa-rocket"
                                            style={{
                                              paddingLeft: "5px",
                                              paddingRight: "5px",
                                              fontSize: "18px",
                                              color: "#0E6EB8",
                                            }}
                                          >
                                            <UncontrolledTooltip
                                              target="spStartup"
                                              placement="right"
                                            >
                                              {t("signUp.providerType.startup")}
                                            </UncontrolledTooltip>
                                          </i>
                                        ) : null}
                                        {result !== null &&
                                        result.profile !== null &&
                                        result.profile.supportingDocuments !==
                                          null &&
                                        result.profile.supportingDocuments
                                          .length > 0 ? (
                                          <i
                                            id="documentsFound"
                                            className="fa fa-file-text"
                                            style={{
                                              paddingLeft: "5px",
                                              paddingRight: "5px",
                                              fontSize: "18px",
                                              color: "#0E6EB8",
                                            }}
                                          >
                                            <UncontrolledTooltip
                                              target="documentsFound"
                                              placement="right"
                                            >
                                              {t("supportingDocAvailable")}
                                            </UncontrolledTooltip>
                                          </i>
                                        ) : null}
                                      </Row>
                                      <Row>
                                        {result.badge != null ? (
                                          <>
                                            <Tooltip title={result.badge.name}>
                                              <img
                                                src={result.badge.image}
                                                width="32px"
                                                style={{ marginRight: "20px" }}
                                              />
                                            </Tooltip>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        {result.vettingBadge != null ? (
                                          <Tooltip
                                            title={result.vettingBadge.name}
                                          >
                                            <img
                                              src={result.vettingBadge.image}
                                              width="32px"
                                            />
                                          </Tooltip>
                                        ) : (
                                          <></>
                                        )}
                                      </Row>
                                    </Col>
                                    <Col md="2" style={{ top: "50%" }}>
                                      <Row style={{ marginTop: "10%" }}>
                                        <Link
                                          tag="h4"
                                          style={{ fontSize: "18px" }}
                                          onClick={() => {
                                            history.push(
                                              `messaging/${result.profile.userId}`
                                            );
                                          }}
                                        >
                                          <i
                                            id="message"
                                            className="fa fa-comments"
                                            style={{
                                              paddingLeft: "10px",
                                              paddingRight: "10px",
                                              color: "#0E6EB8",
                                            }}
                                          ></i>

                                          <UncontrolledTooltip
                                            // className="custom-tooltip"
                                            target="message"
                                            placement="bottom"
                                          >
                                            {t("message.sp")}
                                          </UncontrolledTooltip>
                                        </Link>
                                        <Link
                                          id="bookmark"
                                          hidden={result.bookmarked === true}
                                          tag="h4"
                                          style={{ fontSize: "18px" }}
                                        >
                                          <i
                                            className="now-ui-icons location_bookmark"
                                            style={{
                                              paddingLeft: "10px",
                                              paddingRight: "10px",
                                              color: "#0E6EB8",
                                            }}
                                            onClick={async () => {
                                              const model = {
                                                spId: result.profile.userId,
                                                userId: user.profile.userId,
                                                bookmark: true,
                                              };
                                              await ToggleSPBookmark(model);
                                            }}
                                          ></i>
                                          <UncontrolledTooltip
                                            target="bookmark"
                                            placement="right"
                                          >
                                            {t("bookmark.click")}
                                          </UncontrolledTooltip>
                                        </Link>
                                        <Link
                                          id="unbookmark"
                                          hidden={result.bookmarked === false}
                                          tag="h4"
                                          style={{ fontSize: "18px" }}
                                        >
                                          <i
                                            className="fa fa-bookmark"
                                            style={{
                                              paddingLeft: "10px",
                                              paddingRight: "10px",
                                              color: "#0E6EB8",
                                            }}
                                            onClick={async () => {
                                              const model = {
                                                spId: result.profile.userId,
                                                userId: user.profile.userId,
                                                bookmark: false,
                                              };
                                              await ToggleSPBookmark(model);
                                            }}
                                          ></i>
                                          <UncontrolledTooltip
                                            target="unbookmark"
                                            placement="bottom"
                                          >
                                            {t("unbookmark.click")}
                                          </UncontrolledTooltip>
                                        </Link>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <CardText style={{ display: "flex" }}>
                                      <Col md="12">
                                        <ReactCountryFlag
                                          countryCode={
                                            result.countryView.countryCode
                                          }
                                          svg
                                          style={{
                                            width: "1em",
                                            height: "1em",
                                            marginTop: "-2px",
                                            marginRight: "10px",
                                            borderRadius: "0px",
                                          }}
                                          title="US"
                                        />
                                        <small style={{ fontSize: "80%" }}>
                                          {result.countryView.countryName}
                                        </small>
                                      </Col>
                                    </CardText>
                                  </Row>
                                  <Row>
                                    {result.profile &&
                                      result.profile.spType &&
                                      result.profile.spType === "Start Up" && (
                                        <>
                                          {/* <div
                                            style={{
                                              paddingLeft: "3px",
                                              paddingRight: "3px",
                                            }}
                                          >
                                            <Badge
                                              style={{
                                                marginLeft: "10px",
                                                marginTop: "5px",
                                                backgroundColor: "#5de33b",
                                                border: "none",
                                                fontSize: "80%",
                                              }}
                                            >
                                              {result.profile.spType}
                                            </Badge>
                                          </div> */}
                                          {result.profile.startupStage ===
                                          "Seed stage" ? (
                                            <Badge
                                              style={{
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                                marginTop: "5px",
                                                backgroundColor: "#15d134",
                                                border: "none",
                                                fontSize: "80%",
                                              }}
                                            >
                                              {result.profile.startupStage}
                                            </Badge>
                                          ) : result.profile.startupStage ===
                                            "Early stage" ? (
                                            <Badge
                                              style={{
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                                marginTop: "5px",
                                                backgroundColor: "#12ad2c",
                                                border: "none",
                                                fontSize: "80%",
                                              }}
                                            >
                                              {result.profile.startupStage}
                                            </Badge>
                                          ) : (
                                            <Badge
                                              style={{
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                                marginTop: "5px",
                                                backgroundColor: "#0d8d22",
                                                border: "none",
                                                fontSize: "80%",
                                              }}
                                            >
                                              {result.profile.startupStage}
                                            </Badge>
                                          )}
                                        </>
                                      )}
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12">
                                  <CardText
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {result.profile.overview}
                                  </CardText>
                                </Col>
                              </Row>
                              <Row style={{ marginTop: "10px" }}>
                                {result.industriesAndServices &&
                                  result.industriesAndServices.map(
                                    (industry) => {
                                      return (
                                        <>
                                          <div
                                            style={{
                                              paddingLeft: "3px",
                                              paddingRight: "3px",
                                            }}
                                          >
                                            <Badge
                                              style={{
                                                backgroundColor: "#f5f5fa",
                                                color: "#000000",
                                                border: "none",
                                                fontSize: "80%",
                                              }}
                                            >
                                              {industry.industryName}
                                            </Badge>
                                          </div>
                                          {industry.services &&
                                            industry.services.map((service) => {
                                              return (
                                                <div
                                                  style={{
                                                    paddingLeft: "3px",
                                                    paddingRight: "3px",
                                                  }}
                                                >
                                                  <Badge
                                                    style={{
                                                      backgroundColor:
                                                        "#f5f5fa",
                                                      color: "#000000",
                                                      border: "none",
                                                      fontSize: "80%",
                                                    }}
                                                  >
                                                    {service.serviceName}
                                                  </Badge>
                                                </div>
                                              );
                                            })}
                                        </>
                                      );
                                    }
                                  )}
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
                {searchResults &&
                  Object.keys(searchResults).length < totalCount && (
                    <center>
                      <Button
                        color="primary"
                        onClick={() => {
                          onLoadMoreClick();
                        }}
                      >
                        {t("loadMore")}
                      </Button>
                    </center>
                  )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SearchProviders;
