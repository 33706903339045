import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/Message`;

export const messageService = {
  GetAllUserConvProfilesAsync,
  SendMessageAsync,
  GetConvoMessagesAsync,
  GetConfidentialityAgreementAsync,
  AddChatNDAAsync,
  UpdateSeenMessagesAsync,
};

function GetAllUserConvProfilesAsync(userId, receiverId) {
  if (receiverId === null || receiverId === undefined)
    return fetchWrapper.get(`${baseUrl}/GetAllUserConvProfiles/${userId}`);
  else
    return fetchWrapper.get(
      `${baseUrl}/GetAllUserConvProfiles/${userId}/${receiverId}`
    );
}

function SendMessageAsync(data) {
  return fetchWrapper.post(`${baseUrl}/SendMessage`, data);
}

function GetConvoMessagesAsync(userId, receiverId, projId, fromProj) {
  return fetchWrapper.get(
    `${baseUrl}/GetConvoMessages/${userId}/${receiverId}/${projId}/${fromProj}`
  );
}

function GetConfidentialityAgreementAsync(language, isPartner) {
  return fetchWrapper.get(
    `${baseUrl}/GetConfidentialityAgreement/${language}/${isPartner}`
  );
}

function AddChatNDAAsync(model) {
  return fetchWrapper.post(`${baseUrl}/AddChatNDA`, model);
}

function UpdateSeenMessagesAsync(senderId, receiverId, projId) {
  if (projId === null || projId === undefined)
    return fetchWrapper.post(
      `${baseUrl}/UpdateSeenMessages/${senderId}/${receiverId}`
    );
  return fetchWrapper.post(
    `${baseUrl}/UpdateSeenMessages/${senderId}/${receiverId}/${projId}`
  );
}
