import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";
import { put, post, get } from "axios";
const baseUrl = `${config.apiUrl}/LandingPage`;

export const landingPageService = {
  GetAllPartnersAsync,
  GetLandingPageTextAsyncLanguage,
  GetAllIfYouAreAsync,
  GetAllIfYouBelieveInAsync,
  GetAllWhoWeAreAsync,
  GetAllSectionsByLanguageAsync,
};
function GetLandingPageTextAsyncLanguage(language, texttype) {
  return fetchWrapper.get(
    `${baseUrl}/GetLandingPageTextAsyncLanguage/${language}/${texttype}`
  );
}
function GetAllPartnersAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllPartnersAsync`);
}

function GetAllIfYouAreAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllIfYouAreAsync`);
}
function GetAllIfYouBelieveInAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllIfYouBelieveInAsync`);
}
function GetAllWhoWeAreAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllWhoWeAre`);
}
function GetAllSectionsByLanguageAsync(lan) {
  return fetchWrapper.get(`${baseUrl}/GetAllSectionsByLanguage/${lan}`);
}
