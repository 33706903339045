import React, { useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import CardHeader from "reactstrap/lib/CardHeader";
import Card from "reactstrap/lib/Card";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import ChangePassword from "./ChangePassword";
import PreferredLanguage from "./PreferredLanguage";
import DeactivateAccount from "./deactivateAccount";
import Subscription from "./Subscription";
import { Link } from "react-router-dom";
import UpgradeAccount from "./UpgradeAccount";
import { profileService } from "_services";

function ViewSettings({ history, location }) {
  const [loading, setLoading] = useState(false);
  const [iconPills, setIconPills] = React.useState("1");
  const [user, setUser] = useState(profileService.userValue);

  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  async function RefreshAccount() {
    await profileService.RefreshTokenAsync();
    setUser(profileService.userValue);
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <div className="wrapper">
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto">
                <Card
                  className={rtl ? "text-right" : ""}
                  style={{ boxShadow: "none" }}
                >
                  <CardHeader>
                    <Nav className="justify-content-center" role="tablist" tabs>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          className={iconPills === "1" ? "active" : ""}
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("1");
                          }}
                        >
                          <i
                            className={
                              rtl
                                ? "now-ui-icons users_circle-08 ml-1"
                                : "now-ui-icons users_circle-08 mr-1"
                            }
                          ></i>
                          {t("accountSettings.userManagement")}
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          tag={Link}
                          className={iconPills === "2" ? "active" : ""}
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("2");
                          }}
                        >
                          <i
                            className={
                              rtl
                                ? "now-ui-icons ui-1_settings-gear-63 ml-1"
                                : "now-ui-icons ui-1_settings-gear-63 mr-1"
                            }
                          ></i>
                          {t("accountSettings.memberships")}
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                  </CardHeader>
                  <CardBody>
                    <TabContent activeTab={"iconPills" + iconPills}>
                      <TabPane tabId="iconPills1">
                        {user.isPrimary ? (
                          <Row>
                            <Col md="6">
                              <ChangePassword
                                history={history}
                                location={location}
                              />
                            </Col>
                            <Col md="6">
                              {/* <PreferredLanguage /> */}

                              {user !== null &&
                                user.profile !== null &&
                                user.profile.spType !== null &&
                                user.profile.spType === "Start Up" && (
                                  <UpgradeAccount
                                    history={history}
                                    refresh={RefreshAccount}
                                  />
                                )}
                              <DeactivateAccount />
                              <Subscription location={location} />
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col md="6" className="m-auto">
                              <ChangePassword
                                history={history}
                                location={location}
                              />
                            </Col>
                          </Row>
                        )}
                      </TabPane>
                      {/* <TabPane tabId="iconPills2">
                        <Col md="12">
                          <Card className={rtl ? "text-right" : ""}>
                            <CardHeader className="text-center">
                              <CardTitle className="" tag="h4">
                                {t("accountSettings.paymentSettings")}
                              </CardTitle>
                              <hr
                                className="btn-primary"
                                style={{ size: "1", width: "20%" }}
                              ></hr>
                            </CardHeader>
                            <CardBody className="text-center">
                              <p>{t("pricing.description")}</p>
                              <p>{t("pricing.description.second")}</p>
                            </CardBody>
                          </Card>
                        </Col>
                      </TabPane> */}
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ViewSettings;
