import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/notificationsToken`;

export const tokenService = {
  AddTokenAsync,
};

function AddTokenAsync(model) {
  return fetchWrapper.post(`${baseUrl}/AddToken`, model);
}
