import React, { useEffect, useContext, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../_context";
// reactstrap components
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { languageService, countryService } from "_services";

function LanguageDropdown({ history, match }) {
  const { t, i18n } = useTranslation();
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [lan, setLan] = React.useState(
    localStorage.getItem("currentLanguage")
      ? localStorage.getItem("currentLanguage")
      : "US"
  );
  const lanRef = React.useRef(
    localStorage.getItem("currentLanguage")
      ? localStorage.getItem("currentLanguage")
      : "US"
  );
  const [languages, setLanguages] = useState(null);
  const [loading, setLoading] = useState(true);
  const changed = React.useRef(false);
  const rtl = i18n.dir() == "rtl";

  const fetchData = async () => {
    setLoading(true);
    await languageService.GetAllLanguagesAsync().then((x) => {
      setLanguages(x);
    });
    setLoading(false);
  };

  function changeLan(lan) {
    // if (lan === "AR") {
    //   setLan("LB");
    //   setCurrentLan("ar");
    //   i18n.changeLanguage("ar");
    //   //     localStorage.setItem("currentLanguage", "ar");
    // } else if (lan === "FR") {
    //   setCurrentLan("fr");
    //   setLan("FR");
    //   i18n.changeLanguage("fr");
    //
    //    localStorage.setItem("currentLanguage", "fr");
    // } else {
    //   setLan("US");
    //   setCurrentLan("en");
    //   i18n.changeLanguage("en");
    //   //  localStorage.setItem("currentLanguage", "en");
    // }
    changed.current = !changed.current;
    setLan(lan.languageFlagName);
    lanRef.current = lan.languageFlagName;
    setCurrentLan(lan.languageName);
    localStorage.setItem("currentLanguage", lan.languageFlagName);
    i18n.changeLanguage(lan.languageName);
  }

  useEffect(() => {
    fetchData();

    const language =
      localStorage.getItem("currentLan") == null
        ? "US"
        : localStorage.getItem("currentLan");
    countryService.GetCountryByCodeAsync(language).then((res) => {
      console.log(res);
      setLan(res.countryCode);
    });
    // if (currentLan === "ar") {
    //   setLan("LB");
    // }  else if (currentLan === "fr") {
    //   setLan("fr"); // }
    //else {
    //   setLan("US");
    // }
    // setLan("US");
  }, [currentLan, changed.current]);
  document.body.dir = i18n.dir();
  return (
    <>
      <UncontrolledDropdown nav>
        <DropdownToggle
          caret
          color="default"
          href="#pablo"
          nav
          style={{ fontFamily: "SpaceGrotesk" }}
          onClick={(e) => e.preventDefault()}
        >
          <ReactCountryFlag
            countryCode={lanRef.current}
            svg
            className={rtl ? "ml-2" : "mr-2"}
            style={{
              width: "2em",
              height: "2em",
            }}
            title={lanRef.current}
          />
        </DropdownToggle>
        <DropdownMenu
          className={rtl ? "dropdown-menu-left" : "dropdown-menu-right"}
        >
          {languages &&
            languages.map((x) => {
              return (
                <DropdownItem
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={(e) => {
                    changeLan(x);
                  }}
                >
                  {x.languageDescription}
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}
export default LanguageDropdown;
