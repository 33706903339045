import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Modal,
} from "reactstrap";
import { profileService } from "_services";
import { LanguageContext } from "_context";

function DeactivateAccount() {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const user = profileService.userValue;
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);

  async function deactivate() {
    await profileService
      .DeactivateUserAsync(user.profile.userId)
      .then(async (error) => {
        await profileService.Logout(user.profile.userId);
        if (error.message !== undefined) {
          setError(error.message);
        } else {
          setError(error);
        }
        setSuccessAlert(!successAlert);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function deleteUser() {
    await profileService
      .DeleteUserAsync(user.profile.userId)
      .then(async (error) => {
        await profileService.Logout(user.profile.userId);
        if (error.message !== undefined) {
          setError(error.message);
        } else {
          setError(error);
        }
        setSuccessAlert(!successAlert);
      })
      .catch((error) => {
        setError(error.message);
        if (error.error !== null && error.error.length > 0) {
          for (var i = 0; i < error.error.length; i++) {
            if (error.error[i].languageName == currentLan) {
              setError(error.error[i].error);
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  return (
    <>
      <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("delete.buttonTitle")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>{t("delete.message")}</p>
        </div>
        <div className="modal-footer" style={{ justifyContent: "flex-end" }}>
          <center>
            <Button
              className="mr-2"
              style={{ margin: "auto" }}
              color="primary"
              type="button"
              onClick={(e) => setDeleteAlert(!deleteAlert)}
            >
              {t("close.button")}
            </Button>
            <Button
              className="mr-2"
              style={{ margin: "auto" }}
              color="danger"
              type="button"
              onClick={() => {
                setDeleteAlert(!deleteAlert);
                setConfirmDeleteAlert(!confirmDeleteAlert);
              }}
            >
              {t("delete.buttonTitle")}
            </Button>
          </center>
        </div>
      </Modal>
      <Modal
        isOpen={confirmDeleteAlert}
        style={rtl ? { textAlign: "right" } : {}}
      >
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">
            {`${t("confirmDeleteAlert.one")} "${user.profile.companyName}`}"
          </h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>{t("confirmDeleteAlert.two")}</p>
        </div>
        <div className="modal-footer" style={{ justifyContent: "flex-end" }}>
          <center>
            <Button
              className="mr-2"
              style={{ margin: "auto" }}
              color="primary"
              type="button"
              onClick={(e) => setConfirmDeleteAlert(!confirmDeleteAlert)}
            >
              {t("close.button")}
            </Button>
            <Button
              className="mr-2"
              style={{ margin: "auto" }}
              color="danger"
              type="button"
              onClick={() => {
                setConfirmDeleteAlert(!confirmDeleteAlert);
                deleteUser();
              }}
            >
              {t("delete.buttonTitle")}
            </Button>
          </center>
        </div>
      </Modal>

      <Modal isOpen={successAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("deactivate.button")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>{errorMessage}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            onClick={(e) => setSuccessAlert(!successAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>{errorMessage}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("deactivate.button")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>{t("deactivate.message")}</p>
        </div>
        <div>
          <center>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                setAlert(!alert);
                deactivate();
              }}
            >
              {t("deactivate.button")}
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={(e) => setAlert(!alert)}
            >
              {t("close.button")}
            </Button>
          </center>
        </div>
      </Modal>
      <Card className={rtl ? "text-right" : ""}>
        <CardHeader className="text-center">
          <CardTitle className="" tag="h4">
            {t("account.suspension")}
          </CardTitle>
          <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
        </CardHeader>
        <CardBody>
          <center>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                setAlert(true);
              }}
            >
              {t("deactivate.button")}
            </Button>
            <Button
              color="danger"
              type="button"
              onClick={() => {
                setDeleteAlert(true);
              }}
            >
              {t("delete.buttonTitle")}
            </Button>
          </center>
        </CardBody>
      </Card>
    </>
  );
}

export default DeactivateAccount;
