import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditIfYouAre from "./AddEditIfYouAre";
import IfYouAreList from "./IfYouAreList";

function IfYouAre({ history, match }) {
    const { path } = match;
    return (
        <div>
            <Switch>
                <PrivateRoute path={`${path}`} component={IfYouAreList} exact />
                <PrivateRoute path={`${path}/add`} component={AddEditIfYouAre} />
                <PrivateRoute
                    path={`${path}/edit/:id`}
                    component={AddEditIfYouAre}
                />
            </Switch>
        </div>
    );
}

export { IfYouAre };
