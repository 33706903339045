import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditAffiliated from "./AddEditAffiliated";
import AffiliatedUsersList from "./AffiliatedUsersList";

function AffiliatedUsers({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={AffiliatedUsersList} exact />
        <PrivateRoute path={`${path}/add`} component={AddEditAffiliated} />
        <PrivateRoute path={`${path}/edit/:id`} component={AddEditAffiliated} />
      </Switch>
    </div>
  );
}

export { AffiliatedUsers };
