import React, { useState, useContext } from "react";
import { Row, Col } from "reactstrap";
import { profileService, projectSeenService } from "../../../_services";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import Badge from "reactstrap/lib/Badge";
import CardText from "reactstrap/lib/CardText";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import Tooltip from "reactstrap/lib/Tooltip";
import { LanguageContext } from "_context";

function ProjectOverviewCard({ history, props, onBookmark }) {
  const user = profileService.userValue;
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  const [BookmarkTooltipOpen, setBookmarkTooltipOpen] = useState(false);

  const bookmarkToggle = () => setBookmarkTooltipOpen(!BookmarkTooltipOpen);

  const [UnbookmarkTooltipOpen, setUnbookmarkTooltipOpen] = useState(false);

  const unBookmarkToggle = () =>
    setUnbookmarkTooltipOpen(!UnbookmarkTooltipOpen);
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await projectSeenService
  //       .GetAllProjectsSeenAsync(user.profile.userId, false)
  //       .then((x) => {
  //         setInfo(x);
  //       });
  //     setLoading(false);
  //   };
  // React.useEffect(() => {
  //   //  fetchData();
  //   document.body.classList.add("profile-page");
  //   document.body.classList.add("sidebar-collapse");
  //   document.documentElement.classList.remove("nav-open");
  //   document.body.style = "background-color: #ffffff";
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //   return function cleanup() {
  //     document.body.classList.remove("profile-page");
  //     document.body.classList.remove("sidebar-collapse");
  //   };
  // }, []);

  async function ToggleProjectBookmarkAsync(model) {
    await projectSeenService
      .ToggleProjectBookmarkAsync(model)
      .then((x) => {
        // history.push(`${path}/${selectedProject}`);

        setUnbookmarkTooltipOpen(false);
        onBookmark(model.projectId, model.bookmark);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  // async function handleBookmark(index,flag)
  // {
  //   let updatedList = props.map((item) => {
  //         if (item.projectView.projectID === index) {
  //           item.projectView.bookMarked = flag;
  //           return item;
  //         }
  //         else
  //         {
  //           return item; // else return unmodified item
  //         }

  //       });
  //       setInfo(updatedList);
  // }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <Card style={{ marginBottom: "15px" }}>
        <CardBody
          className={rtl ? "text-left" : ""}
          style={
            rtl
              ? { paddingBottom: "0px", direction: "ltr" }
              : { paddingBottom: "0px" }
          }
        >
          <Row style={{ paddingBottom: "1rem" }}>
            <Col md="8">
              <Link
                style={{ marginTop: "0px" }}
                tag="h4"
                onClick={() =>
                  history.push(
                    `/provider/search-project/${props.projectView.projectID}`
                  )
                }
              >
                {props !== null &&
                props.projectView !== null &&
                props.projectView.projectTitle !== null
                  ? props.projectView.projectTitle
                  : ""}
              </Link>
              <i
                id="bookmark"
                hidden={props.bookmark === true}
                className="now-ui-icons location_bookmark"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  //borderColor: "#0E6EB8",
                  color: "#0E6EB8",
                }}
                onClick={async () => {
                  const model = {
                    projectId: props.projectView.projectID,
                    userId: user.profile.userId,
                    bookmark: true,
                  };
                  await ToggleProjectBookmarkAsync(model);
                }}
              >
                {/* <UncontrolledTooltip
                  target="bookmark"
                  placement="right"
                  style={{ boxShadow: "0px 10px 10px 0px rgb(0,0,0,0.02)" }}
                >
                  {t("bookmark.click")}
                </UncontrolledTooltip> */}
                <Tooltip
                  isOpen={BookmarkTooltipOpen}
                  toggle={bookmarkToggle}
                  target="bookmark"
                  placement="right"
                  //   style={{ boxShadow: "0px 10px 10px 0px rgb(0,0,0,0.02)" }}
                >
                  {t("bookmark.click")}
                </Tooltip>
              </i>

              <i
                id="unbookmark"
                hidden={props.bookmark === false}
                className="fa fa-bookmark"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  //borderColor: "#0E6EB8",
                  color: "#0E6EB8",
                }}
                onClick={async () => {
                  const model = {
                    projectId: props.projectView.projectID,
                    userId: user.profile.userId,
                    bookmark: false,
                  };
                  await ToggleProjectBookmarkAsync(model);
                }}
              ></i>
              {/* <UncontrolledTooltip
                style={{ boxShadow: "0px 10px 10px 0px rgb(0,0,0,0.02)" }}
                target="unbookmark"
                placement="right"
              >
                {t("unbookmark.click")}
              </UncontrolledTooltip> */}
              <Tooltip
                isOpen={UnbookmarkTooltipOpen}
                toggle={unBookmarkToggle}
                //   style={{ boxShadow: "0px 10px 10px 0px rgb(0,0,0,0.02)" }}
                target="unbookmark"
                placement="right"
              >
                {t("unbookmark.click")}
              </Tooltip>
            </Col>
            {/* <Col md="4" style={{ marginTop: "auto" }}>
              {props.bookmark === true ? (
                <Button
                  style={{ marginTop: "auto" }}
                  color="primary"
                  onClick={async () => {
                    const model = {
                      projectId: props.projectView.projectID,
                      userId: user.profile.userId,
                      bookmark: false,
                    };
                    await ToggleProjectBookmarkAsync(model);
                  }}
                >
                  {t("bookmarked")}
                </Button>
              ) : (
                <Button
                  style={{ marginTop: "auto" }}
                  color="primary"
                  onClick={async () => {
                    const model = {
                      projectId: props.projectView.projectID,
                      userId: user.profile.userId,
                      bookmark: true,
                    };
                    await ToggleProjectBookmarkAsync(model);
                  }}
                >
                  {t("bookmark")}
                </Button>
              )}
            </Col> */}
          </Row>

          <div
            style={{
              display: "flex",
              //marginTop: "-10px",
            }}
          >
            <CardText style={{ width: "350px" }}>
              <i
                class="fa fa-cogs"
                aria-hidden="true"
                style={{
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
              ></i>
              <small style={{ fontSize: "80%" }}>
                {props !== null &&
                props.projectView !== null &&
                props.projectView.projectTypeView !== null &&
                props.projectView.projectTypeView.projectTypeName !== null
                  ? props.projectView.projectTypeView.projectTypeName
                  : ""}
              </small>
            </CardText>
            <CardText style={{ width: "350px" }}>
              <i
                class="fas fa-map-marker-alt"
                aria-hidden="true"
                style={{
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
              ></i>
              {props !== null &&
              props.projectView !== null &&
              props.projectView.country !== null &&
              props.projectView.country.countryCode !== null ? (
                <ReactCountryFlag
                  countryCode={props.projectView.country.countryCode}
                  svg
                  style={{
                    width: "1.5em",
                    height: "1.5em",
                    marginTop: "-2px",
                    marginRight: "10px",
                    borderRadius: "0px",
                  }}
                  title="US"
                />
              ) : (
                ""
              )}

              <small style={{ fontSize: "80%" }}>
                {props !== null &&
                props.projectView !== null &&
                props.projectView.country !== null &&
                props.projectView.country.countryName !== null
                  ? props.projectView.country.countryName
                  : ""}
              </small>
            </CardText>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "-10px",
            }}
          >
            <CardText style={{ width: "350px" }}>
              <i
                className="fa fa-upload"
                aria-hidden="true"
                style={{
                  paddingRight: "13px",
                  paddingLeft: "13px",
                }}
              ></i>
              <small style={{ fontSize: "80%" }}>
                {`${new Date(
                  props.projectView.publicationDateTime
                ).getUTCDate()}/${
                  monthNames[
                    new Date(
                      props.projectView.publicationDateTime
                    ).getUTCMonth()
                  ]
                }/${new Date(
                  props.projectView.publicationDateTime
                ).getFullYear()}` !== "1/January/1"
                  ? `${new Date(
                      props.projectView.publicationDateTime
                    ).getUTCDate()}/${
                      monthNames[
                        new Date(
                          props.projectView.publicationDateTime
                        ).getUTCMonth()
                      ]
                    }/${new Date(
                      props.projectView.publicationDateTime
                    ).getFullYear()}`
                  : ""}
              </small>
            </CardText>
            <CardText
              style={{
                width: "350px",
              }}
            >
              <i
                class="fa fa-hourglass-end"
                aria-hidden="true"
                style={{
                  paddingRight: "12px",
                  paddingLeft: "12px",
                }}
              ></i>
              <small style={{ fontSize: "80%" }}>
                {props !== null &&
                props.projectView !== null &&
                props.projectView.closingDate !== null &&
                `${new Date(props.projectView.closingDate).getUTCDate()}/${
                  monthNames[
                    new Date(props.projectView.closingDate).getUTCMonth()
                  ]
                }/${new Date(props.projectView.closingDate).getFullYear()}` !==
                  "1/January/1"
                  ? `${new Date(props.projectView.closingDate).getUTCDate()}/${
                      monthNames[
                        new Date(props.projectView.closingDate).getUTCMonth()
                      ]
                    }/${new Date(props.projectView.closingDate).getFullYear()}`
                  : ""}
              </small>
            </CardText>
          </div>
          <div style={{ display: "flex", marginTop: "-10px" }}>
            <CardText
              // style={{
              //   marginLeft: "10%",
              //   marginRight: "10%",
              // }}
              style={{ width: "350px" }}
            >
              <i
                className="fa fa-credit-card"
                aria-hidden="true"
                style={{
                  paddingRight: "13px",
                  paddingLeft: "13px",
                }}
              ></i>
              <small style={{ fontSize: "80%" }}>
                {props.projectView.budget &&
                  props.projectView.budget.description}
              </small>
            </CardText>
          </div>
          <Col style={{ marginTop: "7px", marginBottom: "7px" }}>
            <Row>
              <div
                style={{
                  paddingLeft: "3px",
                  paddingRight: "3px",
                }}
              >
                <Badge
                  style={{
                    backgroundColor: "#f5f5fa",
                    color: "#000000",
                    border: "none",
                    fontSize: "85%",
                  }}
                >
                  {props !== null &&
                  props.projectView !== null &&
                  props.projectView.typeOfIndustry !== null &&
                  props.projectView.typeOfIndustry.typeOfIndustryName !== null
                    ? props.projectView.typeOfIndustry.typeOfIndustryName
                    : ""}
                </Badge>
              </div>
              <div
                style={{
                  paddingLeft: "3px",
                  paddingRight: "3px",
                }}
              >
                <Badge
                  style={{
                    backgroundColor: "#f5f5fa",
                    color: "#000000",
                    border: "none",
                    fontSize: "85%",
                  }}
                >
                  {props !== null &&
                  props.projectView !== null &&
                  props.projectView.typeOfIndustry !== null &&
                  props.projectView.typeOfIndustry.typeOfServiceName
                    ? props.projectView.typeOfIndustry.typeOfServiceName
                    : ""}
                </Badge>
              </div>
            </Row>
          </Col>
        </CardBody>
      </Card>
    </>
  );
}

export default ProjectOverviewCard;
