import {config} from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/documentType`;

export const documentService = {
    GetDocumentTypeByLanguageAsync
};

function GetDocumentTypeByLanguageAsync(lan) {
  return fetchWrapper.get(`${baseUrl}/GetDocumentTypeByLanguage/${lan}`);
}


