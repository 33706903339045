import React from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
// reactstrap components
import { Card, CardBody, CardTitle, CardHeader, FormGroup } from "reactstrap";

// core components

function AddressDetails({ props }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  return (
    <Card className={rtl ? "text-right" : ""}>
      <CardHeader className="text-center">
        <CardTitle className="" tag="h4">
          {t("createCompanyProfile.address.diviver")}
        </CardTitle>
        <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
      </CardHeader>
      <CardBody>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.country")}
              </label>

              <label
                className="col-md-6"
                //  style={{ fontWeight: "bold" }}
              >
                {props.countryView.countryName !== null &&
                  props.countryView.countryName}
              </label>
            </div>
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.city")}
              </label>
              <label
                className="col-md-6"
                //    style={{ fontWeight: "bold" }}
              >
                {props.profile.cityID !== null && props.profile.cityID}
              </label>
            </div>
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.address")}
              </label>
              <label className="col-md-6">
                {props.profile.address !== null && props.profile.address}
              </label>
            </div>
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.region")}
              </label>
              <label
                className="col-md-6"
                //    style={{ fontWeight: "bold" }}
              >
                {props.profile.region !== null && props.profile.region}
              </label>
            </div>
          </FormGroup>
        </div>

        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("zip.postal.code")}
              </label>
              <label className="col-md-6">
                {props.profile.zipOrPostalCode !== null &&
                  props.profile.zipOrPostalCode}
              </label>
            </div>
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.phone")}
              </label>
              <label className="col-md-6">
                {props.profile.phoneNumber !== null &&
                  props.profile.phoneNumber.toString()}
              </label>
            </div>
          </FormGroup>
        </div>
      </CardBody>
    </Card>
  );
}

export default AddressDetails;
