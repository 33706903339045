import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import ProjectDetails from "./ProjectDetails";
import PublishedByProfile from "./PublishedByProfile";
import SearchProjects from "./SearchProjects";


function Search({ history, match }) {
  const { path } = match;
  return (
    <>
      <Switch>
        <PrivateRoute exact path={`${path}`} component={SearchProjects} />
        <PrivateRoute
          exact
          path={`${path}/:projectID`}
          component={ProjectDetails}
        />
        <PrivateRoute
          exact
          path={`${path}/:projectID/:profileID`}
          component={PublishedByProfile}
        />

      </Switch>
    </>
  );
}

export { Search };
