import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import EditJob from "./EditJob";
import JobsList from "./JobsList";
import LogsList from "./LogsList";

function SystemJobs({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={JobsList} exact />
        <PrivateRoute path={`${path}/edit/:id`} component={EditJob} />
        <PrivateRoute path={`${path}/viewLogs/:id`} component={LogsList} />
      </Switch>
    </div>
  );
}

export { SystemJobs };
