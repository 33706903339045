import React, { useState, useContext } from "react";
import * as Yup from "yup";
// reactstrap components
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArticleIcon from "@mui/icons-material/Article";
import { Paper, ThemeProvider } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { theme } from "../../../theme.js";
import Switch from "react-bootstrap-switch";
import update from "immutability-helper";
import Modal from "reactstrap/lib/Modal";
import { planService } from "_services/plan.service.js";
import { searchService } from "_services/search.service.js";
import { surveyService } from "_services/survey.service.js";
import { languageService } from "_services/language.service.js";
import { adminService } from "_services/admin.service.js";
import { LanguageContext } from "_context";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Row from "reactstrap/lib/Row";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import { IconButton } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  FileCopyIcon: forwardRef((props, ref) => (
    <FileCopyIcon {...props} ref={ref} />
  )),
};

function SS_POSurveyList({ history, match, location }) {
  const { path } = match;
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowDataID, setRowDataID] = useState(null);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [languages, setLanguages] = useState([]);

  const [itemCount, setItemCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const resetSearch = React.useRef(false);

  let [currentPage, setCurrentPage] = useState(0);
  const userPerPage = pageSize;
  const begin = React.useRef(0);
  const end = React.useRef(userPerPage);

  const count = Math.ceil(itemCount / userPerPage);

  function next() {
    if (end.current > itemCount) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
    begin.current = (currentPage + 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  const handleSizeChange = (value) => {
    setPageSize(parseInt(value));
    end.current = begin.current + parseInt(value);
  };

  const handleChange = async (e, id) => {
    const model = {
      Active: e,
    };

    const surveyIndex = surveys.findIndex((x) => x.surveyId == id);

    surveyService
      .UpdateActiveStatus(id, model)
      .then((x) => {
        setSurveys(
          update(surveys, {
            [surveyIndex]: {
              active: {
                $set: e,
              },
            },
          })
        );
      })
      .catch((error) => {
        console.log("Error:", error);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  };

  var columns = [
    {
      title: t("surveyTitle"),
      field: "surveyTitle",
      width: "5%",
    },
    {
      title: t("SurveyDescription"),
      field: "surveyDescription",
    },
    {
      title: t("surveyAccountNames"),
      field: "surveyAccountNames",
    },
    {
      title: t("surveyType"),
      field: "surveyType",
      width: "1%",
    },
    {
      title: t("creationDate"),
      field: "creationDate",
      width: "10%",
      type: "date",
      dateSetting: { locale: "en-GB" },
    },
    {
      title: t("surveyVersion"),
      field: "version",
      width: "10%",
    },
    {
      title: t("nbResponses"),
      field: "nbResponses",
      width: "10%",
    },
    {
      title: t("surveyQuestions"),
      field: "surveys",
      hidden: true,
    },
    {
      title: t("active"),
      field: "active",

      render: (data) => (
        // console.log("dATA:", data),
        <>
          {console.log("dataaaaaaa:", data)}
          {data.surveyType != "Manually send survey to users." ? (
            <Switch
              //defaultChecked={data.selectedPartner}
              value={data.active}
              id={data.surveyId}
              offColor=""
              disabled={
                data.surveyType == "Manually send survey to users."
                  ? true
                  : false
              }
              offText={t("addProject.projectNDA.false")}
              onColor="brown"
              onText={t("addProject.projectNDA.true")}
              onChange={(e) => {
                // setSelected(!selected);
                // console.log("VVVVVVVVVVVVVVVVVVV:", e.currentTarget);
                //setSelected(!data.selectedPartner);
                handleChange(!data.active, data.surveyId);
              }}
            ></Switch>
          ) : (
            <></>
          )}
        </>
      ),
      width: "10%",
    },
  ];

  function handleRemove(id) {
    const newList = surveys.filter((survey) => survey.surveyId !== id);
    setSurveys(newList);
  }
  async function deleteSelectedItem(id) {
    await surveyService
      .DeleteSurvey(id)
      .then(() => {
        handleRemove(id);
        setDeleteAlert(!deleteAlert);
      })
      .catch((error) => {
        setDeleteAlert(!deleteAlert);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  const handleSearch = async (e) => {
    // setSearchKeyword(e);
    const model = {
      Keyword: e,
      PageNumber: 0,
      PageSize: pageSize,
    };
    await searchService.SearchSsSurveysAsync(model).then((res) => {
      const tmpList =
        res &&
        res.map((survey) => ({
          surveyAccountNames: survey.surveyAccountNames,
          surveyAccountTypes: survey.surveyAccountTypes,
          surveyId: survey.surveyId,
          surveyTitle: survey.surveys[0].surveyTitle,
          surveyDescription: survey.surveys[0].surveyDescription,
          surveys: survey.surveys[0],
          surveyType: survey.surveyType,
          active: survey.active,
          nbResponses: survey.nbResponses,
          version: survey.version,
          creationDate: survey.creationDate,
        }));
      setSurveys(tmpList);
      begin.current = 0;
      end.current = pageSize;
      setPageSize(pageSize);
    });
    await searchService.SearchSsSurveysCountAsync(model).then((res) => {
      console.log(res);
      setItemCount(res);
    });
  };

  const fetchData = async () => {
    if (resetSearch.current == false) setLoading(true);
    await surveyService.GetAllSSSurveys().then((res) => {
      const tmpList =
        res &&
        res.map((survey) => ({
          surveyAccountNames: survey.surveyAccountNames,
          surveyAccountTypes: survey.surveyAccountTypes,
          surveyId: survey.surveyId,
          surveyTitle: survey.surveys[0].surveyTitle,
          surveyDescription: survey.surveys[0].surveyDescription,
          surveys: survey.surveys[0],
          surveyType: survey.surveyType,
          active: survey.active,
          nbResponses: survey.nbResponses,
          version: survey.version,
          creationDate: survey.creationDate,
        }));
      setSurveys(tmpList);
      setItemCount(tmpList.length);
    });

    await languageService.GetAllLanguagesAsync().then((x) => {
      setLanguages(x);
    });
    resetSearch.current = false;
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  // if (loading) {
  //   return (
  //     <>
  //       <center
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           position: "absolute",
  //           left: "50%",
  //           top: "80%",
  //         }}
  //       >
  //         <PulseLoader color="#0E6EB8" />
  //       </center>
  //     </>
  //   );
  // }
  return (
    <>
      <Container>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div className="modal-body col-md-12">
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("delete.alert")}</h5>
          </div>
          <div className="modal-body col-md-12">
            <p>{t("delete.alert.body")}</p>
          </div>

          <div className="modal-footer" style={{ justifyContent: "flex-end" }}>
            <center>
              <Link
                className="mr-2"
                type="button"
                style={{ margin: "auto", color: "#000000" }}
                onClick={(e) => setDeleteAlert(!deleteAlert)}
              >
                {t("cancel.link")}
              </Link>
              <Button
                color="danger"
                type="button"
                className="mr-2"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => deleteSelectedItem(rowDataID)}
              >
                {t("delete")}
              </Button>
            </center>
          </div>
        </Modal>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            width: "100%",
            columnGap: "10rem",
          }}
        >
          <div
            style={{
              color: "#0e6eb8",
              fontSize: "1.25rem",
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
              fontWeight: 500,
              letterSpacing: "0.0075em",
              textTransform: "none",
              marginLeft: "15px",
            }}
          >
            {t("ss&poSurveyList")}
          </div>
          <FormControl
            className="d-flex flex-row align-items-center"
            sx={{ m: 1 }}
            variant="standard"
          >
            <Input
              id="searchInput"
              type="text"
              placeholder={t("search")}
              onChange={(e) => handleSearch(e.currentTarget.value)}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton aria-label="toggle password visibility">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      const searchInput =
                        document.getElementById("searchInput");
                      searchInput.value = "";
                      resetSearch.current = true;
                      fetchData();
                    }}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            <Tooltip title={t("add.button")}>
              <AddBox
                className="actionIcon"
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
                onClick={(event, rowData) => {
                  history.push({
                    pathname: `${path}/add`,
                    languages: languages,
                  });
                }}
              ></AddBox>
            </Tooltip>
          </FormControl>
        </Row>
        {loading && (
          <center
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "50%",
              top: "100%",
            }}
          >
            <PulseLoader color="#0E6EB8" />
          </center>
        )}
        {!loading && (
          <>
            <ThemeProvider
              theme={theme}
              style={rtl ? { textAlign: "right" } : {}}
            >
              <MaterialTable
                //  style={{ backgroundColor: "#2C2C34", color: "#d9dcde" }}
                components={{
                  Container: (props) => (
                    <Paper {...props} className="usersTable" elevation={0} />
                  ),
                }}
                title={<span></span>}
                columns={columns}
                data={surveys}
                icons={tableIcons}
                onSearchChange={handleSearch}
                options={{
                  // pageSize: 10,
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  cellStyle: {
                    textAlign: rtl ? "right" : "left",
                  },
                  headerStyle: {
                    textAlign: rtl ? "right" : "left",
                  },
                }}
                localization={{
                  header: {
                    actions: t("actions"),
                  },
                  toolbar: {
                    searchPlaceholder: t("search"),
                  },
                }}
                actions={[
                  {
                    icon: () => <ArticleIcon className="actionIcon" />,
                    tooltip: t("user.responses"),
                    onClick: (event, rowData) => {
                      history.push(`${path}/responses/${rowData.surveyId}`, {
                        state: rowData,
                        type: "SS&PO",
                      });
                    },
                  },
                  {
                    icon: () => <VisibilityIcon className="actionIcon" />,
                    tooltip: t("surveyPreview"),
                    onClick: (event, rowData) => {
                      history.push(`${path}/preview/${rowData.surveyId}`, {
                        state: rowData,
                        type: "SS&PO",
                      });
                    },
                  },
                  {
                    icon: () => <Edit className="actionIcon" />,
                    tooltip: t("edit"),
                    onClick: (event, rowData) => {
                      history.push(`${path}/edit/${rowData.surveyId}`, {
                        state: rowData,
                        type: "SS&PO",
                      });
                    },
                  },
                  {
                    icon: () => (
                      <Delete
                        className="actionIcon"
                        style={{ fill: "#FF3636" }}
                      />
                    ),
                    tooltip: t("delete"),
                    //isFreeAction: true,
                    onClick: (event, rowData) => {
                      setRowDataID(rowData.surveyId);
                      setDeleteAlert(!deleteAlert);
                    },
                  },
                  // {
                  //   icon: () => (
                  //     <AddBox
                  //       className="actionIcon"
                  //       style={{
                  //         color: "#000000",
                  //       }}
                  //     />
                  //   ),
                  //   tooltip: t("add"),
                  //   isFreeAction: true,
                  //   onClick: (event, rowData) => {
                  //     history.push({
                  //       pathname: `${path}/add`,
                  //       languages: languages,
                  //     });
                  //   },
                  // },
                ]}
              />
            </ThemeProvider>
            <div className="d-flex justify-content-end align-items-center mt-2">
              <select
                value={pageSize}
                onChange={(e) => handleSizeChange(e.target.value)}
                style={{
                  height: "36px",
                  borderRadius: 0,
                  width: "auto",
                  fontSize: "12px",
                }}
                className={"form-control"}
              >
                <option
                  key={5}
                  value={5}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  5
                </option>
                <option
                  key={10}
                  value={10}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  10
                </option>
                <option
                  key={15}
                  value={15}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  15
                </option>
                <option
                  key={20}
                  value={20}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  20
                </option>
                <option
                  key={50}
                  value={50}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  50
                </option>
                <option
                  key={100}
                  value={100}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  100
                </option>
              </select>
              <IconButton
                aria-label="next"
                disabled={currentPage == 0 ? true : false}
              >
                {rtl ? (
                  <ArrowForwardIosSharpIcon
                    onClick={() => prev()}
                    style={{
                      color: "#d9dcde",
                    }}
                  />
                ) : (
                  <ArrowBackIosSharpIcon
                    onClick={() => prev()}
                    style={{
                      color: "#d9dcde",
                    }}
                  />
                )}
              </IconButton>
              <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
                {begin.current} -{" "}
                {end.current > itemCount ? itemCount : end.current}{" "}
                {rtl ? "من" : "of"} {itemCount}
              </p>
              <IconButton
                aria-label="next"
                disabled={end.current >= itemCount ? true : false}
              >
                {rtl ? (
                  <ArrowBackIosSharpIcon
                    onClick={() => next()}
                    style={{
                      color: "#d9dcde",
                    }}
                  />
                ) : (
                  <ArrowForwardIosSharpIcon
                    onClick={() => next()}
                    style={{
                      color: "#d9dcde",
                    }}
                  />
                )}
              </IconButton>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default SS_POSurveyList;
