import React, { useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// pages for this kit
import Index from "views/Index2.jsx";
import { Account } from "components/Account/Index";
import { Profile } from "components/Profile/Index";
import { Provider } from "components/ServiceProvider/Index";
import { Seeker } from "components/ServiceSeeker/Index";
import { Partner } from "components/PartnerOrganization/Index";
import FooterSubPages from "components/LandingPage/FooterSubPages";
import { AdminPanel } from "components/AdminPanel/Index";
import { Team } from "components/Team/Index";
import { Contact } from "components/Contact/Index";
// import { Plans } from "components/Plans/Index";
import { Videos } from "components/Videos/Index";
import { Challenge } from "components/Challenge/Index";
import { UnseenMessagesContextProvider } from "_context";
import { PrivateRoute } from "_helpers/PrivateRoute";
import { AccountTypes } from "_helpers";
import { profileService, accountService } from "_services";
import { useIdleTimer } from "react-idle-timer";
import moment from "moment";
import { Button, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { tokenService } from "_services";
import { requestPermissionAndGetToken } from "../firebase";

function App() {
  const idleTimerRef = React.useRef(null);
  const user = profileService.userValue;
  const [isLoggedIn, setIsLoggedIn] = React.useState(true);
  const [modalAlert, setModalAlert] = React.useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    const loader = document.querySelector(".loader-container");
    loader.classList.add("loader--hide");

    if (localStorage.getItem("time") != null) {
      const lastTimeActive = JSON.parse(localStorage.getItem("time"));
      const startDate = moment(lastTimeActive);
      const timeEnd = moment(new Date());
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);

      console.log(diffDuration);

      if (
        diffDuration._milliseconds > 28800000 &&
        parsed.fromEmail === undefined
      ) {
        setModalAlert(true);
      }
    }
  });

  useEffect(() => {
    console.log(window);
    const checkPermission = async () => {
      if (user != null) {
        if ("Notification" in window) {
          console.log(Notification.permission);
          console.log("permission");
          // if (
          //   Notification.permission !== "granted" &&
          //   Notification.permission !== "denied"
          // ) {
          //   Notification.requestPermission().then((permission) => {
          //     if (permission === "granted") {
          //       const token = localStorage.getItem("token");
          //       console.log(token);
          //       const model = {
          //         UserID: user.profile.userId,
          //         Token: token,
          //       };
          //       tokenService.AddTokenAsync(model).then((x) => {
          //         localStorage.removeItem("token");
          //       });
          //     }
          //   });
          // }
          if (Notification.permission == "granted") {
            await requestPermissionAndGetToken();
            const token = localStorage.getItem("token");
            console.log(token);
            const model = {
              UserID: user.profile.userId,
              Token: token,
            };
            await tokenService.AddTokenAsync(model).then((x) => {
              localStorage.removeItem("token");
            });
          }
        }
      }
    };
    checkPermission();
  }, []);

  const onIdle = () => {
    setModalAlert(true);
  };

  const onPrompt = () => {
    // Fire a Modal Prompt
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  };

  const onAction = (event) => {
    // Do something when a user triggers a watched event
  };

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: 28800000,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  React.useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      localStorage.setItem("time", JSON.stringify(getLastActiveTime()));
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <>
      <Modal isOpen={modalAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-body">
          <p className={rtl ? "text-right" : ""}>{t("session.messageAlert")}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            style={
              rtl ? { margin: "auto", textAlign: "right" } : { margin: "auto" }
            }
            onClick={(e) => {
              setModalAlert(!modalAlert);
              if (localStorage.getItem("time") != null)
                localStorage.removeItem("time");
              if (user != null) profileService.Logout(user.profile.userId);
              else profileService.Logout();
            }}
          >
            {t("label.ok")}
          </Button>
        </div>
      </Modal>

      <Switch>
        <Route path="/account" render={(props) => <Account {...props} />} />
        <Route path="/ourteam" component={(props) => <Team {...props} />} />
        <Route path="/videos" component={(props) => <Videos {...props} />} />
        <Route
          path="/million-dollar-challenge"
          component={(props) => <Challenge {...props} />}
        />
        <Route
          path="/contactus"
          component={(props) => <Contact {...props} />}
        />
        {/* <Route path="/plans" component={(props) => <Plans {...props} />} /> */}
        <Route path="/index" component={Index} />
        <PrivateRoute
          path="/provider"
          roles={[AccountTypes.Provider]}
          component={(props) => <Provider {...props} />}
        />
        <PrivateRoute
          path="/seeker"
          roles={[AccountTypes.Seeker]}
          component={(props) => <Seeker {...props} />}
        />
        <PrivateRoute
          path="/partner"
          roles={[AccountTypes.Partner]}
          component={(props) => <Partner {...props} />}
        />
        <PrivateRoute
          path="/admin"
          roles={[AccountTypes.Admin]}
          component={(props) => <AdminPanel {...props} />}
        />
        <PrivateRoute
          path="/admin"
          roles={[AccountTypes.Admin]}
          component={(props) => <AdminPanel {...props} />}
        />

        <Route path="/profile" render={(props) => <Profile {...props} />} />
        <Route
          path="/footer-sub-pages/:iconPill"
          render={(props) => <FooterSubPages {...props} />}
        />
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </>
  );
}

export { App };
