import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import InviteServiceProviders from "./InviteServiceProviders";

function InviteProviders({ history, match }) {
  const { path } = match;
  return (
    <>
      <Switch>
        <PrivateRoute path={path} component={InviteServiceProviders} />
      </Switch>
    </>
  );
}

export { InviteProviders };
