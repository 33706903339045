import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/surveyResponse`;

export const surveyResponseService = {
  AddSurveyResponse,
  CheckingSurveyValidity,
  GetResponsesBySurveyIDAndUserID,
  GetResponsesByUserID,
};

function AddSurveyResponse(params) {
  return fetchWrapper.post(`${baseUrl}/AddSurveyResponse`, params);
}

function CheckingSurveyValidity(params) {
  return fetchWrapper.post(`${baseUrl}/CheckingSurveyValidity`, params);
}

function GetResponsesByUserID(id) {
  return fetchWrapper.get(`${baseUrl}/GetResponsesByUserID/${id}`);
}

function GetResponsesBySurveyIDAndUserID(params) {
  return fetchWrapper.post(
    `${baseUrl}/GetResponsesBySurveyIDAndUserID`,
    params
  );
}
