import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/transaction`;

export const transactionsService = {
  AddTransaction,
  EditTransaction,
  DeleteTransaction,
  GetAllTransactions,
  GetAllTransactionsCount,
  GetTransaction,
  GetAllUserTransactions,
  GetAllUserTransactionsCount,
  ConfirmTransaction,
  UpdatePaymentRef,
  GetAllUserConfirmedTransactionsCountAsync,
};

function GetAllTransactions(model) {
  return fetchWrapper.post(`${baseUrl}/GetAllTransactions`, model);
}
function GetAllTransactionsCount() {
  return fetchWrapper.get(`${baseUrl}/GetAllTransactionsCount`);
}

function GetTransaction(id) {
  return fetchWrapper.get(`${baseUrl}/GetTransaction/${id}`);
}

function AddTransaction(model) {
  return fetchWrapper.post(`${baseUrl}/AddTransaction`, model);
}

function DeleteTransaction(id) {
  return fetchWrapper.delete(`${baseUrl}/DeleteTransaction/${id}`);
}

function EditTransaction(id, model) {
  return fetchWrapper.put(`${baseUrl}/EditTransaction/${id}`, model);
}

function GetAllUserTransactions(userId, model) {
  return fetchWrapper.post(
    `${baseUrl}/GetAllUserTransactions/${userId}`,
    model
  );
}

function GetAllUserTransactionsCount(userId) {
  return fetchWrapper.get(`${baseUrl}/GetAllUserTransactionsCount/${userId}`);
}
function ConfirmTransaction(id, bool) {
  return fetchWrapper.put(`${baseUrl}/ConfirmTransaction/${id}/${bool}`);
}

function UpdatePaymentRef(model) {
  return fetchWrapper.put(`${baseUrl}/UpdatePaymentRef`, model);
}
function GetAllUserConfirmedTransactionsCountAsync(id) {
  return fetchWrapper.get(
    `${baseUrl}/GetAllUserConfirmedTransactionsCount/${id}`
  );
}
