import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/emailLogs`;

export const emailLogsService = {
  GetAllAsync,
  GetEmailLogAsync,
  DeleteEmailLogAsync,
  UpdateEmailLogsAsync,
  AddEmailLogsAsync,
  GetAlertsLogCount,
  GetNewslettersLogCount,
  GetDirectLinksAlertsLogsCount,
  GetAllDirectLink,
  GetSurveysCount,
  GetMembershipsCount,
  GetTransactionsCount,
};

function GetAllAsync(model) {
  return fetchWrapper.post(`${baseUrl}/getAll`, model);
}

function GetAllDirectLink(model) {
  return fetchWrapper.post(`${baseUrl}/getAllDirectLink`, model);
}

function GetEmailLogAsync(model) {
  return fetchWrapper.post(`${baseUrl}/getOneEmailLog`, model);
}

function DeleteEmailLogAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function UpdateEmailLogsAsync(model) {
  return fetchWrapper.put(`${baseUrl}`, model);
}

function AddEmailLogsAsync(model) {
  return fetchWrapper.post(`${baseUrl}`, model);
}

function GetAlertsLogCount() {
  return fetchWrapper.get(`${baseUrl}/getAlertsCount`);
}

function GetNewslettersLogCount() {
  return fetchWrapper.get(`${baseUrl}/getNewslettersCount`);
}

function GetDirectLinksAlertsLogsCount() {
  return fetchWrapper.get(`${baseUrl}/getDirectLinksAlertsLogsCount`);
}

function GetSurveysCount() {
  return fetchWrapper.get(`${baseUrl}/getSurveysCount`);
}

function GetTransactionsCount() {
  return fetchWrapper.get(`${baseUrl}/GetTransactionsCount`);
}

function GetMembershipsCount() {
  return fetchWrapper.get(`${baseUrl}/GetMembershipsCount`);
}
