import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import ProfilePage from "../Profile/CompanyProfilePage/ProfilePage";
import SeekerNavbar from "components/Navbars/SeekerNavbar";
import { Search } from "./Search/Index";
import { Project } from "./Projects/index";
import { AccountSettings } from "components/Profile/AccountSettings/Index";
import Message from "components/Messages/messaging";
import EditProfile from "components/Profile/EditProfile";
import { SearchProviderContextProvider } from "_context";
import { SearchListContextProvider } from "_context";
import { useMediaQuery } from "react-responsive";
import SeekerNavbarMobileTablet from "components/Navbars/SeekerNavbarMobileTablet";
import { SpSeen } from "./SpSeen/Index";
import { AccountTypes } from "_helpers";
import { DirectLinks } from "./DirectLinks/Index";
import { SurveyResponse } from "./SurveyResponse";
// import MembershipHistoryPage from "components/Profile/CompanyProfilePage/Membership/MembershipHistoryPage";
// import ActiveMembership from "components/Profile/CompanyProfilePage/Membership/ActiveMembership";
// import { Insights } from "components/Profile/CompanyProfilePage/Insights/Index";
import { Survey } from "./Survey/Index";

function Seeker({ history, match }) {
  const { path } = match;
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });

  return (
    <>
      {isDesktopOrLaptop && <SeekerNavbar props={history} />}
      {!isDesktopOrLaptop && <SeekerNavbarMobileTablet props={history} />}
      <Switch>
        <SearchProviderContextProvider>
          <SearchListContextProvider>
            <PrivateRoute
              path={`${path}/profile-page`}
              component={ProfilePage}
              roles={[AccountTypes.Seeker]}
              exact
            />

            <PrivateRoute
              path={`${path}/profile-page/edit`}
              component={EditProfile}
              roles={[AccountTypes.Seeker]}
              exact
            />
            <PrivateRoute path={`${path}/search-provider`} component={Search} />
            <PrivateRoute path={`${path}/projects`} component={Project} />
            <PrivateRoute
              path={`${path}/accountSettings`}
              roles={[AccountTypes.Seeker]}
              component={AccountSettings}
            />
            <PrivateRoute path={`${path}/messaging/:id?`} component={Message} />
            <PrivateRoute path={`${path}/seenSP`} component={SpSeen} />
            <PrivateRoute path={`${path}/pending`} component={Survey} />
            <PrivateRoute
              path={`${path}/directLinks`}
              component={DirectLinks}
            />
            <PrivateRoute path={`${path}/survey`} component={SurveyResponse} />
            {/* <PrivateRoute
              path={`${path}/profile-page/membershipHistory`}
              component={MembershipHistoryPage}
            />
            <PrivateRoute
              path={`${path}/profile-page/activeMembership`}
              component={ActiveMembership}
              exact
            />
            <PrivateRoute
              path={`${path}/profile-page/Insights`}
              component={Insights}
            /> */}
          </SearchListContextProvider>
        </SearchProviderContextProvider>
      </Switch>
    </>
  );
}

export { Seeker };
