import React, { useEffect, useState, useContext } from "react";
//import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { AccountTypes } from "../../_helpers";
import { LanguageContext } from "../../_context";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Col,
  CardTitle,
  Container,
  Modal,
  Row,
} from "reactstrap";
import { profileService } from "../../_services";
import { Link } from "react-router-dom";
import CardSubtitle from "reactstrap/lib/CardSubtitle";
import queryString from "query-string";

// core components

function UpgradeAccountForm({ history, location }) {
  const user = profileService.userValue;
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const parsed = queryString.parse(location.search);

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    // document.body.style = "background-color: #f5f5fa";
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const passwordRegex =
    /^(?=.*[A-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])\S{6,100}$/;

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t("signUp.email.error")),
    password: Yup.string()
      .concat(
        showPassword
          ? Yup.string().required(t("signUp.password.required"))
          : null
      )
      .min(6, t("signUp.password.regex"))
      .matches(passwordRegex, t("signUp.password.regex")),
    confirmPassword: Yup.string()
      .when("password", (password, schema) => {
        if (password)
          return schema.required(t("signUp.confirmPassword.required"));
      })
      .oneOf([Yup.ref("password")], t("signUp.confirmPassword.match")),
  });

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  const [currentLan, setCurrentLan] = useContext(LanguageContext);

  const [successAlert, setSuccessAlert] = useState(false);

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    const newEmailModel = {
      oldEmail: parsed.email,
      title: "Mr.",
      firstName: fields.email,
      lastName: fields.email,
      newEmail: fields.email,
      password: fields.password,
      confirmPassword: fields.confirmPassword,
    };
    const model = {
      email: fields.email,
      language: currentLan,
    };
    await profileService
      .UpgradeSeedStageEmailAsync(newEmailModel)
      .then(async (user) => {
        setSuccessAlert(!successAlert);
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName === currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  return (
    <>
      <div
        className="section section-signup "
        style={{
          backgroundColor: "#f5f5fa",
          backgroundSize: "cover",
          marginTop: "50px",
        }}
      >
        <Container style={{ marginTop: "-5%" }}>
          <Modal
            isOpen={successAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("done")}</h5>
            </div>
            <div className={rtl ? "modal-body text-right" : "modal-body"}>
              <p>{t("upgrade.successful.partone")}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={async (e) => {
                  await profileService.Logout(user.profile.userId);
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={errorAlert}
            style={rtl ? { textAlign: "right" } : {}}
            v
          >
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
              }
            >
              <p>{errorMessage}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>

          <>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                // useEffect(() => {
                //   let mounted = true;
                //   return () => (mounted = false);
                // }, []);

                async function CheckEmail() {
                  setIsCheckingEmail(true);
                  const model = {
                    email: values.email,
                  };
                  await profileService
                    .GetUserByEmail(model)
                    .then((user) => {
                      if (user === false) {
                        setIsCheckingEmail(false);
                        setShowPassword(true);
                      } else {
                        UpgradeExisitingUser();
                        // setIsCheckingEmail(false);
                      }
                    })
                    .catch((error) => {
                      setIsCheckingEmail(false);
                      if (error.message !== undefined) {
                        setError(error.message);
                        if (error.error !== null && error.error.length > 0) {
                          for (var i = 0; i < error.error.length; i++) {
                            if (error.error[i].languageName === currentLan) {
                              setError(error.error[i].error);
                            }
                          }
                        }
                      } else {
                        setError(error);
                      }
                      setErrorAlert(!errorAlert);
                    });
                }

                async function UpgradeExisitingUser() {
                  const existingEmailModel = {
                    oldEmail: parsed.email,
                    newEmail: values.email,
                  };
                  await profileService
                    .UpgradeSeedStageEmailAsync(existingEmailModel)
                    .then(async (user) => {
                      setSuccessAlert(!successAlert);
                      setIsCheckingEmail(false);
                    })
                    .catch((error) => {
                      setIsCheckingEmail(false);
                      if (error.message !== undefined) {
                        setError(error.message);
                        if (
                          error.error !== null &&
                          error.error !== undefined &&
                          error.error.length > 0
                        ) {
                          for (var i = 0; i < error.error.length; i++) {
                            if (error.error[i].languageName === currentLan) {
                              setError(error.error[i].error);
                            }
                          }
                        }
                      } else {
                        setError(error);
                      }
                      setErrorAlert(!errorAlert);
                    });
                }

                return (
                  <Col md="8" style={{ margin: "auto" }}>
                    <Card className={rtl ? "text-right" : ""}>
                      <Form
                        autoComplete="off"
                        // style={{
                        //   margin: "0 auto",
                        //   width: "100%",
                        //   padding: "10px",
                        // }}
                      >
                        <CardBody>
                          <CardTitle className="text-center" tag="h4">
                            {t("upgradeStartupAccount")}
                          </CardTitle>
                          <CardSubtitle className="mb-2 text-muted text-center">
                            {t("upgradeAccountSubtitle")}
                          </CardSubtitle>
                          <Row>
                            <Col md="12">
                              <div
                                className="form-row"
                                //style={{ width: "max-content" }}
                              >
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                  }}
                                >
                                  <label htmlFor="inputEmail4">
                                    {t("signUp.email")}
                                  </label>

                                  <Field
                                    autoFocus={true}
                                    name="email"
                                    disabled={showPassword}
                                    placeholder={t("signUp.email")}
                                    type="text"
                                    onChange={(e) =>
                                      setFieldValue(
                                        "email",
                                        e.target.value,
                                        false
                                      )
                                    }
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  {errors.email && touched.email ? (
                                    <ErrorMessage
                                      name="email"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  hidden={!showPassword}
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("signUp.password")}
                                  </label>

                                  <Field
                                    name="password"
                                    placeholder={t("signUp.password")}
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  {errors.password && touched.password ? (
                                    <ErrorMessage
                                      name="password"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  hidden={!showPassword}
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("signUp.confirmPassword")}
                                  </label>

                                  <Field
                                    name="confirmPassword"
                                    placeholder={t("signUp.confirmPassword")}
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.confirmPassword &&
                                      touched.confirmPassword
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  {errors.confirmPassword &&
                                  touched.confirmPassword ? (
                                    <ErrorMessage
                                      name="confirmPassword"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <center>
                                    <Button
                                      hidden={showPassword}
                                      color="primary"
                                      type="button"
                                      //   disabled={!isValid}
                                      onClick={() => {
                                        setIsCheckingEmail(true);
                                        CheckEmail();
                                      }}
                                    >
                                      {isCheckingEmail && (
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      )}
                                      {t("next.button")}
                                    </Button>
                                    <Button
                                      hidden={!showPassword}
                                      color="primary"
                                      type="submit"
                                      disabled={isSubmitting || !showPassword}
                                    >
                                      {isSubmitting && (
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      )}
                                      {t("upgrade.button")}
                                    </Button>
                                  </center>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Form>
                    </Card>
                  </Col>
                );
              }}
            </Formik>
          </>
        </Container>
      </div>
    </>
  );
}

export default UpgradeAccountForm;
