import React, { useContext } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import LanguageDropdown from "./LanguageDropdown";
import { LanguageContext } from "../../_context";
import { useTranslation } from "react-i18next";
import { profileService } from "_services";

function AdminNavbar({ props }) {
  const user = profileService.userValue;

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [currentLan, setCurrentLan] = useContext(LanguageContext);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {/* {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null} */}
      <Navbar
        className={"fixed-top shadow-none"}
        expand="lg"
        color="white"
        style={{
          paddingLeft: "70px",
          paddingRight: "70px",
          boxShadow: "none !important",
        }}
      >
        <div className="navbar-translate">
          <NavbarBrand
            //   href="https://demos.creative-tim.com/now-ui-kit-react/#/index?ref=nukr-index-navbar"
            //  target="_blank"
            id="navbar-brand"
            href="/"
            className="pl-5"
            style={{
              width: "193px",
              height: "48px",
            }}
          >
            {/* Now UI Kit React */}
            {/* <img
                //   src={require("assets/img/outerpond-logo-nobg.png").default}
                // src={require("assets/img/outerpond-logo-resized.png").default}
                src={require("assets/img/Outerpond-Logo-168x42.png").default}
                alt=""
                // height="35px"
                // width="100px"
              /> */}
            <img
              src={require("assets/img/Outerpond-Logo.png").default}
              alt=""
            />
          </NavbarBrand>
          <UncontrolledTooltip target="#navbar-brand">
            Outerpond
          </UncontrolledTooltip>
          <button
            className="navbar-toggler navbar-toggler"
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
              setCollapseOpen(!collapseOpen);
            }}
            aria-expanded={collapseOpen}
            type="button"
          >
            <span className="navbar-toggler-bar top-bar"></span>
            <span className="navbar-toggler-bar middle-bar"></span>
            <span className="navbar-toggler-bar bottom-bar"></span>
          </button>
        </div>
        <Collapse className="justify-content-end" isOpen={collapseOpen} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink
                style={{ fontFamily: "SpaceGrotesk" }}
                onClick={() => {
                  props.push("/admin");
                }}
                tag={Link}
              >
                {t("adminPanel.tab")}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ fontFamily: "SpaceGrotesk" }}
                onClick={() => {
                  props.push("/admin/cms");
                }}
                tag={Link}
              >
                {t("adminPanel.CMStab")}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ fontFamily: "SpaceGrotesk" }}
                onClick={() => {
                  props.push("/admin/analytics");
                }}
                tag={Link}
              >
                {t("adminPanel.AnalyticsTab")}
              </NavLink>
            </NavItem>

            <UncontrolledDropdown nav>
              <DropdownToggle
                caret
                color="default"
                href="#pablo"
                nav
                style={{ fontFamily: "SpaceGrotesk" }}
                onClick={(e) => e.preventDefault()}
              >
                <i className="now-ui-icons users_single-02"></i>
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby="navbarDropdown"
                className={rtl ? "dropdown-menu-left" : "dropdown-menu-right"}
              >
                {/* <DropdownItem header tag="p" style={{ fontWeight: "bold" }}>
          Profile
        </DropdownItem> */}
                <DropdownItem
                  //  header
                  disabled
                  tag="p"
                  className="text-center"
                  style={{ fontWeight: "bold", fontFamily: "SpaceGrotesk" }}
                >
                  {user.profile.userName}
                  <br></br>
                  {user.profileTypeView.profileTypeName}
                </DropdownItem>
                <DropdownItem
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    props.push("/admin/account/change-password?source=admin");
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                  }}
                >
                  {t("accountDropdown.accountSettings")}
                </DropdownItem>
                <DropdownItem
                  style={{ fontFamily: "SpaceGroteskBold" }}
                  onClick={(e) => profileService.Logout(user.profile.userId)}
                >
                  {t("logout")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <LanguageDropdown />
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
