import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { emailLogsService } from "_services";
import { Paper, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../theme.js";
import { Button, Container, Modal, Row } from "reactstrap";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import { IconButton } from "@material-ui/core";
import moment from "moment";
import { AlertTypes } from "_helpers";
import FormGroup from "reactstrap/lib/FormGroup";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { searchService } from "_services";
import PulseLoader from "react-spinners/PulseLoader";

function AlertsEmailLogsCard({
  history,
  path,
  tableIcons,
  itemCount,
  directLinkItemCount,
  changeItemCount,
  changeDirectLinkItemCount,
  getAlertsLogCount,
  getDirectLinkItemCount,
}) {
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowDataID, setRowDataID] = useState(null);
  const [alertLogs, setAlertLogs] = useState([]);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [opportunityType, setOpportunityType] = useState(
    t("listedOpportunities")
  );
  const [pageSize, setPageSize] = useState(5);
  const resetSearch = React.useRef(false);
  const [loading, setLoading] = useState(true);

  console.log(itemCount);

  var columns = [
    {
      title: t("emailLog.user"),
      field: "emailLogsId",
      hidden: true,
    },
    {
      title: t("emailLog.user"),
      field: "opportunityID",
      hidden: true,
    },
    {
      title: t("emailLog.user"),
      field: "userID",
      hidden: true,
    },
    {
      title: t("emailLog.opportunity"),
      field: "opportunityLabel",
      hidden: true,
    },
    {
      title: t("emailLog.user"),
      field: "email",
    },
    {
      title: t("emailLog.alert"),
      field: "alertType",
    },
    {
      title: t("emailLog.opportunity"),
      field: "opportunity",
    },
    {
      title: t("partner.list.sent.invitations.date"),
      field: "date",
    },
    {
      title: t("emailLog.status"),
      field: "status",
    },
    {
      title: t("emailLog.reason"),
      field: "reason",
    },
  ];

  let [currentPage, setCurrentPage] = useState(0);
  const emailLogsPerPage = pageSize;
  const begin = React.useRef(0);
  const end = React.useRef(emailLogsPerPage);

  const count =
    opportunityType == t("listedOpportunities")
      ? Math.ceil(itemCount / emailLogsPerPage)
      : Math.ceil(directLinkItemCount / emailLogsPerPage);

  function next() {
    if (opportunityType == t("listedOpportunities")) {
      if (end.current > itemCount) return;
      setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
      begin.current = (currentPage + 1) * emailLogsPerPage;
      end.current = begin.current + emailLogsPerPage;
    } else {
      if (end.current > directLinkItemCount) return;
      setCurrentPage((currentPage) =>
        Math.min(currentPage + 1, directLinkItemCount)
      );
      begin.current = (currentPage + 1) * emailLogsPerPage;
      end.current = begin.current + emailLogsPerPage;
    }
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * emailLogsPerPage;
    end.current = begin.current + emailLogsPerPage;
  }

  async function deleteSelectedItem(id) {
    await emailLogsService
      .DeleteEmailLogAsync(id)
      .then(() => {
        // handleRemove(id);
        fetchAlertData();
        if (opportunityType == t("listedOpportunities"))
          changeItemCount(itemCount - 1);
        else changeDirectLinkItemCount(directLinkItemCount - 1);
        setDeleteAlert(!deleteAlert);
      })
      .catch((error) => {
        setDeleteAlert(!deleteAlert);
        if (error.message !== undefined) {
          setError(error.message);
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  const handleChange = (value) => {
    setPageSize(parseInt(value));
    end.current = begin.current + parseInt(value);
  };

  useEffect(() => {
    fetchAlertData();
  }, [end.current, begin.current, pageSize]);

  const fetchAlertData = async () => {
    if (resetSearch.current == false) setLoading(true);
    if (opportunityType == t("listedOpportunities")) {
      let skipEmailLogs = begin.current;
      const model = {
        index: 1,
        emailLogsToSkip: skipEmailLogs,
        startingEmailLogs: emailLogsPerPage,
      };

      await emailLogsService.GetAllAsync(model).then((res) => {
        const tmpList =
          res &&
          res.map((email) => ({
            email: email.userEmail,
            alertType:
              email.alertType == "NewOpp"
                ? AlertTypes.NewOpp
                : email.alertType == "ClosingDateOppSS"
                ? AlertTypes.ClosingDateOppSS
                : email.alertType == "ClosingDateOppSP"
                ? AlertTypes.ClosingDateOppSP
                : email.alertType == "AdminDispatch"
                ? AlertTypes.AdminDispatch
                : email.alertType,
            opportunity: email.asProject[0].projectTitle,
            opportunityID: email.opportunityID,
            opportunityLabel: email.alertType,
            status:
              email.status == "P"
                ? "Pending"
                : email.status == "S"
                ? "Sent"
                : email.status == "F"
                ? "Failed"
                : email.status,
            reason: email.reason,
            emailLogsId: email.emailLogsId,
            date: moment(email.date).format("YYYY-MM-DD, h:mm:ss a"),
            userID: email.userID,
          }));
        setAlertLogs(tmpList);
      });
    } else {
      let skipEmailLogs = begin.current;
      const model = {
        emailLogsToSkip: skipEmailLogs,
        startingEmailLogs: emailLogsPerPage,
      };
      await emailLogsService.GetAllDirectLink(model).then((res) => {
        const tmpList =
          res &&
          res.map((email) => ({
            email: email.userEmail,
            alertType:
              email.alertType == "NewOpp"
                ? AlertTypes.NewOpp
                : email.alertType == "ClosingDateOppSS"
                ? AlertTypes.ClosingDateOppSS
                : email.alertType == "ClosingDateOppSP"
                ? AlertTypes.ClosingDateOppSP
                : email.alertType == "AdminDispatch"
                ? AlertTypes.AdminDispatch
                : email.alertType,
            opportunity: email.asDirectLink[0].title,
            opportunityID: email.opportunityID,
            opportunityLabel: email.alertType,
            status:
              email.status == "P"
                ? "Pending"
                : email.status == "S"
                ? "Sent"
                : email.status == "F"
                ? "Failed"
                : email.status,
            reason: email.reason,
            emailLogsId: email.emailLogsId,
            date: moment(email.date).format("YYYY-MM-DD, h:mm:ss a"),
            userID: email.userID,
          }));
        setAlertLogs(tmpList);
      });
    }

    setLoading(false);
    resetSearch.current = false;
  };

  const handleSearch = async (e) => {
    if (opportunityType == t("listedOpportunities")) {
      const model = {
        Keyword: e,
        PageNumber: 0,
        PageSize: pageSize,
        Index: 1,
      };
      await searchService.SearchEmailLogsAsync(model).then((res) => {
        const tmpList =
          res &&
          res.map((email) => ({
            email: email.userEmail,
            alertType:
              email.alertType == "NewOpp"
                ? AlertTypes.NewOpp
                : email.alertType == "ClosingDateOppSS"
                ? AlertTypes.ClosingDateOppSS
                : email.alertType == "ClosingDateOppSP"
                ? AlertTypes.ClosingDateOppSP
                : email.alertType == "AdminDispatch"
                ? AlertTypes.AdminDispatch
                : email.alertType,
            opportunity: email.asProject[0].projectTitle,
            opportunityID: email.opportunityID,
            opportunityLabel: email.alertType,
            status:
              email.status == "P"
                ? "Pending"
                : email.status == "S"
                ? "Sent"
                : email.status == "F"
                ? "Failed"
                : email.status,
            reason: email.reason,
            emailLogsId: email.emailLogsId,
            date: moment(email.date).format("YYYY-MM-DD, h:mm:ss a"),
            userID: email.userID,
          }));
        setAlertLogs(tmpList);
        begin.current = 0;
        end.current = pageSize;
        setPageSize(pageSize);
      });
      await searchService.SearchEmailLogsCountAsync(model).then((res) => {
        changeItemCount(res);
      });
    } else {
      const model = {
        Keyword: e,
        PageNumber: 0,
        PageSize: pageSize,
        Index: 6,
      };
      await searchService.SearchEmailLogsAsync(model).then((res) => {
        const tmpList =
          res &&
          res.map((email) => ({
            email: email.userEmail,
            alertType:
              email.alertType == "NewOpp"
                ? AlertTypes.NewOpp
                : email.alertType == "ClosingDateOppSS"
                ? AlertTypes.ClosingDateOppSS
                : email.alertType == "ClosingDateOppSP"
                ? AlertTypes.ClosingDateOppSP
                : email.alertType == "AdminDispatch"
                ? AlertTypes.AdminDispatch
                : email.alertType,
            opportunity: email.asDirectLink[0].title,
            opportunityID: email.opportunityID,
            opportunityLabel: email.alertType,
            status:
              email.status == "P"
                ? "Pending"
                : email.status == "S"
                ? "Sent"
                : email.status == "F"
                ? "Failed"
                : email.status,
            reason: email.reason,
            emailLogsId: email.emailLogsId,
            date: moment(email.date).format("YYYY-MM-DD, h:mm:ss a"),
            userID: email.userID,
          }));
        setAlertLogs(tmpList);
        begin.current = 0;
        end.current = pageSize;
        setPageSize(pageSize);
      });
      await searchService.SearchEmailLogsCountAsync(model).then((res) => {
        changeDirectLinkItemCount(res);
      });
    }
  };

  function handleRemove(id) {
    const newList = alertLogs.filter((item) => item.emailLogsId !== id);
    setAlertLogs(newList);
    if (opportunityType == t("listedOpportunities"))
      changeItemCount(itemCount - 1);
    else changeDirectLinkItemCount(directLinkItemCount - 1);
  }

  const handleChangePage = (event, newPageNumber) => {};

  return (
    <div>
      <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("delete.alert")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>{t("delete.alert.body")}</p>
        </div>

        <div className="modal-footer" style={{ justifyContent: "flex-end" }}>
          <center>
            <Link
              className="mr-2"
              type="button"
              style={{ margin: "auto", color: "#000000" }}
              onClick={() => setDeleteAlert(!deleteAlert)}
            >
              {t("cancel.link")}
            </Link>
            <Button
              color="danger"
              type="button"
              className="mr-2"
              style={{ margin: "auto" }}
              onClick={() => deleteSelectedItem(rowDataID)}
            >
              {t("delete")}
            </Button>
          </center>
        </div>
      </Modal>
      <Container>
        <UncontrolledDropdown style={{ marginTop: "10px" }}>
          <DropdownToggle
            aria-expanded={false}
            aria-haspopup={true}
            caret
            color="primary"
            data-toggle="dropdown"
            id="dropdownMenuButton"
            type="button"
          >
            {opportunityType}
          </DropdownToggle>
          <DropdownMenu aria-labelledby="dropdownMenuButton">
            <DropdownItem
              //  href="#pablo"
              onClick={async (e) => {
                setLoading(true);
                setOpportunityType(t("listedOpportunities"));
                begin.current = 0;
                end.current = pageSize;
                setCurrentPage(0);
                let skipEmailLogs = begin.current;
                const model = {
                  index: 1,
                  emailLogsToSkip: skipEmailLogs,
                  startingEmailLogs: emailLogsPerPage,
                };
                await emailLogsService.GetAllAsync(model).then((res) => {
                  const tmpList =
                    res &&
                    res.map((email) => ({
                      email: email.userEmail,
                      alertType:
                        email.alertType == "NewOpp"
                          ? AlertTypes.NewOpp
                          : email.alertType == "ClosingDateOppSS"
                          ? AlertTypes.ClosingDateOppSS
                          : email.alertType == "ClosingDateOppSP"
                          ? AlertTypes.ClosingDateOppSP
                          : email.alertType == "AdminDispatch"
                          ? AlertTypes.AdminDispatch
                          : email.alertType,
                      opportunity: email.asProject[0].projectTitle,
                      opportunityID: email.opportunityID,
                      opportunityLabel: email.alertType,
                      status:
                        email.status == "P"
                          ? "Pending"
                          : email.status == "S"
                          ? "Sent"
                          : email.status == "F"
                          ? "Failed"
                          : email.status,
                      reason: email.reason,
                      emailLogsId: email.emailLogsId,
                      date: moment(email.date).format("YYYY-MM-DD, h:mm:ss a"),
                      userID: email.userID,
                    }));
                  setAlertLogs(tmpList);
                });

                setLoading(false);
              }}
            >
              {t("listedOpportunities")}
            </DropdownItem>
            <DropdownItem
              //  href="#pablo"
              onClick={async (e) => {
                setLoading(true);
                setOpportunityType(t("linkedOpportunities"));
                begin.current = 0;
                end.current = pageSize;
                setCurrentPage(0);
                let skipEmailLogs = begin.current;
                const model = {
                  emailLogsToSkip: skipEmailLogs,
                  startingEmailLogs: emailLogsPerPage,
                };
                await emailLogsService.GetAllDirectLink(model).then((res) => {
                  const tmpList =
                    res &&
                    res.map((email) => ({
                      email: email.userEmail,
                      alertType:
                        email.alertType == "NewOpp"
                          ? AlertTypes.NewOpp
                          : email.alertType == "ClosingDateOppSS"
                          ? AlertTypes.ClosingDateOppSS
                          : email.alertType == "ClosingDateOppSP"
                          ? AlertTypes.ClosingDateOppSP
                          : email.alertType == "AdminDispatch"
                          ? AlertTypes.AdminDispatch
                          : email.alertType,
                      opportunity: email.asDirectLink[0].title,
                      opportunityID: email.opportunityID,
                      opportunityLabel: email.alertType,
                      status:
                        email.status == "P"
                          ? "Pending"
                          : email.status == "S"
                          ? "Sent"
                          : email.status == "F"
                          ? "Failed"
                          : email.status,
                      reason: email.reason,
                      emailLogsId: email.emailLogsId,
                      date: moment(email.date).format("YYYY-MM-DD, h:mm:ss a"),
                      userID: email.userID,
                    }));
                  setAlertLogs(tmpList);
                });

                setLoading(false);
              }}
            >
              {t("linkedOpportunities")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        {/* <FormGroup className="col-md-12">
          <select
            style={{
              fontSize: "14px",
            }}
            name="type"
            className="form-control"
            onChange={(e) => {
              setOpportunityType(e.target.value);
            }}
          >
            <option value={t("listedOpportunities")}>
              {t("listedOpportunities")}
            </option>
            <option value={t("linkedOpportunities")}>
              {t("linkedOpportunities")}
            </option>
          </select>
        </FormGroup> */}
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            width: "100%",
            columnGap: "10rem",
          }}
        >
          <div
            style={{
              color: "#0e6eb8",
              fontSize: "1.25rem",
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
              fontWeight: 500,
              letterSpacing: "0.0075em",
              textTransform: "none",
              marginLeft: "15px",
            }}
          >
            {t("emailLogsList")}
          </div>
          <FormControl sx={{ m: 1 }} variant="standard">
            <Input
              id="searchInput"
              type="text"
              placeholder={t("search")}
              onChange={(e) => handleSearch(e.currentTarget.value)}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton aria-label="toggle password visibility">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      const searchInput =
                        document.getElementById("searchInput");
                      searchInput.value = "";
                      resetSearch.current = true;
                      fetchAlertData();
                      if (opportunityType == t("listedOpportunities"))
                        getAlertsLogCount();
                      else getDirectLinkItemCount();
                    }}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Row>
        {loading && (
          <center
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "50%",
              top: "100%",
            }}
          >
            <PulseLoader color="#0E6EB8" />
          </center>
        )}
        {!loading && (
          <>
            <ThemeProvider theme={theme}>
              <MaterialTable
                //  style={{ backgroundColor: "#2C2C34", color: "#d9dcde" }}
                components={{
                  Container: (props) => (
                    <Paper {...props} className="usersTable" elevation={0} />
                  ),
                }}
                title={<span></span>}
                columns={columns}
                data={alertLogs}
                icons={tableIcons}
                onSearchChange={handleSearch}
                options={{
                  // pageSize: 10,
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  cellStyle: {
                    textAlign: rtl ? "right" : "left",
                  },
                  headerStyle: {
                    textAlign: rtl ? "right" : "left",
                  },
                }}
                localization={{
                  header: {
                    actions: t("actions"),
                  },
                  toolbar: {
                    searchPlaceholder: t("search"),
                  },
                }}
                actions={[
                  {
                    icon: () => (
                      <Edit
                        className="actionIcon"
                        style={{
                          color: "#0E6EB8",
                        }}
                      />
                    ),
                    tooltip: t("edit"),
                    //isFreeAction: true,
                    onClick: (event, rowData) => {
                      history.push(`${path}/edit/${rowData.emailLogsId}`, {
                        state: { rowData: rowData, index: 1 },
                        type: "Alert",
                      });
                    },
                  },
                  ,
                  {
                    icon: () => (
                      <Delete
                        style={{ fill: "#FF3636" }}
                        className="actionIcon"
                      />
                    ),
                    tooltip: t("delete"),
                    onClick: (event, rowData) => {
                      setRowDataID(rowData.emailLogsId);
                      setDeleteAlert(!deleteAlert);
                    },
                  },
                ]}
              />
            </ThemeProvider>
            {opportunityType == t("listedOpportunities") ? (
              <div className="d-flex justify-content-end align-items-center">
                <select
                  value={pageSize}
                  onChange={(e) => handleChange(e.target.value)}
                  style={{
                    height: "36px",
                    borderRadius: 0,
                    width: "auto",
                    fontSize: "12px",
                  }}
                  className={"form-control"}
                >
                  <option
                    key={5}
                    value={5}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    5
                  </option>
                  <option
                    key={10}
                    value={10}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    10
                  </option>
                  <option
                    key={15}
                    value={15}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    15
                  </option>
                  <option
                    key={20}
                    value={20}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    20
                  </option>
                </select>
                <IconButton
                  aria-label="next"
                  disabled={currentPage == 0 ? true : false}
                >
                  {rtl ? (
                    <ArrowForwardIosSharpIcon
                      onClick={() =>
                        history.push("..", {
                          type: "Alert",
                        })
                      }
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  ) : (
                    <ArrowBackIosSharpIcon
                      onClick={() =>
                        history.push("..", {
                          type: "Alert",
                        })
                      }
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  )}
                </IconButton>
                <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
                  {begin.current} -{" "}
                  {end.current > itemCount ? itemCount : end.current}{" "}
                  {rtl ? "من" : "of"} {itemCount}
                </p>
                <IconButton
                  aria-label="next"
                  disabled={end.current >= itemCount ? true : false}
                >
                  {rtl ? (
                    <ArrowBackIosSharpIcon
                      onClick={() => next()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  ) : (
                    <ArrowForwardIosSharpIcon
                      onClick={() => next()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  )}
                </IconButton>
              </div>
            ) : (
              <div className="d-flex justify-content-end align-items-center">
                <select
                  value={pageSize}
                  onChange={(e) => handleChange(e.target.value)}
                  style={{
                    height: "36px",
                    borderRadius: 0,
                    width: "auto",
                    fontSize: "12px",
                  }}
                  className={"form-control"}
                >
                  <option
                    key={5}
                    value={5}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    5
                  </option>
                  <option
                    key={10}
                    value={10}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    10
                  </option>
                  <option
                    key={15}
                    value={15}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    15
                  </option>
                  <option
                    key={20}
                    value={20}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    20
                  </option>
                </select>
                <IconButton
                  aria-label="next"
                  disabled={currentPage == 0 ? true : false}
                >
                  {rtl ? (
                    <ArrowForwardIosSharpIcon
                      onClick={() => prev()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  ) : (
                    <ArrowBackIosSharpIcon
                      onClick={() => prev()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  )}
                </IconButton>
                <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
                  {begin.current} -{" "}
                  {end.current > directLinkItemCount
                    ? directLinkItemCount
                    : end.current}{" "}
                  {rtl ? "من" : "of"} {directLinkItemCount}
                </p>
                <IconButton
                  aria-label="next"
                  disabled={end.current > directLinkItemCount ? true : false}
                >
                  {rtl ? (
                    <ArrowBackIosSharpIcon
                      onClick={() => next()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  ) : (
                    <ArrowForwardIosSharpIcon
                      onClick={() => next()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  )}
                </IconButton>
              </div>
            )}
            <hr className="mt-0"></hr>
          </>
        )}
      </Container>
    </div>
  );
}

export default AlertsEmailLogsCard;
