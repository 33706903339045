import { config } from "../_helpers/config";
import { fetchWrapper, history } from "../_helpers";
import { put } from "axios";
import { BehaviorSubject } from "rxjs";

const userSubject = new BehaviorSubject(null);
const baseUrl = `${config.apiUrl}/profile`;

export const profileService = {
  LoginAsync,
  RefreshTokenAsync,
  GetUserByEmail,
  AddProfileAsync,
  SignUpStepOneAsync,
  SignUpStepTwoAsync,
  VerifyProfileAsync,
  GetProfileById,
  Logout,
  GetActiveMembershipAsync,
  GetAllMembershipsAsync,
  RemoveDocumentByNameAsync,
  ResendVerificationEmail,
  ForgotPassword,
  ResetPassword,
  ChangePasswordAsync,
  ContactUsEmailAsync,
  DeactivateUserAsync,
  DeleteUserAsync,
  UnsubScribeProfileAsync,
  UpgradeSeedStageEmailAsync,
  UpgradeToEarlyStageAsync,
  UpgradeCorporateToEarlyAsync,
  UpgradeToLaterStageAsync,
  UpgradeToCorporateAsync,
  GetAllFeaturedProfilesAsync,
  ToggleFeaturedProfileAsync,
  VerifyExistingCompanyAsync,
  CheckForRegisteredCompanyAsync,
  IsPrimaryServiceProvider,
  AddSecondaryProfileAsync,
  GetSecondaryUsersAsync,
  ToggleSecondaryUserDisabled,
  MakePrimaryUserAsync,
  GetAllPartnersAsync,
  GetAllSelectedProfiles,
  GetAllProvidersAsync,
  GetAllActiveProvidersAsync,
  UpdatePartnerSelected,
  GetAllUsersWithBadges,
  GetAllUsersWithNoBadges,
  GetAllUsersWithNoBadgesCount,
  AddManuallyGrantedBadgeToUser,
  RemoveManuallyGrantedBadgeToUser,
  GetAllUsersAsync,
  UpdatePartnerWantsToConnect,
  GetProfileByIdAsync,
  ToggleDispatchSubscription,
  ToggleNotificationsSubscription,
  ToggleBrowserNotificationsSubscription,
  GetAllUserMembership,
  GetAllUserMembershipCount,
  UpdateMembershipStatus,
  AddMembershipToUserAsync,
  CheckIsDomainEmailExistsAsync,
  UpdateAccountTypeAsync,
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
};

function LoginAsync(params) {
  return fetchWrapper.post(`${baseUrl}/Login`, params).then((user) => {
    userSubject.next(user);
    startRefreshTokenTimer();
    return user;
  });
}

function RefreshTokenAsync() {
  return fetchWrapper.post(`${baseUrl}/refresh-token`, {}).then((user) => {
    // publish user to subscribers and start timer to refresh token
    userSubject.next(user);
    startRefreshTokenTimer();
    return user;
  });
}

function GetAllFeaturedProfilesAsync(role) {
  return fetchWrapper.get(`${baseUrl}/GetAllFeaturedProfilesAsync/${role}`);
}

function ToggleFeaturedProfileAsync(id, flag) {
  return fetchWrapper.post(`${baseUrl}/ToggleFeaturedProfile/${id}/${flag}`);
}

function Logout(userId) {
  // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
  fetchWrapper.post(`${baseUrl}/deleteCookies`);
  fetchWrapper.get(`${baseUrl}/logout/${userId}`);
  stopRefreshTokenTimer();
  userSubject.next(null);
  sessionStorage.clear();
  history.push("/index");
}

function UpgradeSeedStageEmailAsync(params) {
  return fetchWrapper.post(`${baseUrl}/UpgradeSeedStageEmail`, params);
}

function UpgradeToEarlyStageAsync(params) {
  return fetchWrapper.post(`${baseUrl}/UpgradeToEarlyStage`, params);
}

function UpgradeCorporateToEarlyAsync(userId) {
  return fetchWrapper.post(`${baseUrl}/UpgradeCorporateToEarly/${userId}`, {});
}

function UpgradeToLaterStageAsync(userId) {
  return fetchWrapper.post(`${baseUrl}/UpgradeToLaterStage/${userId}`, {});
}

function UpgradeToCorporateAsync(userId) {
  return fetchWrapper.post(`${baseUrl}/UpgradeToCorporate/${userId}`, {});
}

function GetUserByEmail(params) {
  return fetchWrapper.post(`${baseUrl}/checkEmail`, params);
}

function AddProfileAsync(params) {
  return fetchWrapper.post(`${baseUrl}/AddProfile/${false}`, params);
}

function SignUpStepOneAsync(params) {
  return fetchWrapper.post(`${baseUrl}/SignUpStepOne/${false}`, params);
}

function GetProfileByIdAsync(userId) {
  return fetchWrapper.get(`${baseUrl}/GetProfileByIdAsync/${userId}`);
}
function SignUpStepTwoAsync(id, params) {
  const user = profileService.userValue;
  const options = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user !== null ? user.jwtToken : ""}`,
    },
  };
  return put(`${baseUrl}/SignUpStepTwo/${id}/${true}`, params, options);
}

function VerifyProfileAsync(params) {
  return fetchWrapper.post(`${baseUrl}/VerifyProfile`, params).then((user) => {
    userSubject.next(user);
    startRefreshTokenTimer();
    return user;
  });
}

function GetProfileById(id, flag, projectID, userID, language) {
  if (
    (userID === null || userID === undefined) &&
    (projectID === null || projectID === undefined)
  ) {
    return fetchWrapper.get(
      `${baseUrl}/GetProfileById/${id}/${flag}/${language}`
    );
  } else if (
    (projectID === null || projectID === undefined) &&
    userID !== null &&
    userID !== undefined
  ) {
    return fetchWrapper.get(
      `${baseUrl}/GetProfileById/${id}/${flag}/${language}/${userID}`
    );
  } else {
    return fetchWrapper.get(
      `${baseUrl}/GetProfileById/${id}/${flag}/${language}/${userID}/${projectID}`
    );
  }
}

function GetActiveMembershipAsync(userId) {
  return fetchWrapper.get(`${baseUrl}/GetActiveMembership/${userId}`);
}

function GetAllMembershipsAsync(userId) {
  return fetchWrapper.get(`${baseUrl}/GetAllMemberships/${userId}`);
}

function RemoveDocumentByNameAsync(userId, model) {
  return fetchWrapper.post(`${baseUrl}/RemoveDocumentByName/${userId}`, model);
}

function ResendVerificationEmail(params) {
  return fetchWrapper.post(`${baseUrl}/resend-verificationEmail`, params);
}

function ForgotPassword(params) {
  return fetchWrapper.post(`${baseUrl}/forgot-password`, params);
}

function ResetPassword(params) {
  return fetchWrapper.post(`${baseUrl}/reset-password`, params);
}

function ChangePasswordAsync(params) {
  return fetchWrapper.post(`${baseUrl}/change-password`, params);
}

function ContactUsEmailAsync(params) {
  return fetchWrapper.post(`${baseUrl}/ContactUsEmail`, params);
}

function DeactivateUserAsync(userId) {
  return fetchWrapper.post(`${baseUrl}/DeactivateUser/${userId}`);
}

function DeleteUserAsync(userId) {
  return fetchWrapper.post(`${baseUrl}/DeleteUser/${userId}`);
}

function UnsubScribeProfileAsync(model) {
  return fetchWrapper.post(`${baseUrl}/UnsubScribeProfile`, model);
}

function VerifyExistingCompanyAsync(model) {
  return fetchWrapper.post(`${baseUrl}/CheckForExistingCompany`, model);
}

function CheckForRegisteredCompanyAsync(model) {
  return fetchWrapper.post(`${baseUrl}/checkForRegisteredCompany`, model);
}
function CheckIsDomainEmailExistsAsync(model) {
  return fetchWrapper.post(`${baseUrl}/CheckIsDomainEmailExists`, model);
}

function IsPrimaryServiceProvider(userId) {
  return fetchWrapper.get(`${baseUrl}/isPrimary/${userId}`);
}

function AddSecondaryProfileAsync(model) {
  return fetchWrapper.post(`${baseUrl}/addSecondary`, model);
}

function GetSecondaryUsersAsync(userId) {
  return fetchWrapper.get(`${baseUrl}/GetSecondaryUsers/${userId}`);
}

function ToggleSecondaryUserDisabled(model) {
  return fetchWrapper.put(`${baseUrl}/ToggleDisable`, model);
}

function MakePrimaryUserAsync(model) {
  return fetchWrapper.put(`${baseUrl}/MakePrimary`, model);
}

function GetAllPartnersAsync(params) {
  return fetchWrapper.get(`${baseUrl}/GetAllPartnersAsync/${params}`);
}

function GetAllSelectedProfiles() {
  return fetchWrapper.get(`${baseUrl}/GetAllSelectedProfiles`);
}
function GetAllUserMembership(userId, model) {
  return fetchWrapper.post(`${baseUrl}/GetAllUserMembership/${userId}`, model);
}
function GetAllUserMembershipCount(userId) {
  return fetchWrapper.get(`${baseUrl}/GetAllUserMembershipCount/${userId}`);
}
function GetAllProvidersAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllProviders`);
}

function GetAllActiveProvidersAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllActiveProviders`);
}

function UpdatePartnerSelected(userId, model) {
  return fetchWrapper.put(`${baseUrl}/UpdatePartnerSelected/${userId}`, model);
}

function UpdateMembershipStatus(userId, membershipId) {
  return fetchWrapper.put(
    `${baseUrl}/UpdateMembershipStatus/${userId}/${membershipId}`
  );
}
function UpdatePartnerWantsToConnect(userId, model) {
  return fetchWrapper.put(
    `${baseUrl}/UpdatePartnerWantsToConnect/${userId}`,
    model
  );
}
function GetAllUsersWithBadges(badgeId, model) {
  return fetchWrapper.post(
    `${baseUrl}/GetAllUsersWithBadges/${badgeId}`,
    model
  );
}

function GetAllUsersWithNoBadges(model) {
  return fetchWrapper.post(`${baseUrl}/GetAllUsersWithNoBadges`, model);
}

function GetAllUsersWithNoBadgesCount(id) {
  if (id === null || id === undefined) {
    return fetchWrapper.get(`${baseUrl}/GetAllUsersWithNoBadgesCount`);
  } else {
    return fetchWrapper.get(`${baseUrl}/GetAllUsersWithNoBadgesCount/${id}`);
  }
}

function GetAllUsersAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllUsersAsync`);
}

function ToggleNotificationsSubscription(params) {
  return fetchWrapper.post(
    `${baseUrl}/ToggleNotificationsSubscription`,
    params
  );
}

function ToggleBrowserNotificationsSubscription(params) {
  return fetchWrapper.post(
    `${baseUrl}/ToggleBrowserNotificationsSubscription`,
    params
  );
}

function ToggleDispatchSubscription(params) {
  return fetchWrapper.post(`${baseUrl}/ToggleDispatchSubscription`, params);
}

function AddManuallyGrantedBadgeToUser(userId, badgeId) {
  return fetchWrapper.put(
    `${baseUrl}/AddManuallyGrantedBadgeToUser/${userId}/${badgeId}`
  );
}

function RemoveManuallyGrantedBadgeToUser(userId, badgeId) {
  return fetchWrapper.put(
    `${baseUrl}/RemoveManuallyGrantedBadgeToUser/${userId}/${badgeId}`
  );
}
// helper functions

let refreshTokenTimeout;

function startRefreshTokenTimer() {
  // parse json object from base64 encoded jwt token
  const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split(".")[1]));

  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000);
  const timeout = expires.getTime() - Date.now() - 60 * 1000;
  refreshTokenTimeout = setTimeout(RefreshTokenAsync, timeout);
}

function stopRefreshTokenTimer() {
  clearTimeout(refreshTokenTimeout);
}

function AddMembershipToUserAsync(userId, params, fromInit) {
  return fetchWrapper.post(
    `${baseUrl}/AddMembershipToUser/${userId}/${fromInit}`,
    params
  );
}
function UpdateAccountTypeAsync(params) {
  return fetchWrapper.post(`${baseUrl}/UpdateAccountType`, params);
}
