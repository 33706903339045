import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditWhoWeAreTypes from "./AddEditWhoWeAreTypes";
import TypesList from "./TypesList";
function WhoWeAreTypes({ history, match }) {
    const { path } = match;
    return (
        <div>
            <Switch>
                <PrivateRoute path={`${path}`} component={TypesList} exact />
                <PrivateRoute path={`${path}/add`} component={AddEditWhoWeAreTypes} />
                <PrivateRoute
                    path={`${path}/edit/:id`}
                    component={AddEditWhoWeAreTypes}
                />
            </Switch>
        </div>
    );
}

export { WhoWeAreTypes };
