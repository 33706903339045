import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import MessagesList from "./MessagesList";
import MessagesDetails from "./MessagesDetails";

function Messages({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={MessagesList} exact />
        <PrivateRoute path={`${path}/details`} component={MessagesDetails} />
      </Switch>
    </div>
  );
}

export { Messages };
