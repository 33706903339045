import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { profileService, spSeenService } from "../../../_services";
import { LanguageContext } from "_context";
import Modal from "reactstrap/lib/Modal";
import { Button } from "react-scroll";
import { useTranslation } from "react-i18next";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import queryString from "query-string";
import Tooltip from "@mui/material/Tooltip";
import { textAlign } from "@mui/system";

function ProfilePageTopContent({ props, fromPartner, history, location, rtl }) {
  const user = profileService.userValue;
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  const [bookmarked, setBookmarked] = useState(
    fromPartner == true ? null : props.bookmarked
  );
  const parsed = queryString.parse(
    location !== null && location !== undefined && location.search
  );

  console.log(props);

  async function ToggleSPBookmark(model) {
    await spSeenService
      .ToggleSpSeenBookmarkAsync(model)
      .then(async () => {
        setBookmarked(model.bookmark);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName === currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  return (
    <>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div className="modal-body col-md-12">
          <p>{errorMessage}</p>
        </div>

        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            style={
              rtl ? { margin: "auto", textAlign: "right" } : { margin: "auto" }
            }
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <div
        className={rtl ? "section text-right" : "section"}
        style={{ backgroundColor: "#f5f5fa", paddingTop: "100px" }}
      >
        <Container>
          <center>
            <img
              className="photo-container"
              alt="..."
              style={{
                objectFit: "contain",
                margin: "0 0 auto auto",
                borderRadius: "0%",
              }}
              src={props.logo.filePath}
            ></img>
          </center>
          <h3 className="title">
            {props.profile.companyName}
            {(location.state !== null &&
              location.state !== undefined &&
              location.state.showChat !== null &&
              location.state.showChat !== undefined &&
              location.state.showChat === false) ||
            (parsed !== null &&
              parsed !== undefined &&
              parsed.showChat === "false") ? (
              ""
            ) : props.profile.userId != user.profile.userId &&
              user.profileTypeView.profileTypeDescription != "Provider" ? (
              <>
                <Link
                  tag="h4"
                  style={{ fontSize: "18px" }}
                  onClick={() => {
                    history.push(`../../messaging/${props.profile.userId}`);
                  }}
                >
                  <i
                    id="partnership"
                    className="fa fa-comments"
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      color: "#0E6EB8",
                    }}
                  ></i>
                </Link>
                {fromPartner == false ? (
                  <>
                    <Link
                      id="bookmark"
                      hidden={bookmarked === true}
                      tag="h4"
                      style={{ fontSize: "18px" }}
                    >
                      <i
                        className="now-ui-icons location_bookmark"
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          color: "#0E6EB8",
                        }}
                        onClick={async () => {
                          const model = {
                            spId: props.profile.userId,
                            userId: user.profile.userId,
                            bookmark: true,
                          };
                          await ToggleSPBookmark(model);
                        }}
                      ></i>
                      <UncontrolledTooltip target="bookmark" placement="right">
                        {t("bookmark.click")}
                      </UncontrolledTooltip>
                    </Link>
                    <Link
                      id="unbookmark"
                      hidden={bookmarked === false}
                      tag="h4"
                      style={{ fontSize: "18px" }}
                    >
                      <i
                        className="fa fa-bookmark"
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          color: "#0E6EB8",
                        }}
                        onClick={async () => {
                          const model = {
                            spId: props.profile.userId,
                            userId: user.profile.userId,
                            bookmark: false,
                          };
                          await ToggleSPBookmark(model);
                        }}
                      ></i>
                      <UncontrolledTooltip
                        target="unbookmark"
                        placement="right"
                      >
                        {t("unbookmark.click")}
                      </UncontrolledTooltip>
                    </Link>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              ""
            )}
          </h3>
          <Row className="d-flex justify-content-center">
            {props.badge != null ? (
              <>
                <Tooltip title={props.badge.name}>
                  <img
                    src={props.badge.image}
                    width="32px"
                    style={{ marginRight: "20px" }}
                  />
                </Tooltip>
              </>
            ) : (
              <></>
            )}
            {props.vettingBadge != null ? (
              <Tooltip title={props.vettingBadge.name}>
                <img src={props.vettingBadge.image} width="32px" />
              </Tooltip>
            ) : (
              <></>
            )}
          </Row>
          <center style={rtl ? { direction: "ltr" } : {}}>
            {props.profile.overview != null
              ? props.profile.overview.split(/\r\n|\n|\r/gm).map((line) => {
                  return <p>{line}</p>;
                })
              : ""}
          </center>
        </Container>
      </div>
    </>
  );
}

export default ProfilePageTopContent;
