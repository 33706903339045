import ProfilePageTopContent from "components/Profile/CompanyProfilePage/ProfilePageTopContent";
import React, { useState, useEffect } from "react";

// reactstrap components
import { Container } from "reactstrap";
import CardText from "reactstrap/lib/CardText";
import { useTranslation } from "react-i18next";

// core components

function ProfilePageTopContentSecure({ props, history, location }) {
  const { t, i18n } = useTranslation();
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    setShowInfo(props.companyShowInfo);
  }, []);
  return (
    <>
      {showInfo === true ? (
        <ProfilePageTopContent props={props} history={history} location={location} />
      ) : (
        <div
          className="section"
          style={{ backgroundColor: "#f5f5fa", paddingTop: "100px" }}
        >
          <Container>
            {/* <div className="photo-container">
            <img
              alt="..."
              style={{ objectFit: "contain" }}
              src={props.logo.filePath}
            ></img>
          </div> */}
            <center>
              <img
                //   className="photo-container"
                alt="..."
                width="20%"
                height="20%"
                style={{
                  objectFit: "contain",
                  margin: "0 0 auto auto",
                  borderRadius: "0%",
                }}
                src={require("assets/img/restricted.svg").default}
              ></img>

              {/* <i
                className="photo-container fa fa-lock"
                aria-hidden="true"
                style={{ color: "#0E6EB8" }}
              ></i> */}
            </center>
            <center>
              {/* {props.profile.overview != null
                ? props.profile.overview.split(/\r\n|\n|\r/gm).map((line) => {
                    return <p>{line}</p>;
                  })
                : ""} */}
              <CardText style={{ fontWeight: "bold", marginTop: "20px" }}>
                {t("restriction.topPage")}{" "}
              </CardText>
            </center>
          </Container>
        </div>
      )}
    </>
  );
}

export default ProfilePageTopContentSecure;
