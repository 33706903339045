import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/survey`;

export const surveyService = {
  AddSurvey,
  EditSurvey,
  GetSurveyByLanguageName,
  GetAllSurveys,
  GetAllSurveysByLanguageName,
  DeleteSurvey,
  GetSurvey,
  GetSurveysByType,
  UpdateActiveStatus,
  GetAllPendingSurveys,
  GetAllPendingSurveysCount,
  GetAllSPSurveys,
  GetAllSSSurveys,
};

function GetAllSurveys() {
  return fetchWrapper.get(`${baseUrl}/GetAllSurveys`);
}
function GetAllSPSurveys() {
  return fetchWrapper.get(`${baseUrl}/GetAllSPSurveys`);
}
function GetAllSSSurveys() {
  return fetchWrapper.get(`${baseUrl}/GetAllSSSurveys`);
}
function GetAllSurveysByLanguageName(language) {
  return fetchWrapper.get(`${baseUrl}/GetAllSurveysByLanguageName/${language}`);
}

function GetSurveyByLanguageName(surveyId, language) {
  return fetchWrapper.get(
    `${baseUrl}/GetSurveyByLanguageName/${surveyId}/${language}`
  );
}

function GetSurvey(surveyId) {
  return fetchWrapper.get(`${baseUrl}/GetSurvey/${surveyId}`);
}

function AddSurvey(params, fromEdit) {
  return fetchWrapper.post(`${baseUrl}/AddSurvey/${fromEdit}`, params);
}

function EditSurvey(surveyId, params) {
  return fetchWrapper.put(`${baseUrl}/EditSurvey/${surveyId}`, params);
}

function UpdateActiveStatus(surveyId, params) {
  return fetchWrapper.put(`${baseUrl}/UpdateActiveStatus/${surveyId}`, params);
}

function DeleteSurvey(surveyId) {
  return fetchWrapper.delete(`${baseUrl}/DeleteSurvey/${surveyId}`);
}

function GetSurveysByType(type, role) {
  return fetchWrapper.get(`${baseUrl}/GetSurveysByType/${type}/${role}`);
}

function GetAllPendingSurveys(id) {
  return fetchWrapper.get(`${baseUrl}/GetAllPendingSurveys/${id}`);
}

function GetAllPendingSurveysCount(id) {
  return fetchWrapper.get(`${baseUrl}/GetAllPendingSurveysCount/${id}`);
}
