import React from "react";
import { useTranslation } from "react-i18next";

import { Card, CardBody, CardTitle } from "reactstrap";
import ListGroupItem from "reactstrap/lib/ListGroupItem";
import Badge from "reactstrap/lib/Badge";
import CardSubtitle from "reactstrap/lib/CardSubtitle";
import CardText from "reactstrap/lib/CardText";
import ListGroup from "reactstrap/lib/ListGroup";

function HowTo({ props }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4" style={{ marginTop: "-2px" }}>
          {t("partner.inviteProviders")}
        </CardTitle>

        <CardText>{t("inviteProviders.howTo.one")}</CardText>
        <CardSubtitle className="mb-2 text-muted" style={{ marginTop: "10px" }}>
          {t("partner.howToUse")}
        </CardSubtitle>
        <ListGroup flush>
          <ListGroupItem style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "3px",
                paddingRight: "3px",
                marginTop: "-2px",
              }}
            >
              <Badge color="primary">1</Badge>
            </div>
            {t("inviteProviders.li.one")}
          </ListGroupItem>
          <ListGroupItem style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "3px",
                paddingRight: "3px",
                marginTop: "-2px",
              }}
            >
              <Badge color="primary">2</Badge>
            </div>
            {t("inviteProviders.li.two")}
          </ListGroupItem>
          <ListGroupItem style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "3px",
                paddingRight: "3px",
                marginTop: "-2px",
              }}
            >
              <Badge color="primary">3</Badge>
            </div>
            {t("inviteProviders.li.three")}
          </ListGroupItem>
          <ListGroupItem style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "3px",
                paddingRight: "3px",
                marginTop: "-2px",
              }}
            >
              <Badge color="primary">4</Badge>
            </div>
            {t("inviteProviders.li.four")}
          </ListGroupItem>
          <ListGroupItem style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "3px",
                paddingRight: "3px",
                marginTop: "-2px",
              }}
            >
              <Badge color="primary">5</Badge>
            </div>
            {t("inviteProviders.li.five")}
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
}

export default HowTo;
