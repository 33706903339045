import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/industryService`;

export const industryService = {
  GetIndustriesServicesByLanguageAsync,
  GetProfilesIndustriesServicesByLanguageAsync,
  GetProjectsIndustriesServicesByLanguageAsync,
  GetAllIndustriesServicesAsync,
  GetIndustriesServicesAsync,
  GetIndustriesCountAsync,
};

function GetAllIndustriesServicesAsync() {
  return fetchWrapper.post(`${baseUrl}/GetAllIndustriesServices`);
}

function GetIndustriesServicesAsync() {
  return fetchWrapper.get(`${baseUrl}/GetIndustriesServices`);
}

function GetIndustriesServicesByLanguageAsync(lan) {
  return fetchWrapper.get(`${baseUrl}/GetIndustriesServicesByLanguage/${lan}`);
}

function GetProfilesIndustriesServicesByLanguageAsync(lan, sp) {
  return fetchWrapper.get(
    `${baseUrl}/GetProfilesIndustriesServicesByLanguage/${lan}/${sp}`
  );
}

function GetProjectsIndustriesServicesByLanguageAsync(lan, isDirect) {
  return fetchWrapper.get(
    `${baseUrl}/GetProjectsIndustriesServicesByLanguage/${lan}/${isDirect}`
  );
}

function GetIndustriesCountAsync() {
  return fetchWrapper.get(`${baseUrl}/GetIndustriesCount`);
}
