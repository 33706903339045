import { ResponsiveBar } from "@nivo/bar";
import { analyticsService } from "_services";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import React, { useState, useContext } from "react";
import { Height } from "@material-ui/icons";
function BarChart() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    await analyticsService.GetProjectsNumberByTypeBarAsync().then((x) => {
      setData(x.projectsNumbersByType);

      setIndex(x.indexes);
    });

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const colors = { opportunity: "#0e6eb8" };
  const getColor = (bar) => colors[bar.id];
  // const data = [
  //   {
  //     projectType: "LB",
  //     DirectLink: 55,
  //     // ProviderColor: "hsl(168, 100%, 65%)",
  //     // Seeker: 87,
  //     // SeekerColor: "hsl(144, 70%, 50%)",
  //     Opportunity: 175,
  //     // PartnerColor: "hsl(185, 70%, 50%)",
  //   },
  //   {
  //     projectType: "FR",
  //     DirectLink: 10,
  //     // ProviderColor: "hsl(168, 100%, 65%)",
  //     // Seeker: 20,
  //     // SeekerColor: "hsl(144, 70%, 50%)",
  //     Opportunity: 300,
  //     // PartnerColor: "hsl(185, 70%, 50%)",
  //   },
  //   {
  //     projectType: "US",
  //     DirectLink: 40,
  //     // ProviderColor: "hsl(168, 100%, 65%)",
  //     // Seeker: 30,
  //     // SeekerColor: "hsl(144, 70%, 50%)",
  //     Opportunity: 200,
  //     // PartnerColor: "hsl(185, 70%, 50%)",
  //   },
  // ];
  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <div className="section">
        <Container>
          <div style={{ height: "500px" }}>
            <h6 className="text-primary">
              {t("opportunitiesByType-BarChart")}
            </h6>
            <ResponsiveBar
              data={data}
              keys={index}
              indexBy="projectType"
              margin={{ top: 50, right: 130, bottom: 200, left: 60 }}
              padding={0.3}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={getColor}
              onClick={(data) => {
                // if (data.id === "Opportunity") {
                //   console.log(
                //     `There are ${data.data.Opportunity} Opportunity Organizations in ${data.data.projectType}`
                //   );
                // } else if (data.id === "DirectLink") {
                //   console.log(
                //     `There are ${data.data.DirectLink} Service Providers in ${data.data.projectType}`
                //   );
                // } else {
                //   console.log(
                //     `There are ${data.data.Seeker} Service Seekers in ${data.data.projectType}`
                //   );
                // }
              }}
              // defs={[
              //     {
              //         id: 'dots',
              //         type: 'patternDots',
              //         background: 'inherit',
              //         color: '#38bcb2',
              //         size: 4,
              //         padding: 1,
              //         stagger: true
              //     },
              //     {
              //         id: 'lines',
              //         type: 'patternLines',
              //         background: 'inherit',
              //         color: '#eed312',
              //         rotation: -45,
              //         lineWidth: 6,
              //         spacing: 10
              //     }
              // ]}
              // fill={[
              //     {
              //         match: {
              //             id: 'Seeker'
              //         },
              //         id: 'dots'
              //     },
              //     {
              //         match: {
              //             id: 'DirectLink'
              //         },
              //         id: 'lines'
              //     }
              // ]}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 15,
                legend: "Opportunity Types",
                legendPosition: "middle",
                legendOffset: 70,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Number Of Opportunities",
                legendPosition: "middle",
                legendOffset: -40,
                format: (e) => e % 5 === 0 && e,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  data: [{ label: "Opportunity", color: "#0e6eb8", id: 1 }],
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default BarChart;
