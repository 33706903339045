import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditCountry from "./AddEditCountry";
import CountriesList from "./CountriesList";

function Countries({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={CountriesList} exact />
        <PrivateRoute path={`${path}/add`} component={AddEditCountry} />
        <PrivateRoute path={`${path}/edit/:id`} component={AddEditCountry} />
      </Switch>
    </div>
  );
}

export { Countries };
