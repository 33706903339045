import React from "react";
import { Switch } from "react-router-dom";
import { SSInvitationHistoryContextProvider } from "_context";
import { PrivateRoute } from "_helpers/PrivateRoute";
import InviteServiceSeekers from "./InviteServiceSeekers";

function InviteSeekers({ history, match }) {
  const { path } = match;
  return (
    <>
      <Switch>
        <SSInvitationHistoryContextProvider>
          <PrivateRoute path={path} component={InviteServiceSeekers} />
        </SSInvitationHistoryContextProvider>
      </Switch>
    </>
  );
}

export { InviteSeekers };
