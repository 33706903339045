import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditProjectType from "./AddEditProjectType";
import ProjectTypeList from "./ProjectTypeList";

function ProjectTypes({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={ProjectTypeList} exact />
        <PrivateRoute path={`${path}/add`} component={AddEditProjectType} />
        <PrivateRoute
          path={`${path}/edit/:id`}
          component={AddEditProjectType}
        />
      </Switch>
    </div>
  );
}

export { ProjectTypes };
