import React, { useContext, useEffect, useState } from "react";
import "assets/css/mainSection.css";
import LandingPageNavbar2 from "components/Navbars/LandingPageNavbar2.jsx";
import { AccountTypes } from "_helpers";
import { profileService, adminService, languageService } from "_services";
import SeekerNavbar from "components/Navbars/SeekerNavbar";
import ProviderNavbar from "components/Navbars/ProviderNavbar";
import PartnerNavbar from "components/Navbars/PartnerNavbar";
import { ProfileStatus } from "_helpers";
import LoggedInUserNavbar from "components/Navbars/LoggedInUserNavbar";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import Modal from "reactstrap/lib/Modal";
import Col from "reactstrap/lib/Col";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import { LanguageContext } from "_context";
import LandingPageNavbarMobileTablet2 from "components/Navbars/LandingPageNavbarMobileTablet2";
import ProviderNavbarMobileTablet from "components/Navbars/ProviderNavbarMobileTablet";
import PartnerNavbarMobileTablet from "components/Navbars/PartnerNavbarMobileTablet";
import SeekerNavbarMobileTablet from "components/Navbars/SeekerNavbarMobileTablet";
import * as Scroll from "react-scroll";
import AdminNavbarMobileTablet from "components/Navbars/AdminNavbarMobileTablet";
import AdminNavbar from "components/Navbars/AdminNavbar";
import PulseLoader from "react-spinners/PulseLoader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { NavItem, NavLink, Nav, Navbar, Button } from "reactstrap";
import ReactHtmlParser from "react-html-parser";

function VideosPage({ history, location }) {
  const user = profileService.userValue;
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [videos, setVideos] = useState([]);
  const [currentLan] = useContext(LanguageContext);
  const [pple, setWPple] = useState([]);
  const [challenge, setChallenge] = useState(null);

  const isMobile = useMediaQuery({
    query: "(max-width: 930px)",
  });

  const [errorAlert, setErrorAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [videoPath, setVideoPath] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [height, setHeight] = useState(0);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTablet = useMediaQuery({
    minWidth: 650,
    maxWidth: 1300,
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1223px)",
  });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t("signUp.email.error")),
    firstName: Yup.string().required(t("fullName.required")),
  });
  const initialValues = {
    email: "",
    firstName: "",
    message: "",
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user !== null && user.profileExpired)
      profileService.Logout(user.profile.userId);
  }, []);

  const fetchData = async () => {
    const loader = document.querySelector(".loader-container");

    setLoading(true);
    await languageService.GetAllLanguagesAsync().then(async (x) => {
      var lang = x.filter((lan) => lan.languageName === currentLan)[0];
      console.log("lang", lang);

      await adminService.GetVideosAsync().then((res) => {
        const tempList = res.map((video) => ({
          id: video._id,
          videoTitle: ReactHtmlParser(
            video.videoTitle.filter((x) => x.languageID == lang.languageID)[0]
              .name
          ),
          videoPath: video.videoPath,
          videoName: video.videoName,
        }));
        console.log("tempList", tempList);
        setVideos(tempList);
      });

      await adminService.GetChallengeAsync().then((x) => {
        setChallenge(x);
      });
    });

    loader.classList.add("loader--hide");
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    let scrollTo = window.location.hash.replace("#", "");
    Scroll.scroller.scrollTo(scrollTo, {
      offset: -50,
      duration: 1,
      smooth: true,
      delay: 0,
    });
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  React.useEffect(() => {
    const page = document.getElementById("fullPage");
    const footer = document.querySelector(".myFooter");
    if (footer != null) {
      setHeight(footer.clientHeight);
      // if (page != null) {
      //   page.style.clientHeight = `calc(100vh - ${footer.clientHeight} - 70px)`;
      //   console.log("page", page);
      // }
    }
  });

  function ShowErrorMessage(message) {
    setErrorMessage(message);
    setErrorAlert(!errorAlert);
  }

  // useEffect(() => {
  //   var iframe = document.getElementById("cartoonVideo");
  //   if (alert == true) {
  //     iframe.play();
  //   }
  // }, []);

  const settings = {
    slidesToShow: videos.length < 3 ? videos.length : 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    draggable: true,
    infinite: true,
    swipeToSlide: true,
    swipe: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: videos.length < 3 ? videos.length : 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: videos.length < 3 ? videos.length : 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
    ],
  };

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  if (loading) {
    return <></>;
  }
  return (
    <>
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker &&
        isDesktopOrLaptop && <SeekerNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker &&
        !isDesktopOrLaptop && <SeekerNavbarMobileTablet props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
        isDesktopOrLaptop && <ProviderNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
        !isDesktopOrLaptop && <ProviderNavbarMobileTablet props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner &&
        isDesktopOrLaptop && <PartnerNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner &&
        !isDesktopOrLaptop && <PartnerNavbarMobileTablet props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Admin &&
        isDesktopOrLaptop && <AdminNavbar props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Admin &&
        !isDesktopOrLaptop && <AdminNavbarMobileTablet props={history} />}
      {user && user.profile.profileStatus !== ProfileStatus.Active && (
        <LoggedInUserNavbar props={history} />
      )}
      {!user && isDesktopOrLaptop && <LandingPageNavbar2 props={history} />}
      {!user && !isDesktopOrLaptop && (
        <LandingPageNavbarMobileTablet2 props={history} />
      )}
      <div
        style={
          isDesktopOrLaptop
            ? {
                padding: "70px 70px 0px 70px",
                position: "relative",
                height: "100vh",
              }
            : { paddingTop: "70px", position: "relative", height: "100vh" }
        }
      >
        <Modal
          className="modal-lg"
          isOpen={alert}
          style={rtl ? { textAlign: "right" } : {}}
        >
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <div class="embed-responsive embed-responsive-16by9">
              {/* <iframe
                id="cartoonVideo"
                class="embed-responsive-item"
                width="560"
                height="315"
                src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4?autoplay=1&cc_load_policy=1"
                allowfullscreen
              ></iframe> */}

              <video id="cartoonVideo" width="600" controls muted autoPlay>
                <source src={videoPath} type="video/mp4"></source>
              </video>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setAlert(!alert);
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        {videos && (
          <div
            id="fullPage"
            style={
              isMobile
                ? {
                    margin: "0",
                    padding: "30px 25px 150px 25px",
                    width: "100%",
                    color: "#333",
                    // backgroundColor: "#419be0",
                  }
                : {
                    margin: "0",
                    padding: "30px 25px 0px 25px",
                    width: "100%",
                    color: "#333",
                    height: `calc(100vh - 130px)`,
                    // backgroundColor: "#419be0",
                  }
            }
            // className={isIpad ? "profilesTablet" : ""}
          >
            <h2 className="text-center mb-5">{t("demo.videos")}</h2>
            {videos && videos.length > 0 && (
              <Row
                className="d-flex align-items-center"
                // style={{ paddingBottom: "250px" }}
              >
                {/* <Slider {...settings}> */}

                {videos.map((video) => {
                  return (
                    <Col
                      className={
                        // isTabletBreakPoint
                        //   ? "col-md-12 col-sm-12 col-12 d-flex justify-content-center"
                        //   : isTabletSlider || isMiniTabletSlider
                        //   ? "col d-flex justify-content-center"
                        //   : isMobile
                        //   ? "col-md-4 col-sm-12 col-12 d-flex justify-content-center"
                        //   :
                        isTablet
                          ? "col-md-6 col-lg-6 col-sm-12 col-12"
                          : "col-md-4 col-lg-4 col-sm-12 col-12  "
                      }
                      style={{
                        margin: "auto ",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // paddingLeft: "20px",
                      }}
                    >
                      <div>
                        <video
                          width="370"
                          controls
                          muted
                          onPlay={(e) => {
                            e.target.pause();
                            setVideoPath(video.videoPath);
                            setAlert(true);
                          }}
                          // poster="https://www.outerpond.com/images/Outerpond-Logo-208-74.png"
                        >
                          <source
                            src={video.videoPath}
                            type="video/mp4"
                          ></source>
                        </video>
                      </div>
                      <label
                        style={
                          rtl
                            ? {
                                width: "100%",
                                textAlign: "right",
                                paddingRight: "15px",
                              }
                            : {}
                        }
                      >
                        {video.videoTitle}
                      </label>
                    </Col>
                  );
                })}
                {/* </Slider> */}
              </Row>
            )}
          </div>
        )}
        {(!videos || videos.length == 0) && (
          <div className="text-center mb-2" style={{ paddingBottom: "250px" }}>
            {" "}
            {t("no.videos.found")}{" "}
          </div>
        )}
        <div
          className="myFooter"
          style={
            !isMobile
              ? {
                  position: "absolute",
                  bottom: "-300px",
                  left: "70px",
                  right: "70px",
                }
              : {}
          }
        >
          <Row
            className={
              isMobile
                ? "d-flex justify-content-center"
                : "d-flex justify-content-start"
            }
            style={{ backgroundColor: "#EEF5FB" }}
          >
            <Col
              className={
                isMobile
                  ? "col-md-12 col-sm-12 col-12"
                  : "col-md-4 col-sm-12 col-12"
              }
            >
              <Nav className="flex-row justify-content-start mt-3">
                {/* <NavItem>
                <a className="nav-link " href="#serviceProviders">
                  {t("featuredSP")}
                </a>
              </NavItem> */}
                <Col
                  className={
                    isMobile
                      ? "col-md-12 col-sm-12 col-12 d-flex justify-content-center"
                      : "col-md-12 col-sm-12 col-12"
                  }
                >
                  {/* {user && (
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                      }
                      onClick={() => {
                        history.push("/plans");
                      }}
                      tag={Link}
                    >
                      {t("mainPage.plans")}
                    </NavLink>
                  </NavItem>
                )} */}
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                      }
                      onClick={() => {
                        history.push("/ourteam");
                      }}
                      tag={Link}
                    >
                      {t("landingpage.ourteam")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                      }
                      onClick={() => {
                        history.push("/contactUs");
                      }}
                      tag={Link}
                    >
                      {t("navbar.contactUs")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                      }
                      onClick={() => {
                        history.push("/videos");
                      }}
                      tag={Link}
                    >
                      {t("demo.videos")}
                    </NavLink>
                  </NavItem>
                  {challenge && challenge.isPublic && (
                    <NavItem>
                      <NavLink
                        style={
                          rtl
                            ? {
                                color: "#4D4D4D",
                                fontFamily: "SpaceGrotesk",
                                textAlign: "justify",
                              }
                            : {
                                color: "#4D4D4D",
                                fontFamily: "SpaceGrotesk",
                              }
                        }
                        onClick={() => {
                          history.push("/million-dollar-challenge");
                        }}
                        tag={Link}
                      >
                        {t("million.dollar")}
                      </NavLink>
                    </NavItem>
                  )}
                </Col>
                {/* <Col
                      className={
                        isMobile
                          ? "col-md-6 col-sm-12 col-12 pb-3 d-flex justify-content-center"
                          : "col-md-6 col-sm-12 col-12"
                      }
                    >
                      
                    </Col> */}

                {/* <LanguageDropdown /> */}
              </Nav>
            </Col>
            <Col
              row
              className={
                isMobile
                  ? "d-flex justify-content-center align-items-center col-md-4 col-sm-12 col-12 pt-4 pb-2"
                  : "d-flex justify-content-end align-items-center col-md-4 col-sm-12 col-12"
              }
            >
              {" "}
              <Col
                className={
                  isMobile
                    ? "d-flex justify-content-center align-items-center flex-column mt-0"
                    : "d-flex justify-content-center align-items-center flex-column mt-4"
                }
              >
                <Row>
                  <p
                    style={{
                      color: "#4D4D4D",
                      fontSize: "14px",
                      fontFamily: "SpaceGroteskMedium",
                      textAlign: "left",
                      marginBottom: "0",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("mainPage.usaid")}
                  </p>
                </Row>
                <Row>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      width: "140px",
                    }}
                    src={require("assets/img/usaid_logo_vertical.png").default}
                    alt=""
                  />
                </Row>
              </Col>
            </Col>
            {!isMobile ? (
              <Col
                className="col-md-4 col-sm-12 col-12 d-flex justify-content-end align-items-end"
                style={{ paddingBottom: "10px" }}
              >
                <Row
                  className={
                    "d-flex justify-content-end align-items-center py-1"
                  }
                  style={{ backgroundColor: "#EEF5FB" }}
                >
                  <p
                    style={
                      // isMobile
                      //   ? {
                      //       display: "none",
                      //       color: "#fff",
                      //       fontSize: "16px",
                      //       fontFamily: "SpaceGrotesk",
                      //       marginRight: "15px",
                      //       marginBottom: "0",
                      //     }
                      //   : {
                      {
                        color: "#4D4D4D",
                        fontSize: "16px",
                        fontFamily: "SpaceGrotesk",
                        marginRight: "15px",
                        marginBottom: "0",
                      }
                    }
                  >
                    {t("followUs")}
                  </p>
                  <img
                    style={{
                      width: "32px",
                      height: "32px",
                      objectFit: "contain",
                      marginRight: "15px",
                      cursor: "pointer",
                    }}
                    src={require("assets/img/linkedin.png").default}
                    onClick={() => {
                      const win = window.open(
                        `https://www.linkedin.com/company/outerpond/`,
                        "_blank"
                      );
                      win.focus();
                    }}
                    alt=""
                  />
                  <img
                    style={{
                      width: "32px",
                      height: "32px",
                      objectFit: "contain",
                      marginRight: "15px",
                      cursor: "pointer",
                    }}
                    src={require("assets/img/twitter.png").default}
                    onClick={() => {
                      const win = window.open(
                        `https://twitter.com/outerpond`,
                        "_blank"
                      );
                      win.focus();
                    }}
                    alt=""
                  />
                  <img
                    style={{
                      width: "32px",
                      height: "32px",
                      objectFit: "contain",
                      marginRight: "15px",
                      cursor: "pointer",
                    }}
                    src={require("assets/img/YoutubeSVG.svg").default}
                    onClick={() => {
                      const win = window.open(
                        `https://www.youtube.com/@outerpond3036`,
                        "_blank"
                      );
                      win.focus();
                    }}
                    alt=""
                  />
                </Row>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          {isMobile && (
            <Row
              className={
                isMobile
                  ? "d-flex justify-content-center align-items-center py-3"
                  : "d-flex justify-content-end align-items-center py-3 px-2"
              }
              style={{ backgroundColor: "#EEF5FB" }}
            >
              <p
                style={
                  // isMobile
                  //   ? {
                  //       display: "none",
                  //       color: "#fff",
                  //       fontSize: "16px",
                  //       fontFamily: "SpaceGrotesk",
                  //       marginRight: "15px",
                  //       marginBottom: "0",
                  //     }
                  //   : {
                  {
                    color: "#4D4D4D",
                    fontSize: "16px",
                    fontFamily: "SpaceGrotesk",
                    marginRight: "15px",
                    marginBottom: "0",
                  }
                }
              >
                {t("followUs")}
              </p>
              <img
                style={{
                  width: "32px",
                  height: "32px",
                  objectFit: "contain",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                src={require("assets/img/linkedin.png").default}
                onClick={() => {
                  const win = window.open(
                    `https://www.linkedin.com/company/outerpond/`,
                    "_blank"
                  );
                  win.focus();
                }}
                alt=""
              />
              <img
                style={{
                  width: "32px",
                  height: "32px",
                  objectFit: "contain",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                src={require("assets/img/twitter.png").default}
                onClick={() => {
                  const win = window.open(
                    `https://twitter.com/outerpond`,
                    "_blank"
                  );
                  win.focus();
                }}
                alt=""
              />
              <img
                style={{
                  width: "32px",
                  height: "32px",
                  objectFit: "contain",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                src={require("assets/img/YoutubeSVG.svg").default}
                onClick={() => {
                  const win = window.open(
                    `https://www.youtube.com/@outerpond3036`,
                    "_blank"
                  );
                  win.focus();
                }}
                alt=""
              />
            </Row>
          )}
          <Row
            className={
              isMobile
                ? "d-flex flex-column justify-content-center align-items-center pt-3 px-2"
                : "d-flex justify-content-between align-items-center px-4"
            }
            style={{ backgroundColor: "#132438" }}
          >
            <p
              style={{
                color: "#fff",
                fontSize: "12px",
                fontFamily: "SpaceGrotesk",
                marginRight: "30px",
                marginBottom: "0",
              }}
            >
              {t("mainPage.footer")}
            </p>
            <Nav className="d-flex align-items-center">
              <NavItem>
                <NavLink
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    fontFamily: "SpaceGrotesk",
                  }}
                  to={"/footer-sub-pages/1"}
                  tag={Link}
                >
                  {t("footer.faq")}
                </NavLink>
              </NavItem>
              <span className="text-white">—</span>
              <NavItem>
                <NavLink
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    fontFamily: "SpaceGrotesk",
                  }}
                  to={"/footer-sub-pages/2"}
                  tag={Link}
                >
                  {t("footer.terms")}
                </NavLink>
              </NavItem>
              <span className="text-white">—</span>
              <NavItem>
                <NavLink
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    fontFamily: "SpaceGrotesk",
                  }}
                  to={"/footer-sub-pages/3"}
                  tag={Link}
                >
                  {t("footer.privacyPolicy")}
                </NavLink>
              </NavItem>
            </Nav>
          </Row>
        </div>
      </div>
    </>
  );
}

export default VideosPage;
