import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/plan`;

export const planService = {
    GetAllPlans,
    GetPlanById
};

function GetAllPlans() {
    return fetchWrapper.get(`${baseUrl}/GetAllPlans`);
}

function GetPlanById(id) {
    return fetchWrapper.get(`${baseUrl}/GetPlanById/${id}`);
}

