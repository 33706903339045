import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditOurAim from "./AddEditOurAim";


function OurAim({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={AddEditOurAim} exact />
      </Switch>
    </div>
  );
}

export { OurAim };
