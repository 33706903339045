import React, { useState, useContext } from "react";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import { LanguageContext } from "_context";
import { roleService } from "_services";
import { languageService } from "_services";
import { useEffect } from "react";
import { surveyService } from "_services/survey.service";
import { SurveyAnswerTypes } from "_helpers/SurveyAnswerTypes";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import { v4 as uuidv4 } from "uuid";
import update from "immutability-helper";
import { useRef } from "react";
import { adminService } from "_services";
import { AccountTypes } from "_helpers";
import "assets/css/generalStyles.css";

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      overflowX: "auto",
    },
  },
};

function EditSurvey({ history, match, location }) {
  const isAddMode = !match.params.id;
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [errorOnChange, setErrorOnChange] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [languagesName, setLanguagesName] = useState([]);
  const [roles, setRoles] = useState([]);
  const [bool, setBool] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [type, setType] = useState(null);
  const typeRef = React.useRef();
  const [questionCount, setQuestionCount] = useState(1);
  const [questions, setQuestions] = useState([]);
  const questionsRef = React.useRef([]);
  const [selectedIDMcq, setSelectedIDMcq] = useState(null);
  const [answers, setAnswers] = useState([]);
  const answersRef = React.useRef([]);
  const [data, setData] = useState([]);
  const [showQuestions, setShowQuestions] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [roleNames, setRoleNames] = useState([]);
  const [surveyTypes, setSurveyTypes] = useState([]);
  const [selectedSurveyType, setSelectedSurveyType] = useState(null);
  const [emailTitle, setEmailTitle] = useState(null);
  const [surveyTitles, setSurveyTitles] = useState([]);
  const surveyTitlesRef = useRef([]);
  const surveyDescriptionsRef = useRef([]);
  const surveyQuestionsRef = useRef([]);
  const totalNumberOfQuestions = useRef(0);
  const [manually, setManually] = useState(false);
  const [surveyDescriptions, setSurveyDescriptions] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [newQuestion, setNewQuestion] = useState(false);
  const [seeker, setSeeker] = useState(false);
  const formikRef = useRef();
  const uuid = useRef();
  const answer1_id = useRef();
  const answer2_id = useRef();
  const answer3_id = useRef();
  const answer4_id = useRef();
  const surveysRef = useRef();
  const languagesRef = useRef();

  // const handleTabChange = (event, newTabValue) => {
  //   setTabValue(newTabValue);
  //   setTabIndex(tabIndex + 1);
  // };

  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const id = match.params.id;
  const [initialValues, setInitialValues] = useState({});

  const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;

        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === "object") {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey);
        }
      });
      // console.log(result);
      return result;
    };

    return transformObjectToDotNotation(formikErrors);
  };

  const ScrollToFieldError = ({
    scrollBehavior = { behavior: "smooth", block: "center" },
  }) => {
    const { submitCount, isValid, errors } = useFormikContext();

    useEffect(() => {
      if (isValid) return;

      const fieldErrorNames = getFieldErrorNames(errors);
      if (fieldErrorNames.length <= 0) return;

      const element = document.querySelector(`[name='${fieldErrorNames[0]}']`);
      // console.log(element);
      if (!element) return;

      // Scroll to first known error into view
      element.scrollIntoView(scrollBehavior);
      // console.log(fieldErrorNames);
      // Formik doesn't (yet) provide a callback for a client-failed submission,
      // thus why this is implemented through a hook that listens to changes on
      // the submit count.
    }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
  };

  // console.log(questionsRef.current);

  async function onChange(value) {
    // const {
    //   target: { value },
    // } = e;
    console.log(value);
    let partner = AccountTypes.Partner;
    let seeker = AccountTypes.Seeker;
    let provider = AccountTypes.Provider;
    var roleList = [];
    console.log(value);
    value.forEach((role) => {
      value.forEach((selectedRole) => {
        console.log(selectedRole);
        if (role == selectedRole) {
          roleList.push(role);
        }
      });
    });
    console.log(roleList);
    if (
      (roleList.includes(partner) && roleList.includes(provider)) ||
      (roleList.includes(seeker) && roleList.includes(provider))
    ) {
      if (manually == false) {
        setErrorOnChange(true);
        setError(t("surveyTypes.error"));
        setErrorAlert(!errorAlert);
      }
      setSelectedList(typeof value === "string" ? value.split(",") : value);
    } else {
      if (roleList.includes(partner) || roleList.includes(seeker)) {
        setSeeker(true);
      } else if (roleList.includes(provider)) {
        setSeeker(false);
      }
      setSelectedList(typeof value === "string" ? value.split(",") : value);

      const model = {
        roles: roleList,
      };
      await adminService
        .GetSurveyTypesAsync(model)
        .then((x) => {
          var types = x.filter((x) => x.surveyTypeCode != "ManuallySendSurvey");
          setSurveyTypes(types);
        })
        .catch((error) => {
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    }
  }

  const handleChange = (e, type, languageID) => {
    e.preventDefault();
    const answersArr =
      surveyQuestionsRef.current[0].Questions[questionCount - 1];
    if (answersArr != undefined && answersArr.answers != null)
      surveyQuestionsRef.current = update(surveyQuestionsRef.current, {
        [0]: {
          Questions: {
            [questionCount - 1]: {
              answers: {
                $set: [],
              },
            },
          },
        },
      });
    if (type == SurveyAnswerTypes.MCQ) {
      // setSelectedIDMcq(languageID);
      answer1_id.current = uuidv4();
      answer2_id.current = uuidv4();
      answer3_id.current = uuidv4();
      answer4_id.current = uuidv4();
    }
    setType(type);
    typeRef.current = type;
  };

  const handleQuestionTypeChange = (value, id, flag) => {
    // if (questions.length == 0) {
    //   const objArr = [];
    //   languages.forEach((element) => {
    //     const newObj = {
    //       LanguageID: element.languageID,
    //       QuestionType: value,
    //     };
    //     objArr.push(newObj);
    //   });
    //   questionsRef.current = objArr;
    //   setQuestions(objArr);
    // }
    console.log(surveyQuestionsRef.current);
    if (flag == false) {
      languages.forEach((element) => {
        const questionsIndex = surveyQuestionsRef.current.findIndex(
          (x) => x.LanguageID == element.languageID
        );
        let questionsArr = surveyQuestionsRef.current[questionsIndex].Questions;
        questionsArr.forEach((question) => {
          const questionsIDIndex = questionsArr.findIndex(
            (x) => x.questionID == id
          );
          if (question.questionID == id) {
            surveyQuestionsRef.current[questionsIndex].Questions = update(
              surveyQuestionsRef.current[questionsIndex].Questions,
              {
                [questionsIDIndex]: {
                  questionType: {
                    $set: value,
                  },
                },
              }
            );
          }
        });
        surveys.forEach((survey) => {
          if (element.languageID === survey.languageID) {
            const userIndex = surveys.findIndex(
              (x) => x.languageID === survey.languageID
            );
            let surveyArr = surveys;
            if (surveyArr[userIndex].questions == undefined) {
              surveyArr[userIndex].questions = [];
            }
            surveyArr[userIndex].questions =
              surveyQuestionsRef.current[questionsIndex].Questions;
            setSurveys(surveyArr);
          }
        });
      });
    } else {
      const newSurveyQuestion = {
        questionType: value,
        questionID: uuid.current,
      };
      languages.forEach((element) => {
        const questionsIndex = surveyQuestionsRef.current.findIndex(
          (x) => x.LanguageID == element.languageID
        );
        // let questionsArr = surveyQuestionsRef.current[questionsIndex].Questions;
        // questionsArr.forEach((question) => {
        //   const questionsIDIndex = questionsArr.findIndex(
        //     (x) => x.questionID == uuid.current
        //   );

        //   if (questionsIDIndex >= 0) {
        //     surveyQuestionsRef.current[questionsIndex].Questions = update(
        //       surveyQuestionsRef.current[questionsIndex].Questions,
        //       {
        //         [questionsIDIndex]: {
        //           questionType: {
        //             $set: value,
        //           },
        //         },
        //       }
        //     );
        //   } else {
        surveyQuestionsRef.current[questionsIndex].Questions = update(
          surveyQuestionsRef.current[questionsIndex].Questions,
          {
            $push: [newSurveyQuestion],
          }
        );
        // }
        // });

        surveys.forEach((survey) => {
          if (element.languageID === survey.languageID) {
            const userIndex = surveys.findIndex(
              (x) => x.languageID === survey.languageID
            );
            let surveyArr = surveys;
            if (surveyArr[userIndex].questions == undefined) {
              surveyArr[userIndex].questions = [];
            }
            surveyArr[userIndex].questions =
              surveyQuestionsRef.current[questionsIndex].Questions;
            setSurveys(surveyArr);
          }
        });
      });
    }
  };

  const handleQuestionOrderChange = (value, id, flag) => {
    if (flag == false) {
      languages.forEach((element) => {
        const questionsIndex = surveyQuestionsRef.current.findIndex(
          (x) => x.LanguageID == element.languageID
        );
        let questionsArr = surveyQuestionsRef.current[questionsIndex].Questions;
        questionsArr.forEach((question) => {
          const questionsIDIndex = questionsArr.findIndex(
            (x) => x.questionID == id
          );
          if (question.questionID == id) {
            surveyQuestionsRef.current[questionsIndex].Questions = update(
              surveyQuestionsRef.current[questionsIndex].Questions,
              {
                [questionsIDIndex]: {
                  questionOrder: {
                    $set: parseInt(value),
                  },
                },
              }
            );
          }
        });
        surveys.forEach((survey) => {
          if (element.languageID === survey.languageID) {
            const userIndex = surveys.findIndex(
              (x) => x.languageID === survey.languageID
            );
            let surveyArr = surveys;
            if (surveyArr[userIndex].questions == undefined) {
              surveyArr[userIndex].questions = [];
            }
            surveyArr[userIndex].questions =
              surveyQuestionsRef.current[questionsIndex].Questions;
            setSurveys(surveyArr);
          }
        });
      });
    } else {
      const newSurveyQuestion = {
        questionOrder: parseInt(value),
        questionID: uuid.current,
      };
      languages.forEach((element) => {
        const questionsIndex = surveyQuestionsRef.current.findIndex(
          (x) => x.LanguageID == element.languageID
        );
        let questionsArr = surveyQuestionsRef.current[questionsIndex].Questions;
        questionsArr.forEach((question) => {
          const questionsIDIndex = questionsArr.findIndex(
            (x) => x.questionID == uuid.current
          );
          if (questionsIDIndex >= 0) {
            surveyQuestionsRef.current[questionsIndex].Questions = update(
              surveyQuestionsRef.current[questionsIndex].Questions,
              {
                [questionsIDIndex]: {
                  questionOrder: {
                    $set: parseInt(value),
                  },
                },
              }
            );
          } else {
            surveyQuestionsRef.current[questionsIndex].Questions = update(
              surveyQuestionsRef.current[questionsIndex].Questions,
              {
                $push: [newSurveyQuestion],
              }
            );
          }
        });
        surveys.forEach((survey) => {
          if (element.languageID === survey.languageID) {
            const userIndex = surveys.findIndex(
              (x) => x.languageID === survey.languageID
            );
            let surveyArr = surveys;
            if (surveyArr[userIndex].questions == undefined) {
              surveyArr[userIndex].questions = [];
            }
            surveyArr[userIndex].questions =
              surveyQuestionsRef.current[questionsIndex].Questions;
            setSurveys(surveyArr);
          }
        });
      });
    }
    // languages.forEach((element) => {
    //   const questionsIndex = questions.findIndex(
    //     (x) => x.LanguageID == element.languageID
    //   );
    //   questionsRef.current = update(questionsRef.current, {
    //     [questionsIndex]: {
    //       QuestionOrder: {
    //         $set: value,
    //       },
    //     },
    //   });
    //   setQuestions(
    //     update(questions, {
    //       [questionsIndex]: {
    //         QuestionOrder: {
    //           $set: value,
    //         },
    //       },
    //     })
    //   );
    // });
  };

  const handleAnswerChange = (id, flag) => {
    if (flag == false) {
      const questionsIndex = surveyQuestionsRef.current.findIndex(
        (x) => x.LanguageID == id
      );
      let questionsArr = surveyQuestionsRef.current[questionsIndex].Questions;
      const newSurveyQuestion = {
        questionID: uuid.current,
        languageID: id,
        answers: answersRef.current,
      };
      const questionId =
        surveyQuestionsRef.current[questionsIndex].Questions[questionCount - 1]
          .questionID;
      const questionsIDIndex = questionsArr.findIndex(
        (x) => x.questionID == questionId
      );
      if (questionsIDIndex >= 0) {
        surveyQuestionsRef.current[questionsIndex].Questions = update(
          surveyQuestionsRef.current[questionsIndex].Questions,
          {
            [questionsIDIndex]: {
              answers: {
                $set: answersRef.current,
              },
            },
          }
        );
      } else {
        surveyQuestionsRef.current[questionsIndex].Questions = update(
          surveyQuestionsRef.current[questionsIndex].Questions,
          {
            $push: [newSurveyQuestion],
          }
        );
      }
      surveys.forEach((survey) => {
        const userIndex = surveys.findIndex((x) => x.languageID === id);
        let surveyArr = surveys;
        if (surveyArr[userIndex].questions == undefined) {
          surveyArr[userIndex].questions = [];
        }
        surveyArr[userIndex].questions =
          surveyQuestionsRef.current[questionsIndex].Questions;
        setSurveys(surveyArr);
      });
      // questionsRef.current = update(questionsRef.current, {
      //   [questionsIndex]: {
      //     Answers: {
      //       $set: answersRef.current,
      //     },
      //   },
      // });
      // setQuestions(
      //   update(questions, {
      //     [questionsIndex]: {
      //       Answers: {
      //         $set: answersRef.current,
      //       },
      //     },
      //   })
      // );
    } else {
      const questionsIndex = surveyQuestionsRef.current.findIndex(
        (x) => x.LanguageID == id
      );
      const newSurveyQuestion = {
        questionID: uuid.current,
        languageID: id,
        answers: answersRef.current,
      };
      let questionsArr = surveyQuestionsRef.current[questionsIndex].Questions;
      questionsArr.forEach((question) => {
        const questionsIDIndex = questionsArr.findIndex(
          (x) => x.questionID == uuid.current
        );
        if (questionsIDIndex >= 0) {
          surveyQuestionsRef.current[questionsIndex].Questions = update(
            surveyQuestionsRef.current[questionsIndex].Questions,
            {
              [questionsIDIndex]: {
                answers: {
                  $set: answersRef.current,
                },
              },
            }
          );
        } else {
          surveyQuestionsRef.current[questionsIndex].Questions = update(
            surveyQuestionsRef.current[questionsIndex].Questions,
            {
              $push: [newSurveyQuestion],
            }
          );
        }
        surveys.forEach((survey) => {
          const userIndex = surveys.findIndex((x) => x.languageID === id);
          let surveyArr = surveys;
          if (surveyArr[userIndex].questions == undefined) {
            surveyArr[userIndex].questions = [];
          }
          surveyArr[userIndex].questions =
            surveyQuestionsRef.current[questionsIndex].Questions;
          setSurveys(surveyArr);
        });
      });
    }
  };

  function initialForm(translations) {
    var newJson = {};
    translations.map((x) => {
      var newKey = x.languageName;
      var newVal = "";
      newJson[newKey] = newVal;
    });

    setInitialValues(newJson);
  }

  function editInitialForm(translations, plan) {
    var newJson = {};
    translations.map((x) => {
      var newKey = x.languageName;
      // console.log(x.languageName);
      if (plan.planDescription != null) {
        for (var i = 0; i < plan.planDescription.length; i++) {
          if (x.languageID === plan.planDescription[i].languageID) {
            var newVal = plan.planDescription[i].name;
            // console.log(newVal);
            newJson[newKey] = newVal;
            // console.log(newJson[newKey]);
            languagesName[i] = plan.planDescription[i].name;
          }
        }
        setLanguagesName(languagesName);
      }
    });

    setInitialValues(newJson);
  }

  const fetchData = async () => {
    setLoading(true);
    await languageService.GetAllLanguagesAsync().then((x) => {
      // console.log(x);
      setLanguages(x);
      const objArr = [];
      x.forEach((element) => {
        const newObj = {
          LanguageID: element.languageID,
        };
        objArr.push(newObj);
      });
      setSurveys(objArr);
      roleService.GetRoleByLanguageAsync(i18n.language).then((x) => {
        setRoles(x);
      });

      if (isAddMode) {
        initialForm(x);
      } else {
        editInitialForm(x, location.state.state);
      }
    });
    await surveyService.GetSurvey(id).then((x) => {
      console.log(x);
      let provider = AccountTypes.Provider;
      setSelectedList(x.surveyAccountNames);
      if (x.roles.includes(provider)) setSeeker(false);
      else setSeeker(true);
      setSelectedSurveyType(x.surveyType);
      setEmailTitle(x.emailTitle);
      setManually(x.manually);
      x.surveys.forEach((survey) => {
        totalNumberOfQuestions.current = survey.questions.length;
        surveyTitlesRef.current = update(surveyTitlesRef.current, {
          $push: [
            {
              SurveyTitle: survey.surveyTitle,
              LanguageID: survey.languageID,
            },
          ],
        });
        surveyDescriptionsRef.current = update(surveyDescriptionsRef.current, {
          $push: [
            {
              SurveyDescription: survey.surveyDescription,
              LanguageID: survey.languageID,
            },
          ],
        });
        surveyQuestionsRef.current = update(surveyQuestionsRef.current, {
          $push: [
            {
              Questions: survey.questions,
              LanguageID: survey.languageID,
            },
          ],
        });
        setSelectedList(x.surveyAccountTypes);
        onChange(x.surveyAccountTypes);
      });
      console.log(surveyTitlesRef.current);
      setSurveys(x.surveys);
      setData(x);
    });
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    console.log("bool" + bool);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  console.log(surveyQuestionsRef.current);
  console.log(surveys);
  console.log("manually", manually);
  console.log("seeker", seeker);

  async function onSubmit(fields, { setStatus, setSubmitting, setFieldValue }) {
    // setSurveys([
    //   ...surveys,
    //   {
    //     LanguageID: tabValue,
    //     SurveyTitle: fields.surveyTitle,
    //     SurveyDescription: fields.surveyDescription,
    //   },
    // ]);
    if (showQuestions == true) handleNextQuestion(setFieldValue, true);
    const newItem = {
      // LanguageID: oldTabValue,
      SurveyTitle: fields.surveyTitle,
      SurveyDescription: fields.surveyDescription,
      SurveyType: fields.surveyType,
    };
    // setSurveys(update(surveys, { $push: [newItem] }));
    const model = {
      SurveyAccountTypes: selectedList,
      Surveys: surveys,
      SurveyType: selectedSurveyType,
      EmailTitle: emailTitle,
      Manually: manually,
    };

    // console.log("model", model);
    // const model = {};
    // let list = [];
    // var regex = /(<([^>]+)>)/gi;
    // if (languages !== null) {
    //   for (var i = 0; i < languages.length; i++) {
    //     var hasText = !!languagesName[i].replace(regex, "").length;
    //     if (hasText === false) {
    //       setError(t("required.all"));
    //       setErrorAlert(!errorAlert);
    //       return false;
    //     }
    //     const translation = {
    //       languageID: languages[i].languageID,
    //       name: languagesName[i],
    //     };
    //     list.push(translation);
    //   }
    // }

    // model["planDescription"] = list;
    await surveyService
      .EditSurvey(match.params.id, model)
      .then((x) => {
        setSubmitting(false);
        setBool(x);
        setAlert(!alert);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
    setSubmitting(false);
  }

  const handleAddQuestion = (fields, setFieldValue) => {
    var proceedNext = true;
    // setData({ ...data, SurveyAccountTypes: selectedList, Surveys: surveys });
    if (
      selectedList.length == 0 ||
      (selectedSurveyType == null && !manually) ||
      emailTitle == null
    ) {
      proceedNext = false;
      setError(t("required.all"));
      setErrorAlert(!errorAlert);
    }
    // else if (selectedList.length > 0) {
    //   surveys.forEach((survey) => {
    //     if (survey.surveyTitle == null || survey.surveyDescription == null) {
    //       proceedNext = false;
    //       setError(t("required.all"));
    //       setErrorAlert(!errorAlert);
    //     }
    //   });
    // }
    surveysRef.current = surveys;
    languagesRef.current = languages;
    var list = [];
    if (
      selectedList.length > 0 &&
      (selectedSurveyType != null || manually) &&
      emailTitle != null
    ) {
      surveysRef.current.forEach((survey) => {
        if (
          survey.questions[0] != null &&
          survey.questions[0].questionType == SurveyAnswerTypes.MCQ
        ) {
          const answers = survey.questions[0].answers;
          setAnswers(answers);
          answersRef.current = answers;
          setFieldValue(`questionTitle${survey.languageID}`, "");
          setFieldValue(
            `answer1${survey.languageID}`,
            answers[0] != null && answers[0].answer != null
              ? answers[0].answer
              : ""
          );
          setFieldValue(
            `answer2${survey.languageID}`,
            answers[1] != null && answers[1].answer != null
              ? answers[1].answer
              : ""
          );
          setFieldValue(
            `answer3${survey.languageID}`,
            answers[2] != null && answers[2].answer != null
              ? answers[2].answer
              : ""
          );
          setFieldValue(
            `answer4${survey.languageID}`,
            answers[3] != null && answers[3].answer != null
              ? answers[3].answer
              : ""
          );
          answer1_id.current =
            answers[0] != null ? answers[0].answerID : uuidv4();
          answer2_id.current =
            answers[1] != null ? answers[1].answerID : uuidv4();
          answer3_id.current =
            answers[2] != null ? answers[2].answerID : uuidv4();
          answer4_id.current =
            answers[3] != null ? answers[3].answerID : uuidv4();
        } else {
          setFieldValue(`questionTitle${survey.languageID}`, "");
          setFieldValue(`answer1${survey.languageID}`, "");
          setFieldValue(`answer2${survey.languageID}`, "");
          setFieldValue(`answer3${survey.languageID}`, "");
          setFieldValue(`answer4${survey.languageID}`, "");
        }
        const objectIndex = surveysRef.current.findIndex(
          (s) => s.languageID === survey.languageID
        );
        if (
          Object.entries(survey).length == 1 ||
          (Object.entries(survey).length == 2 && survey.surveyTitle == "") ||
          (Object.entries(survey).length == 2 &&
            survey.surveyDescription == "") ||
          (Object.entries(survey).length > 2 &&
            survey.surveyTitle == "" &&
            survey.surveyDescription == "")
        ) {
          surveysRef.current = update(surveysRef.current, {
            $splice: [[objectIndex, 1]],
          });
        } else {
          list.push(survey.languageID);
        }
      });
      languagesRef.current = languagesRef.current.filter((lan) =>
        list.some((surv) => surv === lan.languageID)
      );

      surveysRef.current.forEach((survey) => {
        if (survey.surveyTitle == null || survey.surveyDescription == null) {
          proceedNext = false;
          setError(t("required.all"));
          setErrorAlert(!errorAlert);
        }
      });
    }
    if (proceedNext == true) {
      setData(
        update(data, {
          surveys: {
            $set: surveys,
          },
        })
      );
      uuid.current = uuidv4();
      setSurveys(surveysRef.current);
      setLanguages(languagesRef.current);
      setShowQuestions(true);
    }
  };

  const handleNextQuestion = (setFieldValue, flag) => {
    let surveyQuestionsRefArray = surveyQuestionsRef.current;
    let proceedNext = true;
    languages.forEach((language) => {
      const index = surveyQuestionsRef.current.findIndex(
        (x) => x.LanguageID == language.languageID
      );
      let q = surveyQuestionsRefArray[index].Questions[questionCount - 1];
      // if (q.length == 0) {
      //   proceedNext = false;
      //   console.log("here3");
      //   setError(t("required.all"));
      //   setErrorAlert(!errorAlert);
      // } else {
      //   q.forEach((question) => {
      if (
        q.questionID == null ||
        q.questionOrder == null ||
        q.questionText == null ||
        q.questionType == null ||
        q.questionOrder == "" ||
        q.questionText == ""
      ) {
        proceedNext = false;
        console.log("one");
        setError(t("required.all"));
        setErrorAlert(!errorAlert);
      } else if (q.questionType == SurveyAnswerTypes.MCQ && q.answers == null) {
        // question.Answers.forEach((answer) => {
        //   if (answer.Answer == "" || answer.Answer == null) {
        proceedNext = false;
        console.log("two");
        setError(t("required.all"));
        setErrorAlert(!errorAlert);
        //   }
        // });
      }
      // });
      // }
    });
    if (proceedNext == true && flag == false) {
      surveys.forEach((survey) => {
        survey.questions.forEach((question) => {
          if (question.answers != null && question.answers.length > 0) {
            question.answers.forEach((answer) => {
              const answerCount = question.answers.findIndex(
                (x) => x.answerID == answer.answerID
              );
              if (answer.answer == "") {
                question.answers = update(question.answers, {
                  $splice: [[answerCount, 1]],
                });
              }
            });
          }
          if (question.LanguageID === survey.languageID) {
            const userIndex = surveys.findIndex(
              (x) => x.languageID === survey.languageID
            );
            let surveyArr = surveys;
            if (surveyArr[userIndex].questions == undefined) {
              surveyArr[userIndex].questions = [];
            }
            surveyArr[userIndex].questions.push(question);
            setSurveys(surveyArr);
            // setSurveys(
            //   update(surveys, {
            //     [userIndex]: {
            //       $merge: {
            //         Questions: [question],
            //       },
            //     },
            //   })
            // );
          }
        });
      });
      // setSurveys([...surveys])
      // if (flag == false) {
      questionsRef.current = [];
      setQuestions([]);
      setAnswers([]);
      answersRef.current = [];
      setType(null);
      setFieldValue("questionType", "");
      setFieldValue("questionOrder", "");
      languages.forEach((language) => {
        const index = surveyQuestionsRef.current.findIndex(
          (x) => x.LanguageID == language.languageID
        );
        if (
          surveyQuestionsRef.current[index].Questions[questionCount] != null &&
          surveyQuestionsRef.current[index].Questions[questionCount]
            .questionType == SurveyAnswerTypes.MCQ
        ) {
          const answers =
            surveyQuestionsRef.current[index].Questions[questionCount].answers;
          setAnswers(answers);
          answersRef.current = answers;
          setFieldValue(`questionTitle${language.languageID}`, "");
          setFieldValue(
            `answer1${language.languageID}`,
            answers[0] != null && answers[0].answer != null
              ? answers[0].answer
              : ""
          );
          setFieldValue(
            `answer2${language.languageID}`,
            answers[1] != null && answers[1].answer != null
              ? answers[1].answer
              : ""
          );
          setFieldValue(
            `answer3${language.languageID}`,
            answers[2] != null && answers[2].answer != null
              ? answers[2].answer
              : ""
          );
          setFieldValue(
            `answer4${language.languageID}`,
            answers[3] != null && answers[3].answer != null
              ? answers[3].answer
              : ""
          );
          answer1_id.current =
            answers[0] != null ? answers[0].answerID : uuidv4();
          answer2_id.current =
            answers[1] != null ? answers[1].answerID : uuidv4();
          answer3_id.current =
            answers[2] != null ? answers[2].answerID : uuidv4();
          answer4_id.current =
            answers[3] != null ? answers[3].answerID : uuidv4();
        } else {
          setFieldValue(`questionTitle${language.languageID}`, "");
          setFieldValue(`answer1${language.languageID}`, "");
          setFieldValue(`answer2${language.languageID}`, "");
          setFieldValue(`answer3${language.languageID}`, "");
          setFieldValue(`answer4${language.languageID}`, "");
        }
      });
      setQuestionCount(questionCount + 1);
      uuid.current = uuidv4();
      // }
    } else if (proceedNext == true && flag == true) {
      surveys.forEach((survey) => {
        survey.questions.forEach((question) => {
          if (question.answers != null && question.answers.length > 0) {
            question.answers.forEach((answer) => {
              const answerCount = question.answers.findIndex(
                (x) => x.answerID == answer.answerID
              );
              if (answer.answer == "") {
                question.answers = update(question.answers, {
                  $splice: [[answerCount, 1]],
                });
              }
            });
          }
          if (question.LanguageID === survey.languageID) {
            const userIndex = surveys.findIndex(
              (x) => x.languageID === survey.languageID
            );
            let surveyArr = surveys;
            if (surveyArr[userIndex].questions == undefined) {
              surveyArr[userIndex].questions = [];
            }
            surveyArr[userIndex].questions =
              surveyQuestionsRefArray[userIndex].Questions;
            setSurveys(surveyArr);
            // setSurveys(
            //   update(surveys, {
            //     [userIndex]: {
            //       $merge: {
            //         Questions: [question],
            //       },
            //     },
            //   })
            // );
          }
        });
      });
    }
  };

  const handleDeleteQuestion = () => {
    let s = surveyQuestionsRef.current;
    s.forEach((survey) => {
      survey.Questions = update(survey.Questions, {
        $splice: [[questionCount - 1, 1]],
      });
    });
    surveys.forEach((survey) => {
      let questions = surveyQuestionsRef.current.filter(
        (x) => x.LanguageID == survey.languageID
      )[0].Questions;
      survey.questions = questions;
    });
    setQuestionCount(questionCount - 1);
    totalNumberOfQuestions.current = totalNumberOfQuestions.current - 1;
    setDeleteAlert(!deleteAlert);
  };

  const handleNewQuestion = (setFieldValue) => {
    setQuestionCount(questionCount + 1);
    setNewQuestion(true);
    setAnswers([]);
    setFieldValue("questionType", "");
    setFieldValue("questionOrder", "");
    languages.forEach((language) => {
      setFieldValue(`questionTitle${language.languageID}`, "");
      setFieldValue(`answer1${language.languageID}`, "");
      setFieldValue(`answer2${language.languageID}`, "");
      setFieldValue(`answer3${language.languageID}`, "");
      setFieldValue(`answer4${language.languageID}`, "");
    });
  };

  return (
    <div
      className="section"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // backgroundColor: "#EEF5FB",
        height: questions.length > 1 ? "fit-content" : "auto",
      }}
    >
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("done")}</h5>
          </div>
          <div className="modal-body">
            {isAddMode ? (
              bool == true ? (
                <p>{t("AddInsteadOfEdit")}</p>
              ) : (
                <p>{t("surveyAddSuccess")}</p>
              )
            ) : bool == true ? (
              <p>{t("AddInsteadOfEdit")}</p>
            ) : (
              <p>{t("surveyUpdateSuccess")}</p>
            )}
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setAlert(!alert);
                if (isAddMode) {
                  history.push(".", {
                    type: location.state.type == "SS&PO" ? "SS&PO" : "SP",
                  });
                } else {
                  history.push("..", {
                    type: location.state.type == "SS&PO" ? "SS&PO" : "SP",
                  });
                }
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}></div>
          <div className="modal-body">{t("questionDeleteConfirmation")}</div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setDeleteAlert(!deleteAlert);
              }}
            >
              {t("label.no")}
            </Button>
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                handleDeleteQuestion();
              }}
            >
              {t("label.yes")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div className="modal-body col-md-12">
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                if (errorOnChange == true) setSelectedList([]);
                setErrorAlert(!errorAlert);
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, errors, touched, values, setFieldValue }) => {
              return (
                <Form className={rtl ? "w-100 text-right" : "w-100"}>
                  <CardTitle className="text-center" tag="h4">
                    {showQuestions == true
                      ? t("surveyQuestion") + questionCount
                      : t("editSurvey")}
                  </CardTitle>
                  {showQuestions == false ? (
                    <>
                      <div className="form-group">
                        <FormGroup
                          className="col-md-8"
                          style={{
                            margin: "0",
                            width: "100%",
                            padding: "10px",
                            marginTop: "-2%",
                            marginBottom: "1%",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="#107FD4"
                                checked={manually}
                                onChange={() => {
                                  setManually(!manually);
                                  setSelectedSurveyType(null);
                                  setSelectedList([]);
                                }}
                                name="manuallySendSurvey"
                              />
                            }
                            label={
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "SpaceGrotesk",
                                }}
                              >
                                {t("manuallySendSurvey")}
                              </span>
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="form-group">
                        <FormGroup
                          className="col-md-8"
                          style={{
                            margin: "0",
                            width: "100%",
                            padding: "10px",
                            marginTop: "-2%",
                            marginBottom: "1%",
                          }}
                        >
                          <FormControl
                            sx={{ m: 1, width: 300 }}
                            style={{ margin: 0 }}
                            className={"row"}
                          >
                            <label
                              htmlFor="rolesList"
                              style={{ fontSize: "16px" }}
                            >
                              {t("surveyAccountNames")}
                            </label>
                            <Select
                              labelId="rolesList"
                              id="demo-multiple-name"
                              multiple
                              name="surveyAccountTypeName"
                              placeholder="Roles"
                              value={selectedList}
                              disabled={editClicked == true ? false : true}
                              onChange={(e) => {
                                setFieldValue(
                                  "surveyAccountTypes",
                                  typeof value === "string"
                                    ? e.target.value.split(",")
                                    : e.target.value
                                );
                                onChange(e.target.value);
                              }}
                              style={{
                                height: "36px",
                                borderRadius: 0,
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                              }}
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": {
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                },
                              }}
                              MenuProps={MenuProps}
                            >
                              {roles.map((role) => (
                                <MenuItem
                                  className="css-1km1ehz"
                                  key={role.roleId}
                                  value={role.roleId}
                                >
                                  {role.description}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </FormGroup>
                      </div>
                      {!manually && (
                        <div className="form-group">
                          <FormGroup
                            className="col-md-8"
                            style={{
                              margin: "0",
                              width: "100%",
                              padding: "10px",
                              marginTop: "-2%",
                              marginBottom: "1%",
                            }}
                          >
                            <FormControl
                              sx={{ m: 1, width: 300 }}
                              style={{ margin: 0 }}
                              className={"row"}
                            >
                              <label
                                htmlFor="rolesList"
                                style={{ fontSize: "16px" }}
                              >
                                {t("surveyTypes")}
                              </label>
                              <Select
                                labelId="surveyTypes"
                                name="surveyTypes"
                                placeholder="Roles"
                                value={selectedSurveyType}
                                disabled={editClicked == true ? false : true}
                                onChange={(e) => {
                                  setFieldValue("surveyType", e.target.value);
                                  setSelectedSurveyType(e.target.value);
                                }}
                                style={{
                                  height: "36px",
                                  width: 300,
                                  borderRadius: 0,
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                }}
                                sx={{
                                  boxShadow: "none",
                                  width: 300,
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                  },
                                }}
                                MenuProps={MenuProps}
                              >
                                {/* {surveyTypes.length == 0 && (
                                <MenuItem
                                  key={selectedSurveyType}
                                  value={selectedSurveyType}
                                >
                                  {selectedSurveyType}
                                </MenuItem>
                              )} */}
                                {surveyTypes.map((type) => (
                                  <MenuItem
                                    className="css-1km1ehz"
                                    key={type.surveyTypeCode}
                                    value={type.surveyTypeCode}
                                  >
                                    {type.surveyTypeDescription}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </div>
                      )}
                      <div className="form-group">
                        <FormGroup
                          className="col-md-8"
                          style={{
                            margin: "0",
                            width: "100%",
                            padding: "10px",
                            marginTop: "-2%",
                            marginBottom: "1%",
                          }}
                        >
                          <FormControl
                            sx={{ m: 1, width: 300 }}
                            style={{ margin: 0 }}
                            className={"row"}
                          >
                            <label
                              htmlFor="rolesList"
                              style={{ fontSize: "16px" }}
                            >
                              {t("survey.email.title")}
                            </label>
                            <Field
                              labelId="emailTitle"
                              name="emailTitle"
                              value={emailTitle}
                              disabled={editClicked == true ? false : true}
                              onChange={(e) => {
                                setFieldValue("emailTitle", e.target.value);
                                setEmailTitle(e.target.value);
                              }}
                              style={{
                                height: "36px",
                                width: 300,
                                borderRadius: 0,
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                              }}
                              sx={{
                                boxShadow: "none",
                                width: 300,
                                ".MuiOutlinedInput-notchedOutline": {
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                },
                              }}
                            />
                          </FormControl>
                        </FormGroup>
                      </div>
                      {languages.length > 0 &&
                        surveys.length > 0 &&
                        languages.map((language) => (
                          <Row
                            key={language.languageID}
                            style={{
                              margin: "auto",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <Card
                              style={{
                                width: "100%",
                                height: "20%",
                                background:
                                  "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "none",
                                border: "1px solid #D9D9D9",
                                borderRadius: "10px",
                              }}
                            >
                              <CardBody>
                                <Form autoComplete="off">
                                  <ScrollToFieldError />

                                  <Row>
                                    <Col>
                                      <div className="row justify-content-center w-100">
                                        <span
                                          className="pl-2 pb-4"
                                          style={{
                                            fontSize: "16px",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {language.languageDescription}
                                        </span>
                                      </div>
                                      <div className="form-group">
                                        <FormGroup
                                          className="col-md-8"
                                          style={{
                                            margin: "0",
                                            width: "100%",
                                            padding: "10px",
                                            marginTop: "-2%",
                                          }}
                                        >
                                          <Field
                                            name={
                                              "surveyTitle" +
                                              language.languageID
                                            }
                                            placeholder={t("surveyTitle")}
                                            value={
                                              surveyTitlesRef.current != null &&
                                              surveyTitlesRef.current.filter(
                                                (x) =>
                                                  x.LanguageID ==
                                                  language.languageID
                                              ).length > 0
                                                ? surveyTitlesRef.current.filter(
                                                    (x) =>
                                                      x.LanguageID ==
                                                      language.languageID
                                                  )[0].SurveyTitle
                                                : ""
                                            }
                                            disabled={
                                              editClicked == true ? false : true
                                            }
                                            onChange={(e) => {
                                              const surveyTitleIndex =
                                                surveyTitlesRef.current.findIndex(
                                                  (x) =>
                                                    x.LanguageID ==
                                                    language.languageID
                                                );
                                              {
                                                if (surveyTitleIndex >= 0)
                                                  surveyTitlesRef.current =
                                                    update(
                                                      surveyTitlesRef.current,
                                                      {
                                                        [surveyTitleIndex]: {
                                                          SurveyTitle: {
                                                            $set: e.target
                                                              .value,
                                                          },
                                                        },
                                                      }
                                                    );
                                                else
                                                  surveyTitlesRef.current = [
                                                    ...surveyTitlesRef.current,
                                                    {
                                                      LanguageID:
                                                        language.languageID,
                                                      SurveyTitle:
                                                        e.target.value,
                                                    },
                                                  ];
                                              }
                                              const surveyIndex =
                                                surveys.findIndex(
                                                  (x) =>
                                                    x.languageID ==
                                                    language.languageID
                                                );
                                              if (surveyIndex >= 0)
                                                setSurveys(
                                                  update(surveys, {
                                                    [surveyIndex]: {
                                                      surveyTitle: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              else
                                                setSurveys([
                                                  ...surveys,
                                                  {
                                                    languageID:
                                                      language.languageID,
                                                    surveyTitle: e.target.value,
                                                  },
                                                ]);
                                            }}
                                            style={{
                                              borderTop: "none",
                                              borderLeft: "none",
                                              borderRight: "none",
                                              fontSize: "24px",
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.surveyTitle &&
                                              touched.surveyTitle
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          {errors.surveyTitle &&
                                            touched.surveyTitle && (
                                              <ErrorMessage
                                                name="surveyTitle"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            )}
                                        </FormGroup>
                                      </div>
                                      <div className="form-group">
                                        <FormGroup
                                          className="col-md-8"
                                          style={{
                                            margin: "0",
                                            width: "100%",
                                            padding: "10px",
                                            marginTop: "-2%",
                                          }}
                                        >
                                          <Field
                                            name={
                                              "surveyDescription" +
                                              language.languageID
                                            }
                                            style={{
                                              borderTop: "none",
                                              borderLeft: "none",
                                              borderRight: "none",
                                            }}
                                            value={
                                              surveyDescriptionsRef.current !=
                                                null &&
                                              surveyDescriptionsRef.current.filter(
                                                (x) =>
                                                  x.LanguageID ==
                                                  language.languageID
                                              ).length > 0
                                                ? surveyDescriptionsRef.current.filter(
                                                    (x) =>
                                                      x.LanguageID ==
                                                      language.languageID
                                                  )[0].SurveyDescription
                                                : ""
                                            }
                                            disabled={
                                              editClicked == true ? false : true
                                            }
                                            onClick={(e) =>
                                              e.target.disabled == false
                                            }
                                            onChange={(e) => {
                                              const surveyDescIndex =
                                                surveyDescriptionsRef.current.findIndex(
                                                  (x) =>
                                                    x.LanguageID ==
                                                    language.languageID
                                                );
                                              if (surveyDescIndex >= 0)
                                                surveyDescriptionsRef.current =
                                                  update(
                                                    surveyDescriptionsRef.current,
                                                    {
                                                      [surveyDescIndex]: {
                                                        SurveyDescription: {
                                                          $set: e.target.value,
                                                        },
                                                      },
                                                    }
                                                  );
                                              else
                                                surveyDescriptionsRef.current =
                                                  [
                                                    ...surveyDescriptionsRef.current,
                                                    {
                                                      LanguageID:
                                                        language.languageID,
                                                      SurveyDescription:
                                                        e.target.value,
                                                    },
                                                  ];
                                              const surveyIndex =
                                                surveys.findIndex(
                                                  (x) =>
                                                    x.languageID ==
                                                    language.languageID
                                                );
                                              if (surveyIndex >= 0)
                                                setSurveys(
                                                  update(surveys, {
                                                    [surveyIndex]: {
                                                      surveyDescription: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              else
                                                setSurveys([
                                                  ...surveys,
                                                  {
                                                    languageID:
                                                      language.languageID,
                                                    surveyDescription:
                                                      e.target.value,
                                                  },
                                                ]);
                                            }}
                                            placeholder={t("surveyDescription")}
                                            className={
                                              "form-control" +
                                              (errors.surveyDescription &&
                                              touched.surveyDescription
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          {errors.surveyDescription &&
                                            touched.surveyDescription && (
                                              <ErrorMessage
                                                name="surveyDescription"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            )}
                                        </FormGroup>
                                      </div>
                                    </Col>
                                  </Row>
                                </Form>
                              </CardBody>
                            </Card>
                          </Row>
                        ))}

                      <div>
                        <p style={{ paddingLeft: "10px", fontSize: "16px" }}>
                          {t("surveyNoQuestions")}:{" "}
                          {surveyQuestionsRef.current[0].Questions.length}
                        </p>
                      </div>
                    </>
                  ) : newQuestion != true ? (
                    <>
                      <Row className="w-100">
                        <div className="form-row col-3">
                          <FormGroup
                            style={{
                              margin: "0",
                              width: "100%",
                              padding: "10px",
                              marginTop: "-2%",
                              marginBottom: "1%",
                            }}
                          >
                            <Field
                              style={{
                                fontSize: "12px",
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                              }}
                              name={"questionType"}
                              as="select"
                              value={
                                surveyQuestionsRef.current[0].Questions[
                                  questionCount - 1
                                ].questionType
                              }
                              onChange={(e) => {
                                handleChange(e, e.target.value);
                                setFieldValue(`questionType`, e.target.value);
                                handleQuestionTypeChange(
                                  e.target.value,
                                  surveyQuestionsRef.current[0].Questions[
                                    questionCount - 1
                                  ].questionID,
                                  false
                                );
                              }}
                              className={
                                "form-control" +
                                (errors.roleId && touched.roleId
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value="" disabled defaultValue selected>
                                {t("surveyQuestionType")}
                              </option>
                              <option value={SurveyAnswerTypes.YesNo}>
                                {SurveyAnswerTypes.YesNo}
                              </option>
                              <option value={SurveyAnswerTypes.MCQ}>
                                {SurveyAnswerTypes.MCQ}
                              </option>
                              <option value={SurveyAnswerTypes.Text}>
                                {SurveyAnswerTypes.Text}
                              </option>
                              {(selectedSurveyType ==
                                "SendSurveyToSSWithInteraction" ||
                                selectedSurveyType ==
                                  "SendSurveyToSSBeforeClosingDate") && (
                                <option value={SurveyAnswerTypes.Dropdown}>
                                  {SurveyAnswerTypes.Dropdown}
                                </option>
                              )}
                            </Field>

                            {errors.questionType && touched.questionType && (
                              <ErrorMessage
                                name="questionType"
                                component="div"
                                className="invalid-feedback"
                              />
                            )}
                          </FormGroup>
                        </div>

                        <div className="form-group col-3">
                          <FormGroup
                            style={{
                              margin: "0",
                              width: "fit-content",
                              padding: "10px",
                              marginTop: "-1%",
                            }}
                          >
                            <Field
                              min="1"
                              type="number"
                              name={"questionOrder"}
                              value={
                                surveyQuestionsRef.current[0].Questions[
                                  questionCount - 1
                                ].questionOrder
                              }
                              placeholder={t("surveyQuestionOrder")}
                              onChange={(e) => {
                                setFieldValue(`questionOrder`, e.target.value);
                                handleQuestionOrderChange(
                                  e.target.value,
                                  surveyQuestionsRef.current[0].Questions[
                                    questionCount - 1
                                  ].questionID,
                                  false
                                );
                              }}
                              style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                              }}
                              className={
                                "form-control" +
                                (errors.questionOrder && touched.questionOrder
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            {errors.questionOrder && touched.questionOrder && (
                              <ErrorMessage
                                name="questionOrder"
                                component="div"
                                className="invalid-feedback"
                              />
                            )}
                          </FormGroup>
                        </div>
                      </Row>
                      <Row className="w-100">
                        {showQuestions == true &&
                          languages.length > 0 &&
                          languages.map((language) => (
                            <Card
                              key={language.languageID}
                              className="mb-0 mt-2"
                              style={{
                                width: "100%",
                                background:
                                  "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "none",
                                border: "1px solid #D9D9D9",
                                borderRadius: "10px",
                              }}
                            >
                              <CardBody>
                                <div className="row justify-content-center w-100">
                                  <span
                                    className="pl-2 pb-4"
                                    style={{
                                      fontSize: "16px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {language.languageDescription}
                                  </span>
                                </div>
                                <Row>
                                  <div className="form-group col-6 pl-0">
                                    <FormGroup
                                      style={{
                                        margin: "0",
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "-1%",
                                      }}
                                    >
                                      <Field
                                        min="1"
                                        type="text"
                                        value={
                                          surveyQuestionsRef.current.filter(
                                            (x) =>
                                              x.LanguageID ==
                                              language.languageID
                                          ).length > 0
                                            ? surveyQuestionsRef.current.filter(
                                                (x) =>
                                                  x.LanguageID ==
                                                  language.languageID
                                              )[0].Questions[questionCount - 1]
                                                .questionText
                                            : ""
                                        }
                                        name={
                                          "questionTitle" + language.languageID
                                        }
                                        style={{
                                          borderTop: "none",
                                          borderLeft: "none",
                                          borderRight: "none",
                                          width: "100%",
                                        }}
                                        onChange={(e) => {
                                          let id =
                                            surveyQuestionsRef.current[0]
                                              .Questions[questionCount - 1]
                                              .questionID;
                                          setFieldValue(
                                            `questionTitle${language.languageID}`,
                                            e.target.value
                                          );
                                          const questionsIndex =
                                            questions.findIndex(
                                              (x) =>
                                                x.LanguageID ==
                                                language.languageID
                                            );
                                          const questionsRefIndex =
                                            surveyQuestionsRef.current.findIndex(
                                              (x) =>
                                                x.LanguageID ==
                                                language.languageID
                                            );
                                          if (questionsRefIndex >= 0) {
                                            const questionsIDIndex =
                                              surveyQuestionsRef.current[
                                                questionsRefIndex
                                              ].Questions.findIndex(
                                                (x) => x.questionID == id
                                              );
                                            // console.log(questionsIndex);
                                            if (questionsIDIndex < 0) {
                                              questionsRef.current = [
                                                ...questionsRef.current,
                                                {
                                                  questionID: id,
                                                  questionText: e.target.value,
                                                  languageID:
                                                    language.languageID,
                                                },
                                              ];
                                              setQuestions([
                                                ...questions,
                                                {
                                                  questionID: id,
                                                  questionText: e.target.value,
                                                  languageID:
                                                    language.languageID,
                                                },
                                              ]);
                                              surveyQuestionsRef.current[
                                                questionsRefIndex
                                              ].Questions = [
                                                ...surveyQuestionsRef.current[
                                                  questionsRefIndex
                                                ].Questions,
                                                {
                                                  questionID: id,
                                                  questionText: e.target.value,
                                                  languageID:
                                                    language.languageID,
                                                },
                                              ];
                                            } else {
                                              // questionsRef.current = update(
                                              //   questionsRef.current,
                                              //   {
                                              //     [questionsIndex]: {
                                              //       QuestionText: {
                                              //         $set: e.target.value,
                                              //       },
                                              //       QuestionID: {
                                              //         $set: uuid.current,
                                              //       },
                                              //     },
                                              //   }
                                              // );
                                              surveyQuestionsRef.current[
                                                questionsRefIndex
                                              ].Questions = update(
                                                surveyQuestionsRef.current[
                                                  questionsRefIndex
                                                ].Questions,
                                                {
                                                  [questionsIDIndex]: {
                                                    questionText: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                              // setQuestions(
                                              //   update(questions, {
                                              //     [questionsIndex]: {
                                              //       QuestionText: {
                                              //         $set: e.target.value,
                                              //       },
                                              //       QuestionID: {
                                              //         $set: uuid.current,
                                              //       },
                                              //     },
                                              //   })
                                              // );
                                            }
                                          } else {
                                            let i =
                                              surveyQuestionsRef.current.length;
                                            surveyQuestionsRef.current[i] = {
                                              LanguageID: language.languageID,
                                              Questions: [
                                                {
                                                  questionID: id,
                                                  questionText: e.target.value,
                                                  languageID:
                                                    language.languageID,
                                                  questionType:
                                                    surveyQuestionsRef
                                                      .current[0].Questions[
                                                      questionCount - 1
                                                    ].questionType,
                                                  questionOrder:
                                                    surveyQuestionsRef
                                                      .current[0].Questions[
                                                      questionCount - 1
                                                    ].questionOrder,
                                                },
                                              ],
                                            };
                                          }
                                          surveys.forEach((survey) => {
                                            if (
                                              survey.languageID ===
                                              language.languageID
                                            ) {
                                              const userIndex =
                                                surveys.findIndex(
                                                  (x) =>
                                                    x.languageID ===
                                                    language.languageID
                                                );
                                              if (userIndex >= 0) {
                                                let surveyArr = surveys;
                                                if (
                                                  surveyArr[userIndex]
                                                    .questions == undefined
                                                ) {
                                                  surveyArr[
                                                    userIndex
                                                  ].questions = [
                                                    {
                                                      questionID: id,
                                                      questionText:
                                                        e.target.value,
                                                      languageID:
                                                        language.languageID,
                                                      questionType:
                                                        surveyQuestionsRef
                                                          .current[0].Questions[
                                                          questionCount - 1
                                                        ].questionType,
                                                      questionOrder:
                                                        surveyQuestionsRef
                                                          .current[0].Questions[
                                                          questionCount - 1
                                                        ].questionOrder,
                                                    },
                                                  ];
                                                } else
                                                  surveyArr[
                                                    userIndex
                                                  ].questions =
                                                    surveyQuestionsRef.current[
                                                      userIndex
                                                    ].Questions;
                                                setSurveys(surveyArr);
                                              }
                                            }
                                          });
                                        }}
                                        placeholder={t(
                                          "surveyUntitledQuestion"
                                        )}
                                        className={
                                          "form-control" +
                                          (errors.questionTitle &&
                                          touched.questionTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      {errors.questionTitle &&
                                        touched.questionTitle && (
                                          <ErrorMessage
                                            name="questionTitle"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                    </FormGroup>
                                  </div>
                                </Row>

                                {surveyQuestionsRef.current[
                                  surveyQuestionsRef.current.findIndex(
                                    (x) => x.LanguageID == language.languageID
                                  )
                                ] != null &&
                                surveyQuestionsRef.current[
                                  surveyQuestionsRef.current.findIndex(
                                    (x) => x.LanguageID == language.languageID
                                  )
                                ].Questions[questionCount - 1].questionType ==
                                  SurveyAnswerTypes.MCQ ? (
                                  <>
                                    <div className="form-group mt-4">
                                      <FormGroup
                                        className="col-12 col-md-6 col-lg-5 col-sm-12"
                                        style={{
                                          margin: "0",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <Field
                                          name={"answer1" + language.languageID}
                                          placeholder={t("surveyAnswer1")}
                                          className={
                                            "form-control" +
                                            (errors.answer1 && touched.answer1
                                              ? " is-invalid"
                                              : "")
                                          }
                                          style={{
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none",
                                          }}
                                          // value={
                                          //   surveyQuestionsRef.current.filter(
                                          //     (x) =>
                                          //       x.LanguageID ==
                                          //       language.languageID
                                          //   ).length > 0
                                          //     ? surveyQuestionsRef.current.filter(
                                          //         (x) =>
                                          //           x.LanguageID ==
                                          //           language.languageID
                                          //       )[0].Questions[
                                          //         questionCount - 1
                                          //       ].answers.length > 0 &&
                                          //       surveyQuestionsRef.current.filter(
                                          //         (x) =>
                                          //           x.LanguageID ==
                                          //           language.languageID
                                          //       )[0].Questions[
                                          //         questionCount - 1
                                          //       ].answers[0] != null
                                          //       ? surveyQuestionsRef.current.filter(
                                          //           (x) =>
                                          //             x.LanguageID ==
                                          //             language.languageID
                                          //         )[0].Questions[
                                          //           questionCount - 1
                                          //         ].answers[0].answer
                                          //       : ""
                                          //     : ""
                                          // }
                                          // onChange={(e) => {}}
                                          id={uuidv4()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `answer1${language.languageID}`,
                                              e.target.value
                                            );
                                            const index =
                                              surveyQuestionsRef.current.findIndex(
                                                (x) =>
                                                  x.LanguageID ==
                                                  language.languageID
                                              );
                                            const answersArr =
                                              surveyQuestionsRef.current[index]
                                                .Questions[questionCount - 1]
                                                .answers;
                                            answersRef.current =
                                              answersArr == null
                                                ? []
                                                : answersArr;
                                            const answersIndex =
                                              answersRef.current.findIndex(
                                                (x) =>
                                                  x.answerID ==
                                                  answer1_id.current
                                              );
                                            if (answersIndex < 0) {
                                              setAnswers([
                                                ...answers,
                                                {
                                                  answer: e.target.value,
                                                  answerID: answer1_id.current,
                                                  languageID:
                                                    language.languageID,
                                                  answerOrder: 1,
                                                },
                                              ]);
                                              answersRef.current = [
                                                ...answersRef.current,
                                                {
                                                  answer: e.target.value,
                                                  answerID: answer1_id.current,
                                                  languageID:
                                                    language.languageID,
                                                  answerOrder: 1,
                                                },
                                              ];
                                            } else {
                                              if (
                                                answersRef.current.filter(
                                                  (x) =>
                                                    x.languageID !=
                                                    language.languageID
                                                ).length > 0
                                              ) {
                                                setAnswers([
                                                  {
                                                    answer: e.target.value,
                                                    answerID:
                                                      answer1_id.current,
                                                    languageID:
                                                      language.languageID,
                                                    answerOrder: 1,
                                                  },
                                                ]);
                                                answersRef.current = [
                                                  {
                                                    answer: e.target.value,
                                                    answerID:
                                                      answer1_id.current,
                                                    languageID:
                                                      language.languageID,
                                                    answerOrder: 1,
                                                  },
                                                ];
                                              } else
                                                setAnswers(
                                                  update(answers, {
                                                    [answersIndex]: {
                                                      answer: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              answersRef.current = update(
                                                answersRef.current,
                                                {
                                                  [answersIndex]: {
                                                    answer: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                            handleAnswerChange(
                                              language.languageID,
                                              false
                                            );
                                          }}
                                        />
                                        {errors.answer1 && touched.answer1 && (
                                          <ErrorMessage
                                            name={
                                              "answer1" + language.languageID
                                            }
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-group">
                                      <FormGroup
                                        className="col-12 col-md-6 col-lg-5 col-sm-12"
                                        style={{
                                          margin: "0",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <Field
                                          name={"answer2" + language.languageID}
                                          placeholder={t("surveyAnswer2")}
                                          className={
                                            "form-control" +
                                            (errors.answer2 && touched.answer2
                                              ? " is-invalid"
                                              : "")
                                          }
                                          style={{
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none",
                                          }}
                                          // value={
                                          //   surveyQuestionsRef.current.filter(
                                          //     (x) =>
                                          //       x.LanguageID ==
                                          //       language.languageID
                                          //   ).length > 0
                                          //     ? surveyQuestionsRef.current.filter(
                                          //         (x) =>
                                          //           x.LanguageID ==
                                          //           language.languageID
                                          //       )[0].Questions[
                                          //         questionCount - 1
                                          //       ].answers.length > 0 &&
                                          //       surveyQuestionsRef.current.filter(
                                          //         (x) =>
                                          //           x.LanguageID ==
                                          //           language.languageID
                                          //       )[0].Questions[
                                          //         questionCount - 1
                                          //       ].answers[1] != null
                                          //       ? surveyQuestionsRef.current.filter(
                                          //           (x) =>
                                          //             x.LanguageID ==
                                          //             language.languageID
                                          //         )[0].Questions[
                                          //           questionCount - 1
                                          //         ].answers[1].answer
                                          //       : ""
                                          //     : ""
                                          // }
                                          // onChange={(e) => {}}
                                          id={uuidv4()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `answer2${language.languageID}`,
                                              e.target.value
                                            );
                                            const index =
                                              surveyQuestionsRef.current.findIndex(
                                                (x) =>
                                                  x.LanguageID ==
                                                  language.languageID
                                              );
                                            const answersArr =
                                              surveyQuestionsRef.current[index]
                                                .Questions[questionCount - 1]
                                                .answers;
                                            answersRef.current =
                                              answersArr == null
                                                ? []
                                                : answersArr;
                                            const answersIndex =
                                              answersRef.current.findIndex(
                                                (x) =>
                                                  x.answerID ==
                                                  answer2_id.current
                                              );
                                            if (answersIndex < 0) {
                                              setAnswers([
                                                ...answers,
                                                {
                                                  answer: e.target.value,
                                                  answerID: answer2_id.current,
                                                  languageID:
                                                    language.languageID,
                                                  answerOrder: 2,
                                                },
                                              ]);
                                              answersRef.current = [
                                                ...answersRef.current,
                                                {
                                                  answer: e.target.value,
                                                  answerID: answer2_id.current,
                                                  languageID:
                                                    language.languageID,
                                                  answerOrder: 2,
                                                },
                                              ];
                                            } else {
                                              if (
                                                answersRef.current.filter(
                                                  (x) =>
                                                    x.languageID !=
                                                    language.languageID
                                                ).length > 0
                                              ) {
                                                setAnswers([
                                                  {
                                                    answer: e.target.value,
                                                    answerID:
                                                      answer2_id.current,
                                                    languageID:
                                                      language.languageID,
                                                    answerOrder: 2,
                                                  },
                                                ]);
                                                answersRef.current = [
                                                  {
                                                    answer: e.target.value,
                                                    answerID:
                                                      answer2_id.current,
                                                    languageID:
                                                      language.languageID,
                                                    answerOrder: 2,
                                                  },
                                                ];
                                              } else
                                                setAnswers(
                                                  update(answers, {
                                                    [answersIndex]: {
                                                      answer: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              answersRef.current = update(
                                                answersRef.current,
                                                {
                                                  [answersIndex]: {
                                                    answer: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                            handleAnswerChange(
                                              language.languageID,
                                              false
                                            );
                                          }}
                                        />
                                        {errors.answer2 && touched.answer2 && (
                                          <ErrorMessage
                                            name={
                                              "answer2" + language.languageID
                                            }
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-group">
                                      <FormGroup
                                        className="col-12 col-md-6 col-lg-5 col-sm-12"
                                        style={{
                                          margin: "0",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <Field
                                          name={"answer3" + language.languageID}
                                          placeholder={t("surveyAnswer3")}
                                          className={
                                            "form-control" +
                                            (errors.answer3 && touched.answer3
                                              ? " is-invalid"
                                              : "")
                                          }
                                          style={{
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none",
                                          }}
                                          // value={
                                          //   surveyQuestionsRef.current.filter(
                                          //     (x) =>
                                          //       x.LanguageID ==
                                          //       language.languageID
                                          //   ).length > 0
                                          //     ? surveyQuestionsRef.current.filter(
                                          //         (x) =>
                                          //           x.LanguageID ==
                                          //           language.languageID
                                          //       )[0].Questions[
                                          //         questionCount - 1
                                          //       ].answers.length > 0 &&
                                          //       surveyQuestionsRef.current.filter(
                                          //         (x) =>
                                          //           x.LanguageID ==
                                          //           language.languageID
                                          //       )[0].Questions[
                                          //         questionCount - 1
                                          //       ].answers[2] != null
                                          //       ? surveyQuestionsRef.current.filter(
                                          //           (x) =>
                                          //             x.LanguageID ==
                                          //             language.languageID
                                          //         )[0].Questions[
                                          //           questionCount - 1
                                          //         ].answers[2].answer
                                          //       : ""
                                          //     : ""
                                          // }
                                          // onChange={(e) => {}}
                                          id={uuidv4()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `answer3${language.languageID}`,
                                              e.target.value
                                            );
                                            const index =
                                              surveyQuestionsRef.current.findIndex(
                                                (x) =>
                                                  x.LanguageID ==
                                                  language.languageID
                                              );
                                            const answersArr =
                                              surveyQuestionsRef.current[index]
                                                .Questions[questionCount - 1]
                                                .answers;
                                            answersRef.current =
                                              answersArr == null
                                                ? []
                                                : answersArr;
                                            const answersIndex =
                                              answersRef.current.findIndex(
                                                (x) =>
                                                  x.answerID ==
                                                  answer3_id.current
                                              );
                                            if (answersIndex < 0) {
                                              setAnswers([
                                                ...answers,
                                                {
                                                  answer: e.target.value,
                                                  answerID: answer3_id.current,
                                                  languageID:
                                                    language.languageID,
                                                  answerOrder: 3,
                                                },
                                              ]);
                                              answersRef.current = [
                                                ...answersRef.current,
                                                {
                                                  answer: e.target.value,
                                                  answerID: answer3_id.current,
                                                  languageID:
                                                    language.languageID,
                                                  answerOrder: 3,
                                                },
                                              ];
                                            } else {
                                              if (
                                                answersRef.current.filter(
                                                  (x) =>
                                                    x.languageID !=
                                                    language.languageID
                                                ).length > 0
                                              ) {
                                                setAnswers([
                                                  {
                                                    answer: e.target.value,
                                                    answerID:
                                                      answer3_id.current,
                                                    languageID:
                                                      language.languageID,
                                                    answerOrder: 3,
                                                  },
                                                ]);
                                                answersRef.current = [
                                                  {
                                                    answer: e.target.value,
                                                    answerID:
                                                      answer3_id.current,
                                                    languageID:
                                                      language.languageID,
                                                    answerOrder: 3,
                                                  },
                                                ];
                                              } else
                                                setAnswers(
                                                  update(answers, {
                                                    [answersIndex]: {
                                                      answer: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              answersRef.current = update(
                                                answersRef.current,
                                                {
                                                  [answersIndex]: {
                                                    answer: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                            handleAnswerChange(
                                              language.languageID,
                                              false
                                            );
                                          }}
                                        />
                                        {errors.answer3 && touched.answer3 && (
                                          <ErrorMessage
                                            name={
                                              "answer3" + language.languageID
                                            }
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-group">
                                      <FormGroup
                                        className="col-12 col-md-6 col-lg-5 col-sm-12"
                                        style={{
                                          margin: "0",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <Field
                                          name={"answer4" + language.languageID}
                                          placeholder={t("surveyAnswer4")}
                                          className={
                                            "form-control" +
                                            (errors.answer4 && touched.answer4
                                              ? " is-invalid"
                                              : "")
                                          }
                                          style={{
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none",
                                          }}
                                          // value={
                                          //   surveyQuestionsRef.current.filter(
                                          //     (x) =>
                                          //       x.LanguageID ==
                                          //       language.languageID
                                          //   ).length > 0
                                          //     ? surveyQuestionsRef.current.filter(
                                          //         (x) =>
                                          //           x.LanguageID ==
                                          //           language.languageID
                                          //       )[0].Questions[
                                          //         questionCount - 1
                                          //       ].answers.length > 0 &&
                                          //       surveyQuestionsRef.current.filter(
                                          //         (x) =>
                                          //           x.LanguageID ==
                                          //           language.languageID
                                          //       )[0].Questions[
                                          //         questionCount - 1
                                          //       ].answers[3] != null
                                          //       ? surveyQuestionsRef.current.filter(
                                          //           (x) =>
                                          //             x.LanguageID ==
                                          //             language.languageID
                                          //         )[0].Questions[
                                          //           questionCount - 1
                                          //         ].answers[3].answer
                                          //       : ""
                                          //     : ""
                                          // }
                                          // onChange={(e) => {}}
                                          id={uuidv4()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `answer4${language.languageID}`,
                                              e.target.value
                                            );
                                            const index =
                                              surveyQuestionsRef.current.findIndex(
                                                (x) =>
                                                  x.LanguageID ==
                                                  language.languageID
                                              );
                                            const answersArr =
                                              surveyQuestionsRef.current[index]
                                                .Questions[questionCount - 1]
                                                .answers;
                                            answersRef.current =
                                              answersArr == null
                                                ? []
                                                : answersArr;
                                            const answersIndex =
                                              answersRef.current.findIndex(
                                                (x) =>
                                                  x.answerID ==
                                                  answer4_id.current
                                              );
                                            if (answersIndex < 0) {
                                              setAnswers([
                                                ...answers,
                                                {
                                                  answer: e.target.value,
                                                  answerID: answer4_id.current,
                                                  languageID:
                                                    language.languageID,
                                                  answerOrder: 4,
                                                },
                                              ]);
                                              answersRef.current = [
                                                ...answersRef.current,
                                                {
                                                  answer: e.target.value,
                                                  answerID: answer4_id.current,
                                                  languageID:
                                                    language.languageID,
                                                  answerOrder: 4,
                                                },
                                              ];
                                            } else {
                                              if (
                                                answersRef.current.filter(
                                                  (x) =>
                                                    x.languageID !=
                                                    language.languageID
                                                ).length > 0
                                              ) {
                                                setAnswers([
                                                  {
                                                    answer: e.target.value,
                                                    answerID:
                                                      answer4_id.current,
                                                    languageID:
                                                      language.languageID,
                                                    answerOrder: 4,
                                                  },
                                                ]);
                                                answersRef.current = [
                                                  {
                                                    answer: e.target.value,
                                                    answerID:
                                                      answer4_id.current,
                                                    languageID:
                                                      language.languageID,
                                                    answerOrder: 4,
                                                  },
                                                ];
                                              } else
                                                setAnswers(
                                                  update(answers, {
                                                    [answersIndex]: {
                                                      answer: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              answersRef.current = update(
                                                answersRef.current,
                                                {
                                                  [answersIndex]: {
                                                    answer: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                            handleAnswerChange(
                                              language.languageID,
                                              false
                                            );
                                          }}
                                        />
                                        {errors.answer4 && touched.answer4 && (
                                          <ErrorMessage
                                            name={
                                              "answer4" + language.languageID
                                            }
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </CardBody>
                            </Card>
                          ))}
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row className="w-100">
                        <div className="form-row col-3">
                          <FormGroup
                            style={{
                              margin: "0",
                              width: "100%",
                              padding: "10px",
                              marginTop: "-2%",
                              marginBottom: "1%",
                            }}
                          >
                            <Field
                              style={{
                                fontSize: "12px",
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                              }}
                              name={"questionType"}
                              as="select"
                              onChange={(e) => {
                                handleChange(e, e.target.value);
                                setFieldValue(`questionType`, e.target.value);
                                handleQuestionTypeChange(
                                  e.target.value,
                                  null,
                                  true
                                );
                              }}
                              className={
                                "form-control" +
                                (errors.roleId && touched.roleId
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value="" disabled defaultValue selected>
                                {t("surveyQuestionType")}
                              </option>
                              <option value={SurveyAnswerTypes.YesNo}>
                                {SurveyAnswerTypes.YesNo}
                              </option>
                              <option value={SurveyAnswerTypes.MCQ}>
                                {SurveyAnswerTypes.MCQ}
                              </option>
                              <option value={SurveyAnswerTypes.Text}>
                                {SurveyAnswerTypes.Text}
                              </option>
                              {(selectedSurveyType ==
                                "SendSurveyToSSWithInteraction" ||
                                selectedSurveyType ==
                                  "SendSurveyToSSBeforeClosingDate") && (
                                <option value={SurveyAnswerTypes.Dropdown}>
                                  {SurveyAnswerTypes.Dropdown}
                                </option>
                              )}
                            </Field>

                            {errors.questionType && touched.questionType && (
                              <ErrorMessage
                                name="questionType"
                                component="div"
                                className="invalid-feedback"
                              />
                            )}
                          </FormGroup>
                        </div>

                        <div className="form-group col-3">
                          <FormGroup
                            style={{
                              margin: "0",
                              width: "fit-content",
                              padding: "10px",
                              marginTop: "-1%",
                            }}
                          >
                            <Field
                              min="1"
                              type="number"
                              name={"questionOrder"}
                              placeholder={t("surveyQuestionOrder")}
                              disabled={typeRef.current == null ? true : false}
                              onChange={(e) => {
                                setFieldValue(`questionOrder`, e.target.value);
                                handleQuestionOrderChange(
                                  e.target.value,
                                  null,
                                  true
                                );
                              }}
                              style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                              }}
                              className={
                                "form-control" +
                                (errors.questionOrder && touched.questionOrder
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            {errors.questionOrder && touched.questionOrder && (
                              <ErrorMessage
                                name="questionOrder"
                                component="div"
                                className="invalid-feedback"
                              />
                            )}
                          </FormGroup>
                        </div>
                      </Row>
                      <Row className="w-100">
                        {showQuestions == true &&
                          languages.length > 0 &&
                          languages.map((language) => (
                            <Card
                              key={language.languageID}
                              className="mb-0 mt-2"
                              style={{
                                width: "100%",
                                background:
                                  "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "none",
                                border: "1px solid #D9D9D9",
                                borderRadius: "10px",
                              }}
                            >
                              <CardBody>
                                <div className="row justify-content-center w-100">
                                  <span
                                    className="pl-2 pb-4"
                                    style={{
                                      fontSize: "16px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {language.languageDescription}
                                  </span>
                                </div>
                                <Row>
                                  <div className="form-group col-6 pl-0">
                                    <FormGroup
                                      style={{
                                        margin: "0",
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "-1%",
                                      }}
                                    >
                                      <Field
                                        min="1"
                                        type="text"
                                        name={
                                          "questionTitle" + language.languageID
                                        }
                                        style={{
                                          borderTop: "none",
                                          borderLeft: "none",
                                          borderRight: "none",
                                          width: "100%",
                                        }}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `questionTitle${language.languageID}`,
                                            e.target.value
                                          );
                                          // const questionsIndex =
                                          //   questions.findIndex(
                                          //     (x) =>
                                          //       x.LanguageID ==
                                          //       language.languageID
                                          //   );
                                          // // console.log(questionsIndex);
                                          // if (questionsIndex < 0) {
                                          //   questionsRef.current = [
                                          //     ...questionsRef.current,
                                          //     {
                                          //       QuestionID: uuid.current,
                                          //       QuestionText: e.target.value,
                                          //       LanguageID: language.languageID,
                                          //     },
                                          //   ];
                                          //   setQuestions([
                                          //     ...questions,
                                          //     {
                                          //       QuestionID: uuid.current,
                                          //       QuestionText: e.target.value,
                                          //       LanguageID: language.languageID,
                                          //     },
                                          //   ]);
                                          // } else {
                                          //   questionsRef.current = update(
                                          //     questionsRef.current,
                                          //     {
                                          //       [questionsIndex]: {
                                          //         QuestionText: {
                                          //           $set: e.target.value,
                                          //         },
                                          //         QuestionID: {
                                          //           $set: uuid.current,
                                          //         },
                                          //       },
                                          //     }
                                          //   );
                                          //   setQuestions(
                                          //     update(questions, {
                                          //       [questionsIndex]: {
                                          //         QuestionText: {
                                          //           $set: e.target.value,
                                          //         },
                                          //         QuestionID: {
                                          //           $set: uuid.current,
                                          //         },
                                          //       },
                                          //     })
                                          //   );
                                          // }
                                          const questionsIndex =
                                            surveyQuestionsRef.current.findIndex(
                                              (x) =>
                                                x.LanguageID ==
                                                language.languageID
                                            );
                                          let questionsArr =
                                            surveyQuestionsRef.current[
                                              questionsIndex
                                            ].Questions;
                                          questionsArr.forEach((question) => {
                                            const questionsIDIndex =
                                              questionsArr.findIndex(
                                                (x) =>
                                                  x.questionID == uuid.current
                                              );
                                            if (questionsIDIndex >= 0) {
                                              surveyQuestionsRef.current[
                                                questionsIndex
                                              ].Questions = update(
                                                surveyQuestionsRef.current[
                                                  questionsIndex
                                                ].Questions,
                                                {
                                                  [questionsIDIndex]: {
                                                    questionText: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            } else {
                                              const newSurveyQuestion = {
                                                questionID: uuid.current,
                                                questionText: e.target.value,
                                                languageID: language.languageID,
                                                answers: [],
                                              };
                                              surveyQuestionsRef.current[
                                                questionsIndex
                                              ].Questions = update(
                                                surveyQuestionsRef.current[
                                                  questionsIndex
                                                ].Questions,
                                                {
                                                  $push: [newSurveyQuestion],
                                                }
                                              );
                                            }
                                          });
                                          surveys.forEach((survey) => {
                                            const userIndex = surveys.findIndex(
                                              (x) =>
                                                x.languageID ===
                                                language.languageID
                                            );
                                            let surveyArr = surveys;
                                            if (
                                              surveyArr[userIndex].questions ==
                                              undefined
                                            ) {
                                              surveyArr[userIndex].questions =
                                                [];
                                            }
                                            surveyArr[userIndex].questions =
                                              surveyQuestionsRef.current[
                                                questionsIndex
                                              ].Questions;
                                            setSurveys(surveyArr);
                                          });
                                        }}
                                        placeholder={t(
                                          "surveyUntitledQuestion"
                                        )}
                                        className={
                                          "form-control" +
                                          (errors.questionTitle &&
                                          touched.questionTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      {errors.questionTitle &&
                                        touched.questionTitle && (
                                          <ErrorMessage
                                            name="questionTitle"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                    </FormGroup>
                                  </div>
                                </Row>

                                {type == SurveyAnswerTypes.MCQ ? (
                                  <>
                                    <div className="form-group mt-4">
                                      <FormGroup
                                        className="col-12 col-md-6 col-lg-5 col-sm-12"
                                        style={{
                                          margin: "0",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <Field
                                          name={"answer1" + language.languageID}
                                          placeholder={t("surveyAnswer1")}
                                          className={
                                            "form-control" +
                                            (errors.answer1 && touched.answer1
                                              ? " is-invalid"
                                              : "")
                                          }
                                          style={{
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none",
                                          }}
                                          id={uuidv4()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `answer1${language.languageID}`,
                                              e.target.value
                                            );
                                            const answersIndex =
                                              answers.findIndex(
                                                (x) =>
                                                  x.AnswerID ==
                                                  answer1_id.current
                                              );
                                            if (answersIndex < 0) {
                                              setAnswers([
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer1_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 1,
                                                },
                                              ]);
                                              answersRef.current = [
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer1_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 1,
                                                },
                                              ];
                                            } else {
                                              if (
                                                answers.filter(
                                                  (x) =>
                                                    x.LanguageID !=
                                                    language.languageID
                                                ).length > 0
                                              ) {
                                                setAnswers([
                                                  ...answers,
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer1_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 1,
                                                  },
                                                ]);
                                                answersRef.current = [
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer1_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 1,
                                                  },
                                                ];
                                              } else
                                                setAnswers(
                                                  update(answers, {
                                                    [answersIndex]: {
                                                      Answer: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              answersRef.current = update(
                                                answers,
                                                {
                                                  [answersIndex]: {
                                                    Answer: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                            handleAnswerChange(
                                              language.languageID,
                                              true
                                            );
                                          }}
                                        />
                                        {errors.answer1 && touched.answer1 && (
                                          <ErrorMessage
                                            name={
                                              "answer1" + language.languageID
                                            }
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-group">
                                      <FormGroup
                                        className="col-12 col-md-6 col-lg-5 col-sm-12"
                                        style={{
                                          margin: "0",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <Field
                                          name={"answer2" + language.languageID}
                                          placeholder={t("surveyAnswer2")}
                                          className={
                                            "form-control" +
                                            (errors.answer2 && touched.answer2
                                              ? " is-invalid"
                                              : "")
                                          }
                                          style={{
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none",
                                          }}
                                          id={uuidv4()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `answer2${language.languageID}`,
                                              e.target.value
                                            );
                                            const answersIndex =
                                              answers.findIndex(
                                                (x) =>
                                                  x.AnswerID ==
                                                  answer2_id.current
                                              );
                                            if (answersIndex < 0) {
                                              setAnswers([
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer2_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 2,
                                                },
                                              ]);
                                              answersRef.current = [
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer2_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 2,
                                                },
                                              ];
                                            } else {
                                              if (
                                                answers.filter(
                                                  (x) =>
                                                    x.LanguageID !=
                                                    language.languageID
                                                ).length > 0
                                              ) {
                                                setAnswers([
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer2_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 2,
                                                  },
                                                ]);
                                                answersRef.current = [
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer2_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 2,
                                                  },
                                                ];
                                              } else
                                                setAnswers(
                                                  update(answers, {
                                                    [answersIndex]: {
                                                      Answer: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              answersRef.current = update(
                                                answers,
                                                {
                                                  [answersIndex]: {
                                                    Answer: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                            handleAnswerChange(
                                              language.languageID,
                                              true
                                            );
                                          }}
                                        />
                                        {errors.answer2 && touched.answer2 && (
                                          <ErrorMessage
                                            name={
                                              "answer2" + language.languageID
                                            }
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-group">
                                      <FormGroup
                                        className="col-12 col-md-6 col-lg-5 col-sm-12"
                                        style={{
                                          margin: "0",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <Field
                                          name={"answer3" + language.languageID}
                                          placeholder={t("surveyAnswer3")}
                                          className={
                                            "form-control" +
                                            (errors.answer3 && touched.answer3
                                              ? " is-invalid"
                                              : "")
                                          }
                                          style={{
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none",
                                          }}
                                          id={uuidv4()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `answer3${language.languageID}`,
                                              e.target.value
                                            );
                                            const answersIndex =
                                              answers.findIndex(
                                                (x) =>
                                                  x.AnswerID ==
                                                  answer3_id.current
                                              );
                                            if (answersIndex < 0) {
                                              setAnswers([
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer3_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 3,
                                                },
                                              ]);
                                              answersRef.current = [
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer3_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 3,
                                                },
                                              ];
                                            } else {
                                              if (
                                                answers.filter(
                                                  (x) =>
                                                    x.LanguageID !=
                                                    language.languageID
                                                ).length > 0
                                              ) {
                                                setAnswers([
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer3_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 3,
                                                  },
                                                ]);
                                                answersRef.current = [
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer3_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 3,
                                                  },
                                                ];
                                              } else
                                                setAnswers(
                                                  update(answers, {
                                                    [answersIndex]: {
                                                      Answer: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              answersRef.current = update(
                                                answers,
                                                {
                                                  [answersIndex]: {
                                                    Answer: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                            handleAnswerChange(
                                              language.languageID,
                                              true
                                            );
                                          }}
                                        />
                                        {errors.answer3 && touched.answer3 && (
                                          <ErrorMessage
                                            name={
                                              "answer3" + language.languageID
                                            }
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-group">
                                      <FormGroup
                                        className="col-12 col-md-6 col-lg-5 col-sm-12"
                                        style={{
                                          margin: "0",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <Field
                                          name={"answer4" + language.languageID}
                                          placeholder={t("surveyAnswer4")}
                                          className={
                                            "form-control" +
                                            (errors.answer4 && touched.answer4
                                              ? " is-invalid"
                                              : "")
                                          }
                                          style={{
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none",
                                          }}
                                          id={uuidv4()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `answer4${language.languageID}`,
                                              e.target.value
                                            );
                                            const answersIndex =
                                              answers.findIndex(
                                                (x) =>
                                                  x.AnswerID ==
                                                  answer4_id.current
                                              );
                                            if (answersIndex < 0) {
                                              setAnswers([
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer4_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 4,
                                                },
                                              ]);
                                              answersRef.current = [
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer4_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 4,
                                                },
                                              ];
                                            } else {
                                              if (
                                                answers.filter(
                                                  (x) =>
                                                    x.LanguageID !=
                                                    language.languageID
                                                ).length > 0
                                              ) {
                                                setAnswers([
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer4_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 4,
                                                  },
                                                ]);
                                                answersRef.current = [
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer4_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 4,
                                                  },
                                                ];
                                              } else
                                                setAnswers(
                                                  update(answers, {
                                                    [answersIndex]: {
                                                      Answer: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              answersRef.current = update(
                                                answers,
                                                {
                                                  [answersIndex]: {
                                                    Answer: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                            handleAnswerChange(
                                              language.languageID,
                                              true
                                            );
                                          }}
                                        />
                                        {errors.answer4 && touched.answer4 && (
                                          <ErrorMessage
                                            name={
                                              "answer4" + language.languageID
                                            }
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </CardBody>
                            </Card>
                          ))}
                      </Row>
                    </>
                  )}
                  {/* </CardBody>
                  </Card> */}
                  <div className="form-row">
                    <FormGroup
                      style={{
                        margin: "0 auto",
                        width: "100%",
                        padding: "10px",
                      }}
                    >
                      <div className={rtl ? "float-left" : "float-right"}>
                        <Button
                          color="primary"
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => history.push(`..`)}
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          {t("cancel.link")}
                        </Button>
                        {showQuestions == false && (
                          <>
                            <Button
                              color="primary"
                              type="button"
                              disabled={isSubmitting}
                              onClick={(e) => {
                                setEditClicked(true);
                                e.target.disabled = true;
                              }}
                            >
                              {isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              )}
                              {t("edit.button")}
                            </Button>

                            <Button
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              )}
                              {t("surveySubmit")}
                            </Button>
                            <Button
                              color="primary"
                              type="button"
                              disabled={isSubmitting}
                              onClick={() =>
                                handleAddQuestion(values, setFieldValue)
                              }
                            >
                              {isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              )}
                              {t("surveyQuestions")}
                            </Button>
                          </>
                        )}
                        {showQuestions == true && (
                          <>
                            <Button
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              )}
                              {t("done")}
                            </Button>
                            {newQuestion == false ? (
                              <Button
                                color="primary"
                                type="button"
                                disabled={isSubmitting}
                                onClick={() => setDeleteAlert(!deleteAlert)}
                              >
                                {isSubmitting && (
                                  <span className="spinner-border spinner-border-sm mr-1"></span>
                                )}
                                {t("surveyDeleteQuestion")}
                              </Button>
                            ) : (
                              <></>
                            )}
                            {totalNumberOfQuestions.current != questionCount ? (
                              <Button
                                color="primary"
                                type="button"
                                disabled={isSubmitting}
                                onClick={() =>
                                  handleNextQuestion(setFieldValue, false)
                                }
                              >
                                {isSubmitting && (
                                  <span className="spinner-border spinner-border-sm mr-1"></span>
                                )}
                                {t("surveyNextQuestion")}
                              </Button>
                            ) : newQuestion == false ? (
                              <Button
                                color="primary"
                                type="button"
                                disabled={isSubmitting}
                                onClick={() => handleNewQuestion(setFieldValue)}
                              >
                                {isSubmitting && (
                                  <span className="spinner-border spinner-border-sm mr-1"></span>
                                )}
                                {t("surveyNewQuestion")}
                              </Button>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>
                    </FormGroup>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default EditSurvey;
