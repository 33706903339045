import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/spSeen`;

export const spSeenService = {
  ToggleSpSeenBookmarkAsync,
  GetAllSpSeenAsync,
  AddSpSeenAsync,
};

function AddSpSeenAsync(params) {
  return fetchWrapper.post(`${baseUrl}/AddSpSeen`, params);
}

function ToggleSpSeenBookmarkAsync(params) {
  return fetchWrapper.post(`${baseUrl}/ToggleSpSeenBookmark`, params);
}

function GetAllSpSeenAsync(userId, flag, language) {
  return fetchWrapper.get(
    `${baseUrl}/GetAllSpSeen/${userId}/${flag}/${language}`
  );
}
