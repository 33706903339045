import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/process`;

export const processService = {
    GetAllProcessAsync,
    UpdateProcessAsync
};

function GetAllProcessAsync() {
    return fetchWrapper.get(`${baseUrl}/GetAllProcess`);
}

function UpdateProcessAsync(id, params) {
    return fetchWrapper.post(`${baseUrl}/UpdateProcess/${id}`,params);
}

