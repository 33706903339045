import ContactInfo from "components/Profile/CompanyProfilePage/ContactInfo";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
// reactstrap components
import { Card, CardBody, CardTitle, CardHeader, FormGroup } from "reactstrap";
import CardText from "reactstrap/lib/CardText";
import Row from "reactstrap/lib/Row";
import RestrictedInfoCard from "./RestrictedInfoCard";

// core components

function ContactDetailsSecure({ props }) {
  const [showInfo, setShowInfo] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setShowInfo(props.contactShowInfo);
  }, []);
  return (
    <>
      {showInfo === true ? (
        <ContactInfo props={props} />
      ) : (
        <RestrictedInfoCard props={t("createCompanyProfile.contact.diviver")} />
      )}
    </>
  );
}

export default ContactDetailsSecure;
