import React, { useEffect, useState, useContext, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { roleService, profileService } from "../../_services";
import PulseLoader from "react-spinners/PulseLoader";
import { StartupStages, AccountTypes } from "_helpers";
//import i18n from "../../i18n";
// reactstrap components
import { Link } from "react-router-dom";
import { LanguageContext } from "_context";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Col,
  CardTitle,
  Modal,
  Container,
  Row,
} from "reactstrap";
//import { createModifiersFromModifierFlags } from "typescript";
import queryString from "query-string";
// core components

function SignUpForm({ history, location }) {
  // const [firstFocus, setFirstFocus] = React.useState(false);
  // const [lastFocus, setLastFocus] = React.useState(false);
  // const [emailFocus, setEmailFocus] = React.useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const parsed = queryString.parse(location.search);
  const formikRef = useRef();
  const [roles, setRoles] = React.useState(null);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isProvider, setIsProvider] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [startup, setStartup] = useState(false);
  const [spTypeTouched, setSpTypeTouched] = useState(false);
  const [option, setOption] = useState(AccountTypes.Corporate);

  const [loading, setLoading] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [alert, setAlert] = useState(false);

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  const [newUser, setNewUser] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const initialValues = {
    email:
      parsed.email !== null && parsed.email !== undefined ? parsed.email : "",
    password: "",
    confirmPassword: "",
    profile: "",
    spType: AccountTypes.Corporate,
    startupStage: StartupStages.Seed,
  };
  const passwordRegex =
    /^(?=.*[A-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])\S{6,100}$/;
  const businessAccount =
    /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/;

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t("signUp.email.error")),
    // .matches(
    //   businessAccount,
    //   "Please enter a business account or Select Service Provider Type as Start Up"
    // ),
    // .matches(
    //   isProvider ? (businessAccount, "Please enter a business account") : null
    // ),
    password: Yup.string()
      .concat(
        showPassword
          ? Yup.string().required(t("signUp.password.required"))
          : null
      )
      .min(6, t("signUp.password.regex"))
      .matches(passwordRegex, t("signUp.password.regex")),
    confirmPassword: Yup.string()
      .when("password", (password, schema) => {
        if (password)
          return schema.required(t("signUp.confirmPassword.required"));
      })
      .oneOf([Yup.ref("password")], t("signUp.confirmPassword.match")),
    profile: Yup.string().required(t("signUp.profileType.error")),
  });

  async function fetData() {
    setLoading(true);
    await roleService.GetRoleByLanguageAsync(i18n.language).then((x) => {
      setRoles(x);
    });
    setLoading(false);
  }
  useEffect(() => {
    // i18n.changeLanguage("fr");
    document.body.classList.add("sidebar-collapse");
    // document.body.style = "background-color: #f5f5fa";
    document.documentElement.classList.remove("nav-open");
    fetData();
    return function cleanup() {
      //document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [i18n.language]);

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    const userExistsModel = {
      email: fields.email,
      accountTypeId: fields.profile,
      spType: fields.spType,
      startupStage: fields.startupStage,
    };

    const newUserModel = {
      title: "Mr.",
      firstName: fields.email,
      lastName: fields.email,
      email: fields.email,
      password: fields.password,
      confirmPassword: fields.confirmPassword,
      accountTypeId: fields.profile,
      spType: fields.spType,
      startupStage: fields.startupStage,
    };

    if (selectedUser == null) {
      profileService
        .SignUpStepOneAsync(newUserModel)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    } else {
      profileService
        .AddProfileAsync(userExistsModel)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    }
  }

  function handleChangeOption(e) {
    setOption(e.target.value);
    if (e.target.value === AccountTypes.Corporate) {
      setStartup(false);
    } else {
      setStartup(true);
    }
  }

  function resetFields() {
    setShowPassword(false);
    setNewUser(false);
    setIsCheckingEmail(false);
    setShowPassword(false);
    setShowFields(false);
    setSelectedUser(null);
    formikRef.current?.resetForm();
    setStartup(false);
    setIsProvider(false);
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <div
        className="section section-signup"
        style={{
          backgroundColor: "#f5f5fa",
          marginTop: "50px",
          backgroundSize: "cover",
        }}
      >
        {/* <SignUpNavbar /> */}
        <Container style={{ marginTop: "-5%" }}>
          <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">
                {t("AccountCreatedSuccessfully.title")}
              </h5>
              {/* <button aria-label="Close" className="close" type="button">
                <span aria-hidden={true}>×</span>
              </button> */}
            </div>
            <div className="modal-body">
              <p>{t("AccountCreatedSuccessfully.description")}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => {
                  setAlert(!alert);
                  history.push("/");
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => {
                  setErrorAlert(!errorAlert);
                  resetFields();
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Row>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              innerRef={formikRef}
              // validateOnBlur={true}
              //validateOnChange={true}
            >
              {({
                errors,
                touched,
                isSubmitting,
                values,
                setFieldValue,
                validateField,
                validateForm,
                isValid,
              }) => {
                //   useEffect(() => {
                //     let mounted = true;
                //     return () => (mounted = false);
                //   }, []);

                async function CheckEmail() {
                  const model = {
                    email: values.email,
                    spType: values.spType,
                    profileID: selectedProfile,
                  };

                  await profileService
                    .GetUserByEmail(model)
                    .then((user) => {
                      if (user === false) {
                        setNewUser(true);
                        // setIsCheckingEmail(false);
                        if (isProvider && values.spType === "Corporate")
                          checkRegistered(user, model, false);
                        else {
                          RegisterExistingCompany();
                          // setShowPassword(true);
                          // setShowFields(true);
                        }
                        setSelectedUser(null);
                      } else {
                        if (isProvider && values.spType === "Corporate") {
                          checkRegistered(user, model, true);
                        }
                        if (!isRegistered) registerExistingUser();
                      }
                    })
                    .catch((error) => {
                      setIsCheckingEmail(false);
                      if (error.message !== undefined) {
                        console.log(error.message);
                        setError(error.message);
                        if (error.error !== null && error.error.length > 0) {
                          for (var i = 0; i < error.error.length; i++) {
                            if (error.error[i].languageName == currentLan) {
                              setError(error.error[i].error);
                            }
                          }
                        }
                      } else {
                        setError(error);
                      }
                      setErrorAlert(!errorAlert);
                    });

                  // if (
                  //   selectedProfile.includes("4aae639") &&
                  //   values.spType === "Corporate"
                  // ) {
                  //   await profileService
                  //     .CheckForRegisteredCompanyAsync(model)
                  //     .then((res) => {
                  //       if (res === false) {
                  //         setNewUser(true);
                  //         setIsCheckingEmail(false);
                  //         setShowPassword(true);
                  //         setShowFields(true);
                  //         setSelectedUser(null);
                  //       }
                  //     })
                  //     .catch((error) => {
                  //       setIsCheckingEmail(false);
                  //       if (error.message !== undefined) {
                  //         setError(error.message);
                  //         if (error.error !== null && error.error?.length > 0) {
                  //           for (var i = 0; i < error.error?.length; i++) {
                  //             if (error.error[i].languageName == currentLan) {
                  //               setError(error.error[i].error);
                  //             }
                  //           }
                  //         }
                  //       } else {
                  //         setError(error);
                  //       }
                  //       setErrorAlert(!errorAlert);
                  //     });
                  // } else {

                  // }
                }

                async function checkRegistered(user, model, newUser) {
                  await profileService
                    .CheckForRegisteredCompanyAsync(model)
                    .then((res) => {
                      if (res === false) {
                        // setIsCheckingEmail(false);
                        setIsRegistered(false);
                        setNewUser(true);
                        if (newUser) {
                          setShowPassword(false);
                          setShowFields(false);
                        } else {
                          setShowPassword(true);
                          setShowFields(true);
                        }
                        setSelectedUser(null);
                      } else {
                        setIsRegistered(true);
                        setNewUser(false);
                        if (newUser) {
                          setShowPassword(true);
                          setShowFields(true);
                        } else {
                          setShowPassword(false);
                          setShowFields(false);
                        }
                      }
                    })
                    .catch((error) => {
                      setIsRegistered(false);
                      setShowPassword(false);
                      setShowFields(false);
                      setIsCheckingEmail(false);
                      if (error.error !== undefined) {
                        console.log(error.message);
                        setError(error.message);
                        if (error.error !== null && error.error?.length > 0) {
                          for (var i = 0; i < error.error?.length; i++) {
                            if (error.error[i].languageName == currentLan) {
                              setError(error.error[i].error);
                            }
                          }
                        }
                      } else {
                        setError(error);
                      }
                      setErrorAlert(!errorAlert);
                    });
                }

                function registerExistingUser() {
                  const userExistsModel = {
                    title: "Mr.",
                    firstName: values.email,
                    lastName: values.email,
                    email: values.email,
                    accountTypeId: values.profile,
                    spType: values.spType,
                    startupStage: values.startupStage,
                  };
                  profileService
                    .AddProfileAsync(userExistsModel)
                    .then(() => {
                      setIsCheckingEmail(false);
                      setAlert(!alert);
                    })
                    .catch((error) => {
                      setIsCheckingEmail(false);
                      if (error.message !== undefined) {
                        setError(error.message);
                        if (error.error !== null && error.error.length > 0) {
                          for (var i = 0; i < error.error.length; i++) {
                            if (error.error[i].languageName == currentLan) {
                              setError(error.error[i].error);
                            }
                          }
                        }
                      } else {
                        setError(error);
                      }
                      setErrorAlert(!errorAlert);
                    });
                }

                function RegisterExistingCompany() {
                  const model = {
                    Email: values.email,
                    Type: values.profile,
                  };
                  profileService
                    .CheckIsDomainEmailExistsAsync(model)
                    .then((res) => {
                      setIsCheckingEmail(false);
                      if (res.exists) {
                        setShowPassword(false);
                        setShowFields(false);
                        setError(
                          t("domain.exists.one") +
                            res.accountType +
                            t("domain.exists.two")
                        );
                        setErrorAlert(true);
                      } else {
                        setShowPassword(true);
                        setShowFields(true);
                      }
                    });
                }
                return (
                  <Col md="8" style={{ margin: "auto" }}>
                    <Card>
                      <CardHeader className="text-center">
                        <CardTitle className="" tag="h4">
                          {t("signUp.title")}
                        </CardTitle>
                      </CardHeader>

                      <Form
                        autoComplete="off"
                        className={rtl ? "text-right" : ""}
                        // style={{
                        //   margin: "0 auto",
                        //   width: "100%",
                        //   padding: "10px",
                        // }}
                      >
                        <CardBody>
                          <Row>
                            <Col md="12">
                              <div
                                className="form-row"
                                // style={{ width: "max-content" }}
                                style={{ display: "flex" }}
                              >
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                  }}
                                >
                                  <label htmlFor="inputEmail4">
                                    {t("signUp.email")}
                                  </label>
                                  <Field
                                    // validate={validateEmail}
                                    //  disabled={showFields}
                                    disabled={newUser}
                                    autoFocus={true}
                                    name="email"
                                    placeholder={t("signUp.email")}
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />

                                  {errors.email && touched.email ? (
                                    <ErrorMessage
                                      name="email"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  //  hidden={!showFields}
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="profile">
                                    {t("signUp.profileType")}
                                  </label>

                                  <Field
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    name="profile"
                                    // placeholder="Password"
                                    as="select"
                                    onChange={(e) => {
                                      // validateField("email");
                                      // validateForm();
                                      setSpTypeTouched(true);
                                      setIsProvider(false);
                                      setStartup(false);
                                      setOption(AccountTypes.Corporate);
                                      setSelectedProfile(e.target.value);
                                      setFieldValue(
                                        "profile",
                                        e.target.value,
                                        false
                                      );
                                      roles &&
                                        roles.map((role) => {
                                          if (
                                            e.target.value === role.roleId &&
                                            role.description === "Provider"
                                          ) {
                                            setIsProvider(true);
                                          }
                                          return null;
                                        });
                                    }}
                                    className={
                                      "form-control" +
                                      (errors.profile && touched.profile
                                        ? " is-invalid"
                                        : "")
                                    }
                                  >
                                    <option
                                      value=""
                                      disabled
                                      defaultValue
                                      hidden
                                    >
                                      {t("signUp.profileType.placeholder")}
                                    </option>
                                    {roles &&
                                      roles.map((role) =>
                                        role.description === "Provider" ? (
                                          <option
                                            key={role.roleId}
                                            value={role.roleId}
                                          >
                                            {role.roleName}
                                            {" ("}
                                            {t("lebanonBased")}
                                            {")"}
                                          </option>
                                        ) : role.description === "Partner" ? (
                                          <option
                                            key={role.roleId}
                                            value={role.roleId}
                                          >
                                            {role.roleName}
                                            {" ("}
                                            {t("signedMOU")}
                                            {")"}
                                          </option>
                                        ) : (
                                          <option
                                            key={role.roleId}
                                            value={role.roleId}
                                          >
                                            {role.roleName}
                                          </option>
                                        )
                                      )}
                                  </Field>
                                  {errors.profile && touched.profile ? (
                                    <ErrorMessage
                                      name="profile"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  hidden={!isProvider}
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label>
                                    {" "}
                                    {t("signUp.providerType.title")}
                                  </label>
                                  <br />
                                  <label htmlFor="spType">
                                    <Field
                                      name="spType"
                                      type="radio"
                                      value={AccountTypes.Corporate}
                                      checked={
                                        option === AccountTypes.Corporate
                                      }
                                      onChange={(e) => {
                                        handleChangeOption(e);
                                        setFieldValue(
                                          "spType",
                                          e.target.value,
                                          false
                                        );

                                        // validateField("email");
                                        validateForm();
                                      }}
                                    />{" "}
                                    {t("signUp.providerType.corporate")}
                                  </label>
                                  <label
                                    htmlFor="inputPassword4"
                                    style={{ padding: "10px" }}
                                  >
                                    <Field
                                      name="spType"
                                      type="radio"
                                      value={AccountTypes.Startup}
                                      checked={option === AccountTypes.Startup}
                                      onChange={(e) => {
                                        handleChangeOption(e);
                                        setFieldValue(
                                          "spType",
                                          e.target.value,
                                          false
                                        );
                                        //  validateField("email");
                                        // validateForm();
                                      }}
                                    />{" "}
                                    {t("signUp.providerType.startup")}
                                  </label>
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  hidden={!startup}
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="startupStage">
                                    {t("createCompanyProfile.StartupStage")}
                                  </label>
                                  <Field
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    name="startupStage"
                                    placeholder={t(
                                      "createCompanyProfile.StartupStage"
                                    )}
                                    as="select"
                                    className={
                                      "form-control" +
                                      (errors.startupStage &&
                                      touched.startupStage
                                        ? " is-invalid"
                                        : "")
                                    }
                                  >
                                    {/* <option value={""} defaultValue>
                                      {t("createCompanyProfile.selectOption")}
                                    </option> */}
                                    <option
                                      defaultValue
                                      value={StartupStages.Seed}
                                    >
                                      {t("seedStage")}
                                    </option>
                                    <option value={StartupStages.Early}>
                                      {t("earlyStage")}
                                    </option>
                                    <option value={StartupStages.Later}>
                                      {t("laterStage")}
                                    </option>
                                  </Field>
                                  {errors.startupStage &&
                                  touched.startupStage ? (
                                    <ErrorMessage
                                      name="startupStage"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  hidden={!showPassword}
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("signUp.password")}
                                  </label>

                                  <Field
                                    name="password"
                                    placeholder={t("signUp.password")}
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  {errors.password && touched.password ? (
                                    <ErrorMessage
                                      name="password"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  hidden={!showPassword}
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("signUp.confirmPassword")}
                                  </label>

                                  <Field
                                    name="confirmPassword"
                                    placeholder={t("signUp.confirmPassword")}
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.confirmPassword &&
                                      touched.confirmPassword
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  {errors.confirmPassword &&
                                  touched.confirmPassword ? (
                                    <ErrorMessage
                                      name="confirmPassword"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <Button
                                    color="primary"
                                    hidden={showFields}
                                    type="button"
                                    //   disabled={!isValid}
                                    onClick={(e) => {
                                      if (
                                        values.email === "" ||
                                        values.profile === ""
                                      ) {
                                        e.preventDefault();
                                      } else {
                                        //   validateForm();
                                        setIsCheckingEmail(true);

                                        CheckEmail();
                                      }
                                    }}
                                  >
                                    {isCheckingEmail && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {t("next.button")}
                                  </Button>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    hidden={!showFields}
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {t("signUp.button")}
                                  </Button>
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <hr
                                  //  className="btn-primary"
                                  style={{
                                    borderTop: "1px solid #bbb",
                                    marginTop: "2%",
                                    width: "90%",
                                  }}
                                ></hr>
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label>
                                    {t("register.alreadyHaveAccount")}
                                    <Link to="/account/login">
                                      {t("navigation.login")}
                                    </Link>
                                  </label>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Form>
                    </Card>
                  </Col>
                );
              }}
            </Formik>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SignUpForm;
