import React, { useState, createContext } from "react";
import { profileService } from "_services";
// Create Context Object
export const SearchProviderContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const SearchProviderContextProvider = props => {

  const user = profileService.userValue;
  const initialModel = {
    userPerforminSearchId: user !== null ? user.profile.userId : '',
    searchIndex: 0,
    openForPartnership: null,
    financing: null,
    searchKeyWord: '',
    industries: null,
    countries: [],
    typeOfIndustriesAndServices: [],
    spType: [],
    NumberOfEmployeesId: [],
    filterType: "Ascending",
    filterBy: "CompanyName",
  }
  const [searchModel, setSearchModel] = useState(initialModel);
  // useEffect(() => {
  // }, [ searchModel]);

  return (
    <SearchProviderContext.Provider value={[searchModel, setSearchModel]}>
      {props.children}
    </SearchProviderContext.Provider>
  );
};