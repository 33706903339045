import { BehaviorSubject } from "rxjs";

import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const userSubject = new BehaviorSubject(null);
const baseUrl = `${config.apiUrl}/profile`;
//const baseUrl = `https://authentication.entelligence.cloud/accounts`;
export const accountService = {
  login,
  logout,
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
};

function login(model) {
  return fetchWrapper.post(`${baseUrl}/authenticate`, model).then();
}

function logout() {
  // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
  // fetchWrapper.post(`${baseUrl}/revoke-token`, {});
  fetchWrapper.post(`${baseUrl}/deleteCookies`);
  sessionStorage.clear();
  userSubject.next(null);

  // history.push("/account/login");
  //document.cookie=("refreshToken=; expires=Thu ,01 Jan 1970  00:00:01 GMT;");
}
