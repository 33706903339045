import React, { useEffect, useState, useContext } from "react";
//import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Col,
  CardTitle,
  Container,
  Modal,
  Row,
} from "reactstrap";
import { profileService } from "../../_services";
import queryString from "query-string";
import LoggedInUserNavbar from "components/Navbars/LoggedInUserNavbar";
import { LanguageContext } from "_context";
// core components

function ContactUs({ history, location }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [contactUsMessage, setMessage] = useState("");
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const parsed = queryString.parse(location.search);

  const initialValues = {
    email: parsed.email,
    firstName: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t("signUp.email.error")),
    firstName: Yup.string().required(t("fullName.required")),
    //   message: Yup.string().required("Message is required"),
  });

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  const [successAlert, setSuccessAlert] = useState(false);

  useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    // document.body.style = "background-color: #f5f5fa";
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  });

  async function onSubmit(fields, { setStatus, setSubmitting, setFieldValue }) {
    setStatus();
    const model = {
      sender: fields.firstName,
      senderEmail: fields.email,
      message: contactUsMessage,
    };

    const userVariables = {
      userVariables: model,
      language: currentLan,
    };
    await profileService
      .ContactUsEmailAsync(userVariables)
      .then(() => {
        setFieldValue("firstName", "", false);
        setFieldValue("email", "", false);
        setMessage("");
        setSuccessAlert(!successAlert);
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
    setSubmitting(false);
  }

  return (
    <>
      <div
        className="section section-signup"
        style={{
          backgroundColor: "#f5f5fa",
          backgroundSize: "cover",
          marginTop: "50px",
        }}
      >
        <Container style={{ marginTop: "-5%" }}>
          <Modal
            isOpen={successAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("contactUs.alert")}</h5>
            </div>
            <div className={rtl ? "modal-body text-right" : "modal-body"}>
              <p>
                <b>{t("contactUs.thankYou")}</b>
              </p>
              <p>{t("contactUs.messageAlert")}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => {
                  setSuccessAlert(!successAlert);
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
              }
            >
              <p>{errorMessage}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <div className="text-center">
            <h2
              className="title"
              style={{
                textTransform: "uppercase",

                color: "#990000 ",
                fontWeight: "bold",
                fontSize: "48px",
              }}
            >
              {t("navbar.contactUs")}
            </h2>

            <hr style={{ marginTop: "-15px" }} />
            <p className="">
              {t("contactUs.inquiries.one")} <br></br>
              {t("contactUs.inquiries.two")}
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, isSubmitting, setFieldValue }) => {
              //   useEffect(() => {
              //     let mounted = true;
              //     return () => (mounted = false);
              //   }, []);

              return (
                <div>
                  <>
                    <Form autoComplete="off">
                      <Card className={rtl ? "text-right" : ""}>
                        <CardBody>
                          <div className="form-row">
                            <FormGroup
                              className="col-md-6"
                              style={{
                                margin: "0 auto",
                                width: "100%",
                                padding: "10px",
                                marginTop: "-2%",
                              }}
                            >
                              <label htmlFor="firstName">
                                {t("contactUs.firstName")}
                              </label>

                              <Field
                                name="firstName"
                                style={{ backgroundColor: "#ffffff" }}
                                placeholder={t("contactUs.firstName")}
                                type="test"
                                className={
                                  "form-control" +
                                  (errors.firstName && touched.firstName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              {errors.firstName && touched.firstName ? (
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              ) : (
                                <span className="isValid" component="div">
                                  <br></br>
                                </span>
                              )}
                            </FormGroup>
                          </div>
                          <div className="form-row">
                            <FormGroup
                              className="col-md-6"
                              style={{
                                margin: "0 auto",
                                width: "100%",
                                padding: "10px",
                                marginTop: "-2%",
                              }}
                            >
                              <label htmlFor="inputEmail4">
                                {t("contactUs.email")}
                              </label>

                              <Field
                                name="email"
                                placeholder={t("contactUs.email")}
                                type="text"
                                style={{ backgroundColor: "#ffffff" }}
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              {errors.email && touched.email ? (
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              ) : (
                                <span className="isValid" component="div">
                                  <br></br>
                                </span>
                              )}
                            </FormGroup>
                          </div>
                          <div className="form-row">
                            <FormGroup
                              className="col-md-6"
                              style={{
                                margin: "0 auto",
                                width: "100%",
                                padding: "10px",
                                marginTop: "-2%",
                              }}
                            >
                              <label htmlFor="message">
                                {t("contactUs.message")}
                              </label>

                              <textarea
                                value={contactUsMessage}
                                name="message"
                                style={{ backgroundColor: "#ffffff" }}
                                rows="3"
                                placeholder={t("contactUs.message")}
                                type="text"
                                required={true}
                                className={"form-control"}
                                onChange={(e) => setMessage(e.target.value)}
                              ></textarea>
                            </FormGroup>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="text-center">
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          {t("contactUs.button")}
                        </Button>
                      </div>
                    </Form>
                  </>
                </div>
              );
            }}
          </Formik>
        </Container>
      </div>
    </>
  );
}

export default ContactUs;
