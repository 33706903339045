import React, { useState, useContext } from "react";
import { Container } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import { useTranslation } from "react-i18next";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Button from "reactstrap/lib/Button";
import {
  adminService,
  profileService,
  languageService,
  landingPageService,
} from "_services";
import { LanguageContext } from "_context";
import Modal from "reactstrap/lib/Modal";
import { Link } from "react-router-dom";
import ReactHtmlParser, {
  convertNodeToElement,
  processNodes,
} from "react-html-parser";
import reactHtmlReplace from "react-html-replace";

function CMSHome({ history, match, location }) {
  const { path } = match;
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [languages, setLanguages] = useState([]);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [ifYouAre, setIfYouAre] = useState([]);
  const [ifYouBelieveIn, setIfYouBelieveIn] = useState([]);
  const [ourAim, setOurAim] = useState([]);
  const [usageAndBenefits, setUsageAndBenefits] = useState([]);
  const [partners, setPartners] = useState([]);
  const [whoWeAre, setWhoWeAre] = useState([]);
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(false);
  const [updateAlert, setUpdateAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const fetchData = async () => {
    landingPageService
      .GetLandingPageTextAsyncLanguage(currentLan, t("admincms.ifyouare"))
      .then((y) => {
        setIfYouAre(y);
      });

    landingPageService
      .GetLandingPageTextAsyncLanguage(currentLan, t("admincms.ifyoubelievein"))
      .then((y) => {
        setIfYouBelieveIn(y);
      });

    landingPageService
      .GetLandingPageTextAsyncLanguage(currentLan, t("admincms.ourAim"))
      .then((y) => {
        setOurAim(y);
      });

    landingPageService
      .GetLandingPageTextAsyncLanguage(
        currentLan,
        t("admincms.usageandbenefits")
      )
      .then((y) => {
        setUsageAndBenefits(y);
      });

    landingPageService.GetAllPartnersAsync().then((y) => {
      setPartners(y);
    });

    landingPageService.GetAllWhoWeAreAsync().then((y) => {
      setWhoWeAre(y);
    });
  };
  const options = {
    decodeEntities: true,
    transform,
  };

  function transform(node, index) {
    if (node.type === "tag" && node.name === "b") {
      return <b key={index}>{processNodes(node.children, transform)}</b>;
    }
    if (
      node.type === "tag" &&
      node.name === "br" &&
      node.next !== null &&
      node.next.name === "br"
    ) {
      return null;
    }
    if (node.type === "tag" && node.name === "br") {
      return <br></br>;
    }
  }

  async function UpdateSheets() {
    setUpdateAlert(false);
    await adminService
      .SyncTemplates()
      .then(() => {
        setAlert(!alert);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName === currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  const Mention = (props) => {
    const { children, id, name } = props;
    return (
      <span name={name} id={id} style={{ border: "1px solid #ccc" }}>
        &nbsp;{children}&nbsp;
      </span>
    );
  };
  return (
    <>
      <div className={rtl ? "section text-right" : "section"}>
        <Container>
          <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("done")}</h5>
            </div>
            <div className="modal-body">
              <p>{t("updateSheetsSuccess")}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => {
                  setAlert(!alert);
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={updateAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("warning")}</h5>
            </div>
            <div className="modal-body">
              <p>{t("updateSheetsWarning")}</p>
            </div>
            <div
              className="modal-footer"
              style={
                rtl
                  ? { justifyContent: "flex-end" }
                  : { justifyContent: "flex-start" }
              }
            >
              <center>
                <Link
                  className="mr-2"
                  type="button"
                  style={{ margin: "auto", color: "#000000" }}
                  onClick={(e) => setUpdateAlert(!updateAlert)}
                >
                  {t("cancel.link")}
                </Link>
                <Button
                  color="primary"
                  type="button"
                  className="mr-2"
                  style={
                    rtl
                      ? { margin: "auto", textAlign: "right" }
                      : { margin: "auto" }
                  }
                  onClick={async (e) => await UpdateSheets()}
                >
                  {t("updateSheets")}
                </Button>
              </center>
            </div>
          </Modal>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Row style={{ marginTop: "15px" }}>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/featuredIn`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-star"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("mainPage.featured")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  await profileService.RefreshTokenAsync().then(() => {
                    history.push(`${path}/if-you-are`);
                  });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-wrench"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admincms.ifyouare")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col> */}

            {/* <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  await profileService.RefreshTokenAsync().then(() => {
                    history.push(`${path}/if-you-believe-in`);
                  });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-wrench"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admincms.ifyoubelievein")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col> */}
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/sections`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-keyboard"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("mainPage.texts")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/million-dollar-challenge`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-money-bill-alt"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("million.dollar")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  await profileService.RefreshTokenAsync().then(() => {
                    history.push(`${path}/our-aim`);
                  });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-bullseye"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admincms.ourAim")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col> */}
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/our-partners`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-users"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admincms.ourpartners")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/testimonials`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-comment"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("mainPage.testimonials")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  await profileService.RefreshTokenAsync().then(() => {
                    history.push(`${path}/our-usageandbenefits`);
                  });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-wrench"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admincms.usageandbenefits")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col> */}
            {/* <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer"  }}
                className="card-transition"
                onClick={async () => {
                  await profileService.RefreshTokenAsync().then(() => {
                    history.push(`${path}/whowearetypes`)
                  });

                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-user-friends"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admincms.whoweareTypes")}
                  </CardTitle>
                </CardBody>

              </Card>
            </Col> */}

            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/video`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-video-camera"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admincms.video")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/who-we-are`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-user-friends"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admincms.whoweare")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>

            {/* <Col md="3" style={{ marginBottom: "20px" }}>
                    <div className="team-player">
                      <div
                        style={{
                          height: "120px",
                        }}
                      >
                        <img
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "contain",
                            border: "2px solid #e6e6e8",
                          }}
                          alt="..."
                          className="rounded-circle img-fluid"
                          src={
                            require("assets/img/NM-BW-Outerpond.webp").default
                          }
                          width="162px"
                          height="162px"
                        ></img>
                      </div>
                      <h4 className="title">Nagi Moubarak</h4>
                      <p className="category" style={{ color: "#112A84" }}>
                        {t("initiator")}
                      </p>
                      <a
                        href="https://www.linkedin.com/in/nmoubarak/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={require("assets/img/Linkedin30By30.png").default}
                          alt=" "
                        />
                      </a>
                    </div>
                  </Col> */}

            {/* Start Of - OUR AIM Section */}

            {/* <Row>
              {console.log(ourAim)}
              <Col md="12" className="parsing p">

                {ourAim.map(item => (
                  ReactHtmlParser(`${item.documentText}`,
                    options
                  )))}

              </Col>
            </Row> */}
            {/* End Of - OUR AIM Section */}

            {/* Start Of - USAGE & BENEFITS Section */}
            {/* <Row>
              <Col>

                {console.log(usageAndBenefits)}
                {usageAndBenefits.map(item => (

                  reactHtmlReplace(
                    `${item.documentText}`,
                    (tag, props) => {

                      if (tag === 'p') {
                        return <p style={{ fontSize: 20 }}></p>
                      }

                      if (tag === 'strong') {
                        return <div style={{ fontSize: 20, fontWeight: "bold", marginBottom: -20, }}></div>
                      }

                      if (tag === 'li') {

                        return (
                          <CardBody style={{ paddingLeft: "0px", paddingBottom: "0px" }}>
                            <div style={{ fontSize: 20, margin: 0 }}>

                              <h5 style={{ marginTop: 3, fontSize: 20, float: "left" }}>
                                <i
                                  style={{
                                    color: "#0E6EB8",
                                    paddingRight: "10px",
                                  }}
                                  className="fa fa-check-circle"
                                  aria-hidden="true"

                                >

                                </i>
                              </h5>
                              <div>

                              </div>

                            </div>
                          </CardBody>
                        )
                      }


                    }
                  )

                ))}

              </Col>
            </Row> */}
            {/* End Of - USAGE & BENEFITS Section */}
            {/* <Row> */}
            {/* Start Of - If You Are Section */}
            {/* <Col md="5">
                {ifYouAre.map(item => (
                  reactHtmlReplace(`${item.documentText}`,
                    (tag, props) => {

                      if (tag === 'p') {
                        return (
                          <Card md="3">
                            <CardBody style={{ paddingBottom: "15px" }}>
                              <div>
                                <div style={{ margin: "0 auto", maxWidth: "none" }}>
                                  <i
                                    style={{
                                      marginTop: "4px",
                                      color: "#0E6EB8",
                                      float: "left",
                                      paddingRight: "10px",
                                    }}
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div>

                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        )
                      }
                    }
                  )
                ))}
              </Col> */}
            {/* End Of - If You Are Section */}
            {/* Start Of - If You Believe In Section */}
            {/* <Col md="5">
                {ifYouBelieveIn.map(item => (
                  reactHtmlReplace(`${item.documentText}`,
                    (tag, props) => {

                      if (tag === 'p') {
                        return (
                          <Card md="3">
                            <CardBody style={{ paddingBottom: "15px" }}>
                              <div>
                                <div style={{ margin: "0 auto", maxWidth: "none" }}>
                                  <i
                                    style={{
                                      marginTop: "4px",
                                      color: "#0E6EB8",
                                      float: "left",
                                      paddingRight: "10px",
                                    }}
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div>

                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        )
                      }
                    }
                  )
                ))}
              </Col> */}
            {/* End Of - If You Believe In Section */}
          </Row>

          {/* </Row> */}
        </Container>
      </div>
    </>
  );
}

export default CMSHome;
