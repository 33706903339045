import React, { useState, useContext } from "react";
import { Container } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import { useTranslation } from "react-i18next";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Button from "reactstrap/lib/Button";
import { adminService, profileService } from "_services";
import { LanguageContext } from "_context";
import Modal from "reactstrap/lib/Modal";
import { Link, useHistory } from "react-router-dom";
import { FileNames } from "_helpers";

function AdminHome({ history, match, location }) {
  const { path } = match;
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const browserHistory = useHistory();

  const user = profileService.userValue;

  const [currentLan, setCurrentLan] = useContext(LanguageContext);

  const [alert, setAlert] = useState(false);
  const [updateAlert, setUpdateAlert] = useState(false);
  const [backupAlert, setBackupAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  const [downloading, setDownloading] = useState(false);

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  async function UpdateSheets() {
    setUpdateAlert(false);
    await adminService
      .SyncTemplates()
      .then(() => {
        setAlert(!alert);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName === currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function DownloadDatabaseBackup() {
    // const model = {
    //   downloadSpDocument: true,
    // };
    await adminService
      .DownloadDatabaseBackupAsync()
      .then((x) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([x.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", FileNames.DatabaseBackup);
        document.body.appendChild(link);
        link.click();
        setBackupAlert(!backupAlert);
        //    fileDownload(x.data, "UploadedSps.xlsx");
      })
      .catch(async (e) => {
        var errorString = await new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.addEventListener("loadend", () =>
            resolve(fileReader.result)
          );
          fileReader.readAsText(e.response.data);
        });
        console.log(errorString);
        var newErrorString = errorString.replace("\\", "");
        console.log(errorString);
        // var error = errorbuffer.toJSON();
        var error = JSON.parse(newErrorString);
        console.log(error);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName === currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }
  return (
    <>
      <div className="section">
        <Container>
          <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("done")}</h5>
            </div>
            <div className={rtl ? "modal-body text-right" : "modal-body"}>
              <p>{t("updateSheetsSuccess")}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => {
                  setAlert(!alert);
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={updateAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("warning")}</h5>
            </div>
            <div className={rtl ? "modal-body text-right" : "modal-body"}>
              <p>{t("updateSheetsWarning")}</p>
            </div>
            <div
              className="modal-footer"
              style={
                rtl
                  ? { justifyContent: "flex-end" }
                  : { justifyContent: "flex-start" }
              }
            >
              <center>
                <Link
                  className="mr-2"
                  type="button"
                  style={{ margin: "auto", color: "#000000" }}
                  onClick={(e) => setUpdateAlert(!updateAlert)}
                >
                  {t("cancel.link")}
                </Link>
                <Button
                  color="primary"
                  type="button"
                  className="mr-2"
                  style={
                    rtl
                      ? { margin: "auto", textAlign: "right" }
                      : { margin: "auto" }
                  }
                  onClick={async (e) => await UpdateSheets()}
                >
                  {t("updateSheets")}
                </Button>
              </center>
            </div>
          </Modal>
          <Modal isOpen={backupAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("warning")}</h5>
            </div>
            <div className={rtl ? "modal-body text-right" : "modal-body"}>
              <p>{t("downloadWarning")}</p>
            </div>
            <div
              className="modal-footer"
              style={
                rtl
                  ? { justifyContent: "flex-end" }
                  : { justifyContent: "flex-start" }
              }
            >
              <center>
                <Link
                  className="mr-2"
                  type="button"
                  style={{ margin: "auto", color: "#000000" }}
                  onClick={(e) => setBackupAlert(!backupAlert)}
                >
                  {t("cancel.link")}
                </Link>
                <Button
                  color="primary"
                  type="button"
                  className="mr-2"
                  style={
                    rtl
                      ? { margin: "auto", textAlign: "right" }
                      : { margin: "auto" }
                  }
                  onClick={async (e) => await DownloadDatabaseBackup()}
                >
                  {t("downloadBackup")}
                </Button>
              </center>
            </div>
          </Modal>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Row style={{ marginTop: "15px" }}>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  browserHistory.push(`${path}/analytics`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fas fa-chart-line"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("adminPanel.AnalyticsTab")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  browserHistory.push(`${path}/affiliatedUsersList`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa-solid fa-file-export"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("adminPanel.AffiliatedTo")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push({
                    pathname: `${path}/badges`,
                    state: user.isPrimary,
                  });
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa-sharp fa-solid fa-certificate"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admin.badges")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/budgets`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-usd"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admin.budget")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/industries`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-cogs"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("createCompanyProfile.industry")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/countries`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-globe"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("filterSearch.country.placeholder")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async (e) => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  setBackupAlert(!backupAlert);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fas fa-database"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("databaseBackup")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async (e) => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/emailLogs`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fas fa-envelope"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("adminPanel.EmailLogs")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/external-opportunities`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-star"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("external.opportunities")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  //  await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/featured-opportunities?linked`);
                  //   });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-star"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("linkedOpportunities")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/featured-opportunities?listed`);
                  //  });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-star"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("listedOpportunities")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/logs`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa-solid fa-square-poll-horizontal"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("logs")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push({
                    pathname: `${path}/messages`,
                    state: user.isPrimary,
                  });
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa-solid fa-message"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("navbar.messages")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push({
                    pathname: `${path}/newsletters`,
                    state: user.isPrimary,
                  });
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa-solid fa-newspaper"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("newsletters")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/nbEmployees`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-users"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("createCompanyProfile.numberOfEmployees")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/project-types`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-file"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("adminpanel.projectTypes")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push({
                    pathname: `${path}/partners`,
                    state: user.isPrimary,
                  });
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i class="fa fa-users" style={{ color: "#0E6EB8" }}></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admin.partners")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push({
                    pathname: `${path}/plans`,
                    state: user.isPrimary,
                  });
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-credit-card"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admin.plans")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col> */}
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async (e) => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/service-providers`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      //class="fas fa-user-cog"
                      class="fa fa-star"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("SP")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/system-setup/policies`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-file-text"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("systemPolicies")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  //await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/surveys`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa-solid fa-square-poll-horizontal"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("surveys")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/surveyTypes`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa-solid fa-square-poll-vertical"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("surveyTypes")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/system-jobs`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-tasks"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("systemJobs")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/variables`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-sliders"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("systemVariables")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  //   await profileService.RefreshTokenAsync().then(() => {
                  history.push(`${path}/transactions`);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa-solid fa-arrow-right-arrow-left"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("transactions")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col> */}
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async (e) => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  setUpdateAlert(!updateAlert);
                  // });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-refresh"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("updateExcelSheets")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer" }}
                className="card-transition"
                onClick={async () => {
                  // await profileService.RefreshTokenAsync().then(() => {
                  history.push({
                    pathname: `${path}/users`,
                    state: user.isPrimary,
                  });
                  //});
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-user"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("admin.users")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>

            {/* <Col md="3">
              <Card
                style={{ minHeight: "186px", cursor: "pointer"  }}
                className="card-transition"
                onClick={async (e) => {
                  await profileService.RefreshTokenAsync().then(() => {
                    history.push(`${path}/user-logs`);
                  });
                }}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fas fa-clipboard-list"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("UserLogs")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AdminHome;
