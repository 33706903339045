import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import ServiceProvidersList from "./ServiceProvidersList";
import ServiceProviderDetails from "./Details/Index";

function ServiceProviders({ match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={ServiceProvidersList} exact />
        <PrivateRoute
          path={`${path}/serviceprovider-details`}
          component={ServiceProviderDetails}
        />
      </Switch>
    </div>
  );
}

export { ServiceProviders };
