import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditPeople from "./AddEditPeople";
import WhoWeAreList from "./WhoWeAreList";

function WhoWeAre({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={WhoWeAreList} exact />
        <PrivateRoute path={`${path}/add`} component={AddEditPeople} />
        <PrivateRoute
          path={`${path}/edit/:id`}
          component={AddEditPeople}
        />
      </Switch>
    </div>
  );
}

export { WhoWeAre };
