import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { color, fontSize, fontWeight, style } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import { green, lightGreen } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import StepConnector from "@material-ui/core/StepConnector";

export default function ProgressBar({ props, type }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [activeStep, setActiveStep] = React.useState(0);
  const [optional, setOptional] = React.useState(new Set());
  const [closed, setClosed] = React.useState("Closed");

  const steps = ["New", "Published", "Interacted", closed];
  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiStepIcon-active": { color: "red" },
      "& .MuiStepIcon-completed": { color: "green" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" },
    },
    connectorRight: {
      position: "absolute",
      top: "12px",
      left: "calc(50% + 20px)",
      right: "calc(-50% + 20px)",
    },
    connectorLeft: {
      position: "absolute",
      top: "12px",
      left: "calc(-50% + 20px)",
      right: "calc(50% + 20px)",
    },
  }));
  console.log(props.projectStatus);
  const c = useStyles();
  const getData = async () => {
    if (props.projectStatus == "New") {
      setActiveStep(0);
    } else if (props.projectStatus == "Published") {
      setActiveStep(1);
    } else if (props.projectStatus == "Interacted") {
      setActiveStep(2);
      setOptional(2);
    } else if (props.projectStatus == "Awarded") {
      setActiveStep(3);
      setOptional(3);
      setClosed("Awarded");
    } else if (props.projectStatus == "Not Awarded") {
      setActiveStep(3);
      setOptional(2);
      setClosed("Not Awarded");
    } else if (props.projectStatus == "No Cooporation") {
      setActiveStep(3);
      setOptional(2);
      setClosed("No Cooporation");
    } else if (props.projectStatus == "Withdrawn") {
      setActiveStep(3);
      setOptional(2);
      setClosed("Withdrawn");
    } else if (props.projectStatus == "Closed") {
      setActiveStep(3);
      setOptional(2);
      setClosed("Closed");
    }
  };

  const fetchData = async () => {
    await getData();
  };

  React.useEffect(() => {
    fetchData();
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={
          <StepConnector className={rtl ? c.connectorRight : c.connectorLeft} />
        }
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps1 = {};
          const labelProps2 = {};
          if (index == 2) {
            labelProps1.optional = (
              <>
                <Typography variant="caption">
                  {t("clicks")}: {props.nbClicks}
                </Typography>
                <br />
                {type === "listed" ? (
                  <Typography variant="caption">
                    {t("navbar.messages")}: {props.nbMsgs}
                  </Typography>
                ) : (
                  <></>
                )}
              </>
            );
          }
          if (index == 3) {
            closed == "Awarded" ? (
              (labelProps2.optional = (
                <>
                  {" "}
                  <Typography variant="caption">
                    Awarded By: {props.awardedBy}
                  </Typography>
                  <br />
                </>
              ))
            ) : (
              <></>
            );
          }

          return (
            <Step key={label} {...stepProps}>
              {index !== activeStep ? (
                index > activeStep ? (
                  <StepLabel
                    icon={<i class="fa-solid fa-minus"></i>}
                    style={{ fontSize: "25px", color: "#7b7b7b" }}
                  >
                    {label}
                  </StepLabel>
                ) : (
                  <StepLabel
                    {...labelProps1}
                    {...labelProps2}
                    icon={<i class="fa-solid fa-check"></i>}
                    style={{ fontSize: "25px", color: "#004ab1" }}
                  >
                    {label}
                  </StepLabel>
                )
              ) : (
                <StepLabel
                  {...labelProps1}
                  {...labelProps2}
                  icon={<i class="fa-solid fa-check"></i>}
                  style={{
                    fontSize: "25px",
                    color: "#03d022",
                  }}
                >
                  {label}
                </StepLabel>
              )}
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
