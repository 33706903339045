import React, { useState, useContext } from "react";
import { Row, Col } from "reactstrap";
import { profileService, directLinkSeenService } from "_services";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import Badge from "reactstrap/lib/Badge";
import CardText from "reactstrap/lib/CardText";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import Tooltip from "reactstrap/lib/Tooltip";
import { LanguageContext } from "_context";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import Modal from "reactstrap/lib/Modal";
import { Button } from "react-scroll";
import { DirectLinkCriteriaContext } from "_context";
import { DirectSearchResultsContext } from "_context";

function DirectLinkOverviewCard({
  history,
  match,
  searchResults,
  updateSearchResults,
  tabNumber,
}) {
  const user = profileService.userValue;
  const path = match;
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [searchModel, setSearchModel] = useContext(DirectLinkCriteriaContext);

  const [projectSearchList, setProjectSearchList] = useContext(
    DirectSearchResultsContext
  );

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  // React.useEffect(() => {
  //   document.body.classList.add("profile-page");
  //   document.body.classList.add("sidebar-collapse");
  //   document.documentElement.classList.remove("nav-open");
  //   document.body.style = "background-color: #ffffff";
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //   return function cleanup() {
  //     document.body.classList.remove("profile-page");
  //     document.body.classList.remove("sidebar-collapse");
  //   };
  // }, []);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleExternalLink = async (project) => {
    const model = {
      userId: user.profile.userId,
      directLinkId: project.id,
      projectURL:
        !project.link.startsWith("http://") &&
        !project.link.startsWith("https://")
          ? `http://${project.link}`
          : `${project.link}`,
      clickedExternalLink: true,
    };
    await directLinkSeenService
      .AddDirectLinkSeenAsync(model)
      .then(() => {
        const win = window.open(model.projectURL, "_blank");
        win.focus();
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  };
  async function ToggleDirectLinkBookmark(model) {
    await directLinkSeenService
      .ToggleDirectLinkBookmarkAsync(model)
      .then((x) => {
        handleBookmarkFromBookmarked(model.directLinkId, model.bookmark);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function handleBookmarkFromBookmarked(index, flag) {
    if (flag === true) {
      let updatedList = searchResults.map((item) => {
        if (item.id === index) {
          item.bookmarked = flag;
          return item;
        } else {
          return item; // else return unmodified item
        }
      });
      updateSearchResults(updatedList);
      let updatedSearchList = projectSearchList.list.map((item) => {
        if (item.id === index) {
          item.bookmarked = flag;
          return item;
        } else {
          return item; // else return unmodified item
        }
      });
      const model = {
        totalCount: projectSearchList.totalCount,
        list: updatedSearchList,
      };
      setProjectSearchList(model);
    } else {
      if (tabNumber === "4") {
        const updatedList = searchResults.filter((item) => item.id !== index);
        updateSearchResults(updatedList);
        let updatedSearchList = projectSearchList.list.map((item) => {
          if (item.id === index) {
            item.bookmarked = flag;
            return item;
          } else {
            return item; // else return unmodified item
          }
        });
        const model = {
          totalCount: projectSearchList.totalCount,
          list: updatedSearchList,
        };
        setProjectSearchList(model);
      } else {
        let updatedList = searchResults.map((item) => {
          if (item.id === index) {
            item.bookmarked = flag;
            return item;
          } else {
            return item; // else return unmodified item
          }
        });
        updateSearchResults(updatedList);
        let updatedSearchList = projectSearchList.list.map((item) => {
          if (item.id === index) {
            item.bookmarked = flag;
            return item;
          } else {
            return item; // else return unmodified item
          }
        });
        const model = {
          totalCount: projectSearchList.totalCount,
          list: updatedSearchList,
        };
        setProjectSearchList(model);
      }
    }
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div className="modal-body col-md-12">
          <p>{errorMessage}</p>
        </div>

        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            style={
              rtl ? { margin: "auto", textAlign: "right" } : { margin: "auto" }
            }
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      {searchResults &&
        searchResults.map((project) => {
          return (
            <Row>
              <Col md="12" style={{ margin: "auto" }}>
                <Card>
                  <CardBody
                    className={rtl ? "text-left" : ""}
                    style={
                      rtl
                        ? { paddingBottom: "10px", direction: "ltr" }
                        : { paddingBottom: "10px" }
                    }
                  >
                    <Row style={{ marginBottom: "1rem" }}>
                      <Col md="11">
                        <Link
                          tag="h4"
                          style={{ fontSize: "18px" }}
                          onClick={() => {
                            handleExternalLink(project);
                          }}
                        >
                          <UncontrolledTooltip target="link">
                            {t("link.tooltip")}
                          </UncontrolledTooltip>
                          <button
                            class="btn btn-primary btn-round btn-sm"
                            id="link"
                          >
                            <i class="fa-solid fa-arrow-up-right-from-square pr-2"></i>{" "}
                            {t("btnText.viewOpportunity")}
                          </button>
                        </Link>
                      </Col>
                      <Col md="1">
                        <Link
                          id="bookmark"
                          hidden={project.bookmarked === true}
                          tag="h4"
                          style={{ fontSize: "18px" }}
                        >
                          <i
                            className="now-ui-icons location_bookmark"
                            style={{
                              color: "#0E6EB8",
                            }}
                            onClick={async () => {
                              const model = {
                                directLinkId: project.id,
                                userId: user.profile.userId,
                                bookmark: true,
                              };
                              await ToggleDirectLinkBookmark(model);
                            }}
                          ></i>
                          <UncontrolledTooltip target="bookmark">
                            {t("bookmark.click")}
                          </UncontrolledTooltip>
                        </Link>
                        <Link
                          id="unbookmark"
                          hidden={project.bookmarked === false}
                          tag="h4"
                          style={{ fontSize: "18px" }}
                        >
                          <i
                            className="fa fa-bookmark"
                            style={{
                              color: "#0E6EB8",
                            }}
                            onClick={async () => {
                              const model = {
                                directLinkId: project.id,
                                userId: user.profile.userId,
                                bookmark: false,
                              };
                              await ToggleDirectLinkBookmark(model);
                            }}
                          ></i>
                          <UncontrolledTooltip
                            target="unbookmark"
                            placement="bottom"
                          >
                            {t("unbookmark.click")}
                          </UncontrolledTooltip>
                        </Link>
                      </Col>
                    </Row>

                    {project !== null && project.title !== null ? (
                      <Row>
                        <Col
                          md="11"
                          style={{
                            marginBottom: "20px",
                            marginTop: "10px",
                          }}
                        >
                          {project.title}
                        </Col>
                      </Row>
                    ) : null}

                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      <CardText
                        // style={{
                        //   marginLeft: "10%",
                        //   marginRight: "10%",
                        // }}
                        style={{ width: "350px" }}
                      >
                        <i
                          className="fa fa-upload"
                          aria-hidden="true"
                          style={{
                            paddingRight: "13px",
                            paddingLeft: "13px",
                          }}
                        ></i>
                        <small style={{ fontSize: "80%" }}>
                          {`${new Date(
                            project.publicationDateTime
                          ).getUTCDate()}/${
                            monthNames[
                              new Date(
                                project.publicationDateTime
                              ).getUTCMonth()
                            ]
                          }/${new Date(
                            project.publicationDateTime
                          ).getFullYear()}` !== "1/January/1"
                            ? `${new Date(
                                project.publicationDateTime
                              ).getUTCDate()}/${
                                monthNames[
                                  new Date(
                                    project.publicationDateTime
                                  ).getUTCMonth()
                                ]
                              }/${new Date(
                                project.publicationDateTime
                              ).getFullYear()}`
                            : ""}
                        </small>
                      </CardText>
                      <CardText>
                        <i
                          class="fa fa-hourglass-end"
                          aria-hidden="true"
                          style={{
                            paddingRight: "12px",
                            paddingLeft: "12px",
                          }}
                        ></i>
                        <small style={{ fontSize: "80%" }}>
                          {project.closingDate !== null &&
                          `${new Date(project.closingDate).getUTCDate()}/${
                            monthNames[
                              new Date(project.closingDate).getUTCMonth()
                            ]
                          }/${new Date(project.closingDate).getFullYear()}` !==
                            "1/January/1"
                            ? `${new Date(project.closingDate).getUTCDate()}/${
                                monthNames[
                                  new Date(project.closingDate).getUTCMonth()
                                ]
                              }/${new Date(project.closingDate).getFullYear()}`
                            : ""}
                        </small>
                      </CardText>
                    </div>
                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      {project !== null &&
                      project.country !== null &&
                      project.country.countryCode !== null ? (
                        <>
                          <CardText style={{ width: "350px" }}>
                            <i
                              class="fas fa-map-marker-alt"
                              aria-hidden="true"
                              style={{
                                paddingLeft: "3px",
                                paddingRight: "3px",
                                marginLeft: "10px",
                              }}
                            ></i>
                            <ReactCountryFlag
                              countryCode={project.country.countryCode}
                              svg
                              style={{
                                width: "1.5em",
                                height: "1.5em",
                                marginTop: "-2px",
                                marginRight: "10px",
                                marginLeft: "10px",
                                borderRadius: "0px",
                              }}
                              title="LB"
                            />
                            <small style={{ fontSize: "80%" }}>
                              {project !== null &&
                              project.country !== null &&
                              project.country.countryName !== null
                                ? project.country.countryName
                                : ""}
                            </small>
                          </CardText>
                        </>
                      ) : null}
                    </div>
                    <Col style={{ marginTop: "7px", marginBottom: "7px" }}>
                      <Row>
                        <div
                          style={{
                            paddingLeft: "3px",
                            paddingRight: "3px",
                          }}
                        >
                          <Badge
                            style={{
                              backgroundColor: "#f5f5fa",
                              color: "#000000",
                              border: "none",
                              fontSize: "85%",
                            }}
                          >
                            {project !== null &&
                            project !== null &&
                            project.typeOfIndustry !== null &&
                            project.typeOfIndustry.typeOfIndustryName !== null
                              ? project.typeOfIndustry.typeOfIndustryName
                              : ""}
                          </Badge>
                        </div>
                        <div
                          style={{
                            paddingLeft: "3px",
                            paddingRight: "3px",
                          }}
                        >
                          <Badge
                            style={{
                              backgroundColor: "#f5f5fa",
                              color: "#000000",
                              border: "none",
                              fontSize: "85%",
                            }}
                          >
                            {project !== null &&
                            project !== null &&
                            project.typeOfIndustry !== null &&
                            project.typeOfIndustry.typeOfServiceName
                              ? project.typeOfIndustry.typeOfServiceName
                              : ""}
                          </Badge>
                        </div>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          );
        })}
    </>
  );
}

export default DirectLinkOverviewCard;
