import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/directLinkSeen`;

export const directLinkSeenService = {
  AddDirectLinkSeenAsync,
  ToggleDirectLinkBookmarkAsync,
  GetAllDirectLinkSeenAsync,
};

function AddDirectLinkSeenAsync(params) {
  return fetchWrapper.post(`${baseUrl}/AddDirectLinkSeen`, params);
}

function ToggleDirectLinkBookmarkAsync(params) {
  return fetchWrapper.post(`${baseUrl}/ToggleDirectLinkBookmark`, params);
}

function GetAllDirectLinkSeenAsync(userId, bookmark, language) {
  return fetchWrapper.get(
    `${baseUrl}/GetAllDirectLinkSeen/${userId}/${bookmark}/${language}`
  );
}
