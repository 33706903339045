import React, { useState, useContext } from "react";
import * as Yup from "yup";
// reactstrap components
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { Link } from "react-router-dom";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import Edit from "@material-ui/icons/Edit";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import { Paper, ThemeProvider } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@mui/material/Tooltip";
import { Field } from "formik";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { theme } from "../../../theme.js";
import Modal from "reactstrap/lib/Modal";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { profileService } from "_services/profile.service.js";
import { searchService } from "_services/search.service.js";
import { LanguageContext } from "_context";
import { badgeService } from "_services/BadgeService.js";
import update from "immutability-helper";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import "assets/css/generalStyles.css";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  FileCopyIcon: forwardRef((props, ref) => (
    <FileCopyIcon {...props} ref={ref} />
  )),
};

function ManuallyGrantBagde({ type, history, match, location }) {
  const user = profileService.userValue;
  const badgeType = type != null ? type : location.state.type;
  const { path } = match;
  const [badge, setBadge] = useState(null);
  const [badges, setBadges] = useState(null);
  const [badgeId, setBadgeId] = useState(null);
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [role, setRole] = useState(null);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowDataID, setRowDataID] = useState(null);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [allSelected, setAllSelected] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [page, setPage] = useState(1);
  const pageRef = React.useRef(0);
  const [itemCount, setItemCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const resetSearch = React.useRef(false);
  var columns = [
    {
      title: t("admin.users.userName"),
      field: "userName",
      //   render: (item) => (
      //     <img
      //       src={require("assets/img/g8.jpg").default}
      //       alt=""
      //       border="3"
      //       height="100"
      //       width="100"
      //     />
      //   ),
    },
    {
      title: t("createCompanyProfile.companyName"),
      field: "companyName",
    },
    {
      title: t("badge.name"),
      field: "badgeName",
    },
    {
      title: null,
      field: "",
      headerStyle: {
        textAlign: "right",
      },
      render: (data) =>
        // console.log("dATA:", data),
        allSelected ? (
          data.selected == false ? (
            <div className="d-flex justify-content-end">
              <Tooltip title={t("viewUser")}>
                <IconButton>
                  <VisibilityIcon
                    style={{
                      color: "#0E6EB8",
                    }}
                    onClick={() => {
                      const win = window.open(
                        `/admin/users/viewProfile/${data.userId}?fromBadges=true`,
                        "_blank"
                      );
                      win.focus();
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("add.badge")}>
                <IconButton>
                  <AddCircleOutlineIcon
                    style={{
                      color: "#0E6EB8",
                    }}
                    onClick={() => {
                      data.selected = true;
                      setUsers([...users]);
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              <FormControl
                sx={{ m: 1, width: 300 }}
                style={{ margin: "0 20px" }}
              >
                <Select
                  name="badge"
                  placeholder={t("VettingBadge")}
                  value={selectedBadge}
                  onChange={(e) => handleBadgeChange(e.target.value)}
                  MenuProps={MenuProps}
                  style={{ height: "36px", borderRadius: 0 }}
                >
                  <MenuItem key={null} value={null}></MenuItem>
                  {badges.map((badge) => (
                    <MenuItem key={badge.id} value={badge.id}>
                      {badge.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Tooltip title={t("confirm.badge")}>
                <IconButton>
                  <CheckIcon
                    style={{
                      color: "#0E6EB8",
                    }}
                    onClick={() => {
                      setSelectedUser(data.userId);
                      setAlert(!alert);
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("clear.badge")}>
                <IconButton>
                  <ClearIcon
                    style={{
                      color: "#949494",
                    }}
                    onClick={() => {
                      const userIndex = users.findIndex(
                        (x) => x._id == data._id
                      );
                      setSelectedBadge("");
                      setUsers(
                        update(users, {
                          [userIndex]: {
                            selected: {
                              $set: false,
                            },
                          },
                        })
                      );
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          )
        ) : (
          <div className="d-flex justify-content-end">
            <Tooltip title={t("remove.badge")}>
              <IconButton>
                <RemoveCircleIcon
                  style={{ fill: "#FF3636" }}
                  onClick={() => {
                    setSelectedUser(data.userId);
                    setDeleteAlert(!deleteAlert);
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>
        ),
    },
  ];

  var columnsBadge = [
    {
      title: t("admin.users.userName"),
      field: "userName",
      //   render: (item) => (
      //     <img
      //       src={require("assets/img/g8.jpg").default}
      //       alt=""
      //       border="3"
      //       height="100"
      //       width="100"
      //     />
      //   ),
    },
    {
      title: t("createCompanyProfile.companyName"),
      field: "companyName",
    },
    {
      title: null,
      field: "",
      headerStyle: {
        textAlign: "right",
      },
      render: (data) =>
        // console.log("dATA:", data),
        allSelected ? (
          data.selected == false ? (
            <div className="d-flex justify-content-end">
              <Tooltip title={t("viewUser")}>
                <IconButton>
                  <VisibilityIcon
                    style={{
                      color: "#0E6EB8",
                    }}
                    onClick={() => {
                      const win = window.open(
                        `/admin/users/viewProfile/${data.userId}?fromBadges=true`,
                        "_blank"
                      );
                      win.focus();
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("add.badge")}>
                <IconButton>
                  <AddCircleOutlineIcon
                    style={{
                      color: "#0E6EB8",
                    }}
                    onClick={() => {
                      data.selected = true;
                      setUsers([...users]);
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              <FormControl
                sx={{ m: 1, width: 300 }}
                style={{ margin: "0 20px" }}
              >
                <Select
                  name="badge"
                  placeholder={t("VettingBadge")}
                  value={selectedBadge}
                  onChange={(e) => handleBadgeChange(e.target.value)}
                  MenuProps={MenuProps}
                  style={{ height: "36px", borderRadius: 0 }}
                >
                  <MenuItem key={null} value={null}></MenuItem>
                  {badges.map((badge) => (
                    <MenuItem key={badge.id} value={badge.id}>
                      {badge.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Tooltip title={t("confirm.badge")}>
                <IconButton>
                  <CheckIcon
                    style={{
                      color: "#0E6EB8",
                    }}
                    onClick={() => {
                      setSelectedUser(data.userId);
                      setAlert(!alert);
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("clear.badge")}>
                <IconButton>
                  <ClearIcon
                    style={{
                      color: "#949494",
                    }}
                    onClick={() => {
                      const userIndex = users.findIndex(
                        (x) => x._id == data._id
                      );
                      setSelectedBadge("");
                      setUsers(
                        update(users, {
                          [userIndex]: {
                            selected: {
                              $set: false,
                            },
                          },
                        })
                      );
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          )
        ) : (
          <div className="d-flex justify-content-end">
            <Tooltip title={t("remove.badge")}>
              <IconButton>
                <RemoveCircleIcon
                  style={{ fill: "#FF3636" }}
                  onClick={() => {
                    setSelectedUser(data.userId);
                    setDeleteAlert(!deleteAlert);
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>
        ),
    },
  ];

  let [currentPage, setCurrentPage] = useState(0);
  const userPerPage = pageSize;
  const begin = React.useRef(0);
  const end = React.useRef(userPerPage);

  const count = Math.ceil(itemCount / userPerPage);

  function next() {
    if (end.current > itemCount) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
    begin.current = (currentPage + 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function handleRemove(id) {
    const newList = users.filter((user) => user.id !== id);
    setUsers(newList);
  }
  async function deleteSelectedItem() {
    await profileService
      .RemoveManuallyGrantedBadgeToUser(selectedUser, badgeId)
      .then(() => {
        // handleRemove(selectedUser);
        fetchUsersWithBadges(badgeId);
        setDeleteAlert(!deleteAlert);
      })
      .catch((error) => {
        setDeleteAlert(!deleteAlert);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }
  const fetchData = async () => {
    if (resetSearch.current == false) setLoading(true);
    await badgeService.GetAllManuallyGrantedBadges().then((res) => {
      const tempList = res.map((badge) => ({
        id: badge.id,
        name: badge.name,
        image:
          "http://outerpond.ixia.tech:88/backend/GetPartnerLogo/IxiaTechLogo.png",
        type: badge.type,
        selected: false,
      }));
      setBadges(tempList.reverse());
    });
    const model = {
      emailLogsToSkip: begin.current,
      startingEmailLogs: pageSize,
    };
    await profileService.GetAllUsersWithNoBadges(model).then((res) => {
      const tempList = res.map((user) => ({
        _id: user._id,
        userName: user.userName,
        userId: user.userId,
        vettingBadgeId: user.vettingBadgeId,
        companyName: user.companyName,
        selected: false,
        badgeName:
          user.asBadge != null && user.asBadge.length > 0
            ? user.asBadge[0].name
            : "",
      }));
      setUsers(tempList);
    });
    setLoading(false);
    resetSearch.current = false;
  };

  const fetchUsersWithBadges = async (badgeId) => {
    const model = {
      emailLogsToSkip: begin.current,
      startingEmailLogs: pageSize,
    };
    await profileService.GetAllUsersWithBadges(badgeId, model).then((res) => {
      const tempList = res.map((user) => ({
        _id: user._id,
        userName: user.userName,
        userId: user.userId,
        vettingBadgeId: user.vettingBadgeId,
        companyName: user.companyName,
        selected: false,
      }));
      setUsers(tempList);
    });
  };

  const fetchAllUsers = async () => {
    const model = {
      emailLogsToSkip: begin.current,
      startingEmailLogs: pageSize,
    };
    await profileService.GetAllUsersWithNoBadges(model).then((res) => {
      const tempList = res.map((user) => ({
        _id: user._id,
        userName: user.userName,
        userId: user.userId,
        vettingBadgeId: user.vettingBadgeId,
        companyName: user.companyName,
        selected: false,
        badgeName:
          user.asBadge != null && user.asBadge.length > 0
            ? user.asBadge[0].name
            : "",
      }));
      setUsers(tempList);
    });
  };

  const fetchCount = async () => {
    if (badge == null)
      await profileService.GetAllUsersWithNoBadgesCount().then((res) => {
        setItemCount(res);
      });
    else
      await profileService
        .GetAllUsersWithNoBadgesCount(badge.id)
        .then((res) => {
          setItemCount(res);
        });
  };

  const handleChange = (value) => {
    setPageSize(parseInt(value));
    end.current = begin.current + parseInt(value);
  };

  React.useEffect(() => {
    fetchCount();
  }, [badge]);

  React.useEffect(() => {
    if (badge == null) fetchData();
    else fetchUsersWithBadges(badge.id);
  }, [end.current, begin.current, badge, pageSize]);

  React.useEffect(() => {
    fetchCount();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleBadgeSelection = async (selectedBadge) => {
    // fetchUsersWithBadges(selectedBadge.id);
    setAllSelected(false);
    badges.forEach((badge) => {
      if (badge.id == selectedBadge.id) {
        badge.selected = !badge.selected;
      } else {
        badge.selected = false;
      }
    });
    setBadges([...badges]);
    setBadgeId(selectedBadge.id);
    setBadge(selectedBadge);
    begin.current = 0;
    end.current = pageSize;
    setCurrentPage(0);
  };

  const handleSelection = async () => {
    fetchAllUsers();
    badges.forEach((badge) => {
      badge.selected = false;
    });
    setBadges([...badges]);
    setAllSelected(!allSelected);
    setBadge(null);
    begin.current = 0;
    end.current = pageSize;
    setCurrentPage(0);
  };

  const handleBadgeChange = (badge) => {
    setSelectedBadge(badge);
  };

  const handleConfirmation = async () => {
    await profileService
      .AddManuallyGrantedBadgeToUser(selectedUser, selectedBadge)
      .then((res) => {
        fetchAllUsers();
        fetchCount();
        setSelectedBadge(null);
        setAlert(!alert);
      });
  };

  const handleSearch = async (e) => {
    setSearchKeyword(e);
    const model = {
      Keyword: e,
      UserPerforminSearchId: user.profile.userId,
      PageNumber: 0,
      PageSize: pageSize,
    };
    await searchService.GetAllUsersWithNoBadgesAsync(model).then((res) => {
      console.log(res);
      const tempList = res.map((user) => ({
        _id: user._id,
        userName: user.userName,
        userId: user.userId,
        vettingBadgeId: user.vettingBadgeId,
        companyName: user.companyName,
        selected: false,
        badgeName:
          user.asBadge != null && user.asBadge.length > 0
            ? user.asBadge[0].name
            : "",
      }));
      setUsers(tempList);
      begin.current = 0;
      end.current = pageSize;
      setPageSize(pageSize);
    });
    await searchService.GetAllUsersWithNoBadgesCountAsync(model).then((res) => {
      setItemCount(res);
    });
  };

  // if (loading) {
  //   return (
  //     <>
  //       <center
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           position: "absolute",
  //           left: "50%",
  //           top: "100%",
  //         }}
  //       >
  //         <PulseLoader color="#0E6EB8" />
  //       </center>
  //     </>
  //   );
  // }
  return (
    <>
      <Container>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title"></h5>
          </div>
          <div
            className={
              rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
            }
          >
            <p>{t("confirmation.msg.badge")}</p>
          </div>

          <div className="modal-footer d-flex justify-content-center">
            <Button
              color="primary"
              type="button"
              style={{ marginRight: "100px" }}
              onClick={(e) => {
                handleConfirmation();
              }}
            >
              {t("label.yes")}
            </Button>
            <Button
              color="danger"
              type="button"
              onClick={(e) => {
                setSelectedBadge(null);
                setAlert(!alert);
              }}
            >
              {t("label.no")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("delete.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
            }
          >
            <p>{t("delete.alert.body")}</p>
          </div>

          <div className="modal-footer" style={{ justifyContent: "flex-end" }}>
            <center>
              <Link
                className="mr-2"
                type="button"
                style={{ margin: "auto", color: "#000000" }}
                onClick={(e) => setDeleteAlert(!deleteAlert)}
              >
                {t("cancel.link")}
              </Link>
              <Button
                color="danger"
                type="button"
                className="mr-2"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => deleteSelectedItem()}
              >
                {t("delete")}
              </Button>
            </center>
          </div>
        </Modal>
        <Row>
          {loading && (
            <center
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: "50%",
                top: "100%",
              }}
            >
              <PulseLoader color="#0E6EB8" />
            </center>
          )}
          <Col className={"col"}>
            {!loading && (
              <>
                <ThemeProvider theme={theme}>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",

                      width: "100%",
                      columnGap: "10rem",
                    }}
                  >
                    <div
                      style={{
                        color: "#0e6eb8",
                        fontSize: "1.25rem",
                        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                        fontWeight: 500,
                        letterSpacing: "0.0075em",
                        textTransform: "none",
                        marginLeft: "15px",
                      }}
                    >
                      {t("userList")}
                    </div>
                    <FormControl sx={{ m: 1 }} variant="standard">
                      <Input
                        id="searchInput"
                        type="text"
                        placeholder={t("search")}
                        onChange={(e) => handleSearch(e.currentTarget.value)}
                        startAdornment={
                          <InputAdornment position="start">
                            <IconButton aria-label="toggle password visibility">
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(e) => {
                                const searchInput =
                                  document.getElementById("searchInput");
                                searchInput.value = "";
                                resetSearch.current = true;
                                fetchData();
                                fetchCount();
                              }}
                              // onMouseDown={handleMouseDownPassword}
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Row>
                  <MaterialTable
                    components={{
                      Container: (props) => (
                        <Paper
                          {...props}
                          className="usersTable"
                          elevation={0}
                        />
                      ),
                      Toolbar: (props) => (
                        <div>
                          <MTableToolbar {...props} />
                          <div style={{ padding: "0px 10px" }}>
                            <Stack direction="row" spacing={1}>
                              {allSelected == false ? (
                                <Chip
                                  label={t("no.badges")}
                                  className="css-nz3zgg-MuiButtonBase-root-MuiChip-root"
                                  style={{
                                    marginRight: 5,
                                    border: "1px dashed #1565C0",
                                    backgroundColor: "transparent",
                                  }}
                                  clickable={true}
                                  onClick={(e) => handleSelection()}
                                />
                              ) : (
                                <Chip
                                  label={t("no.badges")}
                                  color="primary"
                                  className="css-t0864r-MuiButtonBase-root-MuiChip-root"
                                  style={{
                                    marginRight: 5,
                                  }}
                                  clickable={true}
                                  onClick={(e) => handleSelection()}
                                />
                              )}
                              {badges &&
                                badges.map((badge) =>
                                  badge.selected == false ? (
                                    <Chip
                                      label={badge.name}
                                      className="css-nz3zgg-MuiButtonBase-root-MuiChip-root"
                                      style={{
                                        marginRight: 5,
                                        border: "1px dashed #1565C0",
                                        backgroundColor: "transparent",
                                      }}
                                      clickable={true}
                                      onClick={(e) =>
                                        handleBadgeSelection(badge)
                                      }
                                    />
                                  ) : (
                                    <Chip
                                      label={badge.name}
                                      color="primary"
                                      className="css-t0864r-MuiButtonBase-root-MuiChip-root"
                                      style={{
                                        marginRight: 5,
                                      }}
                                      clickable={true}
                                      onClick={(e) =>
                                        handleBadgeSelection(badge)
                                      }
                                    />
                                  )
                                )}
                            </Stack>
                          </div>
                        </div>
                      ),
                      // Pagination: (props) => (
                      //   <TablePagination
                      //     {...props}
                      //     emptyRowsWhenPaging={false}
                      //     rowsPerPageOptions={[5, 10, 20]}
                      //     rowsPerPage={10}
                      //     count={55}
                      //     page={page}
                      //     onPageChange={(e, page) => {
                      //       console.log("page", page);
                      //       console.log("e", e);
                      //       setPage(page);
                      //     }}
                      //   />
                      // ),
                    }}
                    title={<></>}
                    columns={allSelected ? columns : columnsBadge}
                    data={users}
                    icons={tableIcons}
                    onSearchChange={handleSearch}
                    options={{
                      paging: false,
                      actionsColumnIndex: -1,
                      cellStyle: {
                        textAlign: rtl ? "right" : "left",
                      },
                      headerStyle: {
                        textAlign: rtl ? "right" : "left",
                      },
                    }}
                    localization={{
                      header: {
                        actions: t("actions"),
                      },
                      toolbar: {
                        searchPlaceholder: t("search"),
                      },
                    }}
                    // options={{
                    //   totalCount: 200,
                    //   pageSize: users.length < 5 ? users.length : 5,
                    //   showFirstLastPageButtons: true,
                    //   pageSizeOptions: [],
                    //   paginationType: "stepped",
                    //   actionsColumnIndex: -1,
                    // }}
                    // totalCount={200}
                    // onChangePage={(pageData) => {
                    //   // your custom logic here, for example making a new API call with the new page number
                    //   console.log(pageData);
                    //   if (pageData > page) {
                    //     pageRef.current = pageData * 2 * 5;
                    //   } else {
                    //     pageRef.current = (pageData - 1) * 2 * 5;
                    //   }
                    // }}
                    // onRowClick={(event, rowData) => {
                    //   const win = window.open(
                    //     `/admin/users/viewProfile/${rowData.userId}`,
                    //     "_blank"
                    //   );
                    //   win.focus();
                    // }}
                    actions={
                      [
                        // {
                        //   icon: () => <Edit className="actionIcon" />,
                        //   tooltip: t("edit"),
                        //   onClick: (event, rowData) => {
                        //     console.log(rowData);
                        //     history.push(`${path}/edit/vetting/${rowData.id}`, {
                        //       state: rowData,
                        //       type: rowData.type,
                        //     });
                        //   },
                        // },
                        // {
                        //   icon: () => (
                        //     <Delete
                        //       className="actionIcon"
                        //       style={{ fill: "#FF3636", float: "right" }}
                        //     />
                        //   ),
                        //   tooltip: t("delete"),
                        //   //isFreeAction: true,
                        //   onClick: (event, rowData) => {
                        //     console.log(rowData);
                        //     setRowDataID(rowData.id);
                        //     setDeleteAlert(!deleteAlert);
                        //   },
                        // },
                        // {
                        //   icon: () => (
                        //     <AddBox
                        //       className="actionIcon"
                        //       style={{
                        //         color: "#000000",
                        //       }}
                        //     />
                        //   ),
                        //   tooltip: t("add"),
                        //   isFreeAction: true,
                        //   onClick: (event, rowData) => {
                        //     history.push(`${path}/add`);
                        //   },
                        // },
                      ]
                    }
                  />
                </ThemeProvider>
                <div className="d-flex justify-content-end align-items-center">
                  <select
                    value={pageSize}
                    onChange={(e) => handleChange(e.target.value)}
                    style={{
                      height: "36px",
                      borderRadius: 0,
                      width: "auto",
                      fontSize: "12px",
                    }}
                    className={"form-control"}
                  >
                    <option
                      key={5}
                      value={5}
                      style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                    >
                      5
                    </option>
                    <option
                      key={10}
                      value={10}
                      style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                    >
                      10
                    </option>
                    <option
                      key={15}
                      value={15}
                      style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                    >
                      15
                    </option>
                    <option
                      key={20}
                      value={20}
                      style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                    >
                      20
                    </option>
                    <option
                      key={50}
                      value={50}
                      style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                    >
                      50
                    </option>
                    <option
                      key={100}
                      value={100}
                      style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                    >
                      100
                    </option>
                  </select>
                  <IconButton
                    aria-label="next"
                    disabled={currentPage == 0 ? true : false}
                  >
                    {rtl ? (
                      <ArrowForwardIosSharpIcon
                        onClick={() => prev()}
                        style={{
                          color: "#d9dcde",
                        }}
                      />
                    ) : (
                      <ArrowBackIosSharpIcon
                        onClick={() => prev()}
                        style={{
                          color: "#d9dcde",
                        }}
                      />
                    )}
                  </IconButton>
                  <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
                    {begin.current} -{" "}
                    {end.current > itemCount ? itemCount : end.current}{" "}
                    {rtl ? "من" : "of"} {itemCount}
                  </p>
                  <IconButton
                    aria-label="next"
                    disabled={end.current >= itemCount ? true : false}
                  >
                    {rtl ? (
                      <ArrowBackIosSharpIcon
                        onClick={() => next()}
                        style={{
                          color: "#d9dcde",
                        }}
                      />
                    ) : (
                      <ArrowForwardIosSharpIcon
                        onClick={() => next()}
                        style={{
                          color: "#d9dcde",
                        }}
                      />
                    )}
                  </IconButton>
                </div>
              </>
            )}
          </Col>
          {/* <Col className={"col-4 d-flex flex-column align-items-center"}>
            {t("badgesList")}
            <Card
              style={{
                border: "1px dashed black",
                boxShadow: "none",
                marginTop: "20px",
                height: "100%",
              }}
            >
              <CardBody>
                {badges &&
                  badges.map((badge) => (
                    <img
                      src={badge.image}
                      alt=""
                      draggable="true"
                      onDragStart={(event) => drag(event)}
                    />
                  ))}
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </>
  );
}

export default ManuallyGrantBagde;
