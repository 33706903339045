import React, { useState, createContext, useEffect } from "react";
import { partnerService } from "_services";
import { profileService } from "_services";

// Create Context Object
export const SSInvitationHistoryContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const SSInvitationHistoryContextProvider = props => {

    const user = profileService.userValue;
    const [SShistory, setSSHistory] = useState([]);

  return (
    <SSInvitationHistoryContext.Provider value={[SShistory, setSSHistory]}>
      {props.children}
    </SSInvitationHistoryContext.Provider>
  );
};