// import { createMuiTheme } from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core/styles'

// export const theme = createMuiTheme({
  export const theme = createTheme({
  props:{
    MuiPaper:{
     elevation: 0
    },
    MuiCard:{
      elevation:0
    }
  },
  // palette: {
  //   type: "dark",
  // },
  overrides: {
    MuiCard:{
      root:{
        boxShadow:"none"
      }
    },
    MuiToolbar:{
      root:
      {
        marginLeft:"-10px"
      }
    },
  MuiTypography:{
    h6:{
      textTransform:"none",
      color:"#0e6eb8"
    }
  },
  MuiIconButton:{
    root:{
      padding: "5px",
    },
    colorInherit:
    {
      color:"#0E6EB8"
    },
    // label:{
    //   color:"#0E6EB8"
    // }
  },
  // MuiSvgIcon:{
  //   root:{
  //     fill:"#0E6EB8",
 
  //   }
  // }
  },
});
