import React, { useContext, useEffect, useState } from "react";
import "assets/css/mainSection.css";
import LandingPageNavbar2 from "components/Navbars/LandingPageNavbar2.jsx";
import { AccountTypes } from "_helpers";
import { profileService, adminService, languageService } from "_services";
import SeekerNavbar from "components/Navbars/SeekerNavbar";
import ProviderNavbar from "components/Navbars/ProviderNavbar";
import PartnerNavbar from "components/Navbars/PartnerNavbar";
import { ProfileStatus } from "_helpers";
import LoggedInUserNavbar from "components/Navbars/LoggedInUserNavbar";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { LanguageContext } from "_context";
import LandingPageNavbarMobileTablet2 from "components/Navbars/LandingPageNavbarMobileTablet2";
import ProviderNavbarMobileTablet from "components/Navbars/ProviderNavbarMobileTablet";
import PartnerNavbarMobileTablet from "components/Navbars/PartnerNavbarMobileTablet";
import SeekerNavbarMobileTablet from "components/Navbars/SeekerNavbarMobileTablet";
import * as Scroll from "react-scroll";
import AdminNavbarMobileTablet from "components/Navbars/AdminNavbarMobileTablet";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import PulseLoader from "react-spinners/PulseLoader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { NavItem, NavLink, Nav, Navbar } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import "./parsing-quill.css";

function ChallengePage({ history, location }) {
  const user = profileService.userValue;
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [currentLan] = useContext(LanguageContext);
  const [challenge, setChallenge] = useState(null);

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 48,
    height: 48,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1223px)",
  });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user !== null && user.profileExpired)
      profileService.Logout(user.profile.userId);
  }, []);

  const fetchData = async (lan) => {
    setLoading(true);
    await languageService.GetAllLanguagesAsync().then(async (x) => {
      var lang = x.filter((lan) => lan.languageName === currentLan)[0];
      await adminService.GetChallengeAsync().then((x) => {
        var description = x.description;
        var descriptionObj = description.filter(
          (x) => x.languageID == lang.languageID
        )[0];
        var challengeObj = {
          code: x.code,
          isPublic: x.isPublic,
          description: descriptionObj.name,
        };
        setChallenge(challengeObj);
      });
    });

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    let scrollTo = window.location.hash.replace("#", "");
    Scroll.scroller.scrollTo(scrollTo, {
      offset: -50,
      duration: 1,
      smooth: true,
      delay: 0,
    });
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  React.useEffect(() => {
    const element = document.getElementById("challengeSection");

    if (element != null) {
      // Set the height of the element to the screen height
      element.style.minHeight = `${window.innerHeight}px`;
    }
  });

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  if (loading) {
    return <></>;
  }
  return (
    <>
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker &&
        isDesktopOrLaptop && <SeekerNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker &&
        !isDesktopOrLaptop && <SeekerNavbarMobileTablet props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
        isDesktopOrLaptop && <ProviderNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
        !isDesktopOrLaptop && <ProviderNavbarMobileTablet props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner &&
        isDesktopOrLaptop && <PartnerNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner &&
        !isDesktopOrLaptop && <PartnerNavbarMobileTablet props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Admin &&
        isDesktopOrLaptop && <AdminNavbar props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Admin &&
        !isDesktopOrLaptop && <AdminNavbarMobileTablet props={history} />}
      {user && user.profile.profileStatus !== ProfileStatus.Active && (
        <LoggedInUserNavbar props={history} />
      )}
      {!user && isDesktopOrLaptop && <LandingPageNavbar2 props={history} />}
      {!user && !isDesktopOrLaptop && (
        <LandingPageNavbarMobileTablet2 props={history} />
      )}
      <div
        id="challengeSection"
        style={
          isDesktopOrLaptop
            ? { padding: "70px 70px 0px 70px", position: "relative" }
            : { paddingTop: "70px" }
        }
      >
        <div style={isDesktopOrLaptop ? { paddingBottom: "250px" } : {}}>
          {challenge && ReactHtmlParser(challenge.description)}
        </div>
        <div
          style={
            isMobile
              ? {}
              : {
                  position: "absolute",
                  bottom: "0",
                  right: "70px",
                  left: "70px",
                }
          }
        >
          <Row
            className={
              isMobile
                ? "d-flex justify-content-center"
                : "d-flex justify-content-start"
            }
            style={{ backgroundColor: "#EEF5FB" }}
          >
            <Col className="col-md-4 col-sm-12 col-12">
              <Nav className="flex-row justify-content-start mt-3">
                {/* <NavItem>
                <a className="nav-link " href="#serviceProviders">
                  {t("featuredSP")}
                </a>
              </NavItem> */}
                <Col
                  className={
                    isMobile
                      ? "col-md-12 col-sm-12 col-12 d-flex justify-content-center"
                      : "col-md-12 col-sm-12 col-12"
                  }
                >
                  {/* {user && (
                    <NavItem>
                      <NavLink
                        style={
                          rtl
                            ? {
                                color: "#4D4D4D",
                                fontFamily: "SpaceGrotesk",
                                textAlign: "justify",
                              }
                            : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                        }
                        onClick={() => {
                          history.push("/plans");
                        }}
                        tag={Link}
                      >
                        {t("mainPage.plans")}
                      </NavLink>
                    </NavItem>
                  )} */}
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                      }
                      onClick={() => {
                        history.push("/ourteam");
                      }}
                      tag={Link}
                    >
                      {t("landingpage.ourteam")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                      }
                      onClick={() => {
                        history.push("/contactUs");
                      }}
                      tag={Link}
                    >
                      {t("navbar.contactUs")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                      }
                      onClick={() => {
                        history.push("/videos");
                      }}
                      tag={Link}
                    >
                      {t("demo.videos")}
                    </NavLink>
                  </NavItem>
                  {challenge && challenge.isPublic && (
                    <NavItem>
                      <NavLink
                        style={
                          rtl
                            ? {
                                color: "#4D4D4D",
                                fontFamily: "SpaceGrotesk",
                                textAlign: "justify",
                              }
                            : {
                                color: "#4D4D4D",
                                fontFamily: "SpaceGrotesk",
                              }
                        }
                        onClick={() => {
                          history.push("/million-dollar-challenge");
                        }}
                        tag={Link}
                      >
                        {t("million.dollar")}
                      </NavLink>
                    </NavItem>
                  )}
                </Col>
                {/* <Col
                      className={
                        isMobile
                          ? "col-md-6 col-sm-12 col-12 pb-3 d-flex justify-content-center"
                          : "col-md-6 col-sm-12 col-12"
                      }
                    >
                      
                    </Col> */}

                {/* <LanguageDropdown /> */}
              </Nav>
            </Col>
            <Col
              row
              className={
                isMobile
                  ? "d-flex justify-content-center align-items-center col-md-4 col-sm-12 col-12 pt-4 pb-2"
                  : "d-flex justify-content-end align-items-center col-md-4 col-sm-12 col-12"
              }
            >
              {" "}
              <Col
                className={
                  isMobile
                    ? "d-flex justify-content-center align-items-center flex-column mt-0"
                    : "d-flex justify-content-center align-items-center flex-column mt-4"
                }
              >
                <Row>
                  <p
                    style={{
                      color: "#4D4D4D",
                      fontSize: "14px",
                      fontFamily: "SpaceGroteskMedium",
                      textAlign: "left",
                      marginBottom: "0",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("mainPage.usaid")}
                  </p>
                </Row>
                <Row>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      width: "140px",
                    }}
                    src={require("assets/img/usaid_logo_vertical.png").default}
                    alt=""
                  />
                </Row>
              </Col>
            </Col>
            {!isMobile ? (
              <Col
                className="col-md-4 col-sm-12 col-12 d-flex justify-content-end align-items-end"
                style={{ paddingBottom: "10px" }}
              >
                <Row
                  className={
                    "d-flex justify-content-end align-items-center py-1"
                  }
                  style={{ backgroundColor: "#EEF5FB" }}
                >
                  <p
                    style={
                      // isMobile
                      //   ? {
                      //       display: "none",
                      //       color: "#fff",
                      //       fontSize: "16px",
                      //       fontFamily: "SpaceGrotesk",
                      //       marginRight: "15px",
                      //       marginBottom: "0",
                      //     }
                      //   : {
                      {
                        color: "#4D4D4D",
                        fontSize: "16px",
                        fontFamily: "SpaceGrotesk",
                        marginRight: "15px",
                        marginBottom: "0",
                      }
                    }
                  >
                    {t("followUs")}
                  </p>
                  <img
                    style={{
                      width: "32px",
                      height: "32px",
                      objectFit: "contain",
                      marginRight: "15px",
                      cursor: "pointer",
                    }}
                    src={require("assets/img/linkedin.png").default}
                    onClick={() => {
                      const win = window.open(
                        `https://www.linkedin.com/company/outerpond/`,
                        "_blank"
                      );
                      win.focus();
                    }}
                    alt=""
                  />
                  <img
                    style={{
                      width: "32px",
                      height: "32px",
                      objectFit: "contain",
                      marginRight: "15px",
                      cursor: "pointer",
                    }}
                    src={require("assets/img/twitter.png").default}
                    onClick={() => {
                      const win = window.open(
                        `https://twitter.com/outerpond`,
                        "_blank"
                      );
                      win.focus();
                    }}
                    alt=""
                  />
                  <img
                    style={{
                      width: "32px",
                      height: "32px",
                      objectFit: "contain",
                      marginRight: "15px",
                      cursor: "pointer",
                    }}
                    src={require("assets/img/YoutubeSVG.svg").default}
                    onClick={() => {
                      const win = window.open(
                        `https://www.youtube.com/@outerpond3036`,
                        "_blank"
                      );
                      win.focus();
                    }}
                    alt=""
                  />
                </Row>
              </Col>
            ) : (
              <></>
            )}
          </Row>

          {isMobile && (
            <Row
              className={
                isMobile
                  ? "d-flex justify-content-center align-items-center py-3"
                  : "d-flex justify-content-end align-items-center py-3"
              }
              style={{ backgroundColor: "#EEF5FB" }}
            >
              <p
                style={
                  // isMobile
                  //   ? {
                  //       display: "none",
                  //       color: "#fff",
                  //       fontSize: "16px",
                  //       fontFamily: "SpaceGrotesk",
                  //       marginRight: "15px",
                  //       marginBottom: "0",
                  //     }
                  //   : {
                  {
                    color: "#4D4D4D",
                    fontSize: "16px",
                    fontFamily: "SpaceGrotesk",
                    marginRight: "15px",
                    marginBottom: "0",
                  }
                }
              >
                {t("followUs")}
              </p>
              <img
                style={{
                  width: "32px",
                  height: "32px",
                  objectFit: "contain",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                src={require("assets/img/linkedin.png").default}
                onClick={() => {
                  const win = window.open(
                    `https://www.linkedin.com/company/outerpond/`,
                    "_blank"
                  );
                  win.focus();
                }}
                alt=""
              />
              <img
                style={{
                  width: "32px",
                  height: "32px",
                  objectFit: "contain",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                src={require("assets/img/twitter.png").default}
                onClick={() => {
                  const win = window.open(
                    `https://twitter.com/outerpond`,
                    "_blank"
                  );
                  win.focus();
                }}
                alt=""
              />
              <img
                style={{
                  width: "32px",
                  height: "32px",
                  objectFit: "contain",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                src={require("assets/img/YoutubeSVG.svg").default}
                onClick={() => {
                  const win = window.open(
                    `https://www.youtube.com/@outerpond3036`,
                    "_blank"
                  );
                  win.focus();
                }}
                alt=""
              />
            </Row>
          )}

          <Row
            className={
              isMobile
                ? "d-flex flex-column justify-content-between align-items-center pt-3 px-4"
                : "d-flex justify-content-between align-items-center px-4"
            }
            style={{
              backgroundColor: "#132438",
            }}
          >
            <p
              style={{
                color: "#fff",
                fontSize: "12px",
                fontFamily: "SpaceGrotesk",
                marginRight: "30px",
                marginBottom: "0",
              }}
            >
              {t("mainPage.footer")}
            </p>
            <Nav className="d-flex align-items-center">
              <NavItem>
                <NavLink
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    fontFamily: "SpaceGrotesk",
                  }}
                  to={"/footer-sub-pages/1"}
                  tag={Link}
                >
                  {t("footer.faq")}
                </NavLink>
              </NavItem>
              <span className="text-white">—</span>
              <NavItem>
                <NavLink
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    fontFamily: "SpaceGrotesk",
                  }}
                  to={"/footer-sub-pages/2"}
                  tag={Link}
                >
                  {t("footer.terms")}
                </NavLink>
              </NavItem>
              <span className="text-white">—</span>
              <NavItem>
                <NavLink
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    fontFamily: "SpaceGrotesk",
                  }}
                  to={"/footer-sub-pages/3"}
                  tag={Link}
                >
                  {t("footer.privacyPolicy")}
                </NavLink>
              </NavItem>
            </Nav>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ChallengePage;
