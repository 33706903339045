import DefaultFooter from "components/Footers/DefaultFooter";
import SignUpNavbar from "components/Navbars/SignUpNavbar";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AfterUpgradePage from "./AfterUpgradePage";
import ChangePassword from "./ChangePassword";
import ContactUs from "./ContactUs";
import ForgotPassword from "./ForgotPassword";
import LoginForm from "./LoginForm";
import ResendVerificationEmail from "./ResendVerificationEmail";
import ResetPassword from "./ResetPassword";
import SignUpForm from "./SignUpForm";
import Unsubscribe from "./Unsubscribe";
import UpgradeAccountForm from "./UpgradeAccountForm";

function Account({ history, match }) {
  const { path } = match;
  return (
    <div>
      <SignUpNavbar />
      <Switch>
        <Route path={`${path}/login`} component={LoginForm} />
        <Route path={`${path}/register`} component={SignUpForm} />
        <Route
          path={`${path}/resend-email`}
          component={ResendVerificationEmail}
        />
        <Route path={`${path}/forgot-password`} component={ForgotPassword} />
        <Route path={`${path}/reset-password`} component={ResetPassword} />
        <Route path={`${path}/unsubscribe`} component={Unsubscribe} />
        <PrivateRoute
          path={`${path}/change-password`}
          component={ChangePassword}
        />
        <Route path={`${path}/contact-us`} component={ContactUs} />
        <Route
          path={`${path}/upgrade-account`}
          component={UpgradeAccountForm}
        />
        <Route path={`${path}/after-upgrade`} component={AfterUpgradePage} />
      </Switch>
    </div>
  );
}

export { Account };
