import { config } from "../_helpers/config";
import { post,get} from 'axios';
import { fetchWrapper } from "../_helpers";
import { profileService } from "./profile.service";

const baseUrl = `${config.apiUrl}/partner`;

export const partnerService = {
    ProcessSpExcel,
    ProcessSSExcel,
    GetPartnerDocumentErrors,
    GetPartnerEmailRecipients,
    DownloadPartnerExcel,
    ResendSSInvitationEmailAsync
};

function ProcessSpExcel(params) {
  const user = profileService.userValue;
  if(user != null){
    const options = {
      headers: {
        'content-type': 'multipart/form-data',
          'Authorization' : `Bearer ${user.jwtToken}`
      },
    }
    return post(`${baseUrl}/ProcessSpExcel`, params, options);
  }else return post(`${baseUrl}/ProcessSpExcel`, params);
}

function ProcessSSExcel(params) {
  const user = profileService.userValue;
  if(user != null){
    const options = {
      headers: {
        'content-type': 'multipart/form-data',
          'Authorization' : `Bearer ${user.jwtToken}`
      },
    }
    return post(`${baseUrl}/ProcessSsExcel`, params, options);
  }else return post(`${baseUrl}/ProcessSsExcel`, params);
}

function GetPartnerDocumentErrors(id,source) {
  return fetchWrapper.get(`${baseUrl}/GetPartnerDocumentErrors/${id}/${source}`);
}

function GetPartnerEmailRecipients(id,source) {
  return fetchWrapper.get(`${baseUrl}/GetPartnerEmailRecipients/${id}/${source}`);
}

function DownloadPartnerExcel(index) {
  const user = profileService.userValue;
  return get(`${baseUrl}/DownloadPartnerDocument/${index}`,
  {
    headers: {
     
      'Authorization': `Bearer ${user !== null ? user.jwtToken : ""}`
    },
  responseType: 'blob',
  Accept:[ 'application/vnd.ms-excel','application/pdf']
  }).then(response=>{
    return response});
}

// function DownloadPartnerExcel(sp,ss) {
//   return fetchWrapper.get(`${baseUrl}/DownloadPartnerExcelDocument/${sp}/${ss}`);
// }

function ResendSSInvitationEmailAsync(id) {
  return fetchWrapper.get(`${baseUrl}/ResendSSInvitationEmail/${id}`);
}