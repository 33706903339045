import React, { useState, createContext } from "react";

// Create Context Object
export const DirectLinksListContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const DirectLinksListContextProvider = props => {

    const model = {
        totalCount:0,
        list: []
    }
  const [directLinksList, setDirectLinksList] = useState(model);
  // useEffect(() => {
  // }, [ searchModel]);

  return (
    <DirectLinksListContext.Provider value={[directLinksList, setDirectLinksList]}>
      {props.children}
    </DirectLinksListContext.Provider>
  );
};