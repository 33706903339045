import React, { useState, useContext } from "react";
import { Button, CardBody, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { Paper, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../theme.js";
import { adminService, profileService, searchService } from "_services";
import Modal from "reactstrap/lib/Modal";
import { LanguageContext } from "_context";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import { IconButton } from "@material-ui/core";
import Switch from "react-bootstrap-switch";
import update from "immutability-helper";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import PulseLoader from "react-spinners/PulseLoader";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Row from "reactstrap/lib/Row";
import Tooltip from "@mui/material/Tooltip";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  SubdirectoryArrowLeftIcon: forwardRef((props, ref) => (
    <SubdirectoryArrowLeftIcon {...props} ref={ref} />
  )),
  FileCopyIcon: forwardRef((props, ref) => (
    <FileCopyIcon {...props} ref={ref} />
  )),
  AccountBalanceIcon: forwardRef((props, ref) => (
    <AccountBalanceIcon {...props} ref={ref} />
  )),
};

function AffiliatedUsersList({ history, match, location }) {
  const user = profileService.userValue;
  const { path } = match;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [surveys, setSurveys] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const [iconPills, setIconPills] = React.useState("1");
  const [existingUser, setExistingUser] = React.useState(true);
  const [rowDataID, setRowDataID] = useState(null);

  const resetSearch = React.useRef(false);

  var columns = [
    {
      title: t("contactUs.email"),
      field: "profile.userName",
      width: "20%",
    },
    {
      title: t("admin.users.companyName"),
      field: "profile.companyName",
      width: "10%",
    },
    // {
    //   title: t("admin.users.type"),
    //   field: "profileTypeView.profileTypeName",
    //   width: "10%",
    // },
    {
      title: t("admin.users.status"),
      field: "profile.profileStatus",
      width: "10%",
    },
    // {
    //   title: t("signUp.providerType.title"),
    //   field: "spType",
    //   width: "10%",
    // },
    // {
    //   title: t("createCompanyStartupStage"),
    //   field: "startupStage",
    //   width: "10%",
    // },
    // {
    //   title: t("expirationDate"),
    //   field: "expirationDate",
    //   width: "10%",
    // },
    // {
    //   title: t("UserActivityBadgeName"),
    //   field: "activityBadgeName",
    // },
    // {
    //   title: t("UserVettingBadgeName"),
    //   field: "vettingBadgeName",
    // },
    // {
    //   title: t("UserPlanName"),
    //   field: "planName",
    // },
    // {
    //   title: t("admin.users.activationDate"),
    //   field: "activationDate",
    //   width: "10%",
    // },
    // {
    //   title: t("uploadedBy"),
    //   field: "uploadedBy",
    //   width: "10%",
    // },
    // {
    //   title: t("uploadedFrom"),
    //   field: "source",
    //   width: "10%",
    // },
    {
      title: t("showInAffiliated"),
      field: "profile.selectedForAffiliated",
      render: (data) => (
        <>
          <Switch
            //defaultChecked={data.selectedPartner}
            value={data.selectedForAffiliated}
            id={data.profile.userId}
            checked={data.selectedForAffiliated}
            offColor=""
            offText={t("addProject.projectNDA.false")}
            onColor="brown"
            onText={t("addProject.projectNDA.true")}
            onChange={(e) => {
              console.log(data);
              // setSelected(!selected);
              //setSelected(!data.selectedPartner);
              handleChangeAffiliated(
                !data.selectedForAffiliated,
                data.profile.userId,
                null,
                null,
                null
              );
            }}
          ></Switch>
        </>
      ),
      width: "10%",
    },
  ];

  var columns_non_existing = [
    {
      title: t("contactUs.email"),
      field: "userName",
    },
    {
      title: t("admin.users.companyName"),
      field: "companyName",
    },
    {
      title: t("showInAffiliated"),
      field: "selectedForAffiliated",
      render: (data) => (
        <>
          <Switch
            //defaultChecked={data.selectedPartner}
            value={data.selectedForAffiliated}
            id={data._id}
            checked={data.selectedForAffiliated}
            offColor=""
            offText={t("addProject.projectNDA.false")}
            onColor="brown"
            onText={t("addProject.projectNDA.true")}
            onChange={(e) => {
              handleChangeAffiliated(
                !data.selectedForAffiliated,
                data._id,
                data.companyName,
                data.userName,
                data.additionalInfo
              );
            }}
          ></Switch>
        </>
      ),
    },
  ];

  let [currentPage, setCurrentPage] = useState(0);
  const userPerPage = pageSize;
  const begin = React.useRef(0);
  const end = React.useRef(userPerPage);

  const count = Math.ceil(itemCount / userPerPage);

  function next() {
    if (end.current > itemCount) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
    begin.current = (currentPage + 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  const handleChange = (value) => {
    setPageSize(parseInt(value));
    end.current = begin.current + parseInt(value);
  };

  const handleChangeAffiliated = async (
    e,
    id,
    companyName,
    userName,
    additionalInfo
  ) => {
    if (existingUser == true) {
      const model = {
        SelectedForAffiliated: e,
      };
      const usersIndex = !existingUser
        ? users.findIndex((x) => x.userId == id)
        : users.findIndex((x) => x.profile.userId == id);
      await profileService
        .UpdatePartnerSelected(id, model)
        .then((x) => {
          console.log(usersIndex);
          setUsers(
            update(users, {
              [usersIndex]: {
                selectedForAffiliated: {
                  $set: e,
                },
              },
            })
          );
          console.log(users);
        })
        .catch((error) => {
          console.log("Error:", error);
          // if (error.message !== undefined) {
          //   setError(error.message);
          //   if (error.error !== null && error.error.length > 0) {
          //     for (var i = 0; i < error.error.length; i++) {
          //       if (error.error[i].languageName == currentLan) {
          //         setError(error.error[i].error);
          //       }
          //     }
          //   }
          // } else {
          // setError(error);
          // // }
          // setErrorAlert(!errorAlert);
        });
    } else {
      const model = {
        _id: id,
        SelectedForAffiliated: e,
        CompanyName: companyName,
        UserName: userName,
        AdditionalInfo: additionalInfo,
      };
      const usersIndex = users.findIndex((x) => x._id == id);
      await adminService
        .UpdateExternalUser(model)
        .then((x) => {
          setUsers(
            update(users, {
              [usersIndex]: {
                selectedForAffiliated: {
                  $set: e,
                },
              },
            })
          );
          console.log(users);
        })
        .catch((error) => {
          console.log("Error:", error);
          // if (error.message !== undefined) {
          setError(error);
          //   if (error.error !== null && error.error.length > 0) {
          //     for (var i = 0; i < error.error.length; i++) {
          //       if (error.error[i].languageName == currentLan) {
          //         setError(error.error[i].error);
          //       }
          //     }
          //   }
          // } else {
          // setError(error);
          // // }
          setErrorAlert(!errorAlert);
        });
    }
  };

  async function deleteSelectedItem(id) {
    const model = {
      _id: id,
    };
    await adminService
      .DeleteExternalUser(model)
      .then(() => {
        handleRemove(id);
        setDeleteAlert(!deleteAlert);
      })
      .catch((error) => {
        setDeleteAlert(!deleteAlert);
        if (error.message !== undefined) {
          setError(error.message);
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  function handleRemove(id) {
    const newList = users.filter((item) => item._id !== id);
    setUsers(newList);
  }

  const fetchData = async () => {
    // if (begin.current == 0) setLoading(true);
    if (resetSearch.current == false) setLoading(true);
    const model = {
      emailLogsToSkip: begin.current,
      startingEmailLogs: pageSize,
    };
    if (existingUser == true)
      await adminService.GetAllUsersAsync(model).then((x) => {
        var list = [];
        list = x;
        for (var i = 0; i < list.length; i++) {
          if (list[i].profile.spType === "Start Up") {
            list[i].profile.spType = t("signUp.providerType.startup");
          }
        }
        setUsers(list);
      });
    else
      await adminService.GetListOfExternalUsers(model).then((x) => {
        setUsers(x);
      });

    setLoading(false);
    // if (begin.current == 0) setLoading(false);
    resetSearch.current = false;
  };

  const fetchCount = async () => {
    if (existingUser == true)
      await adminService.GetAllUsersCountAsync().then((res) => {
        setItemCount(res);
      });
    else
      await adminService.GetListOfExternalUsersCount().then((res) => {
        setItemCount(res);
      });
  };

  React.useEffect(() => {
    fetchData();

    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [end.current, begin.current, pageSize, existingUser]);

  React.useEffect(() => {
    fetchCount();
  }, [existingUser]);

  const handleSearch = async (e) => {
    setSearchKeyword(e);
    const model = {
      Keyword: e,
      UserPerforminSearchId: user.userId,
      PageNumber: 0,
      PageSize: pageSize,
    };
    if (existingUser == true) {
      await searchService.SearchUsersAsync(model).then((res) => {
        console.log(res);
        setUsers(res);
        begin.current = 0;
        end.current = pageSize;
        setPageSize(pageSize);
      });
      await searchService.SearchUsersCountAsync(model).then((res) => {
        console.log(res);
        setItemCount(res);
      });
    } else {
      await searchService.SearchExternalProfilesAsync(model).then((res) => {
        console.log(res);
        setUsers(res);
        begin.current = 0;
        end.current = pageSize;
        setPageSize(pageSize);
      });
      await searchService
        .SearchExternalProfilesCountAsync(model)
        .then((res) => {
          console.log(res);
          setItemCount(res);
        });
    }
  };

  const TableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",

          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            width: "100%",
            columnGap: "10rem",
          }}
        >
          <div
            style={{
              color: "#0e6eb8",
              fontSize: "1.25rem",
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
              fontWeight: 500,
              letterSpacing: "0.0075em",
              textTransform: "none",
            }}
          >
            {t("userList")}
          </div>
          {/* {!loading && (
          <section
            style={{
              fontFamily: `-apple-system, sans-serif`,
              fontSize: "17px",
              padding: "0 4rem",
              display: "flex",
              fontWeight: 500,
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: "2.5rem",
              color: "#0e6eb8",
            }}
          >
            <span
              style={{
                // borderRight: "1px solid #0e6eb8",

                paddingRight: "2rem",
              }}
            >
              SP: {SPTotalCount}
            </span>
            <span
              style={{
                // borderRight: "1px solid #0e6eb8",
                paddingRight: "2rem",
              }}
            >
              SS: {SSTotalCount}
            </span>
            <span>PO: {POTotalCount}</span>
          </section>
        )} */}
        </div>
      </div>
    );
  };

  // if (loading) {
  //   return (
  //     <>
  //       <center
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           position: "absolute",
  //           left: "50%",
  //           top: "50%",
  //         }}
  //       >
  //         <PulseLoader color="#0E6EB8" />
  //       </center>
  //     </>
  //   );
  // }
  return (
    <>
      <div className="section">
        <Container>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={{ margin: "auto" }}
                onClick={() => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={successAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{t("manuallySendSurvey.success")}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={{ margin: "auto" }}
                onClick={() => setSuccessAlert(!successAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("delete.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{t("delete.alert.body")}</p>
            </div>

            <div
              className="modal-footer"
              style={{ justifyContent: "flex-end" }}
            >
              <center>
                <Link
                  className="mr-2"
                  type="button"
                  style={{ margin: "auto", color: "#000000" }}
                  onClick={() => setDeleteAlert(!deleteAlert)}
                >
                  {t("cancel.link")}
                </Link>
                <Button
                  color="danger"
                  type="button"
                  className="mr-2"
                  style={
                    rtl
                      ? { margin: "auto", textAlign: "right" }
                      : { margin: "auto" }
                  }
                  onClick={() => deleteSelectedItem(rowDataID)}
                >
                  {t("delete")}
                </Button>
              </center>
            </div>
          </Modal>

          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                tag={Link}
                className={iconPills === "1" ? "active" : ""}
                style={{ boxShadow: "none" }}
                // href="#pablo"
                onClick={async (e) => {
                  // await fetchAlertData();
                  setIconPills("1");
                  setExistingUser(true);
                  e.preventDefault();
                }}
              >
                {t("existing.users")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                className={iconPills === "2" ? "active" : ""}
                style={{ boxShadow: "none" }}
                //  href="#pablo"
                onClick={async (e) => {
                  // await fetchNewslettersData();
                  setIconPills("2");
                  setExistingUser(false);
                  e.preventDefault();
                }}
              >
                {t("nonexisting.users")}
              </NavLink>
            </NavItem>
          </Nav>
          {loading && (
            <center
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: "50%",
                top: "100%",
              }}
            >
              <PulseLoader color="#0E6EB8" />
            </center>
          )}
          {!loading && (
            <>
              <ThemeProvider theme={theme}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",

                    width: "100%",
                    columnGap: "10rem",
                  }}
                >
                  <div
                    style={{
                      color: "#0e6eb8",
                      fontSize: "1.25rem",
                      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                      fontWeight: 500,
                      letterSpacing: "0.0075em",
                      textTransform: "none",
                      marginLeft: "15px",
                    }}
                  >
                    {t("userList")}
                  </div>
                  <FormControl
                    className="d-flex flex-row align-items-center"
                    sx={{ m: 1 }}
                    variant="standard"
                  >
                    <Input
                      id="searchInput"
                      type="text"
                      placeholder={t("search")}
                      onChange={(e) => handleSearch(e.currentTarget.value)}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton aria-label="toggle password visibility">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) => {
                              const searchInput =
                                document.getElementById("searchInput");
                              searchInput.value = "";
                              resetSearch.current = true;
                              fetchData();
                              fetchCount();
                            }}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {existingUser == false && (
                      <Tooltip title={t("add.button")}>
                        <AddBox
                          className="actionIcon"
                          style={{
                            color: "#000000",
                            cursor: "pointer",
                          }}
                          onClick={(event, rowData) => {
                            history.push({
                              pathname: `${path}/add`,
                            });
                          }}
                        ></AddBox>
                      </Tooltip>
                    )}
                  </FormControl>
                </Row>
                <MaterialTable
                  components={{
                    Container: (props) => (
                      <Paper {...props} className="usersTable" elevation={0} />
                    ),
                  }}
                  title={<span></span>}
                  columns={existingUser ? columns : columns_non_existing}
                  data={users}
                  icons={tableIcons}
                  onSearchChange={handleSearch}
                  options={{
                    // pageSize: 10,
                    search: false,
                    paging: false,
                    actionsColumnIndex: -1,
                    cellStyle: {
                      textAlign: rtl ? "right" : "left",
                    },
                    headerStyle: {
                      textAlign: rtl ? "right" : "left",
                    },
                  }}
                  localization={{
                    header: {
                      actions: t("actions"),
                    },
                    toolbar: {
                      searchPlaceholder: t("search"),
                    },
                  }}
                  actions={
                    existingUser == false
                      ? [
                          // {
                          //   icon: () => (
                          //     <AddBox
                          //       className="actionIcon"
                          //       style={{
                          //         color: "#000000",
                          //       }}
                          //     />
                          //   ),
                          //   tooltip: t("add.non.existing"),
                          //   isFreeAction: true,
                          //   onClick: () => {
                          //     history.push(`${path}/add`);
                          //   },
                          // },
                          {
                            icon: () => (
                              <Edit
                                className="actionIcon"
                                style={{
                                  color: "#0E6EB8",
                                }}
                              />
                            ),
                            tooltip: t("edit"),
                            onClick: (event, rowData) => {
                              history.push(`${path}/edit/${rowData._id}`, {
                                state: rowData,
                              });
                            },
                          },
                          {
                            icon: () => (
                              <Delete
                                style={{ fill: "#FF3636" }}
                                className="actionIcon"
                              />
                            ),
                            tooltip: t("delete"),
                            onClick: (event, rowData) => {
                              setRowDataID(rowData._id);
                              setDeleteAlert(!deleteAlert);
                            },
                          },
                        ]
                      : []
                  }
                />
              </ThemeProvider>
              <div className="d-flex justify-content-end align-items-center">
                <select
                  value={pageSize}
                  onChange={(e) => handleChange(e.target.value)}
                  style={{
                    height: "36px",
                    borderRadius: 0,
                    width: "auto",
                    fontSize: "12px",
                  }}
                  className={"form-control"}
                >
                  <option
                    key={5}
                    value={5}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    5
                  </option>
                  <option
                    key={10}
                    value={10}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    10
                  </option>
                  <option
                    key={15}
                    value={15}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    15
                  </option>
                  <option
                    key={20}
                    value={20}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    20
                  </option>
                  <option
                    key={50}
                    value={50}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    50
                  </option>
                  <option
                    key={100}
                    value={100}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    100
                  </option>
                </select>
                <IconButton
                  aria-label="next"
                  disabled={currentPage == 0 ? true : false}
                >
                  {rtl ? (
                    <ArrowForwardIosSharpIcon
                      onClick={() => prev()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  ) : (
                    <ArrowBackIosSharpIcon
                      onClick={() => prev()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  )}
                </IconButton>
                <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
                  {begin.current} -{" "}
                  {end.current > itemCount ? itemCount : end.current}{" "}
                  {rtl ? "من" : "of"} {itemCount}
                </p>
                <IconButton
                  aria-label="next"
                  disabled={end.current >= itemCount ? true : false}
                >
                  {rtl ? (
                    <ArrowBackIosSharpIcon
                      onClick={() => next()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  ) : (
                    <ArrowForwardIosSharpIcon
                      onClick={() => next()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  )}
                </IconButton>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
}

export default AffiliatedUsersList;
