import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditVideo from "./AddEditVideo";
import VideosList from "./VideosList";

function Videos({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={VideosList} exact />
        <PrivateRoute path={`${path}/add`} component={AddEditVideo} />
        <PrivateRoute path={`${path}/edit/:id`} component={AddEditVideo} />
      </Switch>
    </div>
  );
}

export { Videos };
