import React, { useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import "../../../../assets/css/custom-quill.css";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import Card from "reactstrap/lib/Card";
import ReactQuill from "react-quill";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import { AccountTypes } from "_helpers";
import { LanguageContext } from "_context";
import { testimonialService } from "_services";
import { profileService } from "_services";
import { languageService } from "_services";
import Switch from "react-bootstrap-switch";
import { useEffect } from "react";
import * as Yup from "yup";
import queryString from "query-string";
import update from "immutability-helper";

function AddEditTestimonials({ history, match, location }) {
  const isAddMode = !match.params.id;
  const parsed = queryString.parse(location.search);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [languagesName, setLanguagesName] = useState([]);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState([]);
  const countRef = useRef([]);
  const [existingUser, setExistingUser] = React.useState(
    parsed && parsed.user == "1" ? true : false
  );
  const [iconPills, setIconPills] = React.useState(
    parsed && parsed.user == "1" ? "1" : "2"
  );
  const [selectedUser, setSelectedUser] = useState(
    location.state != null && location.state.state.userId != null
      ? location.state.state.userId
      : null
  );

  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  var validationSchema = existingUser
    ? Yup.object().shape({})
    : Yup.object().shape({
        user: Yup.string().required(t("fieldRequired")),
        company: Yup.string().required(t("fieldRequired")),
        jobTitle: Yup.string().required(t("fieldRequired")),
      });

  const id = match.params.id;
  const userData = location.state != null ? location.state.state : null;

  const modules = {
    toolbar: [],
  };
  const formats = [];

  const [initialValues, setInitialValues] = useState(
    !isAddMode
      ? existingUser == false && userData != null
        ? {
            user: userData.user,
            company: userData.company,
            jobTitle: userData.jobTitle,
            description: userData.description,
          }
        : {
            user: "",
            company: "",
            jobTitle: "",
            description: [],
          }
      : {
          userID: "",
          description: [],
        }
  );
  // console.log(initialValues);
  // console.log(isAddMode);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);

  const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;

        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === "object") {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey);
        }
      });
      // console.log(result);
      return result;
    };

    return transformObjectToDotNotation(formikErrors);
  };

  const ScrollToFieldError = ({
    scrollBehavior = { behavior: "smooth", block: "center" },
  }) => {
    const { submitCount, isValid, errors } = useFormikContext();

    useEffect(() => {
      if (isValid) return;

      const fieldErrorNames = getFieldErrorNames(errors);
      if (fieldErrorNames.length <= 0) return;

      const element = document.querySelector(`[name='${fieldErrorNames[0]}']`);
      // console.log(element);
      if (!element) return;

      element.scrollIntoView(scrollBehavior);
      // console.log(fieldErrorNames);
    }, [submitCount]);

    return null;
  };

  // or

  function initialForm(translations) {
    var newJson = {
      userID: "",
      description: [],
    };
    translations.map((x) => {
      var newKey = x.languageName;
      var newVal = "";
      newJson[newKey] = newVal;
    });

    setInitialValues(newJson);
  }

  function editInitialForm(translations, testimonial) {
    var newJson =
      existingUser == true
        ? {
            userID: testimonial.userID,
            description: testimonial.description,
          }
        : {
            user: testimonial.user,
            company: testimonial.company,
            jobTitle: testimonial.jobTitle,
            description: testimonial.description,
          };
    translations.map((x) => {
      var newKey = x.languageName;
      // console.log(x.languageName);
      if (testimonial.description != null) {
        for (var i = 0; i < testimonial.description.length; i++) {
          if (x.languageID === testimonial.description[i].languageID) {
            var newVal = testimonial.description[i].name;
            // console.log(newVal);
            newJson[newKey] = newVal;
            // console.log(newJson[newKey]);
            languagesName[i] = testimonial.description[i].name;
          }
        }
        setLanguagesName(languagesName);
      }
    });
    setInitialValues(newJson);
  }

  const fetchData = async () => {
    setLoading(true);
    let arr = [];
    await languageService.GetAllLanguagesAsync().then((x) => {
      setLanguages(x);
      x.forEach((lan) => arr.push(0));
      profileService.GetAllUsersAsync().then((x) => {
        setUsers(x);
      });
      if (isAddMode) {
        initialForm(x);
      } else {
        editInitialForm(x, location.state.state);

        //console.log(location.state.state);
      }
    });
    setCount(arr);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    //console.log(isAddMode);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    const model = existingUser
      ? {
          userID: selectedUser,
          isExisting: true,
          description: [],
        }
      : {
          user: initialValues.user,
          company: initialValues.company,
          jobTitle: initialValues.jobTitle,
          isExisting: false,
          description: [],
        };
    let list = [];

    if (languages !== null) {
      let eleList = [];
      for (var i = 0; i < languages.length; i++) {
        var hasText = languagesName[i];
        var ele = document.createElement("body");
        ele.innerHTML = hasText;

        [...ele.querySelectorAll("p")].forEach((para) => {
          if (para.innerText.trim() === "") ele.removeChild(para);
        });
        eleList.push(ele.innerHTML);

        if (eleList.length == languages.length) {
          for (var y = 0; y < eleList.length; y++) {
            if (eleList.includes("") || eleList.includes("undefined")) {
              setError(t("testimonialIsRequired"));
              setErrorAlert(!errorAlert);
              return false;
            }
          }
        }
        const translation = {
          languageID: languages[i].languageID,
          name: languagesName[i],
        };
        list.push(translation);
      }
    }

    model["description"] = list;
    if (isAddMode) {
      await testimonialService
        .AddTestimonialsAsync(model)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
      setSubmitting(false);
    } else {
      await testimonialService
        .UpdateTestimonialAsync(match.params.id, model)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
      setSubmitting(false);
    }
  }

  const handleOnChange = (e, i) => {
    if (e.length > 247) {
      setError("You exceeded the word count");
      setErrorAlert(!errorAlert);
    } else {
      languagesName[i] = e;
      setLanguagesName(languagesName);
      if (e.length == 11 && e.charAt(3) == "<") {
        setCount(
          update(count, {
            [i]: {
              $set: 0,
            },
          })
        );
      } else {
        setCount(
          update(count, {
            [i]: {
              $set: e.length - 7,
            },
          })
        );
      }
      countRef.current = e.length - 7;
    }
  };
  // const handleChange = (id) => {
  //   setUser(id);
  // };
  return (
    <div className="section testimonials">
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("done")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            {isAddMode ? (
              <p>{t("testimonialAddSuccess")}</p>
            ) : (
              <p>{t("testimonialUpdateSuccess")}</p>
            )}
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => {
                setAlert(!alert);
                if (isAddMode == true) {
                  history.push(".");
                } else {
                  history.push("..");
                }
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => {
              return (
                <Col>
                  <Card
                    className={rtl ? "text-right" : ""}
                    style={{ paddingTop: "20px" }}
                  >
                    <Link
                      className="pt-4 pl-4"
                      onClick={(e) =>
                        isAddMode ? history.push(".") : history.push("..")
                      }
                    >
                      {t("back")}
                    </Link>
                    <Nav className="justify-content-center" role="tablist" tabs>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          className={iconPills === "1" ? "active" : ""}
                          // href="#pablo"
                          onClick={async (e) => {
                            // await fetchAlertData();
                            e.preventDefault();
                            setIconPills("1");
                            setExistingUser(true);
                          }}
                        >
                          {t("existing.users")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          className={iconPills === "2" ? "active" : ""}
                          //  href="#pablo"
                          onClick={async (e) => {
                            // await fetchNewslettersData();
                            e.preventDefault();
                            setIconPills("2");
                            setExistingUser(false);
                          }}
                        >
                          {t("nonexisting.users")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Form autoComplete="off">
                      <ScrollToFieldError />
                      <CardBody>
                        {isAddMode ? (
                          <CardTitle className="text-center" tag="h4">
                            {t("add.testimonial")}
                          </CardTitle>
                        ) : (
                          <CardTitle className="text-center" tag="h4">
                            {t("edit.testimonial")}
                          </CardTitle>
                        )}
                        {existingUser == true ? (
                          <Row>
                            <Col md="12">
                              <div className="form-group">
                                <FormGroup
                                  className="col-md-8 d-flex flex-column mt-4"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="testimonial.Users">
                                    {t("testimonial.Users")}
                                  </label>
                                  <FormControl
                                    sx={{ m: 1, width: 300 }}
                                    style={{ margin: 0 }}
                                  >
                                    {/* <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    // value={users}
                                    // onChange={handleChange}
                                    style={{ height: "36px", borderRadius: 0 }}
                                    MenuProps={MenuProps}
                                  >
                                    {users.map((user) => (
                                      <MenuItem
                                        key={user.userId}
                                        value={user.userId}
                                      >
                                        {user.userName}
                                      </MenuItem>
                                    ))}
                                  </Select> */}
                                    <Field
                                      name="userID"
                                      placeholder={t("testimonial.Users")}
                                      value={selectedUser}
                                      onChange={(e) =>
                                        setSelectedUser(e.target.value)
                                      }
                                      as="select"
                                      className={
                                        "form-control" +
                                        (errors.userID && touched.userID
                                          ? " is-invalid"
                                          : "")
                                      }
                                    >
                                      <option key={null} value={null}></option>
                                      {users.map((user) => (
                                        <option key={user._id} value={user._id}>
                                          {user.userName}
                                        </option>
                                      ))}
                                    </Field>
                                    {errors.userID && touched.userID && (
                                      <ErrorMessage
                                        name="userID"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    )}
                                  </FormControl>
                                </FormGroup>
                              </div>

                              <div className="form-group">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "0",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Field
                                    disabled={true}
                                    placeholder={t("description.testimonial")}
                                    value={t("description.testimonial")}
                                    className={"form-control"}
                                  />
                                </FormGroup>
                              </div>
                              <div className="form-group">
                                {languages &&
                                  languages.map((x, i) => {
                                    return (
                                      <div className="form-group">
                                        <FormGroup
                                          className="col-md-8 mt-2"
                                          style={{
                                            margin: "0 auto",
                                            width: "100%",
                                            padding: "10px",
                                            marginTop: "-2.5%",
                                          }}
                                        >
                                          <div className="d-flex justify-content-between">
                                            <label htmlFor="description">
                                              {x.languageDescription}
                                            </label>
                                            <label
                                              htmlFor="description"
                                              style={{ color: "grey" }}
                                            >
                                              {count[i]}/240
                                            </label>
                                          </div>
                                          <ReactQuill
                                            name="description"
                                            style={{
                                              height: "150px",
                                              marginBottom: "6%",
                                              resize: "vertical",
                                            }}
                                            theme="snow"
                                            value={
                                              languagesName[i] === undefined
                                                ? null
                                                : languagesName[i]
                                            }
                                            onChange={(e) =>
                                              handleOnChange(e, i, i)
                                            }
                                            modules={modules}
                                            formats={formats}
                                          />
                                          {errors.planDescription &&
                                            touched.planDescription && (
                                              <ErrorMessage
                                                name="description"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            )}
                                        </FormGroup>
                                      </div>
                                    );
                                  })}
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <center>
                                    <Button
                                      color="primary"
                                      type="submit"
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting && (
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      )}
                                      {t("createCompanyProfile.save")}
                                    </Button>
                                  </center>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col md="12">
                              <div className="form-group">
                                <FormGroup
                                  className="col-md-8 d-flex flex-column mt-4"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="testimonial.user">
                                    {t("testimonial.user")}
                                  </label>
                                  <FormControl
                                    sx={{ m: 1, width: 300 }}
                                    style={{ margin: 0 }}
                                  >
                                    <Field
                                      name="user"
                                      // placeholder={t("testimonial.user")}
                                      // value={selectedUser}
                                      onChange={(e) => {
                                        setInitialValues({
                                          ...initialValues,
                                          user: e.target.value,
                                        });
                                        setFieldValue("user", e.target.value);
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.user && touched.user
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    {errors.user && touched.user && (
                                      <ErrorMessage
                                        name="user"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    )}
                                  </FormControl>
                                </FormGroup>
                              </div>
                              <div className="form-group">
                                <FormGroup
                                  className="col-md-8 d-flex flex-column mt-4"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="testimonial.company">
                                    {t("testimonial.company")}
                                  </label>
                                  <FormControl
                                    sx={{ m: 1, width: 300 }}
                                    style={{ margin: 0 }}
                                  >
                                    <Field
                                      name="company"
                                      // placeholder={t("testimonial.company")}
                                      // value={selectedUser}
                                      onChange={(e) => {
                                        setInitialValues({
                                          ...initialValues,
                                          company: e.target.value,
                                        });
                                        setFieldValue(
                                          "company",
                                          e.target.value
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.company && touched.company
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    {errors.company && touched.company && (
                                      <ErrorMessage
                                        name="company"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    )}
                                  </FormControl>
                                </FormGroup>
                              </div>
                              <div className="form-group">
                                <FormGroup
                                  className="col-md-8 d-flex flex-column mt-4"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="testimonial.jobTitle">
                                    {t("testimonial.jobTitle")}
                                  </label>
                                  <FormControl
                                    sx={{ m: 1, width: 300 }}
                                    style={{ margin: 0 }}
                                  >
                                    <Field
                                      name="jobTitle"
                                      // placeholder={t("testimonial.jobTitle")}
                                      // value={selectedUser}
                                      onChange={(e) => {
                                        setInitialValues({
                                          ...initialValues,
                                          jobTitle: e.target.value,
                                        });
                                        setFieldValue(
                                          "jobTitle",
                                          e.target.value
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.jobTitle && touched.jobTitle
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    {errors.jobTitle && touched.jobTitle && (
                                      <ErrorMessage
                                        name="jobTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    )}
                                  </FormControl>
                                </FormGroup>
                              </div>

                              <div className="form-group">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "0",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Field
                                    disabled={true}
                                    placeholder={t("description.testimonial")}
                                    value={t("description.testimonial")}
                                    className={"form-control"}
                                  />
                                </FormGroup>
                              </div>
                              <div className="form-group">
                                {languages &&
                                  languages.map((x, i) => {
                                    return (
                                      <div className="form-group">
                                        <FormGroup
                                          className="col-md-8 mt-2"
                                          style={{
                                            margin: "0 auto",
                                            width: "100%",
                                            padding: "10px",
                                            marginTop: "-2.5%",
                                          }}
                                        >
                                          <div className="d-flex justify-content-between">
                                            <label htmlFor="description">
                                              {x.languageDescription}
                                            </label>
                                            <label
                                              htmlFor="description"
                                              style={{ color: "grey" }}
                                            >
                                              {count[i]}/240
                                            </label>
                                          </div>
                                          <ReactQuill
                                            name="description"
                                            style={{
                                              height: "150px",
                                              marginBottom: "6%",
                                              resize: "vertical",
                                            }}
                                            theme="snow"
                                            value={
                                              languagesName[i] === undefined
                                                ? null
                                                : languagesName[i]
                                            }
                                            onChange={(e) =>
                                              handleOnChange(e, i)
                                            }
                                            modules={modules}
                                            formats={formats}
                                          />
                                          {errors.planDescription &&
                                            touched.planDescription && (
                                              <ErrorMessage
                                                name="description"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            )}
                                        </FormGroup>
                                      </div>
                                    );
                                  })}
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <center>
                                    <Button
                                      color="primary"
                                      type="submit"
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting && (
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      )}
                                      {t("createCompanyProfile.save")}
                                    </Button>
                                  </center>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default AddEditTestimonials;
