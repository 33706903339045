import React, { useContext, useEffect, useState } from "react";
import "assets/css/mainSection.css";
import LandingPageNavbar2 from "components/Navbars/LandingPageNavbar2.jsx";
import { AccountTypes } from "_helpers";
import { profileService, landingPageService, adminService } from "_services";
import SeekerNavbar from "components/Navbars/SeekerNavbar";
import ProviderNavbar from "components/Navbars/ProviderNavbar";
import PartnerNavbar from "components/Navbars/PartnerNavbar";
import { ProfileStatus } from "_helpers";
import LoggedInUserNavbar from "components/Navbars/LoggedInUserNavbar";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { WhoWeAreUserTypes } from "_helpers/WhoWeAreUserTypes";
import Modal from "reactstrap/lib/Modal";
import Col from "reactstrap/lib/Col";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import { LanguageContext } from "_context";
import LandingPageNavbarMobileTablet2 from "components/Navbars/LandingPageNavbarMobileTablet2";
import ProviderNavbarMobileTablet from "components/Navbars/ProviderNavbarMobileTablet";
import PartnerNavbarMobileTablet from "components/Navbars/PartnerNavbarMobileTablet";
import SeekerNavbarMobileTablet from "components/Navbars/SeekerNavbarMobileTablet";
import * as Scroll from "react-scroll";
import AdminNavbarMobileTablet from "components/Navbars/AdminNavbarMobileTablet";
import AdminNavbar from "components/Navbars/AdminNavbar";
import { processNodes } from "react-html-parser";
import { projectSeenService } from "_services";
import { spSeenService } from "_services";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import PulseLoader from "react-spinners/PulseLoader";
import { Link } from "react-router-dom";
import { NavItem, NavLink, Nav, Navbar } from "reactstrap";

function TeamList({ history, location }) {
  const user = profileService.userValue;
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [contactUsMessage, setMessage] = useState("");
  const [currentLan] = useContext(LanguageContext);
  const [pple, setWPple] = useState([]);
  const [corporateAndDev, setCorporateAndDev] = useState([]);
  const [whoWeAre, setWhoWeAre] = useState([]);
  const [challenge, setChallenge] = useState(null);

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 48,
    height: 48,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1223px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  const [loading, setLoading] = useState(true);
  const options = {
    decodeEntities: true,
    transform,
  };

  useEffect(() => {
    if (user !== null && user.profileExpired)
      profileService.Logout(user.profile.userId);
  }, []);

  function transform(node, index) {
    if (node.type === "tag" && node.name === "b") {
      return <b key={index}>{processNodes(node.children, transform)}</b>;
    }
    if (
      node.type === "tag" &&
      node.name === "br" &&
      node.next !== null &&
      node.next.name === "br"
    ) {
      return null;
    }
    if (node.type === "tag" && node.name === "br") {
      return <br></br>;
    }
  }

  const fetchData = async () => {
    const loader = document.querySelector(".loader-container");

    setLoading(true);
    landingPageService.GetAllWhoWeAreAsync().then((y) => {
      //setWhoWeAre(y);
      var ppleList = [];
      var corporateAndDevList = [];
      y.map((item) =>
        item.typeId === WhoWeAreUserTypes.EarlySupporter ||
        item.typeId === WhoWeAreUserTypes.Initiator ||
        item.typeId === WhoWeAreUserTypes.TechAdvisor ||
        item.typeId === WhoWeAreUserTypes.ManagementAdvisor
          ? ppleList.push(item)
          : corporateAndDevList.push(item)
      );
      setWPple(ppleList);
      setCorporateAndDev(corporateAndDevList);
      // var list = ppleList.concat(corporateAndDevList);
      // setWhoWeAre(list);
      // console.log(y);
      // console.log(y);
    });

    await adminService.GetChallengeAsync().then((x) => {
      setChallenge(x);
    });

    loader.classList.add("loader--hide");
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    let scrollTo = window.location.hash.replace("#", "");
    Scroll.scroller.scrollTo(scrollTo, {
      offset: -50,
      duration: 1,
      smooth: true,
      delay: 0,
    });
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function ShowErrorMessage(message) {
    setErrorMessage(message);
    setErrorAlert(!errorAlert);
  }

  const settings = {
    slidesToShow: 10,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    draggable: true,
    infinite: true,
    swipeToSlide: true,
    swipe: true,
    arrows: false,
  };

  const [successAlert, setSuccessAlert] = useState(false);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  if (loading) {
    return <></>;
  }
  return (
    <>
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker &&
        isDesktopOrLaptop && <SeekerNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker &&
        !isDesktopOrLaptop && <SeekerNavbarMobileTablet props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
        isDesktopOrLaptop && <ProviderNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
        !isDesktopOrLaptop && <ProviderNavbarMobileTablet props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner &&
        isDesktopOrLaptop && <PartnerNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner &&
        !isDesktopOrLaptop && <PartnerNavbarMobileTablet props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Admin &&
        isDesktopOrLaptop && <AdminNavbar props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Admin &&
        !isDesktopOrLaptop && <AdminNavbarMobileTablet props={history} />}
      {user && user.profile.profileStatus !== ProfileStatus.Active && (
        <LoggedInUserNavbar props={history} />
      )}
      {!user && isDesktopOrLaptop && <LandingPageNavbar2 props={history} />}
      {!user && !isDesktopOrLaptop && (
        <LandingPageNavbarMobileTablet2 props={history} />
      )}
      <div
        style={
          isDesktopOrLaptop ? { paddingLeft: "70px", paddingRight: "70px" } : {}
        }
      >
        <Row
          className="d-flex justify-content-center"
          style={{ marginTop: "100px" }}
        >
          <p
            style={{
              color: "#393939",
              fontSize: "32px",
              fontFamily: "SpaceGroteskMedium",
              textAlign: "center",
            }}
          >
            {t("landingpage.ourteam")}
          </p>
          <br></br>
          <br></br>
        </Row>
        {/* <Row
          style={{
            paddingBottom: "90px",
            display: "flex",
            // flexDirection: "column",
          }}
        > */}
        <Row className="justify-content-center">
          {pple.length > 0 &&
            pple.map((item, index) => {
              return (
                <Col className="col-sm-12 col-md-4 col-lg-3 d-flex flex-column align-items-center">
                  <Badge
                    key={index}
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    // style={{ marginRight: "50px" }}
                    badgeContent={
                      <SmallAvatar
                        src={require("assets/img/LinkedInSVG.svg").default}
                        onClick={() => {
                          const win = window.open(item.linkedInUrl, "_blank");
                          win.focus();
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    }
                  >
                    <Avatar
                      className="avatar"
                      sx={{ width: 160, height: 160 }}
                      src={item.userImage.filePath}
                    />
                  </Badge>
                  <Row>
                    <p
                      style={{
                        color: "#406BAE",
                        fontSize: "16px",
                        fontFamily: "SpaceGroteskBold",
                        textAlign: "center",
                        marginBottom: "0px",
                      }}
                    >
                      {item.firstName} {item.lastName}
                    </p>
                  </Row>
                  <Row>
                    <p
                      style={{
                        color: "#4B4B4B",
                        fontSize: "12px",
                        fontFamily: "SpaceGrotesk",
                        textAlign: "center",
                        marginBottom: "50px",
                      }}
                    >
                      {item.typeId}
                    </p>
                  </Row>
                </Col>
              );
            })}
        </Row>
        <Row className="justify-content-center">
          {corporateAndDev.length > 0 &&
            corporateAndDev.map((item, index) => {
              return (
                <Col className="col-sm-12 col-md-4 col-lg-3 d-flex flex-column align-items-center">
                  <Badge
                    key={index}
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    // style={{ marginRight: "50px" }}
                    badgeContent={
                      <SmallAvatar
                        src={require("assets/img/LinkedInSVG.svg").default}
                        onClick={() => {
                          const win = window.open(item.linkedInUrl, "_blank");
                          win.focus();
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    }
                  >
                    <Avatar
                      className="avatar"
                      sx={{ width: 160, height: 160 }}
                      src={item.userImage.filePath}
                    />
                  </Badge>
                  <Row>
                    <p
                      style={{
                        color: "#406BAE",
                        fontSize: "16px",
                        fontFamily: "SpaceGroteskBold",
                        textAlign: "center",
                        marginBottom: "0px",
                      }}
                    >
                      {item.firstName} {item.lastName}
                    </p>
                  </Row>
                  <Row>
                    <p
                      style={{
                        color: "#4B4B4B",
                        fontSize: "12px",
                        fontFamily: "SpaceGrotesk",
                        textAlign: "center",
                        marginBottom: "50px",
                      }}
                    >
                      {item.typeId}
                    </p>
                  </Row>
                </Col>
              );
            })}
        </Row>
        {/* </Row> */}
        <Row
          className={
            isMobile
              ? "d-flex justify-content-center"
              : "d-flex justify-content-start"
          }
          style={{ backgroundColor: "#EEF5FB" }}
        >
          <Col className="col-md-4 col-sm-12 col-12">
            <Nav className="flex-row justify-content-start mt-3">
              {/* <NavItem>
                <a className="nav-link " href="#serviceProviders">
                  {t("featuredSP")}
                </a>
              </NavItem> */}
              <Col
                className={
                  isMobile
                    ? "col-md-12 col-sm-12 col-12 d-flex justify-content-center"
                    : "col-md-12 col-sm-12 col-12"
                }
              >
                {/* {user && (
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                      }
                      onClick={() => {
                        history.push("/plans");
                      }}
                      tag={Link}
                    >
                      {t("mainPage.plans")}
                    </NavLink>
                  </NavItem>
                )} */}
                <NavItem>
                  <NavLink
                    style={
                      rtl
                        ? {
                            color: "#4D4D4D",
                            fontFamily: "SpaceGrotesk",
                            textAlign: "justify",
                          }
                        : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                    }
                    onClick={() => {
                      history.push("/ourteam");
                    }}
                    tag={Link}
                  >
                    {t("landingpage.ourteam")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={
                      rtl
                        ? {
                            color: "#4D4D4D",
                            fontFamily: "SpaceGrotesk",
                            textAlign: "justify",
                          }
                        : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                    }
                    onClick={() => {
                      history.push("/contactUs");
                    }}
                    tag={Link}
                  >
                    {t("navbar.contactUs")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={
                      rtl
                        ? {
                            color: "#4D4D4D",
                            fontFamily: "SpaceGrotesk",
                            textAlign: "justify",
                          }
                        : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                    }
                    onClick={() => {
                      history.push("/videos");
                    }}
                    tag={Link}
                  >
                    {t("demo.videos")}
                  </NavLink>
                </NavItem>
                {challenge && challenge.isPublic && (
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                            }
                      }
                      onClick={() => {
                        history.push("/million-dollar-challenge");
                      }}
                      tag={Link}
                    >
                      {t("million.dollar")}
                    </NavLink>
                  </NavItem>
                )}
              </Col>
              {/* <Col
                      className={
                        isMobile
                          ? "col-md-6 col-sm-12 col-12 pb-3 d-flex justify-content-center"
                          : "col-md-6 col-sm-12 col-12"
                      }
                    >
                      
                    </Col> */}

              {/* <LanguageDropdown /> */}
            </Nav>
          </Col>
          <Col
            row
            className={
              isMobile
                ? "d-flex justify-content-center align-items-center col-md-4 col-sm-12 col-12 pt-4 pb-2"
                : "d-flex justify-content-end align-items-center col-md-4 col-sm-12 col-12"
            }
          >
            {" "}
            <Col
              className={
                isMobile
                  ? "d-flex justify-content-center align-items-center flex-column mt-0"
                  : "d-flex justify-content-center align-items-center flex-column mt-4"
              }
            >
              <Row>
                <p
                  style={{
                    color: "#4D4D4D",
                    fontSize: "14px",
                    fontFamily: "SpaceGroteskMedium",
                    textAlign: "left",
                    marginBottom: "0",
                    paddingBottom: "10px",
                  }}
                >
                  {t("mainPage.usaid")}
                </p>
              </Row>
              <Row>
                <img
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    width: "140px",
                  }}
                  src={require("assets/img/usaid_logo_vertical.png").default}
                  alt=""
                />
              </Row>
            </Col>
          </Col>
          {!isMobile ? (
            <Col
              className="col-md-4 col-sm-12 col-12 d-flex justify-content-end align-items-end"
              style={{ paddingBottom: "10px" }}
            >
              <Row
                className={"d-flex justify-content-end align-items-center py-1"}
                style={{ backgroundColor: "#EEF5FB" }}
              >
                <p
                  style={
                    // isMobile
                    //   ? {
                    //       display: "none",
                    //       color: "#fff",
                    //       fontSize: "16px",
                    //       fontFamily: "SpaceGrotesk",
                    //       marginRight: "15px",
                    //       marginBottom: "0",
                    //     }
                    //   : {
                    {
                      color: "#4D4D4D",
                      fontSize: "16px",
                      fontFamily: "SpaceGrotesk",
                      marginRight: "15px",
                      marginBottom: "0",
                    }
                  }
                >
                  {t("followUs")}
                </p>
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "contain",
                    marginRight: "15px",
                    cursor: "pointer",
                  }}
                  src={require("assets/img/linkedin.png").default}
                  onClick={() => {
                    const win = window.open(
                      `https://www.linkedin.com/company/outerpond/`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  alt=""
                />
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "contain",
                    marginRight: "15px",
                    cursor: "pointer",
                  }}
                  src={require("assets/img/twitter.png").default}
                  onClick={() => {
                    const win = window.open(
                      `https://twitter.com/outerpond`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  alt=""
                />
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "contain",
                    marginRight: "15px",
                    cursor: "pointer",
                  }}
                  src={require("assets/img/YoutubeSVG.svg").default}
                  onClick={() => {
                    const win = window.open(
                      `https://www.youtube.com/@outerpond3036`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  alt=""
                />
              </Row>
            </Col>
          ) : (
            <></>
          )}
        </Row>

        {isMobile && (
          <Row
            className={
              isMobile
                ? "d-flex justify-content-center align-items-center py-3"
                : "d-flex justify-content-end align-items-center py-3 px-2"
            }
            style={{ backgroundColor: "#EEF5FB" }}
          >
            <p
              style={
                // isMobile
                //   ? {
                //       display: "none",
                //       color: "#fff",
                //       fontSize: "16px",
                //       fontFamily: "SpaceGrotesk",
                //       marginRight: "15px",
                //       marginBottom: "0",
                //     }
                //   : {
                {
                  color: "#4D4D4D",
                  fontSize: "16px",
                  fontFamily: "SpaceGrotesk",
                  marginRight: "15px",
                  marginBottom: "0",
                }
              }
            >
              {t("followUs")}
            </p>
            <img
              style={{
                width: "32px",
                height: "32px",
                objectFit: "contain",
                marginRight: "15px",
                cursor: "pointer",
              }}
              src={require("assets/img/linkedin.png").default}
              onClick={() => {
                const win = window.open(
                  `https://www.linkedin.com/company/outerpond/`,
                  "_blank"
                );
                win.focus();
              }}
              alt=""
            />
            <img
              style={{
                width: "32px",
                height: "32px",
                objectFit: "contain",
                marginRight: "15px",
                cursor: "pointer",
              }}
              src={require("assets/img/twitter.png").default}
              onClick={() => {
                const win = window.open(
                  `https://twitter.com/outerpond`,
                  "_blank"
                );
                win.focus();
              }}
              alt=""
            />
            <img
              style={{
                width: "32px",
                height: "32px",
                objectFit: "contain",
                marginRight: "15px",
                cursor: "pointer",
              }}
              src={require("assets/img/YoutubeSVG.svg").default}
              onClick={() => {
                const win = window.open(
                  `https://www.youtube.com/@outerpond3036`,
                  "_blank"
                );
                win.focus();
              }}
              alt=""
            />
          </Row>
        )}

        <Row
          className={
            isMobile
              ? "d-flex flex-column justify-content-center align-items-center pt-3 px-2"
              : "d-flex justify-content-between align-items-center px-4"
          }
          style={{ backgroundColor: "#132438" }}
        >
          <p
            style={{
              color: "#fff",
              fontSize: "12px",
              fontFamily: "SpaceGrotesk",
              marginRight: "30px",
              marginBottom: "0",
            }}
          >
            {t("mainPage.footer")}
          </p>
          <Nav className="d-flex align-items-center">
            <NavItem>
              <NavLink
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  fontFamily: "SpaceGrotesk",
                }}
                to={"/footer-sub-pages/1"}
                tag={Link}
              >
                {t("footer.faq")}
              </NavLink>
            </NavItem>
            <span className="text-white">—</span>
            <NavItem>
              <NavLink
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  fontFamily: "SpaceGrotesk",
                }}
                to={"/footer-sub-pages/2"}
                tag={Link}
              >
                {t("footer.terms")}
              </NavLink>
            </NavItem>
            <span className="text-white">—</span>
            <NavItem>
              <NavLink
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  fontFamily: "SpaceGrotesk",
                }}
                to={"/footer-sub-pages/3"}
                tag={Link}
              >
                {t("footer.privacyPolicy")}
              </NavLink>
            </NavItem>
          </Nav>
        </Row>
      </div>
    </>
  );
}

export default TeamList;
