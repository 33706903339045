import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import SurveyList from "./SurveyList";
import SurveyPreview from "./SurveyPreview";
import AddSurvey from "./AddSurvey";
import EditSurvey from "./EditSurvey";
import Responses from "./Responses";
import ResponseDetails from "./ResponseDetails";
function Survey({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={SurveyList} exact />
        <PrivateRoute path={`${path}/preview/:id`} component={SurveyPreview} />
        <PrivateRoute
          path={`${path}/responses/:id`}
          component={Responses}
          exact
        />
        <PrivateRoute
          path={`${path}/responses/:id/:userId`}
          component={ResponseDetails}
          exact
        />
        <PrivateRoute path={`${path}/add`} component={AddSurvey} />
        <PrivateRoute path={`${path}/edit/:id`} component={EditSurvey} />
      </Switch>
    </div>
  );
}

export { Survey };
