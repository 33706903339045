import React, { useContext, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import Card from "reactstrap/lib/Card";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import { Link } from "react-router-dom";
import HowTo from "./HowTo";
import UploadExcel from "./UploadExcel";
import { InvitationHistory } from "./InvitationHistory";
import { partnerService } from "_services";
import { profileService } from "_services";

function InviteServiceSeekers({ history }) {
  const [loading, setLoading] = useState(true);
  const [iconPills, setIconPills] = React.useState("1");
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [data, setData] = useState(null);
  const user = profileService.userValue;

  async function getHistory() {
    await partnerService
      .GetPartnerEmailRecipients(user.profile.userId, 1)
      .then((x) => {
        setData(x.ssUploadDocumentEmailRecipients);
      });
  }

  const fetchData = async () => {
    setLoading(true);
    await partnerService
      .GetPartnerEmailRecipients(user.profile.userId, 1)
      .then((x) => {
        setData(x.ssUploadDocumentEmailRecipients);
      });
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      {/* <PartnerNavbar props={history} /> */}
      <div className="wrapper">
        <div className={rtl ? "section text-right" : "section"}>
          <Container>
            {/* <div className=" text-center">
              <h2 className="title">Account Settings</h2>
              <p>
                Manage your account settings (password, membership plan, payment
                method...)
              </p>
            </div> */}
            <Row>
              <Col className="ml-auto mr-auto">
                <Card style={{ boxShadow: "none" }}>
                  <CardHeader>
                    <Nav className="justify-content-center" role="tablist" tabs>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          className={iconPills === "1" ? "active" : ""}
                          //    href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("1");
                          }}
                        >
                          <i className="now-ui-icons business_bulb-63"></i>
                          {t("howTo.tab")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          className={iconPills === "2" ? "active" : ""}
                          //    href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("2");
                          }}
                        >
                          <i className="now-ui-icons arrows-1_share-66"></i>
                          {t("inviteSeekers.tab")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          className={iconPills === "3" ? "active" : ""}
                          //    href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("3");
                          }}
                        >
                          <i className="now-ui-icons files_single-copy-04"></i>
                          {t("partner.upload.seekers.history")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>
                  <CardBody>
                    <TabContent
                      // className="text-center"
                      activeTab={"iconPills" + iconPills}
                    >
                      <TabPane tabId="iconPills1">
                        <HowTo />
                      </TabPane>
                      <TabPane tabId="iconPills2">
                        <UploadExcel onUpload={getHistory} />
                      </TabPane>
                      <TabPane tabId="iconPills3">
                        <InvitationHistory props={data} fromHistory={true} />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* <DefaultFooter /> */}
    </>
  );
}

export default InviteServiceSeekers;
