import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import LanguageDropdown from "./LanguageDropdown";
import { LanguageContext, UnseenMessagesContext } from "../../_context";
import { useTranslation } from "react-i18next";
import { profileService } from "_services";
import Badge from "reactstrap/lib/Badge";

function SeekerNavbarMobileTablet({ props }) {
  const user = profileService.userValue;
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [currentUnseenMessage, setCurrentUnseenMessage] = useContext(
    UnseenMessagesContext
  );

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top shadow-none"} expand="lg" color="white">
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand
              id="navbar-brand"
              href="/"
              className="pl-5"
              style={{
                width: "193px",
                height: "48px",
              }}
            >
              <img
                alt=""
                src={require("assets/img/Outerpond-Logo.png").default}
              />
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Outerpond
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    // profileService.RefreshTokenAsync().then((user) => {
                    props.push("/seeker/search-provider");
                    setCurrentUnseenMessage(user.unseenMessages);
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                    // });
                  }}
                  tag={Link}
                >
                  {t("navbar.findProviders")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    // profileService.RefreshTokenAsync().then((user) => {
                    props.push("/seeker/projects");
                    setCurrentUnseenMessage(user.unseenMessages);
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                    // });
                  }}
                  tag={Link}
                >
                  {t("opportunities")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    // profileService.RefreshTokenAsync().then((user) => {
                    props.push("/seeker/directLinks");
                    setCurrentUnseenMessage(user.unseenMessages);
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                    // });
                  }}
                  tag={Link}
                >
                  {t("directLinks")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavItem>
                  <NavLink
                    style={{ fontFamily: "SpaceGrotesk" }}
                    onClick={() => {
                      // profileService.RefreshTokenAsync().then((user) => {
                      props.push("/seeker/seenSP");
                      setCurrentUnseenMessage(user.unseenMessages);
                      document.documentElement.classList.toggle("nav-open");
                      setCollapseOpen(false);
                      // });
                    }}
                    tag={Link}
                  >
                    {t("provider.recentlyViewed")}
                  </NavLink>
                </NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    // profileService.RefreshTokenAsync().then((user) => {
                    props.push(`/seeker/messaging`);
                    setCurrentUnseenMessage(user.unseenMessages);
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                    // });
                  }}
                  tag={Link}
                >
                  {t("navbar.messages")}
                  {currentUnseenMessage > 0 && (
                    <Badge
                      style={{
                        color: "#0E6EB8",
                        backgroundColor: "#FFFFFF",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {currentUnseenMessage}
                    </Badge>
                  )}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    // profileService.RefreshTokenAsync().then((user) => {
                    props.push("/seeker/profile-page");
                    setCurrentUnseenMessage(user.unseenMessages);
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                    // });
                  }}
                  tag={Link}
                >
                  {t("accountDropdown.viewProfile")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    // profileService.RefreshTokenAsync().then((user) => {
                    props.push("/seeker/accountSettings");
                    setCurrentUnseenMessage(user.unseenMessages);
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                    // });
                  }}
                  tag={Link}
                >
                  {t("accountDropdown.accountSettings")}
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    props.push("/plans");
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                  }}
                  tag={Link}
                >
                  {t("mainPage.plans")}
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGroteskBold" }}
                  onClick={() => profileService.Logout(user.profile.userId)}
                  tag={Link}
                >
                  {t("logout")}
                </NavLink>
              </NavItem>
              <LanguageDropdown />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default SeekerNavbarMobileTablet;
