import React from "react";
import { Router } from "react-router-dom";
// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Suspense } from "react";
import { App } from "app/Index";
import { render } from "react-dom";
import "./styles.less";
import { history } from "../src/_helpers";
import {
  LanguageContextProvider,
  UnseenMessagesContextProvider,
} from "../src/_context";
import { profileService } from "./_services/profile.service";

profileService.RefreshTokenAsync().finally(startApp);

//startApp();
function startApp() {
  render(
    <Router history={history}>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={null}>
          <LanguageContextProvider>
            <UnseenMessagesContextProvider>
              <App />
            </UnseenMessagesContextProvider>
          </LanguageContextProvider>
        </Suspense>
      </I18nextProvider>
    </Router>,
    document.getElementById("root")
  );
}
