import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Multiselect } from "multiselect-react-dropdown";
import { YearPicker } from "react-dropdown-date";
import "../../assets/css/generalStyles.css";
import "../../assets/css/tooltip-style.css";
import "../../assets/css/overrideReactCrop.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ProfileStatus } from "../../_helpers";
import { useMediaQuery } from "react-responsive";
import {
  profileService,
  numberOfEmpService,
  countryService,
  industryService,
  documentService,
  systemSetupService,
} from "_services";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  FormGroup,
  CardTitle,
  Modal,
  Container,
} from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import { LanguageContext } from "_context";
import { AccountTypes } from "_helpers";
import { FileSettings } from "_helpers";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { StartupStages } from "_helpers";
import ReactQuill from "react-quill";
import queryString from "query-string";
function EditProfile({ history, match, location }) {
  const user = profileService.userValue;
  const id = match.params.userId;
  const parsed = queryString.parse(location.search);
  const fromAdmin = parsed.fromAdmin == "true" ? true : false;
  console.log("from admin", fromAdmin);
  console.log("match.params", match.params);
  const [empNB, setEmpNB] = useState(null);
  const [countries, setCountries] = useState(null);
  const [industries, setIndustries] = useState(null);
  const [documentTypeList, setDocumentTypeList] = useState(null);
  const [year, setYear] = useState(null);
  const [alert, setAlert] = useState(false);
  const [info, setInfo] = useState(null);
  const [infoDocument, setInfoDocument] = useState(null);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [selectedList, setSelectedList] = useState([]);
  const [logoPath, setLogoPath] = useState();
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  // const [companyNDA, setCompanyNDA] = useState("");
  const [VerificationErrorMessage, setVerificationErrorMessage] =
    useState(null);
  const [errorVerificationAlert, setErrorVerificationAlert] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [inputList, setInputList] = useState([]);
  const [docInputList, setDocInputList] = useState([]);
  const [documentCount, setDocumentCount] = useState(0);
  const [partners, setPartners] = useState([]);
  const [preview, setPreview] = useState(null);
  const imgRef = useRef(null);
  const completedCropRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, height: 30 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [logoAfterCrop, setLogoAfterCrop] = useState(null);
  const [spNeeds, setSpNeeds] = useState(null);
  const [imageAlert, setImageAlert] = useState(false);

  const [companyDesc, setCompanyDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageValidation, setImageValidation] = useState(false);

  const [userDraft, setUserDraft] = useState(
    location !== null &&
      location !== undefined &&
      location.state !== null &&
      location.state !== undefined &&
      location.state.userDraft === true
      ? true
      : false
  );
  const [userSaved, setUserSaved] = useState(false);

  const [savingDraft, setSavingDraft] = useState(false);

  const [industriesOnly, setIndustriesOnly] = useState([]);
  const [servicesOnly, setServicesOnly] = useState([]);
  const [selectedIndustryList, setSelectedIndustryList] = useState([]);
  const servicesMultiselectRef = React.createRef();
  const [startupStage, setStartupStage] = useState(null);

  const [maxFileUpload, setMaxFileUpload] = useState(10);
  const [maxFileSize, setMaxFileSize] = useState(1);

  const [draftAlert, setDraftAlert] = useState(false);

  const [infoDocLength, setInfoDocLength] = useState(0);
  const [is10, setIs10] = useState(false);
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(
      t("createCompanyProfile.companyName.error")
    ),
    crNumber:
      user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
      user.profile.spType === "Start Up" &&
      startupStage === "Seed stage"
        ? Yup.string()
            .required(t("createCompanyProfile.StartupStage.error"))
            .test(
              "len",
              t("crNumber.length"),
              (val) => !val || val.length <= 21
            )
        : Yup.string()
            .required(t("createCompanyProfile.crNumber.error"))
            .test(
              "len",
              t("crNumber.length"),
              (val) => !val || val.length <= 21
            ),
    managerName: Yup.string().required(
      t("createCompanyProfile.managerName.error")
    ),
    contactFirstName: Yup.string().required(
      t("createCompanyProfile.contactFname.error")
    ),
    contactLastName: Yup.string().required(
      t("createCompanyProfile.contactLname.error")
    ),
    contactJobTitle: Yup.string().required(
      t("createCompanyProfile.contactJob.error")
    ),
    contactEmail: Yup.string().required(
      t("createCompanyProfile.contactEmail.error")
    ),
    countryId: Yup.string().required(t("createCompanyProfile.country.error")),
    cityId: Yup.string().required(t("createCompanyProfile.city.error")),
    numberOfEmployees: Yup.string().required(
      t("createCompanyProfile.numberOfEmployees.error")
    ),
  });

  async function validateImage() {
    //Get reference of File.
    var fileUpload = document.getElementById("companyLogo");

    //Check whether the file is valid Image.
    var regex = new RegExp(
      "([a-zA-Z0-9s_\\.-:() ])+(.jpg|.png|.gif|.JPG|.PNG|.JPEG|.jpeg)$"
    );

    //const size = fileUpload.files[0].size;
    //const finalSize = Math.round(size / 1024);

    if (regex.test(fileUpload.value.toLowerCase())) {
      //Check whether HTML5 is supported.
      if (typeof fileUpload.files != "undefined") {
        //Initiate the FileReader object.
        var reader = new FileReader();
        //Read the contents of Image File.
        reader.readAsDataURL(fileUpload.files[0]);
        reader.onload = function (e) {
          //Initiate the JavaScript Image object.
          var image = new Image();

          //Set the Base64 string return from FileReader as source.
          image.src = e.target.result;

          //Validate the File Height and Width.
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            // if (height > 500 || width > 500) {
            //   setError(t("logo.validateSize"));
            //   setErrorAlert(!errorAlert);
            //   setImageValidation(false);
            //   return false;
            // }
            // if (finalSize > 1024) {
            //   setError(t("fileSize"));
            //   setErrorAlert(!errorAlert);
            //   setImageValidation(false);
            //   return false;
            // }
            setImageValidation(true);
            return true;
          };
        };
      } else {
        setError(t("logo.browserSupport"));
        setErrorAlert(!errorAlert);
        return false;
      }
    } else {
      setError(t("logo.validate"));
      setErrorAlert(!errorAlert);
      return false;
    }
  }

  async function validateDocument() {
    //Get reference of File.
    var files = document.getElementsByName("fileDocs");
    //Check whether the file is valid Image.
    var regex = new RegExp(
      "([a-zA-Z0-9s_\\.-:() ])+(.jpg|.png|.gif|.JPG|.PNG|.JPEG|.jpeg|.pdf|.PDF)$"
    );
    var i;
    for (i = 0; i < files.length; i++) {
      const size = files[i].files[0].size;
      const finalSize = Math.round(size / 1024);

      if (regex.test(files[i].value.toLowerCase())) {
        //Check whether HTML5 is supported.
        if (typeof files[i].files != "undefined") {
          //Initiate the FileReader object.
          // return true;
        } else {
          setError(t("logo.browserSupport"));
          setErrorAlert(!errorAlert);
          return false;
        }

        if (finalSize > maxFileSize * 1024) {
          setError(`${t("fileSize")} ${maxFileSize} MB.`);
          setErrorAlert(!errorAlert);
          return false;
        }
      } else {
        setError(t("supportingDoc.validate"));
        setErrorAlert(!errorAlert);
        return false;
      }
    }

    return true;
  }
  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ direction: "" }, { direction: "rtl" }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["link"],
      ["clean"],
    ],
  };
  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "background",
    "link",
  ];
  const fetchData = async (lan) => {
    setLoading(true);
    await numberOfEmpService.GetAllNumberOfEmployeesAsync().then((x) => {
      setEmpNB(x);
    });
    await profileService.GetAllSelectedProfiles().then((x) => {
      setPartners(x);
    });
    await systemSetupService.GetFileValidationSettingsAsync().then((x) => {
      x.map((settings) => {
        if (settings.description === FileSettings.MaxNbUploads) {
          setMaxFileUpload(settings.value);
        }
        if (settings.description === FileSettings.MaxFileSize) {
          setMaxFileSize(settings.value);
        }
      });
    });

    await countryService.GetCountryByLanguageAsync(lan).then((x) => {
      setCountries(x);
    });
    var servicesList = [];
    await industryService
      .GetIndustriesServicesByLanguageAsync(lan)
      .then((x) => {
        setIndustries(x);
        if (x !== null) {
          for (var i = 0; i < x.length; i++) {
            if (
              !industriesOnly.some(
                (industry) =>
                  industry.typeOfIndustryId === x[i].typeOfIndustryId
              )
            ) {
              industriesOnly.push({
                typeOfIndustryId: x[i].typeOfIndustryId,
                typeOfIndustryName: x[i].typeOfIndustryName,
              });
            }

            if (user.profile.typeOfIndustryId.includes(x[i].typeOfIndustryId)) {
              servicesList.push({
                typeOfIndustryId: x[i].typeOfIndustryId,
                typeOfIndustryName: x[i].typeOfIndustryName,
                typeOfServiceId: x[i].typeOfServiceId,
                typeOfServiceName: x[i].typeOfServiceName,
              });
            }
          }
          setServicesOnly(servicesList);
        }
      });

    await documentService.GetDocumentTypeByLanguageAsync(lan).then((x) => {
      setDocumentTypeList(x);
    });
    var profileId = fromAdmin == true ? id : user.profile.userId;
    console.log("profileId", profileId);
    await profileService
      .GetProfileById(profileId, true, null, null, currentLan)
      .then((profile) => {
        setInfo(profile);
        setInfoDocument(profile.supportingDocuments);
        setSpNeeds(profile.spNeeds);
        let sum = 0;
        for (var i = 0; i < profile.supportingDocuments.length; i++) {
          sum = sum + profile.supportingDocuments[i].files.length;
        }
        if (sum === maxFileUpload) {
          setIs10(true);
        }
        setInfoDocLength(sum);
        setYear(profile.profile.activeSince);
        setSelectedList(profile.typeOfIndustry);
        setPreview(profile.logo.filePath);
        //    setLogoAfterCrop(profile.logo.filePath);
        setStartupStage(profile.profile.startupStage);
        // if (profile.profile.companyNDA != null) setCompanyNDA(profile.profile.companyNDA)
        const list = [...inputList];
        if (profile.profile.phoneNumber != null) {
          profile.profile.phoneNumber.forEach(async (phone) => {
            var model = { phone: phone };
            await list.push(model);
          });
        }
        if (list !== null && list.length > 0) {
          setInputList(list);
        } else {
          setInputList([{ phone: "" }]);
        }

        for (var i = 0; i < profile.typeOfIndustry.length; i++) {
          if (
            !selectedIndustryList.some(
              (industry) =>
                industry.typeOfIndustryId ===
                profile.typeOfIndustry[i].typeOfIndustryId
            )
          ) {
            selectedIndustryList.push({
              typeOfIndustryId: profile.typeOfIndustry[i].typeOfIndustryId,
              typeOfIndustryName: profile.typeOfIndustry[i].typeOfIndustryName,
            });
          }
        }
      });

    setLoading(false);
  };

  useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    fetchData(currentLan);
    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  }, [currentLan]);

  useEffect(() => {
    const link = document.querySelector(".ql-link");
    const tooltip = document.querySelector(".tooltip-link");
    const btn = document.querySelector(".ql-action");
    const btnRmv = document.querySelector(".ql-remove");
    const input = document.querySelector(".ql-tooltip input");

    if (link != null) {
      link.addEventListener("mouseover", () => {
        tooltip.style.display = "block";
      });

      link.addEventListener("mouseout", () => {
        tooltip.style.display = "none";
      });
      link.addEventListener("click", () => {
        input.value = "http://";
      });
    }
    // if (btn != null) {
    //   btn.addEventListener("click", () => {
    //     console.log(input.value);
    //     if (!input.value.startsWith("http")) {
    //       setError(t("link.error"));
    //       setErrorAlert(!errorAlert);
    //     }
    //   });
    // }
  });

  // useEffect(() => {
  //   fetchData();
  // }, [currentLan]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    if (list.length === 0) {
      setInputList([{ phone: "" }]);
    }
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { phone: "" }]);
  };

  const handleDocInputChange = (e, index) => {
    //    const { name, value } = e.target;
    const list = [...docInputList];
    list[index]["document"] = e;
    setDocInputList(list);
  };

  const handleDocTypeInputChange = (e, index) => {
    //    const { name, value } = e.target;
    const list = [...docInputList];
    list[index]["documentType"] = e;
    setDocInputList(list);
  };

  // handle click event of the Remove button
  const handleDocRemoveClick = (index) => {
    const list = [...docInputList];
    const newCount = documentCount - 1;
    // list.splice(index, 1);
    list[index].display = false;
    list[index].documentType = null;
    list[index].document = null;

    if (index === 0) {
      document.getElementById(`documentFile${index}`).value = "";
    }
    setDocInputList(list);
    if (newCount === 0) {
      setDocumentCount(1);
      setDocInputList([{ documentType: null, document: null, display: false }]);
    } else {
      setDocumentCount(newCount);
    }
  };

  // handle click event of the Add button
  const handleDocAddClick = () => {
    setDocInputList([
      ...docInputList,
      { documentType: "", document: "", display: true },
    ]);

    setDocumentCount(documentCount + 1);
  };

  function onInputClick(e) {
    e.target.value = null;
    setLogoAfterCrop(null);
  }

  function onLogoChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setPreview(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setLogoPath(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
      setCrop({ unit: "%", width: 30, height: 30 });
      setImageAlert(!imageAlert);
    } else {
      setLogoAfterCrop(null);
      setLogoPath(null);
      setPreview(null);
    }
  }

  function base64StringtoFile(base64String, filename) {
    var arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  function handleCompleteCrop() {
    const canvasRef = previewCanvasRef.current;
    const imageData64 = canvasRef.toDataURL(logoPath.type);
    const myFilename = logoPath.name;

    // file to be uploaded
    const myNewCroppedFile = base64StringtoFile(imageData64, myFilename);
    return myNewCroppedFile;
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  function onCrop(c) {
    if (
      completedCropRef.current == null ||
      !previewCanvasRef.current ||
      !imgRef.current
    ) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = c;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    //ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }

  async function FillAndSendData(fields, setSubmitting, isDraft) {
    const formData = new FormData();

    formData.append("isDraft", isDraft);
    if (logoPath !== null) {
      formData.append("logo", logoAfterCrop);
    }

    if (fields.companyName !== null) {
      formData.append("companyName", fields.companyName);
    }

    if (fields.crNumber !== null) {
      formData.append("crNumber", fields.crNumber);
    }

    if (year !== null) {
      formData.append("activeSince", year);
    }

    if (companyDesc !== null) {
      formData.append("overview", companyDesc);
    }

    if (fields.managerName !== null) {
      formData.append("managerName", fields.managerName);
    }

    if (fields.contactFirstName !== null) {
      formData.append("contactFirstName", fields.contactFirstName);
    }
    if (fields.contactLastName !== null) {
      formData.append("contactLastName", fields.contactLastName);
    }
    if (fields.contactEmail !== null) {
      formData.append("contactEmail", fields.contactEmail);
    }
    if (fields.contactJobTitle !== null) {
      formData.append("contactJobTitle", fields.contactJobTitle);
    }
    if (fields.zipOrPostalCode !== null) {
      formData.append("zipOrPostalCode", fields.zipOrPostalCode);
    }
    if (fields.financing !== null && fields.financing !== "") {
      formData.append("financing", fields.financing);
    }
    if (fields.startupStage !== null && fields.startupStage !== "") {
      formData.append("startupStage", fields.startupStage);
    }
    if (fields.affiliatedTo !== null && fields.affiliatedTo !== "") {
      formData.append("affiliatedTo", fields.affiliatedTo);
    }

    if (spNeeds !== null && spNeeds !== "") {
      // const links = document.querySelectorAll(".ql-editor a");
      // links.forEach((link) => {
      //   if (link.href.includes(path.slice(0, -4))) {
      //     console.log(`${config.apiUrl}/${path.slice(0, -4)}`);
      //     link.href.replace(`${config.apiUrl}${path.slice(0, -4)}`, "http://");
      //   }
      // });
      formData.append("spNeeds", spNeeds);
    }
    inputList.forEach((item) => {
      if (item.phone !== "" && item.phone !== null) {
        formData.append("phoneNumber", item.phone);
      }
    });

    if (fields.incubator != null) {
      formData.append("incubator", fields.incubator);
    }

    if (fields.openForPartnership != null) {
      formData.append("openForPartnership", fields.openForPartnership);
    }
    if (fields.wantsToConnect != null) {
      formData.append("wantsToConnect", fields.wantsToConnect);
    }

    if (fields.countryId !== null) {
      formData.append("countryId", fields.countryId);
    }
    if (fields.cityId !== null) {
      formData.append("cityId", fields.cityId);
    }
    if (fields.region !== null) {
      formData.append("region", fields.region);
    }

    if (fields.address !== null) {
      formData.append("address", fields.address);
    }

    if (fields.website !== null) {
      formData.append("website", fields.website);
    }

    if (fields.linkedin !== null) {
      formData.append("linkedIn", fields.linkedin);
    }

    formData.append("logo", logoAfterCrop);
    if (fields.numberOfEmployees !== null) {
      formData.append("numberOfEmployees", fields.numberOfEmployees);
    }
    selectedList.forEach((item) => {
      formData.append("TypeOfIndustryId", item.typeOfIndustryId);
      formData.append("TypeOfServiceId", item.typeOfServiceId);
    });

    if (fields.companyInfoShowTo !== null) {
      formData.append("companyInfoShowTo", fields.companyInfoShowTo);
    }

    // if (fields.companyInfoShowTo == "NDA" && companyNDA != null && companyNDA != "") {
    //   formData.append("companyNDA", companyNDA);
    // }

    if (fields.contactInfoShowTo !== null) {
      formData.append("contactInfoShowTo", fields.contactInfoShowTo);
    }

    if (infoDocument != null) {
      if (infoDocument.length != 0) {
        var firstIndex = 0;
        infoDocument.forEach((item) => {
          formData.append(
            `supportingDocumentModels[${firstIndex}].documentType.documentTypeId`,
            item.documentType.documentTypeId
          );
          formData.append(
            `supportingDocumentModels[${firstIndex}].documentType.documentTypeName`,
            item.documentType.documentTypeName
          );
          var secondIndex = 0;
          infoDocument[firstIndex].files.forEach((file) => {
            formData.append(
              `supportingDocumentModels[${firstIndex}].files[${secondIndex}].filePath`,
              file.filePath
            );
            formData.append(
              `supportingDocumentModels[${firstIndex}].files[${secondIndex}].fileName`,
              file.fileName
            );
            secondIndex++;
          });
          firstIndex++;
        });
      }
    }

    // if (
    //   docInputList[0].documentType === null &&
    //   docInputList[0].document === null
    // ) {
    // } else {
    //   docInputList.forEach((item) => {
    //     if (item.display === true && item.documentType !== null &&
    //       item.document !== null
    //       && item.documentType !== ""
    //       && item.document !== "") {
    //       formData.append("DocumentType", item.documentType);
    //       formData.append("Document", item.document);
    //     }

    //   });
    // }
    docInputList.forEach((item) => {
      if (
        item.display === true &&
        item.documentType !== null &&
        item.document !== null &&
        item.documentType !== "" &&
        item.document !== ""
      ) {
        formData.append("DocumentType", item.documentType);
        formData.append("Document", item.document);
      }
    });
    formData.append("addMembership", false);
    formData.append("IsAdmin", fromAdmin);
    var profileId = fromAdmin == true ? id : user.profile.userId;
    await profileService
      .SignUpStepTwoAsync(profileId, formData)
      .then(async () => {
        await profileService.RefreshTokenAsync();
        setSubmitting(false);
        if (!isDraft) {
          setAlert(!alert);
          setUserSaved(true);
        } else {
          await ResetFields();
        }
      })
      .catch((error) => {
        setSubmitting(false);
        if (
          error !== null &&
          error.response !== null &&
          error.response.data !== null &&
          error.response.data.message !== undefined
        ) {
          setError(error.response.data.message);
          if (
            error.response.data.error !== null &&
            error.response.data.error.length > 0
          ) {
            for (var i = 0; i < error.response.data.error.length; i++) {
              if (error.response.data.error[i].languageName == currentLan) {
                setError(error.response.data.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }
  async function onSubmit(fields, { setStatus, setSubmitting }) {
    // setStatus();
    // setSubmitting(true);
    if (
      docInputList.length > 0 &&
      docInputList[0].documentType !== null &&
      docInputList[0].documentType !== "" &&
      docInputList[0].document !== null &&
      docInputList[0].document !== "" &&
      docInputList[0].display === true
    ) {
      const isDocValid = await validateDocument();
      if (isDocValid === false) {
        return null;
      }
    }
    if (logoPath != null) {
      const isValid = validateImage();
      if (imageValidation === false) {
        return null;
      }
    }

    if (selectedList.length === 0) {
      setError(t("required.industries"));
      setErrorAlert(!errorAlert);
      return null;
    }

    const isServicesValid = checkIfAllIndustriesAreSelected(
      selectedIndustryList,
      selectedList
    );
    if (isServicesValid === false) {
      setError(t("required.industries.services"));
      setErrorAlert(!errorAlert);
      return null;
    }

    await FillAndSendData(fields, setSubmitting, false);
  }

  async function ResetFields() {
    var profileId = fromAdmin == true ? id : user.profile.userId;
    console.log("profileId", profileId);
    await profileService
      .GetProfileById(profileId, true, null, null, currentLan)
      .then((profile) => {
        setDocInputList([]);
        setInfo(profile);
        setInfoDocument(profile.supportingDocuments);
        let sum = 0;
        for (var i = 0; i < profile.supportingDocuments.length; i++) {
          sum = sum + profile.supportingDocuments[i].files.length;
        }
        if (sum === maxFileUpload) {
          setIs10(true);
        }
        setInfoDocLength(sum);
        setYear(profile.profile.activeSince);
        setSelectedList(profile.typeOfIndustry);
        setPreview(profile.logo.filePath);
        //    setLogoAfterCrop(profile.logo.filePath);
        setStartupStage(profile.profile.startupStage);
        // if (profile.profile.companyNDA != null) setCompanyNDA(profile.profile.companyNDA)
        const list = [...inputList];
        profile.profile.phoneNumber.forEach(async (phone) => {
          var model = { phone: phone };
          await list.push(model);
        });
        if (list !== null && list.length > 0) {
          setInputList(list);
        } else {
          setInputList([{ phone: "" }]);
        }

        for (var i = 0; i < profile.typeOfIndustry.length; i++) {
          if (
            !selectedIndustryList.some(
              (industry) =>
                industry.typeOfIndustryId ===
                profile.typeOfIndustry[i].typeOfIndustryId
            )
          ) {
            selectedIndustryList.push({
              typeOfIndustryId: profile.typeOfIndustry[i].typeOfIndustryId,
              typeOfIndustryName: profile.typeOfIndustry[i].typeOfIndustryName,
            });
          }
        }

        setDraftAlert(!draftAlert);
      });
  }
  function SaveDraft(fields) {
    if (logoPath !== null && logoPath !== undefined) {
      const isValid = validateImage();
      if (imageValidation === false) {
        setSavingDraft(false);
        return null;
      }
    }

    if (
      docInputList.length > 0 &&
      docInputList[0].documentType !== null &&
      docInputList[0].documentType !== "" &&
      docInputList[0].document !== null &&
      docInputList[0].document !== "" &&
      docInputList[0].display === true
    ) {
      const isDocValid = validateDocument();

      if (isDocValid === false) {
        setSavingDraft(false);
        return null;
      }
    }

    if (selectedList !== null) {
      const isServicesValid = checkIfAllIndustriesAreSelected(
        selectedIndustryList,
        selectedList
      );
      if (isServicesValid === false) {
        setError(t("required.industries.services"));
        setErrorAlert(!errorAlert);
        setSavingDraft(false);
        return null;
      }
    }
    FillAndSendData(fields, setSavingDraft, true);
  }

  function onChange(e) {
    setSelectedList(e);
  }

  function onIndustrySelectChangeRemove(e) {
    setSelectedIndustryList(e);
    //servicesMultiselectRef.current.resetSelectedValues();
    const list = [];
    if (e !== null) {
      e.map(async (industry) => {
        await list.push(industry.typeOfIndustryId);
      });
    }
    const newList = [];
    if (selectedList !== null) {
      selectedList.map((element) => {
        if (list.includes(element.typeOfIndustryId) === true) {
          newList.push(element);
        }
      });
      setSelectedList(newList);
    }

    const servicesList = [];
    industries &&
      industries.map((element) => {
        if (list.includes(element.typeOfIndustryId)) {
          servicesList.push(element);
        }
      });
    setServicesOnly(servicesList);
  }

  function onIndustrySelectChangeAdd(e) {
    setSelectedIndustryList(e);
    //servicesMultiselectRef.current.resetSelectedValues();
    const list = [];
    if (e !== null) {
      e.map(async (industry) => {
        await list.push(industry.typeOfIndustryId);
      });
    }
    const servicesList = [];
    industries &&
      industries.map((element) => {
        if (list.includes(element.typeOfIndustryId)) {
          servicesList.push(element);
        }
      });
    setServicesOnly(servicesList);
  }

  function checkIfAllIndustriesAreSelected(industryList, servicesList) {
    let foundIndustry = false;
    if (industryList !== null) {
      for (var i = 0; i < industryList.length; i++) {
        foundIndustry = false;
        for (var j = 0; j < servicesList.length; j++) {
          if (
            servicesList[j].typeOfIndustryId ===
            industryList[i].typeOfIndustryId
          ) {
            foundIndustry = true;
          }
        }

        if (foundIndustry === false) {
          return false;
        }
      }
      return true;
    }
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      {info && (
        <>
          <div className="section section-signup">
            <Container style={{ marginTop: "-5%" }}>
              <Modal
                isOpen={draftAlert}
                style={rtl ? { textAlign: "right" } : {}}
              >
                <div className="modal-header">
                  <h5 className="modal-title">{t("draftSuccessAlert")}</h5>
                </div>
                <div className="modal-body" style={{ margin: "0 auto" }}>
                  <p>{t("draftSuccessAlertBody")}</p>
                </div>
                <div className="modal-footer">
                  <Button
                    color="primary"
                    type="button"
                    style={
                      rtl
                        ? { margin: "auto", textAlign: "right" }
                        : { margin: "auto" }
                    }
                    onClick={(e) => {
                      setDraftAlert(!draftAlert);
                    }}
                  >
                    {t("close.button")}
                  </Button>
                </div>
              </Modal>
              <Modal
                isOpen={imageAlert}
                style={rtl ? { textAlign: "right" } : {}}
              >
                <div
                  className="modal-header text-center"
                  style={{ margin: "0 auto" }}
                >
                  <h5 className="modal-title">Edit Image</h5>
                </div>
                <div className="modal-body">
                  <p>{t("croppingTool")}</p>
                  <Row>
                    {preview !== null ? (
                      <ReactCrop
                        style={{ margin: "auto" }}
                        src={preview}
                        onImageLoaded={onLoad}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => {
                          setCompletedCrop(c);
                          completedCropRef.current = c;
                          onCrop(c);
                        }}
                      />
                    ) : null}
                  </Row>
                  <p>{t("previewTool")}</p>
                  <Row>
                    <canvas
                      ref={previewCanvasRef}
                      // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                      style={{
                        margin: "auto",
                        width: Math.round(completedCrop?.width ?? 0),
                        height: Math.round(completedCrop?.height ?? 0),
                      }}
                    />
                  </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    color="primary"
                    type="button"
                    style={
                      rtl
                        ? { margin: "auto", textAlign: "right" }
                        : { margin: "auto" }
                    }
                    onClick={(e) => {
                      setPreview(null);
                      setLogoAfterCrop(handleCompleteCrop());
                      setImageAlert(!imageAlert);
                    }}
                  >
                    {t("done")}
                  </Button>
                </div>
              </Modal>
              <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
                <div className="modal-header" style={{ margin: "0 auto" }}>
                  <h5 className="modal-title">
                    {t("editCompanyProfile.success.title")}
                  </h5>
                </div>
                <div className="modal-body">
                  <p>{t("editprofile.success")}</p>
                </div>
                <div className="modal-footer">
                  <Button
                    color="primary"
                    type="button"
                    style={
                      rtl
                        ? { margin: "auto", textAlign: "right" }
                        : { margin: "auto" }
                    }
                    onClick={(e) => {
                      if (!userSaved) {
                        setAlert(!alert);
                      } else {
                        setAlert(!alert);
                        if (userDraft) {
                          if (
                            user != null &&
                            user.profileTypeView != null &&
                            user.profileTypeView.profileTypeDescription ===
                              AccountTypes.Seeker
                          ) {
                            sessionStorage.setItem(
                              "searchProviderMessage",
                              "true"
                            );
                            history.push(`/seeker/search-provider`);
                          } else if (
                            user != null &&
                            user.profileTypeView != null &&
                            user.profileTypeView.profileTypeDescription ===
                              AccountTypes.Provider
                          ) {
                            history.push("/provider/search-project");
                          } else {
                            history.push({
                              pathname: "/partner/search-provider",
                              state: { fromPartner: true },
                            });
                          }
                        } else {
                          fromAdmin == true
                            ? history.push("..")
                            : history.push(".");
                        }
                      }
                    }}
                  >
                    {t("close.button")}
                  </Button>
                </div>
              </Modal>
              <Modal
                isOpen={errorAlert}
                style={rtl ? { textAlign: "right" } : {}}
              >
                <div
                  className="modal-header text-center"
                  style={{ margin: "0 auto" }}
                >
                  <h5 className="modal-title">{t("error.alert")}</h5>
                </div>
                <div className="modal-body col-md-12">
                  <p>{errorMessage}</p>
                </div>

                <div className="modal-footer">
                  <Button
                    color="danger"
                    type="button"
                    style={
                      rtl
                        ? { margin: "auto", textAlign: "right" }
                        : { margin: "auto" }
                    }
                    onClick={(e) => setErrorAlert(!errorAlert)}
                  >
                    {t("close.button")}
                  </Button>
                </div>
              </Modal>
              <Modal
                isOpen={errorVerificationAlert}
                style={rtl ? { textAlign: "right" } : {}}
              >
                <div className="modal-header">
                  <h5 className="modal-title">{t("error.alert")}</h5>
                </div>
                <div className="modal-body">
                  <p>{VerificationErrorMessage}</p>
                </div>
                <div className="modal-footer">
                  <Button
                    color="danger"
                    type="button"
                    style={
                      rtl
                        ? { margin: "auto", textAlign: "right" }
                        : { margin: "auto" }
                    }
                    onClick={(e) => {
                      setErrorVerificationAlert(!errorVerificationAlert);
                      history.push("/");
                    }}
                  >
                    {t("close.button")}
                  </Button>
                </div>
              </Modal>
              <>
                <Row>
                  <Col>
                    <Formik
                      initialValues={{
                        companyName:
                          info.profile.companyName !== null
                            ? info.profile.companyName
                            : "",
                        crNumber:
                          info.profile.crNumber !== null
                            ? info.profile.crNumber
                            : "",
                        managerName:
                          info.profile.managerFullName !== null
                            ? info.profile.managerFullName
                            : "",
                        contactFirstName:
                          info.profile.contactFirstName !== null
                            ? info.profile.contactFirstName
                            : "",
                        contactLastName:
                          info.profile.contactLastName !== null
                            ? info.profile.contactLastName
                            : "",
                        contactJobTitle:
                          info.profile.contactJobTitle !== null
                            ? info.profile.contactJobTitle
                            : "",
                        contactEmail:
                          info.profile.contactEmail !== null
                            ? info.profile.contactEmail
                            : "",
                        countryId:
                          info.countryView.countryId !== null
                            ? info.countryView.countryId
                            : "",
                        cityId:
                          info.profile.cityID !== null
                            ? info.profile.cityID
                            : "",
                        financing:
                          info.profile.financing !== null
                            ? info.profile.financing
                            : "",
                        startupStage:
                          info.profile.startupStage !== null
                            ? info.profile.startupStage
                            : "",
                        region:
                          info.profile.region !== null
                            ? info.profile.region
                            : "",
                        openForPartnership:
                          info.profile.openForPartnership !== null
                            ? info.profile.openForPartnership
                            : "",
                        wantsToConnect:
                          info.profile.wantsToConnect !== null
                            ? info.profile.wantsToConnect
                            : "",
                        incubator:
                          info.profile.incubator !== null
                            ? info.profile.incubator
                            : "",
                        numberOfEmployees:
                          info.profile.numberOfEmployeesId !== null
                            ? info.profile.numberOfEmployeesId
                            : "",
                        address:
                          info.profile.address !== null
                            ? info.profile.address
                            : "",
                        logo: "",
                        website:
                          info.profile.website !== null
                            ? info.profile.website
                            : "",
                        linkedin:
                          info.profile.linkedIn !== null
                            ? info.profile.linkedIn
                            : "",
                        companyInfoShowTo:
                          info.profile.companyInfoShowTo !== null
                            ? info.profile.companyInfoShowTo
                            : "",
                        contactInfoShowTo:
                          info.profile.contactInfoShowTo !== null
                            ? info.profile.contactInfoShowTo
                            : "",
                        overview:
                          info.profile.overview !== null
                            ? info.profile.overview
                            : "",
                        zipOrPostalCode:
                          info.profile.zipOrPostalCode !== null
                            ? info.profile.zipOrPostalCode
                            : "",
                        affiliatedTo:
                          info.profile.affiliatedTo !== null
                            ? info.profile.affiliatedTo
                            : "",
                        spNeeds:
                          info.profile.spNeeds !== null
                            ? info.profile.spNeeds
                            : "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({
                        setFieldValue,
                        errors,
                        touched,
                        isSubmitting,
                        values,
                      }) => {
                        return (
                          <div>
                            <Card
                              style={
                                rtl
                                  ? { marginTop: "5%", textAlign: "right" }
                                  : { marginTop: "5%" }
                              }
                            >
                              <CardHeader className="text-center">
                                <CardTitle className="" tag="h4">
                                  {t("editCompanyProfile.title")}
                                </CardTitle>
                              </CardHeader>
                              <Form autoComplete="off">
                                <CardBody>
                                  <Container>
                                    {preview !== null ? (
                                      <center>
                                        <img
                                          className="photo-container"
                                          alt="..."
                                          width="123px"
                                          height="123px"
                                          style={{
                                            objectFit: "contain",
                                            margin: "0 0 auto auto",
                                            borderRadius: "0%",
                                          }}
                                          src={preview}
                                        ></img>
                                      </center>
                                    ) : (
                                      <center>
                                        <img
                                          className="photo-container"
                                          alt="..."
                                          width="123px"
                                          height="123px"
                                          style={{
                                            objectFit: "contain",
                                            margin: "0 0 auto auto",
                                            borderRadius: "0%",
                                          }}
                                          src={
                                            logoAfterCrop != null
                                              ? URL.createObjectURL(
                                                  logoAfterCrop
                                                )
                                              : preview
                                          }
                                        ></img>
                                      </center>
                                    )}
                                    <center>
                                      <input
                                        id="companyLogo"
                                        style={{ marginTop: "10px" }}
                                        name="logo"
                                        type="file"
                                        accept="image/*"
                                        className={"form-control col-md-4"}
                                        onChange={(event) => {
                                          onLogoChange(event);
                                        }}
                                        onClick={(event) => {
                                          onInputClick(event);
                                        }}
                                      />
                                      <p
                                        style={{
                                          marginBottom: "30px",
                                          marginTop: "10px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {t("add.edit.profile.note")}
                                      </p>
                                    </center>
                                    <h4
                                      style={{
                                        marginTop: "0px",
                                      }}
                                    >
                                      {t(
                                        "createCompanyProfile.company.diviver"
                                      )}
                                    </h4>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #bbb",
                                        marginTop: "-1%",
                                      }}
                                    ></hr>
                                    <div
                                      className="form-row"
                                      style={{ marginTop: "3%" }}
                                    >
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="companyName">
                                          {t(
                                            "createCompanyProfile.companyName"
                                          )}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          *
                                        </label>
                                        <Field
                                          autoFocus={true}
                                          name="companyName"
                                          placeholder={t(
                                            "createCompanyProfile.companyName"
                                          )}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.companyName &&
                                            touched.companyName
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        {errors.companyName &&
                                        touched.companyName ? (
                                          <ErrorMessage
                                            name="companyName"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="managerName">
                                          {t(
                                            "createCompanyProfile.managerName"
                                          )}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          *
                                        </label>
                                        <Field
                                          name="managerName"
                                          placeholder={t(
                                            "createCompanyProfile.managerName"
                                          )}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.managerName &&
                                            touched.managerName
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        {errors.managerName &&
                                        touched.managerName ? (
                                          <ErrorMessage
                                            name="managerName"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-row">
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="crNumber">
                                          {t("createCompanyProfile.crNumber")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          *
                                        </label>

                                        <label style={{ fontSize: "small" }}>
                                          {`(${t(
                                            "createCompanyProfile.StartupStage.error"
                                          )}
                                          )`}
                                        </label>

                                        {/* <i
                                          id="crNumberForStartup"
                                          style={{
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                          }}
                                          className="fa fa-question-circle"
                                        >
                                          <UncontrolledTooltip
                                            placement="right"
                                            target="#crNumberForStartup"
                                            delay={0}
                                          >
                                            {t(
                                              "createCompanyProfile.StartupStage.error"
                                            )}
                                          </UncontrolledTooltip>
                                        </i> */}
                                        <Field
                                          name="crNumber"
                                          placeholder={t(
                                            "createCompanyProfile.crNumber"
                                          )}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.crNumber && touched.crNumber
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        {errors.crNumber && touched.crNumber ? (
                                          <ErrorMessage
                                            name="crNumber"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="activeSince">
                                          {t(
                                            "createCompanyProfile.activeSince"
                                          )}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          *
                                        </label>
                                        <YearPicker
                                          value={year}
                                          defaultValue={
                                            info.profile.activeSince
                                          }
                                          reverse
                                          required={true}
                                          onChange={(year) => {
                                            setYear(year);
                                          }}
                                          name={"activeSince"}
                                          classes={
                                            "font14px form-control" +
                                            (errors.activeSince &&
                                            touched.activeSince
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                      </FormGroup>
                                    </div>
                                    <div className="form-row">
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="openForPartnership">
                                          {t(
                                            "createCompanyProfile.openForPartnership"
                                          )}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          <br></br>
                                        </label>
                                        <Field
                                          name="openForPartnership"
                                          placeholder={t(
                                            "createCompanyProfile.openForPartnership"
                                          )}
                                          style={{
                                            fontSize: "14px",
                                          }}
                                          as="select"
                                          className="form-control"
                                        >
                                          <option value={false} defaultValue>
                                            {t("label.no")}
                                          </option>
                                          <option value={true}>
                                            {t("label.yes")}
                                          </option>
                                        </Field>
                                      </FormGroup>
                                      {user !== null &&
                                      user.profileTypeView !== null &&
                                      user.profileTypeView
                                        .profileTypeDescription !== null &&
                                      user.profileTypeView
                                        .profileTypeDescription ===
                                        AccountTypes.Partner ? (
                                        <FormGroup className="col-md-6">
                                          <label htmlFor="wantsToConnect">
                                            {t(
                                              "createCompanyProfile.wantsToConnect"
                                            )}
                                          </label>
                                          <label
                                            style={{
                                              fontSize: "25px",
                                              color: "red",
                                            }}
                                            htmlFor="projectTypeID"
                                          >
                                            <br></br>
                                          </label>
                                          <Field
                                            name="wantsToConnect"
                                            placeholder={t(
                                              "createCompanyProfile.wantsToConnect"
                                            )}
                                            style={{
                                              fontSize: "14px",
                                            }}
                                            as="select"
                                            className="form-control"
                                          >
                                            <option value={false} defaultValue>
                                              {t("label.no")}
                                            </option>
                                            <option value={true}>
                                              {t("label.yes")}
                                            </option>
                                          </Field>
                                        </FormGroup>
                                      ) : (
                                        <></>
                                      )}
                                      {user !== null &&
                                      user.profileTypeView !== null &&
                                      user.profileTypeView
                                        .profileTypeDescription !== null &&
                                      user.profileTypeView
                                        .profileTypeDescription ===
                                        AccountTypes.Provider ? (
                                        <FormGroup className="col-md-6">
                                          <label htmlFor="financing">
                                            {t(
                                              "createCompanyProfile.Financing"
                                            )}
                                          </label>
                                          <label
                                            style={{
                                              fontSize: "25px",
                                              color: "red",
                                            }}
                                            htmlFor="projectTypeID"
                                          >
                                            <br></br>
                                          </label>
                                          <Field
                                            name="financing"
                                            placeholder={t(
                                              "createCompanyProfile.Financing"
                                            )}
                                            style={{
                                              fontSize: "14px",
                                            }}
                                            as="select"
                                            className="form-control"
                                          >
                                            <option value={false} defaultValue>
                                              {t("label.no")}
                                            </option>
                                            <option value={true}>
                                              {t("label.yes")}
                                            </option>
                                          </Field>
                                        </FormGroup>
                                      ) : (
                                        ""
                                      )}

                                      {
                                        <FormGroup className="col-md-6">
                                          <label htmlFor="city">
                                            {t("affiliatedTo")}
                                          </label>
                                          <label
                                            style={{
                                              fontSize: "25px",
                                              color: "red",
                                            }}
                                            htmlFor="projectTypeID"
                                          >
                                            *
                                          </label>
                                          <label
                                            style={{
                                              fontSize: "25px",
                                              color: "red",
                                            }}
                                            htmlFor="projectTypeID"
                                          >
                                            <br></br>
                                          </label>

                                          <Field
                                            name="affiliatedTo"
                                            placeholder={t("affiliatedTo")}
                                            as="select"
                                            className={
                                              "form-control" +
                                              (errors.affiliatedTo &&
                                              touched.affiliatedTo
                                                ? " is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="" defaultValue>
                                              {t("None")}
                                            </option>
                                            {partners &&
                                              partners.map((partner) => (
                                                <option
                                                  key={partner._id}
                                                  value={partner._id}
                                                >
                                                  {partner.companyName}
                                                </option>
                                              ))}
                                          </Field>

                                          {errors.affiliatedTo &&
                                          touched.affiliatedTo ? (
                                            <ErrorMessage
                                              name="cityId"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          ) : (
                                            <span
                                              className="isValid"
                                              component="div"
                                            >
                                              <br></br>
                                            </span>
                                          )}
                                        </FormGroup>
                                      }
                                    </div>
                                    {user.profileTypeView
                                      .profileTypeDescription ===
                                      AccountTypes.Provider &&
                                    user.profile.spType === "Start Up" ? (
                                      <div className="form-row">
                                        {/* <FormGroup className="col-md-6">
                                          <label htmlFor="startupStage">
                                            {t(
                                              "createCompanyProfile.StartupStage"
                                            )}
                                            *
                                          </label>
                                          <Field
                                            name="startupStage"
                                            placeholder={t(
                                              "createCompanyProfile.StartupStage"
                                            )}
                                            as="select"
                                            onChange={(v) => {
                                              setStartupStage(v.target.value);
                                              setFieldValue("startupStage", v.target.value)
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.startupStage &&
                                                touched.startupStage
                                                ? " is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value={""} defaultValue>
                                              {t(
                                                "createCompanyProfile.selectOption"
                                              )}
                                            </option>
                                            <option value={"Seed stage"}>
                                              {t("seedStage")}
                                            </option>
                                            <option value={"Early stage"}>
                                              {t("earlyStage")}
                                            </option>
                                            <option value={"Later stage"}>
                                              {t("laterStage")}
                                            </option>
                                          </Field>
                                          {errors.startupStage &&
                                            touched.startupStage ? (
                                            <ErrorMessage
                                              name="startupStage"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          ) : (
                                            <span
                                              className="isValid"
                                              component="div"
                                            >
                                              <br></br>
                                            </span>
                                          )}
                                        </FormGroup> */}
                                        {user.profile.spType === "Start Up" ? (
                                          <FormGroup className="col-md-6">
                                            <label htmlFor="incubator">
                                              {t(
                                                "createCompanyProfile.incubator"
                                              )}
                                            </label>
                                            <label
                                              style={{
                                                fontSize: "25px",
                                                color: "red",
                                              }}
                                              htmlFor="projectTypeID"
                                            >
                                              <br></br>
                                            </label>
                                            <Field
                                              name="incubator"
                                              placeholder={t(
                                                "createCompanyProfile.incubator"
                                              )}
                                              type="text"
                                              className="form-control"
                                            />
                                          </FormGroup>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="form-row">
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="numberOfEmployees">
                                          {t(
                                            "createCompanyProfile.numberOfEmployees"
                                          )}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          *
                                        </label>
                                        <Field
                                          style={{ fontSize: "14px" }}
                                          name="numberOfEmployees"
                                          placeholder={t(
                                            "createCompanyProfile.numberOfEmployees"
                                          )}
                                          as="select"
                                          className={
                                            "form-control" +
                                            (errors.numberOfEmployees &&
                                            touched.numberOfEmployees
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option
                                            value=""
                                            disabled
                                            defaultValue
                                            hidden
                                          >
                                            {t(
                                              "createCompanyProfile.numberOfEmployees.placeholder"
                                            )}
                                          </option>
                                          {empNB &&
                                            empNB.map((emp) => (
                                              <option
                                                key={emp.id}
                                                value={emp.id}
                                              >
                                                {emp.description}
                                              </option>
                                            ))}
                                        </Field>
                                        {errors.numberOfEmployees &&
                                        touched.numberOfEmployees ? (
                                          <ErrorMessage
                                            name="numberOfEmployees"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-row">
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="industry">
                                          {t("industries")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          *
                                        </label>
                                        {industriesOnly && (
                                          <Multiselect
                                            hidePlaceholder={true}
                                            style={
                                              rtl
                                                ? {
                                                    searchBox: {
                                                      borderRadius: "0px",
                                                      border:
                                                        "1px solid #E3E3E3",
                                                    },
                                                    inputField: {
                                                      width: "100%",
                                                    },

                                                    option: {
                                                      textAlign: "right",
                                                    },
                                                  }
                                                : {
                                                    searchBox: {
                                                      borderRadius: "0px",
                                                      border:
                                                        "1px solid #E3E3E3",
                                                    },
                                                    inputField: {
                                                      width: "100%",
                                                    },
                                                  }
                                            }
                                            selectedValues={
                                              selectedIndustryList
                                            }
                                            options={industriesOnly}
                                            placeholder={t(
                                              "industries.placeholder"
                                            )}
                                            displayValue="typeOfIndustryName"
                                            //     groupBy="typeOfIndustryName"
                                            closeOnSelect={false}
                                            showCheckbox={true}
                                            onSelect={(e) => {
                                              onIndustrySelectChangeAdd(e);
                                            }}
                                            onRemove={(e) => {
                                              onIndustrySelectChangeRemove(e);
                                            }}
                                          />
                                        )}
                                      </FormGroup>
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="industry">
                                          {t("services")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          *
                                        </label>
                                        {servicesOnly && (
                                          <Multiselect
                                            hidePlaceholder={true}
                                            style={
                                              rtl
                                                ? {
                                                    searchBox: {
                                                      borderRadius: "0px",
                                                      border:
                                                        "1px solid #E3E3E3",
                                                    },
                                                    inputField: {
                                                      width: "100%",
                                                    },

                                                    option: {
                                                      textAlign: "right",
                                                    },
                                                  }
                                                : {
                                                    searchBox: {
                                                      borderRadius: "0px",
                                                      border:
                                                        "1px solid #E3E3E3",
                                                    },
                                                    inputField: {
                                                      width: "100%",
                                                    },
                                                  }
                                            }
                                            options={servicesOnly}
                                            placeholder={t(
                                              "services.placeholder"
                                            )}
                                            displayValue="typeOfServiceName"
                                            groupBy="typeOfIndustryName"
                                            closeOnSelect={false}
                                            showCheckbox={true}
                                            emptyRecordMsg={t(
                                              "invalid.services"
                                            )}
                                            ref={servicesMultiselectRef}
                                            selectedValues={selectedList}
                                            onSelect={(e) => {
                                              onChange(e);
                                            }}
                                            onRemove={(e) => {
                                              onChange(e);
                                            }}
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-row">
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="website">
                                          {t("createCompanyProfile.website")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          <br></br>
                                        </label>
                                        <Field
                                          name="website"
                                          placeholder={t(
                                            "createCompanyProfile.website"
                                          )}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.website && touched.website
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        {errors.website && touched.website ? (
                                          <ErrorMessage
                                            name="website"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="linkedin">
                                          {t("createCompanyProfile.linkedin")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          <br></br>
                                        </label>
                                        <Field
                                          name="linkedin"
                                          placeholder={t(
                                            "createCompanyProfile.linkedin"
                                          )}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.linkedin && touched.linkedin
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        {errors.linkedin && touched.linkedin ? (
                                          <ErrorMessage
                                            name="linkedin"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-row">
                                      <FormGroup className="col-md-12">
                                        <label htmlFor="overview">
                                          {t("createCompanyProfile.overview")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          <br></br>
                                        </label>
                                        <textarea
                                          defaultValue={info.profile.overview}
                                          name="overview"
                                          rows="3"
                                          placeholder={t(
                                            "createCompanyProfile.overview"
                                          )}
                                          onChange={(e) =>
                                            setCompanyDescription(
                                              e.target.value
                                            )
                                          }
                                          className={"form-control"}
                                        />
                                      </FormGroup>
                                    </div>
                                    {user != null &&
                                    user.profileTypeView != null &&
                                    user.profileTypeView
                                      .profileTypeDescription ===
                                      AccountTypes.Provider ? (
                                      <div className="form-row">
                                        <FormGroup className="col-md-12">
                                          <label htmlFor="spNeeds">
                                            {t("createCompanyProfile.spNeeds")}
                                          </label>

                                          <ReactQuill
                                            theme="snow"
                                            value={spNeeds}
                                            onChange={(
                                              e //console.log(e)
                                            ) => setSpNeeds(e)}
                                            modules={modules}
                                            formats={formats}
                                          />
                                          <div className="tooltip-link">
                                            {t("link")}
                                          </div>
                                        </FormGroup>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    <h4>
                                      {t(
                                        "createCompanyProfile.contact.diviver"
                                      )}
                                    </h4>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #bbb",
                                        marginTop: "-1%",
                                      }}
                                    ></hr>
                                    <div
                                      className="form-row"
                                      style={{ marginTop: "3%" }}
                                    >
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="contactFirstName">
                                          {t(
                                            "createCompanyProfile.contactFname"
                                          )}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          *
                                        </label>
                                        <Field
                                          name="contactFirstName"
                                          placeholder={t(
                                            "createCompanyProfile.contactFname"
                                          )}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.contactFirstName &&
                                            touched.contactFirstName
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        {errors.contactFirstName &&
                                        touched.contactFirstName ? (
                                          <ErrorMessage
                                            name="contactFirstName"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="contactLastName">
                                          {t(
                                            "createCompanyProfile.contactLname"
                                          )}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          *
                                        </label>
                                        <Field
                                          name="contactLastName"
                                          placeholder={t(
                                            "createCompanyProfile.contactLname"
                                          )}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.contactLastName &&
                                            touched.contactLastName
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        {errors.contactLastName &&
                                        touched.contactLastName ? (
                                          <ErrorMessage
                                            name="contactLastName"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-row">
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="contactEmail">
                                          {t(
                                            "createCompanyProfile.contactEmail"
                                          )}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          *
                                        </label>
                                        <Field
                                          name="contactEmail"
                                          placeholder={t(
                                            "createCompanyProfile.contactEmail"
                                          )}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.contactEmail &&
                                            touched.contactEmail
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        {errors.contactEmail &&
                                        touched.contactEmail ? (
                                          <ErrorMessage
                                            name="contactEmail"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="contactJobTitle">
                                          {t("createCompanyProfile.contactJob")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          *
                                        </label>
                                        <Field
                                          name="contactJobTitle"
                                          placeholder={t(
                                            "createCompanyProfile.contactJob"
                                          )}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.contactJobTitle &&
                                            touched.contactJobTitle
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        {errors.contactJobTitle &&
                                        touched.contactJobTitle ? (
                                          <ErrorMessage
                                            name="contactJobTitle"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                    </div>
                                    <h4>
                                      {t(
                                        "createCompanyProfile.address.diviver"
                                      )}
                                    </h4>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #bbb",
                                        marginTop: "-1%",
                                      }}
                                    ></hr>
                                    <div
                                      className="form-row"
                                      style={{ marginTop: "3%" }}
                                    >
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="countryId">
                                          {t("createCompanyProfile.country")}
                                          <label
                                            style={{
                                              fontSize: "25px",
                                              color: "red",
                                            }}
                                            htmlFor="projectTypeID"
                                          >
                                            *
                                          </label>
                                          {user &&
                                            user.profileTypeView
                                              .profileTypeDescription ===
                                              AccountTypes.Provider && (
                                              <i
                                                id="disabledForSP"
                                                style={{
                                                  paddingLeft: "10px",
                                                  paddingRight: "10px",
                                                }}
                                                className="fa fa-question-circle"
                                              >
                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target="#disabledForSP"
                                                  delay={0}
                                                >
                                                  {t(
                                                    "country.disabledForSP.tooltip"
                                                  )}
                                                </UncontrolledTooltip>
                                              </i>
                                            )}
                                        </label>
                                        <Field
                                          style={{
                                            fontSize: "14px",
                                          }}
                                          name="countryId"
                                          placeholder={t(
                                            "createCompanyProfile.country"
                                          )}
                                          disabled={
                                            user &&
                                            user.profileTypeView
                                              .profileTypeDescription ===
                                              AccountTypes.Provider
                                          }
                                          as="select"
                                          className={
                                            "form-control" +
                                            (errors.countryId &&
                                            touched.countryId
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option
                                            value=""
                                            disabled
                                            defaultValue
                                            hidden
                                          >
                                            {t(
                                              "createCompanyProfile.country.placeholder"
                                            )}
                                          </option>
                                          {countries &&
                                            countries.map((country) => (
                                              <option
                                                key={country.countryId}
                                                value={country.countryId}
                                              >
                                                {country.countryName}
                                              </option>
                                            ))}
                                        </Field>
                                        {errors.countryId &&
                                        touched.countryId ? (
                                          <ErrorMessage
                                            name="countryId"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="city">
                                          {t("createCompanyProfile.city")}
                                          <label
                                            style={{
                                              fontSize: "25px",
                                              color: "red",
                                            }}
                                            htmlFor="projectTypeID"
                                          >
                                            *
                                          </label>
                                        </label>

                                        <Field
                                          name="cityId"
                                          placeholder={t(
                                            "createCompanyProfile.city"
                                          )}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.cityId && touched.cityId
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        {errors.cityId && touched.cityId ? (
                                          <ErrorMessage
                                            name="cityId"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-row">
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="address">
                                          {t("createCompanyProfile.address")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          <br></br>
                                        </label>
                                        <Field
                                          name="address"
                                          placeholder={t(
                                            "createCompanyProfile.address"
                                          )}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.address && touched.address
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        {errors.address && touched.address ? (
                                          <ErrorMessage
                                            name="address"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="region">
                                          {t("createCompanyProfile.region")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          <br></br>
                                        </label>
                                        <Field
                                          name="region"
                                          placeholder={t(
                                            "createCompanyProfile.region"
                                          )}
                                          type="text"
                                          className="form-control"
                                        />
                                      </FormGroup>
                                    </div>
                                    <div className="form-row">
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="city">
                                          {t("zip.postal.code")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          <br></br>
                                        </label>
                                        <Field
                                          name="zipOrPostalCode"
                                          placeholder={t("zip.postal.code")}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (errors.zipOrPostalCode &&
                                            touched.zipOrPostalCode
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        {errors.zipOrPostalCode &&
                                        touched.zipOrPostalCode ? (
                                          <ErrorMessage
                                            name="cityId"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        ) : (
                                          <span
                                            className="isValid"
                                            component="div"
                                          >
                                            <br></br>
                                          </span>
                                        )}
                                      </FormGroup>
                                    </div>

                                    {inputList.map((x, i) => {
                                      return (
                                        <div className="form-row">
                                          <FormGroup className="col-md-4">
                                            <label htmlFor="phone">
                                              {t("createCompanyProfile.phone")}
                                            </label>
                                            <label
                                              style={{
                                                fontSize: "25px",
                                                color: "red",
                                              }}
                                              htmlFor="projectTypeID"
                                            >
                                              <br></br>
                                            </label>
                                            <Field
                                              placeholder={t(
                                                "createCompanyProfile.phone"
                                              )}
                                              name="phone"
                                              type="text"
                                              className={"form-control"}
                                              value={x.phone}
                                              onChange={(e) =>
                                                handleInputChange(e, i)
                                              }
                                            />
                                          </FormGroup>
                                          {inputList.length > 0 && (
                                            <Button
                                              style={{
                                                // height: "50%",
                                                marginTop: "43px",
                                              }}
                                              className="mr10"
                                              onClick={(x) =>
                                                handleRemoveClick(i)
                                              }
                                              color="danger"
                                            >
                                              <i
                                                class="fa fa-minus"
                                                aria-hidden="true"
                                              ></i>
                                            </Button>
                                          )}
                                          {inputList.length - 1 === i && (
                                            <Button
                                              onClick={handleAddClick}
                                              style={{
                                                // height: "50%",
                                                marginTop: "43px",
                                              }}
                                              color="primary"
                                            >
                                              <i
                                                class="fa fa-plus"
                                                aria-hidden="true"
                                              ></i>
                                            </Button>
                                          )}
                                        </div>
                                      );
                                    })}

                                    {inputList.length === 0 && (
                                      <Button
                                        onClick={handleAddClick}
                                        style={{
                                          //height: "50%",
                                          marginTop: "30px",
                                          marginLeft: "20px",
                                          marginRight: "20px",
                                        }}
                                        color="primary"
                                      >
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    )}

                                    {(user &&
                                      user.profileTypeView
                                        .profileTypeDescription ===
                                        AccountTypes.Seeker) ||
                                    user.profileTypeView
                                      .profileTypeDescription ===
                                      AccountTypes.Partner ? (
                                      <>
                                        <h4>
                                          {t(
                                            "createCompanyProfile.privacyDetails"
                                          )}{" "}
                                        </h4>
                                        <hr
                                          style={{
                                            borderTop: "1px solid #bbb",
                                            marginTop: "-1%",
                                          }}
                                        ></hr>
                                        <p>{t("privacyDetails.title")}</p>
                                        <ul>
                                          <li>
                                            <b>{t("all")}</b>{" "}
                                            {t("privacyDetails.one")}{" "}
                                          </li>
                                          <li>
                                            <b>{t("nda")}</b>{" "}
                                            {t("privacyDetails.two")}{" "}
                                          </li>
                                          <li>
                                            <b>{t("none")}</b>{" "}
                                            {t("privacyDetails.three")}{" "}
                                          </li>
                                        </ul>
                                        <div className="form-row">
                                          <FormGroup className="col-md-6">
                                            <label htmlFor="companyInfoShowTo">
                                              {t(
                                                "createCompanyProfile.showCompanyInfo"
                                              )}
                                            </label>
                                            <label
                                              style={{
                                                fontSize: "25px",
                                                color: "red",
                                              }}
                                              htmlFor="projectTypeID"
                                            >
                                              <br></br>
                                            </label>
                                            <Field
                                              style={{
                                                fontSize: "14px",
                                              }}
                                              name="companyInfoShowTo"
                                              as="select"
                                              className={
                                                "form-control" +
                                                (errors.companyInfoShowTo &&
                                                touched.companyInfoShowTo
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            >
                                              <option
                                                defaultValue
                                                defaultChecked
                                                value="All"
                                              >
                                                {t("all")}
                                              </option>
                                              <option value="NDA">
                                                {t("nda")}
                                              </option>
                                              <option value="None">
                                                {t("none")}
                                              </option>
                                            </Field>
                                          </FormGroup>
                                          <FormGroup className="col-md-6">
                                            <label htmlFor="contactInfoShowTo">
                                              {t(
                                                "createCompanyProfile.showContactInfo"
                                              )}
                                            </label>
                                            <label
                                              style={{
                                                fontSize: "25px",
                                                color: "red",
                                              }}
                                              htmlFor="projectTypeID"
                                            >
                                              <br></br>
                                            </label>
                                            <Field
                                              style={{
                                                fontSize: "14px",
                                              }}
                                              name="contactInfoShowTo"
                                              as="select"
                                              className={
                                                "form-control" +
                                                (errors.contactInfoShowTo &&
                                                touched.contactInfoShowTo
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            >
                                              <option
                                                defaultValue
                                                defaultChecked
                                                value="All"
                                              >
                                                {t("all")}
                                              </option>
                                              <option value="NDA">
                                                {t("nda")}
                                              </option>
                                              <option value="None">
                                                {t("none")}
                                              </option>
                                            </Field>
                                          </FormGroup>
                                        </div>
                                        {/* {
                                          values.companyInfoShowTo == "NDA" ?
                                            <div className="form-row">
                                              <FormGroup className="col-md-12">
                                                <label htmlFor="companyNDA">
                                                  {t(
                                                    "createCompanyProfile.companyNDA"
                                                  )}
                                                </label>
                                                <ReactQuill
                                                  theme="snow"
                                                  value={companyNDA}
                                                  onChange={(e) => setCompanyNDA(e)}
                                                />
                                              </FormGroup>
                                            </div> : ""
                                        } */}
                                      </>
                                    ) : null}

                                    <h4>
                                      {t("createCompanyProfile.supportingDocs")}{" "}
                                      <i
                                        id="docs"
                                        className="fa fa-question-circle"
                                      >
                                        <UncontrolledTooltip
                                          placement="right"
                                          target="#docs"
                                          delay={0}
                                        >
                                          {t("docs.tooltip")}
                                        </UncontrolledTooltip>
                                      </i>
                                    </h4>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #bbb",
                                        marginTop: "-1%",
                                      }}
                                    ></hr>
                                    {infoDocument.map((doc, i) => {
                                      return doc.files.map((docs, j) => {
                                        return (
                                          <div className="form-row">
                                            <FormGroup className="col-md-6">
                                              <label htmlFor="companyInfoShowTo">
                                                {t("document.type.label")}
                                              </label>
                                              <label
                                                style={{
                                                  fontSize: "25px",
                                                  color: "red",
                                                }}
                                                htmlFor="projectTypeID"
                                              >
                                                <br></br>
                                              </label>
                                              <Field
                                                style={{
                                                  fontSize: "14px",
                                                }}
                                                name="documentType"
                                                as="select"
                                                value={
                                                  doc.documentType
                                                    .documentTypeId
                                                }
                                                className={
                                                  "form-control" +
                                                  (errors.companyInfoShowTo &&
                                                  touched.companyInfoShowTo
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                onChange={(event) => {
                                                  var found = false;
                                                  for (
                                                    var k = 0;
                                                    k < infoDocument.length;
                                                    k++
                                                  ) {
                                                    if (
                                                      infoDocument[k]
                                                        .documentType
                                                        .documentTypeId ==
                                                      event.target.value
                                                    ) {
                                                      found = true;
                                                      infoDocument[
                                                        k
                                                      ].files.push(docs);
                                                      infoDocument[
                                                        i
                                                      ].files.splice(j, 1);
                                                    }
                                                  }
                                                  if (!found) {
                                                    var newDocumentType = {
                                                      documentType: {
                                                        documentTypeId:
                                                          event.target.value,
                                                        documentTypeName: "",
                                                      },
                                                      files: [
                                                        {
                                                          filePath:
                                                            docs.filePath,
                                                          fileName:
                                                            docs.fileName,
                                                        },
                                                      ],
                                                    };
                                                    infoDocument.push(
                                                      newDocumentType
                                                    );
                                                    infoDocument[
                                                      i
                                                    ].files.splice(j, 1);
                                                  }
                                                  setInfoDocument(infoDocument);
                                                }}
                                              >
                                                <option value="" defaultValue>
                                                  {t(
                                                    "createCompanyProfile.selectOption"
                                                  )}
                                                </option>

                                                {documentTypeList &&
                                                  documentTypeList.map(
                                                    (type) => (
                                                      <option
                                                        key={
                                                          type.documentTypeId
                                                        }
                                                        value={
                                                          type.documentTypeId
                                                        }
                                                      >
                                                        {type.documentTypeName}
                                                      </option>
                                                    )
                                                  )}
                                              </Field>
                                            </FormGroup>
                                            <FormGroup className="col-md-4">
                                              <label htmlFor="companyInfoShowTo">
                                                {t("document.name")}
                                              </label>
                                              <label
                                                style={{
                                                  fontSize: "25px",
                                                  color: "red",
                                                }}
                                                htmlFor="projectTypeID"
                                              >
                                                <br></br>
                                              </label>
                                              <a
                                                href={docs.filePath}
                                                rel="noreferrer"
                                                target="_blank"
                                                className="form-control"
                                              >
                                                {docs.fileName}
                                              </a>
                                            </FormGroup>
                                            <Button
                                              style={{
                                                marginTop: "auto",
                                              }}
                                              className="mr10"
                                              color="danger"
                                              onClick={() => {
                                                const model = {
                                                  documentName: docs.fileName,
                                                };
                                                profileService
                                                  .RemoveDocumentByNameAsync(
                                                    user.profile.userId,
                                                    model
                                                  )
                                                  .then(() => {
                                                    const newList =
                                                      infoDocument[
                                                        i
                                                      ].files.filter(
                                                        (item) =>
                                                          item.fileName !==
                                                          docs.fileName
                                                      );
                                                    infoDocument[i].files =
                                                      newList;
                                                    const list =
                                                      infoDocument.filter(
                                                        (item) =>
                                                          item.files.length !==
                                                          0
                                                      );
                                                    setInfoDocument(list);
                                                    if (
                                                      is10 === true &&
                                                      infoDocLength - 1 <
                                                        maxFileUpload
                                                    ) {
                                                      setIs10(false);
                                                    }
                                                    setInfoDocLength(
                                                      infoDocLength > 0
                                                        ? infoDocLength - 1
                                                        : 0
                                                    );
                                                  });
                                              }}
                                            >
                                              <i
                                                class="fa fa-minus"
                                                aria-hidden="true"
                                              ></i>
                                            </Button>
                                          </div>
                                        );
                                      });
                                    })}
                                    {!is10 &&
                                      docInputList.map((x, i) => {
                                        return x.display === true ? (
                                          <div className="form-row">
                                            <FormGroup className="col-md-6">
                                              <label htmlFor="companyInfoShowTo">
                                                {t("document.type.label")}
                                              </label>
                                              <label
                                                style={{
                                                  fontSize: "25px",
                                                  color: "red",
                                                }}
                                                htmlFor="projectTypeID"
                                              >
                                                <br></br>
                                              </label>
                                              <Field
                                                name="documentType"
                                                as="select"
                                                value={x.documentType}
                                                className={
                                                  "form-control" +
                                                  (errors.companyInfoShowTo &&
                                                  touched.companyInfoShowTo
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                onChange={(event) =>
                                                  handleDocTypeInputChange(
                                                    event.target.value,
                                                    i
                                                  )
                                                }
                                              >
                                                <option value="" defaultValue>
                                                  {t(
                                                    "createCompanyProfile.selectOption"
                                                  )}
                                                </option>
                                                {documentTypeList &&
                                                  documentTypeList.map(
                                                    (type) => (
                                                      <option
                                                        key={
                                                          type.documentTypeId
                                                        }
                                                        value={
                                                          type.documentTypeId
                                                        }
                                                      >
                                                        {type.documentTypeName}
                                                      </option>
                                                    )
                                                  )}
                                              </Field>
                                            </FormGroup>
                                            <FormGroup className="col-md-4">
                                              <label htmlFor="documents">
                                                {t(
                                                  "createCompanyProfile.uploadDoc"
                                                )}
                                              </label>
                                              <label
                                                style={{
                                                  fontSize: "25px",
                                                  color: "red",
                                                }}
                                                htmlFor="projectTypeID"
                                              >
                                                <br></br>
                                              </label>
                                              <input
                                                id={`documentFile${i}`}
                                                name="fileDocs"
                                                type="file"
                                                accept="image/*,.pdf"
                                                className={"form-control"}
                                                onChange={(event) =>
                                                  handleDocInputChange(
                                                    event.target.files[0],
                                                    i
                                                  )
                                                }
                                              />
                                            </FormGroup>
                                            {infoDocLength +
                                              docInputList.length >
                                              0 && (
                                              <Button
                                                style={{
                                                  height: "50%",
                                                  marginTop: "43px",
                                                }}
                                                className="mr10"
                                                onClick={(x) => {
                                                  handleDocRemoveClick(i);
                                                }}
                                                color="danger"
                                              >
                                                <i
                                                  class="fa fa-minus"
                                                  aria-hidden="true"
                                                ></i>
                                              </Button>
                                            )}
                                            {/* {documentCount - 1 === i &&
                                                infoDocLength +
                                                documentCount <
                                                10 && (
                                                  <Button
                                                    onClick={handleDocAddClick}
                                                    style={{
                                                      height: "50%",
                                                      marginTop: "43px",
                                                    }}
                                                    color="primary"
                                                  >
                                                    <i
                                                      class="fa fa-plus"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </Button>
                                                )} */}
                                          </div>
                                        ) : null;
                                      })}
                                    {/* {docInputList.length === 0 &&
                                      infoDocLength + docInputList.length <
                                      10 && (
                                        <Button
                                          onClick={handleDocAddClick}
                                          style={{
                                            height: "50%",
                                            marginTop: "43px",
                                          }}
                                          color="primary"
                                        >
                                          <i
                                            class="fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                        </Button>
                                      )} */}
                                    <Button
                                      onClick={handleDocAddClick}
                                      disabled={
                                        infoDocLength + documentCount >=
                                        maxFileUpload
                                      }
                                      style={{
                                        height: "50%",
                                        marginTop: "0px",
                                      }}
                                      color="primary"
                                    >
                                      <i
                                        class="fa fa-plus"
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                    <div className="form-row">
                                      <FormGroup className="col-md-12">
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          *
                                        </label>
                                        <label>{t("requiredFields")}</label>
                                      </FormGroup>
                                    </div>
                                    <div className="form-row">
                                      <FormGroup className="col-md-12 text-center d-flex flex-column align-items-center justify-content-center">
                                        {userDraft ? (
                                          <Button
                                            outline
                                            color="primary"
                                            type="button"
                                            disabled={savingDraft}
                                            onClick={() => {
                                              setSavingDraft(true);
                                              SaveDraft(values);
                                            }}
                                          >
                                            {savingDraft && (
                                              <span className="spinner-border spinner-border-sm mr-1"></span>
                                            )}
                                            {t("saveAsDraft")}
                                          </Button>
                                        ) : null}
                                        <Button
                                          color="primary"
                                          type="submit"
                                          style={{ width: "fitContent" }}
                                          disabled={isSubmitting}
                                          onClick={() => {
                                            if (
                                              Object.keys(errors).length !== 0
                                            ) {
                                              if (
                                                user &&
                                                user.profile &&
                                                user.profile.profileStatus ===
                                                  ProfileStatus.Active
                                              ) {
                                                setError(
                                                  t("fillMandatoryFields")
                                                );
                                              } else {
                                                setError(
                                                  t("fillMandatoryOrDraft")
                                                );
                                              }

                                              setErrorAlert(!errorAlert);
                                            }
                                          }}
                                        >
                                          {isSubmitting && (
                                            <span className="spinner-border spinner-border-sm mr-1"></span>
                                          )}
                                          {t("createCompanyProfile.save")}
                                        </Button>
                                      </FormGroup>
                                    </div>
                                  </Container>
                                </CardBody>
                              </Form>
                            </Card>
                          </div>
                        );
                      }}
                    </Formik>
                  </Col>
                </Row>
              </>
            </Container>
          </div>
          {/* <DefaultFooter /> */}
        </>
      )}
    </>
  );
}

export default EditProfile;
