import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Modal,
} from "reactstrap";
import { profileService } from "_services";
import { StartupStages } from "_helpers";
import { LanguageContext } from "_context";

function UpgradeAccount({ history, refresh }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const user = profileService.userValue;

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [currentLan, setCurrentLan] = useContext(LanguageContext);

  const businessAccount =
    /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|google|outlook|msn|gmail|yahoo|AOL|Zoho|Mail.com|ProtonMail|Icloud|GMX|Yandex|Mail2World|Tutanota|10minutemail|Hubspot|Hushmail|Temp-mail.org|eclipso|eu)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/;

  function checkBusinessAccount(email) {
    var isBusinessAccount = businessAccount.test(email);
    return isBusinessAccount;
  }

  async function UpgradeCorporateAccount() {
    await profileService
      .UpgradeCorporateToEarlyAsync(user.profile.userId)
      .then(() => {
        setSuccessMessage(t("upgrade.corporate.early"));
        setSuccessAlert(!successAlert);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName === currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }
  async function UpgradeToLaterStage() {
    await profileService
      .UpgradeToLaterStageAsync(user.profile.userId)
      .then(() => {
        setSuccessMessage(t("upgrade.early.later"));
        setSuccessAlert(!successAlert);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName === currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function UpgradeToCorporate() {
    await profileService
      .UpgradeToCorporateAsync(user.profile.userId)
      .then(() => {
        setSuccessMessage(t("upgrade.corporate"));
        setSuccessAlert(!successAlert);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName === currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }
  return (
    <>
      <Modal isOpen={successAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("done")}</h5>
        </div>
        <div className="modal-body">
          <p>{successMessage}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            style={{ margin: "auto" }}
            onClick={async (e) => {
              await refresh();
              setSuccessAlert(!successAlert);
            }}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div className="modal-body col-md-12">
          <p>{errorMessage}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            style={{ margin: "auto" }}
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Card>
        <CardHeader className="text-center">
          <CardTitle className="" tag="h4">
            {t("upgradeStartupAccount")}
          </CardTitle>
          <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
        </CardHeader>
        <CardBody>
          <center>
            {user !== null &&
              user.profile !== null &&
              user.profile.spType !== null &&
              user.profile.startupStage !== null &&
              user.profile.spType === "Start Up" &&
              user.profile.startupStage === StartupStages.Seed && (
                <Button
                  color="primary"
                  type="button"
                  onClick={async () => {
                    const test = checkBusinessAccount(user.profile.userName);
                    if (test === true) {
                      await UpgradeCorporateAccount();
                    } else {
                      history.push(
                        `/account/upgrade-account?email=${user.profile.userName}`
                      );
                    }
                  }}
                >
                  {t("upgradeSeedToEarly")}
                </Button>
              )}

            {user !== null &&
              user.profile !== null &&
              user.profile.spType !== null &&
              user.profile.startupStage !== null &&
              user.profile.spType === "Start Up" &&
              user.profile.startupStage === StartupStages.Early && (
                <Button
                  color="primary"
                  type="button"
                  onClick={async () => {
                    await UpgradeToLaterStage();
                  }}
                >
                  {t("upgradeEarlyToLater")}
                </Button>
              )}

            {user !== null &&
              user.profile !== null &&
              user.profile.spType !== null &&
              user.profile.startupStage !== null &&
              user.profile.spType === "Start Up" &&
              user.profile.startupStage === StartupStages.Later && (
                <Button
                  color="primary"
                  type="button"
                  onClick={async () => {
                    await UpgradeToCorporate();
                  }}
                >
                  {t("upgradeLaterToCorporate")}
                </Button>
              )}
          </center>
        </CardBody>
      </Card>
    </>
  );
}

export default UpgradeAccount;
