import AddressDetails from "components/Profile/CompanyProfilePage/AddressDetails";
import ContactInfo from "components/Profile/CompanyProfilePage/ContactInfo";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
// reactstrap components
import { Card, CardBody, CardTitle, CardHeader, FormGroup } from "reactstrap";
import CardText from "reactstrap/lib/CardText";
import Row from "reactstrap/lib/Row";
import RestrictedInfoCard from "./RestrictedInfoCard";

// core components

function AddressDetailsSecure({ props }) {
  const [showInfo, setShowInfo] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setShowInfo(props.contactShowInfo);
  }, []);
  return (
    <>
      {showInfo === true ? (
        <AddressDetails props={props} />
      ) : (
        <Card>
          <CardHeader className="text-center">
            <CardTitle className="" tag="h4">
              {t("createCompanyProfile.address.diviver")}
            </CardTitle>
            <hr
              className="btn-primary"
              style={{ size: "1", width: "20%" }}
            ></hr>
          </CardHeader>
          <CardBody>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("createCompanyProfile.country")}
                  </label>

                  <label
                    className="col-md-6"
                    //  style={{ fontWeight: "bold" }}
                  >
                    {props.countryView.countryName}
                  </label>
                </div>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("createCompanyProfile.city")}
                  </label>
                  <label
                    className="col-md-6"
                    //    style={{ fontWeight: "bold" }}
                  >
                    {props.profile.cityID}
                  </label>
                </div>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("createCompanyProfile.address")}
                  </label>
                  <label className="col-md-6">
                    <i
                      class="fa fa-lock"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </label>
                </div>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("createCompanyProfile.region")}
                  </label>
                  <label
                    className="col-md-6"
                    //    style={{ fontWeight: "bold" }}
                  >
                    {props.profile.region}
                  </label>
                </div>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("zip.postal.code")}
                  </label>
                  <label className="col-md-6">
                    {props.profile.zipOrPostalCode !== null &&
                      props.profile.zipOrPostalCode}
                  </label>
                </div>
              </FormGroup>
            </div>

            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("createCompanyProfile.phone")}
                  </label>
                  <label className="col-md-6">
                    <i
                      class="fa fa-lock"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </label>
                </div>
              </FormGroup>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default AddressDetailsSecure;
