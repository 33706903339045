import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import VideosPage from "./VideosPage";

function Videos({ history, match }) {
  const { path } = match;
  return (
    <div style={{ height: "inherit" }}>
      <Switch>
        <Route path={`${path}`} component={VideosPage} exact />
      </Switch>
    </div>
  );
}

export { Videos };
