import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import EditVariables from "./EditVariable";
import VariablesList from "./VariablesList";

function SystemVariables({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={VariablesList} exact />
        <PrivateRoute path={`${path}/edit/:id`} component={EditVariables} />
      </Switch>
    </div>
  );
}

export { SystemVariables };
