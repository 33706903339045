import React, { useState, createContext } from "react";
import { profileService } from "_services";

// Create Context Object
export const DirectLinkCriteriaContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const DirectLinkCriteriaContextProvider = (props) => {
  const user = profileService.userValue;

  const initialModel = {
    userPerforminSearchId: user !== null ? user.profile.userId : "",
    searchIndex: 0,
    industries:
      user == null
        ? {}
        : user.typeOfIndustry !== null && user.typeOfIndustry.length > 0
        ? {
            typeOfIndustryId: user.typeOfIndustry[0].typeOfIndustryId,
            typeOfIndustryName: user.typeOfIndustry[0].typeOfIndustryName,
          }
        : {},
    typeOfIndustriesAndServices: [],
    publicationDateFrom: null,
    publicationDateTo: null,
    searchKeyWord: "",
    countries: [],
    closingDateFrom: null,
    closingDateTo: null,
    filterType: "Ascending",
    filterBy: "Link",
    languageName: "en",
    initialModal: true,
  };
  const [searchModel, setSearchModel] = useState(initialModel);

  return (
    <DirectLinkCriteriaContext.Provider value={[searchModel, setSearchModel]}>
      {props.children}
    </DirectLinkCriteriaContext.Provider>
  );
};
