import React, { useState } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { profileService } from "_services";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import { AccountTypes } from "_helpers";
import ProfilePageTopContent from "components/Profile/CompanyProfilePage/ProfilePageTopContent";
import CompanyInfo from "components/Profile/CompanyProfilePage/CompanyInfo";
import AddressDetails from "components/Profile/CompanyProfilePage/AddressDetails";
import Industries from "components/Profile/CompanyProfilePage/Industries";
import ContactInfo from "components/Profile/CompanyProfilePage/ContactInfo";
import Documents from "components/Profile/CompanyProfilePage/Documents";
import ProfilePageTopContentSecure from "components/ServiceProvider/Search/ProfilePageTopContentSecure";
import CompanyDetailsSecure from "components/ServiceProvider/Search/CompanyDetailsSecure";
import AddressDetailsSecure from "components/ServiceProvider/Search/AddressDetailsSecure";
import ContactDetailsSecure from "components/ServiceProvider/Search/ContactDetailsSecure";
import { LanguageContext } from "_context";
import { useContext } from "react";
function CompanyDetails({ history, match, location }) {
  const id = match.params.userId;
  const projectID = match.params.projectId;
  const user = profileService.userValue;
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const fetchData = async () => {
    setLoading(true);
    await profileService
      .GetProfileById(id, false, projectID, user.profile.userId, currentLan)
      .then((profile) => {
        setInfo(profile);
      });
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <div className="">
        {info && (
          <>
            <ProfilePageTopContentSecure
              props={info}
              history={history}
              location={location}
            />
            <div className="section">
              <Container>
                <div className="button-container">
                  <Button
                    className="btn"
                    color="primary"
                    size="lg"
                    onClick={() => history.push(`..`)}
                  >
                    {t("backToList")}
                  </Button>
                </div>
              </Container>
            </div>
            <Container style={{ marginTop: "-50px" }}>
              <Row>
                <Col md="6">
                  <CompanyDetailsSecure props={info} />
                  <AddressDetailsSecure props={info} />
                  <Industries props={info} />
                </Col>
                <Col md="6">
                  <Row>
                    <ContactDetailsSecure props={info} />
                  </Row>
                  <Row>
                    <Documents props={info} />
                  </Row>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </div>
    </>
  );
}

export default CompanyDetails;
