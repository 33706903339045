// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/circle-packing
import React, { useState, useContext } from "react";
import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import { analyticsService } from "_services";
import PulseLoader from "react-spinners/PulseLoader";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
function CirclePacking() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const fetchData = async () => {
    setLoading(true);
    await analyticsService.GetProfilesPackedCircleAsync().then((x) => {
      setData(x);
    });

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  // const data = {
  //   name: "Profiles",
  //   // color: "hsl(33, 70%, 50%)",
  //   children: [
  //     {
  //       name: "SP",
  //       //  color: "hsl(53, 70%, 50%)",
  //       children: [
  //         {
  //           name: "Corporate",
  //           //  color: "hsl(128, 70%, 50%)",
  //           value: 500000,
  //         },
  //         {
  //           name: "Startup",
  //           //   color: "hsl(66, 70%, 50%)",

  //           children: [
  //             {
  //               name: "Seed",
  //               //    color: "hsl(186, 70%, 50%)",
  //               value: 100000,
  //             },
  //             {
  //               name: "Early",
  //               //     color: "hsl(100, 70%, 50%)",
  //               value: 170487,
  //             },
  //             {
  //               name: "Later",
  //               //    color: "hsl(189, 70%, 50%)",
  //               value: 104119,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       name: "PO",
  //       //     color: "hsl(249, 70%, 50%)",
  //       value: 2550000,
  //     },

  //     {
  //       name: "SS",
  //       //    color: "hsl(125, 70%, 50%)",
  //       value: 800000,
  //     },
  //   ],
  // };
  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <div className="section">
        <Container>
          <div style={{ height: "500px" }}>
            <h6 className="text-primary">
              {" "}
              {t("profiles-CirclePackingChart")}
            </h6>
            <ResponsiveCirclePacking
              data={data}
              margin={{ top: 20, right: 20, bottom: 200, left: 20 }}
              id="name"
              value="value"
              valueFormat=" >-d"
              colors={{ scheme: "paired" }}
              childColor={{ from: "color", modifiers: [["brighter", 0.4]] }}
              padding={4}
              enableLabels={true}
              labelsFilter={function (e) {
                return 2 === e.node.depth;
              }}
              labelsSkipRadius={10}
              labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.5]] }}
              defs={[
                {
                  id: "lines",
                  type: "patternLines",
                  background: "none",
                  color: "inherit",
                  rotation: -45,
                  lineWidth: 5,
                  spacing: 8,
                },
              ]}
              fill={[{ match: { depth: 1 }, id: "lines" }]}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default CirclePacking;
