import React, { useState } from "react";

// reactstrap components
import { Button, Container, Row, Col, Modal } from "reactstrap";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import LoggedInUserNavbar from "components/Navbars/LoggedInUserNavbar";
import ProfilePageTopContent from "./ProfilePageTopContent";
import { profileService } from "../../../_services";
import CompanyInfo from "./CompanyInfo";
import ContactInfo from "./ContactInfo";
import AddressDetails from "./AddressDetails";
import Industries from "./Industries";
import PrivacyDetails from "./PrivacyDetails";
import PulseLoader from "react-spinners/PulseLoader";
import Documents from "./Documents";
import { useTranslation } from "react-i18next";
import { AccountTypes } from "../../../_helpers";
import SecondaryContacts from "./SecondaryContacts";
import SpecialNeeds from "./SpecialNeeds";
import { LanguageContext } from "_context";
import { useContext } from "react";
// import MembershipHistory from "./Membership/MembershipHistory";
import InsightCard from "./Insights/InsightCard";
import { planService } from "_services/plan.service";

function ProfilePage({ history, match, location }) {
  const { path } = match;
  const user = profileService.userValue;
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [planAnalytics, setPlanAnalytics] = useState(false);
  const [secondaryUsers, setSecondaryUsers] = useState([]);
  const [isDisabled, setIsDisabled] = useState([]);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const fetchData = async () => {
    setLoading(true);

    await profileService
      .GetProfileById(user.profile.userId, false, null, null, currentLan)
      .then((profile) => {
        setInfo(profile);
        // planService
        //   .GetPlanById(profile.profile.membership.slice(-1)[0].planId)
        //   .then((x) => {
        //     console.log(x);
        //     x != null ? setPlanAnalytics(x.analytics) : setPlanAnalytics(false);
        //   })
        //   .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

    await profileService
      .GetSecondaryUsersAsync(user.profile.userId)
      .then((secUsers) => {
        setSecondaryUsers(secUsers);
        const disabledArr = [];
        for (const user in secUsers) {
          disabledArr[user] = secUsers[user].disabled;
        }
        setIsDisabled(disabledArr);
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
    // console.log(info.profile.membership.slice(-1)[0].planId);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const getExpirationDate = (expDate) => {
    return `${new Date(expDate).getDate()}/${
      monthNames[new Date(expDate).getMonth()]
    }/${new Date(expDate).getFullYear()}`;
  };

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      {/* <PartnerNavbar props={history} /> */}
      <>
        <Modal isOpen={error} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div className="modal-body" style={{ margin: "0 auto" }}>
            <p>{message}</p>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={() => {
                setError(false);
                setMessage("");
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
      </>
      <div className="">
        {info && (
          <>
            <ProfilePageTopContent
              props={info}
              history={history}
              location={location}
            />
            <div className="section">
              <Container>
                <div className="button-container">
                  {user.isPrimary == true ? (
                    <Button
                      className="btn"
                      color="primary"
                      size="lg"
                      onClick={() => history.push(`${path}/edit`)}
                    >
                      {t("editProfile.button")}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </Container>
            </div>
            <Container style={{ marginTop: "-50px" }}>
              <Row>
                <Col md="6">
                  <CompanyInfo props={info} />
                  <AddressDetails props={info} />
                  <Industries props={info} />
                </Col>
                <Col md="6">
                  <Row>
                    {/* <MembershipHistory
                      props={info}
                      history={history}
                      location={location}
                      match={path}
                    /> */}
                  </Row>
                  {/* {planAnalytics == true &&
                  (user.profileTypeView.profileTypeDescription ===
                    AccountTypes.Seeker ||
                    user.profileTypeView.profileTypeDescription ===
                      AccountTypes.Partner) ? (
                    <Row>
                      <InsightCard
                        props={info}
                        history={history}
                        location={location}
                        match={path}
                      />
                    </Row>
                  ) : (
                    <></>
                  )} */}

                  <Row>
                    <ContactInfo props={info} />
                  </Row>
                  {user.profileTypeView.profileTypeDescription ===
                    AccountTypes.Provider &&
                  user.isPrimary &&
                  (user.profile.expirationDate == null ||
                    user.profile.expirationDate == undefined ||
                    user.profile.expirationDate == "" ||
                    getExpirationDate(user.profile.expirationDate) ==
                      "1/January/1") ? (
                    <Row>
                      <SecondaryContacts
                        props={info}
                        secondaryUsers={secondaryUsers}
                        setSecondaryUsers={setSecondaryUsers}
                        setError={setError}
                        setMessage={setMessage}
                        isDisabled={isDisabled}
                        setIsDisabled={setIsDisabled}
                      />
                    </Row>
                  ) : null}
                  {user.profileTypeView.profileTypeDescription ===
                    AccountTypes.Seeker ||
                  user.profileTypeView.profileTypeDescription ===
                    AccountTypes.Partner ? (
                    <Row>
                      <PrivacyDetails props={info} />
                    </Row>
                  ) : null}

                  <Row>
                    <Documents props={info} />
                  </Row>
                  {user != null &&
                  user.profileTypeView != null &&
                  user.profileTypeView.profileTypeDescription ===
                    AccountTypes.Provider ? (
                    <Row>
                      <SpecialNeeds props={info} />
                    </Row>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Container>
            {/* <DefaultFooter /> */}
          </>
        )}
      </div>
    </>
  );
}

export default ProfilePage;
