import React, {
  useEffect,
  useState,
  useContext,
  createRef,
  useRef,
  useCallback,
} from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Multiselect } from "multiselect-react-dropdown";
import { YearPicker } from "react-dropdown-date";
import "../../assets/css/generalStyles.css";
import "../../assets/css/tooltip-style.css";
import "../../assets/css/overrideReactCrop.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { CheckCircleOutline } from "@material-ui/icons";
import {
  profileService,
  numberOfEmpService,
  countryService,
  industryService,
  documentService,
  systemSetupService,
} from "../../_services";
import queryString from "query-string";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  FormGroup,
  CardTitle,
  Modal,
  Container,
} from "reactstrap";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import LoggedInUserNavbar from "components/Navbars/LoggedInUserNavbar";
import PulseLoader from "react-spinners/PulseLoader";
import { LanguageContext } from "../../_context";
import { AccountTypes } from "../../_helpers";
import { FileSettings } from "_helpers";
import Row from "reactstrap/lib/Row";
import ReactQuill from "react-quill";
import { CenterFocusStrongRounded } from "@mui/icons-material";

function CreateProfile({ history, location }) {
  const [user, setUser] = useState(
    profileService.userValue === null ? null : profileService.userValue
  );
  const [startupStage, setStartupStage] = useState(null);
  const [empNB, setEmpNB] = useState(null);
  const [countries, setCountries] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [documentTypeList, setDocumentTypeList] = useState(null);
  const [imageValidation, setImageValidation] = useState(false);
  const [year, setYear] = useState("");
  const [alert, setAlert] = useState(false);
  const [draftAlert, setDraftAlert] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [selectedList, setSelectedList] = useState([]);
  const [selectedIndustryList, setSelectedIndustryList] = useState([]);
  const [logoPath, setLogoPath] = useState();
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [existingCompany, setExistingCompany] = useState(null);
  const [companyErr, setCompanyErr] = useState(null);
  const [partners, setPartners] = useState([]);
  const [spNeeds, setSpNeeds] = useState(null);
  const [VerificationErrorMessage, setVerificationErrorMessage] =
    useState(null);
  const [errorVerificationAlert, setErrorVerificationAlert] = useState(false);
  const [verificationAlert, setVerficationAlert] = useState(false);
  // const [companyNDA, setCompanyNDA] = useState("");
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [inputList, setInputList] = useState([{ phone: "" }]);

  const [docInputList, setDocInputList] = useState([
    { documentType: null, document: null, display: true },
  ]);
  const [docShow, setDocShow] = useState([
    { documentType: null, document: null, display: true },
  ]);
  const [documentCount, setDocumentCount] = useState(1);

  const [preview, setPreview] = useState(null);
  const imgRef = useRef(null);
  const completedCropRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, height: 30 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [logoAfterCrop, setLogoAfterCrop] = useState(null);
  const parsed = queryString.parse(location.search);

  const [maxFileUpload, setMaxFileUpload] = useState(10);
  const [maxFileSize, setMaxFileSize] = useState(1);

  const [savingDraft, setSavingDraft] = useState(false);
  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ direction: "" }, { direction: "rtl" }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["link"],
      ["clean"],
    ],
  };
  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "background",
    "link",
  ];

  const initialValues = {
    companyName: "",
    crNumber: "",
    activeSince: "",
    managerName: "",
    contactFirstName: "",
    contactLastName: "",
    contactJobTitle: "",
    contactEmail: parsed.email,
    countryId: "",
    cityId: "",
    region: "",
    numberOfEmployees: "",
    address: "",
    logo: "",
    website: "",
    linkedin: "",
    companyInfoShowTo: "All",
    contactInfoShowTo: "All",
    overview: "",
    spNeeds: "",
  };

  const [lebanonCountryID, setLebanonCountryID] = useState("");
  const [industriesOnly, setIndustriesOnly] = useState([]);
  const [servicesOnly, setServicesOnly] = useState([]);
  const servicesMultiselectRef = React.createRef();

  const [companyDesc, setCompanyDescription] = useState("");
  const validationSchema = Yup.object().shape({
    startupStage:
      user != null
        ? user.profileTypeView != null
          ? user.profileTypeView.profileTypeDescription ===
              AccountTypes.Provider && user.profile.spType === "Start Up"
            ? Yup.string().required(
                t("createCompanyProfile.StartupStage.error")
              )
            : ""
          : ""
        : "",
    companyName: Yup.string().required(
      t("createCompanyProfile.companyName.error")
    ),
    crNumber:
      user !== null &&
      user.profileTypeView !== null &&
      user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
      user.profile.spType === "Start Up" &&
      startupStage === "Seed stage"
        ? Yup.string()
            .required(t("createCompanyProfile.StartupStage.error"))
            .test(
              "len",
              t("createCompanyProfile.crNumber.error"),
              (val) => !val || val.length <= 21
            )
        : Yup.string()
            .required(t("createCompanyProfile.crNumber.error"))
            .test(
              "len",
              t("crNumber.length"),
              (val) => !val || val.length <= 21
            ),
    managerName: Yup.string().required(
      t("createCompanyProfile.managerName.error")
    ),
    contactFirstName: Yup.string().required(
      t("createCompanyProfile.contactFname.error")
    ),
    contactLastName: Yup.string().required(
      t("createCompanyProfile.contactLname.error")
    ),
    contactJobTitle: Yup.string().required(
      t("createCompanyProfile.contactJob.error")
    ),
    contactEmail: Yup.string().required(
      t("createCompanyProfile.contactEmail.error")
    ),
    countryId: Yup.string().required(t("createCompanyProfile.country.error")),
    cityId: Yup.string().required(t("createCompanyProfile.city.error")),
    numberOfEmployees: Yup.string().required(
      t("createCompanyProfile.numberOfEmployees.error")
    ),
  });

  const [loading, setLoading] = useState(true);
  const [imageAlert, setImageAlert] = useState(false);

  function validateImage() {
    //Get reference of File.
    var fileUpload = document.getElementById("companyLogo");

    //Check whether the file is valid Image.
    var regex = new RegExp(
      "([a-zA-Z0-9s_\\.-:() ])+(.jpg|.png|.gif|.JPG|.PNG|.JPEG|.jpeg)$"
    );

    //const size = fileUpload.files[0].size;
    //const finalSize = Math.round(size / 1024);
    if (regex.test(fileUpload.value.toLowerCase())) {
      //Check whether HTML5 is supported.
      if (typeof fileUpload.files != "undefined") {
        //Initiate the FileReader object.
        var reader = new FileReader();
        //Read the contents of Image File.
        reader.readAsDataURL(fileUpload.files[0]);
        reader.onload = function (e) {
          //Initiate the JavaScript Image object.
          var image = new Image();

          //Set the Base64 string return from FileReader as source.
          image.src = e.target.result;

          //Validate the File Height and Width.
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            // if (height > 500 || width > 500) {
            //   //show width and height to user
            //   // document.getElementById("width").innerHTML = width;
            //   // document.getElementById("height").innerHTML = height;
            //   setError(t("logo.validateSize"));
            //   setErrorAlert(!errorAlert);
            //   setImageValidation(false);
            //   return false;
            // }
            // if (finalSize > 1024) {
            //   setError(t("fileSize"));
            //   setErrorAlert(!errorAlert);
            //   setImageValidation(false);
            //   return false;
            // }
            setImageValidation(true);
            return true;
          };
        };
        return imageValidation;
      } else {
        setError(t("logo.browserSupport"));
        setErrorAlert(!errorAlert);
        return false;
      }
    } else {
      setError(t("logo.validate"));
      setErrorAlert(!errorAlert);
      return false;
    }
  }

  function validateDocument() {
    //Get reference of File.
    var files = document.getElementsByName("fileDocs");

    //Check whether the file is valid Image.
    var regex = new RegExp(
      "([a-zA-Z0-9s_\\.-:() ])+(.jpg|.png|.gif|.JPG|.PNG|.JPEG|.jpeg|.pdf|.PDF)$"
    );
    var i;
    for (i = 0; i < files.length; i++) {
      const size = files[i].files[0].size;
      const finalSize = Math.round(size / 1024);
      if (regex.test(files[i].value.toLowerCase())) {
        //Check whether HTML5 is supported.
        if (typeof files[i].files != "undefined") {
          //Initiate the FileReader object.
          // return true;
        } else {
          setError(t("logo.browserSupport"));
          setErrorAlert(!errorAlert);
          return false;
        }
        if (finalSize > maxFileSize * 1024) {
          setError(`${t("fileSize")} ${maxFileSize} MB.`);
          setErrorAlert(!errorAlert);
          return false;
        }
      } else {
        setError(t("supportingDoc.validate"));
        setErrorAlert(!errorAlert);
        return false;
      }
    }
    return true;
  }

  const verifyEmail = async () => {
    setLoading(true);
    if (parsed.fromemail === "true") {
      const verifyRequest = {
        token: parsed.token,
        email: parsed.email,
      };
      await profileService
        .VerifyProfileAsync(verifyRequest)
        .then(async (loggedUser) => {
          setUser(loggedUser);
          setYear(loggedUser.profile.activeSince);
          setSelectedList(loggedUser.typeOfIndustry);
          if (loggedUser.typeOfIndustry != null) {
            for (var i = 0; i < loggedUser.typeOfIndustry.length; i++) {
              if (
                !selectedIndustryList.some(
                  (industry) =>
                    industry.typeOfIndustryId ===
                    loggedUser.typeOfIndustry[i].typeOfIndustryId
                )
              ) {
                selectedIndustryList.push({
                  typeOfIndustryId:
                    loggedUser.typeOfIndustry[i].typeOfIndustryId,
                  typeOfIndustryName:
                    loggedUser.typeOfIndustry[i].typeOfIndustryName,
                });
              }
            }
          }

          await fetchData();
          setVerficationAlert(true);
        })
        .catch((error) => {
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setVerificationErrorMessage(error);
          }
          setErrorVerificationAlert(true);
        });
    } else {
      setUser(profileService.userValue);
      setSelectedList(user?.typeOfIndustry);
      if (user.profile !== null && user.profile.activeSince !== null) {
        setYear(user.profile.activeSince);
      }

      // after login

      if (user.typeOfIndustry !== null) {
        for (var i = 0; i < user.typeOfIndustry.length; i++) {
          if (
            !selectedIndustryList.some(
              (industry) =>
                industry.typeOfIndustryId ===
                user.typeOfIndustry[i].typeOfIndustryId
            )
          ) {
            selectedIndustryList.push({
              typeOfIndustryId: user.typeOfIndustry[i].typeOfIndustryId,
              typeOfIndustryName: user.typeOfIndustry[i].typeOfIndustryName,
            });
          }
        }
      }

      await fetchData();
    }
    setLoading(false);
  };

  const fetchData = async () => {
    await numberOfEmpService.GetAllNumberOfEmployeesAsync().then((x) => {
      setEmpNB(x);
    });

    await profileService.GetAllSelectedProfiles().then((x) => {
      setPartners(x);
    });

    await systemSetupService.GetFileValidationSettingsAsync().then((x) => {
      x.map((settings) => {
        if (settings.description === FileSettings.MaxNbUploads) {
          setMaxFileUpload(settings.value);
        }
        if (settings.description === FileSettings.MaxFileSize) {
          setMaxFileSize(settings.value);
        }
      });
    });
    console.log(spNeeds);
    await countryService.GetCountryByLanguageAsync("en").then((x) => {
      setCountries(x);
      x &&
        x.map(async (country) => {
          if (country.description === "Lebanon") {
            await setLebanonCountryID(country.countryId);
          }
        });
    });

    await industryService
      .GetIndustriesServicesByLanguageAsync("en")
      .then((x) => {
        var servicesList = [];
        setIndustries(x);
        if (x !== null) {
          for (var i = 0; i < x.length; i++) {
            if (
              !industriesOnly.some(
                (industry) =>
                  industry.typeOfIndustryId === x[i].typeOfIndustryId
              )
            ) {
              industriesOnly.push({
                typeOfIndustryId: x[i].typeOfIndustryId,
                typeOfIndustryName: x[i].typeOfIndustryName,
              });
            }

            if (user !== null) {
              if (
                user.profile &&
                user.profile.typeOfIndustryId &&
                user.profile.typeOfIndustryId.includes(x[i].typeOfIndustryId)
              ) {
                servicesList.push({
                  typeOfIndustryId: x[i].typeOfIndustryId,
                  typeOfIndustryName: x[i].typeOfIndustryName,
                  typeOfServiceId: x[i].typeOfServiceId,
                  typeOfServiceName: x[i].typeOfServiceName,
                });
              }
            }
          }
          setServicesOnly(servicesList);
        }
      });

    await documentService.GetDocumentTypeByLanguageAsync("en").then((x) => {
      setDocumentTypeList(x);
    });
  };

  useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    verifyEmail();
    console.log(spNeeds);
    // async function onload() {
    //   await verifyEmail();
    // }
    // onload();

    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  useEffect(() => {
    const link = document.querySelector(".ql-link");
    const tooltip = document.querySelector(".tooltip-link");
    const btn = document.querySelector(".ql-action");
    const btnRmv = document.querySelector(".ql-remove");
    console.log(btn);
    if (link != null) {
      link.addEventListener("mouseover", () => {
        tooltip.style.display = "block";
      });

      link.addEventListener("mouseout", () => {
        tooltip.style.display = "none";
      });
    }
    if (btn != null) {
      btn.addEventListener("click", () => {
        if (!btn.previousSibling.value.startsWith("http")) {
          setError(t("link.error"));
          setErrorAlert(!errorAlert);
        }
      });
    }
  });

  useEffect(() => {
    fetchData();
  }, [currentLan]);

  function base64StringtoFile(base64String, filename) {
    var arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  function handleCompleteCrop() {
    const canvasRef = previewCanvasRef.current;
    const imageData64 = canvasRef.toDataURL(logoPath.type);
    const myFilename = logoPath.name;

    // file to be uploaded
    const myNewCroppedFile = base64StringtoFile(imageData64, myFilename);
    return myNewCroppedFile;
  }

  function onCrop(c) {
    if (
      completedCropRef.current == null ||
      previewCanvasRef.current == null ||
      imgRef.current == null
    ) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = c;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    //ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    if (list.length === 0) {
      setInputList([{ phone: "" }]);
    }
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { phone: "" }]);
  };

  const handleDocInputChange = (e, index) => {
    //    const { name, value } = e.target;
    const list = [...docInputList];
    list[index]["document"] = e;
    setDocInputList(list);
  };

  const handleDocTypeInputChange = (e, index) => {
    //    const { name, value } = e.target;
    const list = [...docInputList];
    list[index]["documentType"] = e;
    setDocInputList(list);
  };

  // handle click event of the Remove button
  const handleDocRemoveClick = (index) => {
    const list = [...docInputList];
    const newCount = documentCount - 1;
    // list.splice(index, 1);
    list[index].display = false;

    if (index === 0) {
      document.getElementById(`documentFile${index}`).value = "";
    }
    setDocInputList(list);
    let newList = [];
    list.map((x) => {
      if (x.display === true) {
        newList.push(x);
      }
    });
    setDocShow(newList);
    if (newCount === 0) {
      setDocumentCount(1);
      setDocInputList([{ documentType: null, document: null, display: false }]);
    } else {
      setDocumentCount(newCount);
    }
  };

  // handle click event of the Add button
  const handleDocAddClick = () => {
    setDocInputList([
      ...docInputList,
      { documentType: "", document: "", display: true },
    ]);
    setDocShow([...docShow, { documentType: "", document: "", display: true }]);
    setDocumentCount(documentCount + 1);
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  function onInputClick(e) {
    e.target.value = null;
    setLogoAfterCrop(null);
  }

  function onLogoChange(e) {
    // if (e.target.files[0] != null) {
    //   setLogoPath(e.target.files[0]);
    //   setPreview(URL.createObjectURL(e.target.files[0]));
    // }
    // if (e.target.files.length === 0) {
    //   setPreview(null);
    // }
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setPreview(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setLogoPath(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
      setCrop({ unit: "%", width: 30, height: 30 });
      setImageAlert(!imageAlert);
    } else {
      setLogoAfterCrop(null);
      setLogoPath(null);
      setPreview(null);
    }
  }

  function SaveDraft(fields) {
    if (logoPath !== null && logoPath !== undefined) {
      const isValid = validateImage();
      if (imageValidation === false) {
        setSavingDraft(false);
        return null;
      }
    }

    if (
      docInputList.length > 0 &&
      docInputList[0].documentType !== null &&
      docInputList[0].documentType !== "" &&
      docInputList[0].document !== null &&
      docInputList[0].document !== "" &&
      docInputList[0].display === true
    ) {
      const isDocValid = validateDocument();

      if (isDocValid === false) {
        setSavingDraft(false);
        return null;
      }
    }

    if (selectedList !== null) {
      const isServicesValid = checkIfAllIndustriesAreSelected(
        selectedIndustryList,
        selectedList
      );
      if (isServicesValid === false) {
        setError(t("required.industries.services"));
        setErrorAlert(!errorAlert);
        setSavingDraft(false);
        return null;
      }
    }
    FillAndSendData(fields, setSavingDraft, true);
  }
  function onSubmit(fields, { setStatus, setSubmitting }) {
    if (logoPath !== null) {
      const isValid = validateImage();
      if (imageValidation === false) {
        return null;
      }
    }

    //  else {
    //   // const finalLogo = handleCompleteCrop();
    //   formData.append("logo", logoAfterCrop);
    // }

    if (
      docInputList.length > 0 &&
      docInputList[0].documentType !== null &&
      docInputList[0].documentType !== "" &&
      docInputList[0].document !== null &&
      docInputList[0].document !== "" &&
      docInputList[0].display === true
    ) {
      const isDocValid = validateDocument();

      if (isDocValid === false) {
        return null;
      }
    }

    if (selectedList === null || selectedList.length === 0) {
      setError(t("required.industries"));
      setErrorAlert(!errorAlert);
      return null;
    }

    const isServicesValid = checkIfAllIndustriesAreSelected(
      selectedIndustryList,
      selectedList
    );
    if (isServicesValid === false) {
      setError(t("required.industries.services"));
      setErrorAlert(!errorAlert);
      return null;
    }

    setStatus();
    FillAndSendData(fields, setSubmitting, false);
  }

  async function FillAndSendData(fields, setSubmitting, isDraft) {
    const formData = new FormData();

    formData.append("isDraft", isDraft);
    if (logoPath !== null) {
      formData.append("logo", logoAfterCrop);
    }

    if (fields.companyName !== null) {
      formData.append("companyName", fields.companyName);
    }
    if (fields.crNumber !== null) {
      formData.append("crNumber", fields.crNumber);
    }
    if (year !== null) {
      formData.append("activeSince", year);
    }
    if (companyDesc !== null) {
      formData.append("overview", companyDesc);
    }
    if (fields.managerName !== null) {
      formData.append("managerName", fields.managerName);
    }

    if (fields.contactFirstName !== null) {
      formData.append("contactFirstName", fields.contactFirstName);
    }

    if (fields.contactLastName !== null) {
      formData.append("contactLastName", fields.contactLastName);
    }

    if (fields.contactEmail !== null) {
      formData.append("contactEmail", fields.contactEmail);
    }

    if (fields.contactJobTitle !== null) {
      formData.append("contactJobTitle", fields.contactJobTitle);
    }

    inputList.forEach((item) => {
      if (item.phone !== "" && item.phone !== null) {
        formData.append("phoneNumber", item.phone);
      }
    });
    if (fields.incubator != null) {
      formData.append("incubator", fields.incubator);
    }
    formData.append("countryId", fields.countryId);
    if (fields.openForPartnership != null) {
      formData.append("openForPartnership", fields.openForPartnership);
    }
    if (fields.financing !== null && fields.financing !== "") {
      formData.append("financing", fields.financing);
    }
    if (fields.startupStage !== null && fields.startupStage !== "") {
      formData.append("startupStage", fields.startupStage);
    }
    if (fields.cityId !== null) {
      formData.append("cityId", fields.cityId);
    }

    if (fields.region !== null) {
      formData.append("region", fields.region);
    }

    if (fields.address !== null) {
      formData.append("address", fields.address);
    }

    if (fields.website !== null) {
      formData.append("website", fields.website);
    }

    if (fields.linkedIn !== null) {
      formData.append("linkedIn", fields.linkedin);
    }

    formData.append("numberOfEmployees", fields.numberOfEmployees);
    if (selectedList !== null) {
      selectedList.forEach((item) => {
        formData.append("TypeOfIndustryId", item.typeOfIndustryId);
        formData.append("TypeOfServiceId", item.typeOfServiceId);
      });
    }

    if (fields.companyInfoShowTo !== null) {
      formData.append("companyInfoShowTo", fields.companyInfoShowTo);
    }

    if (fields.contactInfoShowTo !== null) {
      formData.append("contactInfoShowTo", fields.contactInfoShowTo);
    }

    if (fields.zipOrPostalCode !== null) {
      formData.append("zipOrPostalCode", fields.zipOrPostalCode);
    }

    if (fields.affiliatedTo !== null && fields.affiliatedTo !== "") {
      formData.append("affiliatedTo", fields.affiliatedTo);
    }
    console.log(fields.spNeeds);
    if (spNeeds !== null && spNeeds !== "") {
      formData.append("spNeeds", spNeeds);
    }

    docInputList.forEach((item) => {
      if (
        item.display === true &&
        item.documentType !== null &&
        item.document !== null &&
        item.documentType !== "" &&
        item.document !== ""
      ) {
        formData.append("DocumentType", item.documentType);
        formData.append("Document", item.document);
      }
    });
    formData.append("addMembership", true);
    profileService
      .SignUpStepTwoAsync(user.profile.userId, formData)
      .then(() => {
        setSubmitting(false);
        if (isDraft) {
          setDraftAlert(!draftAlert);
        } else {
          setAlert(!alert);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        if (
          error !== null &&
          error.response !== null &&
          error.response.data !== null &&
          error.response.data.message !== undefined
        ) {
          setError(error.response.data.message);
          if (
            error.response.data.error !== null &&
            error.response.data.error.length > 0
          ) {
            for (var i = 0; i < error.response.data.error.length; i++) {
              if (error.response.data.error[i].languageName == currentLan) {
                setError(error.response.data.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  function onChange(e) {
    setSelectedList(e);
  }

  function onIndustrySelectChangeRemove(e) {
    setSelectedIndustryList(e);
    //servicesMultiselectRef.current.resetSelectedValues();
    const list = [];
    if (e !== null) {
      e.map(async (industry) => {
        await list.push(industry.typeOfIndustryId);
      });
    }
    const newList = [];
    if (selectedList !== null) {
      selectedList.map((element) => {
        if (list.includes(element.typeOfIndustryId) === true) {
          newList.push(element);
        }
      });
      setSelectedList(newList);
    }

    const servicesList = [];
    industries &&
      industries.map((element) => {
        if (list.includes(element.typeOfIndustryId)) {
          servicesList.push(element);
        }
      });
    setServicesOnly(servicesList);
  }

  function onIndustrySelectChangeAdd(e) {
    setSelectedIndustryList(e);
    //servicesMultiselectRef.current.resetSelectedValues();
    const list = [];
    if (e !== null) {
      e.map(async (industry) => {
        await list.push(industry.typeOfIndustryId);
      });
    }
    const servicesList = [];
    industries &&
      industries.map((element) => {
        if (list.includes(element.typeOfIndustryId)) {
          servicesList.push(element);
        }
      });
    setServicesOnly(servicesList);
  }

  function checkIfAllIndustriesAreSelected(industryList, servicesList) {
    let foundIndustry = false;
    if (industryList !== null) {
      for (var i = 0; i < industryList.length; i++) {
        foundIndustry = false;
        for (var j = 0; j < servicesList.length; j++) {
          if (
            servicesList[j].typeOfIndustryId ===
            industryList[i].typeOfIndustryId
          ) {
            foundIndustry = true;
          }
        }

        if (foundIndustry === false) {
          return false;
        }
      }
      return true;
    }
  }

  const checkForExistingCompany = async (companyName) => {
    const model = { companyName };
    await profileService
      .VerifyExistingCompanyAsync(model)
      .then((res) => {
        if (res === true) {
          setExistingCompany(true);
        } else {
          setExistingCompany(false);
        }
      })
      .catch((err) => {
        setCompanyErr(err.message);
      });
  };

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  } else {
    return (
      <>
        <div className="section section-signup">
          <Container style={{ marginTop: "-5%" }}>
            <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
              <div className="modal-header">
                <h5 className="modal-title">
                  {t("createCompanyProfile.success.title")}
                </h5>
              </div>
              <div className="modal-body" style={{ margin: "0 auto" }}>
                <p>{t("createCompanyProfile.success.body")}</p>
              </div>
              <div className="modal-footer">
                <Button
                  color="primary"
                  type="button"
                  style={{ margin: "auto" }}
                  onClick={(e) => {
                    setAlert(!alert);
                    if (
                      user != null &&
                      user.profileTypeView != null &&
                      user.profileTypeView.profileTypeDescription ===
                        AccountTypes.Seeker
                    ) {
                      sessionStorage.setItem("searchProviderMessage", "true");
                      history.push(`/seeker/search-provider`);
                    } else if (
                      user != null &&
                      user.profileTypeView != null &&
                      user.profileTypeView.profileTypeDescription ===
                        AccountTypes.Provider
                    ) {
                      history.push("/provider/search-project");
                    } else {
                      history.push({
                        pathname: "/partner/search-provider",
                        state: { fromPartner: true },
                      });
                    }
                  }}
                >
                  {t("close.button")}
                </Button>
              </div>
            </Modal>
            <Modal
              isOpen={draftAlert}
              style={rtl ? { textAlign: "right" } : {}}
            >
              <div className="modal-header">
                <h5 className="modal-title">{t("draftSuccessAlert")}</h5>
              </div>
              <div className="modal-body" style={{ margin: "0 auto" }}>
                <p>{t("draftSuccessAlertBody")}</p>
              </div>
              <div className="modal-footer">
                <Button
                  color="primary"
                  type="button"
                  style={{ margin: "auto" }}
                  onClick={(e) => {
                    setDraftAlert(!draftAlert);
                  }}
                >
                  {t("close.button")}
                </Button>
              </div>
            </Modal>
            <Modal
              isOpen={errorAlert}
              style={rtl ? { textAlign: "right" } : {}}
            >
              <div
                className="modal-header text-center"
                style={{ margin: "0 auto" }}
              >
                <h5 className="modal-title">{t("error.alert")}</h5>
              </div>
              <div className="modal-body col-md-12">
                <p>{errorMessage}</p>
              </div>
              <div className="modal-footer">
                <Button
                  color="danger"
                  type="button"
                  style={{ margin: "auto" }}
                  onClick={(e) => setErrorAlert(!errorAlert)}
                >
                  {t("close.button")}
                </Button>
              </div>
            </Modal>
            <Modal
              isOpen={imageAlert}
              style={rtl ? { textAlign: "right" } : {}}
            >
              <div
                className="modal-header text-center"
                style={{ margin: "0 auto" }}
              >
                <h5 className="modal-title">Edit Image</h5>
              </div>
              <div className="modal-body">
                <p>{t("croppingTool")}</p>
                <Row>
                  {preview !== null ? (
                    <ReactCrop
                      style={{ margin: "auto" }}
                      src={preview}
                      onImageLoaded={onLoad}
                      crop={crop}
                      onChange={(c) => setCrop(c)}
                      onComplete={(c) => {
                        setCompletedCrop(c);
                        completedCropRef.current = c;
                        onCrop(c);
                      }}
                    />
                  ) : null}
                </Row>
                <p>{t("previewTool")}</p>
                <Row>
                  <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                      margin: "auto",
                      width: Math.round(completedCrop?.width ?? 0),
                      height: Math.round(completedCrop?.height ?? 0),
                    }}
                  />
                </Row>
              </div>
              <div className="modal-footer">
                <Button
                  color="primary"
                  type="button"
                  style={{ margin: "auto" }}
                  onClick={(e) => {
                    setLogoAfterCrop(handleCompleteCrop());
                    setImageAlert(!imageAlert);
                  }}
                >
                  {t("done")}
                </Button>
              </div>
            </Modal>

            <Modal
              isOpen={verificationAlert}
              style={rtl ? { textAlign: "right" } : {}}
            >
              <div className="modal-header" style={{ margin: "0 auto" }}>
                <h5 className="modal-title">
                  {t("verificationEmail.alert.title")}
                </h5>
              </div>
              <div className="modal-body">
                <p>{t("verificationEmail.alert.body")}</p>
              </div>
              <div className="modal-footer">
                <Button
                  color="primary"
                  type="button"
                  style={{ margin: "auto" }}
                  onClick={(e) => {
                    setVerficationAlert(!verificationAlert);
                    parsed.fromemail = "false";
                    const stringified = queryString.stringify(parsed);
                    location.search = stringified;
                  }}
                >
                  {t("close.button")}
                </Button>
              </div>
            </Modal>
            <Modal
              isOpen={errorVerificationAlert}
              style={rtl ? { textAlign: "right" } : {}}
            >
              <div className="modal-header" style={{ margin: "0 auto" }}>
                <h5 className="modal-title">{t("error.alert")}</h5>
              </div>
              <div className="modal-body col-md-12">
                <p>{VerificationErrorMessage}</p>
              </div>
              <div className="modal-footer">
                <Button
                  color="danger"
                  type="button"
                  style={{ margin: "auto" }}
                  onClick={(e) => {
                    setErrorVerificationAlert(!errorVerificationAlert);
                    history.push("/");
                  }}
                >
                  {t("close.button")}
                </Button>
              </div>
            </Modal>
            <>
              {user && (
                <Formik
                  initialValues={{
                    companyName:
                      user.profile && user.profile.companyName
                        ? user.profile.companyName
                        : "",
                    crNumber:
                      user.profile && user.profile.crNumber
                        ? user.profile.crNumber
                        : "",
                    activeSince:
                      user.profile && user.profile.activeSince
                        ? user.profile.activeSince
                        : "",
                    financing:
                      user.profile && user.profile.financing
                        ? user.profile.financing
                        : "",
                    startupStage:
                      user.profile && user.profile.startupStage
                        ? user.profile.startupStage
                        : "",
                    managerName:
                      user.profile && user.profile.managerFullName
                        ? user.profile.managerFullName
                        : "",
                    contactFirstName:
                      user.profile && user.profile.contactFirstName
                        ? user.profile.contactFirstName
                        : "",
                    contactLastName:
                      user.profile && user.profile.contactLastName
                        ? user.profile.contactLastName
                        : "",
                    contactJobTitle:
                      user.profile && user.profile.contactJobTitle
                        ? user.profile.contactJobTitle
                        : "",
                    contactEmail: parsed.email,
                    countryId:
                      user != null &&
                      user.profileTypeView != null &&
                      user.profileTypeView.profileTypeDescription ===
                        AccountTypes.Provider
                        ? lebanonCountryID
                        : user.profile !== null &&
                          user.profile.countryID !== null
                        ? user.profile.countryID
                        : "",
                    cityId:
                      user.profile && user.profile.cityID
                        ? user.profile.cityID
                        : "",
                    region:
                      user.profile && user.profile.region
                        ? user.profile.region
                        : "",
                    numberOfEmployees:
                      user.profile && user.profile.numberOfEmployeesId !== null
                        ? user.profile.numberOfEmployeesId
                        : "",
                    address:
                      user.profile && user.profile.address
                        ? user.profile.address
                        : "",
                    logo: "",
                    website: user.profile
                      ? user.profile && user.profile.website
                      : "",
                    linkedin:
                      user.profile && user.profile.linkedIn
                        ? user.profile.linkedIn
                        : "",
                    companyInfoShowTo: "All",
                    contactInfoShowTo: "All",
                    overview:
                      user.profile & user.profile.overview
                        ? user.profile.overview
                        : "",
                    affiliatedTo: "",
                    spNeeds: "",
                    zipOrPostalCode:
                      user.profile && user.profile.zipOrPostalCode
                        ? user.profile.zipOrPostalCode
                        : "",
                    incubator:
                      user.profile && user.profile.incubator !== null
                        ? user.profile.incubator
                        : "",
                    openForPartnership:
                      user.profile && user.profile.openForPartnership
                        ? user.profile.openForPartnership
                        : false,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    setFieldValue,
                  }) => {
                    return (
                      <div>
                        <Card style={{ marginTop: "5%" }}>
                          <CardHeader className="text-center">
                            <CardTitle className="" tag="h4">
                              {t("createCompanyProfile.title")}
                            </CardTitle>
                          </CardHeader>
                          <Form
                            autoComplete="off"
                            className={rtl ? "text-right" : ""}
                          >
                            <CardBody>
                              <Container>
                                <center>
                                  <p
                                    style={{
                                      marginBottom: "30px",
                                      marginTop: "10px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {t("add.edit.profile.note")}
                                  </p>
                                </center>
                                <h4
                                  style={{
                                    marginTop: "0px",
                                  }}
                                >
                                  {t("createCompanyProfile.company.diviver")}
                                </h4>
                                <hr
                                  style={{
                                    borderTop: "1px solid #bbb",
                                    marginTop: "-1%",
                                  }}
                                ></hr>
                                <div
                                  className="form-row"
                                  style={{ marginTop: "3%" }}
                                >
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="companyName">
                                      {t("createCompanyProfile.companyName")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        position: "relative",
                                      }}
                                    >
                                      <Field
                                        autoFocus={true}
                                        onBlur={() =>
                                          checkForExistingCompany(
                                            values.companyName
                                          )
                                        }
                                        name="companyName"
                                        placeholder={t(
                                          "createCompanyProfile.companyName"
                                        )}
                                        type="text"
                                        className={
                                          rtl
                                            ? "text-right form-control" +
                                              (errors.companyName &&
                                              touched.companyName
                                                ? " is-invalid"
                                                : "")
                                            : "form-control" +
                                              (errors.companyName &&
                                              touched.companyName
                                                ? " is-invalid"
                                                : "")
                                        }
                                      />
                                      {existingCompany === false && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            right: "0.5rem",
                                          }}
                                        >
                                          <CheckCircleOutline color="primary" />
                                        </div>
                                      )}
                                    </div>

                                    {/* {companyErr ? (
                                      <div
                                        style={{
                                          fontSize: "80%",
                                          color: "#dc3545",
                                          paddingTop: "0.25rem",
                                        }}
                                      >
                                        {companyErr}
                                      </div>
                                    ) : null} */}

                                    {existingCompany ? (
                                      <div
                                        style={{
                                          fontSize: "80%",
                                          color: "#dc3545",
                                          paddingTop: "0.25rem",
                                        }}
                                      >
                                        Company Already Exists
                                      </div>
                                    ) : null}
                                    {errors.companyName &&
                                    touched.companyName ? (
                                      <ErrorMessage
                                        name="companyName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="managerName">
                                      {t("createCompanyProfile.managerName")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    <Field
                                      name="managerName"
                                      placeholder={t(
                                        "createCompanyProfile.managerName"
                                      )}
                                      type="text"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.managerName &&
                                            touched.managerName
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.managerName &&
                                            touched.managerName
                                              ? " is-invalid"
                                              : "")
                                      }
                                    />
                                    {errors.managerName &&
                                    touched.managerName ? (
                                      <ErrorMessage
                                        name="managerName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                </div>
                                <div className="form-row">
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="crNumber">
                                      {t("createCompanyProfile.crNumber")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    <label style={{ fontSize: "small" }}>
                                      {`(${t(
                                        "createCompanyProfile.StartupStage.error"
                                      )}
                                          )`}
                                    </label>

                                    <Field
                                      name="crNumber"
                                      placeholder={t(
                                        "createCompanyProfile.crNumber"
                                      )}
                                      type="text"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.crNumber && touched.crNumber
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.crNumber && touched.crNumber
                                              ? " is-invalid"
                                              : "")
                                      }
                                    />
                                    {errors.crNumber && touched.crNumber ? (
                                      <ErrorMessage
                                        name="crNumber"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="activeSince">
                                      {t("createCompanyProfile.activeSince")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    <YearPicker
                                      // defaultValue={t(
                                      //   "createCompanyProfile.activeSince.placeholder"
                                      // )}
                                      value={year}
                                      defaultValue={
                                        user.profile
                                          ? user.profile.activeSince
                                          : ""
                                      }
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      reverse
                                      required={true}
                                      onChange={(year) => {
                                        setYear(year);
                                      }}
                                      name={"activeSince"}
                                      classes={
                                        "font14px form-control" +
                                        (errors.activeSince &&
                                        touched.activeSince
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                  </FormGroup>
                                </div>
                                <div className="form-row">
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="openForPartnership">
                                      {t(
                                        "createCompanyProfile.openForPartnership"
                                      )}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      <br></br>
                                    </label>
                                    <Field
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      name="openForPartnership"
                                      placeholder={t(
                                        "createCompanyProfile.openForPartnership"
                                      )}
                                      as="select"
                                      className={
                                        rtl
                                          ? "text-right form-control"
                                          : "form-control"
                                      }
                                    >
                                      <option value={false} defaultValue>
                                        {t("label.no")}
                                      </option>
                                      <option value={true}>
                                        {t("label.yes")}
                                      </option>
                                    </Field>
                                  </FormGroup>
                                  {user !== null &&
                                  user.profileTypeView !== null &&
                                  user.profileTypeView
                                    .profileTypeDescription !== null &&
                                  user.profileTypeView
                                    .profileTypeDescription ===
                                    AccountTypes.Provider ? (
                                    <FormGroup className="col-md-6">
                                      <label htmlFor="financing">
                                        {t("createCompanyProfile.Financing")}
                                      </label>
                                      <label
                                        style={{
                                          fontSize: "25px",
                                          color: "red",
                                        }}
                                        htmlFor="projectTypeID"
                                      >
                                        <br></br>
                                      </label>
                                      <Field
                                        style={{
                                          fontSize: "14px",
                                        }}
                                        name="financing"
                                        placeholder={t(
                                          "createCompanyProfile.Financing"
                                        )}
                                        as="select"
                                        className={
                                          rtl
                                            ? "text-right form-control"
                                            : "form-control"
                                        }
                                      >
                                        <option value={false} defaultValue>
                                          {t("label.no")}
                                        </option>
                                        <option value={true}>
                                          {t("label.yes")}
                                        </option>
                                      </Field>
                                    </FormGroup>
                                  ) : (
                                    ""
                                  )}
                                  {/* {rtl ? (
                                    <FormGroup className="col-md-6"></FormGroup>
                                  ) : (
                                    <></>
                                  )} */}
                                  {
                                    <FormGroup className="col-md-6">
                                      <label htmlFor="city">
                                        {t("affiliatedTo")}
                                      </label>
                                      <label
                                        style={{
                                          fontSize: "25px",
                                          color: "red",
                                        }}
                                        htmlFor="projectTypeID"
                                      >
                                        *
                                      </label>
                                      <label
                                        style={{
                                          fontSize: "25px",
                                          color: "red",
                                        }}
                                        htmlFor="projectTypeID"
                                      >
                                        <br></br>
                                      </label>

                                      <Field
                                        name="affiliatedTo"
                                        placeholder={t("affiliatedTo")}
                                        as="select"
                                        className={
                                          rtl
                                            ? "text-right form-control" +
                                              (errors.affiliatedTo &&
                                              touched.affiliatedTo
                                                ? " is-invalid"
                                                : "")
                                            : "form-control" +
                                              (errors.affiliatedTo &&
                                              touched.affiliatedTo
                                                ? " is-invalid"
                                                : "")
                                        }
                                      >
                                        <option value="" defaultValue>
                                          {t("None")}
                                        </option>
                                        {partners &&
                                          partners.map((partner) => (
                                            <option
                                              key={partner._id}
                                              value={partner._id}
                                            >
                                              {partner.companyName}
                                            </option>
                                          ))}
                                      </Field>

                                      {errors.affiliatedTo &&
                                      touched.affiliatedTo ? (
                                        <ErrorMessage
                                          name="cityId"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      ) : (
                                        <span
                                          className="isValid"
                                          component="div"
                                        >
                                          <br></br>
                                        </span>
                                      )}
                                    </FormGroup>
                                  }
                                </div>
                                {user != null &&
                                user.profileTypeView != null &&
                                user.profileTypeView.profileTypeDescription ===
                                  AccountTypes.Provider &&
                                user.profile.spType === "Start Up" ? (
                                  <div className="form-row">
                                    {/* <FormGroup className="col-md-6">
                                      <label htmlFor="startupStage">
                                        {t("createCompanyProfile.StartupStage")}
                                        *
                                      </label>
                                      <Field
                                        name="startupStage"
                                        onChange={(v) => {
                                          setStartupStage(v.target.value);
                                          setFieldValue("startupStage", v.target.value)
                                        }}
                                        placeholder={t(
                                          "createCompanyProfile.StartupStage"
                                        )}
                                        as="select"
                                        className={
                                          "form-control" +
                                          (errors.startupStage &&
                                            touched.startupStage
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value={""} defaultValue>
                                          {t(
                                            "createCompanyProfile.selectOption"
                                          )}
                                        </option>
                                        <option value={"Seed stage"}>
                                          {t("seedStage")}
                                        </option>
                                        <option value={"Early stage"}>
                                          {t("earlyStage")}
                                        </option>
                                        <option value={"Later stage"}>
                                          {t("laterStage")}
                                        </option>
                                      </Field>
                                      {errors.startupStage &&
                                        touched.startupStage ? (
                                        <ErrorMessage
                                          name="startupStage"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      ) : (
                                        <span
                                          className="isValid"
                                          component="div"
                                        >
                                          <br></br>
                                        </span>
                                      )}
                                    </FormGroup> */}
                                    <FormGroup className="col-md-6">
                                      <label htmlFor="incubator">
                                        {t("createCompanyProfile.incubator")}
                                      </label>
                                      <label
                                        style={{
                                          fontSize: "25px",
                                          color: "red",
                                        }}
                                        htmlFor="projectTypeID"
                                      >
                                        <br></br>
                                      </label>
                                      <Field
                                        name="incubator"
                                        placeholder={t(
                                          "createCompanyProfile.incubator"
                                        )}
                                        type="text"
                                        className={
                                          rtl
                                            ? "text-right form-control"
                                            : "form-control"
                                        }
                                      />
                                    </FormGroup>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="form-row">
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="industry">
                                      {t("industries")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    {industriesOnly && (
                                      <Multiselect
                                        hidePlaceholder={true}
                                        style={
                                          rtl
                                            ? {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },

                                                option: {
                                                  textAlign: "right",
                                                },
                                              }
                                            : {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },
                                              }
                                        }
                                        options={industriesOnly}
                                        placeholder={t(
                                          "industries.placeholder"
                                        )}
                                        displayValue="typeOfIndustryName"
                                        //     groupBy="typeOfIndustryName"
                                        closeOnSelect={false}
                                        showCheckbox={true}
                                        onSelect={(e) => {
                                          onIndustrySelectChangeAdd(e);
                                        }}
                                        onRemove={(e) => {
                                          onIndustrySelectChangeRemove(e);
                                        }}
                                        selectedValues={selectedIndustryList}
                                      />
                                    )}
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  </FormGroup>
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="industry">
                                      {t("services")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    {servicesOnly && (
                                      <Multiselect
                                        hidePlaceholder={true}
                                        style={
                                          rtl
                                            ? {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },

                                                option: {
                                                  textAlign: "right",
                                                },
                                              }
                                            : {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },
                                              }
                                        }
                                        options={servicesOnly}
                                        placeholder={t("services.placeholder")}
                                        displayValue="typeOfServiceName"
                                        groupBy="typeOfIndustryName"
                                        closeOnSelect={false}
                                        showCheckbox={true}
                                        emptyRecordMsg={t("invalid.services")}
                                        ref={servicesMultiselectRef}
                                        selectedValues={selectedList}
                                        onSelect={(e) => {
                                          onChange(e);
                                        }}
                                        onRemove={(e) => {
                                          onChange(e);
                                        }}
                                      />
                                    )}
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  </FormGroup>
                                </div>

                                <div className="form-row">
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="website">
                                      {t("createCompanyProfile.website")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      <br></br>
                                    </label>
                                    <Field
                                      name="website"
                                      placeholder={t(
                                        "createCompanyProfile.website"
                                      )}
                                      type="text"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.website && touched.website
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.website && touched.website
                                              ? " is-invalid"
                                              : "")
                                      }
                                    />
                                    {errors.website && touched.website ? (
                                      <ErrorMessage
                                        name="website"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="linkedin">
                                      {t("createCompanyProfile.linkedin")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      <br></br>
                                    </label>
                                    <Field
                                      name="linkedin"
                                      placeholder={t(
                                        "createCompanyProfile.linkedin"
                                      )}
                                      type="text"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.linkedin && touched.linkedin
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.linkedin && touched.linkedin
                                              ? " is-invalid"
                                              : "")
                                      }
                                    />
                                    {errors.linkedin && touched.linkedin ? (
                                      <ErrorMessage
                                        name="linkedin"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                </div>
                                <div className="form-row">
                                  {/* {rtl ? (
                                    <FormGroup className="col-md-6"></FormGroup>
                                  ) : (
                                    <></>
                                  )} */}
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="numberOfEmployees">
                                      {t(
                                        "createCompanyProfile.numberOfEmployees"
                                      )}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    <Field
                                      name="numberOfEmployees"
                                      placeholder={t(
                                        "createCompanyProfile.numberOfEmployees"
                                      )}
                                      style={{ fontSize: "14px" }}
                                      as="select"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.numberOfEmployees &&
                                            touched.numberOfEmployees
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.numberOfEmployees &&
                                            touched.numberOfEmployees
                                              ? " is-invalid"
                                              : "")
                                      }
                                    >
                                      <option
                                        value=""
                                        disabled
                                        defaultValue
                                        hidden
                                      >
                                        {t(
                                          "createCompanyProfile.numberOfEmployees.placeholder"
                                        )}
                                      </option>
                                      {empNB &&
                                        empNB.map((emp) => (
                                          <option key={emp.id} value={emp.id}>
                                            {emp.description}
                                          </option>
                                        ))}
                                    </Field>
                                    {errors.numberOfEmployees &&
                                    touched.numberOfEmployees ? (
                                      <ErrorMessage
                                        name="numberOfEmployees"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                </div>
                                <div className="form-row">
                                  {/* {rtl ? (
                                    <FormGroup className="col-md-6"></FormGroup>
                                  ) : (
                                    <></>
                                  )} */}
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="logo">
                                      {t("createCompanyProfile.logo")}{" "}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      <br></br>
                                    </label>
                                    <i
                                      id="logo"
                                      className="fa fa-info-circle"
                                      style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      <UncontrolledTooltip
                                        placement="right"
                                        target="#logo"
                                        delay={0}
                                      >
                                        {t("preferreImgSize.tooltip")}
                                      </UncontrolledTooltip>
                                    </i>
                                    <input
                                      id="companyLogo"
                                      name="logo"
                                      type="file"
                                      accept="image/*"
                                      className={"form-control"}
                                      onChange={(event) => {
                                        onLogoChange(event);
                                      }}
                                      onClick={(e) => onInputClick(e)}
                                    />
                                  </FormGroup>
                                  {logoAfterCrop !== null ? (
                                    <center>
                                      <img
                                        className="photo-container"
                                        alt="..."
                                        width="123px"
                                        height="123px"
                                        style={{
                                          objectFit: "contain",
                                          margin: "0 0 auto auto",
                                          borderRadius: "0%",
                                        }}
                                        src={URL.createObjectURL(logoAfterCrop)}
                                      ></img>
                                    </center>
                                  ) : null}
                                  {/* {preview != null ? (
                                    <img
                                      src={preview}
                                      alt="Company Logo"
                                      width="90px"
                                      height="90px"
                                      style={{
                                        objectFit: "contain",
                                      }}
                                    />
                                  ) : null} */}
                                  {/* {preview !== null ? (
                                    <ReactCrop
                                      src={preview}
                                      onImageLoaded={onLoad}
                                      crop={crop}
                                      onChange={(c) => setCrop(c)}
                                      onComplete={(c) => {
                                        setCompletedCrop(c);
                                        onCrop(c);
                                      }}
                                    />
                                  ) : null}

                                  <canvas
                                    ref={previewCanvasRef}
                                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                    style={{
                                      margin: "auto",
                                      width: Math.round(
                                        completedCrop?.width ?? 0
                                      ),
                                      height: Math.round(
                                        completedCrop?.height ?? 0
                                      ),
                                    }}
                                  /> */}
                                </div>

                                <div className="form-row">
                                  <FormGroup className="col-md-12">
                                    <label htmlFor="overview">
                                      {t("createCompanyProfile.overview")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      <br></br>
                                    </label>
                                    <textarea
                                      defaultValue={
                                        user.profile
                                          ? user.profile.overview
                                          : ""
                                      }
                                      name="overview"
                                      rows="3"
                                      placeholder={t(
                                        "createCompanyProfile.overview"
                                      )}
                                      onChange={(e) =>
                                        setCompanyDescription(e.target.value)
                                      }
                                      className={"form-control"}
                                    />
                                  </FormGroup>
                                </div>

                                {user != null &&
                                user.profileTypeView != null &&
                                user.profileTypeView.profileTypeDescription ===
                                  AccountTypes.Provider ? (
                                  <div className="form-row">
                                    <FormGroup className="col-md-12">
                                      <label htmlFor="spNeeds">
                                        {t("createCompanyProfile.spNeeds")}
                                      </label>

                                      <ReactQuill
                                        theme="snow"
                                        value={spNeeds}
                                        onChange={(
                                          e //console.log(e)
                                        ) => setSpNeeds(e)}
                                        modules={modules}
                                        formats={formats}
                                      />
                                      <div className="tooltip-link">
                                        {t("link")}
                                      </div>
                                    </FormGroup>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <h4>
                                  {t("createCompanyProfile.contact.diviver")}
                                </h4>
                                <hr
                                  style={{
                                    borderTop: "1px solid #bbb",
                                    marginTop: "-1%",
                                  }}
                                ></hr>
                                <div
                                  className="form-row"
                                  style={{ marginTop: "3%" }}
                                >
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="contactFirstName">
                                      {t("createCompanyProfile.contactFname")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    <Field
                                      name="contactFirstName"
                                      placeholder={t(
                                        "createCompanyProfile.contactFname"
                                      )}
                                      type="text"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.contactFirstName &&
                                            touched.contactFirstName
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.contactFirstName &&
                                            touched.contactFirstName
                                              ? " is-invalid"
                                              : "")
                                      }
                                    />
                                    {errors.contactFirstName &&
                                    touched.contactFirstName ? (
                                      <ErrorMessage
                                        name="contactFirstName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="contactLastName">
                                      {t("createCompanyProfile.contactLname")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    <Field
                                      name="contactLastName"
                                      placeholder={t(
                                        "createCompanyProfile.contactLname"
                                      )}
                                      type="text"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.contactLastName &&
                                            touched.contactLastName
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.contactLastName &&
                                            touched.contactLastName
                                              ? " is-invalid"
                                              : "")
                                      }
                                    />
                                    {errors.contactLastName &&
                                    touched.contactLastName ? (
                                      <ErrorMessage
                                        name="contactLastName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                </div>
                                <div className="form-row">
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="contactEmail">
                                      {t("createCompanyProfile.contactEmail")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    <Field
                                      name="contactEmail"
                                      placeholder={t(
                                        "createCompanyProfile.contactEmail"
                                      )}
                                      type="text"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.contactEmail &&
                                            touched.contactEmail
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.contactEmail &&
                                            touched.contactEmail
                                              ? " is-invalid"
                                              : "")
                                      }
                                    />
                                    {errors.contactEmail &&
                                    touched.contactEmail ? (
                                      <ErrorMessage
                                        name="contactEmail"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="contactJobTitle">
                                      {t("createCompanyProfile.contactJob")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    <Field
                                      name="contactJobTitle"
                                      placeholder={t(
                                        "createCompanyProfile.contactJob"
                                      )}
                                      type="text"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.contactJobTitle &&
                                            touched.contactJobTitle
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.contactJobTitle &&
                                            touched.contactJobTitle
                                              ? " is-invalid"
                                              : "")
                                      }
                                    />
                                    {errors.contactJobTitle &&
                                    touched.contactJobTitle ? (
                                      <ErrorMessage
                                        name="contactJobTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                </div>

                                <h4>
                                  {t("createCompanyProfile.address.diviver")}
                                </h4>
                                <hr
                                  style={{
                                    borderTop: "1px solid #bbb",
                                    marginTop: "-1%",
                                  }}
                                ></hr>
                                <div
                                  className="form-row"
                                  style={{ marginTop: "3%" }}
                                >
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="countryId">
                                      {t("createCompanyProfile.country")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    {user != null &&
                                      user.profileTypeView != null &&
                                      user.profileTypeView
                                        .profileTypeDescription ===
                                        AccountTypes.Provider && (
                                        <i
                                          id="disabledForSP"
                                          className="fa fa-question-circle"
                                          style={{
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                          }}
                                        >
                                          <UncontrolledTooltip
                                            placement="right"
                                            target="#disabledForSP"
                                            delay={0}
                                          >
                                            {t("country.disabledForSP.tooltip")}
                                          </UncontrolledTooltip>
                                        </i>
                                      )}
                                    <Field
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      name="countryId"
                                      placeholder={t(
                                        "createCompanyProfile.country"
                                      )}
                                      disabled={
                                        user != null &&
                                        user.profileTypeView != null &&
                                        user.profileTypeView
                                          .profileTypeDescription ===
                                          AccountTypes.Provider
                                      }
                                      as="select"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.countryId &&
                                            touched.countryId
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.countryId &&
                                            touched.countryId
                                              ? " is-invalid"
                                              : "")
                                      }
                                    >
                                      <option
                                        value=""
                                        disabled
                                        defaultValue
                                        hidden
                                      >
                                        {t(
                                          "createCompanyProfile.country.placeholder"
                                        )}
                                      </option>
                                      {countries &&
                                        countries.map((country) => (
                                          <option
                                            key={country.countryId}
                                            value={country.countryId}
                                          >
                                            {country.countryName}
                                          </option>
                                        ))}
                                    </Field>
                                    {errors.countryId && touched.countryId ? (
                                      <ErrorMessage
                                        name="countryId"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="city">
                                      {t("createCompanyProfile.city")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    <Field
                                      name="cityId"
                                      placeholder={t(
                                        "createCompanyProfile.city"
                                      )}
                                      type="text"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.cityId && touched.cityId
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.cityId && touched.cityId
                                              ? " is-invalid"
                                              : "")
                                      }
                                    />
                                    {errors.cityId && touched.cityId ? (
                                      <ErrorMessage
                                        name="cityId"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                </div>
                                <div className="form-row">
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="address">
                                      {t("createCompanyProfile.address")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      <br></br>
                                    </label>
                                    <Field
                                      name="address"
                                      placeholder={t(
                                        "createCompanyProfile.address"
                                      )}
                                      type="text"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.address && touched.address
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.address && touched.address
                                              ? " is-invalid"
                                              : "")
                                      }
                                    />
                                    {errors.address && touched.address ? (
                                      <ErrorMessage
                                        name="address"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="region">
                                      {t("createCompanyProfile.region")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      <br></br>
                                    </label>
                                    <Field
                                      name="region"
                                      placeholder={t(
                                        "createCompanyProfile.region"
                                      )}
                                      type="text"
                                      className={
                                        rtl
                                          ? "text-right form-control"
                                          : "form-control"
                                      }
                                    />
                                  </FormGroup>
                                </div>
                                <div className="form-row">
                                  {/* {rtl ? (
                                    <FormGroup className="col-md-6"></FormGroup>
                                  ) : (
                                    <></>
                                  )} */}
                                  <FormGroup className="col-md-6">
                                    <label htmlFor="city">
                                      {t("zip.postal.code")}
                                    </label>
                                    <label
                                      style={{ fontSize: "25px", color: "red" }}
                                      htmlFor="projectTypeID"
                                    >
                                      <br></br>
                                    </label>
                                    <Field
                                      name="zipOrPostalCode"
                                      placeholder={t("zip.postal.code")}
                                      type="text"
                                      className={
                                        rtl
                                          ? "text-right form-control" +
                                            (errors.zipOrPostalCode &&
                                            touched.zipOrPostalCode
                                              ? " is-invalid"
                                              : "")
                                          : "form-control" +
                                            (errors.zipOrPostalCode &&
                                            touched.zipOrPostalCode
                                              ? " is-invalid"
                                              : "")
                                      }
                                    />
                                    {errors.zipOrPostalCode &&
                                    touched.zipOrPostalCode ? (
                                      <ErrorMessage
                                        name="cityId"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    ) : (
                                      <span className="isValid" component="div">
                                        <br></br>
                                      </span>
                                    )}
                                  </FormGroup>
                                </div>
                                {inputList.map((x, i) => {
                                  return (
                                    <div className="form-row">
                                      <FormGroup className="col-md-4">
                                        <label htmlFor="phone">
                                          {t("createCompanyProfile.phone")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          <br></br>
                                        </label>
                                        <Field
                                          placeholder={t(
                                            "createCompanyProfile.phone"
                                          )}
                                          name="phone"
                                          type="text"
                                          className={
                                            rtl
                                              ? "text-right form-control"
                                              : "form-control"
                                          }
                                          value={x.phone}
                                          onChange={(e) =>
                                            handleInputChange(e, i)
                                          }
                                        />
                                      </FormGroup>
                                      {inputList.length > 0 && (
                                        <Button
                                          style={{
                                            height: "50%",
                                            marginTop: "43px",
                                          }}
                                          className="mr10"
                                          onClick={(x) => handleRemoveClick(i)}
                                          color="danger"
                                        >
                                          <i
                                            class="fa fa-minus"
                                            aria-hidden="true"
                                          ></i>
                                        </Button>
                                      )}
                                      {inputList.length - 1 === i && (
                                        <Button
                                          onClick={handleAddClick}
                                          style={{
                                            height: "50%",
                                            marginTop: "43px",
                                          }}
                                          color="primary"
                                        >
                                          <i
                                            class="fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                        </Button>
                                      )}
                                    </div>
                                  );
                                })}
                                {user != null &&
                                user.profileTypeView != null &&
                                (user.profileTypeView.profileTypeDescription ===
                                  AccountTypes.Seeker ||
                                  user.profileTypeView
                                    .profileTypeDescription ===
                                    AccountTypes.Partner) ? (
                                  <>
                                    <h4>
                                      {t("createCompanyProfile.privacyDetails")}{" "}
                                    </h4>
                                    <hr
                                      //  className="btn-primary"
                                      style={{
                                        borderTop: "1px solid #bbb",
                                        marginTop: "-1%",
                                      }}
                                    ></hr>
                                    <p>{t("privacyDetails.title")}</p>
                                    <ul>
                                      <li>
                                        <b>{t("all")}</b>{" "}
                                        {t("privacyDetails.one")}{" "}
                                      </li>
                                      <li>
                                        <b>{t("nda")}</b>{" "}
                                        {t("privacyDetails.two")}{" "}
                                      </li>
                                      <li>
                                        <b>{t("none")}</b>{" "}
                                        {t("privacyDetails.three")}{" "}
                                      </li>
                                    </ul>

                                    <div className="form-row">
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="companyInfoShowTo">
                                          {t(
                                            "createCompanyProfile.showCompanyInfo"
                                          )}{" "}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          <br></br>
                                        </label>
                                        <Field
                                          style={{
                                            fontSize: "14px",
                                          }}
                                          name="companyInfoShowTo"
                                          //  placeholder="Number of Employees"
                                          as="select"
                                          className={
                                            rtl
                                              ? "text-right form-control" +
                                                (errors.companyInfoShowTo &&
                                                touched.companyInfoShowTo
                                                  ? " is-invalid"
                                                  : "")
                                              : "form-control" +
                                                (errors.companyInfoShowTo &&
                                                touched.companyInfoShowTo
                                                  ? " is-invalid"
                                                  : "")
                                          }
                                        >
                                          {/* <option value="" disabled defaultValue hidden>
                                    {t("createCompanyProfile.selectOption")}
                                  </option> */}
                                          <option
                                            defaultValue
                                            defaultChecked
                                            value="All"
                                          >
                                            {t("all")}
                                          </option>
                                          <option value="NDA">
                                            {t("nda")}
                                          </option>
                                          <option value="None">
                                            {t("none")}
                                          </option>
                                        </Field>
                                      </FormGroup>

                                      <FormGroup className="col-md-6">
                                        <label htmlFor="contactInfoShowTo">
                                          {t(
                                            "createCompanyProfile.showContactInfo"
                                          )}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          <br></br>
                                        </label>
                                        <Field
                                          style={{
                                            fontSize: "14px",
                                          }}
                                          name="contactInfoShowTo"
                                          //  placeholder="Number of Employees"
                                          as="select"
                                          className={
                                            rtl
                                              ? "text-right form-control" +
                                                (errors.contactInfoShowTo &&
                                                touched.contactInfoShowTo
                                                  ? " is-invalid"
                                                  : "")
                                              : "form-control" +
                                                (errors.contactInfoShowTo &&
                                                touched.contactInfoShowTo
                                                  ? " is-invalid"
                                                  : "")
                                          }
                                        >
                                          <option
                                            defaultValue
                                            defaultChecked
                                            value="All"
                                          >
                                            {t("all")}
                                          </option>
                                          <option value="NDA">
                                            {t("nda")}
                                          </option>
                                          <option value="None">
                                            {t("none")}
                                          </option>
                                        </Field>
                                      </FormGroup>
                                    </div>
                                    {/* {
                                      values.companyInfoShowTo == "NDA" ?
                                        <div className="form-row">
                                          <FormGroup className="col-md-12">
                                            <label htmlFor="companyNDA">
                                              {t(
                                                "createCompanyProfile.companyNDA"
                                              )}
                                            </label>
                                            <ReactQuill
                                              theme="snow"
                                              value={companyNDA}
                                              onChange={(e) => setCompanyNDA(e)}
                                            />
                                          </FormGroup>
                                        </div> : ""
                                    } */}
                                  </>
                                ) : null}

                                <h4>
                                  {t("createCompanyProfile.supportingDocs")}{" "}
                                  <i
                                    id="docs"
                                    className="fa fa-question-circle"
                                  >
                                    <UncontrolledTooltip
                                      placement="right"
                                      target="#docs"
                                      delay={0}
                                    >
                                      {t("docs.tooltip")}
                                    </UncontrolledTooltip>
                                  </i>
                                </h4>
                                <hr
                                  //  className="btn-primary"
                                  style={{
                                    borderTop: "1px solid #bbb",
                                    marginTop: "-1%",
                                  }}
                                ></hr>
                                {docInputList.map((x, i) => {
                                  return x.display === true ? (
                                    <div className="form-row">
                                      <FormGroup className="col-md-6">
                                        <label htmlFor="companyInfoShowTo">
                                          {t("document.type.label")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          <br></br>
                                        </label>
                                        <Field
                                          style={{
                                            fontSize: "14px",
                                          }}
                                          name="documentType"
                                          //  placeholder="Number of Employees"
                                          as="select"
                                          value={x.documentType}
                                          className={
                                            rtl
                                              ? "text-right form-control" +
                                                (errors.companyInfoShowTo &&
                                                touched.companyInfoShowTo
                                                  ? " is-invalid"
                                                  : "")
                                              : "form-control" +
                                                (errors.companyInfoShowTo &&
                                                touched.companyInfoShowTo
                                                  ? " is-invalid"
                                                  : "")
                                          }
                                          onChange={(event) =>
                                            handleDocTypeInputChange(
                                              event.target.value,
                                              i
                                            )
                                          }
                                        >
                                          <option
                                            value={null}
                                            //  disabled
                                            defaultValue
                                            //hidden
                                          >
                                            {t(
                                              "createCompanyProfile.selectOption"
                                            )}
                                          </option>
                                          {documentTypeList &&
                                            documentTypeList.map((type) => (
                                              <option
                                                key={type.documentTypeId}
                                                value={type.documentTypeId}
                                              >
                                                {type.documentTypeName}
                                              </option>
                                            ))}
                                        </Field>
                                      </FormGroup>
                                      <FormGroup className="col-md-4">
                                        <label htmlFor="documents">
                                          {t("createCompanyProfile.uploadDoc")}
                                        </label>
                                        <label
                                          style={{
                                            fontSize: "25px",
                                            color: "red",
                                          }}
                                          htmlFor="projectTypeID"
                                        >
                                          <br></br>
                                        </label>
                                        <input
                                          //  placeholder="Phone Number"

                                          id={`documentFile${i}`}
                                          name="fileDocs"
                                          type="file"
                                          accept="image/*,.pdf"
                                          className={"form-control"}
                                          // value={x.documents}
                                          onChange={(event) =>
                                            handleDocInputChange(
                                              event.target.files[0],
                                              i
                                            )
                                          }
                                        />
                                      </FormGroup>
                                      {docInputList.length > 0 && (
                                        <Button
                                          style={{
                                            height: "50%",
                                            marginTop: "43px",
                                          }}
                                          className="mr10"
                                          onClick={(x) =>
                                            handleDocRemoveClick(i)
                                          }
                                          color="danger"
                                        >
                                          <i
                                            class="fa fa-minus"
                                            aria-hidden="true"
                                          ></i>
                                        </Button>
                                      )}
                                      {/* {docShow.length - 1 === i &&
                                          documentCount < 10 && (
                                            <Button
                                              onClick={handleDocAddClick}
                                              style={{
                                                height: "50%",
                                                marginTop: "43px",
                                              }}
                                              color="primary"
                                            >

                                              <i
                                                class="fa fa-plus"
                                                aria-hidden="true"
                                              ></i>
                                            </Button>
                                          )} */}
                                    </div>
                                  ) : null;
                                })}
                                <Button
                                  onClick={handleDocAddClick}
                                  disabled={documentCount === maxFileUpload}
                                  style={{
                                    height: "50%",
                                    marginTop: "0px",
                                  }}
                                  color="primary"
                                >
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </Button>
                                <div className="form-row">
                                  <FormGroup className="col-md-12">
                                    <label
                                      style={{
                                        fontSize: "25px",
                                        color: "red",
                                      }}
                                      htmlFor="projectTypeID"
                                    >
                                      *
                                    </label>
                                    <label>{t("requiredFields")}</label>
                                  </FormGroup>
                                </div>
                                <div className="form-row">
                                  <FormGroup className="col-md-12 text-center d-flex flex-column align-items-center justify-content-center">
                                    <Row>
                                      <Button
                                        outline
                                        color="primary"
                                        type="button"
                                        disabled={savingDraft}
                                        onClick={() => {
                                          setSavingDraft(true);
                                          SaveDraft(values);
                                        }}
                                      >
                                        {savingDraft && (
                                          <span className="spinner-border spinner-border-sm mr-1"></span>
                                        )}
                                        {t("saveAsDraft")}
                                      </Button>
                                      <Button
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                          if (
                                            Object.keys(errors).length !== 0
                                          ) {
                                            setError(t("fillMandatoryOrDraft"));
                                            setErrorAlert(!errorAlert);
                                          }
                                        }}
                                      >
                                        {isSubmitting && (
                                          <span className="spinner-border spinner-border-sm mr-1"></span>
                                        )}
                                        {t("createCompanyProfile.save")}
                                      </Button>
                                    </Row>
                                  </FormGroup>
                                </div>
                              </Container>
                            </CardBody>
                          </Form>
                        </Card>
                      </div>
                    );
                  }}
                </Formik>
              )}
            </>
          </Container>
        </div>
        {/* <DefaultFooter /> */}
      </>
    );
  }
}

export default CreateProfile;
