import { ResponsivePie } from "@nivo/pie";
import { analyticsService } from "_services";
import PulseLoader from "react-spinners/PulseLoader";
import React, { useState, useRef, useContext } from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
function SpsByCategoryPieChart() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [categoriesData, setCategoriesData] = useState([]);
  const [specialtiesData, setSpecialtiesData] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    await analyticsService.GetSpsByCategoryPieChartInputAsync().then((x) => {
      setCategoriesData(x);
    });

    setLoading(false);
  };

  const fetchSpecialtiesData = async (categoryId) => {
    setLoading(true);
    await analyticsService
      .GetSpsBySpecialtyPieChartInputAsync(categoryId)
      .then((x) => {
        setSpecialtiesData(x);
      });

    setLoading(false);
    executeScroll();
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  //   const startUpDistribution = [
  //     {
  //       id: "seed",
  //       label: "Seed Stage",
  //       value: 300,
  //       color: "hsl(331, 70%, 50%)",
  //     },
  //     {
  //       id: "early",
  //       label: "Early Stage",
  //       value: 59,
  //       color: "hsl(187, 70%, 50%)",
  //     },
  //     {
  //       id: "later",
  //       label: "Later Stage",
  //       value: 144,
  //       color: "hsl(261, 70%, 50%)",
  //     },
  //   ];
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();
  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <div className="section">
        <Container>
          <div style={{ height: "500px" }}>
            <h6 className="text-primary"> {t("SpsByCategory-PieChart")}</h6>
            <ResponsivePie
              data={categoriesData}
              margin={{ top: 40, right: 80, bottom: 100, left: 80 }}
              colors={{ scheme: "paired" }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
              onClick={(data) => {
                fetchSpecialtiesData(data["data"].data);
                console.log(` ${data["data"].data}  `);
              }}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              legends={[
                {
                  anchor: "bottom",
                  direction: "column",
                  justify: false,
                  translateX: -500,
                  translateY: 50,
                  itemsSpacing: 5,
                  itemWidth: 100,
                  itemHeight: 8,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          </div>

          <div ref={myRef} style={{ height: "500px" }}>
            <ResponsivePie
              data={specialtiesData}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              colors={{ scheme: "nivo" }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
              onClick={(data) => {}}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              legends={[
                {
                  anchor: "bottom",
                  direction: "column",
                  justify: false,
                  translateX: -500,
                  translateY: 50,
                  itemsSpacing: 10,
                  itemWidth: 100,
                  itemHeight: 8,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default SpsByCategoryPieChart;
