import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
} from "reactstrap";
import { Formik, Field, Form } from "formik";
import PulseLoader from "react-spinners/PulseLoader";
import { languageService } from "_services";
import { adminService } from "_services";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { LanguageContext } from "_context";

function AddEditVideo({ history, match, location }) {
  const isAddMode = !match.params.id;
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [languages, setLanguages] = useState([]);
  const [ourAim, setOurAim] = useState([]);
  const [languagesName, setLanguagesName] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [videoPath, setVideoPath] = useState(null);
  const [video, setVideo] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [initialValues, setInitialValues] = useState({
    videoPath: null,
    videoTitle: "",
  });
  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ direction: "" }, { direction: "rtl" }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["clean"],
    ],
  };
  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "background",
  ];

  function editInitialForm(translations, video) {
    var newJson = { description: "" };

    translations.map((x) => {
      var newKey = x.languageName;
      if (video.videoTitle != null) {
        for (var i = 0; i < video.videoTitle.length; i++) {
          if (x.languageID === video.videoTitle[i].languageID) {
            var newVal = video.videoTitle[i].name;
            newJson[newKey] = newVal;
            languagesName[i] = video.videoTitle[i].name;
          }
        }
        setLanguagesName(languagesName);
      }
    });
    setInitialValues(newJson);
  }

  console.log("isAddMode", isAddMode);

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();

    let list = [];
    var regex = /(<([^>]+)>)/gi;

    if (languages !== null) {
      for (var i = 0; i < languages.length; i++) {
        var hasText = !!languagesName[i].replace(regex, "").length;
        if (hasText === false) {
          setError(t("required.all"));
          setErrorAlert(!errorAlert);
          return false;
        }
        const translation = {
          languageID: languages[i].languageID,
          name: languagesName[i],
        };
        list.push(translation);
      }
    }

    const formData = new FormData();
    formData.append("VideoID", video != null ? video._id : "");
    formData.append("VideoTitle", JSON.stringify(list));
    formData.append("Video", videoPath);

    if (isAddMode) {
      await adminService
        .AddVideoAsync(formData)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    } else {
      await adminService
        .UpdateVideoAsync(formData)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    }
    setSubmitting(false);
  }

  const fetchData = async () => {
    setLoading(true);

    await languageService.GetAllLanguagesAsync().then(async (x) => {
      setLanguages(x);

      if (!isAddMode) {
        await adminService.GetVideoByIDAsync(match.params.id).then((res) => {
          setVideo(res);
          editInitialForm(x, res);
        });
      } else {
        setInitialValues({
          videoPath: null,
          videoTitle: "",
        });
      }
    });
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <div className={rtl ? "section text-right" : "section"}>
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("addVideo.title")}</h5>
          </div>
          <div className="modal-body">
            <p>{t("editVideo.body")}</p>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => {
                setAlert(!alert);
                if (!isAddMode) {
                  history.push("..");
                } else {
                  history.push(".");
                }
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div className="modal-body col-md-12">
            <p>{errorMessage}</p>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting, setFieldValue }) => {
              return (
                <Col md="12" style={{ margin: "auto" }}>
                  <Card style={rtl ? { textAlign: "right" } : {}}>
                    <Form autoComplete="off">
                      <CardBody>
                        <CardTitle className="text-center" tag="h4">
                          {t("admincms.video")}
                        </CardTitle>
                        <Row>
                          <Col md="12">
                            <div className="form-group">
                              <FormGroup
                                className="col-md-10"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <Field
                                  autoFocus={true}
                                  name="videoTitle"
                                  disabled={true}
                                  placeholder={t("video.title")}
                                  className={"form-control"}
                                />
                              </FormGroup>
                            </div>
                            {languages &&
                              languages.map((x, i) => {
                                return (
                                  <div className="form-group">
                                    <FormGroup
                                      className="col-md-10"
                                      style={{
                                        margin: "0 auto",
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "-2%",
                                      }}
                                    >
                                      <label
                                        style={{
                                          marginTop: "2rem",
                                        }}
                                        htmlFor="inputPassword4"
                                      >
                                        {x.languageDescription}
                                      </label>
                                      <ReactQuill
                                        style={{
                                          height: "200px",
                                          marginBottom: "6%",
                                          resize: "vertical",
                                        }}
                                        theme="snow"
                                        value={
                                          languagesName[i] === undefined
                                            ? null
                                            : languagesName[i]
                                        }
                                        onChange={(e) => {
                                          languagesName[i] = e;
                                          setLanguagesName(languagesName);
                                        }}
                                        modules={modules}
                                        formats={formats}
                                      />
                                    </FormGroup>
                                  </div>
                                );
                              })}
                            {!isAddMode ? (
                              <Row className="justify-content-center mb-2">
                                <video width="400" controls>
                                  <source
                                    src={video.videoPath}
                                    type="video"
                                  ></source>
                                </video>
                              </Row>
                            ) : (
                              <></>
                            )}
                            <div class="form-group">
                              <Row>
                                <Col md="12">
                                  <FormGroup
                                    className="col-md-8"
                                    style={{
                                      margin: "0 auto",
                                      width: "100%",
                                      padding: "10px",
                                      marginTop: "-2%",
                                    }}
                                  >
                                    <input
                                      id="videoPath"
                                      style={{ marginTop: "10px" }}
                                      name="videoPath"
                                      type="file"
                                      accept="video/mp4,video/x-m4v,video/*"
                                      className={"form-control "}
                                      onChange={(event) => {
                                        setVideoPath(event.target.files[0]);
                                        setFieldValue(
                                          "videoPath",
                                          event.target.files[0]
                                        );
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>

                            <div className="form-row">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <center>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {t("createCompanyProfile.save")}
                                  </Button>
                                </center>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default AddEditVideo;
