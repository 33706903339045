import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import PendingSurveyList from "./PendingSurveyList";

function Survey({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={PendingSurveyList} />
      </Switch>
    </div>
  );
}

export { Survey };
