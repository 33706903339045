import React, { useState } from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import { Paper, ThemeProvider } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { theme } from "../../../theme.js";
import Modal from "reactstrap/lib/Modal";
import { processService } from "_services/process.service.js";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  FileCopyIcon: forwardRef((props, ref) => (
    <FileCopyIcon {...props} ref={ref} />
  )),
};

function JobsList({ history, match, location }) {
  const { path } = match;
  const [jobs, setJobs] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  var columns = [
    {
      title: t("jobDescription"),
      field: "description",
      width: "5%",
    },
    {
      title: t("jobPeriodicity"),
      field: "periodicity",
    },
    {
      title: t("jobReccurrence"),
      field: "reccurrenceDay",
    },
    {
      title: t("jobEnabled"),
      field: "enabled",
    },
  ];
  const fetchData = async () => {
    setLoading(true);
    await processService.GetAllProcessAsync().then((x) => {
      setJobs(x);
    });

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <div className="section">
        <Container>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={{ margin: "auto" }}
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <ThemeProvider theme={theme}>
            <MaterialTable
              //  style={{ backgroundColor: "#2C2C34", color: "#d9dcde" }}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              title={t("jobsList")}
              columns={columns}
              data={jobs}
              icons={tableIcons}
              options={{
                paging: false,
                actionsColumnIndex: -1,
                cellStyle: {
                  textAlign: rtl ? "right" : "left",
                },
                headerStyle: {
                  textAlign: rtl ? "right" : "left",
                },
              }}
              localization={{
                header: {
                  actions: t("actions"),
                },
                toolbar: {
                  searchPlaceholder: t("search"),
                },
              }}
              actions={[
                {
                  icon: () => <Edit className="actionIcon" />,
                  tooltip: t("edit"),
                  onClick: (event, rowData) => {
                    history.push(`${path}/edit/${rowData.id}`, {
                      state: rowData,
                    });
                  },
                },
                {
                  icon: () => <FileCopyIcon className="actionIcon" />,
                  tooltip: t("viewLogs"),
                  onClick: (event, rowData) => {
                    history.push(`${path}/viewLogs/${rowData.id}`, {
                      state: rowData,
                    });
                  },
                },
              ]}
            />
          </ThemeProvider>
        </Container>
      </div>
    </>
  );
}

export default JobsList;
