import React, { useState } from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

import ProfilePageTopContent from "../../Profile/CompanyProfilePage/ProfilePageTopContent";
import { profileService } from "../../../_services";
import AddressDetails from "../../Profile/CompanyProfilePage/AddressDetails";
import Industries from "../../Profile/CompanyProfilePage/Industries";
import PulseLoader from "react-spinners/PulseLoader";
import Documents from "../../Profile/CompanyProfilePage/Documents";
import { useTranslation } from "react-i18next";
import CompanyDetailsSecure from "./CompanyDetailsSecure";
import ContactDetailsSecure from "./ContactDetailsSecure";
import ProfilePageTopContentSecure from "./ProfilePageTopContentSecure";
import AddressDetailsSecure from "./AddressDetailsSecure";
import { LanguageContext } from "_context";
import { useContext } from "react";
function PublishedByProfile({ history, match, location }) {
  const { path } = match;
  const profileID = match.params.profileID;
  const projectID = match.params.projectID;
  const user = profileService.userValue;
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const fetchData = async () => {
    setLoading(true);
    await profileService
      .GetProfileById(
        profileID,
        false,
        projectID,
        user.profile.userId,
        currentLan
      )
      .then((profile) => {
        setInfo(profile);
      });
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      {/* <PartnerNavbar props={history} /> */}
      <div className="">
        {info && (
          <>
            <ProfilePageTopContentSecure
              props={info}
              history={history}
              location={location}
            />
            <div className="section">
              <Container>
                <div className="button-container">
                  <Button
                    className="btn"
                    color="primary"
                    size="lg"
                    onClick={() =>
                      history.push({
                        pathname: `.`,
                        state: { fromSearch: location.state.fromSearch },
                      })
                    }
                  >
                    {t("back.opportunities")}
                  </Button>
                </div>
              </Container>
            </div>
            <Container style={{ marginTop: "-50px" }}>
              <Row>
                <Col md="6">
                  <CompanyDetailsSecure props={info} />
                  <AddressDetailsSecure props={info} />
                  <Industries props={info} />
                </Col>
                <Col md="6">
                  <>
                    <ContactDetailsSecure props={info} />
                  </>
                  <>
                    <Documents props={info} />
                  </>
                </Col>
              </Row>
            </Container>
            {/* <DefaultFooter /> */}
          </>
        )}
      </div>
    </>
  );
}

export default PublishedByProfile;
