import React, { useState, useEffect } from "react";

// reactstrap components
import { Button, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import { Link, useParams } from "react-router-dom";
import queryString from "query-string";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import Modal from "reactstrap/lib/Modal";
import { emailLogsService } from "_services";
import moment from "moment";
import { AlertTypes } from "_helpers";
import AlertsEmailLogsCard from "./AlertsEmailLogsCard";
import SurveyEmailLogsCard from "./SurveyEmailLogsCard";
import NewslettersEmailLogsCard from "./NewslettersEmailLogsCard";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import CardTitle from "reactstrap/lib/CardTitle";
import CardText from "reactstrap/lib/CardText";
// import TransactionsEmailLogsCard from "./TransactionsEmailLogsCard";
// import MembershipAlertsEmailLogsCard from "./MembershipAlertsEmailLogsCard";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
};

function EmailLogsList({ match, history, location }) {
  const { path } = match;
  const parsed = queryString.parse(location.search);

  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [iconPills, setIconPills] = React.useState(
    location.state != null
      ? location.state.type != null
        ? location.state.type == "Alert"
          ? "1"
          : location.state.type == "Newsletter"
          ? "2"
          : location.state.type == "Survey"
          ? "3"
          : location.state.type == "Transaction"
          ? "4"
          : "5"
        : "1"
      : "1"
  );

  const [alertsLogCount, setAlertsLogCount] = useState(null);
  const [directLinkAlertsLogCount, setDirectLinkAlertsLogCount] =
    useState(null);

  const [newslettersLogCount, setNewslettersLogCount] = useState(null);
  const [surveysLogCount, setSurveysLogCount] = useState(null);
  const [membershipLogCount, setMembershipLogCount] = useState(null);
  const [transactionLogCount, setTransactionLogCount] = useState(null);
  useEffect(() => {
    emailLogsService.GetAlertsLogCount().then((x) => {
      setAlertsLogCount(x);
    });
  }, []);

  useEffect(() => {
    emailLogsService.GetDirectLinksAlertsLogsCount().then((x) => {
      setDirectLinkAlertsLogCount(x);
    });
  }, []);

  useEffect(() => {
    emailLogsService.GetNewslettersLogCount().then((x) => {
      setNewslettersLogCount(x);
    });
  }, []);

  useEffect(() => {
    emailLogsService.GetSurveysCount().then((x) => {
      setSurveysLogCount(x);
    });
  }, []);
  useEffect(() => {
    emailLogsService.GetMembershipsCount().then((x) => {
      setMembershipLogCount(x);
    });
  }, []);
  useEffect(() => {
    emailLogsService.GetTransactionsCount().then((x) => {
      setTransactionLogCount(x);
    });
  }, []);
  // const fetchNewslettersData = async () => {
  //   // setLoading(true);
  //   let skipEmailLogs = begin.current;
  //   const model = {
  //     index: 2,
  //     emailLogsToSkip: skipEmailLogs,
  //     startingEmailLogs: emailLogsPerPage,
  //   };

  //   // await emailLogsService.GetEmailLogsAsync(model).then((x) => console.log(x));
  //   await emailLogsService.GetAllAsync(model).then((res) => {
  //     console.log(res);
  //     const tmpList = res.map((email) => ({
  //       email: email.userEmail,
  //       alertType:
  //         email.alertType == "NewOpp"
  //           ? AlertTypes.NewOpp
  //           : email.alertType == "ClosingDateOppSS"
  //           ? AlertTypes.NewOpp
  //           : email.alertType == "ClosingDateOppSP"
  //           ? AlertTypes.NewOpp
  //           : email.alertType == "AdminDispatch"
  //           ? AlertTypes.AdminDispatch
  //           : email.alertType,
  //       newsletter: email.asNewsletter[0].newsletterSubject,
  //       status:
  //         email.status == "P"
  //           ? "Pending"
  //           : email.status == "S"
  //           ? "Sent"
  //           : email.status == "F"
  //           ? "Failed"
  //           : email.status,
  //       reason: email.reason,
  //       emailLogsId: email.emailLogsId,
  //       date: moment(email.date).format("YYYY-MM-DD, h:mm:ss a"),
  //     }));
  //     setNewslettersLogs(tmpList);
  //   });
  // };

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  // if (loading) {
  //   return (
  //     <>
  //       <center
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           position: "absolute",
  //           left: "50%",
  //           top: "50%",
  //         }}
  //       >
  //         <PulseLoader color="#0E6EB8" />
  //       </center>
  //     </>
  //   );
  // }

  return (
    <>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>{errorMessage}</p>
        </div>

        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            style={
              rtl ? { margin: "auto", textAlign: "right" } : { margin: "auto" }
            }
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>

      <div className={rtl ? "section text-right" : "section"}>
        <Row>
          <Card style={{ boxShadow: "none" }}>
            <CardHeader>
              <div>
                {" "}
                <div className="Container">
                  <Link
                    style={{ margin: "50px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(".");
                    }}
                  >
                    {t("backToList")}
                  </Link>
                </div>
              </div>
              <Nav className="justify-content-center" role="tablist" tabs>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className={iconPills === "1" ? "active" : ""}
                    // href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      // await fetchAlertData();
                      setIconPills("1");
                    }}
                  >
                    {t("emaillogs.alerts")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className={iconPills === "2" ? "active" : ""}
                    //  href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      // await fetchNewslettersData();
                      setIconPills("2");
                    }}
                  >
                    {t("emaillogs.newsletters")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className={iconPills === "3" ? "active" : ""}
                    // href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      // await fetchAlertData();
                      setIconPills("3");
                    }}
                  >
                    {t("emaillogs.survey")}
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    tag={Link}
                    className={iconPills === "4" ? "active" : ""}
                    // href="#pablo"
                    onClick={async (e) => {
                      e.preventDefault();
                      // await fetchAlertData();
                      setIconPills("4");
                    }}
                  >
                    {t("emaillogs.transactions")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className={iconPills === "5" ? "active" : ""}
                    // href="#pablo"
                    onClick={async (e) => {
                      e.preventDefault();
                      // await fetchAlertData();
                      setIconPills("5");
                    }}
                  >
                    {t("emaillogs.memberships")}
                  </NavLink>
                </NavItem> */}
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={"iconPills" + iconPills}>
                <TabPane tabId="iconPills1">
                  {/* {alertsLogCount === 0 ? (
                    <Row>
                      <Card
                        className="text-center"
                        style={{ boxShadow: "none" }}
                      >
                        <CardBody>
                          <CardTitle tag="h4">{t("empty.emaillogs")}</CardTitle>
                        </CardBody>
                      </Card>
                    </Row>
                  ) : ( */}
                  <AlertsEmailLogsCard
                    tableIcons={tableIcons}
                    itemCount={alertsLogCount}
                    directLinkItemCount={directLinkAlertsLogCount}
                    getAlertsLogCount={() =>
                      emailLogsService.GetAlertsLogCount().then((x) => {
                        setAlertsLogCount(x);
                      })
                    }
                    getDirectLinkItemCount={() =>
                      emailLogsService
                        .GetDirectLinksAlertsLogsCount()
                        .then((x) => {
                          setDirectLinkAlertsLogCount(x);
                        })
                    }
                    changeItemCount={(count) => setAlertsLogCount(count)}
                    changeDirectLinkItemCount={(count) =>
                      setDirectLinkAlertsLogCount(count)
                    }
                    location={location}
                    history={history}
                    path={path}
                    type="Alert"
                  />
                  {/* )} */}
                </TabPane>
                <TabPane tabId="iconPills2">
                  {/* {newslettersLogCount === 0 ? (
                    <Row>
                      <Card
                        className="text-center"
                        style={{ boxShadow: "none" }}
                      >
                        <CardBody>
                          <CardTitle tag="h4">{t("empty.emaillogs")}</CardTitle>
                        </CardBody>
                      </Card>
                    </Row>
                  ) : ( */}
                  <NewslettersEmailLogsCard
                    tableIcons={tableIcons}
                    itemCount={newslettersLogCount}
                    changeItemCount={(count) => setNewslettersLogCount(count)}
                    getItemCount={() =>
                      emailLogsService.GetNewslettersLogCount().then((x) => {
                        setNewslettersLogCount(x);
                      })
                    }
                    location={location}
                    history={history}
                    path={path}
                    type="NewsLetter"
                  />
                  {/* )} */}
                </TabPane>
                <TabPane tabId="iconPills3">
                  {/* {surveysLogCount === 0 ? (
                    <Row>
                      <Card
                        className="text-center"
                        style={{ boxShadow: "none" }}
                      >
                        <CardBody>
                          <CardTitle tag="h4">{t("empty.emaillogs")}</CardTitle>
                        </CardBody>
                      </Card>
                    </Row>
                  ) : ( */}
                  <SurveyEmailLogsCard
                    tableIcons={tableIcons}
                    itemCount={surveysLogCount}
                    changeItemCount={(count) => setSurveysLogCount(count)}
                    getItemCount={() =>
                      emailLogsService.GetSurveysCount().then((x) => {
                        setSurveysLogCount(x);
                      })
                    }
                    location={location}
                    history={history}
                    path={path}
                    type="Survey"
                  />
                  {/* )} */}
                </TabPane>
                {/* <TabPane tabId="iconPills4">
                  // {surveysLogCount === 0 ? (
                  //   <Row>
                  //     <Card
                  //       className="text-center"
                  //       style={{ boxShadow: "none" }}
                  //     >
                  //       <CardBody>
                  //         <CardTitle tag="h4">{t("empty.emaillogs")}</CardTitle>
                  //       </CardBody>
                  //     </Card>
                  //   </Row>
                  // ) : (
                    <TransactionsEmailLogsCard
                      tableIcons={tableIcons}
                      itemCount={transactionLogCount}
                      changeItemCount={(count) => setTransactionLogCount(count)}
                      getItemCount={() => emailLogsService.GetTransactionsCount().then((x) => {
                        setTransactionLogCount(x);
                      })}
                      location={location}
                      history={history}
                      path={path}
                      type="Transaction"
                    />
                  // )}
                </TabPane>
                <TabPane tabId="iconPills5">
                  // {surveysLogCount === 0 ? (
                  //   <Row>
                  //     <Card
                  //       className="text-center"
                  //       style={{ boxShadow: "none" }}
                  //     >
                  //       <CardBody>
                  //         <CardTitle tag="h4">{t("empty.emaillogs")}</CardTitle>
                  //       </CardBody>
                  //     </Card>
                  //   </Row>
                  // ) : (
                    <MembershipAlertsEmailLogsCard
                      tableIcons={tableIcons}
                      itemCount={membershipLogCount}
                      changeItemCount={(count) => setMembershipLogCount(count)}
                      getItemCount={() => emailLogsService.GetMembershipsCount().then((x) => {
                        setMembershipLogCount(x);
                      })}
                      location={location}
                      history={history}
                      path={path}
                      type="Membership"
                    />
                  // )}
                </TabPane> */}
              </TabContent>
            </CardBody>
          </Card>
        </Row>
      </div>
    </>
  );
}

export default EmailLogsList;
