import React, { useState, useContext } from "react";
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Paper, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../theme.js";
import { adminService, profileService } from "_services";
import Modal from "reactstrap/lib/Modal";
import { LanguageContext } from "_context";
import { AccountTypes } from "_helpers";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import FormGroup from "reactstrap/lib/FormGroup";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { IconButton } from "@material-ui/core";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { searchService } from "_services";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  SubdirectoryArrowLeftIcon: forwardRef((props, ref) => (
    <SubdirectoryArrowLeftIcon {...props} ref={ref} />
  )),
  FileCopyIcon: forwardRef((props, ref) => (
    <FileCopyIcon {...props} ref={ref} />
  )),
  AccountBalanceIcon: forwardRef((props, ref) => (
    <AccountBalanceIcon {...props} ref={ref} />
  )),
  StarIcon: forwardRef((props, ref) => <StarIcon {...props} ref={ref} />),
  StarBorderIcon: forwardRef((props, ref) => (
    <StarBorderIcon {...props} ref={ref} />
  )),
};

function ServiceProvidersList({ history, match }) {
  const { path } = match;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowDataID, setRowDataID] = useState(null);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [sortByLabel, setSortByLabel] = useState(
    t("Featured Service Providers")
  );
  const [itemCount, setItemCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const resetSearch = React.useRef(false);
  var columns = [
    {
      title: t("admin.users.userName"),
      field: "profile.userName",
      width: "20%",
    },
    {
      title: t("admin.users.companyName"),
      field: "profile.companyName",
      width: "10%",
    },
    {
      title: t("admin.users.type"),
      field: "profileTypeView.profileTypeName",
      width: "10%",
    },
    {
      title: t("signUp.providerType.title"),
      field: "profile.spType",
      width: "10%",
    },
    {
      title: t("createCompanyProfile.StartupStage"),
      field: "profile.startupStage",
      width: "10%",
    },
    // {
    //   title: t("expirationDate"),
    //   field: "expirationDate",
    //   width: "10%",
    // },
    {
      title: t("admin.users.status"),
      field: "profile.profileStatus",
      width: "10%",
    },
    // {
    //   title: t("admin.users.activationDate"),
    //   field: "activationDate",
    //   width: "10%",
    // },
    // {
    //   title: t("uploadedBy"),
    //   field: "uploadedBy",
    //   width: "10%",
    // },
    // {
    //   title: t("uploadedFrom"),
    //   field: "profile.source",
    //   width: "10%",
    // },
  ];

  let [currentPage, setCurrentPage] = useState(0);
  const userPerPage = pageSize;
  const begin = React.useRef(0);
  const end = React.useRef(userPerPage);

  const count = Math.ceil(itemCount / userPerPage);

  function next() {
    if (end.current > itemCount) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
    begin.current = (currentPage + 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  const handleChange = (value) => {
    setPageSize(parseInt(value));
    end.current = begin.current + parseInt(value);
  };

  React.useEffect(() => {
    fetchCount();
  }, []);

  const handleSearch = async (e) => {
    // setSearchKeyword(e);
    const model = {
      Keyword: e,
      Featured: sortByLabel == t("Featured Service Providers") ? true : false,
      PageNumber: 0,
      PageSize: pageSize,
    };
    await searchService.SearchSPsAsync(model).then((res) => {
      setUsers(res);
      begin.current = 0;
      end.current = pageSize;
      setPageSize(pageSize);
    });
    await searchService.SearchSPsCountAsync(model).then((res) => {
      setItemCount(res);
    });
  };

  const fetchCount = async () => {
    if (sortByLabel == t("Featured Service Providers")) {
      await adminService.GetAllFeaturedProfilesCountAsync().then((res) => {
        console.log(res);
        setItemCount(res);
      });
    } else {
      await adminService
        .GetUsersCountByRoleAsync(AccountTypes.Provider)
        .then((res) => setItemCount(res.profileCount));
    }
  };

  const fetchData = async () => {
    if (resetSearch.current == false) setLoading(true);
    const model = {
      emailLogsToSkip: begin.current,
      startingEmailLogs: pageSize,
    };
    console.log(sortByLabel);
    if (sortByLabel == t("Featured Service Providers")) {
      await adminService.GetAllFeaturedProfilesAsync(model).then((x) => {
        var list = [];
        list = x;
        for (var i = 0; i < list.length; i++) {
          if (list[i].profile.spType === "Start Up") {
            list[i].profile.spType = t("signUp.providerType.startup");
          }
        }
        setUsers(list);
      });
    } else {
      await adminService
        .GetAllUsersByRoleAsync(AccountTypes.Provider, model)
        .then((x) => {
          var list = [];
          list = x;
          for (var i = 0; i < list.length; i++) {
            if (list[i].profile.spType === "Start Up") {
              list[i].profile.spType = t("signUp.providerType.startup");
            }
          }
          setUsers(list);
        });
    }

    setLoading(false);
    resetSearch.current = false;
  };

  async function handleToggleFeature(index, flag) {
    if (flag === false && sortByLabel === t("Featured Service Providers")) {
      const updatedList = users.filter((item) => item.profile.userId !== index);
      setUsers(updatedList);
    } else {
      let updatedList = users.map((item) => {
        if (item.profile.userId === index) {
          item.profile.featured = flag;
          return item;
        } else {
          return item; // else return unmodified item
        }
      });
      setUsers(updatedList);
    }
  }

  async function ToggleFeaturedProfile(id, flag) {
    await profileService
      .ToggleFeaturedProfileAsync(id, flag)
      .then(async (x) => {
        await handleToggleFeature(id, flag);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }
  function handleRemove(id) {
    const newList = users.filter((item) => item.profile.userId !== id);
    setUsers(newList);
  }

  async function deleteSelectedItem(id) {
    await adminService
      .DeleteUserAsync(id)
      .then(() => {
        handleRemove(id);
        setDeleteAlert(!deleteAlert);
      })
      .catch((error) => {
        setDeleteAlert(!deleteAlert);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }
  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [end.current, begin.current, pageSize]);

  // if (loading) {
  //   return (
  //     <>
  //       <center
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           position: "absolute",
  //           left: "50%",
  //           top: "50%",
  //         }}
  //       >
  //         <PulseLoader color="#0E6EB8" />
  //       </center>
  //     </>
  //   );
  // }
  return (
    <>
      <div className="section">
        <Container>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={{ margin: "auto" }}
                onClick={() => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("delete.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{t("delete.alert.body")}</p>
            </div>

            <div
              className="modal-footer"
              style={{ justifyContent: "flex-end" }}
            >
              <center>
                <Button
                  type="button"
                  className="mr-2"
                  style={{ margin: "auto" }}
                  color="primary"
                  onClick={() => setDeleteAlert(!deleteAlert)}
                >
                  {t("cancel.link")}
                </Button>
                <Button
                  color="danger"
                  type="button"
                  className="mr-2"
                  style={{ margin: "auto" }}
                  onClick={() => deleteSelectedItem(rowDataID)}
                >
                  {t("delete")}
                </Button>
              </center>
            </div>
          </Modal>
          <Row>
            <Col md="12">
              <UncontrolledDropdown
                style={{ marginTop: "-10px", float: "right" }}
              >
                <DropdownToggle
                  aria-expanded={false}
                  aria-haspopup={true}
                  caret
                  color="primary"
                  data-toggle="dropdown"
                  id="dropdownMenuButton"
                  type="button"
                >
                  {sortByLabel}
                </DropdownToggle>
                <DropdownMenu aria-labelledby="dropdownMenuButton">
                  <DropdownItem
                    //  href="#pablo"
                    onClick={async (e) => {
                      setLoading(true);
                      setSortByLabel(t("Featured Service Providers"));
                      begin.current = 0;
                      end.current = pageSize;
                      setCurrentPage(0);
                      const model = {
                        emailLogsToSkip: begin.current,
                        startingEmailLogs: pageSize,
                      };
                      await adminService
                        .GetAllFeaturedProfilesAsync(model)
                        .then((x) => {
                          var list = [];
                          list = x;
                          for (var i = 0; i < list.length; i++) {
                            if (list[i].profile.spType === "Start Up") {
                              list[i].profile.spType = t(
                                "signUp.providerType.startup"
                              );
                            }
                          }
                          setUsers(list);
                        });
                      await adminService
                        .GetAllFeaturedProfilesCountAsync()
                        .then((res) => {
                          console.log(res);
                          setItemCount(res);
                        });
                      setLoading(false);
                    }}
                  >
                    {t("featuredOnly")}
                  </DropdownItem>
                  <DropdownItem
                    //  href="#pablo"
                    onClick={async (e) => {
                      setLoading(true);
                      setSortByLabel(t("allSP"));
                      begin.current = 0;
                      end.current = pageSize;
                      setCurrentPage(0);
                      const model = {
                        emailLogsToSkip: begin.current,
                        startingEmailLogs: pageSize,
                      };
                      await adminService
                        .GetAllUsersByRoleAsync(AccountTypes.Provider, model)
                        .then((x) => {
                          var list = [];
                          list = x;
                          for (var i = 0; i < list.length; i++) {
                            if (list[i].profile.spType === "Start Up") {
                              list[i].profile.spType = t(
                                "signUp.providerType.startup"
                              );
                            }
                          }
                          setUsers(list);
                        });
                      await adminService
                        .GetUsersCountByRoleAsync(AccountTypes.Provider)
                        .then((res) => setItemCount(res.profileCount));
                      setLoading(false);
                    }}
                  >
                    {t("allSP")}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",

              width: "100%",
              columnGap: "10rem",
            }}
          >
            <div
              style={{
                color: "#0e6eb8",
                fontSize: "1.25rem",
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                fontWeight: 500,
                letterSpacing: "0.0075em",
                textTransform: "none",
                marginLeft: "15px",
              }}
            >
              {t("SPList")}
            </div>
            <FormControl sx={{ m: 1 }} variant="standard">
              <Input
                id="searchInput"
                type="text"
                placeholder={t("search")}
                onChange={(e) => handleSearch(e.currentTarget.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(e) => {
                        const searchInput =
                          document.getElementById("searchInput");
                        searchInput.value = "";
                        resetSearch.current = true;
                        fetchData();
                        fetchCount();
                      }}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Row>
          {loading && (
            <center
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: "50%",
                top: "100%",
              }}
            >
              <PulseLoader color="#0E6EB8" />
            </center>
          )}
          {!loading && (
            <>
              <ThemeProvider theme={theme}>
                <MaterialTable
                  components={{
                    Container: (props) => (
                      <Paper {...props} className="usersTable" elevation={0} />
                    ),
                  }}
                  title={<span></span>}
                  columns={columns}
                  data={users}
                  icons={tableIcons}
                  onSearchChange={handleSearch}
                  options={{
                    // pageSize: 10,
                    search: false,
                    paging: false,
                    actionsColumnIndex: -1,
                    cellStyle: {
                      textAlign: rtl ? "right" : "left",
                    },
                    headerStyle: {
                      textAlign: rtl ? "right" : "left",
                    },
                  }}
                  localization={{
                    header: {
                      actions: t("actions"),
                    },
                    toolbar: {
                      searchPlaceholder: t("search"),
                    },
                  }}
                  detailPanel={(rowData) => {
                    return (
                      <>
                        <Row style={{ marginTop: "10px" }}>
                          <Col md="3">
                            <FormGroup className="col-md-12">
                              <div>
                                <label
                                  className="col-md-12"
                                  htmlFor="companyName"
                                  style={{ fontWeight: "bold", padding: "0px" }}
                                >
                                  {t("expirationDate")}
                                </label>
                                <div
                                  className="col-md-12"
                                  style={{
                                    //   display: "grid",
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                  }}
                                >
                                  <label
                                  // className="col-md-12"
                                  >
                                    {rowData.expirationDate}
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup className="col-md-12">
                              <div>
                                <label
                                  className="col-md-12"
                                  htmlFor="companyName"
                                  style={{ fontWeight: "bold", padding: "0px" }}
                                >
                                  {t("admin.users.activationDate")}
                                </label>
                                <div
                                  className="col-md-12"
                                  style={{
                                    //   display: "grid",
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                  }}
                                >
                                  <label
                                  // className="col-md-12"
                                  >
                                    {rowData.activationDate}
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup className="col-md-12">
                              <div>
                                <label
                                  className="col-md-12"
                                  htmlFor="companyName"
                                  style={{ fontWeight: "bold", padding: "0px" }}
                                >
                                  {t("uploadedBy")}
                                </label>
                                <div
                                  className="col-md-12"
                                  style={{
                                    //   display: "grid",
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                  }}
                                >
                                  <label
                                  // className="col-md-12"
                                  >
                                    {rowData.uploadedBy}
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup className="col-md-12">
                              <div>
                                <label
                                  className="col-md-12"
                                  htmlFor="companyName"
                                  style={{ fontWeight: "bold", padding: "0px" }}
                                >
                                  {t("uploadedFrom")}
                                </label>
                                <div
                                  className="col-md-12"
                                  style={{
                                    //   display: "grid",
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                  }}
                                >
                                  <label
                                  // className="col-md-12"
                                  >
                                    {rowData.profile.source}
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    );
                  }}
                  actions={[
                    {
                      icon: () => (
                        <VisibilityIcon
                          className="actionIcon"
                          style={{ fill: "#0E6EB8" }}
                        />
                      ),
                      tooltip: t("SpSeen"),
                      onClick: (event, rowData) => {
                        // history.push(
                        //   `${path}/serviceprovider-details/serviceprovider-viewers/${rowData.profile.userId}`,
                        //   {
                        //     state: rowData,
                        //   }
                        // );
                        const win = window.open(
                          `${path}/serviceprovider-details/serviceprovider-viewers/${rowData.profile.userId}`,
                          "_blank"
                        );
                        win.focus();
                      },
                    },

                    (rowData) =>
                      rowData.profile.profileStatus === "Active"
                        ? {
                            icon: () => (
                              <AccountBalanceIcon
                                className="actionIcon"
                                style={{ fill: "#0E6EB8" }}
                              />
                            ),
                            tooltip: t("companyDetails"),
                            onClick: (event, rowData) => {
                              const win = window.open(
                                `/admin/users/viewProfile/${rowData.profile.userId}?showChat=false`,
                                "_blank"
                              );
                              win.focus();
                            },
                          }
                        : null,
                    (rowData) =>
                      rowData.profile.profileStatus === "Active"
                        ? {
                            icon: () =>
                              rowData.profile.featured === false ? (
                                <StarBorderIcon
                                  className="actionIcon"
                                  style={{ fill: "#0E6EB8" }}
                                />
                              ) : (
                                <StarIcon
                                  className="actionIcon"
                                  style={{ fill: "#0E6EB8" }}
                                />
                              ),
                            onClick: async (event, rowData) => {
                              rowData.profile.featured === false
                                ? await ToggleFeaturedProfile(
                                    rowData.profile.userId,
                                    true
                                  )
                                : await ToggleFeaturedProfile(
                                    rowData.profile.userId,
                                    false
                                  );
                            },
                            tooltip:
                              rowData.profile.featured === false
                                ? t("AddTofeaturedSps")
                                : t("unFeatureSP"),
                          }
                        : null,
                  ]}
                />
              </ThemeProvider>
              <div className="d-flex justify-content-end align-items-center mt-2">
                <select
                  value={pageSize}
                  onChange={(e) => handleChange(e.target.value)}
                  style={{
                    height: "36px",
                    borderRadius: 0,
                    width: "auto",
                    fontSize: "12px",
                  }}
                  className={"form-control"}
                >
                  <option
                    key={5}
                    value={5}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    5
                  </option>
                  <option
                    key={10}
                    value={10}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    10
                  </option>
                  <option
                    key={15}
                    value={15}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    15
                  </option>
                  <option
                    key={20}
                    value={20}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    20
                  </option>
                  <option
                    key={50}
                    value={50}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    50
                  </option>
                  <option
                    key={100}
                    value={100}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    100
                  </option>
                </select>
                <IconButton
                  aria-label="next"
                  disabled={currentPage == 0 ? true : false}
                >
                  {rtl ? (
                    <ArrowForwardIosSharpIcon
                      onClick={() => prev()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  ) : (
                    <ArrowBackIosSharpIcon
                      onClick={() => prev()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  )}
                </IconButton>
                <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
                  {begin.current} -{" "}
                  {end.current > itemCount ? itemCount : end.current}{" "}
                  {rtl ? "من" : "of"} {itemCount}
                </p>
                <IconButton
                  aria-label="next"
                  disabled={end.current >= itemCount ? true : false}
                >
                  {rtl ? (
                    <ArrowBackIosSharpIcon
                      onClick={() => next()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  ) : (
                    <ArrowForwardIosSharpIcon
                      onClick={() => next()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  )}
                </IconButton>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
}

export default ServiceProvidersList;
