import React, { useContext } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import LanguageDropdown from "./LanguageDropdown";
import { LanguageContext } from "../../_context";
import { useTranslation } from "react-i18next";
import { profileService } from "_services";

function AdminNavbarMobileTablet({ props }) {
  const user = profileService.userValue;

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const { t, i18n } = useTranslation();
  const [currentLan, setCurrentLan] = useContext(LanguageContext);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      <Navbar className={"fixed-top shadow-none"} expand="lg" color="white">
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand
              //   href="https://demos.creative-tim.com/now-ui-kit-react/#/index?ref=nukr-index-navbar"
              //  target="_blank"
              id="navbar-brand"
              href="/"
              // style={{ padding: "10px" }}
              className="pl-5"
              style={{
                width: "193px",
                height: "48px",
              }}
              //  style={{ padding: "10px", marginTop: "10px" }}
            >
              {/* Now UI Kit React */}
              {/* <img
                //   src={require("assets/img/outerpond-logo-nobg.png").default}
                // src={require("assets/img/outerpond-logo-mobile.png").default}
                src={require("assets/img/Outerpond-Logo-120x30.png").default}
                //  src={require("assets/img/Outerpond_Logo_Final1.svg").default}
                //src={require("assets/img/happier-logo.svg").default}
                width="120px"
                height="30px"
                alt=""
                // height="35px"
                // width="100px"
              /> */}
              <img
                src={require("assets/img/Outerpond-Logo.png").default}
                alt=""
              />
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Outerpond
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    props.push("/admin");
                  }}
                  tag={Link}
                >
                  {t("adminPanel.tab")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    props.push("/admin/cms");
                  }}
                  tag={Link}
                >
                  {t("adminPanel.CMStab")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    props.push("/admin/analytics");
                  }}
                  tag={Link}
                >
                  {t("adminPanel.AnalyticsTab")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    props.push("/admin/account/change-password?source=admin");
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                  }}
                  tag={Link}
                >
                  {t("accountDropdown.accountSettings")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontFamily: "SpaceGroteskBold" }}
                  onClick={(e) => profileService.Logout(user.profile.userId)}
                  tag={Link}
                >
                  {t("logout")}
                </NavLink>
              </NavItem>
              <LanguageDropdown />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbarMobileTablet;
