import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

const lan =
  localStorage.getItem("currentLan") != null
    ? localStorage.getItem("currentLan")
    : "en";
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: lan,
    backend: {
      /* translation file path */
      loadPath:
        "/assets/i18n/translations/{{lng}}.json?nocache=" +
        new Date().getTime(),
      // backendOptions: [{
      //   expirationTime:   24 * 60 * 60 * 1000 // 7 days
      //   }, {
      //   loadPath: '/assets/i18n/translations/{{lng}}.json'
      //   }]
    },
    fallbackLng: lan,
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */ // ns: ['translations'],
    // defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
