import React, { useState, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { forwardRef } from "react";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import { Paper, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../theme.js";
import { useTranslation } from "react-i18next";
import EmailIcon from "@material-ui/icons/Email";
import Modal from "reactstrap/lib/Modal";
import Button from "reactstrap/lib/Button";
import { partnerService } from "_services/partner.service.js";
import { LanguageContext } from "_context";
import PulseLoader from "react-spinners/PulseLoader";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  EmailIcon: forwardRef((props, ref) => <EmailIcon {...props} ref={ref} />),
};

function InvitationsSent({ match, history, props, fromHistory }) {
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);

  async function ResendInvitationEmail(id) {
    setLoading(true);
    setAlert(!alert);
    await partnerService
      .ResendSSInvitationEmailAsync(id)
      .then(() => {
        setLoading(false);
        setAlert(!alert);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  var columns = [
    {
      title: t("partner.list.sent.invitations.contactName"),
      field: "profilesToReceiveInvitation.contactName",
    },
    {
      title: t("partner.list.sent.invitations.companyName"),
      field: "profilesToReceiveInvitation.companyName",
    },
    {
      title: t("partner.list.sent.invitations.email"),
      field: "profilesToReceiveInvitation.email",
    },
    {
      title: t("partner.list.sent.invitations.emailSent"),
      field: "emailSentDescription",
    },
    {
      title: t("partner.list.sent.invitations.date"),
      field: "formattedDate",
    },
  ];

  return (
    <div>
      <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
        {loading ? (
          <>
            <div className="modal-header" style={{ margin: "0 auto" }}>
              {t("waitMessage")}
            </div>
            <div className="modal-body">
              <center>
                <Button color="primary">
                  {loading && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  {t("sendingEmail")}
                </Button>
              </center>
            </div>
          </>
        ) : (
          <>
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("done")}</h5>
            </div>
            <div className="modal-body">
              <p>{t("resendEmailSuccessful")}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={{ margin: "auto" }}
                onClick={(e) => {
                  setAlert(!alert);
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </>
        )}
      </Modal>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div className="modal-body col-md-12">
          <p>{errorMessage}</p>
        </div>

        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            style={{ margin: "auto" }}
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>

      {props && (
        <ThemeProvider theme={theme}>
          <MaterialTable
            //  style={{ backgroundColor: "#2C2C34", color: "#d9dcde" }}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            title={t("partner.list.sent.invitations")}
            columns={columns}
            data={props}
            icons={tableIcons}
            options={{
              paging: false,
              actionsColumnIndex: -1,
            }}
            actions={[
              fromHistory === true
                ? {
                    icon: () => (
                      <EmailIcon
                        className="actionIcon"
                        style={{
                          color: "#000000",
                        }}
                      />
                    ),
                    tooltip: t("resendEmail"),
                    onClick: async (event, rowData) => {
                      await ResendInvitationEmail(
                        rowData.profilesToReceiveInvitation._id
                      );
                    },
                  }
                : null,
            ]}
          />
        </ThemeProvider>
      )}
    </div>
  );
}

export { InvitationsSent };
