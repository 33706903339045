import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import ChallengePage from "./ChallengePage";

function Challenge({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <Route path={`${path}`} component={ChallengePage} exact />
      </Switch>
    </div>
  );
}

export { Challenge };
