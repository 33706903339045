import React, { useState, createContext } from "react";

// Create Context Object
export const SearchPartnersListContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const SearchPartnersListContextProvider = props => {

const model = {
    totalCount:0,
    list: []
}
  const [searchList, setSearchList] = useState(model);
  // useEffect(() => {
  // }, [ searchModel]);

  return (
    <SearchPartnersListContext.Provider value={[searchList, setSearchList]}>
      {props.children}
    </SearchPartnersListContext.Provider>
  );
};