import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import TeamList from "./TeamList";

function Team({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <Route path={`${path}`} component={TeamList} exact />
      </Switch>
    </div>
  );
}

export { Team };
