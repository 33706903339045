import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";
import { profileService } from "_services";
// import { param, post } from "jquery";
import { put, post, get } from "axios";
const baseUrl = `${config.apiUrl}/admin`;

export const adminService = {
  AddProjectTypeAsync,
  DeleteWhoWeArePersonAsync,
  AddWhoWeArePersonAsync,
  UpdateWhoWeArePersonAsync,
  AddPartnerAsync,
  DeleteProjectTypeAsync,
  UpdateProjectTypeAsync,
  DeleteCountryAsync,
  AddCountryAsync,
  UpdateCountryAsync,
  DeleteBudgetAsync,
  AddBudgetAsync,
  UpdateBudgetAsync,
  DeleteNumberOfEmployeesAsync,
  UpdateWhoWeAreTypesAsync,
  DeleteWhoWeAreTypesAsync,
  AddNumberOfEmployeesAsync,
  UpdateNumberOfEmployeesAsync,
  DeleteUserAsync,
  GetAllUsersAsync,
  GetAllUsersByRoleAsync,
  UpdateServiceAsync,
  AddServiceAsync,
  DeleteServiceAsync,
  UpdateTypeOfIndustryAsync,
  DeleteTypeOfIndustryAsync,
  AddTypeOfIndustryAsync,
  GetAllProjectsAsync,
  GetAllProjectsCountAsync,
  SyncTemplates,
  DeletePartnerAsync,
  UpdatePartnerAsync,
  UpdateLandingPageTextAsync,
  LandingPageTextAsync,
  AddWhoWeAreTypesAsync,
  DeleteIfYouBelieveIn_IfYouAreAsync,
  DeleteProjectAsync,
  GetUsersCountByRoleAsync,
  DownloadDatabaseBackupAsync,
  GetOpportunityMessagesAsync,
  GetOpportunityViewersAsync,
  GetUserLogsAsync,
  GetUserLogsCountAsync,
  GetOpportunityConvAsync,
  GetServiceProviderViewersAsync,
  GetProfilesWhoInteractedWithProjectAsync,
  GetAllDirectLinksAsync,
  GetAllDirectLinksCountAsync,
  GetReportAccessTokenAsync,
  AddPlan,
  UpdatePlan,
  DeletePlan,
  AddFeaturedInAsync,
  UpdateFeaturedInAsync,
  DeleteFeaturedInAsync,
  GetAllFeaturedInAsync,
  UpdateSectionAsync,
  GetSectionById,
  GetAllConvWithoutOpp,
  GetAllConvAsyncByOpp,
  GetConvByOppCount,
  GetConvWithoutOppCount,
  GetConvAsync,
  GetSurveyTypesAsync,
  ManuallySendSurvey,
  GetScrapedOpportunitiesAsync,
  UpdateScrapedOpportunityAsync,
  TogglePublishDirectLinkAsync,
  GetScrapedOpportunitiesCount,
  GetAllUsersCountAsync,
  GetPOsCountAsync,
  GetAllPOsAsync,
  GetAllFeaturedProfilesAsync,
  GetAllFeaturedProfilesCountAsync,
  GetAllUsersWithResponses,
  GetAllUsersWithResponsesCount,
  GetAllSurveyTypes,
  EditSurveyTypesDescription,
  GetListOfExternalUsers,
  GetExternalUser,
  DeleteExternalUser,
  UpdateExternalUser,
  CreateExternalUser,
  GetListOfExternalUsersCount,
  UpdateScrapedOpportunitiesDelete,
  AddVideoAsync,
  UpdateVideoAsync,
  DeleteVideoAsync,
  GetVideoByIDAsync,
  GetVideosAsync,
  GetChallengeAsync,
  UpdateChallengeAsync,
  MediaUploadAsync,
};

function AddProjectTypeAsync(params) {
  return fetchWrapper.post(`${baseUrl}/AddProjectTypeAsync`, params);
}

function AddWhoWeAreTypesAsync(params) {
  return fetchWrapper.post(`${baseUrl}/AddWhoWeAreTypes`, params);
}
function UpdateWhoWeAreTypesAsync(id, params) {
  return fetchWrapper.post(`${baseUrl}/UpdateWhoWeAreTypes/${id}`, params);
}
function DeleteWhoWeAreTypesAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/DeleteWhoWeAreTypes/${id}`);
}
function AddWhoWeArePersonAsync(params) {
  const user = profileService.userValue;
  const options = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user !== null ? user.jwtToken : ""}`,
    },
  };
  return put(`${baseUrl}/AddWhoWeArePerson`, params, options);
}
function UpdateWhoWeArePersonAsync(id, params) {
  const user = profileService.userValue;
  const options = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user !== null ? user.jwtToken : ""}`,
    },
  };
  return put(`${baseUrl}/UpdateWhoWeArePerson/${id}`, params, options);
}
function DeleteWhoWeArePersonAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/DeleteWhoWeArePerson/${id}`);
}
function AddPartnerAsync(params) {
  const user = profileService.userValue;
  const options = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user !== null ? user.jwtToken : ""}`,
    },
  };
  return put(`${baseUrl}/AddPartnerAsync`, params, options);
}
function UpdatePartnerAsync(id, params) {
  const user = profileService.userValue;
  const options = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user !== null ? user.jwtToken : ""}`,
    },
  };
  return put(`${baseUrl}/UpdatePartnerAsync/${id}`, params, options);
}
function DeleteIfYouBelieveIn_IfYouAreAsync(id) {
  return fetchWrapper.delete(
    `${baseUrl}/DeleteIfYouBelieveInIfYouAreAsync/${id}`
  );
}

function DeletePartnerAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/DeletePartnerAsync/${id}`);
}

function DeleteProjectTypeAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/DeleteProjectType/${id}`);
}
function UpdateLandingPageTextAsync(params) {
  return fetchWrapper.post(`${baseUrl}/UpdateLandingPageTextAsync`, params);
}
function LandingPageTextAsync(texttype) {
  return fetchWrapper.get(`${baseUrl}/LandingPageTextAsync/${texttype}`);
}

function DeleteProjectAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/DeleteProject/${id}`);
}

function UpdateProjectTypeAsync(id, params) {
  return fetchWrapper.post(`${baseUrl}/UpdateProjectType/${id}`, params);
}

function AddCountryAsync(params) {
  return fetchWrapper.post(`${baseUrl}/AddCountry`, params);
}

function UpdateCountryAsync(id, params) {
  return fetchWrapper.post(`${baseUrl}/UpdateCountry/${id}`, params);
}

function DeleteCountryAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/DeleteCountry/${id}`);
}

function AddBudgetAsync(params) {
  return fetchWrapper.post(`${baseUrl}/AddBudget`, params);
}

function UpdateBudgetAsync(id, params) {
  return fetchWrapper.post(`${baseUrl}/UpdateBudget/${id}`, params);
}

function DeleteBudgetAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/DeleteBudget/${id}`);
}

function AddNumberOfEmployeesAsync(params) {
  return fetchWrapper.post(`${baseUrl}/AddNumberOfEmployees`, params);
}

function UpdateNumberOfEmployeesAsync(id, params) {
  return fetchWrapper.post(`${baseUrl}/UpdateNumberOfEmployees/${id}`, params);
}

function DeleteNumberOfEmployeesAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/DeleteNumberOfEmployees/${id}`);
}

function GetAllUsersAsync(params) {
  return fetchWrapper.post(`${baseUrl}/GetAllUsers`, params);
}

function GetAllUsersByRoleAsync(role, params) {
  return fetchWrapper.post(`${baseUrl}/GetAllUsersByRole/${role}`, params);
}

function GetUsersCountByRoleAsync(role) {
  return fetchWrapper.get(`${baseUrl}/GetUsersCountByRole/${role}`);
}

function UpdateServiceAsync(industryId, serviceId, model) {
  return fetchWrapper.post(
    `${baseUrl}/UpdateService/${industryId}/${serviceId}`,
    model
  );
}

function AddServiceAsync(industryId, model) {
  return fetchWrapper.post(`${baseUrl}/AddService/${industryId}`, model);
}

function DeleteServiceAsync(industryId, serviceId) {
  return fetchWrapper.delete(
    `${baseUrl}/DeleteService/${industryId}/${serviceId}`
  );
}

function UpdateTypeOfIndustryAsync(industryId, model) {
  return fetchWrapper.post(
    `${baseUrl}/UpdateTypeOfIndustry/${industryId}`,
    model
  );
}

function AddTypeOfIndustryAsync(model) {
  return fetchWrapper.post(`${baseUrl}/AddTypeOfIndustry`, model);
}

function DeleteTypeOfIndustryAsync(industryId) {
  return fetchWrapper.delete(`${baseUrl}/DeleteTypeOfIndustry/${industryId}`);
}

function GetAllProjectsAsync(id, closed, params) {
  return fetchWrapper.post(`${baseUrl}/GetAllProjects/${closed}/${id}`, params);
}

function GetAllProjectsCountAsync(closed) {
  return fetchWrapper.get(`${baseUrl}/GetAllProjectsCount/${closed}/`);
}

function SyncTemplates() {
  return fetchWrapper.post(`${baseUrl}/SyncTemplates`, {});
}

function DeleteUserAsync(userId) {
  return fetchWrapper.post(`${baseUrl}/DeleteUser/${userId}`);
}

function DownloadDatabaseBackupAsync() {
  const user = profileService.userValue;
  return get(`${baseUrl}/DownloadDatabaseBackup`, {
    headers: {
      Authorization: `Bearer ${user !== null ? user.jwtToken : ""}`,
    },
    responseType: "blob",
    Accept: [
      "application/vnd.ms-excel",
      "application/pdf",
      "application/zip;base64",
    ],
  }).then((response) => {
    return response;
  });
}

function GetOpportunityMessagesAsync(id) {
  return fetchWrapper.get(`${baseUrl}/GetOpportunityMessages/${id}`);
}

function GetOpportunityViewersAsync(id, isDirect) {
  return fetchWrapper.get(`${baseUrl}/GetOpportunityViewers/${id}/${isDirect}`);
}

function GetUserLogsAsync(id, params) {
  return fetchWrapper.post(`${baseUrl}/GetUserLogs/${id}`, params);
}

function GetUserLogsCountAsync(id) {
  return fetchWrapper.get(`${baseUrl}/GetUserLogsCount/${id}`);
}

function GetOpportunityConvAsync(projId, spId) {
  return fetchWrapper.get(`${baseUrl}/GetOpportunityConv/${projId}/${spId}`);
}

function GetServiceProviderViewersAsync(spId) {
  return fetchWrapper.get(`${baseUrl}/GetServiceProviderViewers/${spId}`);
}

function GetProfilesWhoInteractedWithProjectAsync(projID) {
  return fetchWrapper.get(
    `${baseUrl}/GetProfilesWhoInteractedWithProject/${projID}`
  );
}

function GetAllDirectLinksAsync(id, lan, closed, params) {
  return fetchWrapper.post(
    `${baseUrl}/GetAllDirectLinks/${closed}/${lan}/${id}`,
    params
  );
}

function GetAllDirectLinksCountAsync(closed) {
  return fetchWrapper.get(`${baseUrl}/GetAllDirectLinksCount/${closed}`);
}

function GetReportAccessTokenAsync(userId) {
  return fetchWrapper.get(`${baseUrl}/GetAccessToken/${userId}`);
}

function AddPlan(params) {
  return fetchWrapper.post(`${baseUrl}/AddPlan`, params);
}

function UpdatePlan(id, params) {
  return fetchWrapper.post(`${baseUrl}/UpdatePlan/${id}`, params);
}
function DeletePlan(id) {
  return fetchWrapper.delete(`${baseUrl}/DeletePlan/${id}`);
}

function AddFeaturedInAsync(params) {
  const user = profileService.userValue;
  const options = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user !== null ? user.jwtToken : ""}`,
    },
  };
  return put(`${baseUrl}/AddFeaturedIn`, params, options);
}
function UpdateFeaturedInAsync(id, params) {
  const user = profileService.userValue;
  const options = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user !== null ? user.jwtToken : ""}`,
    },
  };
  return put(`${baseUrl}/UpdateFeaturedIn/${id}`, params, options);
}
function DeleteFeaturedInAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/DeleteFeaturedIn/${id}`);
}
function GetAllFeaturedInAsync(id) {
  return fetchWrapper.get(`${baseUrl}/AllFeaturedIn`);
}
function UpdateSectionAsync(id, params) {
  return fetchWrapper.put(`${baseUrl}/UpdateSection/${id}`, params);
}
function GetSectionById(id) {
  return fetchWrapper.get(`${baseUrl}/GetSectionById/${id}`);
}
function GetAllConvAsyncByOpp(skip, take, getCount) {
  return fetchWrapper.get(
    `${baseUrl}/GetAllConvByOpp/${skip}/${take}/${getCount}`
  );
}
function GetAllConvWithoutOpp(skip, take, getCount) {
  return fetchWrapper.get(
    `${baseUrl}/GetAllConvWithoutOpp/${skip}/${take}/${getCount}`
  );
}

function GetConvByOppCount() {
  return fetchWrapper.get(`${baseUrl}/getConvByOppCount`);
}

function GetConvWithoutOppCount() {
  return fetchWrapper.get(`${baseUrl}/GetConvWithoutOppCount`);
}
function GetConvAsync(projectID, person1, person2) {
  return fetchWrapper.get(
    `${baseUrl}/GetConv/${projectID}/${person1}/${person2}`
  );
}
function GetSurveyTypesAsync(params) {
  return fetchWrapper.post(`${baseUrl}/GetSurveyTypes`, params);
}
function GetAllSurveyTypes() {
  return fetchWrapper.get(`${baseUrl}/GetAllSurveyTypes`);
}
function ManuallySendSurvey(userId, surveyId) {
  return fetchWrapper.get(
    `${baseUrl}/ManuallySendSurvey/${userId}/${surveyId}`
  );
}
function GetScrapedOpportunitiesAsync(params) {
  return fetchWrapper.post(`${baseUrl}/GetScrapedOpportunities`, params);
}
function EditSurveyTypesDescription(params) {
  return fetchWrapper.post(`${baseUrl}/EditSurveyTypesDescription`, params);
}
function GetScrapedOpportunitiesCount() {
  return fetchWrapper.get(`${baseUrl}/GetScrapedOpportunitiesCount`);
}
function UpdateScrapedOpportunityAsync(id, flag) {
  return fetchWrapper.put(`${baseUrl}/UpdateScrapedOpportunity/${id}/${flag}`);
}
function UpdateScrapedOpportunitiesDelete(params) {
  return fetchWrapper.put(
    `${baseUrl}/UpdateScrapedOpportunitiesDelete`,
    params
  );
}
function TogglePublishDirectLinkAsync(id, publish) {
  return fetchWrapper.post(
    `${baseUrl}/TogglePublishDirectLink/${id}/${publish}`
  );
}
function GetAllUsersCountAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllUsersCount`);
}
function GetAllPOsAsync(params) {
  return fetchWrapper.post(`${baseUrl}/GetAllPOs`, params);
}
function GetPOsCountAsync() {
  return fetchWrapper.get(`${baseUrl}/GetPOsCount`);
}
function GetAllFeaturedProfilesAsync(params) {
  return fetchWrapper.post(`${baseUrl}/GetAllFeaturedProfilesAsync`, params);
}
function GetAllFeaturedProfilesCountAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllFeaturedProfilesCountAsync`);
}
function GetAllUsersWithResponses(id, params) {
  return fetchWrapper.post(`${baseUrl}/AllUsersWithResponses/${id}`, params);
}
function GetAllUsersWithResponsesCount(id) {
  return fetchWrapper.get(`${baseUrl}/AllUsersWithResponsesCount/${id}`);
}
function GetListOfExternalUsers(params) {
  return fetchWrapper.post(`${baseUrl}/GetListOfExternalUsers`, params);
}
function GetExternalUser(params) {
  return fetchWrapper.post(`${baseUrl}/GetExternalUser`, params);
}
function DeleteExternalUser(params) {
  return fetchWrapper.post(`${baseUrl}/DeleteExternalUser`, params);
}
function UpdateExternalUser(params) {
  return fetchWrapper.post(`${baseUrl}/UpdateExternalUser`, params);
}
function CreateExternalUser(params) {
  return fetchWrapper.post(`${baseUrl}/CreateExternalUser`, params);
}
function GetListOfExternalUsersCount() {
  return fetchWrapper.get(`${baseUrl}/GetListOfExternalUsersCount`);
}
function AddVideoAsync(params) {
  return fetchWrapper.postFormData(`${baseUrl}/AddVideo`, params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
  // return fetchWrapper.put(`${baseUrl}/AddVideo`, params, options);
}
function UpdateVideoAsync(params) {
  return fetchWrapper.postFormData(`${baseUrl}/UpdateVideo`, params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
  // return fetchWrapper.put(`${baseUrl}/UpdateVideo`, params);
}
function DeleteVideoAsync(videoId) {
  return fetchWrapper.delete(`${baseUrl}/DeleteVideo/${videoId}`);
}
function GetVideoByIDAsync(videoId) {
  return fetchWrapper.get(`${baseUrl}/GetVideoByID/${videoId}`);
}
function GetVideosAsync() {
  return fetchWrapper.get(`${baseUrl}/GetVideos`);
}
function GetChallengeAsync() {
  return fetchWrapper.get(`${baseUrl}/GetChallenge`);
}
function UpdateChallengeAsync(params) {
  return fetchWrapper.post(`${baseUrl}/UpdateChallenge`, params);
}
function MediaUploadAsync(params) {
  return fetchWrapper.postFormData(`${baseUrl}/MediaUpload`, params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}
