import React, { useState, createContext } from "react";

// Create Context Object
export const ProjectSearchListContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const ProjectSearchListContextProvider = props => {

    const model = {
        totalCount:0,
        list: []
    }
  const [projectSearchList, setProjectSearchList] = useState(model);
  // useEffect(() => {
  // }, [ searchModel]);

  return (
    <ProjectSearchListContext.Provider value={[projectSearchList, setProjectSearchList]}>
      {props.children}
    </ProjectSearchListContext.Provider>
  );
};