import { TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  FormGroup,
  Button,
  Container,
  Modal,
} from "reactstrap";
import { profileService } from "_services";
import { useHistory } from "react-router-dom";
import Alert from "reactstrap/lib/Alert";

// core components

function SecondaryContacts({
  props,
  secondaryUsers,
  setSecondaryUsers,
  isAdmin,
  userProfileID,
  setError,
  setMessage,
  isDisabled,
  setIsDisabled,
}) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const history = useHistory();

  const user = profileService.userValue;

  const [email, setEmail] = useState("");
  // const [isDisabled, setIsDisabled] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [primaryAlert, setPrimaryAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [model, setModel] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleEmail = (e) => setEmail(e.currentTarget.value);

  const addSecondaryContact = async () => {
    setLoading(true);
    const model = {
      email,
      disabled: false,
      password: "NewSP@123",
      primaryEmail: props.profile.userName,
      accountType: user.profile.accountTypeID,
    };
    await profileService
      .AddSecondaryProfileAsync(model)
      .then((res) => {
        setSecondaryUsers((prev) => [...prev, res]);
        setLoading(false);
        setSuccessAlert(true);
      })
      .catch((err) => {
        setError(true);
        setMessage(err.message);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setEmail("");
      });
  };

  const onShowAlert = (flag) => {
    if (flag === "0") {
      setShowAlert(true);
    } else if (flag === "1") {
      setShowErrorAlert(true);
    }
  };

  console.log("isAdmin", isAdmin);
  console.log("isDisabled", isDisabled);

  return (
    <>
      <Modal isOpen={showAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-body">
          <p className="mt-2">{t("Secondary.disabled.success")}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            style={{ margin: "auto" }}
            onClick={(e) => {
              setShowAlert(false);
            }}
          >
            {t("label.ok")}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={showErrorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div className="modal-body col-md-12">
          <p>{t("Secondary.disabled.failure")}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            style={{ margin: "auto" }}
            onClick={(e) => setShowErrorAlert(!showErrorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={primaryAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-body text-center">
          <h5 className="mt-2">{t("MakeAccountPrimaryMessage.one")}</h5>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            style={{ margin: "auto", width: "20%" }}
            onClick={async (e) => {
              await profileService
                .MakePrimaryUserAsync(model)
                .then((res) => {
                  if (res === true) {
                    setPrimaryAlert(false);
                  }
                  profileService.Logout(user.profile.userId);
                })
                .catch((err) => {
                  setError(true);
                  setMessage(err.message);
                  console.log(err);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            {t("label.yes")}
          </Button>
          <Button
            color="danger"
            type="button"
            style={{ margin: "auto", width: "20%" }}
            onClick={(e) => {
              setPrimaryAlert(false);
            }}
          >
            {t("label.no")}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={successAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-body text-center">
          <p>
            <b>{t("MakeAccountPrimaryMessage.two")}</b>
          </p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            style={{ margin: "auto" }}
            onClick={(e) => {
              setSuccessAlert(!successAlert);
            }}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Card className={rtl ? "text-right" : ""}>
        <CardHeader className="text-center">
          <CardTitle className="" tag="h4">
            {t("createCompanyProfile.secondaryContacts.diviver")}
          </CardTitle>
          <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
        </CardHeader>

        <CardBody>
          <div className="form-row">
            <FormGroup className="col-md-12">
              <div
                style={{
                  display: "flex",
                }}
              >
                <label
                  className="col-md-7"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {t("createCompanyProfile.contactEmail")}
                </label>
                {user.isPrimary || isAdmin === true ? (
                  <label
                    className={isAdmin ? "col-md-5 text-center" : "col-md-5"}
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {t("Secondary.disabled")}
                  </label>
                ) : null}
              </div>
            </FormGroup>
          </div>

          {secondaryUsers.length !== 0 &&
            secondaryUsers.map((u, pos) => (
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <div
                    style={{
                      display: "flex",

                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <label
                      className="col-md-6"
                      style={{ padding: "0.2rem 0rem" }}
                    >
                      {u.email !== null && u.email}
                    </label>
                    {user.isPrimary || isAdmin === true ? (
                      <>
                        <input
                          type="checkbox"
                          className={isAdmin ? "col-md-5" : ""}
                          disabled={isAdmin}
                          defaultChecked={u.disabled}
                          value={isDisabled[pos]}
                          style={{ transform: "scale(1.3)" }}
                          onChange={async () => {
                            const model = {
                              userId: user.profile.userId,
                              secondaryId: u.userId,
                            };

                            await profileService
                              .ToggleSecondaryUserDisabled(model)
                              .then((res) => {
                                const disabledArr = isDisabled;
                                disabledArr[pos] = res;
                                setIsDisabled(disabledArr);
                                onShowAlert("0");
                              })
                              .catch((err) => {
                                console.log("err");
                                onShowAlert("1");
                              });
                          }}
                        />
                        {isAdmin ? (
                          <></>
                        ) : (
                          <button
                            disabled={isDisabled[pos]}
                            style={
                              isDisabled[pos]
                                ? {
                                    opacity: 0.5,
                                    cursor: "not-allowed",
                                    fontSize: "1em",
                                    borderRadius: "0.25rem",
                                    padding: "0.3rem 0.6rem",
                                    backgroundColor: "#0e6eb8",
                                    color: "#ffffff",
                                    border: "none",
                                    outline: "none",
                                  }
                                : {
                                    fontSize: "1em",
                                    borderRadius: "0.25rem",
                                    padding: "0.3rem 0.6rem",
                                    backgroundColor: "#0e6eb8",
                                    color: "#ffffff",
                                    border: "none",
                                    outline: "none",
                                  }
                            }
                            onClick={async () => {
                              // setLoading(true);
                              const model = {
                                primaryEmail: user.profile.userName,
                                primaryId: user.profile.userId,
                                secondaryEmail: u.email,
                                secondaryId: u.userId,
                              };
                              setModel(model);
                              setPrimaryAlert(true);
                            }}
                          >
                            {/* {loading && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )} */}
                            {t("Secondary.MakePrimary")}
                          </button>
                        )}
                      </>
                    ) : null}
                  </div>
                </FormGroup>
              </div>
            ))}
          <div className="form-row">
            {user.isPrimary && !isAdmin ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "1rem 0.4rem",
                }}
              >
                <TextField
                  value={email}
                  onChange={(e) => handleEmail(e)}
                  name="email"
                  placeholder={t("signUp.email")}
                  type="text"
                  style={{ flex: 0.9 }}
                />
                <button
                  style={{
                    fontSize: "1em",
                    borderRadius: "0.25rem",
                    padding: "0.3rem 0.6rem",
                    backgroundColor: "#0e6eb8",
                    color: "#ffffff",
                    border: "none",
                    outline: "none",
                    flex: 0.1,
                    marginLeft: "0.5rem",
                  }}
                  onClick={addSecondaryContact}
                  disabled={loading === true}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  {t("add.button")}
                </button>
              </div>
            ) : null}
            {/* </FormGroup> */}
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default SecondaryContacts;
