import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditTestimonials from "./AddEditTestimonials";
import TestimonialsList from "./TestimonialsList";

function Testimonials({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={TestimonialsList} exact />
        <PrivateRoute path={`${path}/add`} component={AddEditTestimonials} />
        <PrivateRoute
          path={`${path}/edit/:id`}
          component={AddEditTestimonials}
        />
      </Switch>
    </div>
  );
}

export { Testimonials };
