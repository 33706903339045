import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/Badge`;

export const badgeService = {
  AddBadgeAsync,
  GetBadgeByID,
  GetAllVettingBadges,
  GetAllSsAndSpAndPoBadges,
  GetAllBadges,
  EditBadge,
  DeleteBadge,
  AddCredibilityBadgeToUsers,
  GetAllManuallyGrantedBadges,
};

function AddBadgeAsync(params) {
  return fetchWrapper.postFormData(`${baseUrl}/AddBadge`, params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

function GetBadgeByID(id) {
  return fetchWrapper.get(`${baseUrl}/GetBadgeByID/${id}`);
}

function GetAllVettingBadges() {
  return fetchWrapper.get(`${baseUrl}/GetAllVettingBadges`);
}

function GetAllSsAndSpAndPoBadges() {
  return fetchWrapper.get(`${baseUrl}/GetAllSsAndSpAndPoBadges`);
}

function GetAllBadges() {
  return fetchWrapper.get(`${baseUrl}/GetAllBadges`);
}

function EditBadge(id, params) {
  return fetchWrapper.postFormData(`${baseUrl}/EditBadge/${id}`, params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

function DeleteBadge(id) {
  return fetchWrapper.delete(`${baseUrl}/DeleteBadge/${id}`);
}

function AddCredibilityBadgeToUsers() {
  return fetchWrapper.post(`${baseUrl}/AddCredibilityBadgeToUsers/`);
}

function GetAllManuallyGrantedBadges() {
  return fetchWrapper.get(`${baseUrl}/GetAllManuallyGrantedBadges`);
}
