import React, { useState, useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import Tooltip from "@mui/material/Tooltip";
import { Multiselect } from "multiselect-react-dropdown";
import {
  adminService,
  countryService,
  profileService,
  directLinkService,
  industryService,
} from "../../../_services";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  CardTitle,
  Modal,
  UncontrolledTooltip,
  Container,
} from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import { LanguageContext } from "../../../_context";
import "../../../assets/css/generalStyles.css";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import { textAlign } from "@mui/system";

function AddEditDirectLink({ history, match, location }) {
  const { id } = match.params;
  const isAddMode = !id;
  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() + 1)
  );
  const minDate = new Date().setDate(new Date().getDate() + 1);
  const user = profileService.userValue;
  const [countryId, setCountryId] = useState(null);
  const [countries, setCountries] = useState(null);
  const [alert, setAlert] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [VerificationErrorMessage, setVerificationErrorMessage] =
    useState(null);
  const [errorVerificationAlert, setErrorVerificationAlert] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [ndaText, setNdaText] = useState("");
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [newProjectID, setNewProjectID] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [industries, setIndustries] = useState([]);
  const [industryAndService, setIndustryAndService] = useState(null);
  const [industriesOnly, setIndustriesOnly] = useState([]);
  const [servicesOnly, setServicesOnly] = useState([]);
  const servicesMultiselectRef = React.createRef();
  const [selectedList, setSelectedList] = useState([]);
  const [selectedIndustryList, setSelectedIndustryList] = useState([]);
  const [infoAlert, setInfoAlert] = useState(false);

  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ direction: "" }, { direction: "rtl" }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["clean"],
    ],
  };
  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "background",
  ];

  const [initialValues, setInitialValues] = useState({
    projectTitle: "",
    countryID: "",
    projectLink: "",
    description: "",
    extraInfo: "",
  });

  const urlRegex =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,256}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  const validationSchema = Yup.object().shape({
    projectLink: Yup.string()
      .required(t("directLink.required"))
      .matches(urlRegex, t("urlValidation")),
    projectTitle: Yup.string().required(t("directLinkTitle.required")),
    countryID: Yup.string().required(t("createCompanyProfile.country.error")),
  });

  const fetchData = async (lan) => {
    setLoading(true);
    await countryService.GetCountryByLanguageAsync(lan).then((x) => {
      setCountries(x);
    });

    await industryService
      .GetIndustriesServicesByLanguageAsync(lan)
      .then(async (x) => {
        setIndustries(x);
        for (var i = 0; i < x.length; i++) {
          if (
            !industriesOnly.some(
              (industry) =>
                x[i].typeOfIndustryId != null &&
                industry.typeOfIndustryId === x[i].typeOfIndustryId
            )
          ) {
            industriesOnly.push({
              typeOfIndustryId:
                x[i].typeOfIndustryId == null ? null : x[i].typeOfIndustryId,
              typeOfIndustryName:
                x[i].typeOfIndustryName == null
                  ? null
                  : x[i].typeOfIndustryName,
            });
          }
        }

        if (!isAddMode) {
          await directLinkService
            .GetDirectLinkByIdAsync(id, currentLan, false)
            .then((proj) => {
              setProject(proj);
              const values = {
                projectTitle: proj.title,
                countryID: proj.location,
                projectLink: proj.link,
                description: proj.description,
              };
              setIndustryAndService([
                {
                  typeOfIndustryId:
                    proj.typeOfIndustry == null
                      ? null
                      : proj.typeOfIndustry.typeOfIndustryId == null
                      ? null
                      : proj.typeOfIndustry.typeOfIndustryId,
                  typeOfIndustryName:
                    proj.typeOfIndustry == null
                      ? null
                      : proj.typeOfIndustry.typeOfIndustryName == null
                      ? null
                      : proj.typeOfIndustry.typeOfIndustryName,
                  typeOfServiceId:
                    proj.typeOfIndustry == null
                      ? null
                      : proj.typeOfIndustry.typeOfServiceId == null
                      ? null
                      : proj.typeOfIndustry.typeOfServiceId,
                  typeOfServiceName:
                    proj.typeOfIndustry == null
                      ? null
                      : proj.typeOfIndustry.typeOfServiceName == null
                      ? null
                      : proj.typeOfIndustry.typeOfServiceName,
                },
              ]);
              let list = [];
              if (proj.typeOfIndustry != null) list = [proj.typeOfIndustry];
              setSelectedList(list);
              setSelectedIndustryList(list);

              let sList = [];
              x &&
                x.length > 0 &&
                x.map((industry) => {
                  if (
                    industry.typeOfIndustryId != null &&
                    proj.typeOfIndustry != null &&
                    proj.typeOfIndustry.typeOfIndustryId != null &&
                    industry.typeOfIndustryId ===
                      proj.typeOfIndustry.typeOfIndustryId
                  ) {
                    sList.push(industry);
                  }
                });
              setServicesOnly(sList);

              setStartDate(new Date(proj.closingDate));
              setProjectDescription(proj.description);
              setInitialValues(values);
            })
            .catch((error) => {
              console.log(error);
              if (error !== null && error.response !== null) {
                setError(error.response);
              } else {
                setError(error);
              }
              setErrorAlert(!errorAlert);
            });
        }
      });

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData(currentLan);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (location.state != null && location.state.project != null) {
      const values = {
        projectTitle: location.state.project.title,
        projectLink: location.state.project.link,
        description: location.state.project.description,
        extraInfo: location.state.project.extraInformation,
        countryID: "",
      };
      setProject(values);
      setProjectDescription(location.state.project.description);
      setInitialValues(values);
    }

    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [currentLan]);

  function onIndustrySelectChangeAdd(e) {
    setSelectedIndustryList(e);
    //servicesMultiselectRef.current.resetSelectedValues();
    const list = [];
    if (e !== null) {
      e.map(async (industry) => {
        await list.push(industry.typeOfIndustryId);
      });
    }
    const servicesList = [];
    industries &&
      industries.map((element) => {
        if (list.includes(element.typeOfIndustryId)) {
          servicesList.push(element);
        }
      });
    setServicesOnly(servicesList);
  }

  function onIndustrySelectChangeRemove(e) {
    setSelectedIndustryList(e);
    //servicesMultiselectRef.current.resetSelectedValues();
    const list = [];
    if (e !== null) {
      e.map(async (industry) => {
        await list.push(industry.typeOfIndustryId);
      });
    }
    const newList = [];
    if (selectedList !== null) {
      selectedList.map((element) => {
        if (list.includes(element.typeOfIndustryId) === true) {
          newList.push(element);
        }
      });
      setSelectedList(newList);
    }

    const servicesList = [];
    industries &&
      industries.map((element) => {
        if (list.includes(element.typeOfIndustryId)) {
          servicesList.push(element);
        }
      });
    setServicesOnly(servicesList);
  }

  function onChange(e) {
    setSelectedList(e);
  }

  async function onSubmit(fields, { setSubmitting }) {
    if (projectDescription == null || projectDescription == "") {
      setError(t("addProject.projectDescription.error"));
      setErrorAlert(!errorAlert);
    } else {
      if (selectedList === null || selectedList.length === 0) {
        setError(t("required.industries"));
        setErrorAlert(!errorAlert);
        return null;
      }

      const model = {
        title: fields.projectTitle,
        location: fields.countryID,
        closingDate: new Date(startDate).toLocaleDateString().slice(0, 10),
        link: fields.projectLink,
        userId: user.profile.userId,
        description: projectDescription,
        IndustryAndService: selectedList[0],
      };

      if (isAddMode) {
        await directLinkService
          .AddDirectLinkAsync(model)
          .then(async (project) => {
            if (
              location.state != null &&
              location.state.project != null &&
              location.state.scrapedOpportunity != null
            ) {
              await adminService
                .UpdateScrapedOpportunityAsync(location.state.project.id, "1")
                .then((x) => {
                  setSubmitting(false);
                  setNewProjectID(project.id);
                  setAlert(!alert);
                })
                .catch((error) => {
                  setSubmitting(false);
                  if (error.message !== undefined) {
                    setError(error.message);
                    if (error.error !== null && error.error.length > 0) {
                      for (var i = 0; i < error.error.length; i++) {
                        if (error.error[i].languageName === currentLan) {
                          setError(error.error[i].error);
                        }
                      }
                    }
                  } else {
                    setError(error);
                  }
                  setErrorAlert(!errorAlert);
                });
            } else {
              setSubmitting(false);
              setNewProjectID(project.id);
              setAlert(!alert);
            }
          })
          .catch((error) => {
            setSubmitting(false);
            if (error.message !== undefined) {
              setError(error.message);
              if (error.error !== null && error.error.length > 0) {
                for (var i = 0; i < error.error.length; i++) {
                  if (error.error[i].languageName === currentLan) {
                    setError(error.error[i].error);
                  }
                }
              }
            } else {
              setError(error);
            }
            setErrorAlert(!errorAlert);
          });
      } else {
        await directLinkService
          .EditDirectLinkAsync(id, model)
          .then(() => {
            setSubmitting(false);
            setEditAlert(!editAlert);
          })
          .catch((error) => {
            setSubmitting(false);
            if (error.message !== undefined) {
              setError(error.message);
              if (error.error !== null && error.error.length > 0) {
                for (var i = 0; i < error.error.length; i++) {
                  if (error.error[i].languageName === currentLan) {
                    setError(error.error[i].error);
                  }
                }
              }
            } else {
              setError(error);
            }
            setErrorAlert(!errorAlert);
          });
      }
    }
  }
  console.log(initialValues);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <div
        className={
          rtl ? "section section-signup text-right" : "section section-signup"
        }
      >
        <Container style={{ marginTop: "-5%" }}>
          <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("addProject.success.title")}</h5>
            </div>
            <div className="modal-body">
              <p>{t("publishNowSecond")}</p>
            </div>
            <div
              className="modal-footer"
              style={
                rtl
                  ? { justifyContent: "flex-end" }
                  : { justifyContent: "flex-start" }
              }
            >
              <Button
                className="mr-2"
                color="primary"
                type="button"
                onClick={async (e) => {
                  setAlert(!alert);
                  if (
                    location.state != null &&
                    location.state.project != null &&
                    location.state.scrapedOpportunity != null
                  ) {
                    await adminService
                      .TogglePublishDirectLinkAsync(newProjectID, true)
                      .then(() => {
                        if (
                          location.state != null &&
                          location.state.project != null &&
                          location.state.scrapedOpportunity != null
                        ) {
                          history.push(".");
                        } else {
                          history.push(
                            "/" +
                              user.profileTypeView.profileTypeDescription +
                              "/directLinks"
                          );
                        }
                      })
                      .catch((error) => {
                        if (error.message !== undefined) {
                          setError(error.message);
                        } else {
                          setError(error);
                        }
                        setErrorAlert(!errorAlert);
                      });
                  } else {
                    await directLinkService
                      .TogglePublishDirectLinkAsync(newProjectID, true)
                      .then(() => {
                        if (
                          location.state != null &&
                          location.state.project != null &&
                          location.state.scrapedOpportunity != null
                        ) {
                          history.push(".");
                        } else {
                          history.push(
                            "/" +
                              user.profileTypeView.profileTypeDescription +
                              "/directLinks"
                          );
                        }
                      })
                      .catch((error) => {
                        if (error.message !== undefined) {
                          setError(error.message);
                        } else {
                          setError(error);
                        }
                        setErrorAlert(!errorAlert);
                      });
                  }
                }}
              >
                {t("addProject.projectNDA.true")}
              </Button>
              <Link
                color="primary"
                type="button"
                onClick={(e) => {
                  setAlert(!alert);
                  if (
                    location.state != null &&
                    location.state.project != null &&
                    location.state.scrapedOpportunity != null
                  ) {
                    history.push(".");
                  } else {
                    history.push(
                      "/" +
                        user.profileTypeView.profileTypeDescription +
                        "/directLinks"
                    );
                  }
                }}
              >
                {t("addProject.projectNDA.false")}
              </Link>
            </div>
          </Modal>

          <Modal isOpen={editAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("editProject.success.title")}</h5>
            </div>
            <div className="modal-body">
              {/* {project && project.projectStatus == ProjectStatus.Unpublished ? (
                <p>{t("editProject.success.body.unpublished")}</p>
              ) : (
                <p>{t("editProject.success.body.published")}</p>
              )} */}
              <p>{t("editDirectLink.success.title")}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => {
                  setAlert(!alert);
                  history.push(
                    "/" +
                      user.profileTypeView.profileTypeDescription +
                      "/directLinks"
                  );
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>

          <Modal isOpen={infoAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("extra.info")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{initialValues.extraInfo}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => setInfoAlert(!infoAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>

          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={
                rtl
                  ? { margin: "0 auto" }
                  : { margin: "0 auto", textAlign: "right" }
              }
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>

          <>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, isSubmitting, setFieldValue }) => {
                return (
                  <div>
                    <Card
                      style={
                        rtl
                          ? { marginTop: "5%", textAlign: "right" }
                          : { marginTop: "5%" }
                      }
                    >
                      <CardHeader className="text-center">
                        <CardTitle className="" tag="h4">
                          {isAddMode ? t("addLink") : t("editDirectLink")}
                        </CardTitle>
                      </CardHeader>
                      <Form autoComplete="off">
                        <CardBody>
                          <Container>
                            {location.state != null &&
                            location.state.project != null &&
                            location.state.scrapedOpportunity != null ? (
                              <div className="d-flex justify-content-between align-items-center">
                                <h4>{t("directLinkDetails")}</h4>
                                <i
                                  id="info"
                                  class="fa-solid fa-circle-info"
                                  style={{
                                    color: "#107FD4",
                                    marginTop: "30px",
                                    marginBottom: "15px",
                                  }}
                                  onClick={() => setInfoAlert(!infoAlert)}
                                >
                                  <UncontrolledTooltip
                                    placement="right"
                                    target="#info"
                                    delay={0}
                                  >
                                    {t("extra.info")}
                                  </UncontrolledTooltip>
                                </i>
                              </div>
                            ) : (
                              <h4>{t("directLinkDetails")}</h4>
                            )}
                            <hr
                              style={{
                                borderTop: "1px solid #bbb",
                                marginTop: "-1%",
                              }}
                            ></hr>
                            <div
                              className="form-row"
                              style={{ marginTop: "3%" }}
                            >
                              <FormGroup className="col-md-6">
                                <label htmlFor="projectReference">
                                  {t("projectLink")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectLink"
                                >
                                  *
                                </label>
                                <Field
                                  autoFocus={true}
                                  name="projectLink"
                                  placeholder={t("projectLink")}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.projectLink && touched.projectLink
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {errors.projectLink && touched.projectLink ? (
                                  <ErrorMessage
                                    name="projectLink"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                ) : (
                                  <span className="isValid" component="div">
                                    <br></br>
                                  </span>
                                )}
                              </FormGroup>
                              <FormGroup className="col-md-6">
                                <label htmlFor="projectTitle">
                                  {t("directLinkTitle")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectLink"
                                >
                                  *
                                </label>
                                <Field
                                  name="projectTitle"
                                  placeholder={t("directLinkTitle")}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.projectTitle && touched.projectTitle
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {errors.projectTitle && touched.projectTitle ? (
                                  <ErrorMessage
                                    name="projectTitle"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                ) : (
                                  <span className="isValid" component="div">
                                    <br></br>
                                  </span>
                                )}
                              </FormGroup>
                            </div>

                            <div className="form-row">
                              <FormGroup className="col-md-6">
                                <label htmlFor="projectClosingDate">
                                  {t("addProject.projectClosingDate")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>
                                <br></br>
                                <div className="customDatePickerWidth">
                                  <DatePicker
                                    selected={startDate}
                                    dateFormat="MMMM d, yyyy"
                                    className="form-control"
                                    minDate={minDate}
                                    name={startDate}
                                    onChange={(date) => {
                                      setFieldValue("startDate", date);
                                      setStartDate(date);
                                    }}
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup className="col-md-6">
                                <label htmlFor="countryID">
                                  {t("directLinkLocation")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="countryID"
                                >
                                  *
                                </label>
                                <Field
                                  style={{
                                    fontSize: "14px",
                                  }}
                                  name="countryID"
                                  placeholder={t(
                                    "createCompanyProfile.country"
                                  )}
                                  onChange={(v) => {
                                    setCountryId(v.target.value);
                                    setFieldValue("countryID", v.target.value);
                                  }}
                                  as="select"
                                  className={
                                    "form-control" +
                                    (errors.countryID && touched.countryID
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <option value="" defaultValue>
                                    {t(
                                      "createCompanyProfile.country.placeholder"
                                    )}
                                  </option>
                                  {countries &&
                                    countries.map((country) => (
                                      <option
                                        key={country.countryId}
                                        value={country.countryId}
                                      >
                                        {country.countryName}
                                      </option>
                                    ))}
                                </Field>

                                {errors.countryID && touched.countryID ? (
                                  <ErrorMessage
                                    name="countryID"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                ) : (
                                  <span className="isValid" component="div">
                                    <br></br>
                                  </span>
                                )}
                              </FormGroup>
                            </div>

                            <div className="form-row">
                              <FormGroup className="col">
                                <label htmlFor="projectDescription">
                                  {t("addProject.projectDescription")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>
                                <ReactQuill
                                  theme="snow"
                                  name="projectDescription"
                                  value={projectDescription}
                                  onChange={(e) => {
                                    // setFieldValue("description", e);
                                    setProjectDescription(e);
                                  }}
                                  modules={modules}
                                  formats={formats}
                                />
                              </FormGroup>
                            </div>

                            <div className="form-row">
                              <FormGroup className="col-md-12">
                                <label
                                  style={{
                                    fontSize: "25px",
                                    color: "red",
                                  }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>
                                <label>{t("requiredFields")}</label>
                              </FormGroup>
                            </div>

                            <div className="form-row">
                              <FormGroup
                                className={
                                  rtl ? "col-md-6  text-right" : "col-md-6 "
                                }
                              >
                                <label htmlFor="industryAndService">
                                  {t("opportunityCategory")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>

                                <i
                                  id="industries"
                                  className="fa fa-question-circle"
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <UncontrolledTooltip
                                    placement="right"
                                    target="#industries"
                                    delay={0}
                                  >
                                    {t("addproject.industry.info")}
                                  </UncontrolledTooltip>
                                </i>
                                {industriesOnly && (
                                  <Multiselect
                                    selectedValues={selectedIndustryList}
                                    style={
                                      rtl
                                        ? {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },

                                            option: {
                                              textAlign: "right",
                                            },
                                          }
                                        : {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },
                                          }
                                    }
                                    options={industriesOnly}
                                    placeholder={t("opportunityCategory")}
                                    hidePlaceholder={true}
                                    displayValue="typeOfIndustryName"
                                    //   groupBy="typeOfIndustryName"
                                    showCheckbox={true}
                                    selectionLimit={1}
                                    onSelect={(e) => {
                                      onIndustrySelectChangeAdd(e);
                                    }}
                                    onRemove={(e) => {
                                      onIndustrySelectChangeRemove(e);
                                    }}
                                  />
                                )}
                              </FormGroup>
                              <FormGroup className="col-md-6">
                                <label htmlFor="industry">
                                  {t("opportunitySpecialty")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>
                                <i
                                  id="services"
                                  className="fa fa-question-circle"
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <UncontrolledTooltip
                                    placement="right"
                                    target="#services"
                                    delay={0}
                                  >
                                    {t("addproject.industry.info.second")}
                                  </UncontrolledTooltip>
                                </i>
                                {servicesOnly && (
                                  <Multiselect
                                    selectedValues={selectedList}
                                    hidePlaceholder={true}
                                    style={
                                      rtl
                                        ? {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },

                                            option: {
                                              textAlign: "right",
                                            },
                                          }
                                        : {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },
                                          }
                                    }
                                    options={servicesOnly}
                                    placeholder={t("opportunitySpecialty")}
                                    displayValue="typeOfServiceName"
                                    groupBy="typeOfIndustryName"
                                    closeOnSelect={false}
                                    showCheckbox={true}
                                    emptyRecordMsg={t("invalid.services")}
                                    //  ref={servicesMultiselectRef}
                                    selectionLimit={1}
                                    onSelect={(e) => {
                                      onChange(e);
                                    }}
                                    onRemove={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                <span className="isValid" component="div">
                                  <br></br>
                                </span>
                              </FormGroup>
                            </div>
                            <FormGroup className="col-md-12 text-center">
                              <Button
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {isSubmitting && (
                                  <span className="spinner-border spinner-border-sm mr-1"></span>
                                )}
                                {t("createCompanyProfile.save")}
                              </Button>
                            </FormGroup>
                          </Container>
                        </CardBody>
                      </Form>
                    </Card>
                  </div>
                );
              }}
            </Formik>
          </>
        </Container>
      </div>
    </>
  );
}

export default AddEditDirectLink;
