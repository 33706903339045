import React, { useState } from "react";
import { useParams } from "react-router";
// reactstrap components
import { Button, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import { Paper, ThemeProvider } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { theme } from "../../../theme.js";
import Modal from "reactstrap/lib/Modal";
import ChatIcon from "@material-ui/icons/Chat";
import { adminService } from "_services/admin.service";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import { IconButton } from "@material-ui/core";
import moment from "moment";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { searchService } from "_services";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  FileCopyIcon: forwardRef((props, ref) => (
    <FileCopyIcon {...props} ref={ref} />
  )),
  AccountBalanceIcon: forwardRef((props, ref) => (
    <AccountBalanceIcon {...props} ref={ref} />
  )),
};

function MessagesCard({
  itemCount,
  bool,
  history,
  match,
  location,
  setItemCount,
  fetchCount,
}) {
  let msgtype = bool != null ? bool : location.state.bool;
  const { path, url } = match;
  const { id } = useParams();
  const [messages, setMessages] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const resetSearch = React.useRef(false);
  if (msgtype == false) {
    var columns = [
      {
        title: t("messages.1stParty"),
        field: "person1Name",
        width: 130,
      },
      {
        title: t("messages.2ndParty"),
        field: "person2Name",
        width: 130,
      },
      {
        title: t("partner.list.sent.invitations.date"),
        field: "dateTimeSent",
        width: 130,
      },
    ];
  } else {
    var columns = [
      {
        title: t("messages.1stParty"),
        field: "person1Name",
        width: 130,
      },
      {
        title: t("messages.2ndParty"),
        field: "person2Name",
        width: 130,
      },
      {
        title: t("messages.OpportunityName"),
        field: "projectName",
        width: 130,
      },
      {
        title: t("partner.list.sent.invitations.date"),
        field: "dateTimeSent",
        width: 130,
      },
    ];
  }

  let [currentPage, setCurrentPage] = useState(0);
  const convPerPage = pageSize;
  const begin = React.useRef(0);
  const end = React.useRef(convPerPage);

  const count = Math.ceil(itemCount / convPerPage);

  function next() {
    if (end.current > itemCount) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
    begin.current = (currentPage + 1) * convPerPage;
    end.current = begin.current + convPerPage;
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * convPerPage;
    end.current = begin.current + convPerPage;
  }

  const handleChange = (value) => {
    setPageSize(parseInt(value));
    end.current = begin.current + parseInt(value);
  };

  const handleSearch = async (e) => {
    // setSearchKeyword(e);
    const model = {
      Keyword: e,
      OnOpportunity: msgtype == true ? true : false,
      PageNumber: 0,
      PageSize: pageSize,
    };
    await searchService.SearchMessagesAsync(model).then((res) => {
      const tmpList =
        res &&
        res.map((msg) => ({
          person1Name: msg.person1Name,
          person2Name: msg.person2Name,
          projectName: msg.projectName,
          person1ID: msg.person1ID,
          person2ID: msg.person2ID,
          projectID: msg.projectID,
          dateTimeSent: moment(msg.dateTimeSent).format(
            "YYYY-MM-DD, h:mm:ss a"
          ),
        }));
      setMessages(tmpList);
      begin.current = 0;
      end.current = pageSize;
      setPageSize(pageSize);
    });
    await searchService.SearchMessagesCountAsync(model).then((res) => {
      setItemCount(res);
    });
  };

  const fetchData = async () => {
    let ConvToSkip = begin.current.toString();

    if (resetSearch.current == false) setLoading(true);
    if (msgtype == true) {
      await adminService
        .GetAllConvAsyncByOpp(ConvToSkip, convPerPage.toString(), false)
        .then((res) => {
          const tmpList =
            res &&
            res.map((msg) => ({
              person1Name: msg.person1Name,
              person2Name: msg.person2Name,
              projectName: msg.projectName,
              person1ID: msg.person1ID,
              person2ID: msg.person2ID,
              projectID: msg.projectID,
              dateTimeSent: moment(msg.dateTimeSent).format(
                "YYYY-MM-DD, h:mm:ss a"
              ),
            }));
          setMessages(tmpList);
        });
    } else {
      await adminService
        .GetAllConvWithoutOpp(ConvToSkip, convPerPage.toString(), false)
        .then((res) => {
          const tmpList =
            res &&
            res.map((msg) => ({
              person1Name: msg.person1Name,
              person2Name: msg.person2Name,
              projectName: msg.projectName,
              person1ID: msg.person1ID,
              person2ID: msg.person2ID,
              projectID: msg.projectID,
              dateTimeSent: moment(msg.dateTimeSent).format(
                "YYYY-MM-DD, h:mm:ss a"
              ),
            }));
          setMessages(tmpList);
        });
    }
    setLoading(false);
    resetSearch.current = false;
  };

  React.useEffect(() => {
    fetchData();

    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [end.current, begin.current, pageSize]);

  // if (loading) {
  //   return (
  //     <>
  //       <center
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           position: "absolute",
  //           left: "50%",
  //           top: "80%",
  //         }}
  //       >
  //         <PulseLoader color="#0E6EB8" />
  //       </center>
  //     </>
  //   );
  // }
  return (
    <>
      <Container>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            width: "100%",
            columnGap: "10rem",
          }}
        >
          <div
            style={{
              color: "#0e6eb8",
              fontSize: "1.25rem",
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
              fontWeight: 500,
              letterSpacing: "0.0075em",
              textTransform: "none",
              marginLeft: "15px",
            }}
          >
            {t("messageslogsHistory")}
          </div>
          <FormControl sx={{ m: 1 }} variant="standard">
            <Input
              id="searchInput"
              type="text"
              placeholder={t("search")}
              onChange={(e) => handleSearch(e.currentTarget.value)}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton aria-label="toggle password visibility">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      const searchInput =
                        document.getElementById("searchInput");
                      searchInput.value = "";
                      resetSearch.current = true;
                      fetchData();
                      fetchCount();
                    }}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Row>
        {loading && (
          <center
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "50%",
              top: "100%",
            }}
          >
            <PulseLoader color="#0E6EB8" />
          </center>
        )}
        {!loading && (
          <>
            <ThemeProvider theme={theme}>
              <MaterialTable
                // style={{ backgroundColor: "#2C2C34", color: "#d9dcde" }}
                components={{
                  Container: (props) => (
                    <Paper {...props} className="usersTable" elevation={0} />
                  ),
                }}
                title={<span></span>}
                actions={[
                  {
                    icon: () => (
                      <ChatIcon
                        className="actionIcon"
                        style={{ color: "#0E6EB8" }}
                      />
                    ),
                    tooltip: t("navbar.messages"),
                    onClick: (event, rowData) => {
                      history.push(`${url}/details`, {
                        state: rowData,
                      });
                      // const win = window.open(
                      //   `${url}/opportunity-messages/${rowData.senderProfileID}`,
                      //   "_blank"
                      // );
                      // win.focus();
                    },
                  },
                ]}
                columns={columns}
                data={messages}
                icons={tableIcons}
                onSearchChange={handleSearch}
                options={{
                  // pageSize: 10,
                  search: false,
                  paging: false,
                  actionsColumnIndex: -1,
                  cellStyle: {
                    textAlign: rtl ? "right" : "left",
                  },
                  headerStyle: {
                    textAlign: rtl ? "right" : "left",
                  },
                }}
                localization={{
                  header: {
                    actions: t("actions"),
                  },
                  toolbar: {
                    searchPlaceholder: t("search"),
                  },
                }}
              />
            </ThemeProvider>
            <div className="d-flex justify-content-end align-items-center">
              <select
                value={pageSize}
                onChange={(e) => handleChange(e.target.value)}
                style={{
                  height: "36px",
                  borderRadius: 0,
                  width: "auto",
                  fontSize: "12px",
                }}
                className={"form-control"}
              >
                <option
                  key={5}
                  value={5}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  5
                </option>
                <option
                  key={10}
                  value={10}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  10
                </option>
                <option
                  key={15}
                  value={15}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  15
                </option>
                <option
                  key={20}
                  value={20}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  20
                </option>
                <option
                  key={50}
                  value={50}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  50
                </option>
                <option
                  key={100}
                  value={100}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  100
                </option>
              </select>
              <IconButton
                aria-label="next"
                disabled={currentPage == 0 ? true : false}
              >
                {rtl ? (
                  <ArrowForwardIosSharpIcon
                    onClick={() => prev()}
                    style={{
                      color: "#d9dcde",
                    }}
                  />
                ) : (
                  <ArrowBackIosSharpIcon
                    onClick={() => prev()}
                    style={{
                      color: "#d9dcde",
                    }}
                  />
                )}
              </IconButton>
              <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
                {begin.current} -{" "}
                {end.current > itemCount ? itemCount : end.current}{" "}
                {rtl ? "من" : "of"} {itemCount}
              </p>
              <IconButton
                aria-label="next"
                disabled={end.current > itemCount ? true : false}
              >
                {rtl ? (
                  <ArrowBackIosSharpIcon
                    onClick={() => next()}
                    style={{
                      color: "#d9dcde",
                    }}
                  />
                ) : (
                  <ArrowForwardIosSharpIcon
                    onClick={() => next()}
                    style={{
                      color: "#d9dcde",
                    }}
                  />
                )}
              </IconButton>
            </div>
            <hr className="mt-0"></hr>
          </>
        )}
      </Container>
    </>
  );
}

export default MessagesCard;
