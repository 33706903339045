import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { forwardRef } from "react";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import { Paper, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../theme.js";
import { useTranslation } from "react-i18next";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
};

function InvitationsSent({ match, history, props }) {
  const { t } = useTranslation();
  var columns = [
    {
      title: t("partner.list.sent.invitations.contactName"),
      field: "profilesToReceiveInvitation.contactName",
    },
    {
      title: t("partner.list.sent.invitations.companyName"),
      field: "profilesToReceiveInvitation.companyName",
    },
    {
      title: t("partner.list.sent.invitations.email"),
      field: "profilesToReceiveInvitation.email",
    },
    {
      title: t("partner.list.sent.invitations.emailSent"),
      field: "emailSentDescription",
    },
    {
      title: t("partner.list.sent.invitations.date"),
      field: "formattedDate",
    },
  ];

  return (
    <div>
      {/* {props && (
      
      )} */}
      <ThemeProvider theme={theme}>
        <MaterialTable
          //  style={{ backgroundColor: "#2C2C34", color: "#d9dcde" }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          title={t("partner.list.sent.invitations")}
          columns={columns}
          data={props}
          icons={tableIcons}
          options={{
            paging: false,
          }}
        />
      </ThemeProvider>
    </div>
  );
}

export { InvitationsSent };
