import React, { useState, useContext } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { directLinkService, profileService } from "../../../_services";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import Badge from "reactstrap/lib/Badge";
import CardText from "reactstrap/lib/CardText";
import ReactCountryFlag from "react-country-flag";
import Modal from "reactstrap/lib/Modal";
import { ProjectStatus } from "../../../_helpers";
import { Link } from "react-router-dom";
import { LanguageContext } from "_context";
import CardTitle from "reactstrap/lib/CardTitle";

function DirectLinksList({ history, match }) {
  const { path } = match;
  const user = profileService.userValue;
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowDataID, setRowDataID] = useState(null);
  const fetchData = async () => {
    setLoading(true);
    await directLinkService
      .GetAllUserDirectLinksAsync(user.profile.userId, currentLan)
      .then((x) => {
        setInfo(x);
      });
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  async function deleteSelectedItem(id) {
    directLinkService
      .DeleteDirectLinkAsync(id)
      .then(() => {
        handleRemove(id);
        setDeleteAlert(!deleteAlert);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  function handleRemove(id) {
    const newList = info.filter((item) => item.id !== id);
    setInfo(newList);
  }

  function handleTogglePublish(index, flag) {
    let updatedList = info.map((item) => {
      if (item.id === index) {
        if (flag) {
          var defaultValue = new Date("0001-01-01T00:00:00.000+00:00");
          var itemDate = new Date(item.publicationDateTime);
          item.status = ProjectStatus.Published;
          if (itemDate.toString() === defaultValue.toString())
            item.publicationDateTime = new Date();
          return item;
        } else {
          return { ...item, status: ProjectStatus.Unpublished };
        }
      }
      return item;
    });
    setInfo(updatedList);
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  console.log(info);

  return (
    <>
      <Container>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div className="modal-body col-md-12">
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>

        <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("delete.alert")}</h5>
          </div>
          <div className="modal-body col-md-12">
            <p>{t("delete.alert.body")}</p>
          </div>
          <div className="modal-footer" style={{ justifyContent: "flex-end" }}>
            <center>
              <Link
                className="mr-2"
                type="button"
                style={{ margin: "auto", color: "#000000" }}
                onClick={(e) => setDeleteAlert(!deleteAlert)}
              >
                {t("cancel.link")}
              </Link>
              <Button
                color="danger"
                type="button"
                className="mr-2"
                style={
                  rtl
                    ? { justifyContent: "flex-start" }
                    : { justifyContent: "flex-end" }
                }
                onClick={(e) => deleteSelectedItem(rowDataID)}
              >
                {t("delete")}
              </Button>
            </center>
          </div>
        </Modal>

        <div className={rtl ? "section text-right" : "section"}>
          {info && info.length !== 0 && (
            <center>
              <Button
                color="primary"
                onClick={() => {
                  history.push(`${path}/add-link`);
                }}
              >
                {t("addLink")}
              </Button>
            </center>
          )}
          {info && info.length === 0 ? (
            <Row>
              <Card className="text-center" style={{ boxShadow: "none" }}>
                <CardBody>
                  <CardTitle tag="h4">{t("directLinks.empty.first")}</CardTitle>
                  <CardText>{t("directLinks.empty.second")}</CardText>
                  <Button
                    color="primary"
                    //  href="#pablo"
                    onClick={() => history.push(`${path}/add-link`)}
                  >
                    {t("addLink")}
                  </Button>
                </CardBody>
              </Card>
            </Row>
          ) : (
            <Row>
              {info &&
                info.map((project) => {
                  return (
                    <>
                      <Card
                        style={
                          rtl
                            ? {
                                marginBottom: "15px",
                                paddingBottom: "10px",
                                direction: "rtl",
                                textAlign: "right",
                              }
                            : { marginBottom: "15px", paddingBottom: "10px" }
                        }
                      >
                        <CardBody style={{ paddingBottom: "0px" }}>
                          <Row>
                            <Col md="9">
                              {project.link &&
                              !project.link.startsWith("http://") &&
                              !project.link.startsWith("https://") ? (
                                <a
                                  className="col-md-6"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`http://${project.link}`}
                                >
                                  {project.link}
                                </a>
                              ) : (
                                <a
                                  className="col-md-6"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={project.link !== null && project.link}
                                >
                                  {project.link !== null && project.link}
                                </a>
                              )}
                              <Badge
                                tag={Link}
                                id="unpublishButton"
                                hidden={
                                  project.status != ProjectStatus.Published
                                }
                                style={{
                                  marginLeft: "20px",
                                  marginRight: "20px",
                                  marginTop: "-50px",
                                  border: "none",
                                  backgroundColor: "#112A84",
                                  color: "#ffffff",
                                }}
                              >
                                {project.status}
                              </Badge>

                              <Badge
                                tag={Link}
                                id="publishButton"
                                hidden={
                                  project.status != ProjectStatus.Unpublished
                                }
                                style={{
                                  marginLeft: "20px",
                                  marginRight: "20px",
                                  marginTop: "-50px",
                                  border: "none",
                                  backgroundColor: "#f5f5fa",
                                  color: "#000000",
                                }}
                              >
                                {project.status}
                              </Badge>

                              <Badge
                                tag={Link}
                                id="closedButton"
                                hidden={
                                  project.status ===
                                    ProjectStatus.Unpublished ||
                                  project.status === ProjectStatus.Published
                                }
                                style={
                                  project.status === ProjectStatus.NotAwarded ||
                                  project.status ===
                                    ProjectStatus.NoCooperation ||
                                  project.status === ProjectStatus.Withdrawn
                                    ? {
                                        marginLeft: "20px",
                                        marginRight: "20px",
                                        marginTop: "-50px",
                                        border: "none",
                                        backgroundColor: "orange",
                                        color: "#ffffff",
                                      }
                                    : {
                                        marginLeft: "20px",
                                        marginRight: "20px",
                                        marginTop: "-50px",
                                        border: "none",
                                        backgroundColor: "red",
                                        color: "#ffffff",
                                      }
                                }
                              >
                                {project.status}
                              </Badge>
                            </Col>
                            <Col
                              md="3"
                              style={{
                                marginTop: "-10px",
                              }}
                            >
                              <Button
                                id=""
                                color="primary"
                                outline
                                onClick={() => {
                                  history.push(`${path}/edit/${project.id}`);
                                }}
                              >
                                <i class="fa fa-edit" aria-hidden="true"></i>
                              </Button>
                              <Button
                                id=""
                                color="primary"
                                onClick={() => {
                                  directLinkService
                                    .TogglePublishDirectLinkAsync(
                                      project.id,
                                      project.status === ProjectStatus.Published
                                        ? false
                                        : true
                                    )
                                    .then(() => {
                                      handleTogglePublish(
                                        project.id,
                                        project.status ===
                                          ProjectStatus.Published
                                          ? false
                                          : true
                                      );
                                    })
                                    .catch((error) => {
                                      if (error.message !== undefined) {
                                        setError(error.message);
                                        if (
                                          error.error !== null &&
                                          error.error.length > 0
                                        ) {
                                          for (
                                            var i = 0;
                                            i < error.error.length;
                                            i++
                                          ) {
                                            if (
                                              error.error[i].languageName ==
                                              currentLan
                                            ) {
                                              setError(error.error[i].error);
                                            }
                                          }
                                        }
                                      } else {
                                        setError(error);
                                      }
                                      setErrorAlert(!errorAlert);
                                    });
                                }}
                              >
                                {project.status === ProjectStatus.Published
                                  ? t("unpublish")
                                  : t("publish")}
                              </Button>

                              <Button
                                id="deleteButton"
                                color="danger"
                                onClick={() => {
                                  setRowDataID(project.id);
                                  setDeleteAlert(!deleteAlert);
                                }}
                              >
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </Button>
                            </Col>
                          </Row>
                          {project !== null && project.title !== null ? (
                            <Col
                              md="11"
                              style={{
                                marginBottom: "20px",
                                marginTop: "auto",
                              }}
                            >
                              {project.title}
                            </Col>
                          ) : null}

                          <div style={{ display: "flex", margin: "10px 5px" }}>
                            <CardText style={{ width: "350px" }}>
                              <i
                                class="fas fa-map-marker-alt"
                                aria-hidden="true"
                                style={{
                                  paddingRight: "10px",
                                  paddingLeft: "10px",
                                }}
                              ></i>
                              {project !== null &&
                              project.country !== null &&
                              project.country.countryCode !== null ? (
                                <>
                                  <ReactCountryFlag
                                    countryCode={project.country.countryCode}
                                    svg
                                    style={{
                                      width: "1.5em",
                                      height: "1.5em",
                                      marginTop: "-2px",
                                      marginRight: "10px",
                                      borderRadius: "0px",
                                    }}
                                    title="US"
                                  />
                                </>
                              ) : null}

                              <small style={{ fontSize: "80%" }}>
                                {project !== null &&
                                project.country !== null &&
                                project.country.countryName !== null
                                  ? " " + project.country.countryName
                                  : ""}
                              </small>
                            </CardText>
                          </div>
                          <div style={{ display: "flex", marginTop: "-10px" }}>
                            <CardText style={{ width: "350px" }}>
                              <i
                                className="fa fa-upload"
                                aria-hidden="true"
                                style={{
                                  paddingRight: "13px",
                                  paddingLeft: "13px",
                                }}
                              ></i>
                              <small style={{ fontSize: "80%" }}>
                                {`${new Date(
                                  project.publicationDateTime
                                ).getUTCDate()}/${
                                  monthNames[
                                    new Date(
                                      project.publicationDateTime
                                    ).getUTCMonth()
                                  ]
                                }/${new Date(
                                  project.publicationDateTime
                                ).getFullYear()}` !== "1/January/1"
                                  ? `${new Date(
                                      project.publicationDateTime
                                    ).getUTCDate()}/${
                                      monthNames[
                                        new Date(
                                          project.publicationDateTime
                                        ).getUTCMonth()
                                      ]
                                    }/${new Date(
                                      project.publicationDateTime
                                    ).getFullYear()}`
                                  : ""}
                              </small>
                            </CardText>
                            <CardText style={{ width: "350px" }}>
                              <i
                                class="fa fa-hourglass-end"
                                aria-hidden="true"
                                style={{
                                  paddingRight: "12px",
                                  paddingLeft: "12px",
                                }}
                              ></i>
                              <small style={{ fontSize: "80%" }}>
                                {project.closingDate !== null &&
                                `${new Date(
                                  project.closingDate
                                ).getUTCDate()}/${
                                  monthNames[
                                    new Date(project.closingDate).getUTCMonth()
                                  ]
                                }/${new Date(
                                  project.closingDate
                                ).getFullYear()}` !== "1/January/1"
                                  ? `${new Date(
                                      project.closingDate
                                    ).getUTCDate()}/${
                                      monthNames[
                                        new Date(
                                          project.closingDate
                                        ).getUTCMonth()
                                      ]
                                    }/${new Date(
                                      project.closingDate
                                    ).getFullYear()}`
                                  : ""}
                              </small>
                            </CardText>
                          </div>
                          <div
                            style={{ display: "flex", marginTop: "-10px" }}
                          ></div>
                          <Col style={{ marginTop: "7px" }}>
                            <Row>
                              <div
                                style={{
                                  paddingLeft: "3px",
                                  paddingRight: "3px",
                                }}
                              >
                                <Badge
                                  style={{
                                    backgroundColor: "#f5f5fa",
                                    color: "#000000",
                                    border: "none",
                                    fontSize: "85%",
                                  }}
                                >
                                  {project !== null &&
                                  project.typeOfIndustry !== null &&
                                  project.typeOfIndustry.typeOfIndustryName !==
                                    null
                                    ? project.typeOfIndustry.typeOfIndustryName
                                    : ""}
                                </Badge>
                              </div>
                              <div
                                style={{
                                  paddingLeft: "3px",
                                  paddingRight: "3px",
                                }}
                              >
                                <Badge
                                  style={{
                                    backgroundColor: "#f5f5fa",
                                    color: "#000000",
                                    border: "none",
                                    fontSize: "85%",
                                  }}
                                >
                                  {project !== null &&
                                  project.typeOfIndustry !== null &&
                                  project.typeOfIndustry.typeOfServiceName !==
                                    null
                                    ? project.typeOfIndustry.typeOfServiceName
                                    : ""}
                                </Badge>
                              </div>
                            </Row>
                          </Col>
                        </CardBody>
                      </Card>
                    </>
                  );
                })}
            </Row>
          )}
        </div>
      </Container>
    </>
  );
}

export default DirectLinksList;
