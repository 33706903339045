import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditFeaturedIn from "./AddEditFeaturedIn";
import FeaturedInList from "./FeaturedInList";

function FeaturedIn({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={FeaturedInList} exact />
        <PrivateRoute path={`${path}/add`} component={AddEditFeaturedIn} />
        <PrivateRoute path={`${path}/edit/:id`} component={AddEditFeaturedIn} />
      </Switch>
    </div>
  );
}

export { FeaturedIn };
