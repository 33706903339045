import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/Analytics`;

export const analyticsService = {
    GetProfilesPackedCircleAsync,
    GetProjectsNumberByTypeBarAsync,
    GetProjectsPublishedByMonthAsync, 
    GetUserLogInsAsync,
    GetOpportunitiesViews,
    GetAllUsersLastLogIn,
    GetSpFinancingAndPartnershipBarAsync,
    GetProjectsByCategorySunburstInputAsync,
    GetSpsByCategorySunburstInputAsync,
    GetProjectInteractionBarInputAsync,
    GetSpsByCategoryPieChartInputAsync,
    GetSpsBySpecialtyPieChartInputAsync
};



function GetProfilesPackedCircleAsync() {
    return fetchWrapper.get(`${baseUrl}/GetProfilesPackedCircle`,{});
}

function GetProjectsNumberByTypeBarAsync() {
    return fetchWrapper.get(`${baseUrl}/GetProjectsNumberByTypeBar`,{});
}

function GetProjectsPublishedByMonthAsync(year) {
    return fetchWrapper.get(`${baseUrl}/GetProjectsPublishedByMonth/${year}`,{});
}
function GetUserLogInsAsync(dateFrom,dateTo) {
    return fetchWrapper.get(`${baseUrl}/GetNumberOfLoginsByDate/${dateFrom}/${dateTo}`);
}
function GetOpportunitiesViews(model) {
    return fetchWrapper.post(`${baseUrl}/GetOpportunitiesViews`,model);
}
function GetAllUsersLastLogIn() {
    return fetchWrapper.get(`${baseUrl}/GetAllUsersLastLogIn`);
}

function GetSpFinancingAndPartnershipBarAsync() {
    return fetchWrapper.get(`${baseUrl}/GetSpFinancingAndPartnershipBar`);
}

function GetProjectsByCategorySunburstInputAsync() {
    return fetchWrapper.get(`${baseUrl}/GetProjectsByCategorySunburstInput`);
}

function GetSpsByCategorySunburstInputAsync() {
    return fetchWrapper.get(`${baseUrl}/GetSpsByCategorySunburstInput`);
}

function GetProjectInteractionBarInputAsync() {
    return fetchWrapper.get(`${baseUrl}/GetOpportunityTopInteractionsInput`);
}

function GetSpsByCategoryPieChartInputAsync() {
    return fetchWrapper.get(`${baseUrl}/GetSpsByCategoryPieChartInput`);
}

function GetSpsBySpecialtyPieChartInputAsync(categoryId) {
    return fetchWrapper.get(`${baseUrl}/GetSpsBySpecialtyPieChartInput/${categoryId}`);
}