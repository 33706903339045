import React, { useEffect, useState, useContext } from "react";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { InvitationsSent } from "./InvitationsSent";
import Row from "reactstrap/lib/Row";
import CardTitle from "reactstrap/lib/CardTitle";
import CardText from "reactstrap/lib/CardText";
import { useTranslation } from "react-i18next";
import { SSInvitationHistoryContext } from "_context";

function InvitationHistory({ match, props }) {
  const { t } = useTranslation();
  return (
    <>
      {props && props.length > 0 ? (
        <Card>
          <CardBody>
            <InvitationsSent fromHistory={true} props={props} />
          </CardBody>
        </Card>
      ) : (
        <Row>
          <Card className="text-center" style={{ boxShadow: "none" }}>
            <CardBody>
              <CardTitle tag="h4">{t("ss.invitation.history.empty")}</CardTitle>
              <CardText>{t("ss.invitation.history.empty.action")}</CardText>
            </CardBody>
          </Card>
        </Row>
      )}
    </>
  );
}

export { InvitationHistory };
