import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import CMSHome from "./CMSHome";
import { OurPartners } from "./OurPartners/Index";
import { OurAim } from "./OurAim/Index";
import { UsageAndBenefits } from "./UsageAndBenefits/Index";
import { IfYouAre } from "./IfYouAre/Index";
import { IfYouBelieveIn } from "./IfYouBelieveIn/Index";
import { WhoWeAre } from "./WhoWeAre/Index";
import { WhoWeAreTypes } from "./WhoWeAreTypes/Index";
import { FeaturedIn } from "./FeaturedIn/Index";
import { LandingPageSections } from "./LandingPageSections/Index";
import { Testimonials } from "./Testimonials/Index";
import { Videos } from "./Videos/Index";
import { Challenge } from "./Challenge/Index";

function AdminCMS({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={CMSHome} exact />
        <PrivateRoute path={`${path}/our-partners`} component={OurPartners} />
        <PrivateRoute path={`${path}/featuredIn`} component={FeaturedIn} />
        <PrivateRoute path={`${path}/our-aim`} component={OurAim} />
        <PrivateRoute
          path={`${path}/our-usageandbenefits`}
          component={UsageAndBenefits}
        />
        <PrivateRoute
          path={`${path}/whowearetypes`}
          component={WhoWeAreTypes}
        />
        <PrivateRoute path={`${path}/who-we-are`} component={WhoWeAre} />
        <PrivateRoute path={`${path}/if-you-are`} component={IfYouAre} />
        <PrivateRoute
          path={`${path}/if-you-believe-in`}
          component={IfYouBelieveIn}
        />
        <PrivateRoute
          path={`${path}/sections`}
          component={LandingPageSections}
        />
        <PrivateRoute path={`${path}/video`} component={Videos} />
        <PrivateRoute
          path={`${path}/million-dollar-challenge`}
          component={Challenge}
        />
        <PrivateRoute path={`${path}/testimonials`} component={Testimonials} />
      </Switch>
    </div>
  );
}

export { AdminCMS };
