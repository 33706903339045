import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/Logs`;

export const logsService = {
  GetAllLogsAsync,
  GetLogById,
  GetLogsCount,
};

function GetAllLogsAsync(pageNumber, pageSize) {
  return fetchWrapper.get(`${baseUrl}/GetAllLogs/${pageNumber}/${pageSize}`);
}
function GetLogById(id) {
  return fetchWrapper.get(`${baseUrl}/GetLogById/${id}`);
}

function GetLogsCount() {
  return fetchWrapper.get(`${baseUrl}/GetLogsCount`);
}
