import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { Button, Container, Modal } from "reactstrap";
import { profileService } from "../../_services";
import { LanguageContext } from "_context";
import queryString from "query-string";

function Unsubscribe({ history, location }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const parsed = queryString.parse(location.search);
  useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    // document.body.style = "background-color: #f5f5fa";
    document.documentElement.classList.remove("nav-open");
    if (!fetched) {
      onSubmit();
    }
    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [fetched, setFetched] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);

  async function onSubmit() {
    setFetched(!fetched);
    const model = {
      resetToken: encodeURI(parsed.token),
    };
    await profileService
      .UnsubScribeProfileAsync(model)
      .then(() => {
        setSuccessAlert(!successAlert);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  } else
    return (
      <>
        <div
          className="section section-signup"
          style={{
            backgroundColor: "#f5f5fa",
            backgroundSize: "cover",
            marginTop: "50px",
          }}
        >
          <Container style={{ marginTop: "-5%" }}>
            <Modal
              isOpen={successAlert}
              style={rtl ? { textAlign: "right" } : {}}
            >
              <div className="modal-header" style={{ margin: "0 auto" }}>
                <h5 className="modal-title">{t("unsubscribe.title")}</h5>
              </div>
              <div className={rtl ? "modal-body text-right" : "modal-body"}>
                <p>{t("unsubscribe.message")}</p>
              </div>
              <div className="modal-footer">
                <Button
                  color="primary"
                  type="button"
                  style={
                    rtl
                      ? { margin: "auto", textAlign: "right" }
                      : { margin: "auto" }
                  }
                  onClick={(e) => {
                    setSuccessAlert(!successAlert);
                    history.push("/account/login");
                  }}
                >
                  {t("close.button")}
                </Button>
              </div>
            </Modal>
            <Modal
              isOpen={errorAlert}
              style={rtl ? { textAlign: "right" } : {}}
            >
              <div
                className="modal-header text-center"
                style={{ margin: "0 auto" }}
              >
                <h5 className="modal-title">{t("error.alert")}</h5>
              </div>
              <div
                className={
                  rtl
                    ? "modal-body col-md-12 text-right"
                    : "modal-body ol-md-12"
                }
              >
                <p>{errorMessage}</p>
              </div>
              <div className="modal-footer">
                <Button
                  color="danger"
                  type="button"
                  style={
                    rtl
                      ? { margin: "auto", textAlign: "right" }
                      : { margin: "auto" }
                  }
                  onClick={(e) => {
                    setErrorAlert(!errorAlert);
                    history.push("/account/login");
                  }}
                >
                  {t("close.button")}
                </Button>
              </div>
            </Modal>
          </Container>
        </div>
      </>
    );
}

export default Unsubscribe;
