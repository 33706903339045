import React, { useState } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import "../../parsing.css";
import { profileService, projectService } from "../../../_services";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import CardSubtitle from "reactstrap/lib/CardSubtitle";
import CardText from "reactstrap/lib/CardText";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import { useContext } from "react";
import Modal from "reactstrap/lib/Modal";
import ReactHtmlParser from "react-html-parser";
import Badge from "reactstrap/lib/Badge";
import FormGroup from "reactstrap/lib/FormGroup";
import { LanguageContext } from "../../../_context";
function ProjectDetails({ history, match }) {
  const projectID = match.params.projectID;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const user = profileService.userValue;
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [closingDate, setClosingDate] = useState(new Date());
  const [errorMessage] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const fetchData = async () => {
    setLoading(true);
    await projectService
      .GetProjectByIdAsync(projectID, false, null, currentLan)
      .then((project) => {
        setClosingDate(new Date(project.closingDate));
        setInfo(project);
      });
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <div className={rtl ? "section text-right" : "section"}>
        <Container>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          {info && (
            <Card style={rtl ? { textAlign: "right" } : {}}>
              <CardBody>
                <Link onClick={(e) => history.push(".")}>
                  {t("backToList")}
                </Link>
                <Row>
                  <Col md="10">
                    <CardTitle tag="h4">
                      {info.projectTitle !== null ? info.projectTitle : ""}
                    </CardTitle>
                  </Col>
                  <Col md="2">
                    <Button
                      color="primary"
                      onClick={() => {
                        history.push(
                          `/` +
                            user.profileTypeView.profileTypeDescription +
                            `/projects/edit-opportunity/${projectID}`
                        );
                      }}
                    >
                      <i className="fas fa-edit" aria-hidden="true"></i>
                    </Button>
                  </Col>
                </Row>

                <div style={{ display: "flex" }}>
                  <CardText style={{ width: "350px" }}>
                    <i
                      class="fa fa-cogs"
                      aria-hidden="true"
                      style={{
                        paddingRight: "10px",
                        paddingLeft: "10px",
                      }}
                    ></i>
                    <small style={{ fontSize: "80%" }}>
                      {t("project.type")}{" "}
                      {info.projectTypeView &&
                      info.projectTypeView.projectTypeName !== null
                        ? info.projectTypeView.projectTypeName
                        : ""}
                    </small>
                  </CardText>

                  {rtl ? (
                    <CardText style={{ width: "350px" }}>
                      <i
                        class="fas fa-map-marker-alt"
                        aria-hidden="true"
                        style={{
                          paddingRight: "10px",
                          paddingLeft: "10px",
                        }}
                      ></i>
                      <small style={{ fontSize: "80%" }}>
                        {t("project.location")}{" "}
                      </small>
                      <ReactCountryFlag
                        countryCode={
                          info.country && info.country.countryCode !== null
                            ? info.country.countryCode
                            : ""
                        }
                        svg
                        style={
                          rtl
                            ? {
                                width: "1.5em",
                                height: "1.5em",
                                marginTop: "-2px",
                                borderRadius: "0px",
                              }
                            : {
                                width: "1.5em",
                                height: "1.5em",
                                marginTop: "-2px",
                                marginRight: "10px",
                                borderRadius: "0px",
                              }
                        }
                        title="US"
                      />
                      <small
                        style={
                          rtl
                            ? { fontSize: "80%", marginRight: "10px" }
                            : { fontSize: "80%" }
                        }
                      >
                        {info.country && info.country.countryName !== null
                          ? info.country.countryName
                          : ""}
                      </small>
                    </CardText>
                  ) : (
                    <CardText style={{ width: "350px" }}>
                      <i
                        class="fas fa-map-marker-alt"
                        aria-hidden="true"
                        style={{
                          paddingRight: "10px",
                          paddingLeft: "10px",
                        }}
                      ></i>
                      <ReactCountryFlag
                        countryCode={
                          info.country && info.country.countryCode !== null
                            ? info.country.countryCode
                            : ""
                        }
                        svg
                        style={{
                          width: "1.5em",
                          height: "1.5em",
                          marginTop: "-2px",
                          marginRight: "10px",
                          borderRadius: "0px",
                        }}
                        title="US"
                      />

                      <small style={{ fontSize: "80%" }}>
                        {t("project.location")}{" "}
                        {info.country && info.country.countryName !== null
                          ? info.country.countryName
                          : ""}
                      </small>
                    </CardText>
                  )}
                </div>
                <div style={{ display: "flex", marginTop: "-10px" }}>
                  <CardText style={{ width: "350px" }}>
                    <i
                      className="fa fa-upload"
                      aria-hidden="true"
                      style={{
                        paddingRight: "13px",
                        paddingLeft: "13px",
                      }}
                    ></i>

                    <small style={{ fontSize: "80%" }}>
                      {t("project.publicationDate")}{" "}
                      {`${new Date(info.publicationDateTime).getUTCDate()}/${
                        monthNames[
                          new Date(info.publicationDateTime).getUTCMonth()
                        ]
                      }/${new Date(info.publicationDateTime).getFullYear()}` !==
                      "1/January/1"
                        ? `${new Date(info.publicationDateTime).getUTCDate()}/${
                            monthNames[
                              new Date(info.publicationDateTime).getUTCMonth()
                            ]
                          }/${new Date(info.publicationDateTime).getFullYear()}`
                        : ""}
                    </small>
                  </CardText>
                  <CardText style={{ width: "350px" }}>
                    <i
                      class="fa fa-hourglass-end"
                      aria-hidden="true"
                      style={{
                        paddingRight: "12px",
                        paddingLeft: "12px",
                      }}
                    ></i>

                    <small style={{ fontSize: "80%" }}>
                      {t("project.closingDate")}{" "}
                      {closingDate !== null &&
                      `${closingDate.getUTCDate()}/${
                        monthNames[closingDate.getUTCMonth()]
                      }/${closingDate.getFullYear()}` !== "1/January/1"
                        ? `${closingDate.getUTCDate()}/${
                            monthNames[closingDate.getUTCMonth()]
                          }/${closingDate.getFullYear()}`
                        : ""}
                    </small>
                  </CardText>
                </div>
                <div style={{ display: "flex", marginTop: "-10px" }}>
                  <CardText style={{ width: "350px" }}>
                    <i
                      className="fa fa-credit-card"
                      aria-hidden="true"
                      style={{
                        paddingRight: "13px",
                        paddingLeft: "13px",
                      }}
                    ></i>
                    <small style={{ fontSize: "80%" }}>
                      {t("search.budget")}{" "}
                      {info.budget && info.budget.description !== null
                        ? info.budget.description
                        : ""}
                    </small>
                  </CardText>
                </div>
                <Col style={{ marginTop: "7px" }}>
                  <Row>
                    <div
                      style={{
                        paddingLeft: "3px",
                        paddingRight: "3px",
                      }}
                    >
                      <Badge
                        style={{
                          backgroundColor: "#f5f5fa",
                          color: "#000000",
                          border: "none",
                          fontSize: "85%",
                        }}
                      >
                        {info.typeOfIndustry &&
                        info.typeOfIndustry.typeOfIndustryName !== null
                          ? info.typeOfIndustry.typeOfIndustryName
                          : ""}
                      </Badge>
                    </div>
                    <div
                      style={{
                        paddingLeft: "3px",
                        paddingRight: "3px",
                      }}
                    >
                      <Badge
                        style={{
                          backgroundColor: "#f5f5fa",
                          color: "#000000",
                          border: "none",
                          fontSize: "85%",
                        }}
                      >
                        {info.typeOfIndustry &&
                        info.typeOfIndustry.typeOfServiceName !== null
                          ? info.typeOfIndustry.typeOfServiceName
                          : ""}
                      </Badge>
                    </div>
                  </Row>
                </Col>
                {info &&
                  info.supportingDocuments &&
                  info.supportingDocuments.files.length !== 0 && (
                    <CardSubtitle
                      className="mb-2 text-muted"
                      style={{ fontWeight: "bold", marginTop: "20px" }}
                    >
                      {t("createCompanyProfile.supportingDocs")}
                    </CardSubtitle>
                  )}
                <Row>
                  {info &&
                    info.supportingDocuments &&
                    info.supportingDocuments.files &&
                    info.supportingDocuments.files.map((file) => {
                      return (
                        <a
                          className="col-md-8"
                          target="_blank"
                          rel="noreferrer"
                          href={file.filePath}
                        >
                          {file.fileName}
                        </a>
                      );
                    })}
                </Row>

                {info.publishTo !== null &&
                  ((info.publishTo.typeOfIndustriesAndServices !== null &&
                    info.publishTo.typeOfIndustriesAndServices !== undefined &&
                    info.publishTo.typeOfIndustriesAndServices.length > 0) ||
                    (info.publishTo.countries !== null &&
                      info.publishTo.countries !== undefined &&
                      info.publishTo.countries.length > 0) ||
                    (info.publishTo.numberOfEmployees !== null &&
                      info.publishTo.numberOfEmployees !== undefined &&
                      info.publishTo.numberOfEmployees.length > 0) ||
                    (info.publishTo.spType !== null &&
                      info.publishTo.spType !== undefined)) && (
                    <>
                      <hr />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        style={{ fontWeight: "bold", marginTop: "20px" }}
                      >
                        {t("addProject.projectPublishTo")}
                      </CardSubtitle>
                      <Row>
                        {info.publishTo !== null &&
                          info.publishTo.typeOfIndustriesAndServices !== null &&
                          info.publishTo.typeOfIndustriesAndServices !==
                            undefined &&
                          info.publishTo.typeOfIndustriesAndServices.length >
                            0 && (
                            <FormGroup className="col-md-3">
                              <div>
                                <label
                                  className="col-md-6"
                                  htmlFor="companyName"
                                  style={{ fontWeight: "bold", padding: "0px" }}
                                >
                                  {t("filterSearch.industries.placeholder")}
                                </label>
                                <div
                                  className="col-md-6"
                                  style={{
                                    //   display: "grid",
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                  }}
                                >
                                  {info.publishTo.typeOfIndustriesAndServices.map(
                                    (industry) => {
                                      return (
                                        <>
                                          <label
                                          // className="col-md-12"
                                          >
                                            {industry.typeOfIndustryName}
                                          </label>
                                          <br></br>
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </FormGroup>
                          )}

                        {info.publishTo !== null &&
                          info.publishTo.countries !== null &&
                          info.publishTo.countries !== undefined &&
                          info.publishTo.countries.length > 0 && (
                            <FormGroup className="col-md-3">
                              <div>
                                <label
                                  className="col-md-6"
                                  htmlFor="companyName"
                                  style={{ fontWeight: "bold", padding: "0px" }}
                                >
                                  {t("filterSearch.country.placeholder")}
                                </label>
                                <div
                                  className="col-md-6"
                                  style={{
                                    //   display: "grid",
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                  }}
                                >
                                  {info.publishTo.countries.map((country) => {
                                    return (
                                      <>
                                        <label
                                        // className="col-md-12"
                                        >
                                          {country.countryName}
                                        </label>
                                        <br></br>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </FormGroup>
                          )}
                        {info.publishTo !== null &&
                          info.publishTo.numberOfEmployees !== null &&
                          info.publishTo.numberOfEmployees !== undefined &&
                          info.publishTo.numberOfEmployees.length > 0 && (
                            <FormGroup className="col-md-3">
                              <div>
                                <label
                                  className="col-md-6"
                                  htmlFor="companyName"
                                  style={{ fontWeight: "bold", padding: "0px" }}
                                >
                                  {t("filterSearch.companySize.placeholder")}
                                </label>
                                <div
                                  className="col-md-6"
                                  style={{
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                  }}
                                >
                                  {info.publishTo.numberOfEmployees.map(
                                    (nbEmp) => {
                                      return (
                                        <>
                                          <label
                                          // className="col-md-12"
                                          >
                                            {nbEmp.description}
                                          </label>
                                          <br></br>
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </FormGroup>
                          )}
                        {info.publishTo !== null &&
                          info.publishTo.spType !== null &&
                          info.publishTo.spType !== undefined && (
                            <FormGroup className="col-md-3">
                              <div>
                                <label
                                  className="col-md-6"
                                  htmlFor="companyName"
                                  style={{ fontWeight: "bold", padding: "0px" }}
                                >
                                  {t("signUp.providerType.title")}
                                </label>
                                <div
                                  className="col-md-6"
                                  style={{
                                    //   display: "grid",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                  }}
                                >
                                  <label
                                  // className="col-md-12"
                                  >
                                    {info.publishTo.spType}
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                          )}
                      </Row>
                    </>
                  )}

                <hr />
                <CardTitle tag="h5">{t("opportunity.description")}</CardTitle>
                <hr />
                {info.projectDescription !== null ? (
                  <div className="parsing">
                    {ReactHtmlParser(info.projectDescription)}
                  </div>
                ) : (
                  ""
                )}

                <hr />
                <CardTitle tag="h5">{t("nda.title")}</CardTitle>
                <hr />
                {info.ndaText !== null ? (
                  <div className="parsing">{ReactHtmlParser(info.ndaText)}</div>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </>
  );
}

export default ProjectDetails;
