import React, { useState, useContext } from "react";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { projectTypeService } from "_services";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import { LanguageContext } from "_context";
import { adminService } from "_services";
import { systemSetupService } from "_services";
import * as Yup from "yup";
import { sassNull } from "sass";

function EditVariable({ history, match, location }) {
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [initialValues, setInitialValues] = useState({
    value: "",
    stringValue: "",
    stringList: [],
  });
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [inputList, setInputList] = useState([{ stringList: "" }]);
  //const [stringList, setList] = useState([]);
  let validationSchema;

  location.state.state.type === "string" || location.state.state.type === "int"
    ? (validationSchema = Yup.object().shape({
        value:
          location.state.state.type === "string"
            ? Yup.string().required(t("fieldRequired"))
            : Yup.number()
                .typeError(t("numberValidation"))
                .integer(t("integerValidation"))
                .required(t("fieldRequired")),
      }))
    : (validationSchema = Yup.object().shape({}));

  const fetchData = async () => {
    setLoading(true);
    editInitialForm(location.state.state);
    const list = [];
    if (
      location.state.state != null &&
      location.state.state.stringList != null
    ) {
      location.state.state.stringList.forEach((item) => {
        var model = { stringList: item };
        list.push(model);
      });
      console.log(list);
      if (list !== null && list.length > 0) {
        setInputList(list);
      } else {
        setInputList([{ stringList: "" }]);
      }
    }

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function editInitialForm(variable) {
    var newJson = {
      value:
        variable.type === "string"
          ? variable.stringValue
          : variable.type === "int"
          ? variable.value
          : variable.stringList,
    };
    setInitialValues(newJson);
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    if (list.length === 0) {
      setInputList([{ stringList: "" }]);
    }
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { stringList: "" }]);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  async function onSubmit(fields, { setStatus, setSubmitting }) {
    var stringList = [];
    setStatus();
    var model;
    if (
      location.state.state != null &&
      location.state.state.type != null &&
      location.state.state.type === "string"
    ) {
      if (
        location.state.state.description != null &&
        location.state.state.description === "CompanyName"
      ) {
        model = {
          stringValue: fields.value,
          company: true,
        };
      } else {
        model = {
          stringValue: fields.value,
        };
      }
    } else if (
      location.state.state != null &&
      location.state.state.type != null &&
      location.state.state.type === "int"
    ) {
      model = {
        value: fields.value,
        surveyThreshold: true,
      };
    } else if (
      location.state.state != null &&
      location.state.state.type != null &&
      location.state.state.type === "list"
    ) {
      inputList.forEach((item, index) => {
        stringList.push(item.stringList.trim());
        //setList([...stringList, item.stringList]);
      });
      model = {
        stringList,
        surveyThreshold: false,
      };
    }
    console.log(stringList);
    console.log(inputList);
    if (
      location.state.state != null &&
      location.state.state.type != null &&
      location.state.state.type === "list" &&
      (stringList === null ||
        stringList.length === 0 ||
        stringList.includes(""))
    ) {
      setErrorAlert(true);
      setError(t("required.all"));
    } else {
      await systemSetupService
        .UpdateSystemSetupAsync(location.state.state.id, model)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    }
  }
  return (
    <div className="section">
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("done")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>{t("variable.edit.success")}</p>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setAlert(!alert);
                history.push("..");
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, errors, touched }) => {
              return (
                <Col md="8" style={{ margin: "auto" }}>
                  <Card className={rtl ? "text-right" : ""}>
                    <Form autoComplete="off">
                      <CardBody>
                        <CardTitle className="text-center" tag="h4">
                          {t("editVariable")}
                        </CardTitle>
                        <Row>
                          <Col md="12">
                            {location.state.state.type === "string" ||
                            location.state.state.type === "int" ? (
                              <div className="form-group">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("variable.value")}
                                  </label>
                                  <Field
                                    //required={true}
                                    autoFocus={true}
                                    name="value"
                                    placeholder={t("variable.value")}
                                    className={
                                      "form-control" +
                                      (errors.value && touched.value
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  {errors.value && touched.value && (
                                    <ErrorMessage
                                      name="value"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  )}
                                </FormGroup>
                              </div>
                            ) : (
                              inputList.map((x, i) => {
                                return (
                                  <div className="form-row">
                                    <FormGroup className="col-md-4">
                                      <label htmlFor="stringList">
                                        {t("variable.value") + " " + (i + 1)}
                                      </label>
                                      <label
                                        style={{
                                          fontSize: "25px",
                                          color: "red",
                                        }}
                                        htmlFor="projectTypeID"
                                      >
                                        <br></br>
                                      </label>
                                      <Field
                                        placeholder={t("variable.value")}
                                        name="stringList"
                                        type="text"
                                        className={
                                          rtl
                                            ? "text-right form-control"
                                            : //+
                                              // (errors.stringList &&
                                              // touched.stringList
                                              //   ? " is-invalid"
                                              //   : "")
                                              "form-control"
                                          //+
                                          // (errors.stringList &&
                                          // touched.stringList
                                          //   ? " is-invalid"
                                          //   : "")
                                        }
                                        value={x.stringList}
                                        onChange={(e) =>
                                          handleInputChange(e, i)
                                        }
                                      />
                                      {/* {errors.stringList &&
                                        touched.stringList && (
                                          <ErrorMessage
                                            name="stringList"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )} */}
                                    </FormGroup>
                                    {inputList.length > 0 && (
                                      <Button
                                        style={{
                                          height: "50%",
                                          marginTop: "43px",
                                        }}
                                        className="mr10"
                                        onClick={(x) => handleRemoveClick(i)}
                                        color="danger"
                                      >
                                        <i
                                          class="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    )}
                                    {inputList.length - 1 === i && (
                                      <Button
                                        onClick={handleAddClick}
                                        style={{
                                          height: "50%",
                                          marginTop: "43px",
                                        }}
                                        color="primary"
                                      >
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    )}
                                  </div>
                                );
                              })
                            )}

                            <div className="form-row">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <center>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {t("createCompanyProfile.save")}
                                  </Button>
                                </center>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default EditVariable;
