import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import SearchPartners from "./SearchPartners";
import SearchPartnersDetails from "./SearchPartnerDetails";

function SearchPartnersIndex({ history, match }) {
  const { path } = match;
  return (
    <>
      <Switch>
        <PrivateRoute exact path={`${path}`} component={SearchPartners} />
        <PrivateRoute
          exact
          path={`${path}/search-partner-details/:userId`}
          component={SearchPartnersDetails}
        />
      </Switch>
    </>
  );
}

export { SearchPartnersIndex };
