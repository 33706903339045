import React, { useContext, useEffect, useState } from "react";
import "assets/css/mainSection.css";
import { AccountTypes } from "_helpers";
import {
  profileService,
  landingPageService,
  testimonialService,
  languageService,
} from "_services";
import { ProfileStatus } from "_helpers";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { WhoWeAreUserTypes } from "_helpers/WhoWeAreUserTypes";
import Modal from "reactstrap/lib/Modal";
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import ReactCountryFlag from "react-country-flag";
import Tooltip from "@mui/material/Tooltip";
import { LanguageContext } from "_context";
import PulseLoader from "react-spinners/PulseLoader";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import FormGroup from "reactstrap/lib/FormGroup";
import * as Scroll from "react-scroll";
import { adminService, projectService } from "_services";
import ReactHtmlParser, { processNodes } from "react-html-parser";
import { projectSeenService } from "_services";
import { spSeenService } from "_services";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavItem, NavLink, Nav, Navbar } from "reactstrap";
import ReactQuill from "react-quill";
import { Alert } from "reactstrap";

function Sections({ history, location, match }) {
  const { path } = match;
  const user = profileService.userValue;
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const { innerWidth: width, innerHeight: height } = window;
  const [currentLan] = useContext(LanguageContext);
  const [partners, setPartners] = useState([]);
  const [ifYouAre, setIfYouAre] = useState([]);
  const [featuredIn, setFeaturedIn] = useState([]);
  const [corporateAndDev, setCorporateAndDev] = useState([]);
  const [pple, setWPple] = useState([]);
  const [projects, setProjects] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [testimonialsIndex, setTestimonialsIndex] = useState(0);
  const [sections, setSections] = useState([]);
  const [sectionCode, setSectionCode] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [languagesName, setLanguagesName] = useState([]);
  const [modalAlert, setModalAlert] = useState(false);
  const [projectsList, setProjectsList] = useState([]);
  const [timer, setTimer] = useState(0);

  const [ndaAlert, setNdaAlert] = useState(false);
  const [ndaMessage, setNdaMessage] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [initialValues, setInitialValues] = useState({ sectionContent: "" });
  const [alertEdit, setAlertEdit] = useState(true);
  const [challenge, setChallenge] = useState(null);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ direction: "" }, { direction: "rtl" }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["clean"],
    ],
  };
  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "background",
  ];

  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const testimonials1000 = useMediaQuery({ minWidth: 1000 });
  const testimonials700 = useMediaQuery({
    minWidth: 700,
    maxWidth: 1000,
  });
  const testimonials400 = useMediaQuery({
    minWidth: 400,
    maxWidth: 700,
  });
  const testimonials0 = useMediaQuery({
    minWidth: 0,
    maxWidth: 400,
  });
  const isIpad = useMediaQuery({ minWidth: 768, maxWidth: 1100 });
  const isMiniTabletSlider = useMediaQuery({ minWidth: 801, maxWidth: 840 });
  const isTabletBreakPoint = useMediaQuery({
    minWidth: 768,
    maxWidth: 768,
  });
  const isIpadOrTablet = useMediaQuery({
    minWidth: 1100,
    maxWidth: 1224,
  });
  const isIpadImage = useMediaQuery({
    minWidth: 768,
    maxWidth: 780,
  });
  const isDesktopOrLaptopPadding = useMediaQuery({
    minWidth: 1224,
    maxWidth: 1255,
  });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isDesktop = useMediaQuery({
    query: "(min-width: 1460px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isTabletSlider = useMediaQuery({
    minWidth: 1020,
    maxWidth: 1085,
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1000px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  const [loading, setLoading] = useState(true);
  const options = {
    decodeEntities: true,
    transform,
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    if (user !== null && user.profileExpired)
      profileService.Logout(user.profile.userId);
  }, []);

  function transform(node, index) {
    if (node.type === "tag" && node.name === "b") {
      return <b key={index}>{processNodes(node.children, transform)}</b>;
    }
    if (
      node.type === "tag" &&
      node.name === "br" &&
      node.next !== null &&
      node.next.name === "br"
    ) {
      return null;
    }
    if (node.type === "tag" && node.name === "br") {
      return <br></br>;
    }
  }
  async function ValidateFeaturedOpportunity(project) {
    if (user === null) {
      history.push("/account/login", {
        state: {
          source: "fromFeaturedOpportunities",
          project: project.projectID,
        },
      });
    } else if (user.profile.profileStatus !== ProfileStatus.Active) {
    } else if (
      user.profileTypeView.profileTypeDescription === AccountTypes.Provider
    ) {
      setSelectedProject(project.projectID);
      if (
        project.ndaRequired === true &&
        project.userPerformingRequestNDAApproved === false
      ) {
        setNdaMessage(project.ndaText);
        setNdaAlert(!ndaAlert);
      } else {
        await AddProjectSeen({
          userId: user.profile.userId,
          projectId: project.projectID,
          ndaApproved: true,
        });
      }
    } else {
      ShowErrorMessage(t("searchOpportunities.landingPage.restriction"));
    }
  }

  function ValidateFeaturedProfile(profile) {
    if (user === null) {
      history.push("/account/login", {
        state: {
          source: "fromFeaturedSP",
          profile: profile.userId,
        },
      });
    } else if (user.profile.profileStatus !== ProfileStatus.Active) {
    } else if (
      user.profileTypeView.profileTypeDescription === AccountTypes.Seeker
    ) {
      history.push(`/seeker/search-provider/search-details/${profile.userId}`);
    } else if (
      user.profileTypeView.profileTypeDescription === AccountTypes.Partner
    ) {
      history.push(`/partner/search-provider/search-details/${profile.userId}`);
    } else {
      ShowErrorMessage(t("searchProviders.landingPage.restriction"));
    }
  }

  const fetchData = async () => {
    const loader = document.querySelector(".loader-container");

    await landingPageService.GetAllPartnersAsync().then((y) => {
      setPartners(y);
    });
    await landingPageService
      .GetLandingPageTextAsyncLanguage(currentLan, t("admincms.ifyouare"))
      .then((y) => {
        setIfYouAre(y);
      });

    await adminService.GetAllFeaturedInAsync().then((res) => {
      setFeaturedIn(res);
    });

    await landingPageService
      .GetAllSectionsByLanguageAsync(currentLan)
      .then((res) => {
        setSections(res);
      });

    await adminService.GetChallengeAsync().then((x) => {
      setChallenge(x);
    });

    landingPageService.GetAllWhoWeAreAsync().then((y) => {
      // setWhoWeAre(y);
      y.map((item) =>
        item.typeId === WhoWeAreUserTypes.EarlySupporter ||
        item.typeId === WhoWeAreUserTypes.Initiator ||
        item.typeId === WhoWeAreUserTypes.TechAdvisor
          ? setWPple([...pple, item])
          : corporateAndDev.push(item)
      );
      // console.log(y);
    });

    testimonialService.GetTestimonialByLanguageAsync(currentLan).then((y) => {
      const tmpList =
        y &&
        y.map((item) => ({
          id: item.id,
          description: item.description.replace("</p><p><br></p><p>", " "),
          userID: item.userID,
          asUser: item.asUser,
          user: item.user,
          jobTitle: item.jobTitle,
          company: item.company,
        }));
      setTestimonials(tmpList);
    });

    await languageService.GetAllLanguagesAsync().then((x) => {
      setLanguages(x);
    });

    // console.log(testimonials);
    setLoading(true);
    await profileService
      .GetAllFeaturedProfilesAsync(AccountTypes.Provider)
      .then((x) => {
        var list = [];
        list = x;
        var list2 = [];
        for (var i = 0; i < x.length; i++) {
          if (list[i].profile.spType === "Start Up") {
            list[i].profile.spType = t("signUp.providerType.startup");
          }
        }
        for (var i = 0; i < 6; i++) {
          list2.push(x[i]);
        }
        setProfiles(list);
        if (
          location !== null &&
          location !== undefined &&
          location.state !== null &&
          location.state !== undefined &&
          location.state.state !== null &&
          location.state.state !== undefined &&
          location.state.state.source === "fromFeaturedSP"
        ) {
          x.map((profiles) => {
            if (profiles.profile.userId === location.state.state.profile) {
              ValidateFeaturedProfile(profiles.profile);
            }
          });
        }
      });
    if (user == null) {
      await projectService
        .GetAllFeaturedAsync(true, null, currentLan)
        .then((x) => {
          setProjects(x);
        });
    } else {
      await projectService
        .GetAllFeaturedAsync(true, user.profile.userId, currentLan)
        .then((x) => {
          setProjects(x);
          setProjectsList(x.featuredProjects.concat(x.featuredLinks));
          if (
            location !== null &&
            location !== undefined &&
            location.state !== null &&
            location.state !== undefined &&
            location.state.state !== null &&
            location.state.state !== undefined &&
            location.state.state.source === "fromFeaturedOpportunities"
          ) {
            projectsList.map((project) => {
              if (project.projectID === location.state.state.project) {
                ValidateFeaturedOpportunity(project);
              }
            });
          }
        });
    }
    loader.classList.add("loader--hide");
    setLoading(false);
  };

  React.useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location.state !== null &&
      location.state !== undefined &&
      location.state.state !== null &&
      location.state.state !== undefined &&
      location.state.state.source === "fromFeaturedOpportunities" &&
      location.state.state.project == null
    ) {
      if (user === null) {
        history.push("/account/login", {
          state: {
            source: "fromFeaturedOpportunities",
          },
        });
      } else if (user.profile.profileStatus !== ProfileStatus.Active) {
      } else if (
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider
      ) {
        history.push("provider/search-project");
      } else {
        ShowErrorMessage(t("searchOpportunities.landingPage.restriction"));
      }
    } else if (
      location !== null &&
      location !== undefined &&
      location.state !== null &&
      location.state !== undefined &&
      location.state.state !== null &&
      location.state.state !== undefined &&
      location.state.state.source === "fromFeaturedSP" &&
      location.state.state.profile == null
    ) {
      if (user === null) {
        history.push("/account/login", {
          state: {
            source: "fromFeaturedSP",
          },
        });
      } else if (user.profile.profileStatus !== ProfileStatus.Active) {
      } else if (
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker ||
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner
      ) {
        history.push("seeker/search-provider");
      } else {
        ShowErrorMessage(t("searchProviders.landingPage.restriction"));
      }
    }
    fetchData();
    let scrollTo = window.location.hash.replace("#", "");
    Scroll.scroller.scrollTo(scrollTo, {
      offset: -50,
      duration: 1,
      smooth: true,
      delay: 0,
    });
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function ShowErrorMessage(message) {
    setErrorMessage(message);
    setErrorAlert(!errorAlert);
  }

  async function AddProjectSeen(model) {
    await projectSeenService
      .AddProjectSeenAsync(model)
      .then(() => {
        history.push(
          `/provider/search-project/${model.projectId}?fromSearch=true`
        );
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setErrorMessage(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setErrorAlert(error.error[i].error);
              }
            }
          }
        } else {
          setErrorAlert(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function AddSpSeen(model) {
    await spSeenService
      .AddSpSeenAsync(model)
      .then((x) => {
        history.push(`/seeker/search-provider/search-details/${model.spId}`);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setErrorMessage(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setErrorAlert(error.error[i].error);
              }
            }
          }
        } else {
          setErrorAlert(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  useEffect(() => {
    const cards = document.querySelectorAll(".profilesCard");
    if (cards.length > 0) {
      const nodeArray = Array.from(cards);
      const maxHeight = Math.max(...nodeArray.map((card) => card.clientHeight));
      nodeArray.forEach((card) => {
        card.style.height = `${maxHeight}px`;
      });
    }
  }, [profiles]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(timer + 1);
      if (testimonialsIndex < testimonials.length - 1)
        setTestimonialsIndex(testimonialsIndex + 1);
      else setTestimonialsIndex(0);
    }, 2500);
    return () => clearInterval(interval);
  }, [timer]);
  // console.log(projects);

  const settings = {
    slidesToShow: isDesktopOrLaptop
      ? partners.length >= 7
        ? 7
        : partners.length
      : isTablet
      ? 4
      : 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: isDesktopOrLaptop ? false : true,
    infinite: true,
    swipeToSlide: isDesktopOrLaptop ? false : true,
    swipe: isDesktopOrLaptop ? false : true,
    arrows: isDesktopOrLaptop ? true : false,
  };

  const settings2 = {
    className: "slider variable-width",
    slidesToShow:
      isDesktop || isDesktopOrLaptop || isIpadOrTablet
        ? projectsList && projectsList.length < 3
          ? projectsList.length
          : 3
        : isTablet
        ? projectsList && projectsList.length < 2
          ? projectsList.length
          : 2
        : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: isDesktopOrLaptop ? false : true,
    infinite: true,
    swipeToSlide: isDesktopOrLaptop ? false : true,
    swipe: isDesktopOrLaptop ? false : true,
    arrows: isDesktopOrLaptop ? true : false,
  };

  const settings3 = {
    // slidesToShow: isDesktopOrLaptop
    //   ? profiles.length >= 4
    //     ? 4
    //     : profiles.length
    //   : isTablet
    //   ? 3
    //   : isMobile
    //   ? 1
    //   : 2,
    slidesToShow: profiles.length >= 5 ? 5 : profiles.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: isDesktopOrLaptop ? false : true,
    infinite: true,
    swipeToSlide: isDesktopOrLaptop ? false : true,
    swipe: isDesktopOrLaptop ? false : true,
    arrows: isDesktopOrLaptop ? true : false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1475,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      ,
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings4 = {
    className: "slider variable-width",
    slidesToShow: isDesktopOrLaptop
      ? featuredIn.length >= 5
        ? 5
        : featuredIn.length
      : isTablet
      ? 3
      : isMobile
      ? 2
      : 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: isDesktopOrLaptop ? false : true,
    infinite: true,
    swipeToSlide: isDesktopOrLaptop ? false : true,
    swipe: isDesktopOrLaptop ? false : true,
    arrows: isDesktopOrLaptop ? true : false,
  };

  const handleUpdate = async () => {
    const model = {
      SectionCode: sectionCode,
    };
    let list = [];
    var regex = /(<([^>]+)>)/gi;

    if (languages !== null) {
      for (var i = 0; i < languages.length; i++) {
        var hasText = !!languagesName[i].replace(regex, "").length;
        if (hasText === false) {
          setErrorMessage(t("required.all"));
          setErrorAlert(!errorAlert);
          return false;
        }
        const translation = {
          languageID: languages[i].languageID,
          name: languagesName[i],
        };
        list.push(translation);
      }
    }

    model["sectionContent"] = list;
    await adminService
      .UpdateSectionAsync(sectionCode, model)
      .then((x) => {
        const sectionsArr = sections;
        for (let i = 0; i < sectionsArr.length; i++) {
          if (sectionsArr[i].sectionCode === sectionCode) {
            languagesName[0] = languagesName[0].replace("<p>", "");
            languagesName[0] = languagesName[0].replace("</p>", "");
            sectionsArr[i].sectionContent = languagesName[0];
            sectionsArr[i].sectionContentLanguages = list;
          }
        }
        setSections(sectionsArr);
        setModalAlert(!modalAlert);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message !== undefined) {
          setErrorMessage(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setErrorMessage(error.error[i].error);
              }
            }
          }
        } else {
          setErrorMessage(error);
        }
        setErrorAlert(!errorAlert);
      });
  };

  function editInitialForm(languages, selectedSection) {
    var newJson = {};
    languages.map((x) => {
      var newKey = x.languageName;
      if (selectedSection.sectionContentLanguages != null) {
        for (
          var i = 0;
          i < selectedSection.sectionContentLanguages.length;
          i++
        ) {
          if (
            x.languageID ===
            selectedSection.sectionContentLanguages[i].languageID
          ) {
            var newVal = selectedSection.sectionContentLanguages[i];
            newJson[newKey] = newVal;
            languagesName[i] = selectedSection.sectionContentLanguages[i];
          }
        }
        setLanguagesName(languagesName);
      }
    });
    setInitialValues(newJson);
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      {/* {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker &&
        isDesktopOrLaptop && <SeekerNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker &&
        !isDesktopOrLaptop && <SeekerNavbarMobileTablet props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
        isDesktopOrLaptop && <ProviderNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
        !isDesktopOrLaptop && <ProviderNavbarMobileTablet props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner &&
        isDesktopOrLaptop && <PartnerNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner &&
        !isDesktopOrLaptop && <PartnerNavbarMobileTablet props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Admin &&
        isDesktopOrLaptop && <AdminNavbar props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription ===
          AccountTypes.Assistant &&
        isDesktopOrLaptop && <AdminNavbar props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Admin &&
        !isDesktopOrLaptop && <AdminNavbarMobileTablet props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription ===
          AccountTypes.Assistant &&
        !isDesktopOrLaptop && <AdminNavbarMobileTablet props={history} />}
      {user && user.profile.profileStatus !== ProfileStatus.Active && (
        <LoggedInUserNavbar props={history} />
      )}
      {!user && isDesktopOrLaptop && <LandingPageNavbar2 props={history} />}
      {!user && !isDesktopOrLaptop && (
        <LandingPageNavbarMobileTablet2 props={history} />
      )} */}
      <Modal
        fade
        backdrop
        isOpen={modalAlert}
        className="modal-xl"
        style={rtl ? { textAlign: "right" } : {}}
      >
        <div className="modal-body">
          <Formik initialValues={initialValues} onSubmit={() => handleUpdate()}>
            {({ isSubmitting }) => {
              return (
                <Col md="12" style={{ margin: "auto" }}>
                  <Form autoComplete="off">
                    <Row>
                      <Col md="12">
                        {languages &&
                          languages.map((x, i) => {
                            return (
                              <div className="form-group">
                                <FormGroup
                                  className="col-md-10"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginTop: "2rem",
                                    }}
                                    htmlFor="inputPassword4"
                                  >
                                    {x.languageDescription}
                                  </label>
                                  <ReactQuill
                                    style={{
                                      height: "390px",
                                      marginBottom: "6%",
                                      resize: "vertical",
                                    }}
                                    theme="snow"
                                    value={
                                      languagesName[i] === undefined
                                        ? null
                                        : languagesName[i].name
                                    }
                                    onChange={(e) => {
                                      if (languagesName[i] != null) {
                                        languagesName[i].name = e;
                                        setLanguagesName(languagesName);
                                      } else {
                                        let newArr = languagesName;
                                        const translation = {
                                          languageID: languages.languageID,
                                          name: e,
                                        };
                                        newArr.push(translation);
                                        setLanguagesName(newArr);
                                      }
                                    }}
                                    modules={modules}
                                    formats={formats}
                                  />
                                </FormGroup>
                              </div>
                            );
                          })}

                        <div className="form-row">
                          <FormGroup
                            className="col-md-8"
                            style={{
                              margin: "0 auto",
                              width: "100%",
                              padding: "10px",
                              marginTop: "-2%",
                            }}
                          >
                            <center>
                              <Button
                                disabled="true"
                                color="primary"
                                type="submit"
                                // disabled={isSubmitting}
                              >
                                {isSubmitting && (
                                  <span className="spinner-border spinner-border-sm mr-1"></span>
                                )}
                                {t("createCompanyProfile.save")}
                              </Button>
                            </center>
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              );
            }}
          </Formik>
        </div>
      </Modal>

      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
          }
        >
          <p>{errorMessage}</p>
        </div>
        <div className="modal-footer">
          <Button
            disabled="true"
            color="danger"
            type="button"
            style={
              rtl ? { margin: "auto", textAlign: "right" } : { margin: "auto" }
            }
            onClick={() => {
              setErrorAlert(!errorAlert);
            }}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      {sections.length > 0 && (
        <Container
          fluid
          style={
            isDesktopOrLaptop
              ? { paddingLeft: "70px", paddingRight: "70px" }
              : { padding: 0 }
          }
        >
          <div className="d-flex justify-content-center">
            <Alert
              color="info"
              isOpen={alertEdit}
              style={{
                marginTop: "70px",
                width: "fit-content",
                position: "fixed",
                zIndex: "999",
              }}
              // toggle={() => setAlertEdit(!alertEdit)}
            >
              {t("editMode")}
            </Alert>
          </div>
          <Row id="top" className={"d-flex align-items-center"}>
            <Col className="col-xs-12 col-sm-12 col-12 col-md-6 p-0">
              <div className="d-flex justify-content-start align-items-center">
                <Col
                  className={isDesktopOrLaptop ? "pl-5" : isMobile ? "p-0" : ""}
                >
                  <Tooltip title={t("double.click.edit")}>
                    <p
                      // className="title"
                      // contentEditable
                      // onInput={(e) => console.log(e.currentTarget.textContent)}
                      style={
                        rtl
                          ? {
                              color: "#292929",
                              lineHeight: "1.4em",
                              fontSize: "40px",
                              fontFamily: "SpaceGroteskMedium",
                              paddingTop: "100px",
                              marginBottom: "0",
                              textAlign: "justify",
                            }
                          : {
                              color: "#292929",
                              lineHeight: "1.4em",
                              fontSize: "40px",
                              fontFamily: "SpaceGroteskMedium",
                              paddingTop: "100px",
                              marginBottom: "0",
                            }
                      }
                      onDoubleClick={(e) => {
                        history.push(
                          `${path}/edit/${sections[0].sectionCode}?sectionCode=${sections[0].sectionCode}&sectionContent=${sections[0].sectionContent}`
                        );
                        // setLanguagesName(sections[0].sectionContentLanguages);
                        // editInitialForm(languages, sections[0]);
                        // setSectionCode(sections[0].sectionCode);
                        // setSelectedSection(sections[0]);
                        // setModalAlert(!modalAlert);
                      }}
                    >
                      {/* {t("mainPage.title")} */}
                      {sections[0].sectionContent}
                    </p>
                  </Tooltip>
                  <br />

                  <Tooltip title={t("double.click.edit")}>
                    <p
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontSize: "16px",
                              fontFamily: "SpaceGrotesk",
                              marginBottom: "0",
                              lineHeight: "1.4",
                              textAlign: "justify",
                            }
                          : {
                              color: "#4D4D4D",
                              fontSize: "16px",
                              fontFamily: "SpaceGrotesk",
                              marginBottom: "0",
                              lineHeight: "1.4",
                            }
                      }
                      onDoubleClick={(e) => {
                        history.push(
                          `${path}/edit/${sections[1].sectionCode}?sectionCode=${sections[1].sectionCode}&sectionContent=${sections[1].sectionContent}`
                        );
                        // setLanguagesName(sections[1].sectionContentLanguages);
                        // editInitialForm(languages, sections[1]);
                        // setSectionCode(sections[1].sectionCode);
                        // setSelectedSection(sections[1]);
                        // setModalAlert(!modalAlert);
                      }}
                    >
                      {/* {t("mainPage.title.body.one")} */}
                      {sections[1].sectionContent}
                    </p>
                  </Tooltip>
                  <br />

                  <Tooltip title={t("double.click.edit")}>
                    <p
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontSize: "16px",
                              fontFamily: "SpaceGrotesk",
                              marginBottom: "0",
                              lineHeight: "1.4",
                              textAlign: "justify",
                            }
                          : {
                              color: "#4D4D4D",
                              fontSize: "16px",
                              fontFamily: "SpaceGrotesk",
                              marginBottom: "0",
                              lineHeight: "1.4",
                            }
                      }
                      onDoubleClick={(e) => {
                        history.push(
                          `${path}/edit/${sections[2].sectionCode}?sectionCode=${sections[2].sectionCode}&sectionContent=${sections[2].sectionContent}`
                        );
                        // setLanguagesName(sections[2].sectionContentLanguages);
                        // editInitialForm(languages, sections[2]);
                        // setSectionCode(sections[2].sectionCode);
                        // setSelectedSection(sections[2]);
                        // setModalAlert(!modalAlert);
                      }}
                    >
                      {/* {t("mainPage.title.body.two")} */}
                      {sections[2].sectionContent}
                    </p>
                  </Tooltip>
                  <br />

                  <Tooltip title={t("double.click.edit")}>
                    <p
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontSize: "16px",
                              fontFamily: "SpaceGroteskBold",
                              marginBottom: "0",
                              lineHeight: "1.4",
                              textAlign: "justify",
                            }
                          : {
                              color: "#4D4D4D",
                              fontSize: "16px",
                              fontFamily: "SpaceGroteskBold",
                              marginBottom: "0",
                              lineHeight: "1.4",
                            }
                      }
                      onDoubleClick={(e) => {
                        history.push(
                          `${path}/edit/${sections[3].sectionCode}?sectionCode=${sections[3].sectionCode}&sectionContent=${sections[3].sectionContent}`
                        );
                      }}
                    >
                      {/* {t("mainPage.title.body.footer")} */}
                      {sections[3].sectionContent}
                    </p>
                  </Tooltip>
                  {/* <center> */}
                  <p style={rtl ? { textAlign: "justify" } : {}}>
                    <Button
                      disabled="true"
                      size="lg"
                      style={{
                        backgroundColor: "#316CB3",
                        color: "#fffffff",
                        fontFamily: "SpaceGroteskBold",
                        padding: "12px 24px",
                        fontSize: "18px",
                      }}
                      className="mr-4"
                      // onClick={(e) => {
                      //   user === null
                      //     ? history.push("/account/login?addProject=true")
                      //     : user.profile.profileStatus !== ProfileStatus.Active
                      //     ? e.preventDefault()
                      //     : user.profileTypeView.profileTypeDescription ===
                      //       AccountTypes.Seeker
                      //     ? history.push("/seeker/projects/add-project")
                      //     : user.profileTypeView.profileTypeDescription ===
                      //       AccountTypes.Partner
                      //     ? history.push("/partner/projects/add-project")
                      //     : ShowErrorMessage(
                      //         t("postOpportunities.landingPage.restriction")
                      //       );
                      // }}
                    >
                      {t("mainPage.postNeeds.button")}
                    </Button>
                  </p>
                  <Tooltip title={t("double.click.edit")}>
                    <p
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontSize: "16px",
                              marginTop: "12px",
                              fontFamily: "SpaceGroteskBold",
                              marginBottom: "0",
                              textAlign: "justify",
                            }
                          : {
                              color: "#4D4D4D",
                              fontSize: "16px",
                              marginTop: "12px",
                              fontFamily: "SpaceGroteskBold",
                              marginBottom: "0",
                            }
                      }
                      onDoubleClick={(e) => {
                        history.push(
                          `${path}/edit/${sections[4].sectionCode}?sectionCode=${sections[4].sectionCode}&sectionContent=${sections[4].sectionContent}`
                        );
                      }}
                    >
                      {/* {t("mainPage.title.footer.one")} */}
                      {sections[4].sectionContent}
                    </p>
                  </Tooltip>
                  <p
                    style={
                      rtl
                        ? {
                            color: "#005CA9",
                            fontSize: "16px",
                            fontFamily: "SpaceGroteskBold",
                            cursor: "pointer",
                            marginBottom: "0",
                            textAlign: "justify",
                          }
                        : {
                            color: "#005CA9",
                            fontSize: "16px",
                            fontFamily: "SpaceGroteskBold",
                            cursor: "pointer",
                            marginBottom: "0",
                          }
                    }
                    // onClick={(e) => {
                    //   user === null
                    //     ? history.push("/account/login")
                    //     : user.profile.profileStatus !== ProfileStatus.Active
                    //     ? e.preventDefault()
                    //     : user.profileTypeView.profileTypeDescription ===
                    //       AccountTypes.Provider
                    //     ? history.push("/provider/search-project")
                    //     : ShowErrorMessage(
                    //         t("searchOpportunities.landingPage.restriction")
                    //       );
                    // }}
                  >
                    {t("mainPage.title.footer.two")}
                  </p>
                  {/* </center> */}
                </Col>
              </div>
            </Col>
            {isMobile ? (
              <></>
            ) : (
              <Col className="col-xs-12 col-sm-12 col-12 col-md-6">
                <div className="d-flex ">
                  <img
                    style={{
                      width: "100%",
                      // height: "100%",
                      //  height: "auto",
                      objectFit: "contain",
                      marginTop: "30px",
                      // position: "absolute",
                    }}
                    src={require("assets/img/g8.jpg").default}
                    alt=""
                  />
                </div>
              </Col>
            )}
          </Row>
          <br />
          <Row
            className="d-flex justify-content-center"
            style={{ marginTop: "100px" }}
          >
            <Tooltip title={t("double.click.edit")}>
              <p
                style={{
                  color: "#838383",
                  fontSize: "24px",
                  fontFamily: "SpaceGroteskBold",
                  textAlign: "center",
                  marginBottom: "0",
                }}
                onDoubleClick={(e) => {
                  history.push(
                    `${path}/edit/${sections[5].sectionCode}?sectionCode=${sections[5].sectionCode}&sectionContent=${sections[5].sectionContent}`
                  );
                }}
              >
                {/* {t("mainPage.partners.title")} */}
                {sections[5].sectionContent}
              </p>
            </Tooltip>
          </Row>
          <Row className=" justify-content-center">
            <div
              className=""
              style={{
                margin: "0 auto",
                paddingTop: "34px",
                width: "100%",
                color: "#333",
                // backgroundColor: "#419be0",
              }}
            >
              <Slider {...settings}>
                {partners.map((item) => (
                  <div style={{ marginTop: "2%", marginRight: "2%" }}>
                    <a
                      // href={item.hyperLink}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      <img
                        className="partners-photo-container"
                        src={item.partnerLogo.filePath}
                        alt=" "
                        style={{
                          objectFit: "contain",
                          margin: "0 0 auto auto",
                          borderRadius: "0%",
                          filter: "grayscale(100%)",
                          height: "55px",
                        }}
                      />
                    </a>
                  </div>
                ))}
              </Slider>
            </div>
          </Row>
          <Row className="d-flex justify-content-center">
            <div
              style={{
                marginTop: "100px",
              }}
            >
              <img
                style={{
                  width: "520px",
                  objectFit: "contain",
                }}
                src={require("assets/img/landingPageImage_two.png").default}
                alt=""
              />
            </div>
          </Row>
          <Row
            className={
              isDesktopOrLaptop
                ? "d-flex justify-content-center col-sm-12 col-12 col-md-9"
                : "d-flex justify-content-center col-sm-12 col-12 col-md-8"
            }
            style={{
              marginTop: "15px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Tooltip title={t("double.click.edit")}>
              <p
                style={
                  rtl
                    ? {
                        color: "#393939",
                        fontSize: isDesktopOrLaptop ? "32px" : "24px",
                        fontFamily: "SpaceGroteskMedium",
                        textAlign: isMobile ? "justify" : "center",
                        margin: "0",
                        lineHeight: "1.3",
                      }
                    : {
                        color: "#393939",
                        fontSize: isDesktopOrLaptop ? "32px" : "24px",
                        fontFamily: "SpaceGroteskMedium",
                        textAlign: isMobile ? "left" : "center",
                        margin: "0",
                        lineHeight: "1.3",
                      }
                }
                onDoubleClick={(e) => {
                  history.push(
                    `${path}/edit/${sections[6].sectionCode}?sectionCode=${sections[6].sectionCode}&sectionContent=${sections[6].sectionContent}`
                  );
                }}
              >
                {/* {t("mainPage.description.one")} */}
                {sections[6].sectionContent}
              </p>
            </Tooltip>
          </Row>
          <Row
            className={
              isDesktopOrLaptop
                ? "d-flex justify-content-center col-sm-12 col-12 col-md-12 mx-auto"
                : "d-flex justify-content-center col-sm-12 col-12 col-md-8 mx-auto"
            }
            style={{
              marginTop: isDesktopOrLaptop || isTablet ? "20px" : "10px",
            }}
          >
            <Tooltip title={t("double.click.edit")}>
              <p
                style={
                  rtl
                    ? {
                        color: "#4B4B4B",
                        fontSize: "18px",
                        fontFamily: "SpaceGrotesk",
                        textAlign: isMobile ? "justify" : "center",
                        margin: isDesktopOrLaptop ? "0 170px" : "",
                        lineHeight: "1.3",
                      }
                    : {
                        color: "#4B4B4B",
                        fontSize: "18px",
                        fontFamily: "SpaceGrotesk",
                        textAlign: isMobile ? "left" : "center",
                        margin: isDesktopOrLaptop ? "0 170px" : "",
                        lineHeight: "1.3",
                      }
                }
                onDoubleClick={(e) => {
                  history.push(
                    `${path}/edit/${sections[7].sectionCode}?sectionCode=${sections[7].sectionCode}&sectionContent=${sections[7].sectionContent}`
                  );
                }}
              >
                {/* {t("mainPage.description.two")} */}
                {sections[7].sectionContent}
              </p>
            </Tooltip>
          </Row>
          <center
            className="col-9"
            style={{
              margin: "40px auto 0",
              fontSize: "16px",
              color: "#4B4B4B",
            }}
            onDoubleClick={(e) => {
              history.push(
                `${path}/edit/${sections[16].sectionCode}?sectionCode=${sections[16].sectionCode}&sectionContent=${sections[16].sectionContent}`
              );
            }}
          >
            {sections[16].sectionContent}
          </center>
          <div style={{ position: "relative" }}>
            <Row
              className="d-flex justify-content-center"
              style={{
                marginTop: isDesktopOrLaptop ? "100px" : "100px",
              }}
            >
              <div>
                <img
                  style={{
                    width: isDesktopOrLaptop ? "100%" : "auto",
                    objectFit: isDesktopOrLaptop ? "contain" : "cover",
                    marginTop: isDesktopOrLaptop ? "30px" : "0",
                    height: isDesktopOrLaptop ? "100%" : "400px",
                    marginTop: "0",
                  }}
                  src={require("assets/img/landingPageImage_three.png").default}
                  alt=""
                />
              </div>
            </Row>
            <Row
              className="d-flex justify-content-center"
              style={{
                marginTop: "15px",
                position: "absolute",
                // top: isDesktopOrLaptop ? "220px" : "2%",
                top: isTablet ? width * 0.044 : "2%",
                left: isDesktopOrLaptop ? "150px" : isTablet ? "25%" : "5%",
                right: isDesktopOrLaptop ? "150px" : isTablet ? "25%" : "5%",
              }}
            >
              <Tooltip title={t("double.click.edit")}>
                <p
                  style={{
                    color: "#FFFFFF",
                    fontSize: isDesktopOrLaptop
                      ? "32px"
                      : isTablet
                      ? "26px"
                      : "16px",
                    fontFamily: "SpaceGroteskMedium",
                    textAlign: "center",
                    lineHeight: "1.3",
                  }}
                  onDoubleClick={(e) => {
                    history.push(
                      `${path}/edit/${sections[8].sectionCode}?sectionCode=${sections[8].sectionCode}&sectionContent=${sections[8].sectionContent}`
                    );
                  }}
                >
                  {/* {t("mainPage.description.three")} */}
                  {sections[8].sectionContent}
                </p>
              </Tooltip>
            </Row>
            <Row
              className="d-flex justify-content-center"
              style={{
                marginTop: "24px",
                position: "absolute",
                top: isIpadImage
                  ? width * 0.22
                  : isIpad
                  ? width * 0.17
                  : isIpadOrTablet
                  ? width * 0.14
                  : isTablet
                  ? width * 0.1
                  : isMobile
                  ? "4%"
                  : "2%",
                left: isIpad
                  ? "150px"
                  : isIpadOrTablet
                  ? "200px"
                  : isTablet
                  ? "150px"
                  : "5%",
                right: isIpad
                  ? "150px"
                  : isIpadOrTablet
                  ? "200px"
                  : isTablet
                  ? "150px"
                  : "5%",
              }}
            >
              <Tooltip title={t("double.click.edit")}>
                <p
                  style={{
                    // display: isDesktopOrLaptop
                    //   ? "inline"
                    //   : isTablet
                    //   ? "none"
                    //   : "none",
                    color: "#FFFFFF",
                    fontSize: isDesktopOrLaptop
                      ? "18px"
                      : isMobile || isIpadImage
                      ? "12px"
                      : "15px",
                    fontFamily: "SpaceGrotesk",
                    textAlign: "center",
                    marginTop: isDesktopOrLaptop ? "24px" : "0",
                    lineHeight: "1.3",
                  }}
                  onDoubleClick={(e) => {
                    history.push(
                      `${path}/edit/${sections[9].sectionCode}?sectionCode=${sections[9].sectionCode}&sectionContent=${sections[9].sectionContent}`
                    );
                  }}
                >
                  {/* {t("mainPage.description.four")} */}
                  {sections[9].sectionContent}
                </p>
              </Tooltip>
            </Row>
            {projectsList && (
              <Row
                className={
                  isDesktopOrLaptop
                    ? "d-flex justify-content-between px-5"
                    : "d-flex justify-content-between"
                }
                style={
                  rtl
                    ? isDesktopOrLaptop
                      ? { marginTop: "100px", marginLeft: "0px" }
                      : isTabletBreakPoint
                      ? { marginTop: "100px" }
                      : isTablet
                      ? {
                          marginTop: "100px",
                          marginLeft: "15px",
                          marginRight: "15px",
                        }
                      : {
                          marginTop: "100px",
                          marginRight: "15px",
                        }
                    : isDesktopOrLaptop
                    ? { marginTop: "100px", marginLeft: "0px" }
                    : isTabletBreakPoint
                    ? { marginTop: "100px" }
                    : isTablet
                    ? { marginTop: "100px", marginLeft: "15px" }
                    : {
                        marginTop: "100px",
                        marginRight: "15px",
                      }
                }
              >
                <Tooltip title={t("double.click.edit")}>
                  <p
                    style={
                      rtl
                        ? {
                            color: "#4B4B4B",
                            fontSize:
                              isDesktopOrLaptop || isTablet ? "32px" : "32px",
                            fontFamily: "SpaceGroteskMedium",
                            paddingRight: "15px",
                            textAlign: "justify",
                            // margin: "0 170px",
                          }
                        : {
                            color: "#4B4B4B",
                            fontSize:
                              isDesktopOrLaptop || isTablet ? "32px" : "32px",
                            fontFamily: "SpaceGroteskMedium",
                            paddingLeft: "15px",
                            // margin: "0 170px",
                          }
                    }
                    onDoubleClick={(e) => {
                      history.push(
                        `${path}/edit/${sections[10].sectionCode}?sectionCode=${sections[10].sectionCode}&sectionContent=${sections[10].sectionContent}`
                      );
                    }}
                  >
                    {/* {t("mainPage.glimpse")} */}
                    {sections[10].sectionContent}
                  </p>
                </Tooltip>
                <Button
                  disabled="true"
                  size="lg"
                  style={{
                    display: isDesktopOrLaptop
                      ? "block"
                      : isTablet
                      ? "block"
                      : "none",
                    border: "1px solid #406BAE",
                    color: "#406BAE",
                    backgroundColor: "transparent",
                    fontFamily: "SpaceGroteskMedium",
                    padding: "12px 24px",
                    fontSize: "16px",
                    height: "45px",
                  }}
                  className={
                    rtl
                      ? isMobile
                        ? "viewMoreBtn"
                        : "mr-auto viewMoreBtn"
                      : isMobile
                      ? "viewMoreBtn"
                      : "mr-4 viewMoreBtn"
                  }
                  // onClick={(e) => {
                  //   user == null
                  //     ? history.push(
                  //         `/account/login?source=fromFeaturedOpportunities`
                  //       )
                  //     : user.profile.profileStatus !== ProfileStatus.Active
                  //     ? e.preventDefault()
                  //     : user.profileTypeView.profileTypeDescription ===
                  //       AccountTypes.Provider
                  //     ? history.push("/provider/search-project")
                  //     : ShowErrorMessage(
                  //         t("searchOpportunities.landingPage.restriction")
                  //       );
                  // }}
                >
                  {t("mainPage.viewMore")}
                </Button>
              </Row>
            )}
            {projectsList && (
              <Row
                className={isDesktopOrLaptop ? "px-4" : ""}
                style={{ marginLeft: 0 }}
              >
                <div
                  className="featuredProjects"
                  style={{
                    width: "100%",
                    // backgroundColor: "#419be0",
                  }}
                >
                  <Slider {...settings2}>
                    {/* <div className="d-flex justify-content-center"> */}
                    {projectsList &&
                      projectsList.map((project) => {
                        return isTablet ? (
                          <Row className="d-flex justify-content-center">
                            <Col
                              // className="col-md-3 col-sm-8"
                              style={{
                                margin: "auto ",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                className="card mb-0"
                                style={
                                  isMobile
                                    ? {
                                        // minWidth: "400px",
                                        width: "400px",
                                        height: "auto",
                                        backgroundColor: "#EDF3FA",
                                        borderRadius: "20px",
                                        boxShadow: "none",
                                      }
                                    : {
                                        // minWidth: "400px",
                                        // width: "auto",
                                        // minWidth: "400px",
                                        width: "350px",
                                        height: "214px",
                                        backgroundColor: "#EDF3FA",
                                        borderRadius: "20px",
                                        boxShadow: "none",
                                      }
                                }
                              >
                                <Row style={{ marginTop: "29px" }}>
                                  <Col
                                    className={"p-0"}
                                    style={
                                      isMobile
                                        ? {
                                            marginLeft: "15px",
                                            paddingLeft: "0",
                                            paddingRight: "0",
                                          }
                                        : { marginLeft: "0" }
                                    }
                                  >
                                    {project !== null &&
                                      project.country !== null && (
                                        <ReactCountryFlag
                                          countryCode={
                                            project.country.countryCode
                                          }
                                          svg
                                          style={{
                                            width: "auto",
                                            height: "24px",
                                            // marginTop: "22px",
                                            marginLeft: "20px",
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                          }}
                                        />
                                      )}
                                  </Col>
                                  <Col
                                    className={
                                      isMobile
                                        ? "d-flex align-items-center justify-content-center pl-0 pr-0"
                                        : "d-flex align-items-center justify-content-center pr-0 pl-0"
                                    }
                                  >
                                    <Link
                                      style={{
                                        fontFamily: "SpaceGroteskMedium",
                                        color: "#54B0F8",
                                        fontSize: "14px",
                                        marginBottom: "0px",
                                        // marginRight: "8px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                      // onClick={async (e) => {
                                      //   if (user === null) {
                                      //     history.push("/account/login", {
                                      //       state: {
                                      //         source:
                                      //           "fromFeaturedOpportunities",
                                      //         project: project.projectID,
                                      //       },
                                      //     });
                                      //   } else if (
                                      //     user.profile.profileStatus !==
                                      //     ProfileStatus.Active
                                      //   ) {
                                      //     e.preventDefault();
                                      //   } else if (
                                      //     user.profileTypeView
                                      //       .profileTypeDescription ===
                                      //     AccountTypes.Provider
                                      //   ) {
                                      //     setSelectedProject(project.projectID);
                                      //     if (
                                      //       project.ndaRequired === true &&
                                      //       project.userPerformingRequestNDAApproved ===
                                      //         false
                                      //     ) {
                                      //       setNdaMessage(project.ndaText);
                                      //       setNdaAlert(!ndaAlert);
                                      //     } else {
                                      //       await AddProjectSeen({
                                      //         userId: user.profile.userId,
                                      //         projectId: project.projectID,
                                      //         ndaApproved: true,
                                      //       });
                                      //     }
                                      //   } else {
                                      //     ShowErrorMessage(
                                      //       t(
                                      //         "searchOpportunities.landingPage.restriction"
                                      //       )
                                      //     );
                                      //   }
                                      // }}
                                    >
                                      {t("btnText.viewOpportunity")}
                                      <img
                                        src={
                                          require("assets/img/feather-arrow-left-circle.png")
                                            .default
                                        }
                                        style={{
                                          width: "13.4px",
                                          height: "13.4px",
                                          marginLeft: "8px",
                                        }}
                                      />
                                    </Link>
                                  </Col>
                                </Row>
                                <div className="card-body">
                                  <p
                                    style={
                                      isMobile
                                        ? {
                                            color: "#406BAE",
                                            fontSize: "16px",
                                            fontFamily: "SpaceGroteskBold",
                                            // borderBottom:
                                            //   "1px solid #406BAE50",
                                            paddingBottom: "20px",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            // width: "70vw",
                                            whiteSpace: "nowrap",
                                          }
                                        : {
                                            color: "#406BAE",
                                            fontSize: "18px",
                                            fontFamily: "SpaceGroteskBold",
                                            // borderBottom: "1px solid #406BAE50",
                                            paddingBottom: "20px",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            width: "100%",
                                            whiteSpace: "nowrap",
                                          }
                                    }
                                    className="card-title align-items-center justify-content-start"
                                  >
                                    {project.projectTitle}
                                  </p>

                                  {/* <hr
                                    style={{
                                      border: "1px solid #406BAE",
                                      width: "340px",
                                      opacity: "0.2",
                                    }}
                                  ></hr> */}
                                  <Row
                                    style={{
                                      borderTop: "1px solid #406BAE50",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <Col className={"col-6 p-0"}>
                                      <Row className="d-flex flex-column">
                                        <Col>
                                          <p
                                            className="card-text"
                                            style={
                                              rtl
                                                ? {
                                                    color: "#4B4B4B",
                                                    fontFamily: "SpaceGrotesk",
                                                    fontSize: "14px",
                                                    textTransform: "uppercase",
                                                    textAlign: "right",
                                                  }
                                                : {
                                                    color: "#4B4B4B",
                                                    fontFamily: "SpaceGrotesk",
                                                    fontSize: "14px",
                                                    textTransform: "uppercase",
                                                  }
                                            }
                                          >
                                            {t("project.closingDate")}{" "}
                                          </p>
                                        </Col>
                                        <Col>
                                          <p
                                            style={
                                              rtl
                                                ? {
                                                    color: "#4B4B4B",
                                                    fontFamily:
                                                      "SpaceGroteskBold",
                                                    fontSize: "16px",
                                                    textAlign: "right",
                                                  }
                                                : {
                                                    color: "#4B4B4B",
                                                    fontFamily:
                                                      "SpaceGroteskBold",
                                                    fontSize: "16px",
                                                  }
                                            }
                                          >
                                            {project.closingDate !== null &&
                                            `${new Date(
                                              project.closingDate
                                            ).getUTCDate()}/${
                                              monthNames[
                                                new Date(
                                                  project.closingDate
                                                ).getUTCMonth()
                                              ]
                                            }/${new Date(
                                              project.closingDate
                                            ).getFullYear()}` !== "1/January/1"
                                              ? `${new Date(
                                                  project.closingDate
                                                ).getUTCDate()}/${monthNames[
                                                  new Date(
                                                    project.closingDate
                                                  ).getUTCMonth()
                                                ].substring(0, 3)}/${new Date(
                                                  project.closingDate
                                                ).getFullYear()}`
                                              : ""}
                                          </p>
                                        </Col>
                                      </Row>
                                    </Col>
                                    {project.budget && (
                                      <Col className="d-flex justify-content-center col-6 p-0">
                                        <Row className="d-flex flex-column">
                                          <Col>
                                            <p
                                              className="card-text"
                                              style={
                                                rtl
                                                  ? {
                                                      color: "#4B4B4B",
                                                      fontFamily:
                                                        "SpaceGrotesk",
                                                      fontSize: "14px",
                                                      textTransform:
                                                        "uppercase",
                                                      textAlign: "right",
                                                    }
                                                  : {
                                                      color: "#4B4B4B",
                                                      fontFamily:
                                                        "SpaceGrotesk",
                                                      fontSize: "14px",
                                                      textTransform:
                                                        "uppercase",
                                                    }
                                              }
                                            >
                                              {t("addProject.budget")}{" "}
                                            </p>
                                          </Col>
                                          <Col>
                                            <p
                                              style={
                                                rtl
                                                  ? {
                                                      color: "#4B4B4B",
                                                      fontFamily:
                                                        "SpaceGroteskBold",
                                                      fontSize: "16px",
                                                      textAlign: "right",
                                                    }
                                                  : {
                                                      color: "#4B4B4B",
                                                      fontFamily:
                                                        "SpaceGroteskBold",
                                                      fontSize: "16px",
                                                    }
                                              }
                                            >
                                              {project.budget.description}
                                            </p>
                                          </Col>
                                        </Row>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <Row
                            className={isDesktopOrLaptop ? "px-4" : ""}
                            style={{ marginLeft: 0 }}
                          >
                            <Col
                              className="col-md-3 col-sm-8 d-flex justify-content-center"
                              style={{
                                margin: "auto ",
                                paddingRight: "0px",
                                paddingLeft: "0px",
                              }}
                            >
                              <div
                                className="card"
                                style={
                                  isMobile
                                    ? {
                                        // minWidth: "400px",
                                        width: "80vw",
                                        height: "auto",
                                        backgroundColor: "#EDF3FA",
                                        borderRadius: "20px",
                                        boxShadow: "none",
                                      }
                                    : {
                                        // minWidth: "400px",
                                        width: "350px",
                                        height: "214px",
                                        backgroundColor: "#EDF3FA",
                                        borderRadius: "20px",
                                        boxShadow: "none",
                                      }
                                }
                              >
                                <Row style={{ marginTop: "29px" }}>
                                  <Col
                                    className={"p-0"}
                                    style={{
                                      //marginLeft: "15px",
                                      padding: "0",
                                    }}
                                  >
                                    {project !== null &&
                                      project.country !== null && (
                                        <ReactCountryFlag
                                          countryCode={
                                            project.country.countryCode
                                          }
                                          svg
                                          style={{
                                            width: "auto",
                                            height: "24px",
                                            // marginTop: "22px",
                                            marginLeft: "20px",
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                          }}
                                        />
                                      )}
                                  </Col>
                                  <Col
                                    className={
                                      isMobile
                                        ? "d-flex align-items-center justify-content-center pl-0 pr-0"
                                        : "d-flex align-items-center justify-content-center pr-0 pl-0"
                                    }
                                  >
                                    <Link
                                      style={{
                                        fontFamily: "SpaceGroteskMedium",
                                        color: "#54B0F8",
                                        fontSize: "14px",
                                        marginBottom: "0px",
                                        // marginRight: "8px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                      // to={
                                      //   user == null
                                      //     ? `/account/login?source=fromFeaturedOpportunities&project=${project.projectID}`
                                      //     : "#"
                                      // }
                                      // onClick={async (e) => {
                                      //   if (
                                      //     user != null &&
                                      //     user.profileTypeView
                                      //       .profileTypeDescription ===
                                      //       AccountTypes.Provider
                                      //   ) {
                                      //     setSelectedProject(project.projectID);
                                      //     if (
                                      //       project.ndaRequired === true &&
                                      //       project.userPerformingRequestNDAApproved ===
                                      //         false
                                      //     ) {
                                      //       setNdaMessage(project.ndaText);
                                      //       setNdaAlert(!ndaAlert);
                                      //     } else {
                                      //       await AddProjectSeen({
                                      //         userId: user.profile.userId,
                                      //         projectId: project.projectID,
                                      //         ndaApproved: true,
                                      //       });
                                      //     }
                                      //   } else {
                                      //     ShowErrorMessage(
                                      //       t(
                                      //         "searchOpportunities.landingPage.restriction"
                                      //       )
                                      //     );
                                      //   }
                                      // }}
                                    >
                                      {t("btnText.viewOpportunity")}
                                      <img
                                        src={
                                          require("assets/img/feather-arrow-left-circle.png")
                                            .default
                                        }
                                        style={{
                                          width: "13.4px",
                                          height: "13.4px",
                                          marginLeft: "8px",
                                        }}
                                      />
                                    </Link>
                                  </Col>
                                </Row>
                                <div className="card-body">
                                  <p
                                    style={
                                      isMobile
                                        ? {
                                            color: "#406BAE",
                                            fontSize: "16px",
                                            fontFamily: "SpaceGroteskBold",
                                            // borderBottom: "1px solid #406BAE50",
                                            paddingBottom: "20px",
                                            // whiteSpace: "nowrap",
                                          }
                                        : {
                                            color: "#406BAE",
                                            fontSize: "18px",
                                            fontFamily: "SpaceGroteskBold",
                                            // borderBottom: "1px solid #406BAE50",
                                            paddingBottom: "20px",
                                            width: "100%",
                                            // whiteSpace: "nowrap",
                                          }
                                    }
                                    className="card-title align-items-center justify-content-start"
                                  >
                                    {project.projectTitle}
                                  </p>

                                  {/* <hr
                                    style={{
                                      border: "1px solid #406BAE",
                                      width: "340px",
                                      opacity: "0.2",
                                    }}
                                  ></hr> */}
                                  <Row
                                    style={{
                                      paddingTop: "10px",
                                      borderTop: "1px solid #406BAE50",
                                    }}
                                  >
                                    <Col
                                      className={
                                        isMobile ? "col-6 p-0" : " col-6 p-0"
                                      }
                                    >
                                      <Row className="d-flex flex-column">
                                        <Col>
                                          <p
                                            className="card-text"
                                            style={
                                              isMobile
                                                ? {
                                                    color: "#4B4B4B",
                                                    fontFamily: "SpaceGrotesk",
                                                    fontSize: "12px",
                                                    textTransform: "uppercase",
                                                    padding: "0",
                                                  }
                                                : {
                                                    color: "#4B4B4B",
                                                    fontFamily: "SpaceGrotesk",
                                                    fontSize: "14px",
                                                    textTransform: "uppercase",
                                                  }
                                            }
                                          >
                                            {t("project.closingDate")}{" "}
                                          </p>
                                        </Col>
                                        <Col>
                                          <p
                                            style={
                                              isMobile
                                                ? {
                                                    color: "#4B4B4B",
                                                    fontFamily:
                                                      "SpaceGroteskBold",
                                                    fontSize: "14px",
                                                    padding: "0",
                                                  }
                                                : {
                                                    color: "#4B4B4B",
                                                    fontFamily:
                                                      "SpaceGroteskBold",
                                                    fontSize: "16px",
                                                  }
                                            }
                                          >
                                            {project.closingDate !== null &&
                                            `${new Date(
                                              project.closingDate
                                            ).getUTCDate()}/${monthNames[
                                              new Date(
                                                project.closingDate
                                              ).getUTCMonth()
                                            ].substring(0, 3)}/${new Date(
                                              project.closingDate
                                            ).getFullYear()}` !== "1/January/1"
                                              ? `${new Date(
                                                  project.closingDate
                                                ).getUTCDate()}/${monthNames[
                                                  new Date(
                                                    project.closingDate
                                                  ).getUTCMonth()
                                                ].substring(0, 3)}/${new Date(
                                                  project.closingDate
                                                ).getFullYear()}`
                                              : ""}
                                          </p>
                                        </Col>
                                      </Row>
                                    </Col>
                                    {project.budget && (
                                      <Col
                                        className={
                                          isMobile
                                            ? "d-flex justify-content-center col-6"
                                            : "d-flex justify-content-center col-6 p-0"
                                        }
                                      >
                                        <Row className="d-flex flex-column">
                                          <Col>
                                            <p
                                              className="card-text"
                                              style={
                                                isMobile
                                                  ? {
                                                      color: "#4B4B4B",
                                                      fontFamily:
                                                        "SpaceGrotesk",
                                                      fontSize: "12px",
                                                      textTransform:
                                                        "uppercase",
                                                    }
                                                  : {
                                                      color: "#4B4B4B",
                                                      fontFamily:
                                                        "SpaceGrotesk",
                                                      fontSize: "14px",
                                                      textTransform:
                                                        "uppercase",
                                                    }
                                              }
                                            >
                                              {t("addProject.budget")}{" "}
                                            </p>
                                          </Col>
                                          <Col>
                                            <p
                                              style={
                                                isMobile
                                                  ? {
                                                      color: "#4B4B4B",
                                                      fontFamily:
                                                        "SpaceGroteskBold",
                                                      fontSize: "14px",
                                                    }
                                                  : {
                                                      color: "#4B4B4B",
                                                      fontFamily:
                                                        "SpaceGroteskBold",
                                                      fontSize: "16px",
                                                    }
                                              }
                                            >
                                              {project.budget.description}
                                            </p>
                                          </Col>
                                        </Row>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        );
                      })}
                    {/* </div> */}
                  </Slider>
                </div>
              </Row>
            )}
            {profiles && isMobile ? (
              <Row className="d-flex justify-content-center">
                <Button
                  disabled="true"
                  size="lg"
                  style={{
                    border: "1px solid #406BAE",
                    color: "#406BAE",
                    backgroundColor: "transparent",
                    fontFamily: "SpaceGroteskMedium",
                    padding: "12px 24px",
                    fontSize: "16px",
                    height: "45px",
                  }}
                  className={
                    rtl
                      ? isMobile
                        ? "viewMoreBtn"
                        : "mr-auto viewMoreBtn"
                      : isMobile
                      ? "viewMoreBtn"
                      : "mr-4 viewMoreBtn"
                  }
                  // onClick={(e) => {
                  //   // user === null
                  //   //   ? history.push("/account/login")
                  //   //   : user.profile.profileStatus !== ProfileStatus.Active
                  //   //   ? e.preventDefault()
                  //   //   : user.profileTypeView.profileTypeDescription ===
                  //   //     AccountTypes.Provider
                  //   //   ? history.push("/provider/search-project")
                  //   //   : ShowErrorMessage(
                  //   //       t("searchOpportunities.landingPage.restriction")
                  //   //     );
                  //   if (user == null) {
                  //     history.push(
                  //       `/account/login?source=fromFeaturedOpportunities`
                  //     );
                  //   } else if (
                  //     user.profile.profileStatus !== ProfileStatus.Active
                  //   ) {
                  //     e.preventDefault();
                  //   } else if (
                  //     user.profileTypeView.profileTypeDescription ===
                  //     AccountTypes.Provider
                  //   ) {
                  //     history.push({
                  //       pathname: `provider/search-project`,
                  //     });
                  //   } else {
                  //     ShowErrorMessage(
                  //       t("searchOpportunities.landingPage.restriction")
                  //     );
                  //   }
                  // }}
                >
                  {t("mainPage.viewMore")}
                </Button>
              </Row>
            ) : (
              <></>
            )}
            {profiles && (
              <Row
                className={
                  isDesktopOrLaptop
                    ? "d-flex justify-content-between px-5"
                    : "d-flex justify-content-between"
                }
                style={
                  isMobile
                    ? {
                        marginTop: "100px",
                        marfingRight: "15px",
                      }
                    : isDesktopOrLaptop
                    ? { marginTop: "100px", marginLeft: "0px" }
                    : {
                        marginTop: "100px",
                        marginLeft: "15px",
                        // marginRight: "15px",
                      }
                }
              >
                <Col
                  className={
                    isTablet
                      ? "d-flex flex-column p-0 col-9"
                      : isMobile
                      ? "d-flex flex-column p-0 col-12 "
                      : "d-flex flex-column p-0 col-12"
                  }
                >
                  <Tooltip title={t("double.click.edit")}>
                    <p
                      style={
                        rtl
                          ? isMobile
                            ? {
                                color: "#4B4B4B",
                                fontSize: "32px",
                                fontFamily: "SpaceGroteskMedium",
                                paddingRight: "15px",
                                textAlign: "justify",
                              }
                            : isTablet
                            ? {
                                color: "#4B4B4B",
                                fontSize: "32px",
                                fontFamily: "SpaceGroteskMedium",
                                paddingRight: "15px",
                                textAlign: "justify",
                              }
                            : {
                                color: "#4B4B4B",
                                fontSize: "32px",
                                fontFamily: "SpaceGroteskMedium",
                                textAlign: "justify",
                                // margin: "0 170px",
                              }
                          : isMobile
                          ? {
                              color: "#4B4B4B",
                              fontSize: "32px",
                              fontFamily: "SpaceGroteskMedium",
                              paddingLeft: "15px",
                            }
                          : isTablet
                          ? {
                              color: "#4B4B4B",
                              fontSize: "32px",
                              fontFamily: "SpaceGroteskMedium",
                              paddingLeft: "15px",
                            }
                          : {
                              color: "#4B4B4B",
                              fontSize: "32px",
                              fontFamily: "SpaceGroteskMedium",
                              // margin: "0 170px",
                            }
                      }
                      onDoubleClick={(e) => {
                        history.push(
                          `${path}/edit/${sections[11].sectionCode}?sectionCode=${sections[11].sectionCode}&sectionContent=${sections[11].sectionContent}`
                        );
                      }}
                    >
                      {/* {t("mainPage.providers.one")} */}
                      {sections[11].sectionContent}
                    </p>
                  </Tooltip>
                  <Tooltip title={t("double.click.edit")}>
                    <p
                      style={
                        rtl
                          ? isMobile || isDesktopOrLaptop
                            ? {
                                color: "#4B4B4B",
                                fontSize: "18px",
                                fontFamily: "SpaceGrotesk",
                                lineHeight: 1.3,
                                paddingRight: "15px",
                                textAlign: "justify",
                                // margin: "0 170px",
                              }
                            : {
                                color: "#4B4B4B",
                                fontSize: "18px",
                                fontFamily: "SpaceGrotesk",
                                lineHeight: 1.3,
                                paddingRight: "15px",
                                textAlign: "justify",
                                // margin: "0 170px",
                              }
                          : isMobile || isDesktopOrLaptop
                          ? {
                              color: "#4B4B4B",
                              fontSize: "18px",
                              fontFamily: "SpaceGrotesk",
                              lineHeight: 1.3,
                              paddingLeft: "15px",
                              // margin: "0 170px",
                            }
                          : {
                              color: "#4B4B4B",
                              fontSize: "18px",
                              fontFamily: "SpaceGrotesk",
                              lineHeight: 1.3,
                              paddingLeft: "15px",
                              // margin: "0 170px",
                            }
                      }
                      onDoubleClick={(e) => {
                        history.push(
                          `${path}/edit/${sections[12].sectionCode}?sectionCode=${sections[12].sectionCode}&sectionContent=${sections[12].sectionContent}`
                        );
                      }}
                    >
                      {/* {t("mainPage.providers.two")} */}
                      {sections[12].sectionContent}
                    </p>
                  </Tooltip>
                  <Tooltip title={t("double.click.edit")}>
                    <p
                      style={
                        rtl
                          ? isMobile || isDesktopOrLaptop
                            ? {
                                color: "#4B4B4B",
                                fontSize: "18px",
                                fontFamily: "SpaceGrotesk",
                                marginBottom: 0,
                                lineHeight: 1.3,
                                paddingRight: "15px",
                                textAlign: "justify",
                                // margin: "0 170px",
                              }
                            : {
                                color: "#4B4B4B",
                                fontSize: "18px",
                                fontFamily: "SpaceGrotesk",
                                marginBottom: 0,
                                lineHeight: 1.3,
                                paddingRight: "15px",
                                textAlign: "justify",
                                // margin: "0 170px",
                              }
                          : isMobile || isDesktopOrLaptop
                          ? {
                              color: "#4B4B4B",
                              fontSize: "18px",
                              fontFamily: "SpaceGrotesk",
                              marginBottom: 0,
                              lineHeight: 1.3,
                              paddingLeft: "15px",
                              // margin: "0 170px",
                            }
                          : {
                              color: "#4B4B4B",
                              fontSize: "18px",
                              fontFamily: "SpaceGrotesk",
                              marginBottom: 0,
                              lineHeight: 1.3,
                              paddingLeft: "15px",
                              // margin: "0 170px",
                            }
                      }
                      onDoubleClick={(e) => {
                        history.push(
                          `${path}/edit/${sections[13].sectionCode}?sectionCode=${sections[13].sectionCode}&sectionContent=${sections[13].sectionContent}`
                        );
                      }}
                    >
                      {/* {t("mainPage.providers.three")} */}
                      {sections[13].sectionContent}
                    </p>
                  </Tooltip>
                </Col>
                <Col className="p-0">
                  <Button
                    disabled="true"
                    size="lg"
                    style={{
                      display: isDesktopOrLaptop
                        ? "block"
                        : isTablet
                        ? "block"
                        : "none",
                      border: "1px solid #406BAE",
                      color: "#406BAE",
                      backgroundColor: "transparent",
                      fontFamily: "SpaceGroteskMedium",
                      padding: "12px 24px",
                      fontSize: "16px",
                      height: "45px",
                      float: "right",
                    }}
                    className={
                      rtl
                        ? isMobile
                          ? "viewMoreBtn"
                          : "mr-auto viewMoreBtn"
                        : isMobile
                        ? "viewMoreBtn"
                        : "mr-4 ml-auto viewMoreBtn"
                    }
                    // onClick={(e) => {
                    //   user == null
                    //     ? history.push(`/account/login?source=fromFeaturedSP`)
                    //     : user.profile.profileStatus !== ProfileStatus.Active
                    //     ? e.preventDefault()
                    //     : user.profileTypeView.profileTypeDescription ===
                    //       AccountTypes.Seeker
                    //     ? history.push("/seeker/search-provider")
                    //     : ShowErrorMessage(
                    //         t("searchOpportunities.landingPage.restriction")
                    //       );
                    // }}
                  >
                    {t("mainPage.viewMore")}
                  </Button>
                </Col>
              </Row>
            )}
            {/* <Row className=""> */}
            {profiles && (
              <div
                style={{
                  margin: "0",
                  padding: "30px 25px 0 25px",
                  width: "100%",
                  color: "#333",
                  // backgroundColor: "#419be0",
                }}
                className={isIpad ? "profilesTablet" : ""}
              >
                <Slider {...settings3}>
                  {profiles &&
                    profiles.map((profile) => {
                      return (
                        <Col
                          className={
                            isTabletBreakPoint
                              ? "col-md-12 col-sm-12 col-12 d-flex justify-content-center"
                              : isTabletSlider || isMiniTabletSlider
                              ? "col d-flex justify-content-center"
                              : isMobile
                              ? "col-md-4 col-sm-12 col-12 d-flex justify-content-center"
                              : "col-md-4 col-sm-12 col-12"
                          }
                          style={{
                            margin: "auto ",
                            paddingLeft: "0px",
                          }}
                        >
                          <div
                            className="card profilesCard"
                            style={{
                              width: "250px",
                              // height: "181px",
                              boxShadow: "0px 0px 5px #0000001A",
                              border: "1px solid #E2E2E2",
                              borderRadius: "15px",
                            }}
                          >
                            <div
                              className="card-body pl-2 pr-2 d-flex flex-column align-items-center"
                              style={{ paddingTop: "5px" }}
                            >
                              <img
                                src={profile.logo.filePath}
                                style={{
                                  width: "90px",
                                  height: "90px",
                                  objectFit: "contain",
                                }}
                                alt=""
                              />
                              <Tooltip title={profile.profile.companyName}>
                                <p
                                  style={
                                    isTabletSlider || isMiniTabletSlider
                                      ? {
                                          marginTop: "16px",
                                          color: "#406BAE",
                                          fontSize: "18px",
                                          fontFamily: "SpaceGroteskBold",
                                          marginBottom: "0px",
                                          cursor: "pointer",
                                          lineHeight: "1",
                                          height: "65px",
                                          // textOverflow: "ellipsis",
                                          // overflow: "hidden",
                                          // whiteSpace: "nowrap",
                                          textAlign: "center",
                                        }
                                      : {
                                          marginTop: "16px",
                                          color: "#406BAE",
                                          fontSize: "18px",
                                          fontFamily: "SpaceGroteskBold",
                                          marginBottom: "0px",
                                          cursor: "pointer",
                                          lineHeight: "1",
                                          height: "65px",
                                          // textOverflow: "ellipsis",
                                          // overflow: "hidden",
                                          width: "240px",
                                          // whiteSpace: "nowrap",
                                          textAlign: "center",
                                        }
                                  }
                                  className="card-title"
                                  // onClick={async (e) => {
                                  //   if (user === null) {
                                  //     history.push(
                                  //       `/account/login?source=fromFeaturedSP&profile=${profile.profile.userId}`
                                  //     );
                                  //   } else if (
                                  //     user.profile.profileStatus !==
                                  //     ProfileStatus.Active
                                  //   ) {
                                  //     e.preventDefault();
                                  //   } else if (
                                  //     user.profileTypeView
                                  //       .profileTypeDescription ===
                                  //       AccountTypes.Seeker ||
                                  //     user.profileTypeView
                                  //       .profileTypeDescription ===
                                  //       AccountTypes.Partner
                                  //   ) {
                                  //     const model = {
                                  //       userId: user.profile.userId,
                                  //       spId: profile.profile.userId,
                                  //     };
                                  //     AddSpSeen(model);
                                  //   } else {
                                  //     ShowErrorMessage(
                                  //       t(
                                  //         "searchProviders.landingPage.restriction"
                                  //       )
                                  //     );
                                  //   }
                                  // }}
                                >
                                  {profile.profile.companyName}
                                </p>
                              </Tooltip>

                              <p
                                className="card-text"
                                style={{
                                  marginTop: "5px",
                                  color: "#4B4B4B",
                                  fontSize: "14px",
                                  fontFamily: "SpaceGrotesk",
                                  textTransform: "uppercase",
                                  paddingBottom: "5px",
                                }}
                              >
                                {t("profile.activeSince")}{" "}
                                {profile.profile.activeSince}
                              </p>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                </Slider>
              </div>
            )}
            {isMobile && profiles ? (
              <Row className="d-flex justify-content-center">
                <Button
                  disabled="true"
                  size="lg"
                  style={{
                    border: "1px solid #406BAE",
                    color: "#406BAE",
                    backgroundColor: "transparent",
                    fontFamily: "SpaceGroteskMedium",
                    padding: "12px 24px",
                    fontSize: "16px",
                    height: "45px",
                    float: "right",
                    marginBottom: "40px",
                  }}
                  className={
                    rtl
                      ? isMobile
                        ? "viewMoreBtn"
                        : "mr-auto viewMoreBtn"
                      : isMobile
                      ? "viewMoreBtn"
                      : "mr-4 viewMoreBtn"
                  }
                  // onClick={(e) => {
                  //   user == null
                  //     ? history.push(`/account/login?source=fromFeaturedSP`)
                  //     : user.profile.profileStatus !== ProfileStatus.Active
                  //     ? e.preventDefault()
                  //     : user.profileTypeView.profileTypeDescription ===
                  //       AccountTypes.Seeker
                  //     ? history.push("/seeker/search-provider")
                  //     : ShowErrorMessage(
                  //         t("searchOpportunities.landingPage.restriction")
                  //       );
                  // }}
                >
                  {t("mainPage.viewMore")}
                </Button>
              </Row>
            ) : (
              <></>
            )}

            {testimonials.length > 0 && (
              <Row
                style={{
                  backgroundColor: "#EEF5FB",
                  // paddingTop: "35px",
                  marginTop: "80px",
                }}
              >
                <Col className="col-2 d-flex justify-content-center align-items-center">
                  {rtl ? (
                    <i
                      class="fa-solid fa-circle-arrow-right"
                      style={{ fontSize: "24px", color: "#B0B0B0" }}
                      onClick={() => {
                        if (testimonialsIndex < testimonials.length - 1)
                          setTestimonialsIndex(testimonialsIndex + 1);
                        else setTestimonialsIndex(0);
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa-solid fa-circle-arrow-left"
                      style={{ fontSize: "24px", color: "#B0B0B0" }}
                      onClick={() => {
                        if (testimonialsIndex == 0)
                          setTestimonialsIndex(testimonials.length - 1);
                        else if (testimonialsIndex <= testimonials.length - 1)
                          setTestimonialsIndex(testimonialsIndex - 1);
                        else setTestimonialsIndex(0);
                      }}
                    ></i>
                  )}
                </Col>
                <Col className="col-8 d-flex flex-column justify-content-center">
                  <Row className="d-flex justify-content-center">
                    <Tooltip title={t("double.click.edit")}>
                      <p
                        style={
                          testimonials400 || testimonials700
                            ? {
                                color: "#393939",
                                fontSize: "24px",
                                fontFamily: "SpaceGroteskMedium",
                                textAlign: "center",
                                paddingTop: "40px",
                              }
                            : {
                                color: "#393939",
                                fontSize: "32px",
                                fontFamily: "SpaceGroteskMedium",
                                textAlign: "center",
                                paddingTop: "40px",
                                paddingBottom: "40px",
                              }
                        }
                        onDoubleClick={(e) => {
                          history.push(
                            `${path}/edit/${sections[14].sectionCode}?sectionCode=${sections[14].sectionCode}&sectionContent=${sections[14].sectionContent}`
                          );
                        }}
                      >
                        {/* {t("mainPage.testimonials")} */}
                        {sections[14].sectionContent}
                      </p>
                    </Tooltip>
                  </Row>
                  <Row
                    style={{
                      paddingBottom: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Row
                      className="d-flex justify-content-center w-100"
                      style={{
                        height: "120px",
                      }}
                    >
                      <div
                        className="reactHtmlParserPar"
                        style={
                          testimonials0
                            ? {
                                color: "#4B4B4B",
                                fontSize: "14px",
                                fontFamily: "SpaceGrotesk",
                                textAlign: "center",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "190px",
                                overflow: "hidden",
                              }
                            : testimonials700
                            ? {
                                color: "#4B4B4B",
                                fontSize: "16px",
                                fontFamily: "SpaceGrotesk",
                                textAlign: "center",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "150px",
                                overflow: "hidden",
                              }
                            : testimonials400
                            ? {
                                color: "#4B4B4B",
                                fontSize: "14px",
                                fontFamily: "SpaceGrotesk",
                                textAlign: "center",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "150px",
                                overflow: "hidden",
                              }
                            : {
                                color: "#4B4B4B",
                                fontSize: "18px",
                                fontFamily: "SpaceGrotesk",
                                textAlign: "center",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "150px",
                                overflow: "hidden",
                              }
                        }
                      >
                        {ReactHtmlParser(
                          testimonials[testimonialsIndex].description
                        )}
                      </div>
                    </Row>
                    {testimonials[testimonialsIndex].asUser != null ? (
                      <figcaption
                        class="blockquote-footer"
                        style={
                          testimonials0
                            ? {
                                fontSize: "13px",
                                fontFamily: "SpaceGroteskLight",
                                textAlign: "center",
                                marginTop: "60px",
                                height: "80px",
                              }
                            : testimonials400
                            ? {
                                fontSize: "13px",
                                fontFamily: "SpaceGroteskLight",
                                textAlign: "center",
                                marginTop: "30px",
                                height: "60px",
                              }
                            : testimonials700
                            ? {
                                fontSize: "13px",
                                fontFamily: "SpaceGroteskLight",
                                textAlign: "center",
                                marginTop: "30px",
                              }
                            : {
                                fontSize: "14px",
                                fontFamily: "SpaceGroteskLight",
                                textAlign: "center",
                                marginTop: "30px",
                              }
                        }
                      >
                        {
                          testimonials[testimonialsIndex].asUser[0]
                            .contactFirstName
                        }{" "}
                        {
                          testimonials[testimonialsIndex].asUser[0]
                            .contactLastName
                        }
                        ,{" "}
                        {
                          testimonials[testimonialsIndex].asUser[0]
                            .contactJobTitle
                        }{" "}
                        -{" "}
                        <cite title="Source Title">
                          {
                            testimonials[testimonialsIndex].asUser[0]
                              .companyName
                          }
                        </cite>
                      </figcaption>
                    ) : (
                      <figcaption
                        class="blockquote-footer"
                        style={
                          testimonials0
                            ? {
                                fontSize: "13px",
                                fontFamily: "SpaceGroteskLight",
                                textAlign: "center",
                                marginTop: "50px",
                                height: "80px",
                              }
                            : testimonials400
                            ? {
                                fontSize: "13px",
                                fontFamily: "SpaceGroteskLight",
                                textAlign: "center",
                                marginTop: "30px",
                                height: "60px",
                              }
                            : {
                                fontSize: "14px",
                                fontFamily: "SpaceGroteskLight",
                                textAlign: "center",
                                marginTop: "30px",
                              }
                        }
                      >
                        {testimonials[testimonialsIndex].user},{" "}
                        {testimonials[testimonialsIndex].jobTitle} -{" "}
                        <cite title="Source Title">
                          {testimonials[testimonialsIndex].company}
                        </cite>
                      </figcaption>
                    )}
                  </Row>
                </Col>
                <Col className="col-2 d-flex justify-content-center align-items-center">
                  {rtl ? (
                    <i
                      class="fa-solid fa-circle-arrow-left"
                      style={{ fontSize: "24px", color: "#B0B0B0" }}
                      onClick={() => {
                        if (testimonialsIndex == 0)
                          setTestimonialsIndex(testimonials.length - 1);
                        else if (testimonialsIndex <= testimonials.length - 1)
                          setTestimonialsIndex(testimonialsIndex - 1);
                        else setTestimonialsIndex(0);
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa-solid fa-circle-arrow-right"
                      style={{ fontSize: "24px", color: "#B0B0B0" }}
                      onClick={() => {
                        if (testimonialsIndex < testimonials.length - 1)
                          setTestimonialsIndex(testimonialsIndex + 1);
                        else setTestimonialsIndex(0);
                      }}
                    ></i>
                  )}
                </Col>
              </Row>
            )}
            {featuredIn.length > 0 && (
              <Row
                className="d-flex justify-content-center"
                style={{ marginTop: "100px" }}
              >
                <Tooltip title={t("double.click.edit")}>
                  <p
                    style={{
                      color: "#292929",
                      fontSize: "24px",
                      fontFamily: "SpaceGroteskBold",
                      textAlign: "center",
                    }}
                    onDoubleClick={(e) => {
                      history.push(
                        `${path}/edit/${sections[15].sectionCode}?sectionCode=${sections[15].sectionCode}&sectionContent=${sections[15].sectionContent}`
                      );
                    }}
                  >
                    {/* {t("mainPage.featured")} */}
                    {sections[15].sectionContent}
                  </p>
                </Tooltip>
              </Row>
            )}
            {featuredIn.length > 0 && (
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "100px",
                }}
              >
                <div
                  className="featuredIn"
                  style={{
                    margin: "0 auto",
                    padding: "40px",
                    width: "100%",
                    color: "#333",
                    // backgroundColor: "#419be0",
                  }}
                >
                  <Slider {...settings4}>
                    {featuredIn.map((item, index) => (
                      // <object
                      //   data={require("assets/img/no-pictures.png").default}
                      //   type="image/png"
                      //   height="104px"
                      // >
                      <img
                        key={index}
                        style={{
                          objectFit: "contain !important",
                          marginTop: "15px !important",
                        }}
                        height="104px"
                        src={item.logo.filePath}
                      />
                      // </object>
                    ))}
                  </Slider>
                </div>
              </Row>
            )}
            <Row
              className={
                isMobile
                  ? "d-flex justify-content-start"
                  : "d-flex justify-content-start"
              }
              style={{ backgroundColor: "#EEF5FB" }}
            >
              <Col className="col-md-4 col-sm-12 col-12">
                <Nav className="flex-row justify-content-start mt-3">
                  {/* <NavItem>
                <a className="nav-link " href="#serviceProviders">
                  {t("featuredSP")}
                </a>
              </NavItem> */}
                  <Col
                    className={
                      isMobile
                        ? "col-md-12 col-sm-12 col-12 d-flex justify-content-center"
                        : "col-md-12 col-sm-12 col-12"
                    }
                  >
                    {/* {user && <NavItem>
                      <NavLink
                        style={
                          rtl
                            ? {
                                color: "#4D4D4D",
                                fontFamily: "SpaceGrotesk",
                                textAlign: "justify",
                              }
                            : {
                                color: "#4D4D4D",
                                fontFamily: "SpaceGrotesk",
                              }}
                        // onClick={() => {
                        //   history.push("/plans");
                        // }}
                        tag={Link}
                      >
                        {t("mainPage.plans")}
                      </NavLink>
                    </NavItem> } */}
                    <NavItem>
                      <NavLink
                        style={
                          rtl
                            ? {
                                color: "#4D4D4D",
                                fontFamily: "SpaceGrotesk",
                                textAlign: "justify",
                              }
                            : {
                                color: "#4D4D4D",
                                fontFamily: "SpaceGrotesk",
                              }
                        }
                        // onClick={() => {
                        //   history.push("/ourteam");
                        // }}
                        tag={Link}
                      >
                        {t("landingpage.ourteam")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={
                          rtl
                            ? {
                                color: "#4D4D4D",
                                fontFamily: "SpaceGrotesk",
                                textAlign: "justify",
                              }
                            : {
                                color: "#4D4D4D",
                                fontFamily: "SpaceGrotesk",
                              }
                        }
                        // onClick={() => {
                        //   history.push("/contactUs");
                        // }}
                        tag={Link}
                      >
                        {t("navbar.contactUs")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={
                          rtl
                            ? {
                                color: "#4D4D4D",
                                fontFamily: "SpaceGrotesk",
                                textAlign: "justify",
                              }
                            : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                        }
                        // onClick={() => {
                        //   history.push("/videos");
                        // }}
                        tag={Link}
                      >
                        {t("demo.videos")}
                      </NavLink>
                    </NavItem>
                    {challenge && challenge.isPublic && (
                      <NavItem>
                        <NavLink
                          style={
                            rtl
                              ? {
                                  color: "#4D4D4D",
                                  fontFamily: "SpaceGrotesk",
                                  textAlign: "justify",
                                }
                              : {
                                  color: "#4D4D4D",
                                  fontFamily: "SpaceGrotesk",
                                }
                          }
                          // onClick={() => {
                          //   history.push("/million-dollar-challenge");
                          // }}
                          tag={Link}
                        >
                          {t("million.dollar")}
                        </NavLink>
                      </NavItem>
                    )}
                  </Col>
                  {/* <Col
                      className={
                        isMobile
                          ? "col-md-6 col-sm-12 col-12 pb-3 d-flex justify-content-center"
                          : "col-md-6 col-sm-12 col-12"
                      }
                    >
                      
                    </Col> */}

                  {/* <LanguageDropdown /> */}
                </Nav>
              </Col>
              <Col
                row
                className={
                  isMobile
                    ? "d-flex justify-content-center align-items-center col-md-4 col-sm-12 col-12 pt-4 pb-2"
                    : "d-flex justify-content-end align-items-center col-md-4 col-sm-12 col-12"
                }
              >
                {" "}
                <Col
                  className={
                    isMobile
                      ? "d-flex justify-content-center align-items-center flex-column mt-0"
                      : "d-flex justify-content-center align-items-center flex-column mt-4"
                  }
                >
                  <Row>
                    <p
                      style={{
                        color: "#4D4D4D",
                        fontSize: "14px",
                        fontFamily: "SpaceGroteskMedium",
                        textAlign: "left",
                        marginBottom: "0",
                        paddingBottom: "10px",
                      }}
                    >
                      {t("mainPage.usaid")}
                    </p>
                  </Row>
                  <Row>
                    <img
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        width: "140px",
                      }}
                      src={
                        require("assets/img/usaid_logo_vertical.png").default
                      }
                      alt=""
                    />
                  </Row>
                </Col>
              </Col>

              {!isMobile ? (
                <Col
                  className="col-md-4 col-sm-12 col-12 d-flex justify-content-end align-items-end"
                  style={{ paddingBottom: "10px" }}
                >
                  <Row
                    className={
                      "d-flex justify-content-end align-items-center py-1"
                    }
                    style={{ backgroundColor: "#EEF5FB" }}
                  >
                    <p
                      style={
                        // isMobile
                        //   ? {
                        //       display: "none",
                        //       color: "#fff",
                        //       fontSize: "16px",
                        //       fontFamily: "SpaceGrotesk",
                        //       marginRight: "15px",
                        //       marginBottom: "0",
                        //     }
                        //   : {
                        {
                          color: "#4D4D4D",
                          fontSize: "16px",
                          fontFamily: "SpaceGrotesk",
                          marginRight: "15px",
                          marginBottom: "0",
                        }
                      }
                    >
                      {t("followUs")}
                    </p>
                    <img
                      style={{
                        width: "32px",
                        height: "32px",
                        objectFit: "contain",
                        marginRight: "15px",
                        cursor: "pointer",
                      }}
                      src={require("assets/img/linkedin.png").default}
                      onClick={() => {
                        const win = window.open(
                          `https://www.linkedin.com/company/outerpond/`,
                          "_blank"
                        );
                        win.focus();
                      }}
                      alt=""
                    />
                    <img
                      style={{
                        width: "32px",
                        height: "32px",
                        objectFit: "contain",
                        marginRight: "15px",
                        cursor: "pointer",
                      }}
                      src={require("assets/img/twitter.png").default}
                      onClick={() => {
                        const win = window.open(
                          `https://twitter.com/outerpond`,
                          "_blank"
                        );
                        win.focus();
                      }}
                      alt=""
                    />
                    <img
                      style={{
                        width: "32px",
                        height: "32px",
                        objectFit: "contain",
                        marginRight: "15px",
                        cursor: "pointer",
                      }}
                      src={require("assets/img/YoutubeSVG.svg").default}
                      onClick={() => {
                        const win = window.open(
                          `https://www.youtube.com/@outerpond3036`,
                          "_blank"
                        );
                        win.focus();
                      }}
                      alt=""
                    />
                  </Row>
                </Col>
              ) : (
                <></>
              )}
            </Row>

            {isMobile ? (
              <Row
                className={
                  isMobile
                    ? "d-flex justify-content-center align-items-center py-3"
                    : "d-flex justify-content-end align-items-center py-3"
                }
                style={{ backgroundColor: "#EEF5FB" }}
              >
                <p
                  style={
                    // isMobile
                    //   ? {
                    //       display: "none",
                    //       color: "#fff",
                    //       fontSize: "16px",
                    //       fontFamily: "SpaceGrotesk",
                    //       marginRight: "15px",
                    //       marginBottom: "0",
                    //     }
                    //   : {
                    {
                      color: "#4D4D4D",
                      fontSize: "16px",
                      fontFamily: "SpaceGrotesk",
                      marginRight: "15px",
                      marginBottom: "0",
                    }
                  }
                >
                  {t("followUs")}
                </p>
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "contain",
                    marginRight: "15px",
                    cursor: "pointer",
                  }}
                  src={require("assets/img/linkedin.png").default}
                  onClick={() => {
                    const win = window.open(
                      `https://www.linkedin.com/company/outerpond/`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  alt=""
                />
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "contain",
                    marginRight: "15px",
                    cursor: "pointer",
                  }}
                  src={require("assets/img/twitter.png").default}
                  onClick={() => {
                    const win = window.open(
                      `https://twitter.com/outerpond`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  alt=""
                />
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "contain",
                    marginRight: "15px",
                    cursor: "pointer",
                  }}
                  src={require("assets/img/YoutubeSVG.svg").default}
                  onClick={() => {
                    const win = window.open(
                      `https://www.youtube.com/@outerpond3036`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  alt=""
                />
              </Row>
            ) : (
              <></>
            )}

            <Row
              className={
                isMobile
                  ? "d-flex flex-column justify-content-between align-items-center pt-3 px-2"
                  : "d-flex justify-content-between align-items-center px-2"
              }
              style={{ backgroundColor: "#132438" }}
            >
              <p
                style={
                  isMobile
                    ? {
                        color: "#fff",
                        fontSize: "12px",
                        fontFamily: "SpaceGrotesk",
                        marginRight: "30px",
                        marginBottom: "0",
                        textAlign: "center",
                      }
                    : {
                        color: "#fff",
                        fontSize: "12px",
                        fontFamily: "SpaceGrotesk",
                        marginRight: "30px",
                        marginBottom: "0",
                      }
                }
              >
                {t("mainPage.footer")}
              </p>
              <Nav className="d-flex align-items-center">
                <NavItem>
                  <NavLink
                    style={{
                      color: "#fff",
                      fontSize: "12px",
                      fontFamily: "SpaceGrotesk",
                    }}
                    to={"/footer-sub-pages/1"}
                    tag={Link}
                  >
                    {t("footer.faq")}
                  </NavLink>
                </NavItem>
                <span className="text-white">—</span>
                <NavItem>
                  <NavLink
                    style={{
                      color: "#fff",
                      fontSize: "12px",
                      fontFamily: "SpaceGrotesk",
                    }}
                    to={"/footer-sub-pages/2"}
                    tag={Link}
                  >
                    {t("footer.terms")}
                  </NavLink>
                </NavItem>
                <span className="text-white">—</span>
                <NavItem>
                  <NavLink
                    style={{
                      color: "#fff",
                      fontSize: "12px",
                      fontFamily: "SpaceGrotesk",
                    }}
                    to={"/footer-sub-pages/3"}
                    tag={Link}
                  >
                    {t("footer.privacyPolicy")}
                  </NavLink>
                </NavItem>
              </Nav>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
}

export default Sections;
