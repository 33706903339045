import React, { useState, useContext } from "react";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { projectTypeService } from "_services";
import { Formik, Field, Form } from "formik";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import { languageService } from "_services";
import { adminService } from "_services";
import { LanguageContext } from "_context";

function AddEditCountry({ history, match, location }) {
  const isAddMode = !match.params.id;
  const [countries, setCountries] = useState(null);
  const [loading, setLoading] = useState(true);
  const [languages, setLanguages] = useState(null);
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  function initialForm(translations) {
    var newJson = { description: "", countryCode: "" };
    translations.map((x) => {
      var newKey = x.languageName;
      var newVal = "";
      newJson[newKey] = newVal;
    });
    setInitialValues(newJson);
  }

  function editInitialForm(translations, country) {
    var newJson = {
      description: country.description,
      countryCode: country.countryCode,
    };
    translations.map((x) => {
      var newKey = x.languageName;
      if (country.countryName != null) {
        for (var i = 0; i < country.countryName.length; i++) {
          if (x.languageID === country.countryName[i].languageID) {
            var newVal = country.countryName[i].name;
            newJson[newKey] = newVal;
          }
        }
      }
    });
    setInitialValues(newJson);
  }
  const fetchData = async () => {
    setLoading(true);
    // await projectTypeService.GetAllProjectTypeAsync().then((x) => {
    //   setProjectTypes(x);
    // });
    await languageService.GetAllLanguagesAsync().then((x) => {
      setLanguages(x);
      if (isAddMode) {
        initialForm(x);
      } else {
        editInitialForm(x, location.state.state);
      }
    });
    setLoading(false);
  };

  const [initialValues, setInitialValues] = useState({
    description: "",
    countryCode: "",
  });

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();

    const model = {
      description: fields.description,
      countryCode: fields.countryCode,
    };
    let list = [];
    languages &&
      languages.map((x) => {
        const translation = {
          languageID: x.languageID,
          name: fields[x.languageName],
        };
        list.push(translation);
      });
    model["countryName"] = list;
    if (isAddMode) {
      await adminService
        .AddCountryAsync(model)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
      setSubmitting(false);
    } else {
      await adminService
        .UpdateCountryAsync(match.params.id, model)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          setSubmitting(false);
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
      setSubmitting(false);
    }
  }
  return (
    <div className="section">
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("done")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>
              {isAddMode ? t("addCountry.success") : t("editCountry.success")}
            </p>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => {
                setAlert(!alert);
                if (isAddMode) {
                  history.push(".");
                } else {
                  history.push("..");
                }
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting }) => {
              return (
                <Col md="8" style={{ margin: "auto" }}>
                  <Card className={rtl ? "text-right" : ""}>
                    <Form autoComplete="off">
                      <CardBody>
                        <CardTitle className="text-center" tag="h4">
                          {isAddMode ? t("addCountry") : t("editCountry")}
                        </CardTitle>
                        <Row>
                          <Col md="12">
                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("countryDescription")}
                                </label>
                                <Field
                                  required={true}
                                  autoFocus={true}
                                  disabled={!isAddMode}
                                  name="description"
                                  placeholder={t("countryDescription")}
                                  className={"form-control"}
                                />
                              </FormGroup>
                            </div>
                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("countryCode")}
                                </label>
                                <Field
                                  required={true}
                                  //  autoFocus={true}
                                  disabled={!isAddMode}
                                  name="countryCode"
                                  placeholder={t("countryCode")}
                                  className={"form-control"}
                                />
                              </FormGroup>
                            </div>
                            {languages &&
                              languages.map((x) => {
                                return (
                                  <div className="form-group">
                                    <FormGroup
                                      className="col-md-8"
                                      style={{
                                        margin: "0 auto",
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "-2%",
                                      }}
                                    >
                                      <label htmlFor="inputPassword4">
                                        {x.languageDescription}
                                      </label>
                                      <Field
                                        name={x.languageName}
                                        placeholder={t("countryName")}
                                        className={"form-control"}
                                        required
                                      />
                                    </FormGroup>
                                  </div>
                                );
                              })}
                            <div className="form-row">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <center>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {isAddMode
                                      ? t("add.button")
                                      : t("createCompanyProfile.save")}
                                  </Button>
                                </center>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default AddEditCountry;
