import React, { useState, useContext, useRef } from "react";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import { projectTypeService } from "_services";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import { LanguageContext } from "_context";
import { emailLogsService } from "_services";
import { EmailLogStatus } from "_helpers";
import * as Yup from "yup";

function EditEmailLog({ history, match, location }) {
  const { id } = match.params;
  const emailLogData =
    location.state.state.rowData != null ? location.state.state.rowData : {};
  const emailLogIndex =
    location.state.state.index != null ? location.state.state.index : 0;
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [emailLog, setEmailLog] = useState(null);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [status, setStatus] = useState(emailLogData.emailLogStatus);
  const [initialValues, setInitialValues] = useState(
    emailLogIndex == 1
      ? {
          email: emailLogData.email,
          alertType: emailLogData.alertType,
          opportunity: emailLogData.opportunity,
          date: emailLogData.date,
          status: emailLogData.emailLogStatus,
          userID: emailLogData.userID,
          opportunityID: emailLogData.opportunityID,
          opportunityLabel: emailLogData.opportunityLabel,
        }
      : emailLogIndex == 2
      ? {
          email: emailLogData.email,
          alertType: emailLogData.alertType,
          newsletter: emailLogData.newsletter,
          date: emailLogData.date,
          status: emailLogData.emailLogStatus,
          userID: emailLogData.userID,
          newsletterID: emailLogData.newsletterID,
          newsletterLabel: emailLogData.newsletterLabel,
        }
      : emailLogIndex == 3
      ? {
          email: emailLogData.email,
          alertType: emailLogData.alertType,
          date: emailLogData.date,
          status: emailLogData.emailLogStatus,
          userID: emailLogData.userID,
          surveyID: emailLogData.surveyID,
          survey: emailLogData.survey,
        }
      : emailLogIndex == 4
      ? {
          email: emailLogData.email,
          alertType: emailLogData.alertType,
          date: emailLogData.date,
          status: emailLogData.emailLogStatus,
          userID: emailLogData.userID,
          transactionID: emailLogData.transactionID,
          planID: emailLogData.planID,
        }
      : {
          email: emailLogData.email,
          alertType: emailLogData.alertType,
          date: emailLogData.date,
          status: emailLogData.emailLogStatus,
          userID: emailLogData.userID,
          membershipID: emailLogData.membershipID,
          planID: emailLogData.planID,
        }
  );
  const [currentLan, setCurrentLan] = useContext(LanguageContext);

  console.log(status);

  const validationSchema =
    status == EmailLogStatus.Failed
      ? Yup.object().shape({
          email: Yup.string().required(t("fieldRequired")),
          status: Yup.string().required(t("fieldRequired")),
          reason: Yup.string().required(t("fieldRequired")),
        })
      : Yup.object().shape({
          email: Yup.string().required(t("fieldRequired")),
          status: Yup.string().required(t("fieldRequired")),
        });

  // const fetchData = async () => {
  //   setLoading(true);
  //   const model = {
  //     Index: 1,
  //     Id: id,
  //   };
  //   await emailLogsService.GetEmailLogAsync(model).then((res) => {
  //     console.log(res);
  //     setStatus(res.status);
  //     setInitialValues({
  //       email: res.userEmail,
  //       alertType: res.alertType,
  //       opportunity: res.asProject[0].projectTitle,
  //       date: res.date,
  //       status: res.status,
  //       userID: res.userID,
  //       opportunityID: res.opportunityID,
  //       reason: res.reason == null ? "" : res.reason,
  //     });
  //     setEmailLog(res);
  //   });

  //   setLoading(false);
  // };

  React.useEffect(() => {
    // fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    const model = {
      OpportunityID: initialValues.opportunityID,
      NewsletterID: initialValues.newsletterID,
      SurveyID: initialValues.surveyID,
      UserID: initialValues.userID,
      AlertType: initialValues.alertType,
      Status: fields.status,
      Reason: fields.reason,
      Index: emailLogIndex,
      TransactionID: initialValues.transactionID,
      MembershipID: initialValues.membershipID,
      PlanID: initialValues.planID,
    };
    await emailLogsService
      .UpdateEmailLogsAsync(model)
      .then(() => {
        setSubmitting(false);
        setAlert(!alert);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }
  return (
    <div className="section">
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("done")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>{t("emailLog.edit.success")}</p>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => {
                setAlert(!alert);
                history.push("..", {
                  type:
                    location.state != null && location.state.type != null
                      ? location.state.type
                      : "Alert",
                });
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => {
              // emailLogsService.GetEmailLogAsync(id).then((res) => {
              // const fields = [
              //     "email",
              //     "alertType",
              //     "opportunity",
              //     "status",
              //     "reason"
              //   ];
              //   fields.forEach((field) =>{
              //     setFieldValue(field, res[field], false);
              //   });
              //   setEmailLog(res);
              // })
              return (
                <Col md="8" style={{ margin: "auto" }}>
                  <Card>
                    <Form autoComplete="off">
                      <CardBody>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("..", {
                              type:
                                location.state != null &&
                                location.state.type != null
                                  ? location.state.type
                                  : "Alert",
                            });
                          }}
                        >
                          {t("back")}
                        </Link>
                        <CardTitle className="text-center" tag="h4">
                          {t("emailLog.edit")}
                        </CardTitle>
                        <Row>
                          <Col md="12">
                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("emailLog.user")}
                                </label>
                                <Field
                                  name="email"
                                  placeholder={t("emailLog.user")}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "email",
                                      e.target.value,
                                      false
                                    )
                                  }
                                  className={
                                    "form-control" +
                                    (errors.email && touched.email
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {errors.email && touched.email && (
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                )}
                              </FormGroup>
                            </div>

                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("emailLog.alert")}
                                </label>
                                <Field
                                  name="alertType"
                                  placeholder={t("emailLog.alert")}
                                  className={
                                    "form-control" +
                                    (errors.alertType && touched.alertType
                                      ? " is-invalid"
                                      : "")
                                  }
                                  disabled={true}
                                />
                                {errors.alertType && touched.alertType && (
                                  <ErrorMessage
                                    name="alertType"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                )}
                              </FormGroup>
                            </div>

                            {emailLogIndex == 1 ? (
                              <div className="form-group">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("emailLog.opportunity")}
                                  </label>
                                  <Field
                                    name="opportunity"
                                    placeholder={t("emailLog.opportunity")}
                                    className={
                                      "form-control" +
                                      (errors.opportunity && touched.opportunity
                                        ? " is-invalid"
                                        : "")
                                    }
                                    disabled={true}
                                  />
                                  {errors.opportunity &&
                                    touched.opportunity && (
                                      <ErrorMessage
                                        name="value"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    )}
                                </FormGroup>
                              </div>
                            ) : emailLogIndex == 2 ? (
                              <div className="form-group">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("emaillog.newslettersSubject")}
                                  </label>
                                  <Field
                                    name="newsletter"
                                    placeholder={t(
                                      "emaillog.newslettersSubject"
                                    )}
                                    className={
                                      "form-control" +
                                      (errors.newsletter && touched.newsletter
                                        ? " is-invalid"
                                        : "")
                                    }
                                    disabled={true}
                                  />
                                  {errors.newsletter && touched.newsletter && (
                                    <ErrorMessage
                                      name="value"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  )}
                                </FormGroup>
                              </div>
                            ) : (
                              <div className="form-group">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("surveyTitle")}
                                  </label>
                                  <Field
                                    name="survey"
                                    placeholder={t("surveyTitle")}
                                    className={
                                      "form-control" +
                                      (errors.survey && touched.survey
                                        ? " is-invalid"
                                        : "")
                                    }
                                    disabled={true}
                                  />
                                  {errors.survey && touched.survey && (
                                    <ErrorMessage
                                      name="value"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  )}
                                </FormGroup>
                              </div>
                            )}

                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("emailLog.status")}
                                </label>
                                <Field
                                  //required={true}
                                  as="select"
                                  name="status"
                                  placeholder={t("emailLog.status")}
                                  value={status}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "status",
                                      e.target.value,
                                      false
                                    );
                                    setStatus(e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.status && touched.status
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <option
                                    defaultValue
                                    value={EmailLogStatus.Sent}
                                  >
                                    {t("emailLog.status.sent")}
                                  </option>
                                  <option value={EmailLogStatus.Pending}>
                                    {t("emailLog.status.pending")}
                                  </option>
                                  <option value={EmailLogStatus.Failed}>
                                    {t("emailLog.status.failed")}
                                  </option>
                                </Field>
                                {errors.status && touched.status && (
                                  <ErrorMessage
                                    name="status"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                )}
                              </FormGroup>
                            </div>

                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("emailLog.reason")}
                                </label>
                                <Field
                                  //required={true}
                                  name="reason"
                                  placeholder={t("emailLog.reason")}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "reason",
                                      e.target.value,
                                      false
                                    )
                                  }
                                  className={
                                    "form-control" +
                                    (errors.reason && touched.reason
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {errors.reason && touched.reason && (
                                  <ErrorMessage
                                    name="reason"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                )}
                              </FormGroup>
                            </div>

                            <div className="form-row">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <center>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {t("createCompanyProfile.save")}
                                  </Button>
                                </center>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default EditEmailLog;
