import React, { useState, useEffect } from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import { Paper, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../theme.js";
import Modal from "reactstrap/lib/Modal";
import { newslettersService, searchService } from "_services";
import moment from "moment";
import RenewIcon from "@mui/icons-material/Autorenew";
import { IconButton } from "@material-ui/core";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Row from "reactstrap/lib/Row";
import Tooltip from "@mui/material/Tooltip";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
};

function Newsletters({ match, history, location }) {
  const { path } = match;
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [resendAlert, setResendAlert] = useState(false);
  const [confirmationAlert, setConfirmationAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowDataId, setRowDataId] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [count, setCount] = useState(0);

  const resetSearch = React.useRef(false);

  var columns = [
    {
      title: t("newsletters.recipient"),
      field: "newsletterId",
      hidden: true,
    },
    {
      title: t("newsletters.recipient"),
      field: "newsletterBody",
      hidden: true,
    },
    {
      title: t("newsletters.recipient"),
      field: "roles",
    },
    {
      title: t("newsletters.country"),
      field: "countries",
    },
    {
      title: t("industries"),
      field: "categories",
    },
    {
      title: t("emaillog.newslettersSubject"),
      field: "newsletterSubject",
    },
    {
      title: t("partner.list.sent.invitations.date"),
      field: "date",
    },
  ];

  let [currentPage, setCurrentPage] = useState(0);
  const userPerPage = pageSize;
  const begin = React.useRef(0);
  const end = React.useRef(userPerPage);

  // const count = Math.ceil(itemCount / userPerPage);

  function next() {
    if (end.current > itemCount) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
    begin.current = (currentPage + 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  const handleChange = (value) => {
    setPageSize(parseInt(value));
    end.current = begin.current + parseInt(value);
  };

  React.useEffect(() => {
    fetchCount();
  }, []);

  const fetchCount = async () => {
    await newslettersService
      .GetNewslettersCountAsync()
      .then((res) => setItemCount(res));
  };

  const fetchData = async () => {
    if (resetSearch.current == false) setLoading(true);
    const model = {
      emailLogsToSkip: begin.current,
      startingEmailLogs: pageSize,
    };
    await newslettersService.GetAllAsync(model).then((res) => {
      const tmpList = res.map((newsletter) => ({
        newsletterId: newsletter.newsletterId,
        roles: newsletter.roles,
        countries: newsletter.countries,
        categories: newsletter.categories,
        newsletterSubject: newsletter.newsletterSubject,
        newsletterBody: newsletter.newsletterBody,
        date: moment(newsletter.date).format("DD-MM-YYYY, h:mm:ss a"),
      }));
      setLogs(tmpList);
    });

    setLoading(false);
    resetSearch.current = false;
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [end.current, begin.current, pageSize]);

  function handleRemove(id) {
    const newList = logs.filter((item) => item.newsletterId !== id);
    setLogs(newList);
  }

  const handleSearch = async (e) => {
    // setSearchKeyword(e);
    const model = {
      Keyword: e,
      PageNumber: 0,
      PageSize: pageSize,
    };
    await searchService.SearchNewslettersAsync(model).then((res) => {
      const tmpList = res.map((newsletter) => ({
        newsletterId: newsletter.newsletterId,
        roles: newsletter.roles,
        countries: newsletter.countries,
        categories: newsletter.categories,
        newsletterSubject: newsletter.newsletterSubject,
        newsletterBody: newsletter.newsletterBody,
        date: moment(newsletter.date).format("DD-MM-YYYY, h:mm:ss a"),
      }));
      setLogs(tmpList);
      begin.current = 0;
      end.current = pageSize;
      setPageSize(pageSize);
    });
    await searchService.SearchNewslettersCountAsync(model).then((res) => {
      console.log(res);
      setItemCount(res);
    });
  };

  // if (loading) {
  //   return (
  //     <>
  //       <center
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           position: "absolute",
  //           left: "50%",
  //           top: "50%",
  //         }}
  //       >
  //         <PulseLoader color="#0E6EB8" />
  //       </center>
  //     </>
  //   );
  // }

  return (
    <>
      <div className="section">
        <Container>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={{ margin: "auto" }}
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={confirmationAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("done")}</h5>
            </div>
            <div className={rtl ? "modal-body text-right" : "modal-body"}>
              <p>
                {t("newsletters.confirmation.one")}
                {count}
                {t("newsletters.confirmation.two")}
              </p>
            </div>
            <div className="modal-footer">
              <Button
                color="danger"
                className="mr-2"
                type="button"
                style={{ margin: "auto", color: "#fff" }}
                onClick={(e) => {
                  setConfirmationAlert(!confirmationAlert);
                }}
              >
                {t("cancel.link")}
              </Button>
              <Button
                color="primary"
                type="button"
                style={{ margin: "auto" }}
                onClick={async (e) =>
                  await newslettersService
                    .ResendNewsletter(rowDataId, true)
                    .then((x) => {
                      setConfirmationAlert(!confirmationAlert);
                    })
                    .catch((error) => {
                      setDeleteAlert(!deleteAlert);
                      if (error.message !== undefined) {
                        setError(error.message);
                      } else {
                        setError(error);
                      }
                      setErrorAlert(!errorAlert);
                    })
                }
              >
                {t("newsletter.send")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={resendAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("resend.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{t("resend.alert.body")}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={{ margin: "auto" }}
                onClick={async (e) => {
                  await newslettersService
                    .ResendNewsletter(rowDataId, false)
                    .then((x) => {
                      setConfirmationAlert(!confirmationAlert);
                      setResendAlert(!resendAlert);
                      setCount(x);
                    })
                    .catch((error) => {
                      setDeleteAlert(!deleteAlert);
                      if (error.message !== undefined) {
                        setError(error.message);
                      } else {
                        setError(error);
                      }
                      setErrorAlert(!errorAlert);
                    });
                }}
              >
                {t("label.yes")}
              </Button>
              <Button
                color="danger"
                type="button"
                style={{ margin: "auto" }}
                onClick={(e) => setResendAlert(!resendAlert)}
              >
                {t("label.no")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("delete.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{t("delete.alert.body")}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={{ margin: "auto" }}
                onClick={async (e) =>
                  await newslettersService
                    .DeleteNewsletterAsync(rowDataId)
                    .then((x) => {
                      handleRemove(rowDataId);
                      setDeleteAlert(!deleteAlert);
                    })
                    .catch((error) => {
                      setDeleteAlert(!deleteAlert);
                      if (error.message !== undefined) {
                        setError(error.message);
                      } else {
                        setError(error);
                      }
                      setErrorAlert(!errorAlert);
                    })
                }
              >
                {t("label.yes")}
              </Button>
              <Button
                color="danger"
                type="button"
                style={{ margin: "auto" }}
                onClick={(e) => setDeleteAlert(!deleteAlert)}
              >
                {t("label.no")}
              </Button>
            </div>
          </Modal>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",

              width: "100%",
              columnGap: "10rem",
            }}
          >
            <div
              style={{
                color: "#0e6eb8",
                fontSize: "1.25rem",
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                fontWeight: 500,
                letterSpacing: "0.0075em",
                textTransform: "none",
                marginLeft: "15px",
              }}
            >
              {t("newsletters.List")}
            </div>
            <FormControl
              className="d-flex flex-row align-items-center"
              sx={{ m: 1 }}
              variant="standard"
            >
              <Input
                id="searchInput"
                type="text"
                placeholder={t("search")}
                onChange={(e) => handleSearch(e.currentTarget.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton aria-label="toggle password visibility">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(e) => {
                        const searchInput =
                          document.getElementById("searchInput");
                        searchInput.value = "";
                        resetSearch.current = true;
                        fetchData();
                        fetchCount();
                      }}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Tooltip title={t("add.button")}>
                <AddBox
                  className="actionIcon"
                  style={{
                    color: "#000000",
                    cursor: "pointer",
                  }}
                  onClick={(event, rowData) => {
                    history.push({
                      pathname: `${path}/add`,
                      state: { isAddMode: true },
                    });
                  }}
                ></AddBox>
              </Tooltip>
            </FormControl>
          </Row>
          {loading && (
            <center
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                left: "50%",
                top: "100%",
              }}
            >
              <PulseLoader color="#0E6EB8" />
            </center>
          )}
          {!loading && (
            <>
              <ThemeProvider theme={theme}>
                <MaterialTable
                  //  style={{ backgroundColor: "#2C2C34", color: "#d9dcde" }}
                  components={{
                    Container: (props) => (
                      <Paper {...props} className="usersTable" elevation={0} />
                    ),
                  }}
                  title={<span></span>}
                  columns={columns}
                  data={logs}
                  icons={tableIcons}
                  onSearchChange={handleSearch}
                  options={{
                    // pageSize: 10,
                    search: false,
                    paging: false,
                    actionsColumnIndex: -1,
                    cellStyle: {
                      textAlign: rtl ? "right" : "left",
                    },
                    headerStyle: {
                      textAlign: rtl ? "right" : "left",
                    },
                  }}
                  localization={{
                    header: {
                      actions: t("actions"),
                    },
                    toolbar: {
                      searchPlaceholder: t("search"),
                    },
                  }}
                  actions={[
                    {
                      icon: () => (
                        <Edit
                          className="actionIcon"
                          style={{
                            fill: "#0E6EB8",
                          }}
                          //   style={{
                          //     color: "#d9dcde",
                          //   }}
                        />
                      ),
                      tooltip: t("edit"),
                      //isFreeAction: true,
                      onClick: async (event, rowData) => {
                        history.push({
                          pathname: `${path}/add/${rowData.newsletterId}`,
                          state: { isAddMode: false, newsletter: rowData },
                        });
                      },
                    },
                    {
                      icon: () => (
                        <RenewIcon
                          className="actionIcon"
                          style={{
                            fill: "#0E6EB8",
                          }}
                          //   style={{
                          //     color: "#d9dcde",
                          //   }}
                        />
                      ),
                      tooltip: t("resend"),
                      //isFreeAction: true,
                      onClick: async (event, rowData) => {
                        setRowDataId(rowData.newsletterId);
                        setResendAlert(!resendAlert);
                      },
                    },
                    {
                      icon: () => (
                        <Delete
                          className="actionIcon"
                          style={{ fill: "#FF3636" }}
                          //   style={{
                          //     color: "#d9dcde",
                          //   }}
                        />
                      ),
                      tooltip: t("delete"),
                      //isFreeAction: true,
                      onClick: async (event, rowData) => {
                        setRowDataId(rowData.newsletterId);
                        setDeleteAlert(!deleteAlert);
                      },
                    },
                    // {
                    //   icon: () => (
                    //     <AddBox
                    //       className="actionIcon"
                    //       style={{
                    //         color: "#000000",
                    //       }}
                    //     />
                    //   ),
                    //   tooltip: t("add"),
                    //   isFreeAction: true,
                    //   onClick: (event, rowData) => {
                    //     history.push({
                    //       pathname: `${path}/add`,
                    //       state: { isAddMode: true },
                    //     });
                    //   },
                    // },
                  ]}
                />
              </ThemeProvider>
              <div className="d-flex justify-content-end align-items-center">
                <select
                  value={pageSize}
                  onChange={(e) => handleChange(e.target.value)}
                  style={{
                    height: "36px",
                    borderRadius: 0,
                    width: "auto",
                    fontSize: "12px",
                  }}
                  className={"form-control"}
                >
                  <option
                    key={5}
                    value={5}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    5
                  </option>
                  <option
                    key={10}
                    value={10}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    10
                  </option>
                  <option
                    key={15}
                    value={15}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    15
                  </option>
                  <option
                    key={20}
                    value={20}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    20
                  </option>
                  <option
                    key={50}
                    value={50}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    50
                  </option>
                  <option
                    key={100}
                    value={100}
                    style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                  >
                    100
                  </option>
                </select>
                <IconButton
                  aria-label="next"
                  disabled={currentPage == 0 ? true : false}
                >
                  {rtl ? (
                    <ArrowForwardIosSharpIcon
                      onClick={() => prev()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  ) : (
                    <ArrowBackIosSharpIcon
                      onClick={() => prev()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  )}
                </IconButton>
                <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
                  {begin.current} -{" "}
                  {end.current > itemCount ? itemCount : end.current}{" "}
                  {rtl ? "من" : "of"} {itemCount}
                </p>
                <IconButton
                  aria-label="next"
                  disabled={end.current >= itemCount ? true : false}
                >
                  {rtl ? (
                    <ArrowBackIosSharpIcon
                      onClick={() => next()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  ) : (
                    <ArrowForwardIosSharpIcon
                      onClick={() => next()}
                      style={{
                        color: "#d9dcde",
                      }}
                    />
                  )}
                </IconButton>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
}

export default Newsletters;
