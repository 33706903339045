import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddSurveyResponse from "./AddSurveyResponse";

function SurveyResponse({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute
          path={`${path}/:id/:id2/:id3?`}
          component={AddSurveyResponse}
        />
      </Switch>
    </div>
  );
}

export { SurveyResponse };
