import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import OpportunityViewers from "./OpportunityViewers";
import OpportunityMessages from "./OpportunityMessages";
import OpportunityInteractors from "./OpportunityInteractors";
function OpportunityDetails({ match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute
          path={`${path}/opportunity-viewers/:id`}
          component={OpportunityViewers}
          exact
        />
        <PrivateRoute
          path={`${path}/opportunity-interactors/:id`}
          component={OpportunityInteractors}
          exact
        />
        <PrivateRoute
          path={`${path}/opportunity-viewers/:id/opportunity-messages/:userId`}
          component={OpportunityMessages}
          exact
        />
        <PrivateRoute
          path={`${path}/opportunity-interactors/:id/opportunity-messages/:userId`}
          component={OpportunityMessages}
          exact
        />
      </Switch>
    </div>
  );
}

export default OpportunityDetails;
