import React from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
// reactstrap components
import { Card, CardBody, CardTitle, CardHeader, FormGroup } from "reactstrap";

// core components

function ContactInfo({ props }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  return (
    <Card className={rtl ? "text-right" : ""}>
      <CardHeader className="text-center">
        <CardTitle className="" tag="h4">
          {t("createCompanyProfile.contact.diviver")}
        </CardTitle>
        <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
      </CardHeader>
      <CardBody>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("contact.fullName")}
              </label>
              <label className="col-md-6">
                {props.profile.contactFirstName !== null &&
                  props.profile.contactFirstName}{" "}
                {props.profile.contactLastName !== null &&
                  props.profile.contactLastName}
              </label>
            </div>
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.contactEmail")}
              </label>
              <label className="col-md-6">
                {props.profile.contactEmail !== null &&
                  props.profile.contactEmail}
              </label>
            </div>
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.contactJob")}
              </label>
              <label className="col-md-6">
                {props.profile.contactJobTitle !== null &&
                  props.profile.contactJobTitle}
              </label>
            </div>
          </FormGroup>
        </div>
      </CardBody>
    </Card>
  );
}

export default ContactInfo;
