import React, { useState, createContext } from "react";

// Create Context Object
export const SearchListContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const SearchListContextProvider = props => {

const model = {
    totalCount:0,
    list: []
}
  const [searchList, setSearchList] = useState(model);
  // useEffect(() => {
  // }, [ searchModel]);

  return (
    <SearchListContext.Provider value={[searchList, setSearchList]}>
      {props.children}
    </SearchListContext.Provider>
  );
};