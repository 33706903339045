import React, { useContext, useEffect, useState } from "react";
import "assets/css/mainSection.css";
import LandingPageNavbar2 from "components/Navbars/LandingPageNavbar2.jsx";
import { AccountTypes } from "_helpers";
import { profileService, landingPageService, adminService } from "_services";
import ReCAPTCHA from "react-google-recaptcha";
import SeekerNavbar from "components/Navbars/SeekerNavbar";
import ProviderNavbar from "components/Navbars/ProviderNavbar";
import PartnerNavbar from "components/Navbars/PartnerNavbar";
import { ProfileStatus } from "_helpers";
import LoggedInUserNavbar from "components/Navbars/LoggedInUserNavbar";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { WhoWeAreUserTypes } from "_helpers/WhoWeAreUserTypes";
import Modal from "reactstrap/lib/Modal";
import Col from "reactstrap/lib/Col";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Button from "reactstrap/lib/Button";
import FormGroup from "reactstrap/lib/FormGroup";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardFooter from "reactstrap/lib/CardFooter";
import CardSubtitle from "reactstrap/lib/CardSubtitle";
import CardText from "reactstrap/lib/CardText";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { LanguageContext } from "_context";
import LandingPageNavbarMobileTablet2 from "components/Navbars/LandingPageNavbarMobileTablet2";
import ProviderNavbarMobileTablet from "components/Navbars/ProviderNavbarMobileTablet";
import PartnerNavbarMobileTablet from "components/Navbars/PartnerNavbarMobileTablet";
import SeekerNavbarMobileTablet from "components/Navbars/SeekerNavbarMobileTablet";
import * as Scroll from "react-scroll";
import AdminNavbarMobileTablet from "components/Navbars/AdminNavbarMobileTablet";
import AdminNavbar from "components/Navbars/AdminNavbar";
import { processNodes } from "react-html-parser";
import { projectSeenService } from "_services";
import { spSeenService } from "_services";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import PulseLoader from "react-spinners/PulseLoader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { NavItem, NavLink, Nav, Navbar } from "reactstrap";

function ContactUs({ history, location }) {
  const user = profileService.userValue;
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [contactUsMessage, setMessage] = useState("");
  const [currentLan] = useContext(LanguageContext);
  const [pple, setWPple] = useState([]);
  const [corporateAndDev, setCorporateAndDev] = useState([]);
  const [whoWeAre, setWhoWeAre] = useState([]);
  const [challenge, setChallenge] = useState(null);

  var _reCaptchaRef = React.createRef();
  const TEST_SITE_KEY = "6Lec5wEbAAAAAKAYNNhRFYKGdbf6DipNpjQ_l2Da";

  const [notRobot, setNotRobot] = useState(false);
  function handleChange() {
    setNotRobot(!notRobot);
  }
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 48,
    height: 48,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1223px)",
  });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t("signUp.email.error")),
    firstName: Yup.string().required(t("fullName.required")),
  });
  const initialValues = {
    email: "",
    firstName: "",
    message: "",
  };

  async function onSubmit(fields, { setStatus, setSubmitting, setFieldValue }) {
    if (!notRobot) {
      setErrorAlert(!errorAlert);
      setErrorMessage(t("robot.message"));
    } else {
      setStatus();
      const model = {
        sender: fields.firstName,
        senderEmail: fields.email,
        message: contactUsMessage,
      };

      const userVariables = {
        userVariables: model,
        language: currentLan,
      };
      await profileService
        .ContactUsEmailAsync(userVariables)
        .then(() => {
          setFieldValue("firstName", "", false);
          setFieldValue("email", "", false);
          setMessage(t("contactUs.thankYou"));
          setSuccessAlert(!successAlert);
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setErrorMessage(error.error[i].error);
              }
            }
          } else {
            setErrorMessage(error);
          }
          setErrorAlert(!errorAlert);
        });
      setSubmitting(false);
    }
  }

  const [loading, setLoading] = useState(true);
  const options = {
    decodeEntities: true,
    transform,
  };

  useEffect(() => {
    if (user !== null && user.profileExpired)
      profileService.Logout(user.profile.userId);
  }, []);

  function transform(node, index) {
    if (node.type === "tag" && node.name === "b") {
      return <b key={index}>{processNodes(node.children, transform)}</b>;
    }
    if (
      node.type === "tag" &&
      node.name === "br" &&
      node.next !== null &&
      node.next.name === "br"
    ) {
      return null;
    }
    if (node.type === "tag" && node.name === "br") {
      return <br></br>;
    }
  }

  const fetchData = async () => {
    const loader = document.querySelector(".loader-container");

    setLoading(true);
    landingPageService.GetAllWhoWeAreAsync().then((y) => {
      setWhoWeAre(y);
      y.map((item) =>
        item.typeId === WhoWeAreUserTypes.EarlySupporter ||
        item.typeId === WhoWeAreUserTypes.Initiator ||
        item.typeId === WhoWeAreUserTypes.TechAdvisor
          ? setWPple([...pple, item])
          : corporateAndDev.push(item)
      );
      // console.log(y);
    });

    await adminService.GetChallengeAsync().then((x) => {
      setChallenge(x);
    });

    loader.classList.add("loader--hide");
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    let scrollTo = window.location.hash.replace("#", "");
    Scroll.scroller.scrollTo(scrollTo, {
      offset: -50,
      duration: 1,
      smooth: true,
      delay: 0,
    });
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function ShowErrorMessage(message) {
    setErrorMessage(message);
    setErrorAlert(!errorAlert);
  }

  const settings = {
    slidesToShow: 10,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    draggable: true,
    infinite: true,
    swipeToSlide: true,
    swipe: true,
    arrows: false,
  };

  const [successAlert, setSuccessAlert] = useState(false);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  if (loading) {
    return <></>;
  }
  return (
    <>
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker &&
        isDesktopOrLaptop && <SeekerNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker &&
        !isDesktopOrLaptop && <SeekerNavbarMobileTablet props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
        isDesktopOrLaptop && <ProviderNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
        !isDesktopOrLaptop && <ProviderNavbarMobileTablet props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner &&
        isDesktopOrLaptop && <PartnerNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner &&
        !isDesktopOrLaptop && <PartnerNavbarMobileTablet props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Admin &&
        isDesktopOrLaptop && <AdminNavbar props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Admin &&
        !isDesktopOrLaptop && <AdminNavbarMobileTablet props={history} />}
      {user && user.profile.profileStatus !== ProfileStatus.Active && (
        <LoggedInUserNavbar props={history} />
      )}
      {!user && isDesktopOrLaptop && <LandingPageNavbar2 props={history} />}
      {!user && !isDesktopOrLaptop && (
        <LandingPageNavbarMobileTablet2 props={history} />
      )}
      <div style={isDesktopOrLaptop ? { padding: "70px 70px 0px 70px" } : {}}>
        <div className="text-center">
          <p
            className={isDesktopOrLaptop ? "" : " mt-4"}
            style={
              isDesktopOrLaptop
                ? {
                    color: "#393939",
                    fontSize: "32px",
                    fontFamily: "SpaceGroteskMedium",
                    textAlign: "center",
                  }
                : {
                    color: "#393939",
                    fontSize: "32px",
                    fontFamily: "SpaceGroteskMedium",
                    textAlign: "center",
                    paddingTop: "30px",
                  }
            }
          >
            {t("navbar.contactUs")}
          </p>
          <p
            style={{
              color: "#4B4B4B",
              fontSize: "18px",
              fontFamily: "SpaceGrotesk",
              textAlign: "center",
              marginBottom: "0",
            }}
          >
            {t("contactUs.inquiries.one")}
          </p>
          {/* <p
            style={{
              color: "#4B4B4B",
              fontSize: "18px",
              fontFamily: "SpaceGrotesk",
              textAlign: "center",
              marginBottom: "0",
            }}
          >
            {t("contactUs.inquiries.two")}
          </p>
          <p
            style={{
              color: "#406BAE",
              fontSize: "18px",
              fontFamily: "SpaceGrotesk",
              textAlign: "center",
              marginBottom: "0",
            }}
          >
            {t("contactUs.inquiries.three")}
          </p> */}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => {
            //   useEffect(() => {
            //     let mounted = true;
            //     return () => (mounted = false);
            //   }, []);

            return (
              <div style={{ marginBottom: "50px" }}>
                <>
                  <Form style={{ marginTop: "0.5rem" }}>
                    <CardBody>
                      <div className="form-row">
                        <FormGroup
                          className="col-md-5 d-flex justify-content-center"
                          style={{
                            margin: "0 auto",
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          {/* <label htmlFor="firstName">
                                  {t("contactUs.firstName")}
                                </label> */}

                          <Field
                            name="firstName"
                            style={{
                              backgroundColor: "#ffffff",
                              // width: "400px",
                              border: "1px solid #C6CCD0",
                              borderRadius: "7px",
                              fontSize: "14px",
                              fontFamily: "SpaceGrotesk",
                            }}
                            placeholder={t("contactUs.firstName")}
                            type="test"
                            className={
                              "form-control" +
                              (errors.firstName && touched.firstName
                                ? " is-invalid"
                                : "")
                            }
                          />
                          {errors.firstName && touched.firstName ? (
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="invalid-feedback"
                            />
                          ) : (
                            <span className="isValid" component="div">
                              <br></br>
                            </span>
                          )}
                        </FormGroup>
                      </div>
                      <div className="form-row">
                        <FormGroup
                          className="col-md-5 d-flex justify-content-center"
                          style={{
                            margin: "0 auto",
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          {/* <label htmlFor="inputEmail4">
                                  {t("contactUs.email")}
                                </label> */}

                          <Field
                            name="email"
                            placeholder={t("contactUs.email")}
                            type="text"
                            style={{
                              backgroundColor: "#ffffff",
                              // width: "400px",
                              border: "1px solid #C6CCD0",
                              borderRadius: "7px",
                              fontSize: "14px",
                              fontFamily: "SpaceGrotesk",
                            }}
                            className={
                              "form-control" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                          />
                          {errors.email && touched.email ? (
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          ) : (
                            <span className="isValid" component="div">
                              <br></br>
                            </span>
                          )}
                        </FormGroup>
                      </div>
                      <div className="form-row">
                        <FormGroup
                          className="col-md-5 d-flex justify-content-center"
                          style={{
                            margin: "0 auto",
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          {/* <label htmlFor="message">
                                  {t("contactUs.message")}
                                </label> */}
                          <textarea
                            value={contactUsMessage}
                            name="message"
                            style={{
                              backgroundColor: "#ffffff",
                              // width: "400px",
                              border: "1px solid #C6CCD0",
                              borderRadius: "7px",
                              fontSize: "14px",
                              fontFamily: "SpaceGrotesk",
                              minHeight: "120px",
                            }}
                            rows="3"
                            placeholder={t("contactUs.message")}
                            type="text"
                            required={true}
                            className={"form-control"}
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </FormGroup>
                      </div>
                      <div className="form-row py-2">
                        <ReCAPTCHA
                          style={{
                            display: "inline-block",
                            margin: "0 auto",
                          }}
                          theme="light"
                          ref={_reCaptchaRef}
                          sitekey={TEST_SITE_KEY}
                          onChange={handleChange}
                          hl={currentLan}
                        />
                      </div>
                      <div className="form-row">
                        <div
                          className={
                            isMobile
                              ? "col-md-4 d-flex justify-content-center"
                              : "col-md-4 d-flex justify-content-center"
                          }
                          style={{
                            margin: "0 auto",
                          }}
                        >
                          <Button
                            color="primary"
                            type="submit"
                            size="lg"
                            style={{ fontWeight: "bold" }}
                            disabled={isSubmitting}
                          >
                            {isSubmitting && (
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            {t("contactUs.button")}
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Form>
                </>
              </div>
            );
          }}
        </Formik>
        <Row
          className={
            isMobile
              ? "d-flex justify-content-center"
              : "d-flex justify-content-start"
          }
          style={{ backgroundColor: "#EEF5FB" }}
        >
          <Col className="col-md-4 col-sm-12 col-12">
            <Nav className="flex-row justify-content-start mt-3">
              {/* <NavItem>
                <a className="nav-link " href="#serviceProviders">
                  {t("featuredSP")}
                </a>
              </NavItem> */}
              <Col
                className={
                  isMobile
                    ? "col-md-12 col-sm-12 col-12 d-flex justify-content-center"
                    : "col-md-12 col-sm-12 col-12"
                }
              >
                {/* {user && (
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                      }
                      onClick={() => {
                        history.push("/plans");
                      }}
                      tag={Link}
                    >
                      {t("mainPage.plans")}
                    </NavLink>
                  </NavItem>
                )} */}
                <NavItem>
                  <NavLink
                    style={
                      rtl
                        ? {
                            color: "#4D4D4D",
                            fontFamily: "SpaceGrotesk",
                            textAlign: "justify",
                          }
                        : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                    }
                    onClick={() => {
                      history.push("/ourteam");
                    }}
                    tag={Link}
                  >
                    {t("landingpage.ourteam")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={
                      rtl
                        ? {
                            color: "#4D4D4D",
                            fontFamily: "SpaceGrotesk",
                            textAlign: "justify",
                          }
                        : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                    }
                    onClick={() => {
                      history.push("/contactUs");
                    }}
                    tag={Link}
                  >
                    {t("navbar.contactUs")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={
                      rtl
                        ? {
                            color: "#4D4D4D",
                            fontFamily: "SpaceGrotesk",
                            textAlign: "justify",
                          }
                        : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                    }
                    onClick={() => {
                      history.push("/videos");
                    }}
                    tag={Link}
                  >
                    {t("demo.videos")}
                  </NavLink>
                </NavItem>
                {challenge && challenge.isPublic && (
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                            }
                      }
                      onClick={() => {
                        history.push("/million-dollar-challenge");
                      }}
                      tag={Link}
                    >
                      {t("million.dollar")}
                    </NavLink>
                  </NavItem>
                )}
              </Col>
              {/* <Col
                      className={
                        isMobile
                          ? "col-md-6 col-sm-12 col-12 pb-3 d-flex justify-content-center"
                          : "col-md-6 col-sm-12 col-12"
                      }
                    >
                      
                    </Col> */}

              {/* <LanguageDropdown /> */}
            </Nav>
          </Col>
          <Col
            row
            className={
              isMobile
                ? "d-flex justify-content-center align-items-center col-md-4 col-sm-12 col-12 pt-4 pb-2"
                : "d-flex justify-content-end align-items-center col-md-4 col-sm-12 col-12"
            }
          >
            {" "}
            <Col
              className={
                isMobile
                  ? "d-flex justify-content-center align-items-center flex-column mt-0"
                  : "d-flex justify-content-center align-items-center flex-column mt-4"
              }
            >
              <Row>
                <p
                  style={{
                    color: "#4D4D4D",
                    fontSize: "14px",
                    fontFamily: "SpaceGroteskMedium",
                    textAlign: "left",
                    marginBottom: "0",
                    paddingBottom: "10px",
                  }}
                >
                  {t("mainPage.usaid")}
                </p>
              </Row>
              <Row>
                <img
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    width: "140px",
                  }}
                  src={require("assets/img/usaid_logo_vertical.png").default}
                  alt=""
                />
              </Row>
            </Col>
          </Col>
          {!isMobile ? (
            <Col
              className="col-md-4 col-sm-12 col-12 d-flex justify-content-end align-items-end"
              style={{ paddingBottom: "10px" }}
            >
              <Row
                className={"d-flex justify-content-end align-items-center py-1"}
                style={{ backgroundColor: "#EEF5FB" }}
              >
                <p
                  style={
                    // isMobile
                    //   ? {
                    //       display: "none",
                    //       color: "#fff",
                    //       fontSize: "16px",
                    //       fontFamily: "SpaceGrotesk",
                    //       marginRight: "15px",
                    //       marginBottom: "0",
                    //     }
                    //   : {
                    {
                      color: "#4D4D4D",
                      fontSize: "16px",
                      fontFamily: "SpaceGrotesk",
                      marginRight: "15px",
                      marginBottom: "0",
                    }
                  }
                >
                  {t("followUs")}
                </p>
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "contain",
                    marginRight: "15px",
                    cursor: "pointer",
                  }}
                  src={require("assets/img/linkedin.png").default}
                  onClick={() => {
                    const win = window.open(
                      `https://www.linkedin.com/company/outerpond/`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  alt=""
                />
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "contain",
                    marginRight: "15px",
                    cursor: "pointer",
                  }}
                  src={require("assets/img/twitter.png").default}
                  onClick={() => {
                    const win = window.open(
                      `https://twitter.com/outerpond`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  alt=""
                />
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "contain",
                    marginRight: "15px",
                    cursor: "pointer",
                  }}
                  src={require("assets/img/YoutubeSVG.svg").default}
                  onClick={() => {
                    const win = window.open(
                      `https://www.youtube.com/@outerpond3036`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  alt=""
                />
              </Row>
            </Col>
          ) : (
            <></>
          )}
        </Row>

        {isMobile && (
          <Row
            className={
              isMobile
                ? "d-flex justify-content-center align-items-center py-3"
                : "d-flex justify-content-end align-items-center py-3"
            }
            style={{ backgroundColor: "#EEF5FB" }}
          >
            <p
              style={
                // isMobile
                //   ? {
                //       display: "none",
                //       color: "#fff",
                //       fontSize: "16px",
                //       fontFamily: "SpaceGrotesk",
                //       marginRight: "15px",
                //       marginBottom: "0",
                //     }
                //   : {
                {
                  color: "#4D4D4D",
                  fontSize: "16px",
                  fontFamily: "SpaceGrotesk",
                  marginRight: "15px",
                  marginBottom: "0",
                }
              }
            >
              {t("followUs")}
            </p>
            <img
              style={{
                width: "32px",
                height: "32px",
                objectFit: "contain",
                marginRight: "15px",
                cursor: "pointer",
              }}
              src={require("assets/img/linkedin.png").default}
              onClick={() => {
                const win = window.open(
                  `https://www.linkedin.com/company/outerpond/`,
                  "_blank"
                );
                win.focus();
              }}
              alt=""
            />
            <img
              style={{
                width: "32px",
                height: "32px",
                objectFit: "contain",
                marginRight: "15px",
                cursor: "pointer",
              }}
              src={require("assets/img/twitter.png").default}
              onClick={() => {
                const win = window.open(
                  `https://twitter.com/outerpond`,
                  "_blank"
                );
                win.focus();
              }}
              alt=""
            />
            <img
              style={{
                width: "32px",
                height: "32px",
                objectFit: "contain",
                marginRight: "15px",
                cursor: "pointer",
              }}
              src={require("assets/img/YoutubeSVG.svg").default}
              onClick={() => {
                const win = window.open(
                  `https://www.youtube.com/@outerpond3036`,
                  "_blank"
                );
                win.focus();
              }}
              alt=""
            />
          </Row>
        )}

        <Row
          className={
            isMobile
              ? "d-flex flex-column justify-content-between align-items-center pt-3 px-4"
              : "d-flex justify-content-between align-items-center px-4"
          }
          style={{ backgroundColor: "#132438" }}
        >
          <p
            style={{
              color: "#fff",
              fontSize: "12px",
              fontFamily: "SpaceGrotesk",
              marginRight: "30px",
              marginBottom: "0",
            }}
          >
            {t("mainPage.footer")}
          </p>
          <Nav className="d-flex align-items-center">
            <NavItem>
              <NavLink
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  fontFamily: "SpaceGrotesk",
                }}
                to={"/footer-sub-pages/1"}
                tag={Link}
              >
                {t("footer.faq")}
              </NavLink>
            </NavItem>
            <span className="text-white">—</span>
            <NavItem>
              <NavLink
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  fontFamily: "SpaceGrotesk",
                }}
                to={"/footer-sub-pages/2"}
                tag={Link}
              >
                {t("footer.terms")}
              </NavLink>
            </NavItem>
            <span className="text-white">—</span>
            <NavItem>
              <NavLink
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  fontFamily: "SpaceGrotesk",
                }}
                to={"/footer-sub-pages/3"}
                tag={Link}
              >
                {t("footer.privacyPolicy")}
              </NavLink>
            </NavItem>
          </Nav>
        </Row>
      </div>
    </>
  );
}

export default ContactUs;
