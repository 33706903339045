import React, { useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { LanguageContext } from "_context";
import { Link } from "react-router-dom";
import { AccountTypes } from "_helpers";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ImageIcon from "@mui/icons-material/ImageRounded";
import MovieIcon from "@mui/icons-material/Movie";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import { adminService, languageService } from "_services";
import Switch from "react-bootstrap-switch";
import ReactHtmlParser from "react-html-parser";
Quill.register("modules/imageResize", ImageResize);

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddEditChallenge({ history, match, location }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [challenge, setChallenge] = useState(null);
  const [imageAlert, setImageAlert] = useState(false);
  const [videoAlert, setVideoAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [languagesName, setLanguagesName] = useState([]);
  const [width, setWidth] = useState(0);
  const [align, setAlign] = useState("left");
  const [html, setHtml] = useState("");
  const [index, setIndex] = useState(0);
  const [isPublic, setIsPublic] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [initialValues, setInitialValues] = useState({ description: "" });
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const quillRef = useRef([]);
  const rangeRef = useRef({ index: 0 });
  const imgPath = useRef();
  const videoPath = useRef();

  const modules = {
    toolbar: {
      container: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [({ direction: "" }, { direction: "rtl" })],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["link"],
        // ["image"],
        ["clean"],
      ],
      // handlers: {
      //   image: imageHandler,
      // },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    },
  };
  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "align",
    "background",
    "image",
    "link",
  ];

  const handleQuillChange = (html) => {
    setHtml(html);
    rangeRef.current =
      quillRef.current != null && quillRef.current.getEditorSelection();
  };

  const fetchData = async (lan) => {
    setLoading(true);
    await languageService.GetAllLanguagesAsync().then(async (lang) => {
      setLanguages(lang);
      await adminService.GetChallengeAsync().then((res) => {
        var description = res.description;
        var newJson = { description: "" };
        lang.map((x) => {
          var newKey = x.languageName;
          if (description != null) {
            for (var i = 0; i < description.length; i++) {
              if (x.languageID === description[i].languageID) {
                var newVal = description[i].name
                  .replace(/</g, "&lt;")
                  .replace(/>/g, "&gt;");
                console.log("newVal", newVal);
                newJson[newKey] = newVal;
                languagesName[i] = newJson[newKey];
              }
            }
            setHtml(languagesName);
          }
        });
        setIsPublic(res.isPublic);
        setChallenge(res);
        console.log(res);
      });
    });

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData(currentLan);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    let list = [];
    var regex = /(<([^>]+)>)/gi;

    if (languages !== null) {
      for (var i = 0; i < languages.length; i++) {
        var hasText = !!languagesName[i].replace(regex, "").length;
        if (hasText === false) {
          setError(t("required.all"));
          setErrorAlert(!errorAlert);
          return false;
        }
        const translation = {
          languageID: languages[i].languageID,
          name: languagesName[i]
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/"/g, '"'),
        };
        list.push(translation);
      }
    }
    const model = {
      description: list,
      isPublic: isPublic,
    };
    await adminService
      .UpdateChallengeAsync(model)
      .then(() => {
        history.push(".");
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  const handleVideoUpload = async (file) => {
    var formData = new FormData();
    formData.append("Media", file);

    // let range = quillRef.current.getEditorSelection();
    // console.log(range);
    setVideoAlert(false);
    await adminService
      .MediaUploadAsync(formData)
      .then((res) => {
        let isUploading = document.getElementById("isUploading");
        isUploading.style.display = "none";
        setVideoAlert(true);
        videoPath.current = res.path;
        // quillRef.current
        //   .getEditor()
        //   .insertEmbed(range.index, "image", res.path);
      })
      .catch((err) => {
        let isUploading = document.getElementById("isUploading");
        isUploading.style.display = "none";
        console.log(err);
        return false;
      });
  };

  const handleImageUpload = async (file) => {
    var formData = new FormData();
    formData.append("Media", file);

    // let range = quillRef.current.getEditorSelection();
    // console.log(range);
    setImageAlert(false);
    await adminService
      .MediaUploadAsync(formData)
      .then((res) => {
        setImageAlert(true);
        imgPath.current = res.path;
        let isUploading = document.getElementById("isUploading");
        isUploading.style.display = "none";
        // quillRef.current
        //   .getEditor()
        //   .insertEmbed(range.index, "image", res.path);
      })
      .catch((err) => {
        let isUploading = document.getElementById("isUploading");
        isUploading.style.display = "none";
        console.log(err);
        return false;
      });
  };
  return (
    <div className="section">
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("done")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>{t("challenge.update.success")}</p>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setAlert(!alert);
                history.push("..");
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={imageAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title"></h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <Row className="align-items-center">
              <Col md="3">
                <p className="mb-0">{t("width.alert.body")}</p>
              </Col>
              <Col md="3">
                <input
                  name="width"
                  placeholder="in pixels"
                  onChange={(e) => setWidth(e.target.value)}
                  className="form-control"
                  style={{
                    borderColor: "rgba(0, 0, 0, 0.23)",
                    fontSize: "15px",
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-4 align-items-center">
              <Col md="3">
                <p className="mb-0">{t("align.alert.body")}</p>
              </Col>
              <Col md="9">
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="alignment"
                    defaultValue="left"
                    name="radio-buttons-group"
                    onChange={(e) => setAlign(e.target.value)}
                  >
                    <FormControlLabel
                      value="left"
                      control={<Radio />}
                      label="Left"
                      className="mb-0"
                    />
                    <FormControlLabel
                      value="center"
                      control={<Radio />}
                      label="Center"
                      className="mb-0"
                    />
                    <FormControlLabel
                      value="right"
                      control={<Radio />}
                      label="Right"
                      className="mb-0"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setImageAlert(!imageAlert);
                console.log("imgPath", imgPath);
                const img = `<p style="text-align: ${align}"><a href=\"#\"><img src="${imgPath.current}" width="${width}" / ></a></p>`;
                quillRef.current[index]
                  .getEditor()
                  .insertText(rangeRef.current.index, img);
                // history.push("..");
              }}
            >
              {t("createCompanyProfile.save")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={videoAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title"></h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <Row className="align-items-center">
              <Col md="3">
                <p className="mb-0">{t("width.alert.body")}</p>
              </Col>
              <Col md="3">
                <input
                  name="width"
                  placeholder="in pixels"
                  onChange={(e) => setWidth(e.target.value)}
                  className="form-control"
                  style={{
                    borderColor: "rgba(0, 0, 0, 0.23)",
                    fontSize: "15px",
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-4 align-items-center">
              <Col md="3">
                <p className="mb-0">{t("align.alert.body")}</p>
              </Col>
              <Col md="9">
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="alignment"
                    defaultValue="left"
                    name="radio-buttons-group"
                    onChange={(e) => setAlign(e.target.value)}
                  >
                    <FormControlLabel
                      value="left"
                      control={<Radio />}
                      label="Left"
                      className="mb-0"
                    />
                    <FormControlLabel
                      value="center"
                      control={<Radio />}
                      label="Center"
                      className="mb-0"
                    />
                    <FormControlLabel
                      value="right"
                      control={<Radio />}
                      label="Right"
                      className="mb-0"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setVideoAlert(!videoAlert);
                const video = `<p style="text-align: ${align}"><a href=\"#\"><video width="${width}" controls><source src="${videoPath.current}" type="video/mp4"></source></video></a></p>`;
                quillRef.current[index]
                  .getEditor()
                  .insertText(rangeRef.current.index, video);
                // history.push("..");
              }}
            >
              {t("createCompanyProfile.save")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting, errors, touched, setFieldValue }) => {
              return (
                <Col md="8" style={{ margin: "auto" }}>
                  <Card className={rtl ? "text-right" : ""}>
                    <Form autoComplete="off">
                      <CardBody>
                        <Link onClick={(e) => history.push(".")}>
                          {t("back")}
                        </Link>
                        <CardTitle className="text-center" tag="h4">
                          {t("challenge.update")}
                        </CardTitle>
                        <Row>
                          <Col md="12">
                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("isPublic")}
                                </label>
                                <Switch
                                  value={isPublic}
                                  onChange={() => {
                                    setIsPublic(!isPublic);
                                  }}
                                ></Switch>
                              </FormGroup>
                            </div>
                            <div className="form-group">
                              <FormGroup className="col">
                                <label htmlFor="body">{t("description")}</label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="body"
                                >
                                  *
                                </label>
                                <div style={{ marginBottom: "0.5rem" }}>
                                  <Stack direction="row" spacing={1}>
                                    <label htmlFor="upload-photo">
                                      <input
                                        style={{ display: "none" }}
                                        id="upload-photo"
                                        name="upload-photo"
                                        type="file"
                                        onClick={(e) => {
                                          let isUploading =
                                            document.getElementById(
                                              "isUploading"
                                            );
                                          isUploading.style.display = "block";
                                        }}
                                        onAbort={() => {
                                          let isUploading =
                                            document.getElementById(
                                              "isUploading"
                                            );
                                          isUploading.style.display = "none";
                                        }}
                                        onChange={(e) => {
                                          console.log(e.target.complete);
                                          handleImageUpload(e.target.files[0]);
                                        }}
                                        accept="image/*"
                                      />
                                      <Chip
                                        style={{ cursor: "pointer" }}
                                        label={t("uploadImage")}
                                        color="primary"
                                        icon={
                                          <ImageIcon
                                            className={rtl ? "mr-2 ml-0" : ""}
                                          />
                                        }
                                        variant="outlined"
                                      />
                                    </label>
                                    <label htmlFor="upload-video">
                                      <input
                                        style={{ display: "none" }}
                                        id="upload-video"
                                        name="upload-video"
                                        type="file"
                                        onClick={() => {
                                          let isUploading =
                                            document.getElementById(
                                              "isUploading"
                                            );
                                          isUploading.style.display = "block";
                                        }}
                                        onChange={(e) => {
                                          handleVideoUpload(e.target.files[0]);
                                        }}
                                        accept="video/mp4,video/x-m4v,video/*"
                                      />
                                      <Chip
                                        style={{ cursor: "pointer" }}
                                        label={t("uploadVideo")}
                                        color="primary"
                                        icon={
                                          <MovieIcon
                                            className={rtl ? "mr-2 ml-0" : ""}
                                          />
                                        }
                                        variant="outlined"
                                      />
                                    </label>
                                  </Stack>
                                </div>
                                {/* <ReactQuill
                                  ref={quillRef}
                                  name="description"
                                  value={html}
                                  onChange={handleQuillChange}
                                  modules={modules}
                                  formats={formats}
                                />*/}
                                {languages &&
                                  languages.map((x, i) => {
                                    return (
                                      <div className="form-group">
                                        <FormGroup
                                          className="col-md-10"
                                          style={{
                                            margin: "0 auto",
                                            width: "100%",
                                            padding: "10px",
                                            marginTop: "-2%",
                                          }}
                                        >
                                          <label
                                            style={{
                                              marginTop: "2rem",
                                            }}
                                            htmlFor="inputPassword4"
                                          >
                                            {x.languageDescription}
                                          </label>
                                          <ReactQuill
                                            ref={(el) =>
                                              (quillRef.current[i] = el)
                                            }
                                            style={{
                                              height: "200px",
                                              marginBottom: "6%",
                                              resize: "vertical",
                                            }}
                                            theme="snow"
                                            value={
                                              languagesName[i] === undefined
                                                ? null
                                                : languagesName[i]
                                            }
                                            onChange={(e) => {
                                              languagesName[i] = e;
                                              setLanguagesName(languagesName);
                                              setIndex(i);
                                              rangeRef.current =
                                                quillRef.current[i] != null &&
                                                quillRef.current[
                                                  i
                                                ].getEditorSelection();
                                            }}
                                            modules={modules}
                                            formats={formats}
                                          />
                                        </FormGroup>
                                      </div>
                                    );
                                  })}
                              </FormGroup>
                            </div>

                            <div
                              className="form-row"
                              id="isUploading"
                              style={{ display: "none" }}
                            ></div>

                            <div className="form-row">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "35px",
                                }}
                              >
                                <center>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {t("newsletters.update")}
                                  </Button>
                                </center>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default AddEditChallenge;
