import React, { useState, createContext} from "react";
import { profileService } from "_services";

// Create Context Object
export const UnseenMessagesContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const UnseenMessagesContextProvider = props => {

  const user = profileService.userValue;
  const [currentUnseenMessage, setCurrentUnseenMessage] = useState(user!== null ? user.unseenMessages : 0);

  return (
    <UnseenMessagesContext.Provider value={[currentUnseenMessage, setCurrentUnseenMessage]}>
      {props.children}
    </UnseenMessagesContext.Provider>
  );
};