import React, { useEffect, useState, useContext } from "react";
//import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Col,
  CardTitle,
  Container,
  Modal,
  Row,
} from "reactstrap";
import { profileService } from "../../_services";
import queryString from "query-string";
import { LanguageContext } from "_context";
// core components

function ResetPassword({ history, location }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const parsed = queryString.parse(location.search);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    // document.body.style = "background-color: #f5f5fa";
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const passwordRegex =
    /^(?=.*[A-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])\S{6,100}$/;

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .concat(Yup.string().required(t("signUp.password.required")))
      .min(6, t("signUp.password.regex"))
      .matches(passwordRegex, t("signUp.password.regex")),
    confirmPassword: Yup.string()
      .when("password", (password, schema) => {
        if (password)
          return schema.required(t("signUp.confirmPassword.required"));
      })
      .oneOf([Yup.ref("password")], t("signUp.confirmPassword.match")),
  });
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  const [successAlert, setSuccessAlert] = useState(false);

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    const model = {
      // token: parsed.token,
      token: parsed.token,
      password: fields.password,
      confirmPassword: fields.confirmPassword,
    };
    await profileService
      .ResetPassword(model)
      .then(() => {
        setSuccessAlert(!successAlert);
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  return (
    <>
      <div
        className="section section-signup"
        style={{
          backgroundColor: "#f5f5fa",
          backgroundSize: "cover",
          marginTop: "50px",
        }}
      >
        <Container style={{ marginTop: "-5%" }}>
          <Modal
            isOpen={successAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("renew.password.successfull")}</h5>
            </div>
            <div className={rtl ? "modal-body text-right" : "modal-body"}>
              <p>{t("renew.password.alert.body")}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => {
                  setSuccessAlert(!successAlert);
                  history.push("/account/login");
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
              }
            >
              <p>{errorMessage}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>

          <Row>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, isSubmitting, setFieldValue }) => {
                // useEffect(() => {
                //   let mounted = true;
                //   return () => (mounted = false);
                // }, []);

                return (
                  <Col md="8" style={{ margin: "auto" }}>
                    <Card className={rtl ? "text-right" : ""}>
                      <Form autoComplete="off">
                        <CardBody>
                          <CardTitle className="text-center" tag="h4">
                            {t("renew.password.title")}
                          </CardTitle>
                          <Row>
                            <Col md="12">
                              <div className="form-row">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("signUp.password")}
                                  </label>

                                  <Field
                                    name="password"
                                    placeholder={t("signUp.password")}
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  {errors.password && touched.password ? (
                                    <ErrorMessage
                                      name="password"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("signUp.confirmPassword")}
                                  </label>

                                  <Field
                                    name="confirmPassword"
                                    placeholder={t("signUp.confirmPassword")}
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.confirmPassword &&
                                      touched.confirmPassword
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  {errors.confirmPassword &&
                                  touched.confirmPassword ? (
                                    <ErrorMessage
                                      name="confirmPassword"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <center>
                                    <Button
                                      color="primary"
                                      type="submit"
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting && (
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      )}
                                      {t("renew.password.button")}
                                    </Button>
                                  </center>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Form>
                    </Card>
                  </Col>
                );
              }}
            </Formik>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ResetPassword;
