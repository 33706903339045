import React, { useEffect, useContext, useState } from "react";
import { profileService, messageService } from "../../_services";
import { LanguageContext, UnseenMessagesContext } from "../../_context";
import { Button, Modal } from "reactstrap";
import "./cssStyles.css";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import ReactHtmlParser from "react-html-parser";
import Badge from "reactstrap/lib/Badge";
import Row from "reactstrap/lib/Row";
import "../parsing.css";
import "../../assets/css/scrollbar.css";
import { Redirect } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

// curdikurze@vusra.com

function Messaging({ match, location }) {
  const id = match.params.id;
  const user = profileService.userValue;
  console.log(user);
  if (user == null) {
    Redirect();
  }
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const loc = useLocation();

  const [currentUnseenMessage, setCurrentUnseenMessage] = useContext(
    UnseenMessagesContext
  );

  const [messageOpportunity, setMessageOpportunity] = useState(
    match.params.msgOpportunity == null ? null : match.params.msgOpportunity
  );
  const [currentLan] = useContext(LanguageContext);
  const [convomsgs, setConvomsgs] = useState([]);
  const [userConvProfiles, setUserConvProfiles] = useState([]);
  const [message, setMessage] = useState("");
  const [receiver, setReceiver] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [disabledCause, setDisabledCause] = useState("");
  const [rec, setRec] = useState(null);
  const [projId, setProjId] = useState(messageOpportunity != null ? id : null);
  const [receiverLogo, setReceiverLogo] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [convoIndex, setConvoIndex] = useState(null);
  const [ndaAlert, setNdaAlert] = useState(false);
  const typeBool = useRef(false);
  //onst [typeBool, setBool] = useState(false);
  const [confidentialityAgreement, setConfidentialityAgreement] =
    useState(null);
  const [loading, setLoading] = useState(true);
  const messagesEndRef = React.createRef();

  // const chatNDAModel = {
  //   providerId: user.userID,
  //   sSOrPartnerId:
  // }
  const fetchData = async () => {
    await messageService
      .GetConfidentialityAgreementAsync(currentLan, typeBool.current)
      .then((x) => setConfidentialityAgreement(x.message));
    if (id != null) {
      await messageService
        .GetAllUserConvProfilesAsync(user.profile.userId, id)
        .then((x) => {
          setUserConvProfiles(x);
          if (x.length > 0) setConvoIndex(0);
          for (var i = 0; i < x.length; i++) {
            if (x[i].fromProject == false) {
              if (x[i].receipientProfileID.profile.userId === id) {
                setReceiverLogo(x[i].receipientProfileID.logo.filePath);
                setReceiver(x[i].receipientProfileID.profile.userId);
              }
            } else {
              if (x[i].project.projectID === id) {
                setProjId(x[i].project.projectID);
                if (x[i].receipientProfileID.logo != null)
                  setReceiverLogo(x[i].receipientProfileID.logo.filePath);
                else setReceiverLogo(null);
                setReceiver(x[i].receipientProfileID.profile.userId);
              }
            }
          }
        });
      if (messageOpportunity != null) {
        await messageService
          .GetConvoMessagesAsync(
            user.profile.userId,
            messageOpportunity,
            id,
            true
          )
          .then((x) => {
            setConvomsgs(x);
            setDisabled(false);
            setDisabledCause("");
            profileService.GetProfileByIdAsync(id).then((y) => {
              console.log(y);
              setRec(y);
              if (
                y.wantsToConnect == false &&
                y.profileTypeView.profileTypeDescription == "Partner" &&
                user.wantsToConnect == true &&
                user.profileTypeView.profileTypeDescription == "Partner"
              ) {
                setDisabled(true);
                setDisabledCause("receiverCause");
              } else if (
                y.wantsToConnect == true &&
                y.profileTypeView.profileTypeDescription == "Partner" &&
                user.wantsToConnect == false &&
                user.profileTypeView.profileTypeDescription == "Partner"
              ) {
                setDisabled(true);
                setDisabledCause("userCause");
              } else if (
                y.wantsToConnect == false &&
                y.profileTypeView.profileTypeDescription == "Partner" &&
                user.wantsToConnect == false &&
                user.profileTypeView.profileTypeDescription == "Partner"
              ) {
                setDisabled(true);
                setDisabledCause("receiverCause+userCause");
              }
            });
          });
      } else {
        await messageService
          .GetConvoMessagesAsync(user.profile.userId, id, null, false)
          .then((x) => {
            console.log(x);
            setConvomsgs(x);
            setDisabled(false);
            setDisabledCause("");
            profileService.GetProfileByIdAsync(id).then((y) => {
              console.log(y);
              setRec(y);
              if (
                y.wantsToConnect == false &&
                y.profileTypeView.profileTypeDescription == "Partner" &&
                user.wantsToConnect == true &&
                user.profileTypeView.profileTypeDescription == "Partner"
              ) {
                setDisabled(true);
                setDisabledCause("receiverCause");
              } else if (
                y.wantsToConnect == true &&
                y.profileTypeView.profileTypeDescription == "Partner" &&
                user.wantsToConnect == false &&
                user.profileTypeView.profileTypeDescription == "Partner"
              ) {
                setDisabled(true);
                setDisabledCause("userCause");
              } else if (
                y.wantsToConnect == false &&
                y.profileTypeView.profileTypeDescription == "Partner" &&
                user.wantsToConnect == false &&
                user.profileTypeView.profileTypeDescription == "Partner"
              ) {
                setDisabled(true);
                setDisabledCause("receiverCause+userCause");
              }
            });
          });
      }
    } else {
      await messageService
        .GetAllUserConvProfilesAsync(user.profile.userId)
        .then((x) => {
          setUserConvProfiles(x);
          console.log(x);
        });
    }
    setLoading(false);
  };

  useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    console.log(rec);
    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [convomsgs]);

  useEffect(() => {
    if (user.profileTypeView.profileTypeDescription == "Partner") {
      typeBool.current = true;
    } else {
      typeBool.current = false;
    }
    fetchData();
  }, []);

  function onFormSubmit(event) {
    event.preventDefault();
    if (
      message != null &&
      message != "" &&
      receiver != null &&
      receiver != ""
    ) {
      var date = new Date();
      var month = date.getMonth() + 1;
      if (month.toString().length == 1) {
        month = "0" + month;
      }
      const newMessage = {
        receipientProfileID: receiver,
        senderProfileID: user.profile.userId,
        textMessage: message,
        dateTimeSent:
          date.getFullYear() +
          "-" +
          month +
          "-" +
          date.getDate() +
          " " +
          date.getHours() +
          ":" +
          date.getMinutes(),
      };

      const model = {
        SenderId: user.profile.userId,
        ProjectID: projId,
        TextMessage: message,
        ReceiverId: receiver,
      };

      messageService
        .SendMessageAsync(model)
        .then(() => {
          //  fetchData();
        })
        .catch((error) => {
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
      convomsgs.push(newMessage);
      setConvomsgs(convomsgs);
      setMessage("");
    } else if (message == null || message == "") {
      setErrorAlert(!errorAlert);
      setError(t("error.message.not.empty"));
    } else {
      setErrorAlert(!errorAlert);
      setError(t("choose.recipient"));
    }
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <div className="section section-signup">
        <Modal
          isOpen={ndaAlert}
          className="modal-lg"
          style={rtl ? { textAlign: "right" } : {}}
        >
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title" style={{ fontWeight: "bold" }}>
              {t("confidentialityAgreeement")}
            </h5>
          </div>
          <p style={{ padding: "10px" }}>{t("confidentiality.agree")}</p>
          <div className="modal-body">
            <div className="parsing">
              {ReactHtmlParser(confidentialityAgreement)}
            </div>
          </div>
          <div className="modal-footer" style={{ margin: "auto" }}>
            <Button
              color="primary"
              type="button"
              className="mr-2"
              onClick={async (e) => {
                const model = {
                  providerId: user.profile.userId,
                  sSOrPartnerId: receiver,
                };
                messageService.AddChatNDAAsync(model).then(async () => {
                  setNdaAlert(!ndaAlert);
                  await messageService
                    .GetAllUserConvProfilesAsync(user.profile.userId)
                    .then((x) => {
                      setUserConvProfiles(x);
                      setConvoIndex(null);
                      for (var i = 0; i < x.length; i++) {
                        if (receiver == x[i].receipientProfileID.profile.userId)
                          if (x[i].receipientProfileID.logo != null) {
                            setReceiverLogo(
                              x[i].receipientProfileID.logo.filePath
                            );
                          } else {
                            setReceiverLogo(null);
                          }
                      }
                    });
                });
              }}
            >
              {t("accept")}
            </Button>
            <Button
              color="secondary"
              type="button"
              className="mr-2"
              onClick={(e) => setNdaAlert(!ndaAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div className="modal-body col-md-12">
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <div className="container" style={{ marginTop: "-20px" }}>
          <div className="messaging">
            <div className="inbox_msg">
              <div className="inbox_people">
                <div
                  className={rtl ? "text-right headind_srch" : "headind_srch"}
                >
                  <div className="recent_heading w-100">
                    <h4>{t("messaging")}</h4>
                  </div>
                </div>
                <div className="inbox_chat">
                  {userConvProfiles.length > 0
                    ? userConvProfiles.map((userConv, i) => {
                        return (
                          <div
                            className={
                              convoIndex == i
                                ? "chat_list active_chat"
                                : "chat_list"
                            }
                            onClick={async () => {
                              userConvProfiles[i].unseenMessages = 0;
                              setUserConvProfiles(userConvProfiles);
                              setConvoIndex(i);
                              setDisabled(false);
                              setDisabledCause("");
                              setMessageOpportunity(
                                userConv.fromProject ? "true" : null
                              );
                              if (userConv.fromProject == false) {
                                await messageService
                                  .GetConvoMessagesAsync(
                                    user.profile.userId,
                                    userConv.receipientProfileID.profile.userId,
                                    null,
                                    userConv.fromProject
                                  )
                                  .then((x) => {
                                    setConvomsgs(x);
                                  });
                                setProjId(null);
                                setReceiver(
                                  userConv.receipientProfileID.profile.userId
                                );
                                if (userConv.receipientProfileID.logo != null) {
                                  setReceiverLogo(
                                    userConv.receipientProfileID.logo.filePath
                                  );
                                } else {
                                  setReceiverLogo(null);
                                }
                                await messageService
                                  .UpdateSeenMessagesAsync(
                                    userConv.receipientProfileID.profile.userId,
                                    user.profile.userId,
                                    null
                                  )
                                  .then(async () => {
                                    await profileService
                                      .RefreshTokenAsync()
                                      .then((user) => {
                                        setCurrentUnseenMessage(
                                          user.unseenMessages
                                        );
                                      });
                                  });
                                console.log(userConv);
                                if (userConv != null) {
                                  profileService
                                    .GetProfileByIdAsync(
                                      userConv.receipientProfileID.profile
                                        .userId
                                    )
                                    .then((y) => {
                                      console.log(y);
                                      setRec(y);
                                      if (
                                        y.wantsToConnect == false &&
                                        y.profileTypeView
                                          .profileTypeDescription ==
                                          "Partner" &&
                                        user.wantsToConnect == true &&
                                        user.profileTypeView
                                          .profileTypeDescription == "Partner"
                                      ) {
                                        setDisabled(true);
                                        setDisabledCause("receiverCause");
                                      } else if (
                                        y.wantsToConnect == true &&
                                        y.profileTypeView
                                          .profileTypeDescription ==
                                          "Partner" &&
                                        user.wantsToConnect == false &&
                                        user.profileTypeView
                                          .profileTypeDescription == "Partner"
                                      ) {
                                        setDisabled(true);
                                        setDisabledCause("userCause");
                                      } else if (
                                        y.wantsToConnect == false &&
                                        y.profileTypeView
                                          .profileTypeDescription ==
                                          "Partner" &&
                                        user.wantsToConnect == false &&
                                        user.profileTypeView
                                          .profileTypeDescription == "Partner"
                                      ) {
                                        setDisabled(true);
                                        setDisabledCause(
                                          "receiverCause+userCause"
                                        );
                                      }
                                    });
                                }
                              } else {
                                await messageService
                                  .GetConvoMessagesAsync(
                                    user.profile.userId,
                                    userConv.receipientProfileID.profile.userId,
                                    userConv.project.projectID,
                                    userConv.fromProject
                                  )
                                  .then((x) => {
                                    setConvomsgs(x);
                                  });
                                setProjId(userConv.project.projectID);
                                setReceiver(
                                  userConv.receipientProfileID.profile.userId
                                );
                                if (userConv.receipientProfileID.logo != null)
                                  setReceiverLogo(
                                    userConv.receipientProfileID.logo.filePath
                                  );
                                else setReceiverLogo(null);
                                await messageService
                                  .UpdateSeenMessagesAsync(
                                    userConv.receipientProfileID.profile.userId,
                                    user.profile.userId,
                                    userConv.project.projectID
                                  )
                                  .then(async () => {
                                    await profileService
                                      .RefreshTokenAsync()
                                      .then((user) => {
                                        setCurrentUnseenMessage(
                                          user.unseenMessages
                                        );
                                      });
                                  });
                                console.log(
                                  userConv.receipientProfileID.profile.userId
                                );
                                if (userConv != null) {
                                  profileService
                                    .GetProfileByIdAsync(
                                      userConv.receipientProfileID.profile
                                        .userId
                                    )
                                    .then((y) => {
                                      console.log(y);
                                      setRec(y);
                                      if (
                                        y.wantsToConnect == false &&
                                        y.profileTypeView
                                          .profileTypeDescription ==
                                          "Partner" &&
                                        user.wantsToConnect == true &&
                                        user.profileTypeView
                                          .profileTypeDescription == "Partner"
                                      ) {
                                        setDisabled(true);
                                        setDisabledCause("receiverCause");
                                      } else if (
                                        y.wantsToConnect == true &&
                                        y.profileTypeView
                                          .profileTypeDescription ==
                                          "Partner" &&
                                        user.wantsToConnect == false &&
                                        user.profileTypeView
                                          .profileTypeDescription == "Partner"
                                      ) {
                                        setDisabled(true);
                                        setDisabledCause("userCause");
                                      } else if (
                                        y.wantsToConnect == false &&
                                        y.profileTypeView
                                          .profileTypeDescription ==
                                          "Partner" &&
                                        user.wantsToConnect == false &&
                                        user.profileTypeView
                                          .profileTypeDescription == "Partner"
                                      ) {
                                        setDisabled(true);
                                        setDisabledCause(
                                          "receiverCause+userCause"
                                        );
                                      }
                                    });
                                }
                              }
                            }}
                          >
                            <div className="chat_people">
                              <div className="chat_img">
                                <img
                                  src={
                                    userConv.receipientProfileID.logo == null
                                      ? require("assets/img/restricted.svg")
                                          .default
                                      : userConv.receipientProfileID.logo
                                          .filePath
                                  }
                                  alt="img"
                                />
                              </div>
                              <Row>
                                <div className="chat_ib">
                                  <h5
                                    className={
                                      convoIndex == i ? "chatTitle" : ""
                                    }
                                    onClick={() => {
                                      // if (
                                      //   userConv.senderProfileID !=
                                      //   user.profile.userId
                                      // ) {
                                      if (
                                        userConv.requiresConfidentialityAgreement
                                      )
                                        setNdaAlert(!ndaAlert);
                                      // }
                                    }}
                                  >
                                    {userConv.requiresConfidentialityAgreement ? (
                                      <u>
                                        Click here to approve Confidentiality
                                        Agreement to view Company details
                                      </u>
                                    ) : userConv.receipientProfileID.profile
                                        .companyName == null ? (
                                      t("restriction")
                                    ) : (
                                      userConv.receipientProfileID.profile
                                        .companyName
                                    )}
                                  </h5>
                                  {!userConv.fromProject
                                    ? ""
                                    : userConv.project.projectTitle}
                                </div>
                                {userConv.unseenMessages > 0 ? (
                                  <Badge
                                    style={{
                                      backgroundColor: "#0E6EB8",
                                      color: "white",
                                      height: "fit-content",
                                    }}
                                  >
                                    {userConv.unseenMessages}
                                  </Badge>
                                ) : null}
                              </Row>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
              <div className="mesgs">
                <div className="msg_history">
                  {convomsgs.length > 0
                    ? convomsgs.map((message) =>
                        message.senderProfileID === user.profile.userId ? (
                          <div className="outgoing_msg" ref={messagesEndRef}>
                            <div className="sent_msg">
                              {/* <p>{message.textMessage}</p> */}
                              <p>
                                {message.textMessage.split("\n").map((str) => (
                                  <p style={{ margin: -5 }}>{str}</p>
                                ))}{" "}
                              </p>
                              <span className="time_date">
                                {message.dateTimeSent
                                  .toString()
                                  .substring(0, 10) +
                                  " " +
                                  message.dateTimeSent
                                    .toString()
                                    .substring(11, 16)}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="incoming_msg" ref={messagesEndRef}>
                            <div className="incoming_msg_img">
                              {" "}
                              <img
                                src={
                                  receiverLogo == null
                                    ? require("assets/img/restricted.svg")
                                        .default
                                    : receiverLogo
                                }
                                alt="img"
                              />{" "}
                            </div>
                            <div className="received_msg">
                              <div className="received_withd_msg">
                                <p>
                                  {message.textMessage
                                    .split("\n")
                                    .map((str) => (
                                      <p style={{ margin: -5 }}>{str}</p>
                                    ))}{" "}
                                </p>
                                <span className="time_date">
                                  {message.dateTimeSent
                                    .toString()
                                    .substring(0, 10) +
                                    " " +
                                    message.dateTimeSent
                                      .toString()
                                      .substring(11, 16)}
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    : ""}
                </div>
                <div className="type_msg">
                  <div className="input_msg_write pt-2">
                    <form onSubmit={onFormSubmit}>
                      <textarea
                        style={{ whiteSpace: "pre-wrap", maxHeight: "125px" }}
                        type="text"
                        className="write_msg"
                        placeholder={t("type.message.placeholder")}
                        value={message}
                        onInput={(e) => {
                          e.currentTarget.style.height = "auto";
                          e.currentTarget.style.height =
                            e.currentTarget.scrollHeight + "px";
                        }}
                        onChange={(text) => {
                          // if (text.nativeEvent.inputType === "insertLineBreak")
                          //   onFormSubmit(text.nativeEvent);
                          // else
                          setMessage(text.target.value);
                        }}
                      />
                      {disabled == false ? (
                        <>
                          {" "}
                          <button
                            className="msg_send_btn"
                            type="submit"
                            style={{ marginRight: "-7px" }}
                          >
                            <i className="fa fa-share" aria-hidden="true"></i>
                          </button>
                          {/* <UncontrolledTooltip target="">
                            {t("link.tooltip")}
                          </UncontrolledTooltip> */}
                        </>
                      ) : (
                        <>
                          <button
                            // id="button"
                            className="msg_send_btn"
                            style={{
                              marginRight: "-7px",
                              backgroundColor: "grey",
                              cursor: "not-allowed",
                              //pointerEvents: "none",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              return;
                            }}
                            // disabled
                          >
                            <i className="fa fa-share" aria-hidden="true"></i>
                          </button>
                          {disabledCause == "userCause" ? (
                            <UncontrolledTooltip target="button">
                              {t("userCause")}
                            </UncontrolledTooltip>
                          ) : disabledCause == "receiverCause" ? (
                            <UncontrolledTooltip target="button">
                              {t("receiverCause")}
                            </UncontrolledTooltip>
                          ) : (
                            <UncontrolledTooltip target="button">
                              {t("receiverCause+userCause")}
                            </UncontrolledTooltip>
                          )}
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Messaging;
