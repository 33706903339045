import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
// reactstrap components
import { Card, CardBody, CardTitle, CardHeader, FormGroup } from "reactstrap";
import CardText from "reactstrap/lib/CardText";
import Row from "reactstrap/lib/Row";

// core components

function RestrictedInfoCard({ props }) {
  const { t, i18n } = useTranslation();

  //   useEffect(()=>{

  //   },[])
  return (
    <Card>
      <CardHeader className="text-center">
        <CardTitle className="" tag="h4">
          {props}
        </CardTitle>
        <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
      </CardHeader>
      <CardBody className="text-center" style={{ paddingTop: "0px" }}>
        <Row>
          <>
            <img
              src={require("assets/img/restricted.svg").default}
              alt="Collaboration"
              width="40%"
              height="40%"
              style={{ margin: "0 auto" }}
            />
          </>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <center style={{ margin: "0 auto" }}>
            <CardText style={{ fontWeight: "bold" }}>
              {t("restriction")}{" "}
            </CardText>
          </center>
        </Row>
      </CardBody>
    </Card>
  );
}

export default RestrictedInfoCard;
