import {config} from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/role`;

export const roleService = {
  GetRoleByLanguageAsync
};

function GetRoleByLanguageAsync(lan) {
  return fetchWrapper.get(`${baseUrl}/GetRoleByLanguage/${lan}`);
}


