import React, { useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { LanguageContext } from "_context";
import FormControl from "@mui/material/FormControl";
import { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import { adminService, languageService } from "_services";
import ReactHtmlParser from "react-html-parser";
import "./parsing-quill.css";
import { Alert } from "reactstrap";
Quill.register("modules/imageResize", ImageResize);

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ChallengePreview({ history, match, location }) {
  const { id } = useParams();
  const { path } = match;
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [challenge, setChallenge] = useState(null);
  const [imageAlert, setImageAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [width, setWidth] = useState(0);
  const [align, setAlign] = useState("left");
  const [html, setHtml] = React.useState("");
  const [count, setCount] = React.useState(0);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const quillRef = React.useRef();
  const rangeRef = React.useRef({ index: 0 });
  const imgPath = React.useRef();

  const fetchData = async (lan) => {
    setLoading(true);
    await languageService.GetAllLanguagesAsync().then(async (x) => {
      var lang = x.filter((lan) => lan.languageName === currentLan)[0];
      await adminService.GetChallengeAsync().then((x) => {
        var description = x.description;
        var descriptionObj = description.filter(
          (x) => x.languageID == lang.languageID
        )[0];
        var challengeObj = {
          code: x.code,
          isPublic: x.isPublic,
          description: descriptionObj.name,
        };
        setChallenge(challengeObj);
      });
    });

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData(currentLan);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <div className="section">
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("done")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>{t("newsletters.update.success")}</p>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setAlert(!alert);
                history.push("..");
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={imageAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title"></h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <Row className="align-items-center">
              <Col md="3">
                <p className="mb-0">{t("width.alert.body")}</p>
              </Col>
              <Col md="3">
                <input
                  name="width"
                  placeholder="in pixels"
                  onChange={(e) => setWidth(e.target.value)}
                  className="form-control"
                  style={{
                    borderColor: "rgba(0, 0, 0, 0.23)",
                    fontSize: "15px",
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-4 align-items-center">
              <Col md="3">
                <p className="mb-0">{t("align.alert.body")}</p>
              </Col>
              <Col md="9">
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="alignment"
                    defaultValue="left"
                    name="radio-buttons-group"
                    onChange={(e) => setAlign(e.target.value)}
                  >
                    <FormControlLabel
                      value="left"
                      control={<Radio />}
                      label="Left"
                      className="mb-0"
                    />
                    <FormControlLabel
                      value="center"
                      control={<Radio />}
                      label="Center"
                      className="mb-0"
                    />
                    <FormControlLabel
                      value="right"
                      control={<Radio />}
                      label="Right"
                      className="mb-0"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setImageAlert(!imageAlert);
                const img = `<p style="text-align: ${align}"><a href=\"#\"><img src="${imgPath.current}" width="${width}" / ></a></p>`;
                quillRef.current
                  .getEditor()
                  .insertText(rangeRef.current.index, img);
                // history.push("..");
              }}
            >
              {t("createCompanyProfile.save")}
            </Button>
          </div>
        </Modal>
        <div className="d-flex justify-content-center">
          <Alert
            color="info"
            isOpen={true}
            style={{
              marginTop: "10px",
              width: "fit-content",
              position: "fixed",
              zIndex: "999",
              cursor: "pointer",
            }}
            onDoubleClick={() => history.push(`${path}/edit`)}
            // toggle={() => history.push(`${path}/edit`)}
          >
            {t("editChallengeAlert")}
          </Alert>
        </div>
        <div style={{ marginTop: "70px" }}>
          {challenge && ReactHtmlParser(challenge.description)}
        </div>
      </Container>
    </div>
  );
}

export default ChallengePreview;
