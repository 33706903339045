import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditChallenge from "./AddEditChallenge";
import ChallengePreview from "./ChallengePreview";

function Challenge({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={ChallengePreview} exact />
        <PrivateRoute path={`${path}/edit`} component={AddEditChallenge} />
      </Switch>
    </div>
  );
}

export { Challenge };
