import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import EditSurveyTypesDescription from "./EditSurveyTypesDescription";
import SurveyTypesList from "./SurveyTypesList";

function SurveyTypes({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={SurveyTypesList} exact />
        <PrivateRoute
          path={`${path}/:id`}
          component={EditSurveyTypesDescription}
          exact
        />
      </Switch>
    </div>
  );
}

export { SurveyTypes };
