import React, { useState, useContext, useEffect } from "react";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { InvitationsSent } from "./InvitationsSent";
import Row from "reactstrap/lib/Row";
import CardTitle from "reactstrap/lib/CardTitle";
import CardText from "reactstrap/lib/CardText";
import { useTranslation } from "react-i18next";
function InvitationHistory({ match, props }) {
  const { t } = useTranslation();

  // const fetchData = async () => {
  //   setLoading(true);
  //   await partnerService
  //     .GetPartnerEmailRecipients(user.profile.userId)
  //     .then((x) => {
  //       setData(x.spInvitationEmailDocumentRecipients);
  //     });
  //   setLoading(false);
  // };

  // if (loading) {
  //   return (
  //     <>
  //       <center
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           position: "absolute",
  //           left: "50%",
  //           top: "50%",
  //         }}
  //       >
  //         <PulseLoader color="#0E6EB8" />
  //       </center>
  //     </>
  //   );
  // }
  return (
    <>
      {props && props.length > 0 ? (
        <Card>
          <CardBody>
            <InvitationsSent props={props} />
          </CardBody>
        </Card>
      ) : (
        <Row>
          <Card className="text-center" style={{ boxShadow: "none" }}>
            <CardBody>
              <CardTitle tag="h4">{t("sp.invitation.history.empty")}</CardTitle>
              <CardText>{t("sp.invitation.history.empty.action")}</CardText>
            </CardBody>
          </Card>
        </Row>
      )}
    </>
  );
}

export { InvitationHistory };
