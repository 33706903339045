import React, { useState } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import "../../../parsing.css";
import { Link } from "react-router-dom";
import { profileService, projectService, directLinkService } from "_services";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import CardSubtitle from "reactstrap/lib/CardSubtitle";
import CardText from "reactstrap/lib/CardText";
import ReactCountryFlag from "react-country-flag";
import Modal from "reactstrap/lib/Modal";
import ReactHtmlParser from "react-html-parser";
import Badge from "reactstrap/lib/Badge";
import FormGroup from "reactstrap/lib/FormGroup";
import queryString from "query-string";
import UncontrolledCollapse from "reactstrap/lib/UncontrolledCollapse";
import ProgressBar from "./ProgressBar";
import { StyledEngineProvider } from "@mui/material/styles";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import { LanguageContext } from "_context";
import { useContext } from "react";
function OpportunityDescription({ history, match, location }) {
  const projectID = match.params.projectID;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const user = profileService.userValue;
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [closingDate, setClosingDate] = useState(new Date());
  const [errorMessage, setError] = useState(null);
  const [alert, setAlert] = useState(false);
  const [userAlert, setUserAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [projectStatus, setProjectStatus] = useState(null);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const parsed = queryString.parse(
    location !== null && location !== undefined && location.search
  );

  const oppType = location.search
    ? location.search.split("?")[1].split("&")[1]
    : "listed";

  const fetchData = async () => {
    setLoading(true);
    await profileService
      .GetAllActiveProvidersAsync()
      .then((res) => setUsers(res));
    if (oppType === "listed") {
      await projectService
        .GetProjectByIdAsync(projectID, true, null, currentLan)
        .then((project) => {
          setClosingDate(new Date(project.closingDate));
          setInfo(project);
        });
      await projectService.GetTheProjectStatus(projectID).then((status) => {
        setProjectStatus(status);
      });
    } else {
      await directLinkService
        .GetDirectLinkByIdAsync(projectID, currentLan, true)
        .then((project) => setInfo(project));

      await directLinkService
        .GetTheDirectLinkStatus(projectID)
        .then((status) => {
          setProjectStatus(status);
        });
    }
    setLoading(false);
  };

  const handleExternalLink = async (link) => {
    history.replace(link);
    // const win = window.open(link, "_blank");
    // win.focus();
  };

  const handleUpdateStatus = async () => {
    const model = {
      userID: selectedUser,
      projectID: projectID,
      awarded: true,
    };
    if (oppType == "listed") {
      await projectService
        .ToggleAwardedProjectAsync(model)
        .then((res) => {
          setProjectStatus(res.result);
          setAlert(!alert);
          setUserAlert(!userAlert);
        })
        .catch((error) => {
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    } else {
      await directLinkService
        .ToggleAwardedProjectAsync(model)
        .then((res) => {
          setProjectStatus(res);
          setAlert(!alert);
          setUserAlert(!userAlert);
        })
        .catch((error) => {
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    }
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <div className="section">
        <Container>
          <Modal isOpen={userAlert} toggle={() => setUserAlert(!userAlert)}>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <FormGroup className="col-md-12">
                <label htmlFor="users">{t("select.provider")}</label>
                <select
                  style={{
                    fontSize: "14px",
                  }}
                  name="users"
                  placeholder={t("select.provider")}
                  className="form-control"
                  onChange={(e) => {
                    setSelectedUser(e.target.value);
                  }}
                >
                  <option value={null}>{null}</option>
                  {users.length > 0 &&
                    users.map((user) => (
                      <option value={user.userId}>{user.companyName}</option>
                    ))}
                </select>
              </FormGroup>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={{ margin: "auto" }}
                onClick={() => handleUpdateStatus()}
              >
                {t("newsletters.update")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("done")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{t("editProject.success.body.published")}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={{ margin: "auto" }}
                onClick={(e) => setAlert(!alert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={{ margin: "auto" }}
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>

          <Card
            className={rtl ? "text-right" : ""}
            style={{
              margin: "auto",
              backgroundColor: "rgba(255, 255, 255, 0.766)",
              marginBottom: "1rem",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              borderRadius: "15px",
              paddingBottom: "0.5rem",
              paddingTop: "0.1rem",
            }}
          >
            <CardBody>
              <Row>
                <Col md="10">
                  {parsed.fromFeaturedOpp === "true" ? (
                    <></>
                  ) : (
                    <Link
                      onClick={(e) =>
                        parsed !== null &&
                        parsed !== undefined &&
                        parsed.fromFeaturedOpp === "true"
                          ? history.push("/admin/featured-opportunities?listed")
                          : history.push("..")
                      }
                    >
                      {t("backToList")}
                    </Link>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  md="10"
                  style={{
                    marginBottom: "2rem",
                  }}
                >
                  <CardTitle tag="h4">{t("opportunity.progress")}</CardTitle>
                </Col>
                <Col
                  md="2"
                  style={{
                    marginBottom: "2rem",
                    display: "flex",
                    alignItems: "end",
                    fontSize: "12px",
                    color: "#55B0F8",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  <p onClick={() => setUserAlert(!userAlert)}>
                    {t("update.status")}
                  </p>
                </Col>
              </Row>

              <StyledEngineProvider injectFirst>
                <ProgressBar props={projectStatus} type={oppType} />
              </StyledEngineProvider>
            </CardBody>
          </Card>
          {/* <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRight: "1px solid #eee",
                paddingBottom: "0.5rem",
                paddingTop: "1.5rem",
                width: "100%",
              }}
            > */}
          {/* <Row
                style={{
                  fontSize: "large",
                  color: " rgb(14, 110, 184)",
                  fontWeight: 600,
                }}
              > 
               
              </Row> 
            </Col>*/}

          {info && (
            <Card className={rtl ? "text-right" : ""}>
              <CardBody>
                <Row>
                  <Col md="10">
                    <CardTitle tag="h4">
                      {info.projectTitle !== null
                        ? info.projectTitle || info.title
                        : ""}
                    </CardTitle>
                  </Col>
                </Row>
                {oppType === "linked" && (
                  //  (
                  //   <a target="_blank" rel="noreferrer" href={info.link}>
                  //     {info.link}
                  //   </a>
                  // )

                  <>
                    {/* <div
                      style={{
                        display: "flex",
                        margin: "auto",
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: "inherit",
                      }}
                    >
                      <a target="_blank" rel="noreferrer" href={info.link}>
                        {info.link}
                      </a>
                    </div> */}
                    <Col md={3}>
                      <a
                        tag="h4"
                        style={{ fontSize: "18px" }}
                        // onClick={() => {
                        //   handleExternalLink(info.link);
                        // }}
                        href={info.link}
                      >
                        <UncontrolledTooltip target="link" placement="right">
                          {t("link.tooltip")}
                        </UncontrolledTooltip>
                        <button
                          class="btn btn-primary btn-round btn-sm"
                          id="link"
                        >
                          <i class="fa-solid fa-arrow-up-right-from-square pr-2"></i>{" "}
                          {t("btnText.viewOpportunity")}
                        </button>
                      </a>
                    </Col>

                    <div
                      style={{
                        display: "flex",
                        margin: "auto",
                        padding: "inherit",
                      }}
                    >
                      <CardText
                        style={{
                          width: "350px",
                        }}
                      >
                        <ReactCountryFlag
                          countryCode={
                            info.country && info.country.countryCode !== null
                              ? info.country.countryCode
                              : ""
                          }
                          svg
                          style={{
                            width: "1.5em",
                            height: "1.5em",
                            marginTop: "-2px",
                            marginRight: "10px",
                            borderRadius: "0px",
                          }}
                          title="US"
                        />

                        <small style={{ fontSize: "80%" }}>
                          {t("project.location")}{" "}
                          {info.country && info.country.countryName !== null
                            ? info.country.countryName
                            : ""}
                        </small>
                      </CardText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: "inherit",
                      }}
                    >
                      <CardText style={{ width: "350px" }}>
                        <i
                          className="fa fa-upload"
                          aria-hidden="true"
                          style={{
                            paddingRight: "13px",
                            paddingLeft: "13px",
                          }}
                        ></i>

                        <small style={{ fontSize: "80%" }}>
                          {t("project.publicationDate")}{" "}
                          {`${new Date(info.publicationDateTime).getDate()}/${
                            monthNames[
                              new Date(info.publicationDateTime).getMonth()
                            ]
                          }/${new Date(
                            info.publicationDateTime
                          ).getFullYear()}`}
                        </small>
                      </CardText>
                      <CardText style={{ width: "350px" }}>
                        <i
                          class="fa fa-hourglass-end"
                          aria-hidden="true"
                          style={{
                            paddingRight: "12px",
                            paddingLeft: "12px",
                          }}
                        ></i>

                        <small style={{ fontSize: "80%" }}>
                          {t("project.closingDate")}{" "}
                          {info.closingDate !== null
                            ? `${new Date(info.closingDate).getDate()}/${
                                monthNames[
                                  new Date(info.closingDate).getMonth()
                                ]
                              }/${new Date(info.closingDate).getFullYear()}`
                            : ""}
                        </small>
                      </CardText>
                    </div>
                    <Col style={{ marginTop: "7px" }}>
                      <Row>
                        <div
                          style={{
                            paddingLeft: "3px",
                            paddingRight: "3px",
                          }}
                        >
                          <Badge
                            style={{
                              backgroundColor: "#f5f5fa",
                              color: "#000000",
                              border: "none",
                              fontSize: "85%",
                            }}
                          >
                            {info.typeOfIndustry &&
                            info.typeOfIndustry.typeOfIndustryName !== null
                              ? info.typeOfIndustry.typeOfIndustryName
                              : ""}
                          </Badge>
                        </div>
                        <div
                          style={{
                            paddingLeft: "3px",
                            paddingRight: "3px",
                          }}
                        >
                          <Badge
                            style={{
                              backgroundColor: "#f5f5fa",
                              color: "#000000",
                              border: "none",
                              fontSize: "85%",
                            }}
                          >
                            {info.typeOfIndustry &&
                            info.typeOfIndustry.typeOfServiceName !== null
                              ? info.typeOfIndustry.typeOfServiceName
                              : ""}
                          </Badge>
                        </div>
                      </Row>
                    </Col>
                    <hr />

                    {/* <CardTitle tag="h5">
                      {t("opportunity.description")}
                    </CardTitle> 
                    <hr />*/}
                    {info.description !== null ? (
                      <>
                        <h5 style={{ fontWeight: 600 }}>
                          {t("opportunity.description")}
                        </h5>

                        <div className="parsing">
                          {ReactHtmlParser(info.description)}
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {/* <hr />
                    <CardTitle tag="h5">{t("nda.title")}</CardTitle>
                    <hr />
                    {info.ndaText !== null ? (
                      <div className="parsing">
                        {ReactHtmlParser(info.ndaText)}
                      </div>
                    ) : (
                      ""
                    )} */}
                  </>
                )}

                {oppType === "listed" ? (
                  <>
                    <div style={{ display: "flex" }}>
                      <CardText style={{ width: "350px" }}>
                        <i
                          class="fa fa-cogs"
                          aria-hidden="true"
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "10px",
                          }}
                        ></i>
                        <small style={{ fontSize: "80%" }}>
                          {t("project.type")}{" "}
                          {info.projectTypeView &&
                          info.projectTypeView.projectTypeName !== null
                            ? info.projectTypeView.projectTypeName
                            : ""}
                        </small>
                      </CardText>

                      <CardText style={{ width: "350px" }}>
                        <ReactCountryFlag
                          countryCode={
                            info.country && info.country.countryCode !== null
                              ? info.country.countryCode
                              : ""
                          }
                          svg
                          style={{
                            width: "1.5em",
                            height: "1.5em",
                            marginTop: "-2px",
                            marginRight: "10px",
                            borderRadius: "0px",
                          }}
                          title="US"
                        />

                        <small style={{ fontSize: "80%" }}>
                          {t("project.location")}{" "}
                          {info.country && info.country.countryName !== null
                            ? info.country.countryName
                            : ""}
                        </small>
                      </CardText>
                    </div>
                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      <CardText style={{ width: "350px" }}>
                        <i
                          className="fa fa-upload"
                          aria-hidden="true"
                          style={{
                            paddingRight: "13px",
                            paddingLeft: "13px",
                          }}
                        ></i>

                        <small style={{ fontSize: "80%" }}>
                          {t("project.publicationDate")}{" "}
                          {`${new Date(info.publicationDateTime).getDate()}/${
                            monthNames[
                              new Date(info.publicationDateTime).getMonth()
                            ]
                          }/${new Date(
                            info.publicationDateTime
                          ).getFullYear()}`}
                        </small>
                      </CardText>
                      <CardText style={{ width: "350px" }}>
                        <i
                          class="fa fa-hourglass-end"
                          aria-hidden="true"
                          style={{
                            paddingRight: "12px",
                            paddingLeft: "12px",
                          }}
                        ></i>

                        <small style={{ fontSize: "80%" }}>
                          {t("project.closingDate")}{" "}
                          {closingDate !== null
                            ? `${closingDate.getDate()}/${
                                monthNames[closingDate.getMonth()]
                              }/${closingDate.getFullYear()}`
                            : ""}
                        </small>
                      </CardText>
                    </div>
                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      <CardText style={{ width: "350px" }}>
                        <i
                          className="fa fa-credit-card"
                          aria-hidden="true"
                          style={{
                            paddingRight: "13px",
                            paddingLeft: "13px",
                          }}
                        ></i>
                        <small style={{ fontSize: "80%" }}>
                          {t("search.budget")}{" "}
                          {info.budget && info.budget.description !== null
                            ? info.budget.description
                            : ""}
                        </small>
                      </CardText>
                    </div>
                    <Col style={{ marginTop: "7px" }}>
                      <Row>
                        <div
                          style={{
                            paddingLeft: "3px",
                            paddingRight: "3px",
                          }}
                        >
                          <Badge
                            style={{
                              backgroundColor: "#f5f5fa",
                              color: "#000000",
                              border: "none",
                              fontSize: "85%",
                            }}
                          >
                            {info.typeOfIndustry &&
                            info.typeOfIndustry.typeOfIndustryName !== null
                              ? info.typeOfIndustry.typeOfIndustryName
                              : ""}
                          </Badge>
                        </div>
                        <div
                          style={{
                            paddingLeft: "3px",
                            paddingRight: "3px",
                          }}
                        >
                          <Badge
                            style={{
                              backgroundColor: "#f5f5fa",
                              color: "#000000",
                              border: "none",
                              fontSize: "85%",
                            }}
                          >
                            {info.typeOfIndustry &&
                            info.typeOfIndustry.typeOfServiceName !== null
                              ? info.typeOfIndustry.typeOfServiceName
                              : ""}
                          </Badge>
                        </div>
                      </Row>
                    </Col>
                    {info &&
                      info.supportingDocuments &&
                      info.supportingDocuments.files.length !== 0 && (
                        <CardSubtitle
                          className="mb-2 text-muted"
                          style={{ fontWeight: "bold", marginTop: "20px" }}
                        >
                          {t("createCompanyProfile.supportingDocs")}
                        </CardSubtitle>
                      )}
                    <Row>
                      {info &&
                        info.supportingDocuments &&
                        info.supportingDocuments.files &&
                        info.supportingDocuments.files.map((file) => {
                          return (
                            <a
                              className="col-md-8"
                              target="_blank"
                              rel="noreferrer"
                              href={file.filePath}
                            >
                              {file.fileName}
                            </a>
                          );
                        })}
                    </Row>

                    {info.publishTo !== null &&
                      ((info.publishTo.typeOfIndustriesAndServices !== null &&
                        info.publishTo.typeOfIndustriesAndServices !==
                          undefined &&
                        info.publishTo.typeOfIndustriesAndServices.length >
                          0) ||
                        (info.publishTo.countries !== null &&
                          info.publishTo.countries !== undefined &&
                          info.publishTo.countries.length > 0) ||
                        (info.publishTo.numberOfEmployees !== null &&
                          info.publishTo.numberOfEmployees !== undefined &&
                          info.publishTo.numberOfEmployees.length > 0) ||
                        (info.publishTo.spType !== null &&
                          info.publishTo.spType !== undefined)) && (
                        <>
                          <hr />
                          <CardSubtitle
                            className="mb-2 text-muted"
                            style={{ fontWeight: "bold", marginTop: "20px" }}
                          >
                            {t("opportunityPrivacy")}
                          </CardSubtitle>
                          <Row>
                            {info.publishTo !== null &&
                              info.publishTo.typeOfIndustriesAndServices !==
                                null &&
                              info.publishTo.typeOfIndustriesAndServices !==
                                undefined &&
                              info.publishTo.typeOfIndustriesAndServices
                                .length > 0 && (
                                <FormGroup className="col-md-3">
                                  <div>
                                    <label
                                      className="col-md-6"
                                      htmlFor="companyName"
                                      style={{
                                        fontWeight: "bold",
                                        padding: "0px",
                                      }}
                                    >
                                      {t("filterSearch.industries.placeholder")}
                                    </label>
                                    <div
                                      className="col-md-6"
                                      style={{
                                        //   display: "grid",
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                      }}
                                    >
                                      {info.publishTo.typeOfIndustriesAndServices.map(
                                        (industry) => {
                                          return (
                                            <>
                                              <label
                                              // className="col-md-12"
                                              >
                                                {industry.typeOfIndustryName}
                                              </label>
                                              <br></br>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                              )}

                            {info.publishTo !== null &&
                              info.publishTo.countries !== null &&
                              info.publishTo.countries !== undefined &&
                              info.publishTo.countries.length > 0 && (
                                <FormGroup className="col-md-3">
                                  <div>
                                    <label
                                      className="col-md-6"
                                      htmlFor="companyName"
                                      style={{
                                        fontWeight: "bold",
                                        padding: "0px",
                                      }}
                                    >
                                      {t("filterSearch.country.placeholder")}
                                    </label>
                                    <div
                                      className="col-md-6"
                                      style={{
                                        //   display: "grid",
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                      }}
                                    >
                                      {info.publishTo.countries.map(
                                        (country) => {
                                          return (
                                            <>
                                              <label
                                              // className="col-md-12"
                                              >
                                                {country.countryName}
                                              </label>
                                              <br></br>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                              )}
                            {info.publishTo !== null &&
                              info.publishTo.numberOfEmployees !== null &&
                              info.publishTo.numberOfEmployees !== undefined &&
                              info.publishTo.numberOfEmployees.length > 0 && (
                                <FormGroup className="col-md-3">
                                  <div>
                                    <label
                                      className="col-md-6"
                                      htmlFor="companyName"
                                      style={{
                                        fontWeight: "bold",
                                        padding: "0px",
                                      }}
                                    >
                                      {t(
                                        "filterSearch.companySize.placeholder"
                                      )}
                                    </label>
                                    <div
                                      className="col-md-6"
                                      style={{
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                      }}
                                    >
                                      {info.publishTo.numberOfEmployees.map(
                                        (nbEmp) => {
                                          return (
                                            <>
                                              <label
                                              // className="col-md-12"
                                              >
                                                {nbEmp.description}
                                              </label>
                                              <br></br>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                              )}
                            {info.publishTo !== null &&
                              info.publishTo.spType !== null &&
                              info.publishTo.spType !== undefined && (
                                <FormGroup className="col-md-3">
                                  <div>
                                    <label
                                      className="col-md-6"
                                      htmlFor="companyName"
                                      style={{
                                        fontWeight: "bold",
                                        padding: "0px",
                                      }}
                                    >
                                      {t("signUp.providerType.title")}
                                    </label>
                                    <div
                                      className="col-md-6"
                                      style={{
                                        //   display: "grid",
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                      }}
                                    >
                                      <label
                                      // className="col-md-12"
                                      >
                                        {info.publishTo.spType}
                                      </label>
                                    </div>
                                  </div>
                                </FormGroup>
                              )}
                          </Row>
                        </>
                      )}

                    {info.ndaRequired === true && (
                      <>
                        <Row style={{ marginTop: "20px" }} className="col-md-8">
                          <a
                            href="# "
                            id="nda"
                            className="mb-2 text-muted"
                            style={{ fontWeight: "bold" }}
                          >
                            {t("nonDisclosureAgreement")}
                          </a>
                          {info.userPerformingRequestNDAApproved === true && (
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                              style={{
                                marginTop: "3px",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                color: "#0E6EB8",
                              }}
                            ></i>
                          )}
                        </Row>

                        <UncontrolledCollapse
                          role="tabpanel"
                          toggler="#nda"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="parsing mb-3">
                            {ReactHtmlParser(info.ndaText)}
                          </div>
                        </UncontrolledCollapse>
                      </>
                    )}

                    <hr />

                    {/* <CardTitle tag="h5">
                      {t("opportunity.description")}
                    </CardTitle> 
                    <hr />*/}
                    <h5 style={{ fontWeight: 600 }}>
                      {t("opportunity.description")}
                    </h5>
                    {info.projectDescription !== null ? (
                      <div className="parsing">
                        {ReactHtmlParser(info.projectDescription)}
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <hr />
                    <CardTitle tag="h5">{t("nda.title")}</CardTitle>
                    <hr />
                    {info.ndaText !== null ? (
                      <div className="parsing">
                        {ReactHtmlParser(info.ndaText)}
                      </div>
                    ) : (
                      ""
                    )} */}
                  </>
                ) : null}
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </>
  );
}

export default OpportunityDescription;
