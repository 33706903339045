import React, { useState, useContext } from "react";
import { analyticsService } from "_services";
import { ResponsiveBar } from "@nivo/bar";
import { Container, FormGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
import Button from "reactstrap/lib/Button";
import DatePicker from "react-datepicker";
function OpportunitiesViews() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [dateRangeFrom, setDateRangeFrom] = useState(() => {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var newDate = new Date(year - 1, month, day);
    return newDate;
  });
  const [dateRangeTo, setDateRangeTo] = useState(new Date());
  const fetchData = async () => {
    setLoading(true);
    const model = {
      dateFrom: dateRangeFrom,
      dateTo: dateRangeTo,
    };
    await analyticsService.GetOpportunitiesViews(model).then((x) => {
      setData(x);
    });

    setLoading(false);
  };
  const colors = { views: "#0e6eb8" };
  const getColor = (bar) => colors[bar.id];
  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <div className="section">
      <Container>
        <div>
          <h6 className="text-primary"> {t("OpportunitiesViews-BarChart")}</h6>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <label className="text-primary">
              {" "}
              {t("Analytics.SelectDateRange")}
            </label>
          </div>
          <div className="form-row">
            <FormGroup className="col-md-2">
              <DatePicker
                placeholderText={t("filter.from.placeholder")}
                selected={dateRangeFrom}
                dateFormat="d/MMM/yy"
                className="form-control"
                name={dateRangeFrom}
                onChange={(date) => {
                  setDateRangeFrom(date);
                }}
              />
            </FormGroup>
            <FormGroup className="col-md-2">
              <DatePicker
                placeholderText={t("filter.to.placeholder")}
                selected={dateRangeTo}
                dateFormat="d/MMM/yy"
                className="form-control"
                name={dateRangeTo}
                onChange={(date) => {
                  setDateRangeTo(date);
                }}
              />
            </FormGroup>
          </div>
          <div>
            <Button
              color="primary"
              onClick={() => {
                fetchData();
              }}
            >
              Update Results
            </Button>
          </div>
          <div style={{ height: "500px" }}>
            <ResponsiveBar
              data={data}
              keys={["views"]}
              indexBy="projectName"
              margin={{ top: 50, right: 130, bottom: 200, left: 60 }}
              padding={0.3}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={getColor}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 15,
                legend: "Project Name",
                legendPosition: "middle",
                legendOffset: 70,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Number Of Views",
                legendPosition: "middle",
                legendOffset: -40,
                format: (e) => e % 5 === 0 && e,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  data: [{ label: "Views", color: "#0e6eb8", id: 1 }],
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default OpportunitiesViews;
