import React, { useState, createContext ,useEffect} from "react";

// Create Context Object
export const LanguageContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const LanguageContextProvider = props => {
  const [currentLan, setCurrentLan] = useState(localStorage.getItem("currentLan") ||"en");

  useEffect(() => {
    localStorage.setItem("currentLan", currentLan);
  }, [ currentLan]);

  return (
    <LanguageContext.Provider value={[currentLan, setCurrentLan]}>
      {props.children}
    </LanguageContext.Provider>
  );
};