import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/processLog`;

export const processLogService = {
  GetProcessLogsByCodeAsync,
  GetProcessLogsByCodeCountAsync,
};

function GetProcessLogsByCodeAsync(processCode, params) {
  return fetchWrapper.post(
    `${baseUrl}/GetProcessLogsByCode/${processCode}`,
    params
  );
}

function GetProcessLogsByCodeCountAsync(processCode) {
  return fetchWrapper.get(
    `${baseUrl}/GetProcessLogsCountByCode/${processCode}`
  );
}
