import React from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
// reactstrap components
import { Card, CardBody, CardTitle, CardHeader, FormGroup } from "reactstrap";
import Row from "reactstrap/lib/Row";
import ReactHtmlParser from "react-html-parser";
// core components

function SpecialNeeds({ props }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  return (
    <Card className={rtl ? "text-right" : ""}>
      <CardHeader className="text-center">
        <CardTitle className="" tag="h4">
          {t("createCompanyProfile.spNeeds")}
        </CardTitle>
        <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
      </CardHeader>
      <CardBody>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <div className="parsing">{ReactHtmlParser(props.spNeeds)}</div>
            </div>
          </FormGroup>
        </div>
      </CardBody>
    </Card>
  );
}

export default SpecialNeeds;
