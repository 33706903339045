import { analyticsService } from "_services";
import PulseLoader from "react-spinners/PulseLoader";
import React, { useState, useContext } from "react";
import { ResponsiveSunburst } from "@nivo/sunburst";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
function SpsByCategorySunburst() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    await analyticsService.GetSpsByCategorySunburstInputAsync().then((x) => {
      setData(x);
    });

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <div className="section">
        <Container>
          <div style={{ height: "500px" }}>
            <h6 className="text-primary"> {t("SpsByCategory-Sunburst")}</h6>
            <ResponsiveSunburst
              data={data}
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              id="name"
              value="value"
              valueFormat=" >-d"
              cornerRadius={2}
              borderColor={{ theme: "background" }}
              colors={{ scheme: "category10" }}
              childColor={{ from: "color", modifiers: [["brighter", 0.1]] }}
              enableArcLabels={true}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 1.4]],
              }}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default SpsByCategorySunburst;
