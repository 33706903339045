import React, { useContext } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import LanguageDropdown from "./LanguageDropdown";
import { LanguageContext } from "../../_context";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

function SignUpNavbar({ props }) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const { t, i18n } = useTranslation();
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const rtl = i18n.dir() == "rtl";

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      <Navbar
        className={"fixed-top shadow-none"}
        expand="lg"
        color="white"
        style={
          isMobile
            ? {
                boxShadow: "none !important",
              }
            : {
                paddingLeft: "70px",
                paddingRight: "70px",
                boxShadow: "none !important",
              }
        }
      >
        <div className="d-flex flex-row w-100 justify-content-between">
          <>
            <NavbarBrand
              //   href="https://demos.creative-tim.com/now-ui-kit-react/#/index?ref=nukr-index-navbar"
              // target="_blank"
              id="navbar-brand"
              href="/"
              className="pl-5"
              style={{
                width: "193px",
                height: "48px",
              }}
            >
              {/* Now UI Kit React */}
              {/* <img
                  // src={require("assets/img/outerpond-logo-nobg.png").default}
                  src={require("assets/img/Outerpond-Logo-168x42.png").default}
                  alt=""
                  // height="35px"
                  // width="100px"
                /> */}
              <img
                src={require("assets/img/Outerpond-Logo.png").default}
                alt=""
              />
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Outerpond
            </UncontrolledTooltip>
            <Nav
              className={rtl ? "ml-5" : "ml-auto mr-5"}
              navbar
              style={{ flexDirection: "row" }}
            >
              <LanguageDropdown changeLanguage={(lan) => setCurrentLan(lan)} />
            </Nav>
          </>
        </div>
      </Navbar>
    </>
  );
}

export default SignUpNavbar;
