import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { profileService, adminService } from "../../../_services/index";

// css import
import "./index.css";

function AnalyticsMainPage() {
  const { t } = useTranslation();

  const [embedToken, setEmbedToken] = useState("");

  const user = profileService.userValue;

  // useEffect(() => {
  //   const getAccessToken = async () => {
  //     await adminService
  //       .GetReportAccessTokenAsync(user.profile.userId)
  //       .then((res) => setEmbedToken(res.token))
  //       .catch((err) => console.log(err));
  //   };

  //   getAccessToken();
  // }, []);

  useEffect(() => {
    const element = document.getElementById("report");
    var height = window.innerHeight - 70;
    console.log("height", height);
    if (element != null) {
      // Set the height of the element to the screen height
      element.style.height = `${height}px`;
    }
  });

  return (
    <div>
      {/* <PowerBIEmbed
        embedConfig={{
          type: "report",
          id: "14801c6b-8f65-45cd-8cb5-ea4730fbb6e3",
          embedUrl:
            "https://app.powerbi.com/reportEmbed?reportId=14801c6b-8f65-45cd-8cb5-ea4730fbb6e3&groupId=8f40c532-18b5-4475-bcf0-0981e6c54e0d&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d",
          accessToken: embedToken,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: true,
              },
            },
          },
        }}
        eventHandlers={
          new Map([
            [
              "loaded",
              function () {
                console.log("Report loaded");
              },
            ],
            [
              "rendered",
              function () {
                console.log("Report rendered");
              },
            ],
            [
              "error",
              function (event) {
                console.log(event.detail);
              },
            ],
          ])
        }
        cssClassName={"report-style-class"}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      /> */}
      <iframe
        title="Report Section"
        id="report"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/view?r=eyJrIjoiZTY2NzE0N2QtYmQzMy00ODZmLWI2YzgtYTQwNzZmZjg3NWFlIiwidCI6Ijc3NTkxMjQ3LTRhNmItNGNiMS04YWM5LWQyOThkMjZkZDg1OSIsImMiOjl9"
        frameborder="0"
        allowFullScreen="true"
        marginheight="0"
        marginwidth="0"
        scrolling="auto"
        style={{ marginTop: "70px" }}
      ></iframe>
    </div>
  );
}

export default AnalyticsMainPage;
