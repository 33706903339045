import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  FormGroup,
  Button,
  Row,
  Modal,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { profileService } from "_services";
import { LanguageContext } from "_context";
import queryString from "query-string";

function ChangePassword({ history, location }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const user = profileService.userValue;
  const parsed = queryString.parse(location.search);

  const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const passwordRegex =
    /^(?=.*[A-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])\S{6,100}$/;
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .concat(Yup.string().required(t("signUp.password.required")))
      .min(6, t("signUp.password.regex"))
      .matches(passwordRegex, t("signUp.password.regex")),
    confirmPassword: Yup.string()
      .when("password", (password, schema) => {
        if (password)
          return schema.required(t("signUp.confirmPassword.required"));
      })
      .oneOf([Yup.ref("password")], t("signUp.confirmPassword.match")),
    oldPassword: Yup.string().required(t("oldPassword.error")),
  });

  const loggedUserEmail =
    user.isPrimary && user.loggedInUserID === user.profile.userId
      ? user.profile.userName
      : user.profile.secondaryProfiles.find(
          (sp) => sp.userId === user.loggedInUserID
        )?.email;

  async function onSubmit(fields, { setStatus, setSubmitting, setFieldValue }) {
    setStatus();
    const model = {
      email: loggedUserEmail,
      oldPassword: fields.oldPassword,
      password: fields.password,
      confirmPassword: fields.confirmPassword,
    };
    await profileService
      .ChangePasswordAsync(model)
      .then(() => {
        setSuccessMessage(t("success.changePassword"));
        setFieldValue("oldPassword", "", false);
        setFieldValue("password", "", false);
        setFieldValue("confirmPassword", "", false);
        setSuccessAlert(true);
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  return (
    <>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>{errorMessage}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            style={{ margin: "auto" }}
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={successAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("done")}</h5>
        </div>
        <div className={rtl ? "modal-body text-right" : "modal-body"}>
          <p>{successMessage}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            style={{ margin: "auto" }}
            onClick={(e) => {
              if (
                parsed !== null &&
                parsed !== undefined &&
                parsed.source !== null &&
                parsed.source !== undefined &&
                parsed.source === "admin"
              ) {
                setSuccessAlert(!successAlert);
                history.push("..");
              } else {
                setSuccessAlert(!successAlert);
              }
            }}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Card className={rtl ? "text-right" : ""}>
        <CardHeader className="text-center">
          <CardTitle className="" tag="h4">
            {t("changePassword.title")}
          </CardTitle>

          <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
          <label>
            {t("accountSettings.userDetails")} <b>{loggedUserEmail}</b> [{" "}
            {user.profileTypeView.profileTypeName} ]
          </label>
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, isSubmitting }) => {
              return (
                <div>
                  <Form autoComplete="off">
                    <Row>
                      <FormGroup className="col-md-12">
                        <label htmlFor="inputPassword4">
                          {t("oldPassword")}
                        </label>
                        <Field
                          name="oldPassword"
                          placeholder={t("oldPassword")}
                          type="password"
                          className={
                            "form-control" +
                            (errors.oldPassword && touched.oldPassword
                              ? " is-invalid"
                              : "")
                          }
                        />
                        {errors.oldPassword && touched.oldPassword ? (
                          <ErrorMessage
                            name="oldPassword"
                            component="div"
                            className="invalid-feedback"
                          />
                        ) : (
                          <span className="isValid" component="div">
                            <br></br>
                          </span>
                        )}
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="col-md-12">
                        <label htmlFor="inputPassword4">
                          {t("newPassword")}
                        </label>
                        <Field
                          name="password"
                          placeholder={t("newPassword")}
                          type="password"
                          className={
                            "form-control" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                        />
                        {errors.password && touched.password ? (
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        ) : (
                          <span className="isValid" component="div">
                            <br></br>
                          </span>
                        )}
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="col-md-12">
                        <label htmlFor="inputPassword4">
                          {t("signUp.confirmPassword")}
                        </label>

                        <Field
                          name="confirmPassword"
                          placeholder={t("signUp.confirmPassword")}
                          type="password"
                          className={
                            "form-control" +
                            (errors.confirmPassword && touched.confirmPassword
                              ? " is-invalid"
                              : "")
                          }
                        />
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="invalid-feedback"
                          />
                        ) : (
                          <span className="isValid" component="div">
                            <br></br>
                          </span>
                        )}
                      </FormGroup>
                    </Row>
                    <div className="form-row">
                      <FormGroup
                        className="col-md-8"
                        style={{
                          margin: "0 auto",
                          width: "100%",
                          padding: "10px",
                          marginTop: "-2%",
                        }}
                      >
                        <center>
                          <Button
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting && (
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            {t("change")}
                          </Button>
                        </center>
                      </FormGroup>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </CardBody>
      </Card>
    </>
  );
}

export default ChangePassword;
