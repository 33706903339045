import React, { useState, useContext, useRef, useCallback } from "react";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import * as Yup from "yup";
import { projectTypeService, whowearetypesService } from "_services";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import { languageService } from "_services";
import { adminService } from "_services";
import { LanguageContext } from "_context";
import "../../../../assets/css/generalStyles.css";
import "../../../../assets/css/overrideReactCrop.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function AddEditPeople({ history, match, location }) {
  const isAddMode = !match.params.id;
  const [partners, setPartners] = useState(null);
  const [loading, setLoading] = useState(true);
  const [languages, setLanguages] = useState(null);
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [logoPath, setLogoPath] = useState();
  const [preview, setPreview] = useState(null);
  const [initialValues, setInitialValues] = useState({
    personFName: "",
    personLName: "",
    personLinkedInUrl: "",
    userImage: "",
    personType: "",
    personOrder: "",
  });
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [imageAlert, setImageAlert] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const completedCropRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, height: 30 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [logoAfterCrop, setLogoAfterCrop] = useState(null);
  const [types, setTypes] = useState(null);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const urlRegex =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  const validationSchema = Yup.object().shape({
    // partnerHyperLink: Yup.string().required(t("partnerLink.required")).matches(urlRegex, t("partnerUrlValidation")),
  });

  function initialForm(translations) {
    var newJson = {
      personFName: null,
      personLName: null,
      personLinkedInUrl: null,
      userImage: "",
      personType: "",
      personOrder: "",
    };
    translations.map((x) => {
      var newKey = x.languageName;
      var newVal = "";
      newJson[newKey] = newVal;
    });
    setInitialValues(newJson);
  }

  function editInitialForm(partner) {
    var _fetchedPartner = {
      personFName: partner.firstName,
      personLName: partner.lastName,
      personLinkedInUrl: partner.linkedInUrl,
      userImage: partner.userImage.fileName,
      personOrder: partner.position,
      personType: partner.fetchedTypeIdInNumbers,
    };
    setPreview(partner.userImage.filePath);

    setInitialValues(_fetchedPartner);
  }

  function onLogoChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setPreview(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setLogoPath(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
      setImageAlert(!imageAlert);
    } else {
      setPreview(null);
    }
  }
  function base64StringtoFile(base64String, filename) {
    var arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  function handleCompleteCrop() {
    const canvasRef = previewCanvasRef.current;
    const imageData64 = canvasRef.toDataURL(logoPath.type);
    const myFilename = logoPath.name;

    // file to be uploaded
    const myNewCroppedFile = base64StringtoFile(imageData64, myFilename);
    return myNewCroppedFile;
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  function onCrop(c) {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = c;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    //ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }

  const fetchData = async () => {
    setLoading(true);

    await whowearetypesService.GetAllTypesAsync().then((x) => {
      setTypes(x);
    });

    await languageService.GetAllLanguagesAsync().then((x) => {
      setLanguages(x);
      if (isAddMode) {
        initialForm(x);
      } else {
        editInitialForm(location.state.state);
      }
    });
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    const formData = new FormData();

    if (fields.personFName == null) formData.append("firstName", "");
    else formData.append("firstName", fields.personFName);

    if (fields.personLName == null) formData.append("lastName", "");
    else formData.append("lastName", fields.personLName);

    if (fields.personLinkedInUrl == null) formData.append("linkedInUrl", "");
    else formData.append("linkedInUrl", fields.personLinkedInUrl);

    formData.append("userImage", logoAfterCrop);
    formData.append("position", fields.personOrder);
    formData.append("typeId", fields.personType);

    if (isAddMode) {
      await adminService
        .AddWhoWeArePersonAsync(formData)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (
            error !== null &&
            error.response !== null &&
            error.response.data !== null &&
            error.response.data.message !== undefined
          ) {
            setError(error.response.data.message);
            if (
              error.response.data.error !== null &&
              error.response.data.error.length > 0
            ) {
              for (var i = 0; i < error.response.data.error.length; i++) {
                if (error.response.data.error[i].languageName === currentLan) {
                  setError(error.response.data.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
      setSubmitting(false);
    } else {
      await adminService
        .UpdateWhoWeArePersonAsync(match.params.id, formData)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (
            error !== null &&
            error.response !== null &&
            error.response.data !== null &&
            error.response.data.message !== undefined
          ) {
            setError(error.response.data.message);
            if (
              error.response.data.error !== null &&
              error.response.data.error.length > 0
            ) {
              for (var i = 0; i < error.response.data.error.length; i++) {
                if (error.response.data.error[i].languageName === currentLan) {
                  setError(error.response.data.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
      setSubmitting(false);
    }
  }

  return (
    <div className="section">
      <Container>
        <Modal isOpen={imageAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">Edit Image</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>{t("croppingTool")}</p>
            <Row>
              {preview !== null ? (
                <ReactCrop
                  style={{ margin: "auto" }}
                  src={preview}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => {
                    setCompletedCrop(c);
                    completedCropRef.current = c;
                    onCrop(c);
                  }}
                />
              ) : null}
            </Row>
            <p>{t("previewTool")}</p>
            <Row>
              <canvas
                ref={previewCanvasRef}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                  margin: "auto",
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0),
                }}
              />
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => {
                setPreview(null);
                setLogoAfterCrop(handleCompleteCrop());
                setImageAlert(!imageAlert);
              }}
            >
              {t("done")}
            </Button>
          </div>
        </Modal>

        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("addProjectType.title")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>{isAddMode ? t("addPerson.body") : t("editPerson.body")}</p>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => {
                setAlert(!alert);
                if (isAddMode) {
                  history.push(".");
                } else {
                  history.push("..");
                }
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            // validationSchema={validationSchema}
          >
            {({ setFieldValue, errors, touched, isSubmitting }) => {
              return (
                <Col md="8" style={{ margin: "auto" }}>
                  <Card className={rtl ? "text-right" : ""}>
                    <Form autoComplete="off">
                      <CardBody>
                        <CardTitle className="text-center" tag="h4">
                          {isAddMode ? t("addPerson") : t("editPerson")}
                        </CardTitle>
                        <Row>
                          <Col md="12">
                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("personFName")}
                                </label>
                                <Field
                                  autoFocus={true}
                                  // disabled={!isAddMode}
                                  name="personFName"
                                  placeholder={t("personFName")}
                                  className={"form-control"}
                                />
                              </FormGroup>
                            </div>

                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("personLName")}
                                </label>
                                <Field
                                  // disabled={!isAddMode}
                                  name="personLName"
                                  placeholder={t("personLName")}
                                  className={"form-control"}
                                />
                              </FormGroup>
                            </div>

                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("personLinkedInUrl")}
                                </label>
                                <Field
                                  name="personLinkedInUrl"
                                  placeholder={t("personLinkedInUrl")}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.projectLink && touched.projectLink
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {errors.projectLink && touched.projectLink ? (
                                  <ErrorMessage
                                    name="projectLink"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                ) : (
                                  <span className="isValid" component="div">
                                    <br></br>
                                  </span>
                                )}
                              </FormGroup>

                              {/* <FormGroup
                                                                className="col-md-8"
                                                                style={{
                                                                    margin: "0 auto",
                                                                    width: "100%",
                                                                    padding: "10px",
                                                                    marginTop: "-2%",
                                                                }}
                                                            >
                                                                <label htmlFor="inputPassword4">
                                                                    {t("partnerHyperLink")}
                                                                </label>
                                                                <Field
                                                                    required={true}
                                                                    // autoFocus={true}
                                                                    // disabled={!isAddMode}
                                                                    name="partnerHyperLink"
                                                                    placeholder={t("partnerHyperLink")}
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.projectLink && touched.projectLink
                                                                          ? " is-invalid"
                                                                          : "")
                                                                      }
                                                                />
                                                                
                                                            </FormGroup> */}
                            </div>
                            <div class="form-group">
                              <Row>
                                <Col md="12">
                                  <FormGroup
                                    className="col-md-8"
                                    style={{
                                      margin: "0 auto",
                                      width: "100%",
                                      padding: "10px",
                                      marginTop: "-2%",
                                    }}
                                  >
                                    <label htmlFor="inputPassword4">
                                      {t("personImg")}
                                    </label>
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "100",
                                        marginTop: "10px",
                                      }}
                                      htmlFor="inputPassword4"
                                    >
                                      {t("member.img")}
                                    </label>
                                    {preview !== null ? (
                                      <center>
                                        <img
                                          className="photo-container"
                                          alt="..."
                                          style={{
                                            objectFit: "contain",
                                            margin: "0 0 auto auto",
                                            borderRadius: "0%",
                                          }}
                                          width="10%"
                                          height="10%"
                                          src={preview}
                                        ></img>
                                      </center>
                                    ) : (
                                      <center>
                                        <img
                                          className="photo-container"
                                          alt="..."
                                          style={{
                                            objectFit: "contain",
                                            margin: "0 0 auto auto",
                                            borderRadius: "0%",
                                          }}
                                          width="10%"
                                          height="10%"
                                          src={
                                            logoAfterCrop === null
                                              ? null
                                              : URL.createObjectURL(
                                                  logoAfterCrop
                                                )
                                          }
                                        ></img>
                                      </center>
                                    )}

                                    <input
                                      id="userImage"
                                      style={{ marginTop: "10px" }}
                                      name="userImage"
                                      type="file"
                                      accept="image/*"
                                      className={"form-control "}
                                      onChange={(event) => {
                                        onLogoChange(event);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>

                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("personType")}
                                </label>
                                <Field
                                  name="personType"
                                  style={{ fontSize: "14px" }}
                                  as="select"
                                  className={
                                    "form-control" +
                                    (errors.numberOfEmployees &&
                                    touched.numberOfEmployees
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <option value="" disabled defaultValue hidden>
                                    {t("type.placeholder")}
                                  </option>
                                  {types &&
                                    types.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.typeDescription}
                                      </option>
                                    ))}
                                </Field>
                              </FormGroup>
                            </div>

                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("personOrder")}
                                </label>

                                <Field
                                  type="number"
                                  required={true}
                                  // autoFocus={true}
                                  // disabled={!isAddMode}
                                  name="personOrder"
                                  className="form-control"
                                  min={0}
                                ></Field>
                                <label
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "100",
                                    marginTop: "10px",
                                  }}
                                  htmlFor="inputPassword4"
                                >
                                  {t("member.sorting")}
                                </label>
                              </FormGroup>
                            </div>

                            <div className="form-row">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <center>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {isAddMode
                                      ? t("add.button")
                                      : t("createCompanyProfile.save")}
                                  </Button>
                                </center>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default AddEditPeople;
