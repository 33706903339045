import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import ProfilePage from "../Profile/CompanyProfilePage/ProfilePage";
import PartnerNavbar from "components/Navbars/PartnerNavbar";
import EditProfile from "components/Profile/EditProfile";
import { AccountSettings } from "components/Profile/AccountSettings/Index";
import { InviteProviders } from "./InviteProviders/Index";
import { InviteSeekers } from "./InviteSeekers/Index";
import { SearchProviderContextProvider } from "_context";
import { SearchListContextProvider } from "_context";
import { useMediaQuery } from "react-responsive";
import PartnerNavbarMobileTablet from "components/Navbars/PartnerNavbarMobileTablet";
import { Search } from "../ServiceSeeker/Search/Index";
import { Project } from "../ServiceSeeker/Projects/index";
import Message from "components/Messages/messaging";
import { SpSeen } from "components/ServiceSeeker/SpSeen/Index";
import { AccountTypes } from "_helpers";
import { DirectLinks } from "components/ServiceSeeker/DirectLinks/Index";
import { SearchPartnersIndex } from "./Search/Index";
import { SearchPartnerContextPartner } from "_context";
import { SearchPartnersListContextProvider } from "_context";
import { SurveyResponse } from "./SurveyResponse";
import { Survey } from "./Survey/Index";
// import MembershipHistoryPage from "components/Profile/CompanyProfilePage/Membership/MembershipHistoryPage";
// import ActiveMembership from "components/Profile/CompanyProfilePage/Membership/ActiveMembership";
// import { Insights } from "components/Profile/CompanyProfilePage/Insights/Index";
function Partner({ history, match }) {
  const { path } = match;

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <>
      {isDesktopOrLaptop && <PartnerNavbar props={history} />}
      {!isDesktopOrLaptop && <PartnerNavbarMobileTablet props={history} />}
      <Switch>
        <SearchProviderContextProvider>
          <SearchPartnerContextPartner>
            <SearchListContextProvider>
              <SearchPartnersListContextProvider>
                <PrivateRoute
                  path={`${path}/profile-page`}
                  component={ProfilePage}
                  roles={[AccountTypes.Partner]}
                  exact
                />
                <PrivateRoute
                  path={`${path}/profile-page/edit`}
                  component={EditProfile}
                  roles={[AccountTypes.Partner]}
                  exact
                />
                <PrivateRoute
                  path={`${path}/accountSettings`}
                  component={AccountSettings}
                  roles={[AccountTypes.Partner]}
                  exact
                />
                <PrivateRoute
                  path={`${path}/invite-providers`}
                  component={InviteProviders}
                  exact
                />
                <PrivateRoute
                  path={`${path}/invite-seekers`}
                  component={InviteSeekers}
                  exact
                />
                <PrivateRoute
                  path={`${path}/search-provider`}
                  component={Search}
                />
                <PrivateRoute
                  path={`${path}/search-partners`}
                  component={SearchPartnersIndex}
                />
                <PrivateRoute path={`${path}/projects`} component={Project} />
                <PrivateRoute
                  path={`${path}/messaging/:id?`}
                  component={Message}
                />
                <PrivateRoute path={`${path}/seenSP`} component={SpSeen} />
                <PrivateRoute
                  path={`${path}/directLinks`}
                  component={DirectLinks}
                />
                <PrivateRoute
                  path={`${path}/survey`}
                  component={SurveyResponse}
                />
                {/* <PrivateRoute
                  path={`${path}/profile-page/membershipHistory`}
                  component={MembershipHistoryPage}
                  exact
                />
                <PrivateRoute
                  path={`${path}/profile-page/activeMembership`}
                  component={ActiveMembership}
                  exact
                />
                <PrivateRoute path={`${path}/pending`} component={Survey} />
                <PrivateRoute
                  path={`${path}/profile-page/Insights`}
                  component={Insights}
                /> */}
              </SearchPartnersListContextProvider>
            </SearchListContextProvider>
          </SearchPartnerContextPartner>
        </SearchProviderContextProvider>
      </Switch>
    </>
  );
}

export { Partner };
