import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/language`;

export const languageService = {
  GetAllLanguagesAsync,
};

function GetAllLanguagesAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllLanguages`);
}
