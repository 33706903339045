import React, { useState, useContext } from "react";
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Checkbox, Paper, ThemeProvider } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { theme } from "../../../theme.js";
import { adminService } from "_services/admin.service.js";
import Modal from "reactstrap/lib/Modal";
import { LanguageContext } from "_context";
import { profileService } from "_services/profile.service.js";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { searchService } from "_services";
import Tooltip from "@mui/material/Tooltip";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  SubdirectoryArrowLeftIcon: forwardRef((props, ref) => (
    <SubdirectoryArrowLeftIcon {...props} ref={ref} />
  )),
  StarIcon: forwardRef((props, ref) => <StarIcon {...props} ref={ref} />),
  StarBorderIcon: forwardRef((props, ref) => (
    <StarBorderIcon {...props} ref={ref} />
  )),
  AccountBalanceIcon: forwardRef((props, ref) => (
    <AccountBalanceIcon {...props} ref={ref} />
  )),
  FileCopyIcon: forwardRef((props, ref) => (
    <FileCopyIcon {...props} ref={ref} />
  )),
};

function ExternalOpportunitiesList({ history, match, location }) {
  const { path } = match;
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteListAlert, setDeleteListAlert] = useState(false);
  const [rowDataID, setRowDataID] = useState(null);
  const [warningAlert, setWarningAlert] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [selectedIds, setSelectedIds] = useState([]);
  const user = profileService.userValue;
  const resetSearch = React.useRef(false);

  const fetchCount = async () => {
    await adminService
      .GetScrapedOpportunitiesCount()
      .then((res) => setItemCount(res));
  };

  const fetchData = async () => {
    if (resetSearch.current == false) setLoading(true);
    const model = {
      emailLogsToSkip: begin.current,
      startingEmailLogs: pageSize,
    };
    await adminService.GetScrapedOpportunitiesAsync(model).then((x) => {
      console.log(x);
      setProjects(x);
    });

    setLoading(false);
    resetSearch.current = false;
  };

  const handleSearch = async (e) => {
    // setSearchKeyword(e);
    const model = {
      Keyword: e,
      PageNumber: 0,
      PageSize: pageSize,
    };
    await searchService.SearchExternalOpportunitiesAsync(model).then((res) => {
      setProjects(res);
      begin.current = 0;
      end.current = pageSize;
      setPageSize(pageSize);
    });
    await searchService
      .SearchExternalOpportunitiesCountAsync(model)
      .then((res) => {
        setItemCount(res);
      });
  };

  var columns = [
    {
      title: t("addProject.projectTitle"),
      field: "ID",
      hidden: true,
    },
    {
      title: t("source.site"),
      field: "sourceSite",
      width: "15%",
    },
    {
      title: t("addProject.projectTitle"),
      field: "title",
      width: "25%",
    },
    {
      title: t("projectStatus"),
      field: "status",
      width: "10%",
    },
    {
      title: t("project.publicationDate"),
      field: "publicationDate",
      type: "date",
      width: "10%",
      dateSetting: { locale: "en-GB" },
    },
    {
      title: t("filter.closingDate.placeholder"),
      field: "closingDate",
      type: "date",
      width: "10%",
      dateSetting: { locale: "en-GB" },
    },
  ];

  let [currentPage, setCurrentPage] = useState(0);
  const userPerPage = pageSize;
  const begin = React.useRef(0);
  const end = React.useRef(userPerPage);

  const count = Math.ceil(itemCount / userPerPage);

  function next() {
    if (end.current > itemCount) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
    begin.current = (currentPage + 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  const handleChange = (value) => {
    setPageSize(parseInt(value));
    end.current = begin.current + parseInt(value);
  };

  React.useEffect(() => {
    fetchCount();
  }, []);

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [end.current, begin.current, pageSize]);

  React.useEffect(() => {}, []);

  function handleRemove(id) {
    const newList = projects.filter((item) => item.id !== id);
    setProjects(newList);
  }
  function handleRemoveList(list) {
    console.log(list);
    list.forEach((id) => {
      const newList = projects.filter((item) => item.id !== id);
      setProjects(newList);
    });
  }

  async function deleteItem(id) {
    console.log(id);
    await adminService
      .UpdateScrapedOpportunityAsync(id, "2")
      .then((x) => {
        handleRemove(id);
        setDeleteAlert(!deleteAlert);
      })
      .catch((error) => {
        setDeleteAlert(!deleteAlert);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }
  async function deleteSelectedItem() {
    await adminService
      .UpdateScrapedOpportunitiesDelete(selectedIds)
      .then((x) => {
        console.log("hi");
        // handleRemoveList(selectedIds);
        fetchData();
        setItemCount(itemCount - selectedIds.length);
        setSelectedIds([]);
        setDeleteListAlert(!deleteListAlert);
      })
      .catch((error) => {
        setDeleteListAlert(!deleteListAlert);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  console.log(selectedIds);

  const handleCheckboxClick = (event, rowData) => {
    const selectedId = rowData.id;
    const isSelected = selectedIds.includes(selectedId);
    console.log(rowData.id);
    console.log(isSelected);
    event.preventDefault();
    if (isSelected) {
      setSelectedIds(selectedIds.filter((id) => id !== selectedId));
    } else {
      setSelectedIds([...selectedIds, selectedId]);
    }
    event.target.checked = !isSelected;
  };

  // if (loading) {
  //   return (
  //     <>
  //       <center
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           position: "absolute",
  //           left: "50%",
  //           top: "50%",
  //         }}
  //       >
  //         <PulseLoader color="#0E6EB8" />
  //       </center>
  //     </>
  //   );
  // }
  return (
    <>
      <div className="section">
        <Container>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={() => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("delete.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{t("delete.alert.body")}</p>
            </div>

            <div
              className="modal-footer d-flex justify-content-center"
              style={rtl ? { textAlign: "right" } : {}}
            >
              <center>
                <Button
                  type="button"
                  className="mr-2"
                  style={{ margin: "auto" }}
                  color="primary"
                  onClick={() => setDeleteAlert(!deleteAlert)}
                >
                  {t("cancel.link")}
                </Button>
                <Button
                  color="danger"
                  type="button"
                  className="mr-2"
                  style={{ margin: "auto", marginLeft: "100px" }}
                  onClick={() => deleteItem(rowDataID)}
                >
                  {t("delete")}
                </Button>
              </center>
            </div>
          </Modal>
          <Modal
            isOpen={deleteListAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("delete.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{t("delete.alert.body")}</p>
            </div>

            <div
              className="modal-footer d-flex justify-content-center"
              style={rtl ? { textAlign: "right" } : {}}
            >
              <center>
                <Button
                  type="button"
                  className="mr-2"
                  style={{ margin: "auto" }}
                  color="primary"
                  onClick={() => setDeleteListAlert(!deleteListAlert)}
                >
                  {t("cancel.link")}
                </Button>
                <Button
                  color="danger"
                  type="button"
                  className="mr-2"
                  style={{ margin: "auto", marginLeft: "100px" }}
                  onClick={() => deleteSelectedItem()}
                >
                  {t("delete")}
                </Button>
              </center>
            </div>
          </Modal>
          <Modal
            isOpen={warningAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("warning")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{t("featuredOpportunityAlert")}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={() => setWarningAlert(!warningAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Row>
            <Col md="12">
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",

                  width: "100%",
                  columnGap: "10rem",
                }}
              >
                <div
                  style={{
                    color: "#0e6eb8",
                    fontSize: "1.25rem",
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                    fontWeight: 500,
                    letterSpacing: "0.0075em",
                    textTransform: "none",
                    marginLeft: "15px",
                  }}
                >
                  {t("externalOpportunities")}
                </div>
                <FormControl
                  className="d-flex flex-row align-items-center"
                  sx={{ m: 1 }}
                  variant="standard"
                >
                  <Input
                    id="searchInput"
                    type="text"
                    placeholder={t("search")}
                    onChange={(e) => handleSearch(e.currentTarget.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton aria-label="toggle password visibility">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => {
                            const searchInput =
                              document.getElementById("searchInput");
                            searchInput.value = "";
                            resetSearch.current = true;
                            fetchData();
                            fetchCount();
                          }}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Tooltip title={t("delete.button")}>
                    <Delete
                      style={{ fill: "#FF3636", cursor: "pointer" }}
                      className="actionIcon"
                      disabled={selectedIds.length > 0 ? false : true}
                      onClick={(event, rowData) => {
                        if (selectedIds.length > 0)
                          setDeleteListAlert(!deleteAlert);
                      }}
                    ></Delete>
                  </Tooltip>
                </FormControl>
              </Row>
              {loading && (
                <center
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    left: "50%",
                    top: "100%",
                  }}
                >
                  <PulseLoader color="#0E6EB8" />
                </center>
              )}
              {!loading && (
                <>
                  <ThemeProvider theme={theme}>
                    <MaterialTable
                      components={{
                        Container: (props) => (
                          <Paper
                            {...props}
                            className="usersTable"
                            elevation={0}
                          />
                        ),
                      }}
                      title={<span></span>}
                      columns={columns}
                      data={projects}
                      icons={tableIcons}
                      onSearchChange={handleSearch}
                      options={{
                        // pageSize: 10,
                        search: false,
                        paging: false,
                        actionsColumnIndex: -1,
                        cellStyle: {
                          textAlign: rtl ? "right" : "left",
                        },
                        headerStyle: {
                          textAlign: rtl ? "right" : "left",
                        },
                      }}
                      localization={{
                        header: {
                          actions: t("actions"),
                        },
                        toolbar: {
                          searchPlaceholder: t("search"),
                        },
                      }}
                      actions={[
                        {
                          icon: () => (
                            <AddBox
                              className="actionIcon"
                              style={{
                                fill: "#0E6EB8",
                              }}
                            />
                          ),
                          tooltip: t("add.direct.link"),
                          onClick: (event, rowData) => {
                            history.push({
                              pathname: `${path}/opportunity-details`,
                              state: {
                                project: rowData,
                                scrapedOpportunity: true,
                              },
                            });
                          },
                        },
                        (rowData) => ({
                          icon: () => (
                            <ArrowOutwardIcon
                              className="actionIcon"
                              style={{
                                fill: "#0E6EB8",
                              }}
                            />
                          ),
                          disabled: rowData.link == null ? true : false,
                          tooltip: t("view.opportunity.externally"),
                          onClick: (event, rowData) => {
                            console.log(rowData);
                            window.open(`${rowData.link}`, "_blank");
                          },
                        }),
                        // {
                        //   icon: () => (
                        //     <Delete
                        //       style={{ fill: "#FF3636" }}
                        //       className="actionIcon"
                        //     />
                        //   ),
                        //   tooltip: t("delete"),
                        //   onClick: (event, rowData) => {
                        //     setRowDataID(rowData.id);
                        //     setDeleteAlert(!deleteAlert);
                        //   },
                        // },
                        (rowData) => ({
                          icon: () => (
                            <Checkbox
                              style={{ fill: "#0E6EB8", color: "#0E6EB8" }}
                              className="actionIcon"
                              checked={selectedIds.includes(rowData.id)}
                              // onChange={(event) =>
                              //   handleCheckboxChange(event, rowData)
                              // }
                            />
                          ),
                          tooltip: t("select"),
                          // onClick: (event, rowData) => {
                          //   setRowDataID(rowData.id);
                          //   setDeleteAlert(!deleteAlert);
                          // },
                          onClick: (event, rowData) => {
                            handleCheckboxClick(event, rowData);
                          },
                        }),
                        {
                          icon: () => (
                            <Delete
                              style={{ fill: "#FF3636" }}
                              className="actionIcon"
                            />
                          ),
                          tooltip: t("delete"),
                          isFreeAction: true,
                          disabled: selectedIds.length > 0 ? false : true,
                          onClick: (event, rowData) => {
                            if (selectedIds.length > 0)
                              setDeleteListAlert(!deleteAlert);
                          },
                        },
                      ]}
                    />
                  </ThemeProvider>
                  <div className="d-flex justify-content-end align-items-center">
                    <select
                      value={pageSize}
                      onChange={(e) => handleChange(e.target.value)}
                      style={{
                        height: "36px",
                        borderRadius: 0,
                        width: "auto",
                        fontSize: "12px",
                      }}
                      className={"form-control"}
                    >
                      <option
                        key={5}
                        value={5}
                        style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                      >
                        5
                      </option>
                      <option
                        key={10}
                        value={10}
                        style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                      >
                        10
                      </option>
                      <option
                        key={15}
                        value={15}
                        style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                      >
                        15
                      </option>
                      <option
                        key={20}
                        value={20}
                        style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                      >
                        20
                      </option>
                      <option
                        key={50}
                        value={50}
                        style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                      >
                        50
                      </option>
                      <option
                        key={100}
                        value={100}
                        style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                      >
                        100
                      </option>
                    </select>
                    <IconButton
                      aria-label="next"
                      disabled={currentPage == 0 ? true : false}
                    >
                      {rtl ? (
                        <ArrowForwardIosSharpIcon
                          onClick={() => prev()}
                          style={{
                            color: "#d9dcde",
                          }}
                        />
                      ) : (
                        <ArrowBackIosSharpIcon
                          onClick={() => prev()}
                          style={{
                            color: "#d9dcde",
                          }}
                        />
                      )}
                    </IconButton>
                    <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
                      {begin.current} -{" "}
                      {end.current > itemCount ? itemCount : end.current}{" "}
                      {rtl ? "من" : "of"} {itemCount}
                    </p>
                    <IconButton
                      aria-label="next"
                      disabled={end.current >= itemCount ? true : false}
                    >
                      {rtl ? (
                        <ArrowBackIosSharpIcon
                          onClick={() => next()}
                          style={{
                            color: "#d9dcde",
                          }}
                        />
                      ) : (
                        <ArrowForwardIosSharpIcon
                          onClick={() => next()}
                          style={{
                            color: "#d9dcde",
                          }}
                        />
                      )}
                    </IconButton>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ExternalOpportunitiesList;
