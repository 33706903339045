import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "_context";
import { Card, CardBody, CardTitle } from "reactstrap";
import CardText from "reactstrap/lib/CardText";
import Button from "reactstrap/lib/Button";
import { Formik, Form, ErrorMessage } from "formik";
import Modal from "reactstrap/lib/Modal";
import FormGroup from "reactstrap/lib/FormGroup";
import Row from "reactstrap/lib/Row";
import { profileService, partnerService } from "_services";
import * as Yup from "yup";
import { ErrorsList } from "./ErrorsList";
import { InvitationsSent } from "./InvitationsSent";
import PulseLoader from "react-spinners/PulseLoader";
import { FileNames } from "_helpers";

function UploadExcel({ onUpload }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const user = profileService.userValue;

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);

  const [errors, setErrorsList] = useState([]);
  const [invitationsSent, setInvitationsSent] = useState([]);

  const [downloading, setDownloading] = useState(false);

  const [loading, setLoading] = useState(true);

  async function DownloadExcel() {
    // const model = {
    //   downloadSpDocument: true,
    // };
    await partnerService
      .DownloadPartnerExcel(1)
      .then((x) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([x.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", FileNames.InviteProvider);
        document.body.appendChild(link);
        link.click();
        //    fileDownload(x.data, "UploadedSps.xlsx");
      })
      .catch((error) => {
        setDownloading(false);
        if (
          error !== null &&
          error.response !== null &&
          error.response.data !== null &&
          error.response.data.message !== undefined
        ) {
          setError(error.response.data.message);
          if (
            error.response.data.error !== null &&
            error.response.data.error.length > 0
          ) {
            for (var i = 0; i < error.response.data.error.length; i++) {
              if (error.response.data.error[i].languageName == currentLan) {
                setError(error.response.data.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        //   setErrorAlert(!errorAlert);
      });
  }
  const fetchData = async () => {
    setLoading(true);
    await partnerService
      .GetPartnerDocumentErrors(user.profile.userId, 1)
      .then((x) => {
        const list = [];
        x.spInvitationDocumentErrors &&
          x.spInvitationDocumentErrors.errorsList &&
          x.spInvitationDocumentErrors.errorsList.map((error) => {
            const model = {
              errorsList: error,
            };
            list.push(model);
          });
        setErrorsList(list);
      })
      .catch((error) => {
        if (
          error !== null &&
          error.response !== null &&
          error.response.data !== null &&
          error.response.data.message !== undefined
        ) {
          setError(error.response.data.message);
          if (
            error.response.data.error !== null &&
            error.response.data.error.length > 0
          ) {
            for (var i = 0; i < error.response.data.error.length; i++) {
              if (error.response.data.error[i].languageName == currentLan) {
                setError(error.response.data.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        //   setErrorAlert(!errorAlert);
      });
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const SUPPORTED_FORMATS = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  async function onSubmit(
    fields,
    { setStatus, setSubmitting, setFieldValue, setErrors }
  ) {
    setStatus();

    setErrorsList([]);
    setInvitationsSent([]);

    const model = {
      userId: user.profile.userId,
      document: fields.file,
    };

    const formData = new FormData();
    formData.append("language", currentLan);
    formData.append("userId", user.profile.userId);
    formData.append("document", fields.file);

    await partnerService
      .ProcessSpExcel(formData)
      .then(async (x) => {
        if (
          x.data.receivedData &&
          x.data.receivedData.uploadedDocumentErrors !== null &&
          x.data.receivedData.uploadedDocumentErrors.errorsList !== null &&
          x.data.receivedData.uploadedDocumentErrors.errorsList.length > 0
        ) {
          const list = [];
          x.data.receivedData.uploadedDocumentErrors.errorsList.map((error) => {
            const errorModel = {
              errorsList: error,
            };
            list.push(errorModel);
          });
          setErrorsList(list);
          x.data.msg.translatedMsg.map((x) => {
            if (x.languageName === currentLan) {
              setError(x.msg);
            }
          });
          setErrorAlert(!errorAlert);
        }

        if (
          x.data.receivedData &&
          x.data.receivedData.profilesToReceiveInvitations !== null &&
          x.data.receivedData.profilesToReceiveInvitations.length > 0
        ) {
          // const list = [];
          // let historyList = [...history];
          // x.data.receivedData.profilesToReceiveInvitations.map(
          //   async (invitation) => {
          //     historyList.push(invitation);
          //     // const invitationsSent = {
          //     //   contactName: invitation.contactName,
          //     //   companyName: invitation.companyName,
          //     //   email: invitation.email,
          //     //   emailSent: invitation.emailSent === true ? "Yes" : "No",
          //     //   timeStamp: `${new Date(invitation.timeStamp).getDate()}/${
          //     //     monthNames[new Date(invitation.timeStamp).getMonth()]
          //     //   }/${new Date(invitation.timeStamp).getFullYear()}`,
          //     // };
          //     // await list.push(invitationsSent);
          //   }
          // );
          // setHistory(historyList);
          // historyList = [];

          await onUpload();
          setInvitationsSent(x.data.receivedData.profilesToReceiveInvitations);
          if (
            x !== null &&
            x.data !== null &&
            x.data.msg !== null &&
            x.data.msg.translatedMsg !== null
          ) {
            x.data.msg.translatedMsg.map((i) => {
              if (i.languageName === currentLan) {
                setSuccessMessage(i.msg);
              }
            });
          }
          setSuccessAlert(!successAlert);
        }

        setSubmitting(false);
        setErrors({});
        //setFieldValue("file", null);

        document.getElementById("file").value = null;
      })
      .catch((error) => {
        document.getElementById("file").value = null;
        setSubmitting(false);
        if (
          error !== null &&
          error.response !== null &&
          error.response.data !== null &&
          error.response.data.message !== undefined
        ) {
          setError(error.response.data.message);
          if (
            error.response.data.error !== null &&
            error.response.data.error.length > 0
          ) {
            for (var i = 0; i < error.response.data.error.length; i++) {
              if (error.response.data.error[i].languageName == currentLan) {
                setError(error.response.data.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        //   setErrorAlert(!errorAlert);
      });
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div className="modal-body col-md-12">
          <p>{errorMessage}</p>
        </div>

        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            style={
              rtl ? { margin: "auto", textAlign: "right" } : { margin: "auto" }
            }
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={successAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("done")}</h5>
        </div>
        <div className="modal-body">
          <p>{successMessage}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            style={
              rtl ? { margin: "auto", textAlign: "right" } : { margin: "auto" }
            }
            onClick={(e) => {
              setSuccessAlert(!successAlert);
            }}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Card style={rtl ? { textAlign: "right" } : {}}>
        <CardBody>
          <CardTitle tag="h4" style={{ marginTop: "-2px" }}>
            {t("partner.inviteProviders")}
          </CardTitle>
          <CardText>{t("inviteProviders.li.one")}</CardText>
          <Button
            color="primary"
            style={{ marginTop: "-2px" }}
            onClick={(e) => {
              setDownloading(true);
              DownloadExcel();
            }}
          >
            {downloading && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            {t("download.excel")}
          </Button>
          <CardText style={{ marginTop: "8px" }}>
            {t("uploadExcel.tip")}
          </CardText>
          <Formik
            initialValues={{ file: null }}
            validationSchema={Yup.object().shape({
              file: Yup.mixed()
                .required(t("excel.required"))
                .test(
                  "fileFormat",
                  t("excel.format"),
                  (value) => value && SUPPORTED_FORMATS.includes(value.type)
                ),
            })}
            onSubmit={onSubmit}
          >
            {({ errors, touched, isSubmitting, setFieldValue, setErrors }) => {
              // useEffect(() => {
              //   let mounted = true;
              //   return () => (mounted = false);
              // }, []);

              return (
                <div>
                  <Form>
                    <Row>
                      <FormGroup className="col-md-5">
                        <input
                          id="file"
                          name="file"
                          type="file"
                          onChange={(event) => {
                            setFieldValue("file", event.currentTarget.files[0]);
                          }}
                          className={
                            "form-control " +
                            (errors.file && touched.file ? " is-invalid" : "")
                          }
                        />
                        {errors.file && touched.file ? (
                          <ErrorMessage
                            name="file"
                            component="div"
                            className="invalid-feedback"
                          />
                        ) : (
                          <span className="isValid" component="div">
                            <br></br>
                          </span>
                        )}
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="col-md-2">
                        <Button
                          // className="col-md-1"
                          style={{
                            marginTop: "-5px",
                          }}
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          {t("uploadExcel.button")}
                        </Button>
                      </FormGroup>
                    </Row>
                  </Form>
                </div>
              );
            }}
          </Formik>

          {errors.length > 0 && <ErrorsList props={errors} />}
          {invitationsSent.length > 0 && (
            <InvitationsSent props={invitationsSent} />
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default UploadExcel;
