import React, { useState } from "react";
import { useParams } from "react-router";
// reactstrap components
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import { Paper, ThemeProvider } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { theme } from "../../../theme.js";
import Modal from "reactstrap/lib/Modal";
import ChatIcon from "@material-ui/icons/Chat";
import { adminService } from "_services/admin.service";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import { IconButton } from "@material-ui/core";
import { logsService } from "_services/logs.service.js";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  FileCopyIcon: forwardRef((props, ref) => (
    <FileCopyIcon {...props} ref={ref} />
  )),
  AccountBalanceIcon: forwardRef((props, ref) => (
    <AccountBalanceIcon {...props} ref={ref} />
  )),
};

function LogsList({ history, match, location }) {
  const { path, url } = match;
  const { id } = useParams();
  const [logs, setLogs] = useState(null);
  const [itemCount, setItemCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  var columns = [
    // {
    //   title: t("logs.logID"),
    //   field: "logID",
    //   width: 130,
    // },
    {
      title: t("logs.type"),
      field: "type",
      width: 130,
    },
    {
      title: t("logs.status"),
      field: "status",
      width: 130,
    },
    {
      title: t("logs.logDate"),
      field: "logDate",
      width: 130,
    },
    {
      title: t("logs.logEndDate"),
      field: "logEndDate",
      width: 130,
    },
  ];

  let [currentPage, setCurrentPage] = useState(0);
  const logsPerPage = 5;
  const begin = React.useRef(0);
  const end = React.useRef(logsPerPage);

  const count = Math.ceil(itemCount / logsPerPage);

  function next() {
    if (end.current > itemCount) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
    begin.current = (currentPage + 1) * logsPerPage;
    end.current = begin.current + logsPerPage;
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * logsPerPage;
    end.current = begin.current + logsPerPage;
  }

  const fetchData = async () => {
    let logsToSkip = begin.current.toString();

    setLoading(true);
    await logsService.GetLogsCount().then((x) => {
      setItemCount(x);
    });
    await logsService
      .GetAllLogsAsync(logsToSkip, logsPerPage.toString())
      .then((res) => {
        const tmpList = res.map((x) => ({
          id: x.id,
          status: x.status,
          logDate: moment(x.logDate).format("YYYY-MM-DD, h:mm:ss a"),
          logEndDate: moment(x.logEndDate).format("YYYY-MM-DD, h:mm:ss a"),
          type: x.type,
          logDetails: x.logDetails,
        }));
        setLogs(tmpList);
      });

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    console.log(logs);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [end.current, begin.current]);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "80%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <Container>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div className="modal-body col-md-12">
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <ThemeProvider theme={theme}>
          <MaterialTable
            style={{ marginTop: "50px" }}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            title={t("logs.logsHistory")}
            actions={[
              {
                icon: () => (
                  <VisibilityIcon
                    className="actionIcon"
                    style={{ color: "#0E6EB8" }}
                  />
                ),
                tooltip: t("logs.logsDetails"),
                onClick: (event, rowData) => {
                  history.push(`${url}/details`, {
                    state: rowData,
                  });
                },
              },
            ]}
            columns={columns}
            data={logs}
            icons={tableIcons}
            options={{
              paging: false,
              actionsColumnIndex: -1,
            }}
          />
        </ThemeProvider>
        <div className="d-flex justify-content-end align-items-center">
          <IconButton
            aria-label="next"
            disabled={currentPage == 0 ? true : false}
          >
            <ArrowBackIosSharpIcon
              onClick={() => prev()}
              style={{
                color: "#d9dcde",
              }}
            />
          </IconButton>
          <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
            {begin.current} -{" "}
            {end.current > itemCount ? itemCount : end.current} of {itemCount}
          </p>
          <IconButton
            aria-label="next"
            disabled={end.current > itemCount ? true : false}
          >
            <ArrowForwardIosSharpIcon
              onClick={() => next()}
              style={{
                color: "#d9dcde",
              }}
            />
          </IconButton>
        </div>
        <hr className="mt-0"></hr>
      </Container>
    </>
  );
}

export default LogsList;
