import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import UserLogsList from "./UserLogsList";

function UserLogs({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}/:userId`} component={UserLogsList} exact />
      </Switch>
    </div>
  );
}

export { UserLogs };
