import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import FeaturedOpportunitiesList from "./FeaturedOpportunitiesList";
import OpportunityDetails from "./Details/Index";

function FeaturedOpportunities({ match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute
          path={`${path}`}
          component={FeaturedOpportunitiesList}
          exact
        />
        <PrivateRoute
          path={`${path}/opportunity-details`}
          component={OpportunityDetails}
        />
      </Switch>
    </div>
  );
}

export { FeaturedOpportunities };
