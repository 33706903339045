import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/projectSeen`;

export const projectSeenService = {
  AddProjectSeenAsync,
  ToggleProjectBookmarkAsync,
  GetAllProjectsSeenAsync,
};

function AddProjectSeenAsync(params) {
  return fetchWrapper.post(`${baseUrl}/AddProjectSeen`, params);
}

function ToggleProjectBookmarkAsync(params) {
  return fetchWrapper.post(`${baseUrl}/ToggleProjectBookmark`, params);
}

function GetAllProjectsSeenAsync(id, bookmark, language) {
  return fetchWrapper.get(
    `${baseUrl}/GetAllProjectsSeen/${id}/${bookmark}/${language}`
  );
}
