import React from "react";
import { Button, Container } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import { useTranslation } from "react-i18next";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";

function SystemSetupHome({ history, match, location }) {
  const { path } = match;
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <div className="section">
        <Container>
          <Row style={{ marginTop: "30px" }}>
            <Col md="2">
              <Card
                className="card-transition"
                onClick={() => history.push(`${path}/policies`)}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-sliders"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("systemVariables")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="2">
              <Card
                className="card-transition"
                onClick={() => history.push(`${path}/policies`)}
              >
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    <i
                      class="fa fa-file-text"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </CardTitle>
                  <CardTitle
                    tag="h4"
                    style={{ marginTop: "0px" }}
                    className="text-center"
                  >
                    {t("systemPolicies")}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SystemSetupHome;
