import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import EditEmailLog from "./EditEmailLog";
import EmailLogsList from "./EmailLogsList";

function EmailLogs({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}/edit/:id`} component={EditEmailLog} />
        <PrivateRoute path={`${path}`} component={EmailLogsList} />
      </Switch>
    </div>
  );
}

export { EmailLogs };
