import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import ManagerUsers from "./manageUsers";
import SearchDetails from "../../ServiceSeeker/Search/SearchDetails";
import { UserOpportunities } from "./UserOpportunities/Index";
import { UserLogs } from "./UserLogs/Index";
import EditProfile from "../../Profile/EditProfile";

function Users({ match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={ManagerUsers} exact />
        <PrivateRoute
          path={`${path}/viewProfile/:userId`}
          component={SearchDetails}
        />
        <PrivateRoute
          path={`${path}/editProfile/:userId`}
          component={EditProfile}
        />
        <PrivateRoute
          path={`${path}/viewOpportunities/:userId`}
          component={UserOpportunities}
        />
        <PrivateRoute path={`${path}/user-logs`} component={UserLogs} />
      </Switch>
    </div>
  );
}

export { Users };
