import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Modal,
} from "reactstrap";
import { profileService } from "_services";
import { LanguageContext } from "_context";
import queryString from "query-string";

function DispatchSubscription({ location }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const user = profileService.userValue;
  const parsed = queryString.parse(location.search);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successNewsletterAlert, setSuccessNewsletterAlert] = useState(false);
  const [successNotificationsAlert, setSuccessNotificationsAlert] =
    useState(false);
  const [
    successBrowserNotificationsAlert,
    setSuccessBrowserNotificationsAlert,
  ] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [toggleNewsletterSubscription, setToggleNewsletterSubscription] =
    useState(user.profile.newsletterSubscription);
  const [toggleNotificationsSubscription, setToggleNotificationsSubscription] =
    useState(user.profile.notificationsSubscription);
  const [
    toggleBrowserNotificationsSubscription,
    setBrowserToggleNotificationsSubscription,
  ] = useState(user.profile.browserNotificationsSubscription);
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  console.log("user", user);
  useEffect(() => {
    if (parsed.fromemail === "true" && parsed.type === "Dispatch") {
      toggleNewsletter(false);
    } else if (parsed.fromemail === "true" && parsed.type === "Opportunity") {
      toggleNotifications(false);
    }
  }, []);

  async function toggleNewsletter(flag) {
    const model = {
      UserID: user.profile._id,
      Flag: flag,
    };
    await profileService
      .ToggleDispatchSubscription(model)
      .then(() => {
        setSuccessNewsletterAlert(!successNewsletterAlert);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function toggleNotifications(flag) {
    const model = {
      UserID: user.profile._id,
      Flag: flag,
    };
    await profileService
      .ToggleNotificationsSubscription(model)
      .then(() => {
        setSuccessNotificationsAlert(!successNotificationsAlert);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function toggleBrowserNotifications(flag) {
    const model = {
      UserID: user.profile._id,
      Flag: flag,
    };
    await profileService
      .ToggleBrowserNotificationsSubscription(model)
      .then(() => {
        setSuccessBrowserNotificationsAlert(!successBrowserNotificationsAlert);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  return (
    <>
      <Modal
        isOpen={successNewsletterAlert}
        style={rtl ? { textAlign: "right" } : {}}
      >
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("subscription")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>
            {toggleNewsletterSubscription == true
              ? t("dispatch.success.subscription")
              : t("dispatch.success.unsubscription")}
          </p>
        </div>
        <div className="modal-footer text-center">
          <center className="w-100">
            <Button
              color="primary"
              type="button"
              onClick={(e) =>
                setSuccessNewsletterAlert(!successNewsletterAlert)
              }
            >
              {t("close.button")}
            </Button>
          </center>
        </div>
      </Modal>
      <Modal
        isOpen={successNotificationsAlert}
        style={rtl ? { textAlign: "right" } : {}}
      >
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("subscription")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>
            {toggleNotificationsSubscription == true
              ? t("alerts.success.subscription")
              : t("alerts.success.unsubscription")}
          </p>
        </div>
        <div className="modal-footer text-center">
          <center className="w-100">
            <Button
              color="primary"
              type="button"
              onClick={(e) =>
                setSuccessNotificationsAlert(!successNotificationsAlert)
              }
            >
              {t("close.button")}
            </Button>
          </center>
        </div>
      </Modal>
      <Modal
        isOpen={successBrowserNotificationsAlert}
        style={rtl ? { textAlign: "right" } : {}}
      >
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("subscription")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>
            {toggleBrowserNotificationsSubscription == true
              ? t("notify.success.subscription")
              : t("notify.success.unsubscription")}
          </p>
        </div>
        <div className="modal-footer text-center">
          <center className="w-100">
            <Button
              color="primary"
              type="button"
              onClick={(e) =>
                setSuccessBrowserNotificationsAlert(
                  !successBrowserNotificationsAlert
                )
              }
            >
              {t("close.button")}
            </Button>
          </center>
        </div>
      </Modal>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>{errorMessage}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Card className={rtl ? "text-right" : ""}>
        <CardHeader className="text-center">
          <CardTitle className="" tag="h4">
            {t("subscription")}
          </CardTitle>
          <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
        </CardHeader>
        <CardBody>
          <div>
            <Switch
              {...label}
              checked={toggleNewsletterSubscription}
              onChange={(e) => {
                setToggleNewsletterSubscription(e.target.checked);
                toggleNewsletter(e.target.checked);
              }}
            />
            <label>{t("dispatch.subscription")}</label>
          </div>
          <div>
            <Switch
              {...label}
              checked={toggleNotificationsSubscription}
              onChange={(e) => {
                setToggleNotificationsSubscription(e.target.checked);
                toggleNotifications(e.target.checked);
              }}
            />
            <label>{t("alerts.subscription")}</label>
          </div>
          <div>
            <Switch
              {...label}
              checked={toggleBrowserNotificationsSubscription}
              onChange={(e) => {
                setBrowserToggleNotificationsSubscription(e.target.checked);
                toggleBrowserNotifications(e.target.checked);
              }}
            />
            <label>{t("notify.subscription")}</label>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default DispatchSubscription;
