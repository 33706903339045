import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/Payments`;

export const paymentService = {
  CreateSession,
  CheckoutSessionAndSavePaymentData,
  CancelSubscription,
  RetrieveUserPaymentMethods,
  ChangeCustomerPaymentMethod,
  RetrieveCurrentUserPaymentMethod,
  GetAppGateways,
  GetGatewayKeysAsync,
  UpdateGatewayKeysAsync,
};

function CreateSession(params) {
  return fetchWrapper.post(`${baseUrl}/CreateSession`, params);
}

function CheckoutSessionAndSavePaymentData(params) {
  return fetchWrapper.post(
    `${baseUrl}/CheckoutSessionAndSavePaymentData`,
    params
  );
}

function CancelSubscription(params) {
  return fetchWrapper.post(`${baseUrl}/CancelSubscription`, params);
}

function RetrieveUserPaymentMethods(params) {
  return fetchWrapper.post(`${baseUrl}/RetrieveUserPaymentMethods`, params);
}

function ChangeCustomerPaymentMethod(params) {
  return fetchWrapper.post(`${baseUrl}/ChangeCustomerPaymentMethod`, params);
}

function RetrieveCurrentUserPaymentMethod(params) {
  return fetchWrapper.post(
    `${baseUrl}/RetrieveCurrentUserPaymentMethod`,
    params
  );
}

function GetAppGateways(params) {
  return fetchWrapper.post(`${baseUrl}/GetAppGateways`, params);
}
function GetGatewayKeysAsync(params) {
  return fetchWrapper.get(`${baseUrl}/GetGatewayKeys/${params}`);
}
function UpdateGatewayKeysAsync(params) {
  return fetchWrapper.post(`${baseUrl}/UpdateGatewayKeys`, params);
}
