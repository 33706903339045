import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";
import { post } from "axios";
import { profileService } from "./profile.service";

const baseUrl = `${config.apiUrl}/project`;

export const projectService = {
  AddProjectAsync,
  GetAllSeekerProjectsAsync,
  DeleteProjectAsync,
  TogglePublishProjectAsync,
  GetProjectByIdAsync,
  UpdateProjectAsync,
  RemoveDocumentByNameAsync,
  GetProjectDefaultNDAAsync,
  ToggleFeaturedProjectAsync,
  GetAllFeaturedProjectsAsync,
  GetAllFeaturedAsync,
  GetTheProjectStatus,
  GetAllFeaturedProjectsCountAsync,
  ToggleAwardedProjectAsync,
  GetUserProjectsAnalytics,
  GetUserProjectsAnalyticsCount,
};

function AddProjectAsync(params) {
  const user = profileService.userValue;
  const options = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user !== null ? user.jwtToken : ""}`,
    },
  };
  return post(`${baseUrl}/AddProject`, params, options);
}

function GetAllSeekerProjectsAsync(id, isAdmin, language) {
  return fetchWrapper.get(
    `${baseUrl}/GetAllSeekerProjects/${id}/${isAdmin}/${language}`
  );
}

function DeleteProjectAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/DeleteProject/${id}`);
}

function TogglePublishProjectAsync(id, publish) {
  return fetchWrapper.post(`${baseUrl}/TogglePublishProject/${id}/${publish}`);
}

function GetProjectByIdAsync(id, isAdmin, userId, language) {
  if (userId === null || userId === undefined) {
    return fetchWrapper.get(
      `${baseUrl}/GetProjectById/${id}/${isAdmin}/${language}`
    );
  } else {
    return fetchWrapper.get(
      `${baseUrl}/GetProjectById/${id}/${isAdmin}/${language}/${userId}`
    );
  }
}

function UpdateProjectAsync(id, params) {
  const user = profileService.userValue;
  const options = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${user !== null ? user.jwtToken : ""}`,
    },
  };
  return post(`${baseUrl}/UpdateProject/${id}`, params, options);
}

function RemoveDocumentByNameAsync(id, model) {
  return fetchWrapper.post(`${baseUrl}/RemoveDocumentByName/${id}`, model);
}

function GetProjectDefaultNDAAsync(language) {
  return fetchWrapper.get(`${baseUrl}/GetProjectDefaultNDA/${language}`);
}

function ToggleFeaturedProjectAsync(id, flag) {
  return fetchWrapper.post(`${baseUrl}/ToggleFeaturedProject/${id}/${flag}`);
}

function GetAllFeaturedProjectsAsync(isAdmin, id, params, language) {
  if (id === null || id === undefined) {
    return fetchWrapper.post(
      `${baseUrl}/GetAllFeaturedProjects/${isAdmin}/${language}`,

      params
    );
  } else {
    return fetchWrapper.post(
      `${baseUrl}/GetAllFeaturedProjects/${isAdmin}/${language}/${id}`,
      params
    );
  }
}

function GetAllFeaturedProjectsCountAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllFeaturedProjectsCount`);
}

function GetAllFeaturedAsync(isAdmin, id, language) {
  if (id === null || id === undefined) {
    return fetchWrapper.get(`${baseUrl}/GetAllFeatured/${isAdmin}/${language}`);
  } else {
    return fetchWrapper.get(
      `${baseUrl}/GetAllFeatured/${isAdmin}/${language}/${id}`
    );
  }
}

function GetTheProjectStatus(projectId) {
  return fetchWrapper.get(`${baseUrl}/GetTheProjectStatus/${projectId}`);
}

function ToggleAwardedProjectAsync(params) {
  return fetchWrapper.post(`${baseUrl}/ToggleAwardedProject`, params);
}

function GetUserProjectsAnalytics(userId) {
  return fetchWrapper.post(`${baseUrl}/GetUserProjectsAnalytics/${userId}`);
}

function GetUserProjectsAnalyticsCount(userId) {
  return fetchWrapper.get(`${baseUrl}/GetUserProjectsAnalyticsCount/${userId}`);
}
