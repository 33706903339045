import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "_context";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import FormGroup from "reactstrap/lib/FormGroup";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import { languageService } from "_services";
import { Formik, Field, Form } from "formik";
import { surveyService } from "_services/survey.service.js";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { SurveyAnswerTypes } from "_helpers/SurveyAnswerTypes";

function SurveyPreview({ location, match }) {
  const { path } = match;
  const { id } = match.params;
  const [survey, setSurvey] = useState(null);
  const [surveyDetails, setSurveyDetails] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [languages, setLanguages] = useState([]);
  const [languagesName, setLanguagesName] = useState([]);
  const [tabValue, setTabValue] = useState("en");
  const [tabIndex, setTabIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  // const survey = location.state != null && location.state.state.surveys;

  const fetchData = async () => {
    setLoading(true);

    await languageService.GetAllLanguagesAsync().then((x) => {
      setLanguages(x);
    });

    await surveyService.GetSurveyByLanguageName(id, tabValue).then((x) => {
      setSurvey(x);
      if (x != null && x.surveys != null) {
        setSurveyDetails(x.surveys);
        setQuestions(x.surveys.questions);
      } else {
        setSurveyDetails(null);
        setQuestions([]);
      }
    });
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [tabValue]);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
    setTabIndex(tabIndex + 1);
  };

  return (
    <Row
      style={{
        marginTop: "100px",
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Row className="w-100 justify-content-center pb-4">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="primary tabs example"
        >
          {languages.length > 0 &&
            languages.map((language) => (
              <Tab
                key={language.languageID}
                value={language.languageName}
                label={language.languageDescription}
                // disabled={tabValue == language.languageID ? false : true}
              />
            ))}
        </Tabs>
      </Row>
      {survey != null && surveyDetails != null && (
        <>
          <Col className="col-12 d-flex justify-content-center">
            <Card
              className={rtl ? "text-right" : ""}
              style={{
                width: "50%",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "none",
                border: "1px solid #D9D9D9",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "6px",
                  background: "#6CAEF2 0% 0% no-repeat padding-box",
                  borderRadius: "5px 5px 0px 0px",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                }}
              ></div>
              <CardBody className="pl-0 pr-0 pb-0">
                <Row>
                  <Col className="p-0">
                    <div className="form-group">
                      <p
                        name="surveyTitle"
                        style={{
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          fontSize: "24px",
                        }}
                        className={"form-control"}
                      >
                        {" "}
                        {surveyDetails.surveyTitle}{" "}
                      </p>
                    </div>
                    <div className="">
                      <p
                        name="surveyDescription"
                        style={
                          rtl
                            ? {
                                color: "#4f4f4f",
                                fontSize: "14px",
                                paddingRight: "18px",
                              }
                            : {
                                color: "#4f4f4f",
                                fontSize: "14px",
                                paddingLeft: "18px",
                              }
                        }
                        className={""}
                      >
                        {" "}
                        {surveyDetails.surveyDescription}{" "}
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Formik
            initialValues={initialValues}
            // onSubmit={onSubmit}
          >
            {({ isSubmitting }) => {
              return (
                <>
                  {questions.length > 0 &&
                    questions.map((question, index) => (
                      <Row className="w-100">
                        <Col
                          sx="12"
                          style={{
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Card
                            className={rtl ? "text-right" : ""}
                            style={{
                              width: "50%",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              boxShadow: "none",
                              border: "1px solid #D9D9D9",
                              borderRadius: "10px",
                            }}
                          >
                            <Form autoComplete="off">
                              <CardBody>
                                <Row>
                                  <Col md="12">
                                    <div className="form-group">
                                      <div
                                        style={{
                                          margin: "0 auto",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <p>{question.questionText}</p>
                                      </div>
                                    </div>
                                    {question.questionType ==
                                    SurveyAnswerTypes.YesNo ? (
                                      <>
                                        <div className="form-row">
                                          <FormGroup
                                            style={{
                                              margin: "0 auto",
                                              width: "100%",
                                              padding: "10px",
                                              marginTop: "-2%",
                                            }}
                                          >
                                            <div>
                                              <label>
                                                <Field
                                                  name={`yesNo${index}`}
                                                  type="radio"
                                                  value="yes"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />{" "}
                                                {t("radio.yes")}
                                              </label>
                                            </div>
                                            <div>
                                              <label>
                                                <Field
                                                  name={`yesNo${index}`}
                                                  type="radio"
                                                  value="no"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />{" "}
                                                {t("radio.no")}
                                              </label>
                                            </div>
                                          </FormGroup>
                                        </div>
                                      </>
                                    ) : question.questionType ==
                                      SurveyAnswerTypes.Text ? (
                                      <>
                                        <div className="form-group">
                                          <FormGroup
                                            style={{
                                              margin: "0 auto",
                                              width: "100%",
                                              padding: "10px",
                                              marginTop: "-2%",
                                            }}
                                          >
                                            <Field
                                              autoFocus={true}
                                              name="ouraim_description"
                                              placeholder={t(
                                                "surveyYourAnswer"
                                              )}
                                              className={"form-control"}
                                              style={{
                                                height: "36px",
                                                borderRadius: 0,
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                              }}
                                            />
                                          </FormGroup>
                                        </div>
                                      </>
                                    ) : question.questionType ==
                                      SurveyAnswerTypes.Dropdown ? (
                                      <>
                                        <div className="form-group">
                                          <FormGroup
                                            style={{
                                              margin: "0 auto",
                                              width: "100%",
                                              padding: "10px",
                                              marginTop: "-2%",
                                            }}
                                          >
                                            <Field
                                              autoFocus={true}
                                              name="dropdown"
                                              as="select"
                                              className={"form-control"}
                                            >
                                              <option
                                                selected
                                                defaultValue
                                                disabled
                                              >
                                                Select Provider
                                              </option>
                                            </Field>
                                          </FormGroup>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="form-group">
                                          <FormGroup
                                            style={{
                                              margin: "0 auto",
                                              width: "100%",
                                              padding: "10px",
                                              marginTop: "-2%",
                                            }}
                                          >
                                            {question.answers.length > 0 &&
                                              question.answers.map(
                                                (answer, index) => (
                                                  <div>
                                                    <label>
                                                      <Field
                                                        name={`answers${index}`}
                                                        type="radio"
                                                        value={question.answers}
                                                        style={{
                                                          marginRight: "10px",
                                                        }}
                                                      />
                                                      {answer.answer}
                                                    </label>
                                                  </div>
                                                )
                                              )}
                                          </FormGroup>
                                        </div>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </CardBody>
                            </Form>
                          </Card>
                        </Col>
                      </Row>
                    ))}
                  <div className="form-row">
                    <FormGroup
                      className="col-md-8"
                      style={{
                        margin: "0 auto",
                        width: "100%",
                        padding: "10px",
                        marginTop: "-2%",
                      }}
                    >
                      <center>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          {t("surveySubmit")}
                        </Button>
                      </center>
                    </FormGroup>
                  </div>
                </>
              );
            }}
          </Formik>
        </>
      )}
      {survey == "" && (
        <>
          <Col className="col-12 d-flex justify-content-center">
            <Card
              className={rtl ? "text-right" : ""}
              style={{
                width: "50%",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "none",
                border: "1px solid #D9D9D9",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "6px",
                  background: "#6CAEF2 0% 0% no-repeat padding-box",
                  borderRadius: "5px 5px 0px 0px",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                }}
              ></div>
              <CardBody className="pl-0 pr-0 pb-0">
                <div className="">
                  <p
                    name="surveyDescription"
                    style={
                      rtl
                        ? {
                            color: "#4f4f4f",
                            fontSize: "14px",
                            paddingRight: "18px",
                          }
                        : {
                            color: "#4f4f4f",
                            fontSize: "14px",
                            paddingLeft: "18px",
                          }
                    }
                    className={""}
                  >
                    {" "}
                    {t("survey.no.found")}{" "}
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
}

export default SurveyPreview;
