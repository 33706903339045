import React, { useState, useContext } from "react";
import { Button, CardBody, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { Paper, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../theme.js";
import { adminService, languageService } from "_services";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { LanguageContext } from "_context";
import { AccountTypes } from "_helpers";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import FormGroup from "reactstrap/lib/FormGroup";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import { IconButton } from "@material-ui/core";
import moment from "moment";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  SubdirectoryArrowLeftIcon: forwardRef((props, ref) => (
    <SubdirectoryArrowLeftIcon {...props} ref={ref} />
  )),
  VisibilityIcon: forwardRef((props, ref) => (
    <VisibilityIcon {...props} ref={ref} />
  )),
  AccountBalanceIcon: forwardRef((props, ref) => (
    <AccountBalanceIcon {...props} ref={ref} />
  )),
};

const Responses = ({ match, location, history }) => {
  const { path } = match;
  const { id } = match.params;
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [surveys, setSurveys] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [surveyAlert, setSurveyAlert] = useState(false);
  const [surveyDetails, setSurveyDetails] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [languages, setLanguages] = useState([]);
  const [languagesName, setLanguagesName] = useState([]);
  const [tabValue, setTabValue] = useState("en");
  const [tabIndex, setTabIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const [rowDataID, setRowDataID] = useState(null);
  const [users, setUsers] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [pageSize, setPageSize] = useState(20);
  const [itemCount, setItemCount] = useState(0);

  const fetchData = async () => {
    setLoading(true);

    await languageService.GetAllLanguagesAsync().then((x) => {
      setLanguages(x);
    });

    const model = {
      emailLogsToSkip: begin.current,
      startingEmailLogs: pageSize,
    };
    await adminService.GetAllUsersWithResponses(id, model).then((res) => {
      console.log(res);
      setUsers(res);
      setLoading(false);
    });
  };

  var columns = [
    {
      title: t("admin.users.userName"),
      field: "asProfile[0].userName",
      width: "20%",
    },
    {
      title: t("admin.users.companyName"),
      field: "asProfile[0].companyName",
      width: "10%",
    },
  ];

  const TableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",

          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            width: "100%",
            columnGap: "10rem",
          }}
        >
          <div
            style={{
              color: "#0e6eb8",
              fontSize: "1.25rem",
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
              fontWeight: 500,
              letterSpacing: "0.0075em",
              textTransform: "none",
            }}
          >
            {t("userList")}
          </div>
        </div>
      </div>
    );
  };

  let [currentPage, setCurrentPage] = useState(0);
  const userPerPage = pageSize;
  const begin = React.useRef(0);
  const end = React.useRef(userPerPage);

  const count = Math.ceil(itemCount / userPerPage);

  function next() {
    if (end.current > itemCount) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
    begin.current = (currentPage + 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  const handleChange = (value) => {
    setPageSize(parseInt(value));
    end.current = begin.current + parseInt(value);
  };

  const fetchCount = async () => {
    await adminService.GetAllUsersWithResponsesCount(id).then((res) => {
      setItemCount(res);
    });
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [end.current, begin.current, pageSize]);

  React.useEffect(() => {
    fetchCount();
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <div className="section">
        <Container>
          <ThemeProvider theme={theme}>
            <MaterialTable
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              title={<TableHeader style={{ width: "100%" }} />}
              columns={columns}
              data={users}
              icons={tableIcons}
              options={{
                // pageSize: 10,
                paging: false,
                actionsColumnIndex: -1,
                cellStyle: {
                  textAlign: rtl ? "right" : "left",
                },
                headerStyle: {
                  textAlign: rtl ? "right" : "left",
                },
              }}
              localization={{
                header: {
                  actions: t("actions"),
                },
                toolbar: {
                  searchPlaceholder: t("search"),
                },
              }}
              actions={[
                (rowData) => ({
                  icon: () => (
                    <VisibilityIcon
                      className="actionIcon"
                      style={{ color: "#0E6EB8" }}
                    />
                  ),
                  disabled: rowData.status != "Finalized" ? true : false,
                  tooltip: t("view.response"),
                  onClick: (event, rowData) => {
                    history.push(
                      `${match.url}/${rowData.asProfile[0].userId}`,
                      {
                        state: { user: rowData, survey: id },
                      }
                    );
                  },
                }),
              ]}
            />
          </ThemeProvider>
          <div className="d-flex justify-content-end align-items-center">
            <select
              value={pageSize}
              onChange={(e) => handleChange(e.target.value)}
              style={{
                height: "36px",
                borderRadius: 0,
                width: "auto",
                fontSize: "12px",
              }}
              className={"form-control"}
            >
              <option
                key={5}
                value={5}
                style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
              >
                5
              </option>
              <option
                key={10}
                value={10}
                style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
              >
                10
              </option>
              <option
                key={15}
                value={15}
                style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
              >
                15
              </option>
              <option
                key={20}
                value={20}
                style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
              >
                20
              </option>
              <option
                key={50}
                value={50}
                style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
              >
                50
              </option>
              <option
                key={100}
                value={100}
                style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
              >
                100
              </option>
            </select>
            <IconButton
              aria-label="next"
              disabled={currentPage == 0 ? true : false}
            >
              {rtl ? (
                <ArrowForwardIosSharpIcon
                  onClick={() => prev()}
                  style={{
                    color: "#d9dcde",
                  }}
                />
              ) : (
                <ArrowBackIosSharpIcon
                  onClick={() => prev()}
                  style={{
                    color: "#d9dcde",
                  }}
                />
              )}
            </IconButton>
            <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
              {begin.current} -{" "}
              {end.current > itemCount ? itemCount : end.current}{" "}
              {rtl ? "من" : "of"} {itemCount}
            </p>
            <IconButton
              aria-label="next"
              disabled={end.current >= itemCount ? true : false}
            >
              {rtl ? (
                <ArrowBackIosSharpIcon
                  onClick={() => next()}
                  style={{
                    color: "#d9dcde",
                  }}
                />
              ) : (
                <ArrowForwardIosSharpIcon
                  onClick={() => next()}
                  style={{
                    color: "#d9dcde",
                  }}
                />
              )}
            </IconButton>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Responses;
