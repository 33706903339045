import { analyticsService } from "_services";
import PulseLoader from "react-spinners/PulseLoader";
import React, { useState, useContext } from "react";
import { ResponsiveSunburst } from "@nivo/sunburst";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
function ProjectsByCategorySunburst() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    await analyticsService
      .GetProjectsByCategorySunburstInputAsync()
      .then((x) => {
        setData(x);
      });

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  //   var data = {
  //     name: "nivo",
  //     color: "hsl(51, 70%, 50%)",
  //     children: [
  //       {
  //         name: "viz",
  //         color: "hsl(166, 70%, 50%)",
  //         children: [
  //           {
  //             name: "stack",
  //             color: "hsl(61, 70%, 50%)",
  //             children: [
  //               {
  //                 name: "cchart",
  //                 color: "hsl(228, 70%, 50%)",
  //                 value: 130159,
  //               },
  //               {
  //                 name: "xAxis",
  //                 color: "hsl(69, 70%, 50%)",
  //                 value: 72633,
  //               },
  //               {
  //                 name: "yAxis",
  //                 color: "hsl(69, 70%, 50%)",
  //                 value: 136302,
  //               },
  //               {
  //                 name: "layers",
  //                 color: "hsl(342, 70%, 50%)",
  //                 value: 195445,
  //               },
  //             ],
  //           },
  //           {
  //             name: "ppie",
  //             color: "hsl(338, 70%, 50%)",
  //             children: [
  //               {
  //                 name: "chart",
  //                 color: "hsl(153, 70%, 50%)",
  //                 children: [
  //                   {
  //                     name: "pie",
  //                     color: "hsl(297, 70%, 50%)",
  //                     children: [
  //                       {
  //                         name: "outline",
  //                         color: "hsl(80, 70%, 50%)",
  //                         value: 92379,
  //                       },
  //                       {
  //                         name: "slices",
  //                         color: "hsl(193, 70%, 50%)",
  //                         value: 111060,
  //                       },
  //                       {
  //                         name: "bbox",
  //                         color: "hsl(298, 70%, 50%)",
  //                         value: 167722,
  //                       },
  //                     ],
  //                   },
  //                   {
  //                     name: "donut",
  //                     color: "hsl(327, 70%, 50%)",
  //                     value: 182105,
  //                   },
  //                   {
  //                     name: "gauge",
  //                     color: "hsl(13, 70%, 50%)",
  //                     value: 196131,
  //                   },
  //                 ],
  //               },
  //               {
  //                 name: "legends",
  //                 color: "hsl(276, 70%, 50%)",
  //                 value: 121698,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         name: "colors",
  //         color: "hsl(201, 70%, 50%)",
  //         children: [
  //           {
  //             name: "rgb",
  //             color: "hsl(191, 70%, 50%)",
  //             value: 140092,
  //           },
  //           {
  //             name: "hsl",
  //             color: "hsl(196, 70%, 50%)",
  //             value: 145153,
  //           },
  //         ],
  //       },
  //       {
  //         name: "utils",
  //         color: "hsl(196, 70%, 50%)",
  //         children: [
  //           {
  //             name: "randomize",
  //             color: "hsl(147, 70%, 50%)",
  //             value: 129987,
  //           },
  //           {
  //             name: "resetClock",
  //             color: "hsl(188, 70%, 50%)",
  //             value: 10816,
  //           },
  //           {
  //             name: "noop",
  //             color: "hsl(155, 70%, 50%)",
  //             value: 68657,
  //           },
  //           {
  //             name: "tick",
  //             color: "hsl(338, 70%, 50%)",
  //             value: 47051,
  //           },
  //           {
  //             name: "forceGC",
  //             color: "hsl(285, 70%, 50%)",
  //             value: 146516,
  //           },
  //           {
  //             name: "stackTrace",
  //             color: "hsl(104, 70%, 50%)",
  //             value: 47786,
  //           },
  //           {
  //             name: "dbg",
  //             color: "hsl(288, 70%, 50%)",
  //             value: 196495,
  //           },
  //         ],
  //       },
  //       {
  //         name: "generators",
  //         color: "hsl(249, 70%, 50%)",
  //         children: [
  //           {
  //             name: "address",
  //             color: "hsl(62, 70%, 50%)",
  //             value: 155668,
  //           },
  //           {
  //             name: "city",
  //             color: "hsl(122, 70%, 50%)",
  //             value: 25415,
  //           },
  //           {
  //             name: "animal",
  //             color: "hsl(126, 70%, 50%)",
  //             value: 157589,
  //           },
  //           {
  //             name: "movie",
  //             color: "hsl(58, 70%, 50%)",
  //             value: 61714,
  //           },
  //           {
  //             name: "user",
  //             color: "hsl(99, 70%, 50%)",
  //             value: 149578,
  //           },
  //         ],
  //       },
  //       {
  //         name: "set",
  //         color: "hsl(219, 70%, 50%)",
  //         children: [
  //           {
  //             name: "clone",
  //             color: "hsl(157, 70%, 50%)",
  //             value: 26401,
  //           },
  //           {
  //             name: "intersect",
  //             color: "hsl(84, 70%, 50%)",
  //             value: 32528,
  //           },
  //           {
  //             name: "merge",
  //             color: "hsl(192, 70%, 50%)",
  //             value: 52033,
  //           },
  //           {
  //             name: "reverse",
  //             color: "hsl(272, 70%, 50%)",
  //             value: 146466,
  //           },
  //           {
  //             name: "toArray",
  //             color: "hsl(225, 70%, 50%)",
  //             value: 106196,
  //           },
  //           {
  //             name: "toObject",
  //             color: "hsl(164, 70%, 50%)",
  //             value: 153877,
  //           },
  //           {
  //             name: "fromCSV",
  //             color: "hsl(106, 70%, 50%)",
  //             value: 71629,
  //           },
  //           {
  //             name: "slice",
  //             color: "hsl(352, 70%, 50%)",
  //             value: 36699,
  //           },
  //           {
  //             name: "append",
  //             color: "hsl(48, 70%, 50%)",
  //             value: 120338,
  //           },
  //           {
  //             name: "prepend",
  //             color: "hsl(225, 70%, 50%)",
  //             value: 118935,
  //           },
  //           {
  //             name: "shuffle",
  //             color: "hsl(145, 70%, 50%)",
  //             value: 17863,
  //           },
  //           {
  //             name: "pick",
  //             color: "hsl(146, 70%, 50%)",
  //             value: 154553,
  //           },
  //           {
  //             name: "plouc",
  //             color: "hsl(59, 70%, 50%)",
  //             value: 3801,
  //           },
  //         ],
  //       },
  //       {
  //         name: "text",
  //         color: "hsl(183, 70%, 50%)",
  //         children: [
  //           {
  //             name: "trim",
  //             color: "hsl(289, 70%, 50%)",
  //             value: 76050,
  //           },
  //           {
  //             name: "slugify",
  //             color: "hsl(162, 70%, 50%)",
  //             value: 89499,
  //           },
  //           {
  //             name: "snakeCase",
  //             color: "hsl(156, 70%, 50%)",
  //             value: 175968,
  //           },
  //           {
  //             name: "camelCase",
  //             color: "hsl(219, 70%, 50%)",
  //             value: 153752,
  //           },
  //           {
  //             name: "repeat",
  //             color: "hsl(81, 70%, 50%)",
  //             value: 24202,
  //           },
  //           {
  //             name: "padLeft",
  //             color: "hsl(258, 70%, 50%)",
  //             value: 142633,
  //           },
  //           {
  //             name: "padRight",
  //             color: "hsl(242, 70%, 50%)",
  //             value: 193980,
  //           },
  //           {
  //             name: "sanitize",
  //             color: "hsl(354, 70%, 50%)",
  //             value: 6343,
  //           },
  //           {
  //             name: "ploucify",
  //             color: "hsl(104, 70%, 50%)",
  //             value: 78561,
  //           },
  //         ],
  //       },
  //       {
  //         name: "misc",
  //         color: "hsl(344, 70%, 50%)",
  //         children: [
  //           {
  //             name: "greetings",
  //             color: "hsl(290, 70%, 50%)",
  //             children: [
  //               {
  //                 name: "hey",
  //                 color: "hsl(315, 70%, 50%)",
  //                 value: 174237,
  //               },
  //               {
  //                 name: "HOWDY",
  //                 color: "hsl(54, 70%, 50%)",
  //                 value: 134289,
  //               },
  //               {
  //                 name: "aloha",
  //                 color: "hsl(220, 70%, 50%)",
  //                 value: 41152,
  //               },
  //               {
  //                 name: "AHOY",
  //                 color: "hsl(73, 70%, 50%)",
  //                 value: 139691,
  //               },
  //             ],
  //           },
  //           {
  //             name: "other",
  //             color: "hsl(286, 70%, 50%)",
  //             value: 115070,
  //           },
  //           {
  //             name: "path",
  //             color: "hsl(300, 70%, 50%)",
  //             children: [
  //               {
  //                 name: "pathA",
  //                 color: "hsl(352, 70%, 50%)",
  //                 value: 101594,
  //               },
  //               {
  //                 name: "pathB",
  //                 color: "hsl(65, 70%, 50%)",
  //                 children: [
  //                   {
  //                     name: "pathB1",
  //                     color: "hsl(30, 70%, 50%)",
  //                     value: 14898,
  //                   },
  //                   {
  //                     name: "pathB2",
  //                     color: "hsl(345, 70%, 50%)",
  //                     value: 197028,
  //                   },
  //                   {
  //                     name: "pathB3",
  //                     color: "hsl(163, 70%, 50%)",
  //                     value: 46691,
  //                   },
  //                   {
  //                     name: "pathB4",
  //                     color: "hsl(62, 70%, 50%)",
  //                     value: 58157,
  //                   },
  //                 ],
  //               },
  //               {
  //                 name: "pathC",
  //                 color: "hsl(340, 70%, 50%)",
  //                 children: [
  //                   {
  //                     name: "pathC1",
  //                     color: "hsl(38, 70%, 50%)",
  //                     value: 115490,
  //                   },
  //                   {
  //                     name: "pathC2",
  //                     color: "hsl(29, 70%, 50%)",
  //                     value: 120633,
  //                   },
  //                   {
  //                     name: "pathC3",
  //                     color: "hsl(267, 70%, 50%)",
  //                     value: 98780,
  //                   },
  //                   {
  //                     name: "pathC4",
  //                     color: "hsl(214, 70%, 50%)",
  //                     value: 28020,
  //                   },
  //                   {
  //                     name: "pathC5",
  //                     color: "hsl(300, 70%, 50%)",
  //                     value: 177133,
  //                   },
  //                   {
  //                     name: "pathC6",
  //                     color: "hsl(231, 70%, 50%)",
  //                     value: 127068,
  //                   },
  //                   {
  //                     name: "pathC7",
  //                     color: "hsl(109, 70%, 50%)",
  //                     value: 61514,
  //                   },
  //                   {
  //                     name: "pathC8",
  //                     color: "hsl(275, 70%, 50%)",
  //                     value: 21041,
  //                   },
  //                   {
  //                     name: "pathC9",
  //                     color: "hsl(57, 70%, 50%)",
  //                     value: 89543,
  //                   },
  //                 ],
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   };

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <div className="section">
        <Container>
          <div style={{ height: "500px" }}>
            <h6 className="text-primary">
              {" "}
              {t("ProjectsByCategory-Sunburst")}
            </h6>
            <ResponsiveSunburst
              data={data}
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              id="name"
              value="value"
              valueFormat=" >-d"
              cornerRadius={2}
              borderColor={{ theme: "background" }}
              colors={{ scheme: "nivo" }}
              childColor={{ from: "color", modifiers: [["brighter", 0.1]] }}
              enableArcLabels={true}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 1.4]],
              }}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default ProjectsByCategorySunburst;
