import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardTitle, CardHeader, FormGroup } from "reactstrap";
import { AccountTypes } from "_helpers";
import { profileService } from "_services";
function CompanyInfo({ props }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const user = profileService.userValue;
  return (
    <Card className={rtl ? "text-right" : ""}>
      <CardHeader className="text-center">
        <CardTitle className="" tag="h4">
          {t("createCompanyProfile.company.diviver")}
        </CardTitle>
        <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
      </CardHeader>
      <CardBody>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.companyName")}
              </label>
              <label className="col-md-6">
                {props.profile.companyName !== null &&
                  props.profile.companyName}
              </label>
            </div>
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.managerName")}
              </label>
              <label className="col-md-6">
                {props.profile.managerFullName !== null &&
                  props.profile.managerFullName}
              </label>
            </div>
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label
                className="col-md-6"
                htmlFor="crNumber"
                style={{ fontWeight: "bold" }}
              >
                {t("createCompanyProfile.crNumber")}
              </label>
              <label className="col-md-6" htmlFor="crNumber">
                {props.profile.crNumber !== null && props.profile.crNumber}
              </label>
            </div>
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.activeSince")}
              </label>
              <label className="col-md-6">
                {props.profile.activeSince !== null &&
                  props.profile.activeSince}
              </label>
            </div>
          </FormGroup>
        </div>

        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("affiliatedTo")}
              </label>
              <label className="col-md-6">
                {props.affiliatedTo !== null && props.affiliatedTo}
              </label>
            </div>
          </FormGroup>
        </div>

        <div className="form-row">
          {props.profileTypeView.profileTypeDescription ===
            AccountTypes.Provider &&
            props.profile.spType === "Start Up" && (
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("createCompanyProfile.StartupStage")}
                  </label>
                  <label className="col-md-6">
                    {props.profile.startupStage !== null &&
                      props.profile.startupStage}
                  </label>
                </div>
              </FormGroup>
            )}
          {props.profileTypeView.profileTypeDescription ===
            AccountTypes.Provider && (
            <FormGroup className="col-md-12">
              <div style={{ display: "flex" }}>
                <label className="col-md-6" style={{ fontWeight: "bold" }}>
                  {t("createCompanyProfile.Financing")}
                </label>
                <label className="col-md-6">
                  {props.profile.financing !== null && props.profile.financing
                    ? t("label.yes")
                    : t("label.no")}
                </label>
              </div>
            </FormGroup>
          )}
        </div>
        {props.profile.openForPartnership != null ? (
          <div className="form-row">
            <FormGroup className="col-md-12">
              <div style={{ display: "flex" }}>
                <label className="col-md-6" style={{ fontWeight: "bold" }}>
                  {t("createCompanyProfile.openForPartnership")}
                </label>
                <label className="col-md-6">
                  {props.profile.openForPartnership === false
                    ? t("label.no")
                    : t("label.yes")}
                </label>
              </div>
            </FormGroup>
          </div>
        ) : (
          ""
        )}
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.numberOfEmployees")}
              </label>
              <label className="col-md-6">
                {props.numberOfEmployeesView.description !== null &&
                  props.numberOfEmployeesView.description}
              </label>
            </div>
          </FormGroup>
        </div>
        {props.profile.spType === "Start Up" &&
        props.profile.incubator != null ? (
          <div className="form-row">
            <FormGroup className="col-md-12">
              <div style={{ display: "flex" }}>
                <label className="col-md-6" style={{ fontWeight: "bold" }}>
                  {t("createCompanyProfile.incubator")}
                </label>
                <label className="col-md-6">{props.profile.incubator}</label>
              </div>
            </FormGroup>
          </div>
        ) : (
          ""
        )}

        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.website")}
              </label>
              {props.profile.website &&
              !props.profile.website.startsWith("http://") &&
              !props.profile.website.startsWith("https://") ? (
                <a
                  className="col-md-6"
                  target="_blank"
                  rel="noreferrer"
                  href={`http://${props.profile.website}`}
                >
                  {props.profile.website}
                </a>
              ) : (
                <a
                  className="col-md-6"
                  target="_blank"
                  rel="noreferrer"
                  href={props.profile.website !== null && props.profile.website}
                >
                  {props.profile.website !== null && props.profile.website}
                </a>
              )}
            </div>
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.linkedin")}
              </label>
              {props.profile.linkedIn &&
              !props.profile.linkedIn.startsWith("http://") &&
              !props.profile.linkedIn.startsWith("https://") ? (
                <a
                  className="col-md-6"
                  target="_blank"
                  rel="noreferrer"
                  href={`http://${props.profile.linkedIn}`}
                >
                  {props.profile.linkedIn}
                </a>
              ) : (
                <a
                  className="col-md-6"
                  target="_blank"
                  rel="noreferrer"
                  href={
                    props.profile.linkedIn !== null && props.profile.linkedIn
                  }
                >
                  {props.profile.linkedIn !== null && props.profile.linkedIn}
                </a>
              )}
            </div>
          </FormGroup>
        </div>
      </CardBody>
    </Card>
  );
}

export default CompanyInfo;
