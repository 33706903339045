import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import OpportunitiesList from "./OpportunitiesList";
import OpportunityDescription from "./OpportunityDescription";

function UserOpportunities({ match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={OpportunitiesList} exact />
        <PrivateRoute
          path={`${path}/opportunity-description/:projectID`}
          component={OpportunityDescription}
          exact
        />
      </Switch>
    </div>
  );
}

export { UserOpportunities };
