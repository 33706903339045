import React from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
// reactstrap components
import { Card, CardBody, CardTitle, CardHeader, FormGroup } from "reactstrap";

// core components

function Industries({ props }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  return (
    <Card className={rtl ? "text-right" : ""}>
      <CardHeader className="text-center">
        <CardTitle className="" tag="h4">
          {t("createCompanyProfile.industry")}
        </CardTitle>
        <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
      </CardHeader>
      <CardBody>
        {props.industriesAndServices &&
          props.industriesAndServices.map((industry) => {
            return (
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <div style={{ display: "flex" }}>
                    <label
                      className="col-md-6"
                      htmlFor="companyName"
                      style={{ fontWeight: "bold" }}
                    >
                      {industry.industryName}
                    </label>
                    <div
                      className="col-md-6"
                      style={{
                        //   display: "grid",
                        paddingLeft: "none",
                        paddingRight: "none",
                      }}
                    >
                      {industry.services &&
                        industry.services.map((service) => {
                          return (
                            <>
                              <label
                              // className="col-md-12"
                              >
                                {service.serviceName}
                              </label>
                              <br></br>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </FormGroup>
              </div>
            );
          })}
      </CardBody>
    </Card>
  );
}

export default Industries;
