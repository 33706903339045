import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import ServiceProviderViewers from "./ServiceProvidersViewers";

function ServiceProviderDetails({ match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute
          path={`${path}/serviceprovider-viewers/:id`}
          component={ServiceProviderViewers}
          exact
        />
      </Switch>
    </div>
  );
}

export default ServiceProviderDetails;
