import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditNbEmployees from "./AddEditNbEmployees";
import NbEmployeesList from "./NbEmployeesList";

function NbEmployees({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={NbEmployeesList} exact />
        <PrivateRoute path={`${path}/add`} component={AddEditNbEmployees} />
        <PrivateRoute
          path={`${path}/edit/:id`}
          component={AddEditNbEmployees}
        />
      </Switch>
    </div>
  );
}

export { NbEmployees };
