import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import Password from "./Password";

function AccountSettings({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute
          path={`${path}/change-password`}
          component={Password}
          exact
        />
      </Switch>
    </div>
  );
}

export { AccountSettings };
