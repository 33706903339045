import React, { useEffect, useState, useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { AccountTypes } from "../../_helpers";
import { ProfileStatus } from "../../_helpers";
import { UnseenMessagesContext } from "../../_context";
import { requestPermissionAndGetToken } from "../../firebase";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Col,
  CardTitle,
  Container,
  Modal,
  Row,
} from "reactstrap";
import { LanguageContext } from "_context";
import { profileService, spSeenService, tokenService } from "../../_services";
import { Link } from "react-router-dom";
import { StartupStages } from "_helpers";
import queryString from "query-string";

function LoginForm({ history, location }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const parsed = queryString.parse(location.search);

  const initialValues = {
    email:
      parsed.email !== undefined || parsed.email !== null ? parsed.email : "",
    password: "",
  };
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [currentUnseenMessage, setCurrentUnseenMessage] = useContext(
    UnseenMessagesContext
  );

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    console.log(history.location);
    document.body.classList.add("sidebar-collapse");
    // document.body.style = "background-color: #f5f5fa";
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t("signUp.email.error")),
    password: Yup.string()
      .concat(Yup.string().required(t("signUp.password.required")))
      .min(6, t("signUp.password.regex")),
  });

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  const [accountExpired, setAccountExpired] = useState(false);
  const [accountUpgraded, setAccountUpgraded] = useState(false);
  const [notVerifiedAlert, setNotVerifiedAlert] = useState(false);

  const [oldEmail, setOldEmail] = useState("");
  async function AddSpSeen(model) {
    await spSeenService
      .AddSpSeenAsync(model)
      .then((x) => {
        history.push(`/seeker/search-provider/search-details/${model.spId}`);
      })
      .catch((error) => {
        console.log(error);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName === currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    setSubmitting(true);
    await profileService
      .LoginAsync(fields)
      .then(async (user) => {
        // const permission = await requestPermissionAndGetToken();
        // // console.log("permission", permission);
        setSubmitting(false);
        // const token = localStorage.getItem("token");
        // console.log(token);
        // const model = {
        //   UserID: user.profile.userId,
        //   Token: token,
        // };
        // if (permission == 1) {
        //   await tokenService.AddTokenAsync(model).then((x) => {
        //     localStorage.removeItem("token");
        //   });
        // }
        setCurrentUnseenMessage(user.unseenMessages);
        console.log(user);
        console.log(history.location);
        if (user.profile.profileStatus === ProfileStatus.New) {
          setNotVerifiedAlert(!notVerifiedAlert);
        } else if (user.profile.profileStatus === ProfileStatus.Verified) {
          if (user.profile.importedUserPasswordReset === false) {
            history.push(`/account/change-password`);
          } else {
            history.push(
              `/profile/create-profile?email=${user.profile.userName}&fromemail=false`
            );
          }
        } else if (user.profile.profileStatus === ProfileStatus.Draft) {
          history.push(`/profile/edit-profile`, { userDraft: true });
        } else {
          if (
            location !== null &&
            location !== undefined &&
            location.state !== null &&
            location.state !== undefined &&
            location.state.state !== null &&
            location.state.state !== undefined &&
            location.state.state.source === "fromPlans"
          ) {
            // history.push("/plans");
          } else if (
            location !== null &&
            location !== undefined &&
            location.state !== null &&
            location.state !== undefined &&
            location.state.state !== null &&
            location.state.state !== undefined &&
            location.state.state.source === "fromFeaturedSP"
          ) {
            if (
              user.profileTypeView.profileTypeDescription ===
                AccountTypes.Seeker ||
              user.profileTypeView.profileTypeDescription ===
                AccountTypes.Partner
            ) {
              const model = {
                userId: user.profile.userId,
                spId: location.state.state.profile,
              };
              AddSpSeen(model);
            } else {
              history.push("/index");
            }
          } else if (
            location !== null &&
            location !== undefined &&
            location.state !== null &&
            location.state !== undefined &&
            location.state.state !== null &&
            location.state.state !== undefined &&
            location.state.state.source === "fromFeaturedLinkedOpportunities"
          ) {
            if (
              user !== null &&
              user.profile !== null &&
              user.profile.startupStage !== null &&
              user.profile.startupStage === StartupStages.Seed
            ) {
              var date = `${new Date(user.profile.expirationDate).getDate()}/${
                monthNames[new Date(user.profile.expirationDate).getMonth()]
              }/${new Date(user.profile.expirationDate).getFullYear()}`;

              if (date !== "1/January/1") {
                if (user.profileExpired === true) {
                  setOldEmail(user.profile.userName);
                  setAccountExpired(!accountExpired);
                } else {
                  sessionStorage.setItem("startupSeedExpiryMessage", "true");
                  if (
                    location.state.state.link &&
                    !location.state.state.link.startsWith("http://") &&
                    !location.state.state.link.startsWith("https://")
                  )
                    window.open(
                      `http://${location.state.state.link}`,
                      "_blank"
                    );
                  else window.open(location.state.state.link, "_blank");
                  history.push("/provider/search-project");
                }
              } else {
                if (user.profileExpired === true) {
                  setOldEmail(user.profile.userName);
                  setAccountExpired(!accountExpired);
                } else {
                  if (
                    location.state.state.link &&
                    !location.state.state.link.startsWith("http://") &&
                    !location.state.state.link.startsWith("https://")
                  )
                    window.open(
                      `http://${location.state.state.link}`,
                      "_blank"
                    );
                  else window.open(location.state.state.link, "_blank");
                  history.push("/provider/search-project");
                }
              }
            } else {
              if (
                location.state.state.link &&
                !location.state.state.link.startsWith("http://") &&
                !location.state.state.link.startsWith("https://")
              )
                window.open(`http://${location.state.state.link}`, "_blank");
              else window.open(location.state.state.link, "_blank");
              history.push("/provider/search-project");
            }
          } else if (
            location !== null &&
            location !== undefined &&
            location.state !== null &&
            location.state !== undefined &&
            location.state.state !== null &&
            location.state.state !== undefined &&
            location.state.state.source === "fromFeaturedOpportunities"
          ) {
            if (
              user !== null &&
              user.profile !== null &&
              user.profile.startupStage !== null &&
              user.profile.startupStage === StartupStages.Seed
            ) {
              var date = `${new Date(user.profile.expirationDate).getDate()}/${
                monthNames[new Date(user.profile.expirationDate).getMonth()]
              }/${new Date(user.profile.expirationDate).getFullYear()}`;

              if (date !== "1/January/1") {
                if (user.profileExpired === true) {
                  setOldEmail(user.profile.userName);
                  setAccountExpired(!accountExpired);
                } else {
                  sessionStorage.setItem("startupSeedExpiryMessage", "true");
                  history.push("/index#project", {
                    state: {
                      source: "fromFeaturedOpportunities",
                      project: location.state.state.project,
                    },
                  });
                }
              } else {
                if (user.profileExpired === true) {
                  setOldEmail(user.profile.userName);
                  setAccountExpired(!accountExpired);
                } else {
                  history.push("/index#project", {
                    state: {
                      source: "fromFeaturedOpportunities",
                      project: location.state.state.project,
                    },
                  });
                }
              }
            } else {
              history.push("/index#project", {
                state: {
                  source: "fromFeaturedOpportunities",
                  project: location.state.state.project,
                },
              });
            }
          } else if (
            parsed.source == "fromFeaturedOpportunities" &&
            parsed.project != null
          ) {
            if (
              user !== null &&
              user.profile !== null &&
              user.profile.startupStage !== null &&
              user.profile.startupStage === StartupStages.Seed
            ) {
              var date = `${new Date(user.profile.expirationDate).getDate()}/${
                monthNames[new Date(user.profile.expirationDate).getMonth()]
              }/${new Date(user.profile.expirationDate).getFullYear()}`;

              if (date !== "1/January/1") {
                if (user.profileExpired === true) {
                  setOldEmail(user.profile.userName);
                  setAccountExpired(!accountExpired);
                } else {
                  sessionStorage.setItem("startupSeedExpiryMessage", "true");
                  history.push("/index#project", {
                    state: {
                      source: "fromFeaturedOpportunities",
                      project: parsed.project,
                    },
                  });
                }
              } else {
                if (user.profileExpired === true) {
                  setOldEmail(user.profile.userName);
                  setAccountExpired(!accountExpired);
                } else {
                  history.push("/index#project", {
                    state: {
                      source: "fromFeaturedOpportunities",
                      project: parsed.project,
                    },
                  });
                }
              }
            } else {
              history.push("/index#project", {
                state: {
                  source: "fromFeaturedOpportunities",
                  project: parsed.project,
                },
              });
            }
          } else if (
            parsed.source == "fromFeaturedOpportunities" &&
            parsed.project == null
          ) {
            if (
              user !== null &&
              user.profile !== null &&
              user.profile.startupStage !== null &&
              user.profile.startupStage === StartupStages.Seed
            ) {
              var date = `${new Date(user.profile.expirationDate).getDate()}/${
                monthNames[new Date(user.profile.expirationDate).getMonth()]
              }/${new Date(user.profile.expirationDate).getFullYear()}`;

              if (date !== "1/January/1") {
                if (user.profileExpired === true) {
                  setOldEmail(user.profile.userName);
                  setAccountExpired(!accountExpired);
                } else {
                  sessionStorage.setItem("startupSeedExpiryMessage", "true");
                  history.push("/index#project", {
                    state: {
                      source: "fromFeaturedOpportunities",
                    },
                  });
                }
              } else {
                if (user.profileExpired === true) {
                  setOldEmail(user.profile.userName);
                  setAccountExpired(!accountExpired);
                } else {
                  history.push("/index#project", {
                    state: {
                      source: "fromFeaturedOpportunities",
                    },
                  });
                }
              }
            } else {
              history.push("/index#project", {
                state: {
                  source: "fromFeaturedOpportunities",
                },
              });
            }
          } else if (
            parsed.source == "fromFeaturedSP" &&
            parsed.profile != null
          ) {
            console.log(user);

            if (
              user.profileTypeView.profileTypeDescription ===
                AccountTypes.Seeker ||
              user.profileTypeView.profileTypeDescription ===
                AccountTypes.Partner
            ) {
              history.push("/index#project", {
                state: {
                  source: "fromFeaturedSP",
                  profile: parsed.profile,
                },
              });
            } else {
              history.push("/index#project", {
                state: {
                  source: "fromFeaturedSP",
                  profile: parsed.profile,
                },
              });
            }
          } else if (parsed.source == "fromFeaturedSP") {
            if (
              user.profileTypeView.profileTypeDescription ===
                AccountTypes.Seeker ||
              user.profileTypeView.profileTypeDescription ===
                AccountTypes.Partner
            ) {
              history.push("/index#project", {
                state: {
                  source: "fromFeaturedSP",
                  profile: null,
                },
              });
            } else {
              history.push("/index#project", {
                state: {
                  source: "fromFeaturedSP",
                  profile: null,
                },
              });
            }
          } else if (parsed.addProject == "true") {
            if (
              user.profileTypeView.profileTypeDescription ===
              AccountTypes.Seeker
            ) {
              history.push(`/seeker/projects/add-project`);
            } else if (
              user.profileTypeView.profileTypeDescription ===
              AccountTypes.Partner
            ) {
              history.push(`/partner/projects/add-project`);
            } else {
              history.push(`/index#project`, {
                state: {
                  source: "addProject",
                  profile: null,
                },
              });
            }
          } else if (parsed.fillProject == "true") {
            if (
              user.profileTypeView.profileTypeDescription ===
              AccountTypes.Provider
            ) {
              history.push(`/provider/search-project`);
            } else {
              history.push(`/index#project`, {
                state: {
                  source: "fillProject",
                },
              });
            }
          } else {
            if (
              user.profileTypeView.profileTypeDescription ===
              AccountTypes.Seeker
            ) {
              if (
                history.location != null &&
                history.location.state != null &&
                history.location.state.from != null &&
                history.location.state.from.pathname != null &&
                history.location.state.from.search == null
              ) {
                history.push(history.location.state.from.pathname);
              } else if (
                history.location != null &&
                history.location.state != null &&
                history.location.state.from != null &&
                history.location.state.from.pathname != null &&
                history.location.state.from.search != null
              ) {
                history.push(history.location.state.from.pathname, {
                  type: "consultation",
                });
              } else {
                history.push(`/seeker/search-provider`);
              }
            } else if (
              user.profileTypeView.profileTypeDescription ===
              AccountTypes.Provider
            ) {
              if (
                history.location != null &&
                history.location !== undefined &&
                history.location.state != null &&
                history.location.state !== undefined &&
                history.location.state.from !== null &&
                history.location.state.from !== undefined &&
                history.location.state.from.pathname != null &&
                history.location.state.from.pathname != undefined &&
                history.location.state.from.search == null
              ) {
                if (
                  user !== null &&
                  user.profile !== null &&
                  user.profile.startupStage !== null &&
                  user.profile.startupStage === StartupStages.Seed
                ) {
                  var date = `${new Date(
                    user.profile.expirationDate
                  ).getDate()}/${
                    monthNames[new Date(user.profile.expirationDate).getMonth()]
                  }/${new Date(user.profile.expirationDate).getFullYear()}`;

                  if (date !== "1/January/1") {
                    if (user.profileExpired === true) {
                      setOldEmail(user.profile.userName);
                      setAccountExpired(!accountExpired);
                    } else {
                      sessionStorage.setItem(
                        "startupSeedExpiryMessage",
                        "true"
                      );
                      history.push(history.location.state.from.pathname);
                    }
                  } else {
                    if (user.profileExpired === true) {
                      setOldEmail(user.profile.userName);
                      setAccountExpired(!accountExpired);
                    } else {
                      history.push(history.location.state.from.pathname);
                    }
                  }
                } else {
                  history.push(history.location.state.from.pathname);
                }
              } else {
                if (
                  user !== null &&
                  user.profile !== null &&
                  user.profile.startupStage !== null &&
                  user.profile.startupStage === StartupStages.Seed &&
                  history.location.state.from.search == null
                ) {
                  var date = `${new Date(
                    user.profile.expirationDate
                  ).getDate()}/${
                    monthNames[new Date(user.profile.expirationDate).getMonth()]
                  }/${new Date(user.profile.expirationDate).getFullYear()}`;
                  if (date !== "1/January/1") {
                    if (user.profileExpired === true) {
                      setOldEmail(user.profile.userName);
                      setAccountExpired(!accountExpired);
                    } else {
                      sessionStorage.setItem(
                        "startupSeedExpiryMessage",
                        "true"
                      );
                      history.push("/provider/search-project");
                    }
                  } else {
                    if (user.profileExpired === true) {
                      setOldEmail(user.profile.userName);
                      setAccountExpired(!accountExpired);
                    } else {
                      history.push("/provider/search-project");
                    }
                  }
                } else if (
                  history.location != null &&
                  history.location.state != null &&
                  history.location.state.from != null &&
                  history.location.state.from.search != null
                ) {
                  history.push(history.location.state.from.pathname, {
                    type: "consultation",
                  });
                } else {
                  history.push("/provider/search-project");
                }
              }
              if (history.location == null && history.location == undefined) {
                history.push("/provider/search-project");
              }
            } else if (
              user.profileTypeView.profileTypeDescription ===
              AccountTypes.Partner
            ) {
              if (
                history.location != null &&
                history.location.state != null &&
                history.location.state.from != null &&
                history.location.state.from.search == null
              ) {
                history.push(history.location.state.from.pathname);
              } else if (
                history.location != null &&
                history.location.state != null &&
                history.location.state.from != null &&
                history.location.state.from.search != null
              ) {
                history.push(history.location.state.from.pathname, {
                  type: "consultation",
                });
              } else {
                history.push({
                  pathname: "/partner/search-provider",
                  state: { fromPartner: true },
                });
              }
            } else if (
              history.location != null &&
              history.location.state != null &&
              history.location.state.from != null &&
              history.location.state.from.search != null
            ) {
              history.push(history.location.state.from.pathname);
            } else {
              history.push("/admin");
            }
          }
        }
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName === currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  return (
    <>
      <div
        className="section section-signup"
        style={{
          //background: "#EAF5FE repeat",
          // backgroundColor: "#f5f5fa",
          backgroundSize: "cover",
          marginTop: "50px",
          //backgroundRepeat: "repeat-y",
          //  backgroundPosition: "top center",
          // minHeight: "660px",
        }}
      >
        {/* <SignUpNavbar /> */}
        <Container style={{ marginTop: "-5%" }}>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{errorMessage}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => {
                  setErrorAlert(!errorAlert);
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={accountExpired}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("accountExpired")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{t("accountExpiredBody")}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) =>
                  history.push(`/account/upgrade-account?email=${oldEmail}`)
                }
              >
                {t("upgrade.button")}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={accountUpgraded}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("done")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{t("upgrade.successful.parttwo")}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => setAccountUpgraded(!accountUpgraded)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={notVerifiedAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div className="modal-header">
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div className="modal-body">
              <p>{t("login.error.notVerified")}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => {
                  profileService.Logout("");
                  setNotVerifiedAlert(!notVerifiedAlert);
                  //  history.push("/");
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Row>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, isSubmitting, setFieldValue }) => {
                // useEffect(() => {
                //   let mounted = true;
                //   return () => (mounted = false);
                // }, []);

                return (
                  <Col md="8" style={{ margin: "auto" }}>
                    <Card>
                      <CardHeader className="text-center">
                        <CardTitle className="" tag="h4">
                          {t("login.title")}
                        </CardTitle>
                      </CardHeader>

                      <Form
                        autoComplete="off"
                        className={rtl ? "text-right" : ""}
                        // style={{
                        //   margin: "0 auto",
                        //   width: "100%",
                        //   padding: "10px",
                        // }}
                      >
                        <CardBody>
                          <Row>
                            <Col md="12">
                              <div
                                className="form-row"
                                //style={{ width: "max-content" }}
                              >
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                  }}
                                >
                                  <label htmlFor="inputEmail4">
                                    {t("signUp.email")}
                                  </label>

                                  <Field
                                    autoFocus={true}
                                    name="email"
                                    placeholder={t("signUp.email")}
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  {errors.email && touched.email ? (
                                    <ErrorMessage
                                      name="email"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("signUp.password")}
                                  </label>

                                  <Field
                                    name="password"
                                    placeholder={t("signUp.password")}
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  {errors.password && touched.password ? (
                                    <ErrorMessage
                                      name="password"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <center>
                                    <Button
                                      color="primary"
                                      type="submit"
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting && (
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                      )}
                                      {t("login.button")}
                                    </Button>
                                  </center>
                                </FormGroup>
                              </div>
                              <div className="form-row">
                                <hr
                                  //  className="btn-primary"
                                  style={{
                                    borderTop: "1px solid #bbb",
                                    marginTop: "2%",
                                    width: "90%",
                                  }}
                                ></hr>
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label>
                                    {t("login.newMember")}
                                    <Link to="/account/register">
                                      {t("navigation.register")}
                                    </Link>
                                  </label>
                                  <br></br>
                                  <Link to="/account/forgot-password">
                                    {t("login.forgotPassword")}
                                  </Link>
                                  <br></br>
                                  <Link to="/account/resend-email">
                                    {t("login.resendVerificationEmail")}
                                  </Link>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Form>
                    </Card>
                  </Col>
                );
              }}
            </Formik>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LoginForm;
