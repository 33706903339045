import React, { useState, useContext, useRef, useCallback } from "react";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import * as Yup from "yup";
import { projectTypeService, whowearetypesService } from "_services";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import { languageService } from "_services";
import { adminService } from "_services";
import { LanguageContext } from "_context";
import "../../../assets/css/generalStyles.css";
import "../../../assets/css/overrideReactCrop.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { badgeService } from "_services/BadgeService";
import { Link } from "react-router-dom";

function AddEditSS_PO_SPBadge({ type, history, match, location }) {
  const badgeType = type != null ? type : location.state.type;
  const isAddMode = !match.params.id;
  const [loading, setLoading] = useState(true);
  const [languages, setLanguages] = useState(null);
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [imagePath, setImagePath] = useState();
  const [preview, setPreview] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    nbOfOpportunitiesGained: "",
    nbOfOpportunitiesAwarded: "",
    nbCollaborationsInsurveys: "",
    image: "",
  });
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [imageAlert, setImageAlert] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, height: 30 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [logoAfterCrop, setLogoAfterCrop] = useState(null);
  const [types, setTypes] = useState(null);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  function initialForm(translations) {
    var newJson = {
      name: null,
      nbOfOpportunitiesGained: 0,
      nbOfOpportunitiesAwarded: 0,
      nbCollaborationsInsurveys: 0,
      image: "",
      order: 1,
    };
    translations.map((x) => {
      var newKey = x.languageName;
      var newVal = "";
      newJson[newKey] = newVal;
    });
    setInitialValues(newJson);
  }

  function editInitialForm(badge) {
    var _fetchedBadge = {
      name: badge.name,
      nbOfOpportunitiesGained:
        badge.nbOfOpportunitiesGained == "" ? 0 : badge.nbOfOpportunitiesGained,
      nbOfOpportunitiesAwarded:
        badge.nbOfOpportunitiesAwarded == ""
          ? 0
          : badge.nbOfOpportunitiesAwarded,
      nbCollaborationsInsurveys: badge.nbCollaborationsInsurveys,
      image: badge.image.fileName,
      order: badge.order,
    };
    setPreview(badge.image.filePath);

    setInitialValues(_fetchedBadge);
  }
  var validationSchema;
  badgeType == "SP"
    ? (validationSchema = Yup.object().shape({
        name: Yup.string().required(t("fieldRequired")).nullable(),
        nbOfOpportunitiesGained: Yup.number()
          .required(t("fieldRequired"))
          .min(0, t("badge.NumberOfLoginsVal"))
          .nullable(),
        nbCollaborationsInsurveys: Yup.number()
          .required(t("fieldRequired"))
          .min(0, t("badge.NumberOfLoginsVal"))
          .nullable(),
        order: Yup.number()
          .required(t("fieldRequired"))
          .min(1, t("badge.NumberOfLoginsVal"))
          .nullable(),
      }))
    : (validationSchema = Yup.object().shape({
        name: Yup.string().required(t("fieldRequired")).nullable(),
        nbOfOpportunitiesAwarded: Yup.number()
          .required(t("fieldRequired"))
          .min(0, t("badge.NumberOfLoginsVal"))
          .nullable(),
        nbCollaborationsInsurveys: Yup.number()
          .required(t("fieldRequired"))
          .min(0, t("badge.NumberOfLoginsVal"))
          .nullable(),
        order: Yup.number()
          .required(t("fieldRequired"))
          .min(1, t("badge.order"))
          .nullable(),
      }));

  function onImageChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setPreview(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setImagePath(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
      setImageAlert(!imageAlert);
    } else {
      setPreview(null);
    }
  }
  function base64StringtoFile(base64String, filename) {
    var arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  function handleCompleteCrop() {
    const canvasRef = previewCanvasRef.current;
    const imageData64 = canvasRef.toDataURL(imagePath.type);
    const myFilename = imagePath.name;

    // file to be uploaded
    const myNewCroppedFile = base64StringtoFile(imageData64, myFilename);
    return myNewCroppedFile;
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  function onCrop(c) {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = c;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    //ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }

  const fetchData = async () => {
    setLoading(true);

    await languageService.GetAllLanguagesAsync().then((x) => {
      setLanguages(x);
      if (isAddMode) {
        initialForm(x);
      } else {
        editInitialForm(location.state.state);
      }
    });
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;

        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === "object") {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey);
        }
      });
      return result;
    };

    return transformObjectToDotNotation(formikErrors);
  };

  const ScrollToFieldError = ({
    scrollBehavior = { behavior: "smooth", block: "center" },
  }) => {
    const { submitCount, isValid, errors } = useFormikContext();

    React.useEffect(() => {
      if (isValid) return;

      const fieldErrorNames = getFieldErrorNames(errors);
      if (fieldErrorNames.length <= 0) return;

      const element = document.querySelector(`[name='${fieldErrorNames[0]}']`);
      if (!element) return;

      // Scroll to first known error into view
      element.scrollIntoView(scrollBehavior);
      // Formik doesn't (yet) provide a callback for a client-failed submission,
      // thus why this is implemented through a hook that listens to changes on
      // the submit count.
    }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
  };
  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    const formData = new FormData();

    if (fields.name != null) formData.append("name", fields.name);

    if (fields.nbOfOpportunitiesGained != null)
      formData.append(
        "nbOfOpportunitiesGained",
        fields.nbOfOpportunitiesGained
      );

    if (fields.nbOfOpportunitiesAwarded != null)
      formData.append(
        "nbOfOpportunitiesAwarded",
        fields.nbOfOpportunitiesAwarded
      );

    if (fields.nbCollaborationsInsurveys != null)
      formData.append(
        "nbCollaborationsInsurveys",
        fields.nbCollaborationsInsurveys
      );
    if (fields.nbCollaborationsInsurveys != null)
      formData.append("order", fields.order);

    formData.append("Image", logoAfterCrop);

    formData.append("type", badgeType);

    if (isAddMode) {
      await badgeService
        .AddBadgeAsync(formData)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          console.log(error);
          if (error !== null) {
            if (error.message !== undefined) {
              setError(error.message);
              if (error.error !== null && error.error.length > 0) {
                for (var i = 0; i < error.error.length; i++) {
                  if (error.error[i].languageName === currentLan) {
                    setError(error.error[i].error);
                  }
                }
              }
            } else {
              setError(error);
            }
            setErrorAlert(!errorAlert);
          }
        });
      setSubmitting(false);
    } else {
      await badgeService
        .EditBadge(match.params.id, formData)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          console.log(error);
          if (error !== null) {
            if (error.message !== undefined) {
              setError(error.message);
              if (error.error !== null && error.error.length > 0) {
                for (var i = 0; i < error.error.length; i++) {
                  if (error.error[i].languageName === currentLan) {
                    setError(error.error[i].error);
                  }
                }
              }
            } else {
              setError(error);
            }
            setErrorAlert(!errorAlert);
          }
        });
      setSubmitting(false);
    }
  }

  return (
    <div className={isAddMode ? "" : "section"}>
      <Container>
        <Modal isOpen={imageAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">Edit Image</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>{t("croppingTool")}</p>
            <Row>
              {preview !== null ? (
                <ReactCrop
                  style={{ margin: "auto" }}
                  src={preview}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => {
                    setCompletedCrop(c);
                    onCrop(c);
                  }}
                />
              ) : null}
            </Row>
            <p>{t("previewTool")}</p>
            <Row>
              <canvas
                ref={previewCanvasRef}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                  margin: "auto",
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0),
                }}
              />
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => {
                setPreview(null);
                setLogoAfterCrop(handleCompleteCrop());
                setImageAlert(!imageAlert);
              }}
            >
              {t("done")}
            </Button>
          </div>
        </Modal>

        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("addProjectType.title")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>{isAddMode ? t("addBadge.body") : t("editBadge.body")}</p>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => {
                setAlert(!alert);
                if (isAddMode) {
                  history.push(".", {
                    type: "activity",
                  });
                } else {
                  history.push("../..", {
                    type: "activity",
                  });
                }
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ setFieldValue, errors, touched, isSubmitting }) => {
              return (
                <Col md="8" style={{ margin: "auto" }}>
                  <Card className={rtl ? "text-right" : ""}>
                    <Form autoComplete="off">
                      <ScrollToFieldError />
                      <CardBody>
                        <div>
                          <Col md="10">
                            <Link
                              onClick={(e) => {
                                e.preventDefault();
                                if (isAddMode) {
                                  history.push(".", { type: "activity" });
                                } else {
                                  history.push("../..", { type: "activity" });
                                }
                              }}
                            >
                              {t("backToList")}
                            </Link>
                          </Col>
                        </div>
                        <CardTitle className="text-center" tag="h4">
                          {isAddMode
                            ? badgeType == "SP"
                              ? t("addSpBadge")
                              : t("addSSorPOBadge")
                            : t("editBadge")}
                        </CardTitle>
                        <Row>
                          <Col md="12">
                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("badgeName")}
                                </label>
                                <Field
                                  // disabled={!isAddMode}
                                  name="name"
                                  placeholder={t("badgeName")}
                                  className={
                                    "form-control" +
                                    (errors.name && touched.name
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {errors.name && touched.name ? (
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                ) : (
                                  <span className="isValid" component="div">
                                    <br></br>
                                  </span>
                                )}
                              </FormGroup>
                            </div>
                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("badgeOrder")}
                                </label>
                                <Field
                                  type="number"
                                  // disabled={!isAddMode}
                                  name="order"
                                  placeholder={t("badgeOrder")}
                                  className={
                                    "form-control" +
                                    (errors.order && touched.order
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {errors.order && touched.order ? (
                                  <ErrorMessage
                                    name="order"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                ) : (
                                  <span className="isValid" component="div">
                                    <br></br>
                                  </span>
                                )}
                              </FormGroup>
                            </div>
                            {badgeType == "SP" ? (
                              <div className="form-group">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("badgeNbOfOpportunitiesGained")}
                                  </label>
                                  <Field
                                    type="number"
                                    // disabled={!isAddMode}
                                    name="nbOfOpportunitiesGained"
                                    placeholder={t(
                                      "badgeNbOfOpportunitiesGained"
                                    )}
                                    className={
                                      "form-control" +
                                      (errors.nbOfOpportunitiesGained &&
                                      touched.nbOfOpportunitiesGained
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  {errors.nbOfOpportunitiesGained &&
                                  touched.nbOfOpportunitiesGained ? (
                                    <ErrorMessage
                                      name="nbOfOpportunitiesGained"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                            ) : (
                              <div className="form-group">
                                <FormGroup
                                  className="col-md-8"
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    padding: "10px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  <label htmlFor="inputPassword4">
                                    {t("badgeNbOfOpportunitiesAwarded")}
                                  </label>
                                  <Field
                                    type="number"
                                    name="nbOfOpportunitiesAwarded"
                                    placeholder={t(
                                      "badgeNbOfOpportunitiesAwarded"
                                    )}
                                    className={
                                      "form-control" +
                                      (errors.nbOfOpportunitiesAwarded &&
                                      touched.nbOfOpportunitiesAwarded
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  {errors.nbOfOpportunitiesAwarded &&
                                  touched.nbOfOpportunitiesAwarded ? (
                                    <ErrorMessage
                                      name="nbOfOpportunitiesAwarded"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  ) : (
                                    <span className="isValid" component="div">
                                      <br></br>
                                    </span>
                                  )}
                                </FormGroup>
                              </div>
                            )}

                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("badgeNbCollaborationsInsurveys")}
                                </label>
                                <Field
                                  type="number"
                                  name="nbCollaborationsInsurveys"
                                  placeholder={t(
                                    "badgeNbCollaborationsInsurveys"
                                  )}
                                  className={
                                    "form-control" +
                                    (errors.nbCollaborationsInsurveys &&
                                    touched.nbCollaborationsInsurveys
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {errors.nbCollaborationsInsurveys &&
                                touched.nbCollaborationsInsurveys ? (
                                  <ErrorMessage
                                    name="nbCollaborationsInsurveys"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                ) : (
                                  <span className="isValid" component="div">
                                    <br></br>
                                  </span>
                                )}
                              </FormGroup>
                            </div>
                            <div class="form-group">
                              <Row>
                                <Col md="12">
                                  <FormGroup
                                    className="col-md-8"
                                    style={{
                                      margin: "0 auto",
                                      width: "100%",
                                      padding: "10px",
                                      marginTop: "-2%",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "100",
                                        marginTop: "10px",
                                      }}
                                      htmlFor="inputPassword4"
                                    >
                                      {t("badgeImage")}
                                    </label>
                                    {preview !== null ? (
                                      <center>
                                        <img
                                          className="photo-container"
                                          alt="..."
                                          style={{
                                            objectFit: "contain",
                                            margin: "0 0 auto auto",
                                            borderRadius: "0%",
                                          }}
                                          width="10%"
                                          height="10%"
                                          src={preview}
                                        ></img>
                                      </center>
                                    ) : (
                                      <center>
                                        <img
                                          className="photo-container"
                                          alt="..."
                                          style={{
                                            objectFit: "contain",
                                            margin: "0 0 auto auto",
                                            borderRadius: "0%",
                                          }}
                                          width="10%"
                                          height="10%"
                                          src={
                                            logoAfterCrop === null
                                              ? null
                                              : URL.createObjectURL(
                                                  logoAfterCrop
                                                )
                                          }
                                        ></img>
                                      </center>
                                    )}

                                    <input
                                      id="image"
                                      style={{ marginTop: "10px" }}
                                      name="image"
                                      type="file"
                                      accept="image/*"
                                      required={isAddMode ? true : false}
                                      className={"form-control "}
                                      onChange={(event) => {
                                        onImageChange(event);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>

                            <div className="form-row">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <center>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {isAddMode
                                      ? t("add.button")
                                      : t("createCompanyProfile.save")}
                                  </Button>
                                </center>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default AddEditSS_PO_SPBadge;
