import React, { useState, useContext } from "react";
import { Button, CardBody, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import BarChartIcon from "@material-ui/icons/BarChart";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { Paper, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../theme.js";
import {
  adminService,
  profileService,
  searchService,
  roleService,
} from "_services";
import { surveyService } from "_services/survey.service";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { LanguageContext } from "_context";
import { AccountTypes } from "_helpers";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import FormGroup from "reactstrap/lib/FormGroup";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  SubdirectoryArrowLeftIcon: forwardRef((props, ref) => (
    <SubdirectoryArrowLeftIcon {...props} ref={ref} />
  )),
  FileCopyIcon: forwardRef((props, ref) => (
    <FileCopyIcon {...props} ref={ref} />
  )),
  AccountBalanceIcon: forwardRef((props, ref) => (
    <AccountBalanceIcon {...props} ref={ref} />
  )),
};

function ManagerUsers({ history, match, location }) {
  const user = profileService.userValue;
  const { path } = match;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [zeroSurveysAlert, setZeroSurveysAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [surveyAlert, setSurveyAlert] = useState(false);
  const [rowDataID, setRowDataID] = useState(null);
  const [roles, setRoles] = useState([]);
  const [accountTypeID, setAccountTypeID] = useState(null);
  const [accountTypeAlert, setAccountTypeAlert] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [surveys, setSurveys] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const keyword = React.useRef("");
  const search = React.useRef(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [SPTotalCount, setSPCount] = useState(null);

  const [SSTotalCount, setSSCount] = useState(null);

  const [POTotalCount, setPOCount] = useState(null);
  const [pageSize, setPageSize] = useState(20);

  const pageSizeRef = React.useRef(20);
  const usersRef = React.useRef([]);
  const itemCountRef = React.useRef(0);

  const resetSearch = React.useRef(false);

  var columns = [
    {
      title: t("admin.users.userName"),
      field: "profile.userName",
      width: "20%",
    },
    {
      title: t("admin.users.companyName"),
      field: "profile.companyName",
      width: "10%",
    },
    {
      title: t("admin.users.type"),
      field: "profileTypeView.profileTypeName",
      width: "10%",
    },
    {
      title: t("signUp.providerType.title"),
      field: "profile.spType",
      width: "10%",
    },
    {
      title: t("createCompanyProfile.StartupStage"),
      field: "startupStage",
      width: "10%",
    },
    // {
    //   title: t("expirationDate"),
    //   field: "expirationDate",
    //   width: "10%",
    // },
    {
      title: t("admin.users.status"),
      field: "profile.profileStatus",
      width: "10%",
    },
    {
      title: t("UserActivityBadgeName"),
      field: "activityBadgeName",
    },
    {
      title: t("UserVettingBadgeName"),
      field: "vettingBadgeName",
    },
    {
      title: t("UserPlanName"),
      field: "planName",
    },
    // {
    //   title: t("admin.users.activationDate"),
    //   field: "activationDate",
    //   width: "10%",
    // },
    // {
    //   title: t("uploadedBy"),
    //   field: "uploadedBy",
    //   width: "10%",
    // },
    // {
    //   title: t("uploadedFrom"),
    //   field: "profile.source",
    //   width: "10%",
    // },
  ];

  let [currentPage, setCurrentPage] = useState(0);
  const userPerPage = pageSize;
  const begin = React.useRef(0);
  const end = React.useRef(userPerPage);

  const count = Math.ceil(itemCount / userPerPage);

  function next() {
    if (end.current > itemCount) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
    begin.current = (currentPage + 1) * userPerPage;
    end.current = begin.current + userPerPage;
    if (keyword.current != "") handleSearch(begin.current, keyword.current);
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * userPerPage;
    end.current = begin.current + userPerPage;
    if (keyword.current != "") handleSearch(begin.current, keyword.current);
  }

  const handleChange = (value) => {
    setPageSize(parseInt(value));
    end.current = begin.current + parseInt(value);
  };

  const fetchData = async () => {
    if (resetSearch.current == false) setLoading(true);
    await roleService.GetRoleByLanguageAsync(i18n.language).then((x) => {
      setRoles(x.filter((x) => x.description != AccountTypes.Provider));
    });
    const model = {
      emailLogsToSkip: begin.current,
      startingEmailLogs: pageSize,
    };
    await adminService.GetAllUsersAsync(model).then((x) => {
      var list = [];
      list = x;
      for (var i = 0; i < list.length; i++) {
        if (list[i].profile.spType === "Start Up") {
          list[i].profile.spType = t("signUp.providerType.startup");
        }
      }
      setUsers(list);
    });

    if (resetSearch.current == false) setLoading(false);
  };

  const fetchUsersByRole = async () => {
    Promise.all([
      await adminService.GetUsersCountByRoleAsync("Seeker"),
      await adminService.GetUsersCountByRoleAsync("Provider"),
      await adminService.GetUsersCountByRoleAsync("Partner"),
    ])
      .then((data) => {
        setSSCount(data[0]);
        setSPCount(data[1]);
        setPOCount(data[2]);
      })
      .finally(() => setLoading(false));
  };

  function handleRemove(id) {
    const newList = users.filter((item) => item.profile.userId !== id);
    setUsers(newList);
  }

  async function deleteSelectedItem(id) {
    await adminService
      .DeleteUserAsync(id)
      .then(() => {
        handleRemove(id);
        setDeleteAlert(!deleteAlert);
      })
      .catch((error) => {
        setDeleteAlert(!deleteAlert);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }
  const fetchCount = async () => {
    await adminService.GetAllUsersCountAsync().then((res) => {
      setItemCount(res);
    });
  };

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  React.useEffect(() => {
    fetchUsersByRole();
    if (keyword.current == "") {
      fetchData();
    }
  }, [end.current, begin.current, pageSize, keyword.current]);

  React.useEffect(() => {
    fetchCount();
  }, []);

  const handleSearch = (skip, e) => {
    //setSearchKeyword(e);
    keyword.current = e;
    const model = {
      Keyword: e,
      UserPerforminSearchId: user.userId,
      PageNumber: skip,
      PageSize: pageSize,
    };
    const searchFunction = async () => {
      await searchService.SearchUsersAsync(model).then((res) => {
        if (skip == 0 || e == "") {
          begin.current = 0;
          end.current = pageSize;
        }
        // else begin.current = res.length;
        // end.current = pageSize;
        // pageSize = 5;
        // users = res;
        // setPageSize(pageSize);
        setUsers(res);
      });
      await searchService.SearchUsersCountAsync(model).then((res) => {
        // itemCount = res;
        setItemCount(res);
      });
    };
    // setTimeout(search, 1000);
    searchFunction();
  };

  const TableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",

          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            width: "100%",
            columnGap: "10rem",
          }}
        >
          <div
            style={{
              color: "#0e6eb8",
              fontSize: "1.25rem",
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
              fontWeight: 500,
              letterSpacing: "0.0075em",
              textTransform: "none",
            }}
          >
            {t("userList")}
          </div>
          {/* {!loading && (
          <section
            style={{
              fontFamily: `-apple-system, sans-serif`,
              fontSize: "17px",
              padding: "0 4rem",
              display: "flex",
              fontWeight: 500,
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: "2.5rem",
              color: "#0e6eb8",
            }}
          >
            <span
              style={{
                // borderRight: "1px solid #0e6eb8",

                paddingRight: "2rem",
              }}
            >
              SP: {SPTotalCount}
            </span>
            <span
              style={{
                // borderRight: "1px solid #0e6eb8",
                paddingRight: "2rem",
              }}
            >
              SS: {SSTotalCount}
            </span>
            <span>PO: {POTotalCount}</span>
          </section>
        )} */}
        </div>
      </div>
    );
  };

  // if (loading) {
  //   return (
  //     <>
  //       <center
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           position: "absolute",
  //           left: "50%",
  //           top: "50%",
  //         }}
  //       >
  //         <PulseLoader color="#0E6EB8" />
  //       </center>
  //     </>
  //   );
  // }
  return (
    <>
      <div className="section">
        <Container>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={{ margin: "auto" }}
                onClick={() => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("delete.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{t("delete.alert.body")}</p>
            </div>

            <div
              className="modal-footer"
              style={{ justifyContent: "flex-end" }}
            >
              <center>
                <Button
                  type="button"
                  className="mr-2"
                  style={{ margin: "auto" }}
                  color="primary"
                  onClick={() => setDeleteAlert(!deleteAlert)}
                >
                  {t("cancel.link")}
                </Button>
                <Button
                  color="danger"
                  type="button"
                  className="mr-2"
                  style={{ margin: "auto" }}
                  onClick={() => deleteSelectedItem(rowDataID)}
                >
                  {t("delete")}
                </Button>
              </center>
            </div>
          </Modal>
          <Modal
            isOpen={surveyAlert}
            toggle={() => setSurveyAlert(!surveyAlert)}
          >
            {/* <ModalHeader
              toggle={() => setSurveyAlert(!surveyAlert)}
            ></ModalHeader> */}
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <FormGroup className="col-md-12">
                <label htmlFor="survey">{t("survey.select")}</label>
                <select
                  style={{
                    fontSize: "14px",
                  }}
                  name="survey"
                  placeholder={t("survey.select")}
                  className="form-control"
                  onChange={(e) => {
                    setSelectedSurvey(e.target.value);
                  }}
                >
                  <option value={null}>{null}</option>
                  {surveys.length > 0 &&
                    surveys.map((survey) => (
                      <option value={survey.surveyId}>
                        {survey.surveys.surveyTitle}
                      </option>
                    ))}
                </select>
              </FormGroup>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={{ margin: "auto" }}
                onClick={async () => {
                  console.log(selectedSurvey);
                  console.log(rowDataID);
                  if (selectedSurvey != null) {
                    await adminService
                      .ManuallySendSurvey(rowDataID, selectedSurvey)
                      .then((x) => {
                        setSurveys(x);
                        setSurveyAlert(!surveyAlert);
                        setSuccessAlert(!successAlert);
                        setSelectedSurvey(null);
                      })
                      .catch((error) => {
                        if (error.message !== undefined) {
                          setError(error.message);
                          if (error.error !== null && error.error.length > 0) {
                            for (var i = 0; i < error.error.length; i++) {
                              if (error.error[i].languageName == currentLan) {
                                setError(error.error[i].error);
                              }
                            }
                          }
                        } else {
                          setError(error);
                        }
                        setErrorAlert(!errorAlert);
                      });
                    setSurveyAlert(!surveyAlert);
                  }
                }}
              >
                {t("survey.send")}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={accountTypeAlert}
            toggle={() => setAccountTypeAlert(!accountTypeAlert)}
          >
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <FormGroup className="col-md-12">
                <label htmlFor="survey">{t("signUp.profileType")}</label>
                <select
                  style={{
                    fontSize: "14px",
                  }}
                  name="survey"
                  placeholder={t("signUp.profileType.placeholder")}
                  className="form-control"
                  value={accountTypeID}
                  onChange={(e) => {
                    setAccountTypeID(e.target.value);
                  }}
                >
                  <option value={null}>{null}</option>
                  {roles.length > 0 &&
                    roles.map((role) => (
                      <option value={role.roleId}>{role.description}</option>
                    ))}
                </select>
              </FormGroup>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={{ margin: "auto" }}
                onClick={async () => {
                  setIsUpdating(true);
                  const model = {
                    UserId: rowDataID,
                    AccountTypeID: accountTypeID,
                  };
                  await profileService
                    .UpdateAccountTypeAsync(model)
                    .then((res) => {
                      setIsUpdating(false);
                      history.push(
                        `/admin/users/editProfile/${rowDataID}?fromAdmin=true`
                      );
                    })
                    .catch((error) => {
                      setIsUpdating(false);
                      if (error.message !== undefined) {
                        setError(error.message);
                        if (error.error !== null && error.error.length > 0) {
                          for (var i = 0; i < error.error.length; i++) {
                            if (error.error[i].languageName == currentLan) {
                              setError(error.error[i].error);
                            }
                          }
                        }
                      } else {
                        setError(error);
                      }
                      setErrorAlert(!errorAlert);
                    });
                  setAccountTypeAlert(!accountTypeAlert);
                }}
              >
                {isUpdating && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                {t("udpate.account.type")}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={successAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{t("manuallySendSurvey.success")}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={{ margin: "auto" }}
                onClick={() => setSuccessAlert(!successAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={zeroSurveysAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            ></div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{t("manuallySendSurvey.noSurveys")}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={{ margin: "auto" }}
                onClick={() => setZeroSurveysAlert(!zeroSurveysAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>

          <Row
            style={{
              margin: "auto",
              marginBottom: "1rem",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              borderRadius: "15px",
            }}
          >
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRight: "1px solid #eee",
                paddingBottom: "0.5rem",
                paddingTop: "0.5rem",
              }}
            >
              <Row
                style={{
                  fontSize: "x-large",
                  color: " rgb(14, 110, 184)",
                  fontWeight: 900,
                }}
              >
                {SPTotalCount != null ? SPTotalCount.profileCount : 0}
              </Row>
              <Row style={{ fontSize: "small" }}>{t("SP")}</Row>
              <Row style={{ fontSize: "10px", color: "rgb(113, 144, 168)" }}>
                <i>
                  {SPTotalCount != null ? SPTotalCount.active : 0}{" "}
                  {t("active.users")}
                </i>
              </Row>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRight: "1px solid #eee",
                paddingBottom: "0.5rem",
                paddingTop: "0.5rem",
              }}
            >
              <Row
                style={{
                  fontSize: "x-large",
                  color: " rgb(14, 110, 184)",
                  fontWeight: 900,
                }}
              >
                {SSTotalCount != null ? SSTotalCount.profileCount : 0}
              </Row>
              <Row style={{ fontSize: "small" }}>{t("SS")}</Row>
              <Row style={{ fontSize: "10px", color: "rgb(113, 144, 168)" }}>
                <i>
                  {SSTotalCount != null ? SSTotalCount.active : 0}{" "}
                  {t("active.users")}
                </i>
              </Row>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingBottom: "0.5rem",
                paddingTop: "0.5rem",
              }}
            >
              <Row
                style={{
                  fontSize: "x-large",
                  color: " rgb(14, 110, 184)",
                  fontWeight: 900,
                }}
              >
                {POTotalCount != null ? POTotalCount.profileCount : 0}
              </Row>
              <Row style={{ fontSize: "small" }}>{t("PO")}</Row>
              <Row style={{ fontSize: "10px", color: "rgb(113, 144, 168)" }}>
                <i>
                  {POTotalCount != null ? POTotalCount.active : 0}{" "}
                  {t("active.users")}
                </i>
              </Row>
            </Col>
          </Row>
          {/* <hr style={{width:"40%"}}></hr> */}
          <Row>
            {loading && (
              <center
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  left: "50%",
                  top: "100%",
                }}
              >
                <PulseLoader color="#0E6EB8" />
              </center>
            )}
            <Col className={"col"}>
              {!loading && (
                <>
                  <ThemeProvider theme={theme}>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",

                        width: "100%",
                        columnGap: "10rem",
                      }}
                    >
                      <div
                        style={{
                          color: "#0e6eb8",
                          fontSize: "1.25rem",
                          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                          fontWeight: 500,
                          letterSpacing: "0.0075em",
                          textTransform: "none",
                          marginLeft: "15px",
                        }}
                      >
                        {t("userList")}
                      </div>
                      <FormControl sx={{ m: 1 }} variant="standard">
                        <Input
                          id="searchInput"
                          type="text"
                          placeholder={t("search")}
                          onChange={(e) =>
                            handleSearch(0, e.currentTarget.value)
                          }
                          startAdornment={
                            <InputAdornment position="start">
                              <IconButton aria-label="toggle password visibility">
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e) => {
                                  const searchInput =
                                    document.getElementById("searchInput");
                                  searchInput.value = "";
                                  resetSearch.current = true;
                                  keyword.current = "";
                                  begin.current = 0;
                                  end.current = pageSize;
                                  fetchData();
                                  fetchCount();
                                }}
                                // onMouseDown={handleMouseDownPassword}
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Row>
                    <MaterialTable
                      components={{
                        Container: (props) => (
                          <Paper
                            {...props}
                            className="usersTable"
                            elevation={0}
                          />
                        ),
                      }}
                      // title={<TableHeader style={{ width: "100%" }} />}
                      title={<span></span>}
                      columns={columns}
                      data={users}
                      icons={tableIcons}
                      onSearchChange={(e) => handleSearch(e)}
                      options={{
                        // pageSize: pageSize,
                        search: false,
                        paging: false,
                        actionsColumnIndex: -1,
                        cellStyle: {
                          textAlign: rtl ? "right" : "left",
                        },
                        headerStyle: {
                          textAlign: rtl ? "right" : "left",
                        },
                      }}
                      localization={{
                        header: {
                          actions: t("actions"),
                        },
                        toolbar: {
                          searchPlaceholder: t("search"),
                        },
                      }}
                      detailPanel={(rowData) => {
                        return (
                          <>
                            <Row style={{ marginTop: "10px" }}>
                              <Col md="3">
                                <FormGroup className="col-md-12">
                                  <div>
                                    <label
                                      className="col-md-12"
                                      htmlFor="companyName"
                                      style={{
                                        fontWeight: "bold",
                                        padding: "0px",
                                      }}
                                    >
                                      {t("expirationDate")}
                                    </label>
                                    <div
                                      className="col-md-12"
                                      style={{
                                        //   display: "grid",
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                      }}
                                    >
                                      <label
                                      // className="col-md-12"
                                      >
                                        {rowData.expirationDate}
                                      </label>
                                    </div>
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="col-md-12">
                                  <div>
                                    <label
                                      className="col-md-12"
                                      htmlFor="companyName"
                                      style={{
                                        fontWeight: "bold",
                                        padding: "0px",
                                      }}
                                    >
                                      {t("admin.users.activationDate")}
                                    </label>
                                    <div
                                      className="col-md-12"
                                      style={{
                                        //   display: "grid",
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                      }}
                                    >
                                      <label
                                      // className="col-md-12"
                                      >
                                        {rowData.activationDate}
                                      </label>
                                    </div>
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="col-md-12">
                                  <div>
                                    <label
                                      className="col-md-12"
                                      htmlFor="companyName"
                                      style={{
                                        fontWeight: "bold",
                                        padding: "0px",
                                      }}
                                    >
                                      {t("uploadedBy")}
                                    </label>
                                    <div
                                      className="col-md-12"
                                      style={{
                                        //   display: "grid",
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                      }}
                                    >
                                      <label
                                      // className="col-md-12"
                                      >
                                        {rowData.uploadedBy}
                                      </label>
                                    </div>
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="col-md-12">
                                  <div>
                                    <label
                                      className="col-md-12"
                                      htmlFor="companyName"
                                      style={{
                                        fontWeight: "bold",
                                        padding: "0px",
                                      }}
                                    >
                                      {t("uploadedFrom")}
                                    </label>
                                    <div
                                      className="col-md-12"
                                      style={{
                                        //   display: "grid",
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                      }}
                                    >
                                      <label
                                      // className="col-md-12"
                                      >
                                        {rowData.profile.source}
                                      </label>
                                    </div>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        );
                      }}
                      actions={[
                        (rowData) => ({
                          icon: () => (
                            <SyncAltIcon
                              className="actionIcon"
                              style={{ color: "#0E6EB8" }}
                            />
                          ),
                          tooltip: t("change.account.type"),
                          disabled:
                            rowData.profileTypeView.profileTypeDescription ==
                            AccountTypes.Provider
                              ? true
                              : false,
                          onClick: async (event, rowData) => {
                            setRowDataID(rowData.profile.userId);
                            setAccountTypeID(rowData.profile.accountTypeID);
                            setAccountTypeAlert(true);
                            console.log(rowData);
                          },
                        }),
                        {
                          icon: () => (
                            <BarChartIcon
                              className="actionIcon"
                              style={{ color: "#0E6EB8" }}
                            />
                          ),
                          tooltip: t("manually.send.survey"),
                          onClick: async (event, rowData) => {
                            setRowDataID(rowData.profile.userId);
                            await surveyService
                              .GetSurveysByType(
                                "ManuallySendSurvey",
                                rowData.profileTypeView.profileTypeDescription
                              )
                              .then((x) => {
                                setSurveys(x);
                                if (x.length == 0) {
                                  setZeroSurveysAlert(!zeroSurveysAlert);
                                } else {
                                  setSurveyAlert(!surveyAlert);
                                }
                              })
                              .catch((error) => {
                                if (error.message !== undefined) {
                                  setError(error.message);
                                  if (
                                    error.error !== null &&
                                    error.error.length > 0
                                  ) {
                                    for (
                                      var i = 0;
                                      i < error.error.length;
                                      i++
                                    ) {
                                      if (
                                        error.error[i].languageName ==
                                        currentLan
                                      ) {
                                        setError(error.error[i].error);
                                      }
                                    }
                                  }
                                } else {
                                  setError(error);
                                }
                                setErrorAlert(!errorAlert);
                              });
                          },
                        },
                        {
                          icon: () => (
                            <AccountBalanceIcon
                              className="actionIcon"
                              style={{ color: "#0E6EB8" }}
                            />
                          ),
                          tooltip: t("viewUser"),
                          onClick: (event, rowData) => {
                            if (rowData.profile.profileStatus !== "Active") {
                              setError(t("userNotVerified"));
                              setErrorAlert(!errorAlert);
                            } else if (
                              rowData.profile.profileStatus === "Verified" &&
                              rowData.profile.poProfileID !== null
                            ) {
                              const win = window.open(
                                `${path}/viewProfile/${rowData.profile.userId}?showChat=false&fromAdmin=true`,
                                "_blank"
                              );
                              win.focus();
                            } else {
                              const win = window.open(
                                `${path}/viewProfile/${rowData.profile.userId}?showChat=false&fromAdmin=true`,
                                "_blank"
                              );
                              win.focus();
                            }
                          },
                        },
                        (rowData) => ({
                          icon: () => (
                            <FileCopyIcon
                              className="actionIcon"
                              style={{ color: "#0E6EB8" }}
                            />
                          ),
                          tooltip: t("viewOpportunities"),
                          onClick: (event, rowData) => {
                            if (
                              rowData.profileTypeView.profileTypeDescription ===
                                AccountTypes.Seeker ||
                              rowData.profileTypeView.profileTypeDescription ===
                                AccountTypes.Partner
                            ) {
                              const win = window.open(
                                `${path}/viewOpportunities/${rowData.profile.userId}`,
                                "_blank"
                              );
                              win.focus();
                              // history.push(
                              //   `${path}/viewOpportunities/${rowData.profile.userId}`
                              // );
                            } else {
                              setError(t("viewOpportunitiesError"));
                              setErrorAlert(!errorAlert);
                            }
                          },
                        }),
                        (rowData) => ({
                          icon: () => (
                            <FormatListBulletedIcon
                              className="actionIcon"
                              style={{ color: "#0E6EB8" }}
                            />
                          ),
                          tooltip: t("UserLogs"),
                          onClick: (event, rowData) => {
                            //   history.push(`${path}/user-logs/${rowData.profile.userId}`);

                            const win = window.open(
                              `${path}/user-logs/${rowData.profile.userId}`,
                              "_blank"
                            );
                            win.focus();
                          },
                        }),
                        {
                          icon: () => (
                            <Delete
                              style={{ fill: "#FF3636" }}
                              className="actionIcon"
                            />
                          ),
                          tooltip: t("delete"),
                          onClick: (event, rowData) => {
                            setRowDataID(rowData.profile.userId);
                            setDeleteAlert(!deleteAlert);
                          },
                        },
                      ]}
                    />
                  </ThemeProvider>
                  <div className="d-flex justify-content-end align-items-center">
                    <select
                      value={pageSize}
                      onChange={(e) => handleChange(e.target.value)}
                      style={{
                        height: "36px",
                        borderRadius: 0,
                        width: "auto",
                        fontSize: "12px",
                      }}
                      className={"form-control"}
                    >
                      <option
                        key={5}
                        value={5}
                        style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                      >
                        5
                      </option>
                      <option
                        key={10}
                        value={10}
                        style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                      >
                        10
                      </option>
                      <option
                        key={15}
                        value={15}
                        style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                      >
                        15
                      </option>
                      <option
                        key={20}
                        value={20}
                        style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                      >
                        20
                      </option>
                      <option
                        key={50}
                        value={50}
                        style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                      >
                        50
                      </option>
                      <option
                        key={100}
                        value={100}
                        style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                      >
                        100
                      </option>
                    </select>
                    <IconButton
                      aria-label="next"
                      disabled={currentPage == 0 ? true : false}
                    >
                      {rtl ? (
                        <ArrowForwardIosSharpIcon
                          onClick={() => prev()}
                          style={{
                            color: "#d9dcde",
                          }}
                        />
                      ) : (
                        <ArrowBackIosSharpIcon
                          onClick={() => prev()}
                          style={{
                            color: "#d9dcde",
                          }}
                        />
                      )}
                    </IconButton>
                    <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
                      {begin.current} -{" "}
                      {end.current > itemCount ? itemCount : end.current}{" "}
                      {rtl ? "من" : "of"} {itemCount}
                    </p>
                    <IconButton
                      aria-label="next"
                      disabled={end.current >= itemCount ? true : false}
                    >
                      {rtl ? (
                        <ArrowBackIosSharpIcon
                          onClick={() => next()}
                          style={{
                            color: "#d9dcde",
                          }}
                        />
                      ) : (
                        <ArrowForwardIosSharpIcon
                          onClick={() => next()}
                          style={{
                            color: "#d9dcde",
                          }}
                        />
                      )}
                    </IconButton>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ManagerUsers;
