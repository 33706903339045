export const config = {
  // rollbackUrl: "https://outerpond.ixia.tech:2087",
  // apiUrl: "https://outerpond.ixia.tech:2087/backend",
  // rollbackUrl: "http://localhost:3000",
  apiUrl: "https://www.outerpond.com/backend",
  gatewayName: "Stripe",
  publicKey:
    // "pk_test_51N4N1lBwzm2BxElt7LrCxbtigIfhh055t9QQH5mQ77yc6Xc1BmykkjRHBeIRTMK0dMmbrqwnU5Soht1mvqyxivAP00eD3FS40T",
    "pk_test_51MoMvSJj9xghuRDw6fhLmpMu3sexOzZnr5grsZLyP5FtAI1Kky4OaAs7eONVplYc0PhYXt06cATUOCVzw3n6jjsd005XhLlzGJ",
};
