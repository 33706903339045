import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import LanguageDropdown from "./LanguageDropdown";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../_context";

function LandingPageNavbar2({ props }) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const { t, i18n } = useTranslation();
  const [currentLan, setCurrentLan] = React.useContext(LanguageContext);
  const rtl = i18n.dir() == "rtl";

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={"fixed-top shadow-none"}
        style={{
          paddingLeft: "70px",
          paddingRight: "70px",
          boxShadow: "none !important",
        }}
        expand="lg"
        color="white"
      >
        <div className="d-flex flex-nowrap justify-content-between align-items-center w-100 mr-4">
          <div className="navbar-translate" style={{ display: "unset" }}>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
            <NavbarBrand
              //   href="https://demos.creative-tim.com/now-ui-kit-react/#/index?ref=nukr-index-navbar"
              //   target="_blank"
              id="navbar-brand"
              href="/"
              className="pl-5"
              //style={{ paddingLeft: "10px", paddingRight: "10px" }}
              style={{
                width: "193px",
                height: "48px",
              }}
            >
              {/* Now UI Kit React */}
              <img
                // src={require("assets/img/outerpondlogo-resized.jpg").default}
                src={require("assets/img/Outerpond-Logo.png").default}
                // src={
                //   require("assets/img/Outerpond-Logo-Final-Cropped-2-inches.png")
                //     .default
                // }
                //  src={require("assets/img/Outerpond_Logo_Final1.svg").default}
                //src={require("assets/img/happier-logo.svg").default}
                alt=""
                // width="40%"
                // height="40%"
                //height="35px"
                //   width="100px"
              />
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Outerpond
            </UncontrolledTooltip>
          </div>
          {/* <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          > */}
          <Nav
            className={rtl ? "ml-5" : "ml-auto mr-5"}
            navbar
            style={{ flexDirection: "row" }}
          >
            <NavItem>
              <NavLink
                style={{
                  color: "#4B4B4B",
                  marginRight: "20px",
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  fontFamily: "SpaceGroteskBold",
                }}
                onClick={() => {
                  props.push("/account/login");
                }}
                tag={Link}
              >
                {t("navigation.login")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                //      href="/account/register"
                style={{
                  color: "#ffffff",
                  borderRadius: "0.1875rem",
                  backgroundColor: "#316CB3",
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  fontFamily: "SpaceGroteskMedium",
                }}
                onClick={() => {
                  props.push("/account/register");
                }}
                tag={Link}
              >
                {" "}
                {t("navigation.register")}
              </NavLink>
            </NavItem>

            <LanguageDropdown changeLanguage={(lan) => setCurrentLan(lan)} />
          </Nav>
          {/* </Collapse> */}
        </div>
      </Navbar>
    </>
  );
}

export default LandingPageNavbar2;
