import React, { useState, useContext } from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import { Paper, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../theme.js";
import Modal from "reactstrap/lib/Modal";
import { systemSetupService } from "_services/systemSetup.service.js";
import { paymentService } from "_services/payment.service.js";
import { Link } from "react-router-dom";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import { Row, Col } from "reactstrap";
import { config } from "_helpers/config";
import { LanguageContext } from "_context";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
};

function VariablesList({ history, match, location }) {
  const { path } = match;
  const [variables, setVariables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [iconPills, setIconPills] = useState("1");
  const [systemVariables, setSystemVariables] = useState(true);
  const [stripeVariables, setStripeVariables] = useState(false);
  const [editable, setEditable] = useState(true);
  const [appGateways, setAppGateways] = useState([]);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [initialValues, setInitialValues] = useState({
    publicKey: "",
    secretKey: "",
  });

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  var columns = [
    {
      title: t("variables"),
      field: "displayName",
    },
    {
      title: t("variable.value"),
      field: "valueToBeDisplayed",
    },
  ];
  const fetchData = async () => {
    setLoading(true);
    if (stripeVariables == false) {
      await systemSetupService.GetAllSystemSetupAsync().then((x) => {
        if (systemVariables == true) {
          const list = x.filter((item) => item.systemVariable === true);
          list.forEach((element) => {
            if (element.valueToBeDisplayed.length > 50) {
              element.valueToBeDisplayed =
                element.valueToBeDisplayed.substring(0, 50) + "...";
            }
          });
          setVariables(list);
        } else {
          const list = x.filter((item) => item.surveyThreshold === true);
          setVariables(list);
        }
      });
    } else {
      await paymentService
        .GetGatewayKeysAsync(config.gatewayName)
        .then((res) => {
          console.log(res);
          editInitialForm(res);
        });
    }
    setLoading(false);
  };

  function editInitialForm(variable) {
    var _fetchedVariable = {
      publicKey: variable.publishableKey,
      secretKey: variable.secretKey,
    };
    setInitialValues(_fetchedVariable);
  }

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [systemVariables]);

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    const model = {
      GatewayName: config.gatewayName,
      PublicKey: fields.publicKey,
      Token: fields.secretKey,
    };
    await paymentService
      .UpdateGatewayKeysAsync(model)
      .then((res) => {
        setSuccessAlert(true);
        setEditable(true);
        fetchData();
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <div className="section">
        <Container>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={{ margin: "auto" }}
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={successAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("done")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{t("stripe.keys.success")}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={{ margin: "auto" }}
                onClick={(e) => setSuccessAlert(!successAlert)}
              >
                {t("done")}
              </Button>
            </div>
          </Modal>
          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                tag={Link}
                className={iconPills === "1" ? "active" : ""}
                style={{ boxShadow: "none" }}
                // href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  // await fetchAlertData();
                  setSystemVariables(true);
                  setStripeVariables(false);
                  setIconPills("1");
                }}
              >
                {t("general.systemVariables")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                className={iconPills === "2" ? "active" : ""}
                style={{ boxShadow: "none" }}
                //  href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  // await fetchNewslettersData();
                  setSystemVariables(false);
                  setStripeVariables(false);
                  setIconPills("2");
                }}
              >
                {t("surveys.systemVariables")}
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                tag={Link}
                className={iconPills === "3" ? "active" : ""}
                style={{ boxShadow: "none" }}
                //  href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  // await fetchNewslettersData();
                  setSystemVariables(false);
                  setStripeVariables(true);
                  setIconPills("3");
                }}
              >
                {t("stripe.systemVariables")}
              </NavLink>
            </NavItem> */}
          </Nav>
          <ThemeProvider theme={theme}>
            {/* {stripeVariables == false ? ( */}
            <MaterialTable
              //  style={{ backgroundColor: "#2C2C34", color: "#d9dcde" }}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              title={t("variablesList")}
              columns={columns}
              data={variables}
              icons={tableIcons}
              options={{
                paging: false,
                //    grouping: true,
                actionsColumnIndex: -1,
                cellStyle: {
                  textAlign: rtl ? "right" : "left",
                },
                headerStyle: {
                  textAlign: rtl ? "right" : "left",
                },
              }}
              localization={{
                header: {
                  actions: t("actions"),
                },
                toolbar: {
                  searchPlaceholder: t("search"),
                },
              }}
              actions={[
                {
                  icon: () => (
                    <Edit
                      className="actionIcon"
                      //   style={{
                      //     color: "#d9dcde",
                      //   }}
                    />
                  ),
                  tooltip: t("edit"),
                  //isFreeAction: true,
                  onClick: (event, rowData) => {
                    history.push(`${path}/edit/${rowData.id}`, {
                      state: rowData,
                    });
                  },
                },
              ]}
            />
            {/* ) : (
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                // validationSchema={validationSchema}
              >
                {({ setFieldValue, errors, touched, isSubmitting }) => {
                  return (
                    <Col md="8" style={{ margin: "auto" }}>
                      <Card className={rtl ? "text-right" : ""}>
                        <Form autoComplete="off">
                          <CardBody>
                            <CardTitle
                              className="text-center"
                              tag="h4"
                            ></CardTitle>
                            <Row>
                              <Col md="12">
                                <div className="form-group">
                                  <FormGroup
                                    className="col-md-8"
                                    style={{
                                      margin: "0 auto",
                                      width: "100%",
                                      padding: "10px",
                                      marginTop: "-2%",
                                    }}
                                  >
                                    <label htmlFor="inputPassword4">
                                      {t("public.key")}
                                    </label>
                                    <Field
                                      required={true}
                                      autoFocus={true}
                                      name="publicKey"
                                      disabled={editable}
                                      placeholder={t("public.key")}
                                      className={"form-control"}
                                    />
                                  </FormGroup>
                                </div>
                                <div className="form-group">
                                  <FormGroup
                                    className="col-md-8"
                                    style={{
                                      margin: "0 auto",
                                      width: "100%",
                                      padding: "10px",
                                      marginTop: "-2%",
                                    }}
                                  >
                                    <label htmlFor="inputPassword4">
                                      {t("secret.key")}
                                    </label>
                                    <Field
                                      required={true}
                                      autoFocus={true}
                                      name="secretKey"
                                      disabled={editable}
                                      placeholder={t("secret.key")}
                                      className={"form-control"}
                                    />
                                  </FormGroup>
                                </div>
                                <div className="form-row">
                                  <FormGroup
                                    className="col-md-8"
                                    style={{
                                      margin: "0 auto",
                                      width: "100%",
                                      padding: "10px",
                                      marginTop: "-2%",
                                    }}
                                  >
                                    <center>
                                      {editable == true ? (
                                        <Button
                                          color="primary"
                                          type="button"
                                          disable={
                                            editable == false ? true : false
                                          }
                                          onClick={(event) => {
                                            event.preventDefault();
                                            setEditable(false);
                                          }}
                                        >
                                          {t("edit.button")}
                                        </Button>
                                      ) : (
                                        <Button
                                          color="primary"
                                          type="submit"
                                          disabled={isSubmitting}
                                        >
                                          {isSubmitting && (
                                            <span className="spinner-border spinner-border-sm mr-1"></span>
                                          )}
                                          {t("createCompanyProfile.save")}
                                        </Button>
                                      )}
                                    </center>
                                  </FormGroup>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Form>
                      </Card>
                    </Col>
                  );
                }}
              </Formik>
            )} */}
          </ThemeProvider>
        </Container>
      </div>
    </>
  );
}

export default VariablesList;
