import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/newsletters`;

export const newslettersService = {
  GetAllAsync,
  GetNewsletterAsync,
  DeleteNewsletterAsync,
  AddNewslettersAsync,
  UploadImage,
  ResendNewsletter,
  UpdateNewslettersAsync,
  GetNewslettersCountAsync,
};

function GetAllAsync(params) {
  return fetchWrapper.post(`${baseUrl}/GetAllNewsletters`, params);
}

function GetNewslettersCountAsync() {
  return fetchWrapper.get(`${baseUrl}/GetNewslettersCount`);
}

function GetNewsletterAsync(id) {
  return fetchWrapper.get(`${baseUrl}/getOneNewsletter/${id}`);
}

function DeleteNewsletterAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function AddNewslettersAsync(flag, model) {
  return fetchWrapper.post(`${baseUrl}/${flag}`, model);
}

function UpdateNewslettersAsync(id, model) {
  return fetchWrapper.put(`${baseUrl}/${id}`, model);
}

function UploadImage(model) {
  return fetchWrapper.postFormData(baseUrl + "/imageUpload", model, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

function ResendNewsletter(id, flag) {
  return fetchWrapper.post(`${baseUrl}/resend/${id}/${flag}`);
}
