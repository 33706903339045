import { ResponsiveLine } from "@nivo/line";
import { analyticsService } from "_services";
import PulseLoader from "react-spinners/PulseLoader";
import React, { useState, useContext } from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { YearPicker } from "react-dropdown-date";

import "../../../../assets/css/generalStyles.css";
function LineChart() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(2021);
  const fetchData = async (year) => {
    setLoading(true);
    await analyticsService.GetProjectsPublishedByMonthAsync(year).then((x) => {
      setData(x);
    });

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData(selectedYear);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <div className="section">
        <Container>
          <div>
            <h6 className="text-primary">
              {" "}
              {t("ProjectsPublished-LineChart")}
            </h6>
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <label className="text-primary">
                {t("Analytics.SelectYear")}
              </label>
              <div>
                <YearPicker
                  //defaultValue={2021}
                  start={2021} // default is 1900
                  end={2030} // default is current year
                  style={{
                    fontSize: "14px",
                  }}
                  //reverse // default is ASCENDING
                  //required={true} // default is false
                  //  disabled={true} // default is false
                  value={selectedYear} // mandatory
                  onChange={(year) => {
                    // mandatory
                    setSelectedYear(year);
                    fetchData(year);
                  }}
                  id={"year"}
                  name={"year"}
                  classes={"classes"}
                  optionClasses={"option classes"}
                />
              </div>
            </div>
            <div style={{ height: "500px" }}>
              <ResponsiveLine
                data={data}
                margin={{ top: 50, right: 110, bottom: 200, left: 60 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: true,
                  reverse: false,
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Month",
                  legendOffset: 36,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Published Count",
                  legendOffset: -40,
                  legendPosition: "middle",
                  format: (e) => e % 5 === 0 && e,
                }}
                pointSize={10}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                  {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default LineChart;
