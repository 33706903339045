import React, { useContext, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import CardBody from "reactstrap/lib/CardBody";
import "../parsing.css";
import CardHeader from "reactstrap/lib/CardHeader";
import Card from "reactstrap/lib/Card";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import { Link } from "react-router-dom";
import { AccountTypes } from "_helpers";
import { profileService, adminService } from "_services";
import SeekerNavbar from "components/Navbars/SeekerNavbar";
import ProviderNavbar from "components/Navbars/ProviderNavbar";
import PartnerNavbar from "components/Navbars/PartnerNavbar";
import { ProfileStatus } from "_helpers";
import LoggedInUserNavbar from "components/Navbars/LoggedInUserNavbar";
import FooterSubPagesNavbar from "components/Navbars/FooterSubPagesNavbar";
import LandingPageNavbar2 from "components/Navbars/LandingPageNavbar2";
import LandingPageNavbarMobileTablet2 from "components/Navbars/LandingPageNavbarMobileTablet2";
import AdminNavbar from "components/Navbars/AdminNavbar";
import PartnerNavbarMobileTablet from "components/Navbars/PartnerNavbarMobileTablet";
import AdminNavbarMobileTablet from "components/Navbars/AdminNavbarMobileTablet";
import ProviderNavbarMobileTablet from "components/Navbars/ProviderNavbarMobileTablet";
import SeekerNavbarMobileTablet from "components/Navbars/SeekerNavbarMobileTablet";
import { useMediaQuery } from "react-responsive";
import { systemSetupService } from "_services";
import { LanguageContext } from "_context";
import ReactHtmlParser from "react-html-parser";

function FooterSubPages({ history, match }) {
  const user = profileService.userValue;
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const iconPill = match.params.iconPill;
  const [loading, setLoading] = useState(true);
  const [iconPills, setIconPills] = React.useState(iconPill);
  const [texts, setTexts] = useState(null);
  const [challenge, setChallenge] = useState(null);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [currentLan, setCurrentLan] = useContext(LanguageContext);

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const fetchData = async () => {
    setLoading(true);
    await systemSetupService.GetAllFooterSubPagesAsync(currentLan).then((x) => {
      setTexts(x);
    });
    await adminService.GetChallengeAsync().then((x) => {
      setChallenge(x);
    });
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [currentLan]);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker &&
        isDesktopOrLaptop && <SeekerNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Seeker &&
        !isDesktopOrLaptop && <SeekerNavbarMobileTablet props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
        isDesktopOrLaptop && <ProviderNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Provider &&
        !isDesktopOrLaptop && <ProviderNavbarMobileTablet props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner &&
        isDesktopOrLaptop && <PartnerNavbar props={history} />}
      {user &&
        user.profile.profileStatus === ProfileStatus.Active &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Partner &&
        !isDesktopOrLaptop && <PartnerNavbarMobileTablet props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Admin &&
        isDesktopOrLaptop && <AdminNavbar props={history} />}
      {user &&
        user.profileTypeView !== null &&
        user.profileTypeView.profileTypeDescription === AccountTypes.Admin &&
        !isDesktopOrLaptop && <AdminNavbarMobileTablet props={history} />}
      {user && user.profile.profileStatus !== ProfileStatus.Active && (
        <LoggedInUserNavbar props={history} />
      )}
      {!user && isDesktopOrLaptop && <LandingPageNavbar2 props={history} />}
      {!user && !isDesktopOrLaptop && (
        <LandingPageNavbarMobileTablet2 props={history} />
      )}
      <div className="wrapper">
        <div className="section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto">
                <Card style={{ boxShadow: "none" }}>
                  <CardHeader>
                    <Nav className="justify-content-center" role="tablist" tabs>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          className={iconPills === "1" ? "active" : ""}
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("1");
                          }}
                        >
                          <i
                            className={
                              rtl
                                ? "now-ui-icons business_bulb-63 ml-2"
                                : "now-ui-icons business_bulb-63"
                            }
                          ></i>
                          {t("frequently.asked.questions")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          className={iconPills === "2" ? "active" : ""}
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("2");
                          }}
                        >
                          <i
                            className={
                              rtl
                                ? "now-ui-icons ui-1_settings-gear-63 ml-1"
                                : "now-ui-icons ui-1_settings-gear-63"
                            }
                          ></i>
                          {t("footer.terms")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          className={iconPills === "3" ? "active" : ""}
                          onClick={(e) => {
                            e.preventDefault();
                            setIconPills("3");
                          }}
                        >
                          <i
                            className={
                              rtl
                                ? "now-ui-icons business_globe ml-1"
                                : "now-ui-icons business_globe"
                            }
                          ></i>
                          {t("privacy.policy")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>
                  <CardBody>
                    <TabContent activeTab={"iconPills" + iconPills}>
                      <TabPane tabId="iconPills1">
                        <Row>
                          <Card>
                            <CardBody className="parsing">
                              {ReactHtmlParser(texts.faq)}
                            </CardBody>
                          </Card>
                        </Row>
                      </TabPane>
                      <TabPane tabId="iconPills2">
                        <Row>
                          <Card>
                            <CardBody className="parsing">
                              {ReactHtmlParser(texts.termsOfUse)}
                            </CardBody>
                          </Card>
                        </Row>
                      </TabPane>
                      <TabPane tabId="iconPills3">
                        <Row>
                          <Card>
                            <CardBody className="parsing">
                              {ReactHtmlParser(texts.privacyPolicy)}
                            </CardBody>
                          </Card>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Row
          className={
            isMobile
              ? "d-flex justify-content-start"
              : "d-flex justify-content-start"
          }
          style={{ backgroundColor: "#EEF5FB" }}
        >
          <Col className="col-md-4 col-sm-12 col-12">
            <Nav className="flex-row justify-content-start mt-3">
              {/* <NavItem>
                <a className="nav-link " href="#serviceProviders">
                  {t("featuredSP")}
                </a>
              </NavItem> */}
              <Col
                className={
                  isMobile
                    ? "col-md-12 col-sm-12 col-12 d-flex justify-content-center"
                    : "col-md-12 col-sm-12 col-12"
                }
              >
                {/* {user && (
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                      }
                      onClick={() => {
                        history.push("/plans");
                      }}
                      tag={Link}
                    >
                      {t("mainPage.plans")}
                    </NavLink>
                  </NavItem>
                )} */}
                <NavItem>
                  <NavLink
                    style={
                      rtl
                        ? {
                            color: "#4D4D4D",
                            fontFamily: "SpaceGrotesk",
                            textAlign: "justify",
                          }
                        : {
                            color: "#4D4D4D",
                            fontFamily: "SpaceGrotesk",
                          }
                    }
                    onClick={() => {
                      history.push("/ourteam");
                    }}
                    tag={Link}
                  >
                    {t("landingpage.ourteam")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={
                      rtl
                        ? {
                            color: "#4D4D4D",
                            fontFamily: "SpaceGrotesk",
                            textAlign: "justify",
                          }
                        : {
                            color: "#4D4D4D",
                            fontFamily: "SpaceGrotesk",
                          }
                    }
                    onClick={() => {
                      history.push("/contactUs");
                    }}
                    tag={Link}
                  >
                    {t("navbar.contactUs")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={
                      rtl
                        ? {
                            color: "#4D4D4D",
                            fontFamily: "SpaceGrotesk",
                            textAlign: "justify",
                          }
                        : { color: "#4D4D4D", fontFamily: "SpaceGrotesk" }
                    }
                    onClick={() => {
                      history.push("/videos");
                    }}
                    tag={Link}
                  >
                    {t("demo.videos")}
                  </NavLink>
                </NavItem>
                {challenge && challenge.isPublic && (
                  <NavItem>
                    <NavLink
                      style={
                        rtl
                          ? {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                              textAlign: "justify",
                            }
                          : {
                              color: "#4D4D4D",
                              fontFamily: "SpaceGrotesk",
                            }
                      }
                      onClick={() => {
                        history.push("/million-dollar-challenge");
                      }}
                      tag={Link}
                    >
                      {t("million.dollar")}
                    </NavLink>
                  </NavItem>
                )}
              </Col>
              {/* <Col
                      className={
                        isMobile
                          ? "col-md-6 col-sm-12 col-12 pb-3 d-flex justify-content-center"
                          : "col-md-6 col-sm-12 col-12"
                      }
                    >
                      
                    </Col> */}

              {/* <LanguageDropdown /> */}
            </Nav>
          </Col>
          <Col
            row
            className={
              isMobile
                ? "d-flex justify-content-center align-items-center col-md-4 col-sm-12 col-12 pt-4 pb-2"
                : "d-flex justify-content-end align-items-center col-md-4 col-sm-12 col-12"
            }
          >
            {" "}
            <Col
              className={
                isMobile
                  ? "d-flex justify-content-center align-items-center flex-column mt-0"
                  : "d-flex justify-content-center align-items-center flex-column mt-4"
              }
            >
              <Row>
                <p
                  style={{
                    color: "#4D4D4D",
                    fontSize: "14px",
                    fontFamily: "SpaceGroteskMedium",
                    textAlign: "left",
                    marginBottom: "0",
                    paddingBottom: "10px",
                  }}
                >
                  {t("mainPage.usaid")}
                </p>
              </Row>
              <Row>
                <img
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    width: "140px",
                  }}
                  src={require("assets/img/usaid_logo_vertical.png").default}
                  alt=""
                />
              </Row>
            </Col>
          </Col>
          {!isMobile ? (
            <Col
              className="col-md-4 col-sm-12 col-12 d-flex justify-content-end align-items-end"
              style={{ paddingBottom: "10px" }}
            >
              <Row
                className={"d-flex justify-content-end align-items-center py-1"}
                style={{ backgroundColor: "#EEF5FB" }}
              >
                <p
                  style={
                    // isMobile
                    //   ? {
                    //       display: "none",
                    //       color: "#fff",
                    //       fontSize: "16px",
                    //       fontFamily: "SpaceGrotesk",
                    //       marginRight: "15px",
                    //       marginBottom: "0",
                    //     }
                    //   : {
                    {
                      color: "#4D4D4D",
                      fontSize: "16px",
                      fontFamily: "SpaceGrotesk",
                      marginRight: "15px",
                      marginBottom: "0",
                    }
                  }
                >
                  {t("followUs")}
                </p>
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "contain",
                    marginRight: "15px",
                    cursor: "pointer",
                  }}
                  src={require("assets/img/linkedin.png").default}
                  onClick={() => {
                    const win = window.open(
                      `https://www.linkedin.com/company/outerpond/`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  alt=""
                />
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "contain",
                    marginRight: "15px",
                    cursor: "pointer",
                  }}
                  src={require("assets/img/twitter.png").default}
                  onClick={() => {
                    const win = window.open(
                      `https://twitter.com/outerpond`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  alt=""
                />
                <img
                  style={{
                    width: "32px",
                    height: "32px",
                    objectFit: "contain",
                    marginRight: "15px",
                    cursor: "pointer",
                  }}
                  src={require("assets/img/YoutubeSVG.svg").default}
                  onClick={() => {
                    const win = window.open(
                      `https://www.youtube.com/@outerpond3036`,
                      "_blank"
                    );
                    win.focus();
                  }}
                  alt=""
                />
              </Row>
            </Col>
          ) : (
            <></>
          )}
        </Row>

        {isMobile && (
          <Row
            className={
              isMobile
                ? "d-flex justify-content-center align-items-center py-3"
                : "d-flex justify-content-end align-items-center py-3"
            }
            style={{ backgroundColor: "#EEF5FB" }}
          >
            <p
              style={
                // isMobile
                //   ? {
                //       display: "none",
                //       color: "#fff",
                //       fontSize: "16px",
                //       fontFamily: "SpaceGrotesk",
                //       marginRight: "15px",
                //       marginBottom: "0",
                //     }
                //   : {
                {
                  color: "#4D4D4D",
                  fontSize: "16px",
                  fontFamily: "SpaceGrotesk",
                  marginRight: "15px",
                  marginBottom: "0",
                }
              }
            >
              {t("followUs")}
            </p>
            <img
              style={{
                width: "32px",
                height: "32px",
                objectFit: "contain",
                marginRight: "15px",
                cursor: "pointer",
              }}
              src={require("assets/img/linkedin.png").default}
              onClick={() => {
                const win = window.open(
                  `https://www.linkedin.com/company/outerpond/`,
                  "_blank"
                );
                win.focus();
              }}
              alt=""
            />
            <img
              style={{
                width: "32px",
                height: "32px",
                objectFit: "contain",
                marginRight: "15px",
              }}
              src={require("assets/img/twitter.png").default}
              onClick={() => {
                const win = window.open(
                  `https://twitter.com/outerpond`,
                  "_blank"
                );
                win.focus();
              }}
              alt=""
            />
            <img
              style={{
                width: "32px",
                height: "32px",
                objectFit: "contain",
                marginRight: "15px",
                cursor: "pointer",
              }}
              src={require("assets/img/YoutubeSVG.svg").default}
              onClick={() => {
                const win = window.open(
                  `https://www.youtube.com/@outerpond3036`,
                  "_blank"
                );
                win.focus();
              }}
              alt=""
            />
          </Row>
        )}

        <Row
          className={
            isMobile
              ? "d-flex flex-column justify-content-between align-items-center pt-3 px-4"
              : "d-flex justify-content-between align-items-center px-4"
          }
          style={{ backgroundColor: "#132438" }}
        >
          <p
            style={{
              color: "#fff",
              fontSize: "12px",
              fontFamily: "SpaceGrotesk",
              marginRight: "30px",
              marginBottom: "0",
            }}
          >
            {t("mainPage.footer")}
          </p>
          <Nav className="d-flex align-items-center">
            <NavItem>
              <NavLink
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  fontFamily: "SpaceGrotesk",
                }}
                to={"/footer-sub-pages/1"}
                tag={Link}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("1");
                  window.scrollTo(0, 0);
                }}
              >
                {t("footer.faq")}
              </NavLink>
            </NavItem>
            <span className="text-white">—</span>
            <NavItem>
              <NavLink
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  fontFamily: "SpaceGrotesk",
                }}
                to={"/footer-sub-pages/2"}
                tag={Link}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("2");
                  window.scrollTo(0, 0);
                }}
              >
                {t("footer.terms")}
              </NavLink>
            </NavItem>
            <span className="text-white">—</span>
            <NavItem>
              <NavLink
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  fontFamily: "SpaceGrotesk",
                }}
                to={"/footer-sub-pages/3"}
                tag={Link}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("3");
                  window.scrollTo(0, 0);
                }}
              >
                {t("footer.privacyPolicy")}
              </NavLink>
            </NavItem>
          </Nav>
        </Row>
      </div>
    </>
  );
}

export default FooterSubPages;
