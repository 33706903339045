import React, { useState, useContext } from "react";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { projectTypeService } from "_services";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import { LanguageContext } from "_context";
import { adminService } from "_services";
import { systemSetupService } from "_services";
import * as Yup from "yup";
import { sassNull } from "sass";

function EditSurveyTypesDescription({ history, match, location }) {
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [initialValues, setInitialValues] = useState({
    surveyTypeDescription: "",
  });
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  //const [stringList, setList] = useState([]);

  var validationSchema = Yup.object().shape({
    surveyTypeDescription: Yup.string().required(t("fieldRequired")),
  });

  const fetchData = async () => {
    setLoading(true);
    editInitialForm(location.state.state);
    // const list = [];
    // if (
    //   location.state.state != null &&
    //   location.state.state.stringList != null
    // ) {
    //   location.state.state.stringList.forEach((item) => {
    //     var model = { stringList: item };
    //     list.push(model);
    //   });
    //   console.log(list);
    //   if (list !== null && list.length > 0) {
    //     setInputList(list);
    //   } else {
    //     setInputList([{ stringList: "" }]);
    //   }
    // }

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function editInitialForm(variable) {
    var newJson = {
      surveyTypeDescription: variable.surveyTypeDescription,
    };
    setInitialValues(newJson);
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    var model = {
      surveyTypeID: location.state.state.surveyTypeID,
      surveyTypeDescription: fields.surveyTypeDescription,
    };

    await adminService
      .EditSurveyTypesDescription(model)
      .then(() => {
        setSubmitting(false);
        setAlert(!alert);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }
  return (
    <div className="section">
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("done")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>{t("surveyType.edit.success")}</p>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setAlert(!alert);
                history.push(".");
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            //  validationSchema={validationSchema}
          >
            {({ isSubmitting, errors, touched }) => {
              return (
                <Col md="12" style={{ margin: "auto" }}>
                  <Card className={rtl ? "text-right" : ""}>
                    <Form autoComplete="off">
                      <CardBody>
                        <CardTitle className="text-center" tag="h4">
                          {t("editSurveyTypesDescription")}
                        </CardTitle>
                        <Row>
                          <Col md="12" style={{ marginTop: "10px" }}>
                            {/* <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              > */}
                            <div className="form-row">
                              <FormGroup className="col-md-12">
                                <label htmlFor="inputPassword4">
                                  {t("surveyType.Description")}
                                </label>
                                <Field
                                  //required={true}
                                  as="textarea"
                                  autoFocus={true}
                                  name="surveyTypeDescription"
                                  placeholder={t("surveyType.Description")}
                                  className={
                                    "form-control" +
                                    (errors.surveyTypeDescription &&
                                    touched.surveyTypeDescription
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {errors.surveyTypeDescription &&
                                  touched.surveyTypeDescription && (
                                    <ErrorMessage
                                      name="surveyTypeDescription"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  )}
                              </FormGroup>
                            </div>

                            <div className="form-row">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <center>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {t("createCompanyProfile.save")}
                                  </Button>
                                </center>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default EditSurveyTypesDescription;
