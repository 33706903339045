import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import ExternalOpportunitiesList from "./ExternalOpportunitiesList";
import OpportunityDetails from "./Details/Index";

function ExternalOpportunities({ match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute
          path={`${path}`}
          component={ExternalOpportunitiesList}
          exact
        />
        <PrivateRoute
          path={`${path}/opportunity-details`}
          component={OpportunityDetails}
        />
      </Switch>
    </div>
  );
}

export { ExternalOpportunities };
