import React, { useContext, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import {
  Container,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import Card from "reactstrap/lib/Card";
import FormGroup from "reactstrap/lib/FormGroup";
import Button from "reactstrap/lib/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import CardText from "reactstrap/lib/CardText";
import ReactCountryFlag from "react-country-flag";
import { Formik, Field, Form } from "formik";
import ReactHtmlParser from "react-html-parser";
import { Multiselect } from "multiselect-react-dropdown";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import {
  countryService,
  profileService,
  searchService,
  directLinkSeenService,
  industryService,
} from "_services";
import { DirectLinkCriteriaContext } from "_context";
import { DirectSearchResultsContext } from "_context";
import Modal from "reactstrap/lib/Modal";
import { Link } from "react-router-dom";
import Badge from "reactstrap/lib/Badge";
import "../../parsing.css";
import Alert from "reactstrap/lib/Alert";
import { LanguageContext } from "_context";

function SearchDirectLinks({ history, match }) {
  const { path } = match;
  const user = profileService.userValue;
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [searchModel, setSearchModel] = useContext(DirectLinkCriteriaContext);
  const [projectSearchList, setProjectSearchList] = useContext(
    DirectSearchResultsContext
  );

  const [sortByLabel, setSortByLabel] = useState(t("projectLink"));

  const [showAlert, setShowAlert] = useState(
    sessionStorage.getItem("startupSeedExpiryMessage") === "true"
  );

  const [searchResults, setSearchResults] = useState(null);
  const [countries, setCountries] = useState(null);
  const [countryList, setCountryList] = useState([]);

  const [show, setShow] = useState(
    searchModel.industries == null ? false : true
  );
  const [totalCount, setCount] = useState(0);

  const [selectedProject, setSelectedProject] = useState("");
  const [filterBy, setFilterBy] = useState(searchModel.filterBy);
  const [filterType, setFilterType] = useState(searchModel.filterType);
  const countriesMultiselectRef = React.createRef();
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const servicesMultiselectRef = React.createRef();
  const [industriesAndServices, setIndustriesAndServices] = useState([]);
  const [industries, setIndustries] = useState([]);

  const [selectedIndustries, setSelectedIndustries] = useState(
    searchModel.industries
  );
  const selectedIndustriesRef = React.useRef();
  const [selectedIndustriesAndServices, setSelectedIndustriesAndServices] =
    useState([]);
  const initialValues = {
    keyword: searchModel.searchKeyWord,
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [closingDateFrom, setClosingDateFrom] = useState(
    searchModel.closingDateFrom == null
      ? null
      : new Date(searchModel.closingDateFrom)
  );
  const [closingDateTo, setClosingDateTo] = useState(
    searchModel.closingDateTo == null
      ? null
      : new Date(searchModel.closingDateTo)
  );
  const [publicationDateFrom, setPublicationDateFrom] = useState(
    searchModel.publicationDateFrom == null
      ? null
      : new Date(searchModel.publicationDateFrom)
  );
  const [publicationDateTo, setPublicationDateTo] = useState(
    searchModel.publicationDateTo == null
      ? null
      : new Date(searchModel.publicationDateTo)
  );

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [services, setServices] = useState([]);

  React.useEffect(() => {
    selectedIndustriesRef.current = searchModel.industries;
  }, [searchModel]);

  const onLoadMoreClick = async () => {
    const newModel = {
      userPerforminSearchId: user.profile.userId,
      searchIndex: searchModel.searchIndex + 1,
      searchKeyWord: searchModel.searchKeyWord,
      countries: searchModel.countries,
      industries: searchModel.industries,
      typeOfIndustriesAndServices: searchModel.typeOfIndustriesAndServices,
      closingDateFrom: searchModel.closingDateFrom,
      closingDateTo: searchModel.closingDateTo,
      publicationDateFrom: searchModel.publicationDateFrom,
      publicationDateTo: searchModel.publicationDateTo,
      filterType: searchModel.filterType,
      filterBy: searchModel.filterBy,
      languageName: currentLan,
      initialModal: searchModel.initialModal,
    };

    setSearchModel(newModel);

    var list = searchResults;

    await searchService
      .SearchDirectLinksAsync(newModel, currentLan)

      .then((x) => {
        setCount(x.totalCount);
        x.requestedDirectLinkList.forEach(async (project) => {
          list.push(project);
        });
        const model = {
          totalCount: x.totalCount,
          list: list,
        };
        setProjectSearchList(model);
        setSearchResults(list);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  };

  const fetchData = async (lan) => {
    setLoading(true);
    if (
      projectSearchList &&
      projectSearchList.list &&
      projectSearchList.list.length === 0
    ) {
      await searchService
        .SearchDirectLinksAsync(searchModel, currentLan)
        .then((x) => {
          setCount(x.totalCount);
          const model = {
            totalCount: x.totalCount,
            list: x.requestedDirectLinkList,
          };

          if (
            x.requestedDirectLinkList !== null &&
            x.requestedDirectLinkList.length > 0
          ) {
            setSelectedIndustries(x.requestedDirectLinkList[0].typeOfIndustry);
          }

          setProjectSearchList(model);
          setSearchResults(x.requestedDirectLinkList);
          industryService
            .GetProjectsIndustriesServicesByLanguageAsync(lan, true)
            .then((industry) => {
              if (industry != null) {
                setIndustriesAndServices(industry);
                //setIndustries(industry);
                var array = [];
                if (show)
                  for (var i = 0; i < industry.length; i++) {
                    if (
                      x.requestedDirectLinkList !== null &&
                      x.requestedDirectLinkList.length > 0 &&
                      x.requestedDirectLinkList[0].typeOfIndustry !== null &&
                      industry[i].typeOfIndustryId ==
                        x.requestedDirectLinkList[0].typeOfIndustry
                          .typeOfIndustryId
                    ) {
                      array.push({
                        typeOfIndustryId: industry[i].typeOfIndustryId,
                        typeOfIndustryName: industry[i].typeOfIndustryName,
                        typeOfServiceId: industry[i].typeOfServiceId,
                        typeOfServiceName: industry[i].typeOfServiceName,
                      });
                    }
                  }
                setServices(array);
                for (var i = 0; i < industry.length; i++) {
                  if (
                    !industries.some(
                      (ind) =>
                        ind.typeOfIndustryId === industry[i].typeOfIndustryId
                    )
                  ) {
                    industries.push({
                      typeOfIndustryId: industry[i].typeOfIndustryId,
                      typeOfIndustryName: industry[i].typeOfIndustryName,
                    });
                  }
                }
              }
            });
        });
    } else {
      await industryService
        .GetProjectsIndustriesServicesByLanguageAsync(lan, true)
        .then((industry) => {
          if (industry != null) {
            setIndustriesAndServices(industry);
            setIndustries(industry);
            var array = [];
            if (show)
              for (var i = 0; i < industry.length; i++) {
                if (
                  projectSearchList.list !== null &&
                  projectSearchList.list.length > 0 &&
                  projectSearchList.list[0].typeOfIndustry !== null &&
                  industry[i].typeOfIndustryId ==
                    projectSearchList.list[0].typeOfIndustry.typeOfIndustryId
                ) {
                  array.push({
                    typeOfIndustryId: industry[i].typeOfIndustryId,
                    typeOfIndustryName: industry[i].typeOfIndustryName,
                    typeOfServiceId: industry[i].typeOfServiceId,
                    typeOfServiceName: industry[i].typeOfServiceName,
                  });
                }
              }

            setServices(array);

            for (var i = 0; i < industry.length; i++) {
              if (
                !industries.some(
                  (ind) => ind.typeOfIndustryId === industry[i].typeOfIndustryId
                )
              ) {
                industries.push({
                  typeOfIndustryId: industry[i].typeOfIndustryId,
                  typeOfIndustryName: industry[i].typeOfIndustryName,
                });
              }
            }
          }
        });
      setCount(projectSearchList.totalCount);
      setSearchResults(projectSearchList.list);
    }

    await countryService
      .GetDirecctLinksCountryByLanguageAsync(lan)
      .then((x) => {
        setCountries(x);
      });

    setLoading(false);
  };
  React.useEffect(() => {
    fetchData(currentLan);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [currentLan]);

  function onIndustryChange(e) {
    if (selectedIndustriesAndServices.length > 0) {
      servicesMultiselectRef.current.resetSelectedValues();
      setSelectedIndustriesAndServices([]);
    }
    setShow(false);
    if (e == "None") {
      setServices([]);
      setSelectedIndustries(null);
      selectedIndustriesRef.current = null;
    } else {
      setSelectedIndustries({
        typeOfIndustryId: e,
      });
      selectedIndustriesRef.current = { e };
      setShow(true);
      var array = [];
      for (var i = 0; i < industriesAndServices.length; i++) {
        if (industriesAndServices[i].typeOfIndustryId == e) {
          array.push({
            typeOfIndustryId: industriesAndServices[i].typeOfIndustryId,
            typeOfIndustryName: industriesAndServices[i].typeOfIndustryName,
            typeOfServiceId: industriesAndServices[i].typeOfServiceId,
            typeOfServiceName: industriesAndServices[i].typeOfServiceName,
          });
          setServices(array);
        }
      }
    }
  }

  function resetFilter() {
    setClosingDateFrom(null);
    setClosingDateTo(null);
    setShow(false);
    setServices([]);
    setSelectedIndustries(null);
    setPublicationDateFrom(null);
    setPublicationDateTo(null);
    selectedIndustriesRef.current = null;
    if (selectedIndustriesAndServices.length > 0) {
      servicesMultiselectRef.current.resetSelectedValues();
      setSelectedIndustriesAndServices([]);
    }
    if (countryList.length > 0) {
      countriesMultiselectRef.current.resetSelectedValues();
      setCountryList([]);
    }
  }

  function onChange(e) {
    setCountryList(e);
  }

  async function sort() {
    await searchService
      .SearchDirectLinksAsync(searchModel, currentLan)
      .then((x) => {
        setCount(x.totalCount);
        const model = {
          totalCount: x.totalCount,
          list: x.requestedDirectLinkList,
        };
        setProjectSearchList(model);
        setSearchResults(x.requestedDirectLinkList);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    const model = {
      userPerforminSearchId: user.profile.userId,
      searchIndex: 0,
      searchKeyWord: fields.keyword,
      countries: countryList,
      industries: selectedIndustries,
      typeOfIndustriesAndServices: selectedIndustriesAndServices,
      closingDateFrom:
        closingDateFrom == null ? null : closingDateFrom.toDateString(),
      closingDateTo:
        closingDateTo == null ? null : closingDateTo.toDateString(),
      publicationDateFrom:
        publicationDateFrom == null ? null : publicationDateFrom.toDateString(),
      publicationDateTo:
        publicationDateTo == null ? null : publicationDateTo.toDateString(),
      filterType: filterType,
      filterBy: filterBy,
      languageName: currentLan,
      initialModal: false,
    };
    setSearchModel(model);
    await searchService
      .SearchDirectLinksAsync(model, currentLan)
      .then((x) => {
        setCount(x.totalCount);
        const model = {
          totalCount: x.totalCount,
          list: x.requestedDirectLinkList,
        };
        setProjectSearchList(model);
        setSearchResults(x.requestedDirectLinkList);
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function ToggleDirectLinkBookmark(model) {
    await directLinkSeenService
      .ToggleDirectLinkBookmarkAsync(model)
      .then((x) => {
        handleBookmark(model.directLinkId, model.bookmark);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function handleBookmark(index, flag) {
    let updatedList = searchResults.map((item) => {
      if (item.id === index) {
        item.bookmarked = flag;
        return item;
      } else {
        return item; // else return unmodified item
      }
    });
    setSearchResults(updatedList);
    let updatedSearchList = projectSearchList.list.map((item) => {
      if (item.id === index) {
        item.bookmarked = flag;
        return item;
      } else {
        return item; // else return unmodified item
      }
    });
    const model = {
      totalCount: projectSearchList.totalCount,
      list: updatedSearchList,
    };
    setProjectSearchList(model);
  }

  async function AddDirectLinkSeen(model) {
    await directLinkSeenService
      .AddDirectLinkSeenAsync(model)
      .then(() => {
        history.push({
          pathname: `${path}/${model.directLinkId}`,
          state: { fromSearch: "true" },
        });
        // const win = window.open(model.projectURL, "_blank");
        // win.focus();
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  const getExpirationDate = (expDate) => {
    return `${new Date(expDate).getDate()}/${
      monthNames[new Date(expDate).getMonth()]
    }/${new Date(expDate).getFullYear()}`;
  };

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <div
        className={rtl ? "wrapper text-right" : "wrapper"}
        id="searchProvider"
      >
        <div className="section">
          <Row className="justify-content-center" style={{ marginTop: "0px" }}>
            <Col md="12">
              {!user.profileExpired ||
              getExpirationDate(user.profile.expirationDate) !==
                "1/January/1" ? (
                <Alert
                  color="danger"
                  isOpen={showAlert}
                  style={rtl ? { textAlign: "right" } : {}}
                >
                  <div className="container  text-center">
                    {` ${t("startupSeedExpiryMessage.one")}  ${
                      user.profile.expirationDate !== null
                        ? `${new Date(user.profile.expirationDate).getDate()}/${
                            monthNames[
                              new Date(user.profile.expirationDate).getMonth()
                            ]
                          }/${new Date(
                            user.profile.expirationDate
                          ).getFullYear()}`
                        : ""
                    } ${t("startupSeedExpiryMessage.two")}`}{" "}
                    <a
                      href={`/account/upgrade-account?email=${user.profile.userName}`}
                      //  href="#/"
                      className="alert-link"
                      style={{ opacity: 1 }}
                      // onClick={(e) =>
                      //   history.push(
                      //     `/account/upgrade-account/${user.profile.userName}`
                      //   )
                      // }
                    >
                      {t("upgrade.click")}
                    </a>
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={() => {
                        setShowAlert(false);
                        sessionStorage.setItem(
                          "startupSeedExpiryMessage",
                          "false"
                        );
                      }}
                    >
                      <span aria-hidden="true">
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </span>
                    </button>
                  </div>
                </Alert>
              ) : null}
            </Col>
          </Row>
          <Container>
            <Modal
              isOpen={errorAlert}
              style={rtl ? { textAlign: "right" } : {}}
            >
              <div
                className="modal-header text-center"
                style={{ margin: "0 auto" }}
              >
                <h5 className="modal-title">{t("error.alert")}</h5>
              </div>
              <div className="modal-body col-md-12">
                <p>{errorMessage}</p>
              </div>

              <div className="modal-footer">
                <Button
                  color="danger"
                  type="button"
                  style={
                    rtl
                      ? { margin: "auto", textAlign: "right" }
                      : { margin: "auto" }
                  }
                  onClick={(e) => setErrorAlert(!errorAlert)}
                >
                  {t("close.button")}
                </Button>
              </div>
            </Modal>
            <Row>
              <Col md="4">
                <div>
                  <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    {({ isSubmitting, setFieldValue, values }) => {
                      return (
                        <div className="col-md-12">
                          <Card>
                            <Form autoComplete="off">
                              <>
                                <Container>
                                  <FormGroup className="col-md-12">
                                    <h4>{t("filterOpportunities")}</h4>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #bbb",
                                        marginTop: "-1%",
                                      }}
                                    ></hr>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <label htmlFor="keyword">
                                      {t("filterSearch.keyword")}
                                    </label>
                                    <Field
                                      name="keyword"
                                      placeholder={t(
                                        "filterSearch.keyword.placeholder"
                                      )}
                                      type="text"
                                      className={"form-control"}
                                    />
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <label htmlFor="industry">
                                      {t("filterSearch.industries")}
                                    </label>
                                    <br></br>
                                    {industries && (
                                      <select
                                        style={{
                                          fontSize: "14px",
                                        }}
                                        className={"form-control"}
                                        value={
                                          selectedIndustries == null &&
                                          selectedIndustriesRef.current == null
                                            ? "None"
                                            : selectedIndustriesRef.current ==
                                              null
                                            ? "None"
                                            : selectedIndustriesRef.current
                                                .typeOfIndustryId
                                        }
                                        onChange={(e) => {
                                          onIndustryChange(e.target.value);
                                        }}
                                      >
                                        <option key={null} value={null}>
                                          {t("none.placeholder")}
                                        </option>
                                        {industries.map((ind) => (
                                          <option
                                            key={ind.typeOfIndustryId}
                                            value={ind.typeOfIndustryId}
                                          >
                                            {ind.typeOfIndustryName}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </FormGroup>
                                  {show ? (
                                    <FormGroup className="col-md-12">
                                      <label htmlFor="industry">
                                        {t("filterSearch.services")}
                                      </label>
                                      <Multiselect
                                        hidePlaceholder={true}
                                        style={
                                          rtl
                                            ? {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },

                                                option: {
                                                  textAlign: "right",
                                                },
                                              }
                                            : {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },
                                              }
                                        }
                                        closeOnSelect={false}
                                        options={services}
                                        selectedValues={
                                          searchModel.typeOfIndustriesAndServices
                                        }
                                        placeholder={t(
                                          "filterSearch.services.placeholder"
                                        )}
                                        displayValue="typeOfServiceName"
                                        groupBy="typeOfIndustryName"
                                        showCheckbox={true}
                                        ref={servicesMultiselectRef}
                                        onSelect={(e) => {
                                          setSelectedIndustriesAndServices(e);
                                        }}
                                        onRemove={(e) => {
                                          setSelectedIndustriesAndServices(e);
                                        }}
                                      />
                                    </FormGroup>
                                  ) : (
                                    ""
                                  )}

                                  <FormGroup className="col-md-12">
                                    <label htmlFor="industry">
                                      {t("filterSearch.country")}
                                    </label>
                                    {countries && (
                                      <Multiselect
                                        hidePlaceholder={true}
                                        style={
                                          rtl
                                            ? {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },

                                                option: {
                                                  textAlign: "right",
                                                },
                                              }
                                            : {
                                                searchBox: {
                                                  borderRadius: "0px",
                                                  border: "1px solid #E3E3E3",
                                                },
                                                inputField: {
                                                  width: "100%",
                                                },
                                              }
                                        }
                                        closeOnSelect={false}
                                        options={countries}
                                        placeholder={t(
                                          "createCompanyProfile.selectOption"
                                        )}
                                        displayValue="countryName"
                                        selectedValues={searchModel.countries}
                                        ref={countriesMultiselectRef}
                                        showCheckbox={true}
                                        onSelect={(e) => {
                                          onChange(e);
                                        }}
                                        onRemove={(e) => {
                                          onChange(e);
                                        }}
                                      />
                                    )}
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <label htmlFor="industry">
                                      {t("filter.publicationDate")}
                                    </label>
                                    <div className="form-row">
                                      <FormGroup className="col-md-6">
                                        <DatePicker
                                          placeholderText={t(
                                            "filter.from.placeholder"
                                          )}
                                          selected={publicationDateFrom}
                                          dateFormat="d/MMM/yy"
                                          className="form-control"
                                          name={publicationDateFrom}
                                          onChange={(date) => {
                                            setPublicationDateFrom(date);
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup className="col-md-6">
                                        <DatePicker
                                          placeholderText={t(
                                            "filter.to.placeholder"
                                          )}
                                          selected={publicationDateTo}
                                          dateFormat="d/MMM/yy"
                                          className="form-control"
                                          name={publicationDateTo}
                                          onChange={(date) => {
                                            setPublicationDateTo(date);
                                          }}
                                        />
                                      </FormGroup>
                                    </div>
                                  </FormGroup>
                                  <FormGroup className="col-md-12">
                                    <label htmlFor="industry">
                                      {t("filter.closingDate")}
                                    </label>
                                    <div className="form-row">
                                      <FormGroup className="col-md-6">
                                        <DatePicker
                                          placeholderText={t(
                                            "filter.from.placeholder"
                                          )}
                                          selected={closingDateFrom}
                                          dateFormat="d/MMM/yy"
                                          className="form-control"
                                          name={closingDateFrom}
                                          onChange={(date) => {
                                            setClosingDateFrom(date);
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup className="col-md-6">
                                        <DatePicker
                                          placeholderText={t(
                                            "filter.to.placeholder"
                                          )}
                                          selected={closingDateTo}
                                          dateFormat="d/MMM/yy"
                                          className="form-control"
                                          name={closingDateTo}
                                          onChange={(date) => {
                                            setClosingDateTo(date);
                                          }}
                                        />
                                      </FormGroup>
                                    </div>
                                  </FormGroup>
                                  <center>
                                    <FormGroup className="col-md-12">
                                      <div className="form-row">
                                        <FormGroup className="col-md-6">
                                          <Button
                                            color="primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                          >
                                            {isSubmitting && (
                                              <span className="spinner-border spinner-border-sm mr-1"></span>
                                            )}
                                            {t("filterSearch.button")}
                                          </Button>
                                        </FormGroup>
                                        <FormGroup className="col-md-5">
                                          <Button
                                            color="primary"
                                            type="submit"
                                            onClick={() => {
                                              resetFilter(setFieldValue);
                                              setFieldValue("keyword", "");
                                            }}
                                          >
                                            {isSubmitting && (
                                              <span className="spinner-border spinner-border-sm mr-1"></span>
                                            )}
                                            {t("filterSearch.resetButton")}
                                          </Button>
                                        </FormGroup>
                                      </div>
                                    </FormGroup>
                                  </center>
                                </Container>
                              </>
                            </Form>
                          </Card>
                        </div>
                      );
                    }}
                  </Formik>
                </div>
              </Col>
              <Col md="8">
                {searchResults && Object.keys(searchResults).length > 0 ? (
                  <Col md="12">
                    <Card style={{ marginBottom: "10px", boxShadow: "none" }}>
                      <>
                        <div style={{ display: "flex", float: "right" }}>
                          <UncontrolledDropdown style={{ marginTop: "-10px" }}>
                            <DropdownToggle
                              aria-expanded={false}
                              aria-haspopup={true}
                              caret
                              color="primary"
                              data-toggle="dropdown"
                              id="dropdownMenuButton"
                              type="button"
                            >
                              {sortByLabel}
                            </DropdownToggle>
                            <DropdownMenu aria-labelledby="dropdownMenuButton">
                              <DropdownItem
                                //  href="#pablo"
                                onClick={(e) => {
                                  setFilterBy("Link");
                                  searchModel.filterBy = "Link";
                                  searchModel.searchIndex = 0;
                                  setSearchModel(searchModel);
                                  setSortByLabel(t("projectLink"));
                                  sort();
                                }}
                              >
                                {t("projectLink")}
                              </DropdownItem>
                              <DropdownItem
                                //  href="#pablo"
                                onClick={(e) => {
                                  setFilterBy("PublishDate");
                                  searchModel.filterBy = "PublishDate";
                                  searchModel.searchIndex = 0;
                                  setSearchModel(searchModel);
                                  setSortByLabel(t("project.publicationDate"));
                                  sort();
                                }}
                              >
                                {t("project.publicationDate")}
                              </DropdownItem>
                              <DropdownItem
                                //  href="#pablo"
                                onClick={(e) => {
                                  setFilterBy("ClosingDate");
                                  searchModel.filterBy = "ClosingDate";
                                  searchModel.searchIndex = 0;
                                  setSearchModel(searchModel);
                                  setSortByLabel(
                                    t("filter.closingDate.placeholder")
                                  );
                                  sort();
                                }}
                              >
                                {t("filter.closingDate.placeholder")}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              caret
                              color="default"
                              href="#pablo"
                              nav
                            >
                              {filterType == "Ascending" ? (
                                <i className="fa fa-sort-alpha-down"></i>
                              ) : (
                                <i className="fa fa-sort-alpha-up"></i>
                              )}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  setFilterType("Ascending");
                                  searchModel.filterType = "Ascending";
                                  searchModel.searchIndex = 0;
                                  setSearchModel(searchModel);
                                  sort();
                                }}
                              >
                                <i className="fa fa-sort-alpha-down"></i>
                                {t("sort.ascending")}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setFilterType("Descending");
                                  searchModel.filterType = "Descending";
                                  searchModel.searchIndex = 0;
                                  setSearchModel(searchModel);
                                  sort();
                                }}
                              >
                                <i className="fa fa-sort-alpha-up"></i>
                                {t("sort.descending")}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </>
                    </Card>
                  </Col>
                ) : null}

                <Row>
                  {searchResults && Object.keys(searchResults).length > 0 ? (
                    searchResults.map((project) => {
                      return (
                        <div
                          className="col-md-12"
                          key={project.projectID}
                          style={
                            rtl
                              ? {
                                  direction: "ltr",
                                  textAlign: "left",
                                }
                              : {}
                          }
                        >
                          <Card style={{ marginBottom: "15px" }}>
                            <CardBody style={{ paddingBottom: "0px" }}>
                              <Row>
                                <Col md="11">
                                  {project !== null &&
                                  project.title !== null ? (
                                    <Row>
                                      <Col
                                        md="11"
                                        style={{
                                          marginBottom: "20px",
                                          marginTop: "10px",
                                          cursor: "pointer",
                                          color: "#107fd4",
                                        }}
                                        onClick={async () => {
                                          const model = {
                                            userId: user.profile.userId,
                                            directLinkId: project.id,
                                            projectURL: `http://${project.link}`,
                                          };
                                          await AddDirectLinkSeen(model);
                                        }}
                                      >
                                        {project.title}
                                      </Col>
                                    </Row>
                                  ) : null}
                                  {/* {project.link &&
                                  !project.link.startsWith("http://") &&
                                  !project.link.startsWith("https://") ? (
                                    <Link
                                      onClick={async () => {
                                        const model = {
                                          userId: user.profile.userId,
                                          directLinkId: project.id,
                                          projectURL: `http://${project.link}`,
                                        };
                                        await AddDirectLinkSeen(model);
                                      }}
                                    >
                                      {project.link}
                                    </Link>
                                  ) : (
                                    <Link
                                      onClick={async () => {
                                        const model = {
                                          userId: user.profile.userId,
                                          directLinkId: project.id,
                                          projectURL: project.link,
                                        };
                                        await AddDirectLinkSeen(model);
                                      }}
                                    >
                                      {project.link !== null && project.link}
                                    </Link>
                                  )} */}
                                </Col>
                                <Col md="1">
                                  <Link
                                    id="bookmark"
                                    hidden={project.bookmarked === true}
                                    tag="h4"
                                    style={{ fontSize: "18px" }}
                                  >
                                    <i
                                      className="now-ui-icons location_bookmark"
                                      style={{
                                        // paddingLeft: "10px",
                                        // paddingRight: "10px",
                                        color: "#0E6EB8",
                                      }}
                                      onClick={async () => {
                                        const model = {
                                          directLinkId: project.id,
                                          userId: user.profile.userId,
                                          bookmark: true,
                                        };
                                        await ToggleDirectLinkBookmark(model);
                                      }}
                                    ></i>
                                    <UncontrolledTooltip
                                      target="bookmark"
                                      placement="right"
                                    >
                                      {t("bookmark.click")}
                                    </UncontrolledTooltip>
                                  </Link>
                                  <Link
                                    id="unbookmark"
                                    hidden={project.bookmarked === false}
                                    tag="h4"
                                    style={{ fontSize: "18px" }}
                                  >
                                    <i
                                      className="fa fa-bookmark"
                                      style={{
                                        // paddingLeft: "10px",
                                        // paddingRight: "10px",
                                        color: "#0E6EB8",
                                      }}
                                      onClick={async () => {
                                        const model = {
                                          directLinkId: project.id,
                                          userId: user.profile.userId,
                                          bookmark: false,
                                        };
                                        await ToggleDirectLinkBookmark(model);
                                      }}
                                    ></i>
                                    <UncontrolledTooltip
                                      target="unbookmark"
                                      placement="bottom"
                                    >
                                      {t("unbookmark.click")}
                                    </UncontrolledTooltip>
                                  </Link>
                                </Col>
                              </Row>

                              <div
                                style={{
                                  display: "flex",
                                  margin: "-10px 10px 10px 10px",
                                }}
                              >
                                {project !== null &&
                                project.country !== null &&
                                project.country.countryCode !== null ? (
                                  <>
                                    <CardText style={{ width: "350px" }}>
                                      <i
                                        class="fas fa-map-marker-alt"
                                        aria-hidden="true"
                                        style={{
                                          paddingLeft: "3px",
                                          paddingRight: "3px",
                                        }}
                                      ></i>

                                      <ReactCountryFlag
                                        countryCode={
                                          project.country.countryCode
                                        }
                                        svg
                                        style={{
                                          width: "1.5em",
                                          height: "1.5em",
                                          marginTop: "-2px",
                                          marginRight: "10px",
                                          marginLeft: "10px",
                                          borderRadius: "0px",
                                        }}
                                        title="LB"
                                      />

                                      <small style={{ fontSize: "80%" }}>
                                        {project !== null &&
                                        project.country !== null &&
                                        project.country.countryName !== null
                                          ? project.country.countryName
                                          : ""}
                                      </small>
                                    </CardText>
                                  </>
                                ) : null}
                              </div>
                              <div
                                style={{ display: "flex", marginTop: "-10px" }}
                              >
                                <CardText style={{ width: "350px" }}>
                                  <i
                                    className="fa fa-upload"
                                    aria-hidden="true"
                                    style={{
                                      paddingRight: "13px",
                                      paddingLeft: "13px",
                                    }}
                                  ></i>
                                  <small style={{ fontSize: "80%" }}>
                                    {`${new Date(
                                      project.publicationDateTime
                                    ).getDate()}/${
                                      monthNames[
                                        new Date(
                                          project.publicationDateTime
                                        ).getMonth()
                                      ]
                                    }/${new Date(
                                      project.publicationDateTime
                                    ).getFullYear()}` !== "1/January/1"
                                      ? `${new Date(
                                          project.publicationDateTime
                                        ).getDate()}/${
                                          monthNames[
                                            new Date(
                                              project.publicationDateTime
                                            ).getMonth()
                                          ]
                                        }/${new Date(
                                          project.publicationDateTime
                                        ).getFullYear()}`
                                      : ""}
                                  </small>
                                </CardText>
                                <CardText style={{ width: "350px" }}>
                                  <i
                                    className="fa fa-hourglass-end"
                                    aria-hidden="true"
                                    style={{
                                      paddingRight: "12px",
                                      paddingLeft: "12px",
                                    }}
                                  ></i>
                                  <small style={{ fontSize: "80%" }}>
                                    {project.closingDate !== null &&
                                    `${new Date(
                                      project.closingDate
                                    ).getUTCDate()}/${
                                      monthNames[
                                        new Date(
                                          project.closingDate
                                        ).getUTCMonth()
                                      ]
                                    }/${new Date(
                                      project.closingDate
                                    ).getFullYear()}` !== "1/January/1"
                                      ? `${new Date(
                                          project.closingDate
                                        ).getUTCDate()}/${
                                          monthNames[
                                            new Date(
                                              project.closingDate
                                            ).getUTCMonth()
                                          ]
                                        }/${new Date(
                                          project.closingDate
                                        ).getFullYear()}`
                                      : ""}
                                  </small>
                                </CardText>
                              </div>
                              <Col style={{ marginTop: "7px" }}>
                                <Row>
                                  <div
                                    style={{
                                      paddingLeft: "3px",
                                      paddingRight: "3px",
                                    }}
                                  >
                                    <Badge
                                      style={{
                                        backgroundColor: "#f5f5fa",
                                        color: "#000000",
                                        border: "none",
                                        fontSize: "85%",
                                      }}
                                    >
                                      {project !== null &&
                                      project.typeOfIndustry !== null &&
                                      project.typeOfIndustry
                                        .typeOfIndustryName !== null
                                        ? project.typeOfIndustry
                                            .typeOfIndustryName
                                        : ""}
                                    </Badge>
                                  </div>
                                  <div
                                    style={{
                                      paddingLeft: "3px",
                                      paddingRight: "3px",
                                    }}
                                  >
                                    <Badge
                                      style={{
                                        backgroundColor: "#f5f5fa",
                                        color: "#000000",
                                        border: "none",
                                        fontSize: "85%",
                                      }}
                                    >
                                      {project !== null &&
                                      project.typeOfIndustry !== null &&
                                      project.typeOfIndustry
                                        .typeOfServiceName !== null
                                        ? project.typeOfIndustry
                                            .typeOfServiceName
                                        : ""}
                                    </Badge>
                                  </div>
                                </Row>
                              </Col>
                            </CardBody>
                          </Card>
                        </div>
                      );
                    })
                  ) : (
                    <Card className="text-center" style={{ boxShadow: "none" }}>
                      <CardBody className="d-flex flex-column align-items-center">
                        <CardTitle tag="h4">{t("noOpp.first")}</CardTitle>
                        <CardText>{t("noOpp.second")}</CardText>
                        <CardText style={{ width: "70%" }}>
                          {t("noOpp.fourth")}
                        </CardText>
                      </CardBody>
                    </Card>
                  )}
                </Row>
                {searchResults &&
                  Object.keys(searchResults).length < totalCount &&
                  Object.keys(searchResults).length > 0 && (
                    <center>
                      <Button
                        color="primary"
                        onClick={() => {
                          onLoadMoreClick();
                        }}
                      >
                        {t("loadMore")}
                      </Button>
                    </center>
                  )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SearchDirectLinks;
