import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditBudget from "./AddEditBudget";
import BudgetsList from "./BudgetsList";

function Budgets({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={BudgetsList} exact />
        <PrivateRoute path={`${path}/add`} component={AddEditBudget} />
        <PrivateRoute path={`${path}/edit/:id`} component={AddEditBudget} />
      </Switch>
    </div>
  );
}

export { Budgets };
