import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import { useMediaQuery } from "react-responsive";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import LanguageDropdown from "./LanguageDropdown";
import { useTranslation } from "react-i18next";

function LandingPageNavbarMobileTablet2({ props }) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const { t, i18n } = useTranslation();

  const isNotMobile = useMediaQuery({
    query: "(min-width: 768px)",
  });

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      <Navbar
        className={"fixed-top shadow-none pr-0"}
        expand="lg"
        color="white"
        style={isNotMobile ? { paddingLeft: "30px" } : {}}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <NavbarBrand
            id="navbar-brand"
            href="/"
            className=""
            style={{
              width: "193px",
              height: "48px",
            }}
          >
            <img
              src={require("assets/img/Outerpond-Logo.png").default}
              alt=""
            />
          </NavbarBrand>
          <Nav
            className="ml-0 flex-row"
            style={{ flexDirection: "row !important" }}
          >
            <NavItem>
              <NavLink
                style={{ color: "#4B4B4B", fontFamily: "SpaceGroteskBold" }}
                onClick={() => {
                  props.push("/account/login");
                }}
                tag={Link}
              >
                {t("navigation.login")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                //      href="/account/register"
                style={{ color: "#0E6EB8", fontFamily: "SpaceGroteskBold" }}
                onClick={() => {
                  props.push("/account/register");
                }}
                tag={Link}
              >
                {" "}
                {t("navigation.register")}
              </NavLink>
            </NavItem>
            <LanguageDropdown />
          </Nav>
          {/* <button
              className="navbar-toggler navbar-toggler "
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button> */}
        </div>
      </Navbar>
    </>
  );
}

export default LandingPageNavbarMobileTablet2;
