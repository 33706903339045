import {config} from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/NumberOfEmployees`;

export const numberOfEmpService = {
    GetAllNumberOfEmployeesAsync
};

function GetAllNumberOfEmployeesAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllNumberOfEmployees`);
}


