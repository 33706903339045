import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import BarChart from "./BarChart";
import CalendarChart from "./CalendarChart";
import CirclePacking from "./CirclePacking";
import LineChart from "./LineChart";
import OpportunitiesViews from "./OpportunitiesViews";
//import { PieChart } from "./PieChart";
import ProjectsByCategorySunburst from "./ProjectsByCategorySunburst";
import ProjectsByInteractionsBarGraph from "./ProjectsByInteractionsBargraph";
import SpFinancingAndPartnershipBarChart from "./SpFinancingAndPartnership";
import SpsByCategorySunburst from "./SpsByCategorySunburst";
import UserLastLogin from "./UserLastLogin";
import SpsByCategoryPieChart from "./SpsByCategoryPieChart";

function AnalyticsComponents({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute
          path={`${path}/opportunitiesByType-BarChart`}
          component={BarChart}
          exact
        />
        <PrivateRoute
          path={`${path}/Logins-CalendarChart`}
          component={CalendarChart}
        />
        <PrivateRoute
          path={`${path}/profiles-CirclePackingChart`}
          component={CirclePacking}
        />
        <PrivateRoute
          path={`${path}/ProjectsPublished-LineChart`}
          component={LineChart}
        />
        <PrivateRoute
          path={`${path}/OpportunitiesViews-BarChart`}
          component={OpportunitiesViews}
        />
        {/* <PrivateRoute path={`${path}/ProjectsPublished-LineChart`} component={PieChart} /> */}
        <PrivateRoute
          path={`${path}/ProjectsByCategory-Sunburst`}
          component={ProjectsByCategorySunburst}
        />
        <PrivateRoute
          path={`${path}/ProjectsByInteractions-BarGraph`}
          component={ProjectsByInteractionsBarGraph}
        />
        <PrivateRoute
          path={`${path}/SpFinancingAndPartnership-BarChart`}
          component={SpFinancingAndPartnershipBarChart}
        />
        <PrivateRoute
          path={`${path}/SpsByCategory-Sunburst`}
          component={SpsByCategorySunburst}
        />
        <PrivateRoute
          path={`${path}/UserLatestLogins-Table`}
          component={UserLastLogin}
        />
        <PrivateRoute
          path={`${path}/SpsByCategory-PieChart`}
          component={SpsByCategoryPieChart}
        />
      </Switch>
    </div>
  );
}

export { AnalyticsComponents };
