import React, { useState, useEffect } from "react";

// reactstrap components
import { Button, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import { Link } from "react-router-dom";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import Modal from "reactstrap/lib/Modal";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import CardTitle from "reactstrap/lib/CardTitle";
import SS_POSurveyList from "./SS&POSurveyList";
import SPSurveyList from "./SPSurveyList";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
};

function SurveyList({ match, history, location }) {
  const { path } = match;

  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  const [iconPills, setIconPills] = React.useState(
    location.state != null
      ? location.state.type != null
        ? location.state.type == "SS&PO"
          ? "1"
          : "2"
        : "1"
      : "1"
  );

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div className="modal-body col-md-12">
          <p>{errorMessage}</p>
        </div>

        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            style={
              rtl ? { margin: "auto", textAlign: "right" } : { margin: "auto" }
            }
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>

      <div className={rtl ? "section text-right" : "section"}>
        <Row>
          <div className=""></div>
          <Card style={{ boxShadow: "none" }}>
            <CardHeader>
              <Nav className="justify-content-center" role="tablist" tabs>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className={iconPills === "1" ? "active" : ""}
                    // href="#pablo"
                    onClick={async (e) => {
                      // await fetchAlertData();
                      setIconPills("1");
                      e.preventDefault();
                    }}
                  >
                    {t("ss&po")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className={iconPills === "2" ? "active" : ""}
                    //  href="#pablo"
                    onClick={async (e) => {
                      // await fetchNewslettersData();
                      setIconPills("2");
                      e.preventDefault();
                    }}
                  >
                    {t("sp")}
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={"iconPills" + iconPills}>
                <TabPane tabId="iconPills1">
                  {/* {alertsLogCount === 0 ? (
                    <Row>
                      <Card
                        className="text-center"
                        style={{ boxShadow: "none" }}
                      >
                        <CardBody>
                          <CardTitle tag="h4">{t("empty.ssSurvey")}</CardTitle>
                        </CardBody>
                      </Card>
                    </Row>
                  ) : ( */}
                  <SS_POSurveyList
                    tableIcons={tableIcons}
                    history={history}
                    path={path}
                    match={match}
                    type={"SS&PO"}
                  />
                  {/* )} */}
                </TabPane>
                <TabPane tabId="iconPills2">
                  {/* {newslettersLogCount === 0 ? (
                    <Row>
                      <Card
                        className="text-center"
                        style={{ boxShadow: "none" }}
                      >
                        <CardBody>
                          <CardTitle tag="h4">{t("empty.spSurvey")}</CardTitle>
                        </CardBody>
                      </Card>
                    </Row>
                  ) : ( */}
                  <SPSurveyList
                    tableIcons={tableIcons}
                    history={history}
                    path={path}
                    match={match}
                    type={"SP"}
                  />
                  {/* )} */}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Row>
      </div>
    </>
  );
}

export default SurveyList;
