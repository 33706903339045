import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import IndustriesList from "./IndustriesList";
import ServicesList from "./ServicesList";
import EditService from "./addEditService";
import AddEditIndustry from "./addEditIndustry"

function Industries({ match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={IndustriesList} exact />
        <PrivateRoute path={`${path}/services/:id`} component={ServicesList} exact />
        <PrivateRoute path={`${path}/services/:id/edit/:serviceId`} component={EditService} />
        <PrivateRoute path={`${path}/services/:id/add`} component={EditService} />
        <PrivateRoute path={`${path}/edit/:id`} component={AddEditIndustry} />
        <PrivateRoute path={`${path}/add`} component={AddEditIndustry} />
      </Switch>
    </div>
  );
}

export { Industries };
