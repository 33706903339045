import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import SystemSetupHome from "./SystemSetupHome";
import SystemTextLists from "./SystemTextsList";
import EditSystemText from "./EditSystemText";

function SystemSetup({ match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={SystemSetupHome} exact />
        <PrivateRoute
          path={`${path}/policies`}
          component={SystemTextLists}
          exact
        />
        <PrivateRoute
          path={`${path}/policies/edit/:id`}
          component={EditSystemText}
          exact
        />
      </Switch>
    </div>
  );
}

export { SystemSetup };
