import React from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import ChangePassword from "components/Profile/AccountSettings/ChangePassword";
import Col from "reactstrap/lib/Col";

function Password({ history, match, location }) {
  const { path } = match;
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <div className="section">
        <Container>
          <Col md="8" style={{ margin: "auto " }}>
            <ChangePassword history={history} location={location} />
          </Col>
        </Container>
      </div>
    </>
  );
}

export default Password;
