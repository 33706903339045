import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import SearchProviders from "./SearchProviders";
import SearchDetails from "./SearchDetails";

function Search({ history, match }) {
  const { path } = match;
  return (
    <>
      <Switch>
        <PrivateRoute exact path={`${path}`} component={SearchProviders} />
        <PrivateRoute
          exact
          path={`${path}/search-details/:userId`}
          component={SearchDetails}
        />
      </Switch>
    </>
  );
}

export { Search };
