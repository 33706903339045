import React, { useState, useContext } from "react";
import { Button, Container, Row, Col, UncontrolledCollapse } from "reactstrap";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import {
  directLinkSeenService,
  profileService,
  projectSeenService,
  projectService,
} from "../../../_services";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import Modal from "reactstrap/lib/Modal";
import { Link } from "react-router-dom";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import ProjectOverviewCard from "./ProjectOverviewCard";
import { ProjectSearchListContext } from "../../../_context";
import CardTitle from "reactstrap/lib/CardTitle";
import CardText from "reactstrap/lib/CardText";
import DirectLinkOverviewCard from "./DirectLinkOverviewCard";
import { LanguageContext } from "_context";
function MyProjects({ history, match }) {
  const user = profileService.userValue;
  const [info, setInfo] = useState(null);
  const [links, setLinks] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [projectSearchList, setProjectSearchList] = useContext(
    ProjectSearchListContext
  );

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const fetchData = async () => {
    setLoading(true);
    await projectSeenService
      .GetAllProjectsSeenAsync(user.profile.userId, false, currentLan)
      .then((x) => {
        setInfo(x);
      });
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const [iconPills, setIconPills] = React.useState("1");

  function UpdateList(list) {
    setLinks(list);
  }

  async function handleBookmark(index, flag) {
    let updatedList = info.map((item) => {
      if (item.projectView.projectID === index) {
        item.bookmark = flag;
        return item;
      } else {
        return item; // else return unmodified item
      }
    });
    setInfo(updatedList);

    // update project search list
    let searchModelList = projectSearchList.list.map((item) => {
      if (item.projectID === index) {
        item.bookMarked = flag;
        return item;
      } else {
        return item; // else return unmodified item
      }
    });
    const model = {
      totalCount: projectSearchList.totalCount,
      list: searchModelList,
    };

    setProjectSearchList(model);
  }

  async function handleBookmarkFromBookmarked(index, flag) {
    let updatedList = info.map((item) => {
      if (item.projectView.projectID === index) {
        item.bookmark = flag;

        return item;
      } else {
        return item; // else return unmodified item
      }
    });

    if (flag === false) {
      // if unbookmark --> remove from bookmark
      setInfo((updatedList) =>
        updatedList.filter((x) => x.projectView.projectID !== index)
      );
    } else {
      setInfo(updatedList);
    }

    // update project search list
    let searchModelList = projectSearchList.list.map((item) => {
      if (item.projectID === index) {
        item.bookMarked = flag;
        return item;
      } else {
        return item; // else return unmodified item
      }
    });
    const model = {
      totalCount: projectSearchList.totalCount,
      list: searchModelList,
    };

    setProjectSearchList(model);
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <Container>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div className="modal-body col-md-12">
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <div className={rtl ? "section text-right" : "section"}>
          <Row>
            <div className=""></div>
            <Card style={{ boxShadow: "none" }}>
              <CardHeader>
                <Nav className="justify-content-center" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      className={iconPills === "1" ? "active" : ""}
                      // href="#pablo"
                      onClick={async (e) => {
                        await projectSeenService
                          .GetAllProjectsSeenAsync(
                            user.profile.userId,
                            false,
                            currentLan
                          )
                          .then((x) => {
                            setInfo(x);
                          });
                        e.preventDefault();
                        setIconPills("1");
                      }}
                    >
                      {t("allSeen")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      className={iconPills === "2" ? "active" : ""}
                      //  href="#pablo"
                      onClick={async (e) => {
                        await projectSeenService
                          .GetAllProjectsSeenAsync(
                            user.profile.userId,
                            true,
                            currentLan
                          )
                          .then((x) => {
                            setInfo(x);
                          });
                        e.preventDefault();
                        setIconPills("2");
                      }}
                    >
                      {t("bookmarkedNewOpportunities")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      className={iconPills === "3" ? "active" : ""}
                      //  href="#pablo"
                      onClick={async (e) => {
                        await directLinkSeenService
                          .GetAllDirectLinkSeenAsync(
                            user.profile.userId,
                            false,
                            currentLan
                          )
                          .then((x) => {
                            setLinks(x);
                          });
                        e.preventDefault();
                        setIconPills("3");
                      }}
                    >
                      {t("viewExistingOpp")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      className={iconPills === "4" ? "active" : ""}
                      //  href="#pablo"
                      onClick={async (e) => {
                        await directLinkSeenService
                          .GetAllDirectLinkSeenAsync(
                            user.profile.userId,
                            true,
                            currentLan
                          )
                          .then((x) => {
                            setLinks(x);
                          });
                        e.preventDefault();
                        setIconPills("4");
                      }}
                    >
                      {t("bookmarkedExistingOpportunities")}
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent
                  // className="text-center"
                  activeTab={"iconPills" + iconPills}
                >
                  <TabPane tabId="iconPills1">
                    {info && info.length === 0 ? (
                      <Row>
                        <Card
                          className="text-center"
                          style={{ boxShadow: "none" }}
                        >
                          <CardBody>
                            <CardTitle tag="h4">
                              {t("empty.myOpportunities.first.seen")}
                            </CardTitle>
                            <CardText>
                              {t("empty.myOpportunities.second")}
                            </CardText>
                            <Button
                              color="primary"
                              //  href="#pablo"
                              onClick={() =>
                                history.push(`/provider/search-project`)
                              }
                            >
                              {t("viewOpportunities")}
                            </Button>
                          </CardBody>
                        </Card>
                      </Row>
                    ) : (
                      info &&
                      info.map((project) => {
                        return (
                          <ProjectOverviewCard
                            props={project}
                            onBookmark={handleBookmark}
                            history={history}
                            rtl={rtl}
                          />
                        );
                      })
                    )}
                  </TabPane>
                  <TabPane tabId="iconPills2">
                    {info && info.length === 0 ? (
                      <Row>
                        <Card
                          className="text-center"
                          style={{ boxShadow: "none" }}
                        >
                          <CardBody>
                            <CardTitle tag="h4">
                              {t("empty.myOpportunities.first.bookmark")}
                            </CardTitle>
                            <CardText>
                              {t("empty.myOpportunities.second")}
                            </CardText>
                            <Button
                              color="primary"
                              //  href="#pablo"
                              onClick={() =>
                                history.push(`/provider/search-project`)
                              }
                            >
                              {t("viewOpportunities")}
                            </Button>
                          </CardBody>
                        </Card>
                      </Row>
                    ) : (
                      info &&
                      info.map((project) => {
                        return (
                          <ProjectOverviewCard
                            props={project}
                            onBookmark={handleBookmarkFromBookmarked}
                            history={history}
                            rtl={rtl}
                          />
                        );
                      })
                    )}
                  </TabPane>
                  <TabPane tabId="iconPills3">
                    {links && links.length === 0 ? (
                      <Row>
                        <Card
                          className="text-center"
                          style={{ boxShadow: "none" }}
                        >
                          <CardBody>
                            <CardTitle tag="h4">
                              {t("empty.myOpportunities.first.seen")}
                            </CardTitle>
                            <CardText>
                              {t("empty.myOpportunities.second")}
                            </CardText>
                            <Button
                              color="primary"
                              //  href="#pablo"
                              onClick={() =>
                                history.push(`/provider/search-direct-links`)
                              }
                            >
                              {t("viewOpportunities")}
                            </Button>
                          </CardBody>
                        </Card>
                      </Row>
                    ) : (
                      <DirectLinkOverviewCard
                        searchResults={links}
                        updateSearchResults={UpdateList}
                        tabNumber={iconPills}
                        history={history}
                        match={match}
                        rtl={rtl}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="iconPills4">
                    {links && links.length === 0 ? (
                      <Row>
                        <Card
                          className="text-center"
                          style={{ boxShadow: "none" }}
                        >
                          <CardBody>
                            <CardTitle tag="h4">
                              {t("empty.myOpportunities.first.bookmark")}
                            </CardTitle>
                            <CardText>
                              {t("empty.myOpportunities.second")}
                            </CardText>
                            <Button
                              color="primary"
                              //  href="#pablo"
                              onClick={() =>
                                history.push(`/provider/search-direct-links`)
                              }
                            >
                              {t("viewOpportunities")}
                            </Button>
                          </CardBody>
                        </Card>
                      </Row>
                    ) : (
                      <DirectLinkOverviewCard
                        searchResults={links}
                        updateSearchResults={UpdateList}
                        tabNumber={iconPills}
                        history={history}
                        match={match}
                        rtl={rtl}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Row>
        </div>
      </Container>
      {/* <DefaultFooter /> */}
    </>
  );
}

export default MyProjects;
