import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditOurUsageAndBenefits from "./AddEditOurUsageAndBenefits";


function UsageAndBenefits({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={AddEditOurUsageAndBenefits} />
      </Switch>
      </div>
  );
}

export { UsageAndBenefits };
