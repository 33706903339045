import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/systemSetup`;

export const systemSetupService = {
    GetAllSystemSetupAsync,
    UpdateSystemSetupAsync,
    GetAllFooterSubPagesAsync,
    GetFileValidationSettingsAsync
};

function GetAllSystemSetupAsync() {
    return fetchWrapper.get(`${baseUrl}/GetAllSystemSetup`);
}


function UpdateSystemSetupAsync(id, params) {
    return fetchWrapper.post(`${baseUrl}/UpdateSystemSetup/${id}`, params);
}

function GetAllFooterSubPagesAsync(lan) {
    return fetchWrapper.get(`${baseUrl}/GetAllFooterSubPages/${lan}`);
}

function GetFileValidationSettingsAsync() {
    return fetchWrapper.get(`${baseUrl}/GetFileValidationSettings`);
}