import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { emailLogsService } from "_services";
import { Paper, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../theme.js";
import { Button, Container, Modal, Row } from "reactstrap";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import { IconButton } from "@material-ui/core";
import moment from "moment";
import { AlertTypes } from "_helpers";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { searchService } from "_services";
import PulseLoader from "react-spinners/PulseLoader";

function NewslettersEmailLogsCard({
  history,
  path,
  tableIcons,
  itemCount,
  changeItemCount,
  getItemCount,
}) {
  const [loading, setLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [newslettersLogs, setNewslettersLogs] = useState([]);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowDataID, setRowDataID] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const resetSearch = React.useRef(false);
  var columns = [
    {
      title: t("emailLog.user"),
      field: "emailLogsId",
      hidden: true,
    },
    {
      title: t("emailLog.user"),
      field: "newsletterID",
      hidden: true,
    },
    {
      title: t("emailLog.user"),
      field: "newsletterLabel",
      hidden: true,
    },
    {
      title: t("emailLog.user"),
      field: "userID",
      hidden: true,
    },
    {
      title: t("emailLog.user"),
      field: "email",
    },
    {
      title: t("emailLog.alert"),
      field: "alertType",
    },
    {
      title: t("emaillog.newslettersSubject"),
      field: "newsletter",
    },
    {
      title: t("partner.list.sent.invitations.date"),
      field: "date",
    },
    {
      title: t("emailLog.status"),
      field: "status",
    },
    {
      title: t("emailLog.reason"),
      field: "reason",
    },
  ];

  let [currentPage, setCurrentPage] = useState(0);
  const emailLogsPerPage = pageSize;
  const begin = React.useRef(0);
  const end = React.useRef(emailLogsPerPage);

  const count = Math.ceil(itemCount / emailLogsPerPage);

  useEffect(() => {
    fetchNewslettersData();
  }, [end.current, begin.current, pageSize]);

  const fetchNewslettersData = async () => {
    if (resetSearch.current == false) setLoading(true);
    let skipEmailLogs = begin.current;
    const model = {
      index: 2,
      emailLogsToSkip: skipEmailLogs,
      startingEmailLogs: emailLogsPerPage,
    };

    await emailLogsService.GetAllAsync(model).then((res) => {
      const tmpList = res.map((email) => ({
        email: email.userEmail,
        alertType:
          email.alertType == "NewOpp"
            ? AlertTypes.NewOpp
            : email.alertType == "ClosingDateOppSS"
            ? AlertTypes.NewOpp
            : email.alertType == "ClosingDateOppSP"
            ? AlertTypes.NewOpp
            : email.alertType == "AdminDispatch"
            ? AlertTypes.AdminDispatch
            : email.alertType,
        newsletter:
          email.asNewsletter.length > 0
            ? email.asNewsletter[0].newsletterSubject
            : "",
        newsletterID: email.newsletterID,
        newsletterLabel: email.alertType,
        status:
          email.status == "P"
            ? "Pending"
            : email.status == "S"
            ? "Sent"
            : email.status == "F"
            ? "Failed"
            : email.status,
        reason: email.reason,
        emailLogsId: email.emailLogsId,
        date: moment(email.date).format("YYYY-MM-DD, h:mm:ss a"),
        userID: email.userID,
      }));
      setNewslettersLogs(tmpList);
    });
    setLoading(false);
    resetSearch.current = false;
  };

  const handleSearch = async (e) => {
    setSearchKeyword(e);
    const model = {
      Keyword: e,
      PageNumber: 0,
      PageSize: pageSize,
      Index: 2,
    };
    await searchService.SearchEmailLogsAsync(model).then((res) => {
      const tmpList = res.map((email) => ({
        email: email.userEmail,
        alertType:
          email.alertType == "NewOpp"
            ? AlertTypes.NewOpp
            : email.alertType == "ClosingDateOppSS"
            ? AlertTypes.NewOpp
            : email.alertType == "ClosingDateOppSP"
            ? AlertTypes.NewOpp
            : email.alertType == "AdminDispatch"
            ? AlertTypes.AdminDispatch
            : email.alertType,
        newsletter:
          email.asNewsletter.length > 0
            ? email.asNewsletter[0].newsletterSubject
            : "",
        newsletterID: email.newsletterID,
        newsletterLabel: email.alertType,
        status:
          email.status == "P"
            ? "Pending"
            : email.status == "S"
            ? "Sent"
            : email.status == "F"
            ? "Failed"
            : email.status,
        reason: email.reason,
        emailLogsId: email.emailLogsId,
        date: moment(email.date).format("YYYY-MM-DD, h:mm:ss a"),
        userID: email.userID,
      }));
      setNewslettersLogs(tmpList);
      setPageSize(pageSize);
    });
    await searchService.SearchEmailLogsCountAsync(model).then((res) => {
      console.log(res);
      changeItemCount(res);
    });
  };

  function next() {
    if (end.current > itemCount) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
    begin.current = (currentPage + 1) * emailLogsPerPage;
    end.current = begin.current + emailLogsPerPage;
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * emailLogsPerPage;
    end.current = begin.current + emailLogsPerPage;
  }

  async function deleteSelectedItem(id) {
    await emailLogsService
      .DeleteEmailLogAsync(id)
      .then(() => {
        // handleRemove(id);
        fetchNewslettersData();
        changeItemCount(itemCount - 1);
        setDeleteAlert(!deleteAlert);
      })
      .catch((error) => {
        setDeleteAlert(!deleteAlert);
        if (error.message !== undefined) {
          setError(error.message);
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  const handleChange = (value) => {
    setPageSize(parseInt(value));
    end.current = begin.current + parseInt(value);
  };

  function handleRemove(id) {
    const newList = newslettersLogs.filter((item) => item.emailLogsId !== id);
    setNewslettersLogs(newList);
    changeItemCount(itemCount - 1);
  }

  return (
    <div>
      <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("delete.alert")}</h5>
        </div>
        <div
          className={
            rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
          }
        >
          <p>{t("delete.alert.body")}</p>
        </div>

        <div className="modal-footer" style={{ justifyContent: "flex-end" }}>
          <center>
            <Link
              className="mr-2"
              type="button"
              style={{ margin: "auto", color: "#000000" }}
              onClick={() => setDeleteAlert(!deleteAlert)}
            >
              {t("cancel.link")}
            </Link>
            <Button
              color="danger"
              type="button"
              className="mr-2"
              style={{ margin: "auto" }}
              onClick={() => deleteSelectedItem(rowDataID)}
            >
              {t("delete")}
            </Button>
          </center>
        </div>
      </Modal>
      <Container>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            width: "100%",
            columnGap: "10rem",
          }}
        >
          <div
            style={{
              color: "#0e6eb8",
              fontSize: "1.25rem",
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
              fontWeight: 500,
              letterSpacing: "0.0075em",
              textTransform: "none",
              marginLeft: "15px",
            }}
          >
            {t("emailLogsList")}
          </div>
          <FormControl sx={{ m: 1 }} variant="standard">
            <Input
              id="searchInput"
              type="text"
              placeholder={t("search")}
              value={searchKeyword}
              onChange={(e) => handleSearch(e.currentTarget.value)}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton aria-label="toggle password visibility">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      const searchInput =
                        document.querySelector("#searchInput");
                      // searchInput = "";
                      setSearchKeyword("");
                      resetSearch.current = true;
                      fetchNewslettersData();
                      getItemCount();
                    }}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Row>
        {loading && (
          <center
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "50%",
              top: "100%",
            }}
          >
            <PulseLoader color="#0E6EB8" />
          </center>
        )}
        {!loading && (
          <>
            <ThemeProvider theme={theme}>
              <MaterialTable
                //  style={{ backgroundColor: "#2C2C34", color: "#d9dcde" }}
                components={{
                  Container: (props) => (
                    <Paper {...props} className="usersTable" elevation={0} />
                  ),
                }}
                title={<span></span>}
                columns={columns}
                data={newslettersLogs}
                icons={tableIcons}
                onSearchChange={handleSearch}
                options={{
                  // pageSize: 10,
                  search: false,
                  paging: false,
                  //    grouping: true,
                  actionsColumnIndex: -1,
                  cellStyle: {
                    textAlign: rtl ? "right" : "left",
                  },
                  headerStyle: {
                    textAlign: rtl ? "right" : "left",
                  },
                }}
                localization={{
                  header: {
                    actions: t("actions"),
                  },
                  toolbar: {
                    searchPlaceholder: t("search"),
                  },
                }}
                actions={[
                  {
                    icon: () => (
                      <Edit
                        className="actionIcon"
                        style={{
                          color: "#0E6EB8",
                        }}
                      />
                    ),
                    tooltip: t("edit"),
                    //isFreeAction: true,
                    onClick: (event, rowData) => {
                      history.push(`${path}/edit/${rowData.emailLogsId}`, {
                        state: { rowData: rowData, index: 2 },
                        type: "Newsletter",
                      });
                    },
                  },
                  ,
                  {
                    icon: () => (
                      <Delete
                        style={{ fill: "#FF3636" }}
                        className="actionIcon"
                      />
                    ),
                    tooltip: t("delete"),
                    onClick: (event, rowData) => {
                      setRowDataID(rowData.emailLogsId);
                      setDeleteAlert(!deleteAlert);
                    },
                  },
                ]}
              />
            </ThemeProvider>
            <div className="d-flex justify-content-end align-items-center">
              <select
                value={pageSize}
                onChange={(e) => handleChange(e.target.value)}
                style={{
                  height: "36px",
                  borderRadius: 0,
                  width: "auto",
                  fontSize: "12px",
                }}
                className={"form-control"}
              >
                <option
                  key={5}
                  value={5}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  5
                </option>
                <option
                  key={10}
                  value={10}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  10
                </option>
                <option
                  key={15}
                  value={15}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  15
                </option>
                <option
                  key={20}
                  value={20}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  20
                </option>
                <option
                  key={50}
                  value={50}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  50
                </option>
                <option
                  key={100}
                  value={100}
                  style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
                >
                  100
                </option>
              </select>
              <IconButton
                aria-label="next"
                disabled={currentPage == 0 ? true : false}
              >
                {rtl ? (
                  <ArrowForwardIosSharpIcon
                    onClick={() => prev()}
                    style={{
                      color: "#d9dcde",
                    }}
                  />
                ) : (
                  <ArrowBackIosSharpIcon
                    onClick={() => prev()}
                    style={{
                      color: "#d9dcde",
                    }}
                  />
                )}
              </IconButton>
              <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
                {begin.current} -{" "}
                {end.current > itemCount ? itemCount : end.current}{" "}
                {rtl ? "من" : "of"} {itemCount}
              </p>
              <IconButton
                aria-label="next"
                disabled={end.current >= itemCount ? true : false}
              >
                {rtl ? (
                  <ArrowBackIosSharpIcon
                    onClick={() =>
                      history.push("..", {
                        type: "Newsletter",
                      })
                    }
                    style={{
                      color: "#d9dcde",
                    }}
                  />
                ) : (
                  <ArrowForwardIosSharpIcon
                    onClick={() =>
                      history.push("..", {
                        type: "Newsletter",
                      })
                    }
                    style={{
                      color: "#d9dcde",
                    }}
                  />
                )}
              </IconButton>
            </div>
            <hr className="mt-0"></hr>
          </>
        )}
      </Container>
    </div>
  );
}

export default NewslettersEmailLogsCard;
