import React, { useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { projectTypeService } from "_services";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { LanguageContext } from "_context";
import { countryService, newslettersService, industryService } from "_services";
import { Link } from "react-router-dom";
import { AccountTypes } from "_helpers";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ImageIcon from "@mui/icons-material/ImageRounded";
import CircleIcon from "@mui/icons-material/AddCircle";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
Quill.register("modules/imageResize", ImageResize);

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const roles = [
  AccountTypes.Seeker,
  AccountTypes.Provider,
  AccountTypes.Partner,
];

function AddNewsletters({ history, match, location }) {
  // const { id } = match.params;
  const { id } = useParams();
  const isAddMode = location.state.isAddMode;
  //const newsletter = location.state.newsletter;
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [newsletter, setNewsletter] = useState(null);
  const [confirmationAlert, setConfirmationAlert] = useState(false);
  const [imageAlert, setImageAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [countries, setCountries] = useState(0);
  const [width, setWidth] = useState(0);
  const [align, setAlign] = useState("left");
  const [html, setHtml] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [count, setCount] = React.useState(0);
  //   isAddMode ? "" : newsletter.newsletterBody
  // );
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  // const [initialValues, setInitialValues] = useState({
  //   title: isAddMode ? "" : newsletter.newsletterSubject,
  //   body: isAddMode ? "" : newsletter.newsletterBody,
  //   filters: "",
  // });
  const [initialValues, setInitialValues] = useState({
    title: "",
    body: "",
    filters: "",
  });
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const quillRef = React.useRef();
  const rangeRef = React.useRef({ index: 0 });
  const imgPath = React.useRef();

  // function imageHandler() {
  //   const input = document.createElement("input");

  //   input.setAttribute("type", "file");
  //   input.setAttribute("accept", "image/*");
  //   input.click();

  //   input.onchange = async () => {
  //     const quill = quillRef.current;

  //     // var file = input && input.files ? input.files[0] : null;
  //     var file = input.files[0];
  //     var imageType = /image.*/;

  //     if (file.type.match(imageType)) {
  //       var formData = new FormData();
  //       formData.append("image", file, file.filename);
  //     }
  //     let range = quill.getEditorSelection();
  //     console.log(range);
  //     // const range = quillObj.quill.getEditorSelection();
  //     await newslettersService
  //       .UploadImage(formData)
  //       .then((res) => {
  //         // const range = quillObj.getEditorSelection();
  //         // const imagePath = res.path.replace(/\\/g, "/");
  //         quill.getEditor().insertEmbed(range.index, "image", res.path);
  //         // quillObj.getEditor().insertEmbed(range.index, "image", res);
  //       })
  //       .catch((err) => {
  //         // message.error("This is an error message");
  //         console.log(err);
  //         return false;
  //       });
  //     // quillRef.current
  //     //   .getEditor()
  //     //   .insertEmbed(
  //     //     range.index,
  //     //     "image",
  //     //     "https://cdn.pixabay.com/photo/2016/11/29/12/13/fence-1869401_960_720.jpg"
  //     //   );
  //   };
  // }

  const modules = {
    toolbar: {
      container: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [({ direction: "" }, { direction: "rtl" })],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["link"],
        // ["image"],
        ["clean"],
      ],
      // handlers: {
      //   image: imageHandler,
      // },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    },
  };
  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "align",
    "background",
    "image",
    "link",
  ];

  const handleQuillChange = (html) => {
    setHtml(html);
    rangeRef.current =
      quillRef.current != null && quillRef.current.getEditorSelection();
  };

  const [recipients, setRecipients] = React.useState([]);
  //   isAddMode ? [] : newsletter.roles.split(",")
  // );
  const [countriesFilter, setCountriesFilter] = React.useState([]);
  //   isAddMode ? [] : newsletter.countriesId.split(",")
  // );
  const [categoryFilter, setCategoryFilter] = React.useState([]);
  const [industries, setIndustries] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRecipients(typeof value === "string" ? value.split(",") : value);
  };

  const handleCountriesChange = (event) => {
    const {
      target: { value },
    } = event;
    setCountriesFilter(typeof value === "string" ? value.split(",") : value);
  };

  const handleCategoriesChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategoryFilter(typeof value === "string" ? value.split(",") : value);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("fieldRequired")),
  });

  const fetchData = async (lan) => {
    setLoading(true);
    await countryService.GetCountryByLanguageAsync(lan).then((x) => {
      setCountries(x);
    });

    await industryService.GetIndustriesServicesAsync().then((x) => {
      setIndustries(x);
    });

    if (!isAddMode) {
      await newslettersService.GetNewsletterAsync(id).then((x) => {
        setNewsletter(x);
        setInitialValues({
          title: x.newsletterSubject,
          body: x.newsletterBody,
          filters: "",
        });
        setHtml(x.newsletterBody);
        setRecipients(x.roles);
        setCountriesFilter(x.countries);
        setCategoryFilter(x.categories);
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData(currentLan);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  const AddNewsletter = async () => {
    const model = {
      newsletterSubject: title,
      newsletterBody: html,
      countries: countriesFilter,
      roles: recipients,
      categories: categoryFilter,
    };
    if (isAddMode) {
      await newslettersService
        .AddNewslettersAsync(true, model)
        .then((res) => {
          history.push(".");
        })
        .catch((error) => {
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    } else {
      await newslettersService
        .UpdateNewslettersAsync(id, model)
        .then(() => {
          setAlert(!alert);
        })
        .catch((error) => {
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    }
  };

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setTitle(fields.title);
    const model = {
      newsletterSubject: fields.title,
      newsletterBody: html,
      countries: countriesFilter,
      roles: recipients,
      categories: categoryFilter,
    };
    if (isAddMode) {
      await newslettersService
        .AddNewslettersAsync(false, model)
        .then((res) => {
          setSubmitting(false);
          setCount(res);
          setConfirmationAlert(!confirmationAlert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    } else {
      await newslettersService
        .UpdateNewslettersAsync(id, model)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    }
  }

  const handleReceiverClick = () => {
    const quill = quillRef.current;
    if (quill.getEditor().getLength() > 1) {
      let range = quill.getEditorSelection();
      quill.getEditor().insertText(range.index, "{receiver}");
    }
  };
  const handleCompanyClick = () => {
    const quill = quillRef.current;
    if (quill.getEditor().getLength() > 1) {
      let range = quill.getEditorSelection();
      quill.getEditor().insertText(range.index, "{companyName}");
    }
  };

  const handleImageUpload = (file) => {
    var formData = new FormData();
    formData.append("image", file);

    // let range = quillRef.current.getEditorSelection();
    // console.log(range);
    setImageAlert(!imageAlert);
    newslettersService
      .UploadImage(formData)
      .then((res) => {
        setImageAlert(!imageAlert);
        imgPath.current = res.path;
        // quillRef.current
        //   .getEditor()
        //   .insertEmbed(range.index, "image", res.path);
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };
  return (
    <div className="section">
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("done")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>
              {isAddMode
                ? t("newsletters.add.success")
                : t("newsletters.update.success")}
            </p>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setAlert(!alert);
                history.push("..");
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={confirmationAlert}
          style={rtl ? { textAlign: "right" } : {}}
        >
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("done")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>
              {t("newsletters.confirmation.one")}
              {count}
              {t("newsletters.confirmation.two")}
            </p>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              className={rtl ? "mr-2" : ""}
              type="button"
              style={{ margin: "auto", color: "#fff" }}
              onClick={(e) => setConfirmationAlert(!confirmationAlert)}
            >
              {t("cancel.link")}
            </Button>
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setConfirmationAlert(!confirmationAlert);
                AddNewsletter();
              }}
            >
              {t("newsletter.send")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={imageAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title"></h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <Row className="align-items-center">
              <Col md="3">
                <p className="mb-0">{t("width.alert.body")}</p>
              </Col>
              <Col md="3">
                <input
                  name="width"
                  placeholder="in pixels"
                  onChange={(e) => setWidth(e.target.value)}
                  className="form-control"
                  style={{
                    borderColor: "rgba(0, 0, 0, 0.23)",
                    fontSize: "15px",
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-4 align-items-center">
              <Col md="3">
                <p className="mb-0">{t("align.alert.body")}</p>
              </Col>
              <Col md="9">
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="alignment"
                    defaultValue="left"
                    name="radio-buttons-group"
                    onChange={(e) => setAlign(e.target.value)}
                  >
                    <FormControlLabel
                      value="left"
                      control={<Radio />}
                      label="Left"
                      className="mb-0"
                    />
                    <FormControlLabel
                      value="center"
                      control={<Radio />}
                      label="Center"
                      className="mb-0"
                    />
                    <FormControlLabel
                      value="right"
                      control={<Radio />}
                      label="Right"
                      className="mb-0"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setImageAlert(!imageAlert);
                const img = `<p style="text-align: ${align}"><a href=\"#\"><img src="${imgPath.current}" width="${width}" / ></a></p>`;
                quillRef.current
                  .getEditor()
                  .insertText(rangeRef.current.index, img);
                // history.push("..");
              }}
            >
              {t("createCompanyProfile.save")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => {
              // if (!isAddMode) {
              //   setFieldValue("title", newsletter.newsletterSubject, false);
              //   setFieldValue("body", newsletter.newsletterBody, false);
              // }
              return (
                <Col md="8" style={{ margin: "auto" }}>
                  <Card className={rtl ? "text-right" : ""}>
                    <Form autoComplete="off">
                      <CardBody>
                        <Link
                          onClick={(e) =>
                            isAddMode ? history.push(".") : history.push("..")
                          }
                        >
                          {t("back")}
                        </Link>
                        <CardTitle className="text-center" tag="h4">
                          {t("newsletters.add")}
                        </CardTitle>
                        <Row>
                          <Col md="12">
                            <div className="form-group">
                              <FormGroup
                                className="col"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label htmlFor="title">
                                  {t("newsletters.recipient")}
                                </label>
                                <FormControl
                                  sx={{ m: 1, width: 300 }}
                                  style={{ margin: 0 }}
                                >
                                  {/* <InputLabel id="demo-multiple-name-label">{t("newsletters.recipient")}</InputLabel> */}
                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    value={recipients}
                                    onChange={handleChange}
                                    // input={<OutlinedInput label={t("newsletters.recipient")} />}
                                    style={{ height: "36px", borderRadius: 0 }}
                                    MenuProps={MenuProps}
                                  >
                                    {roles.map((role) => (
                                      <MenuItem key={role} value={role}>
                                        {role}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </FormGroup>
                            </div>
                            <div className="form-group">
                              <FormGroup
                                className="col"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label htmlFor="title">
                                  {t("newsletters.country")}
                                </label>
                                <FormControl
                                  sx={{ m: 1, width: 300 }}
                                  style={{ margin: 0 }}
                                >
                                  {/* <InputLabel id="demo-multiple-name-label">{t("newsletters.recipient")}</InputLabel> */}
                                  <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    value={countriesFilter}
                                    onChange={handleCountriesChange}
                                    // input={<OutlinedInput label={t("newsletters.recipient")} />}
                                    style={{ height: "36px", borderRadius: 0 }}
                                    MenuProps={MenuProps}
                                  >
                                    {countries &&
                                      countries.map((country) => (
                                        <MenuItem
                                          key={country.countryId}
                                          value={country.countryId}
                                        >
                                          {country.countryName}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              </FormGroup>
                            </div>
                            <div className="form-group">
                              <FormGroup
                                className="col"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label htmlFor="industry">
                                  {t("industries")}
                                </label>
                                <FormControl
                                  sx={{ m: 1, width: 300 }}
                                  style={{ margin: 0 }}
                                >
                                  {industries.length > 0 && (
                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="demo-multiple-name"
                                      multiple
                                      value={categoryFilter}
                                      onChange={handleCategoriesChange}
                                      style={{
                                        height: "36px",
                                        borderRadius: 0,
                                      }}
                                      MenuProps={MenuProps}
                                    >
                                      {industries &&
                                        industries.map((industry) => (
                                          <MenuItem
                                            key={industry.typeOfIndustryID}
                                            value={industry.typeOfIndustryID}
                                          >
                                            {industry.description}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  )}
                                </FormControl>
                              </FormGroup>
                            </div>
                            <div className="form-group">
                              <FormGroup
                                className="col"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="title">{t("title")}</label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="title"
                                >
                                  *
                                </label>
                                <Field
                                  name="title"
                                  placeholder={t("title")}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "title",
                                      e.target.value,
                                      false
                                    )
                                  }
                                  className={
                                    "form-control" +
                                    (errors.title && touched.title
                                      ? " is-invalid"
                                      : "")
                                  }
                                  style={{
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                    fontSize: "15px",
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                  }}
                                />
                                {errors.title && touched.title && (
                                  <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                )}
                              </FormGroup>
                            </div>

                            <div className="form-group">
                              <FormGroup className="col">
                                <label htmlFor="body">{t("body")}</label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="body"
                                >
                                  *
                                </label>
                                <div style={{ marginBottom: "0.5rem" }}>
                                  <Stack direction="row" spacing={1}>
                                    <Chip
                                      label={t("addReceiverName")}
                                      color="primary"
                                      variant="outlined"
                                      icon={
                                        <CircleIcon
                                          className={rtl ? "mr-2 ml-0" : ""}
                                        />
                                      }
                                      onClick={handleReceiverClick}
                                    />
                                    <Chip
                                      label={t("addCompanyName")}
                                      color="primary"
                                      variant="outlined"
                                      icon={
                                        <CircleIcon
                                          className={rtl ? "mr-2 ml-0" : ""}
                                        />
                                      }
                                      onClick={handleCompanyClick}
                                    />
                                    <label htmlFor="upload-photo">
                                      <input
                                        style={{ display: "none" }}
                                        id="upload-photo"
                                        name="upload-photo"
                                        type="file"
                                        onChange={(e) =>
                                          handleImageUpload(e.target.files[0])
                                        }
                                        accept="image/*"
                                      />
                                      <Chip
                                        style={{ cursor: "pointer" }}
                                        label={t("uploadImage")}
                                        color="primary"
                                        icon={
                                          <ImageIcon
                                            className={rtl ? "mr-2 ml-0" : ""}
                                          />
                                        }
                                        variant="outlined"
                                      />
                                    </label>
                                  </Stack>
                                </div>
                                <ReactQuill
                                  ref={quillRef}
                                  name="body"
                                  value={html}
                                  onChange={handleQuillChange}
                                  modules={modules}
                                  formats={formats}
                                />
                              </FormGroup>
                            </div>

                            {/* <div className="form-group">
                              <input
                                type="file"
                                name="file"
                                onChange={(e) => {
                                  console.log(e.target.files[0]);
                                  const formData = new FormData();
                                  formData.append("image", e.target.files[0]);
                                  newslettersService
                                    .UploadImage(formData)
                                    .then((res) => {
                                      console.log(res);
                                    });
                                }}
                              />
                            </div> */}

                            <div className="form-row">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <center>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {isAddMode
                                      ? t("newsletters.send")
                                      : t("newsletters.update")}
                                  </Button>
                                </center>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default AddNewsletters;
