import { config } from "../_helpers/config";
import { get } from "axios";
const baseUrl = `${config.apiUrl}`;

export const imageService = {
  GetPartnerLogoAsync,
  GetUserImageAsync,
  GetFeaturedInLogoAsync,
  GetVideoAsync,
};

function GetPartnerLogoAsync(imgPath) {
  return get(`${baseUrl}/GetPartnerLogo/${imgPath}`, {
    responseType: "blob",
    Accept: [
      "application/webp",
      "application/png",
      "application/jpg",
      "application/jpeg",
    ],
  }).then((response) => {
    return response;
  });
}

function GetUserImageAsync(imgPath) {
  return get(`${baseUrl}/GetUserImage/${imgPath}`, {
    responseType: "blob",
    Accept: [
      "application/webp",
      "application/png",
      "application/jpg",
      "application/jpeg",
    ],
  }).then((response) => {
    return response;
  });
}
function GetFeaturedInLogoAsync(imgPath) {
  return get(`${baseUrl}/GetFeaturedIn/${imgPath}`, {
    responseType: "blob",
    Accept: [
      "application/webp",
      "application/png",
      "application/jpg",
      "application/jpeg",
    ],
  }).then((response) => {
    return response;
  });
}
function GetVideoAsync(videoPath) {
  return get(`${baseUrl}/GetVideo/${videoPath}`, {
    responseType: "blob",
    Accept: [
      "application/mp4",
      "application/mov",
      "application/wmv",
      "application/mvi",
    ],
  }).then((response) => {
    return response;
  });
}
