import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyArhM41djyqjxEZUsqSGmSiI3QP0UTtUuE",
  authDomain: "outerpond-notifications.firebaseapp.com",
  projectId: "outerpond-notifications",
  storageBucket: "outerpond-notifications.appspot.com",
  messagingSenderId: "258229609187",
  appId: "1:258229609187:web:9d5468869d618c2bf21bd6",
};

export const requestPermissionAndGetToken = async () => {
  console.log("Requesting permission...");
  await Notification.requestPermission().then(async (permission) => {
    console.log(permission);

    if (permission === "granted") {
      console.log("Notification permission granted.");
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);
      await getToken(messaging, {
        vapidKey:
          "BParVgkmS2szEIwtef0LJF-_vvfeGFeDdhwKsw0-z5b06rhlBI6AmsyB1Dj3cv9NB-aFKJPMsIVwarM9XVHHpIs",
      })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem("token", currentToken);
            console.log("CurrentToken", currentToken);
          } else {
            console.log("No Token");
          }
        })
        .catch((error) => {
          console.log(error);
        });

      return 1;
    } else {
      console.log("No permission");
      return 0;
    }
  });
  return 0;
};
