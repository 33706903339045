import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import ListGroupItem from "reactstrap/lib/ListGroupItem";
import Badge from "reactstrap/lib/Badge";
import CardSubtitle from "reactstrap/lib/CardSubtitle";
import CardText from "reactstrap/lib/CardText";
import ListGroup from "reactstrap/lib/ListGroup";
import { partnerService } from "_services";
import { FileNames } from "_helpers";
import Modal from "reactstrap/lib/Modal";
import { LanguageContext } from "_context";

function HowTo({ props }) {
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  async function DownloadAPIDocumentation() {
    await partnerService
      .DownloadPartnerExcel(3)
      .then((x) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([x.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", FileNames.UploadAPI);
        document.body.appendChild(link);
        link.click();
        //    fileDownload(x.data, "UploadedSps.xlsx");
      })
      .catch((error) => {
        setDownloading(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        //   setErrorAlert(!errorAlert);
      });
  }

  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [downloading, setDownloading] = useState(false);

  return (
    <>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div className="modal-body col-md-12">
          <p>{errorMessage}</p>
        </div>

        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            style={
              rtl ? { margin: "auto", textAlign: "right" } : { margin: "auto" }
            }
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Card>
        <CardBody>
          <CardTitle tag="h4" style={{ marginTop: "-2px" }}>
            {t("invite.seekers.opportunities")}
          </CardTitle>

          <CardText>
            {t("howTo.inviteSeekers.title.one")}
            <br></br>
            {t("howTo.inviteSeekers.title.two")}
          </CardText>
          <ul>
            <li>{t("inviteSeekers.li.one")}</li>
            <li>{t("inviteSeekers.li.two")}</li>
            <li>{t("inviteSeekers.li.three")}</li>
            <li>{t("inviteSeekers.li.four")}</li>
          </ul>

          {/* <CardSubtitle className="mb-2 text-muted" style={{ marginTop: "10px" }}>
          {t("partner.howToUse")}
        </CardSubtitle> */}
          <CardSubtitle
            className="mb-2 text-muted"
            style={{ marginTop: "10px" }}
          >
            {t("partner.howToUse")}
          </CardSubtitle>
          <ListGroup flush>
            <ListGroupItem style={{ display: "flex" }}>
              <div
                style={{
                  paddingLeft: "3px",
                  paddingRight: "3px",
                  marginTop: "-2px",
                }}
              >
                <Badge color="primary">1</Badge>
              </div>
              {t("howToUse.one")}
            </ListGroupItem>
            <ListGroupItem style={{ display: "flex" }}>
              <div
                style={{
                  paddingLeft: "3px",
                  paddingRight: "3px",
                  marginTop: "-2px",
                }}
              >
                <Badge color="primary">2</Badge>
              </div>
              {t("howToUse.two")}
            </ListGroupItem>
            <ListGroupItem style={{ display: "flex" }}>
              <div
                style={{
                  paddingLeft: "3px",
                  paddingRight: "3px",
                  marginTop: "-2px",
                }}
              >
                <Badge color="primary">3</Badge>
              </div>
              {t("howToUse.three")}
            </ListGroupItem>
            <ListGroupItem style={{ display: "flex" }}>
              <div
                style={{
                  paddingLeft: "3px",
                  paddingRight: "3px",
                  marginTop: "-2px",
                }}
              >
                <Badge color="primary">4</Badge>
              </div>
              {t("howToUse.four")}
            </ListGroupItem>
            <ListGroupItem style={{ display: "flex" }}>
              <div
                style={{
                  paddingLeft: "3px",
                  paddingRight: "3px",
                  marginTop: "-2px",
                }}
              >
                <Badge color="primary">5</Badge>
              </div>
              {t("howToUse.five")}
            </ListGroupItem>
          </ListGroup>
          <CardSubtitle
            className="mb-2 text-muted"
            style={{ marginTop: "10px" }}
          >
            {t("howToUseAPI")}
          </CardSubtitle>
          <CardText>{t("downloadAPIdoc")}</CardText>
          <Button
            color="primary"
            style={{ marginTop: "-2px" }}
            onClick={(e) => {
              setDownloading(true);
              DownloadAPIDocumentation();
            }}
          >
            {downloading && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            {t("downloadAPIButton")}
          </Button>
        </CardBody>
      </Card>
    </>
  );
}

export default HowTo;
