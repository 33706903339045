import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditSections from "./AddEditSections";
import Sections from "./Sections";

function LandingPageSections({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={Sections} exact />
        <PrivateRoute path={`${path}/edit/:id`} component={AddEditSections} />
      </Switch>
    </div>
  );
}

export { LandingPageSections };
