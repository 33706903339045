import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/country`;

export const countryService = {
  GetCountryByLanguageAsync,
  GetProfilesCountryByLanguageAsync,
  GetProjectsCountryByLanguageAsync,
  GetAllCountriesAsync,
  GetDirecctLinksCountryByLanguageAsync,
  GetCountriesCountAsync,
  GetCountryByCodeAsync,
};

function GetAllCountriesAsync() {
  return fetchWrapper.post(`${baseUrl}/GetAllCountries`);
}

function GetCountryByLanguageAsync(lan) {
  return fetchWrapper.get(`${baseUrl}/GetCountryByLanguage/${lan}`);
}

function GetCountryByCodeAsync(lan) {
  return fetchWrapper.get(`${baseUrl}/GetCountryByCode/${lan}`);
}

function GetProfilesCountryByLanguageAsync(lan, sp) {
  return fetchWrapper.get(
    `${baseUrl}/GetProfilesCountryByLanguage/${lan}/${sp}`
  );
}

function GetProjectsCountryByLanguageAsync(lan) {
  return fetchWrapper.get(`${baseUrl}/GetProjectsCountryByLanguage/${lan}`);
}

function GetDirecctLinksCountryByLanguageAsync(lan) {
  return fetchWrapper.get(`${baseUrl}/GetDirecctLinksCountryByLanguage/${lan}`);
}

function GetCountriesCountAsync() {
  return fetchWrapper.get(`${baseUrl}/GetCountriesCount`);
}
