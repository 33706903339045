import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import RecentlyViewed from "./RecentlyViewed";

function SpSeen({ history, match }) {
  const { path } = match;
  return (
    <>
      <Switch>
        <PrivateRoute exact path={`${path}`} component={RecentlyViewed} />
      </Switch>
    </>
  );
}

export { SpSeen };
