import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditIfYouBelieveIn from "./AddEditIfYouBelieveIn";
import IfYouBelieveInList from "./IfYouBelieveInList";

function IfYouBelieveIn({ history, match }) {
    const { path } = match;
    return (
        <div>
            <Switch>
                <PrivateRoute path={`${path}`} component={IfYouBelieveInList} exact />
                <PrivateRoute path={`${path}/add`} component={AddEditIfYouBelieveIn} />
                <PrivateRoute
                    path={`${path}/edit/:id`}
                    component={AddEditIfYouBelieveIn}
                />
            </Switch>
        </div>
    );
}

export { IfYouBelieveIn };
