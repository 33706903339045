import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditDirectLink from "components/ServiceSeeker/DirectLinks/AddEditDirectLink";

function OpportunityDetails({ match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={AddEditDirectLink} exact />
      </Switch>
    </div>
  );
}

export default OpportunityDetails;
