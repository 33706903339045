import React, { useState, useContext } from "react";
import { ResponsiveCalendar } from "@nivo/calendar";
import { analyticsService } from "_services";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
function CalendarChart() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dateFrom, setdateFrom] = useState("2021-08-01");
  const [dateTo, setdateTo] = useState("2021-09-14");
  const fetchData = async () => {
    setLoading(true);
    await analyticsService.GetUserLogInsAsync(dateFrom, dateTo).then((x) => {
      setData(x);
    });

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <div className="section">
      <Container>
        <div style={{ height: "500px" }}>
          <h6 className="text-primary">{t("Logins-CalendarChart")}</h6>
          <ResponsiveCalendar
            data={data}
            from="2021-08-01"
            to="2021-08-31"
            emptyColor="#eeeeee"
            colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
            margin={{ top: 40, right: 40, bottom: 200, left: 40 }}
            yearSpacing={40}
            monthBorderColor="#ffffff"
            dayBorderWidth={2}
            dayBorderColor="#ffffff"
            legends={[
              {
                anchor: "bottom-right",
                direction: "row",
                translateY: 36,
                itemCount: 4,
                itemWidth: 42,
                itemHeight: 36,
                itemsSpacing: 14,
                itemDirection: "right-to-left",
              },
            ]}
          />
        </div>
      </Container>
    </div>
  );
}

export default CalendarChart;
