import React, { useState, useContext } from "react";
import { Row, Col } from "reactstrap";
import {
  profileService,
  projectSeenService,
  spSeenService,
} from "../../../_services";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import Badge from "reactstrap/lib/Badge";
import CardText from "reactstrap/lib/CardText";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import Tooltip from "reactstrap/lib/Tooltip";
import { LanguageContext } from "_context";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import Modal from "reactstrap/lib/Modal";
import { Button } from "react-scroll";
import { SearchListContext } from "_context";

function SpOverviewCard({
  history,
  match,
  searchResults,
  updateSearchResults,
  tabNumber,
}) {
  const user = profileService.userValue;
  const path = match;
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [searchList, setSearchList] = useContext(SearchListContext);

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);

  // React.useEffect(() => {
  //   document.body.classList.add("profile-page");
  //   document.body.classList.add("sidebar-collapse");
  //   document.documentElement.classList.remove("nav-open");
  //   document.body.style = "background-color: #ffffff";
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //   return function cleanup() {
  //     document.body.classList.remove("profile-page");
  //     document.body.classList.remove("sidebar-collapse");
  //   };
  // }, []);

  async function ToggleSPBookmark(model) {
    await spSeenService
      .ToggleSpSeenBookmarkAsync(model)
      .then((x) => {
        handleBookmarkFromBookmarked(model.spId, model.bookmark);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function handleBookmarkFromBookmarked(index, flag) {
    if (flag === true) {
      let updatedList = searchResults.map((item) => {
        if (item.profile.userId === index) {
          item.bookmarked = flag;
          return item;
        } else {
          return item; // else return unmodified item
        }
      });
      updateSearchResults(updatedList);
      let updatedSearchList = searchList.list.map((item) => {
        if (item.profile.userId === index) {
          item.bookmarked = flag;
          return item;
        } else {
          return item; // else return unmodified item
        }
      });
      const model = {
        totalCount: searchList.totalCount,
        list: updatedSearchList,
      };
      setSearchList(model);
    } else {
      if (tabNumber === "2") {
        const updatedList = searchResults.filter(
          (item) => item.profile.userId !== index
        );
        updateSearchResults(updatedList);
        let updatedSearchList = searchList.list.map((item) => {
          if (item.profile.userId === index) {
            item.bookmarked = flag;
            return item;
          } else {
            return item; // else return unmodified item
          }
        });
        const model = {
          totalCount: searchList.totalCount,
          list: updatedSearchList,
        };
        setSearchList(model);
      } else {
        let updatedList = searchResults.map((item) => {
          if (item.profile.userId === index) {
            item.bookmarked = flag;
            return item;
          } else {
            return item; // else return unmodified item
          }
        });
        updateSearchResults(updatedList);
        let updatedSearchList = searchList.list.map((item) => {
          if (item.profile.userId === index) {
            item.bookmarked = flag;
            return item;
          } else {
            return item; // else return unmodified item
          }
        });
        const model = {
          totalCount: searchList.totalCount,
          list: updatedSearchList,
        };
        setSearchList(model);
      }
    }
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div className="modal-body col-md-12">
          <p>{errorMessage}</p>
        </div>

        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            style={{ margin: "auto" }}
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      {searchResults &&
        searchResults.map((result) => {
          return (
            <Row>
              <Col md="8" style={{ margin: "auto" }}>
                <Card
                  style={
                    rtl
                      ? { marginBottom: "15px", direction: "ltr" }
                      : { marginBottom: "15px" }
                  }
                >
                  <CardBody style={{ paddingBottom: "5px" }}>
                    <Row>
                      <Col md="3">
                        <img
                          src={result.logo.filePath}
                          style={{
                            margin: "0 0 auto auto",
                            width: "123px",
                            height: "123px",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                      </Col>
                      <Col md="8" style={{ top: "50%" }}>
                        <Row style={{ marginTop: "10%" }}>
                          <Col md="10">
                            <Link
                              tag="h4"
                              style={{ fontSize: "18px" }}
                              onClick={() => {
                                if (
                                  path !== null &&
                                  path.path === "/seeker/seenSP"
                                ) {
                                  const win = window.open(
                                    `/seeker/search-provider/search-details/${result.profile.userId}?fromSeen=true`,
                                    "_self"
                                  );
                                  win.focus();
                                } else {
                                  const win = window.open(
                                    `/partner/search-provider/search-details/${result.profile.userId}?fromSeen=true`,
                                    "_self"
                                  );
                                  win.focus();
                                }
                              }}
                            >
                              {result.profile.companyName}
                            </Link>

                            {result.profile.openForPartnership === true ? (
                              <i
                                id="partnership"
                                className="fa fa-handshake"
                                style={{
                                  paddingLeft: "6px",
                                  paddingRight: "6px",
                                  fontSize: "18px",
                                  color: "#0E6EB8",
                                }}
                              >
                                <UncontrolledTooltip
                                  target="partnership"
                                  placement="right"
                                >
                                  {t("createCompanyProfile.openForPartnership")}
                                </UncontrolledTooltip>
                              </i>
                            ) : null}
                            {result.profile.financing === true ? (
                              <i
                                id="financing"
                                className="fa fa-dollar-sign"
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  fontSize: "18px",
                                  color: "#0E6EB8",
                                }}
                              >
                                <UncontrolledTooltip
                                  target="financing"
                                  placement="right"
                                >
                                  {t("createCompanyProfile.Financing")}
                                </UncontrolledTooltip>
                              </i>
                            ) : null}
                            {result.profile &&
                            result.profile.spType &&
                            result.profile.spType === "Start Up" ? (
                              <i
                                id="spStartup"
                                className="fa fa-rocket"
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  fontSize: "18px",
                                  color: "#0E6EB8",
                                }}
                              >
                                <UncontrolledTooltip
                                  target="spStartup"
                                  placement="right"
                                >
                                  {t("signUp.providerType.startup")}
                                </UncontrolledTooltip>
                              </i>
                            ) : null}
                            {result !== null &&
                            result.profile !== null &&
                            result.profile.supportingDocuments !== null &&
                            result.profile.supportingDocuments.length > 0 ? (
                              <i
                                id="documentsFound"
                                className="fa fa-file-text"
                                style={{
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  fontSize: "18px",
                                  color: "#0E6EB8",
                                }}
                              >
                                <UncontrolledTooltip
                                  target="documentsFound"
                                  placement="right"
                                >
                                  {t("supportingDocAvailable")}
                                </UncontrolledTooltip>
                              </i>
                            ) : null}
                          </Col>
                          <Col md="2" style={{ top: "50%" }}>
                            <Row style={{ marginTop: "10%" }}>
                              <Link
                                tag="h4"
                                style={{ fontSize: "18px" }}
                                onClick={() => {
                                  history.push(
                                    `messaging/${result.profile.userId}`
                                  );
                                }}
                              >
                                <i
                                  id="message"
                                  className="fa fa-comments"
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    color: "#0E6EB8",
                                  }}
                                ></i>

                                <UncontrolledTooltip
                                  // className="custom-tooltip"
                                  target="message"
                                  placement="bottom"
                                >
                                  {t("message.sp")}
                                </UncontrolledTooltip>
                              </Link>
                              <Link
                                id="bookmark"
                                hidden={result.bookmarked === true}
                                tag="h4"
                                style={{ fontSize: "18px" }}
                              >
                                <i
                                  className="now-ui-icons location_bookmark"
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    color: "#0E6EB8",
                                  }}
                                  onClick={async () => {
                                    const model = {
                                      spId: result.profile.userId,
                                      userId: user.profile.userId,
                                      bookmark: true,
                                    };
                                    await ToggleSPBookmark(model);
                                  }}
                                ></i>
                                <UncontrolledTooltip
                                  target="bookmark"
                                  placement="right"
                                >
                                  {t("bookmark.click")}
                                </UncontrolledTooltip>
                              </Link>
                              <Link
                                id="unbookmark"
                                hidden={result.bookmarked === false}
                                tag="h4"
                                style={{ fontSize: "18px" }}
                              >
                                <i
                                  className="fa fa-bookmark"
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    color: "#0E6EB8",
                                  }}
                                  onClick={async () => {
                                    const model = {
                                      spId: result.profile.userId,
                                      userId: user.profile.userId,
                                      bookmark: false,
                                    };
                                    await ToggleSPBookmark(model);
                                  }}
                                ></i>
                                <UncontrolledTooltip
                                  target="unbookmark"
                                  placement="bottom"
                                >
                                  {t("unbookmark.click")}
                                </UncontrolledTooltip>
                              </Link>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <CardText style={{ display: "flex" }}>
                            <Col md="12">
                              <ReactCountryFlag
                                countryCode={result.countryView.countryCode}
                                svg
                                style={{
                                  width: "1em",
                                  height: "1em",
                                  marginTop: "-2px",
                                  marginRight: "10px",
                                  borderRadius: "0px",
                                }}
                                title="US"
                              />
                              <small style={{ fontSize: "80%" }}>
                                {result.countryView.countryName}
                              </small>
                            </Col>
                          </CardText>
                        </Row>
                        <Row>
                          {result.profile &&
                            result.profile.spType &&
                            result.profile.spType === "Start Up" && (
                              <>
                                {/* <div
                                            style={{
                                              paddingLeft: "3px",
                                              paddingRight: "3px",
                                            }}
                                          >
                                            <Badge
                                              style={{
                                                marginLeft: "10px",
                                                marginTop: "5px",
                                                backgroundColor: "#5de33b",
                                                border: "none",
                                                fontSize: "80%",
                                              }}
                                            >
                                              {result.profile.spType}
                                            </Badge>
                                          </div> */}
                                {result.profile.startupStage ===
                                "Seed stage" ? (
                                  <Badge
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      marginTop: "5px",
                                      backgroundColor: "#15d134",
                                      border: "none",
                                      fontSize: "80%",
                                    }}
                                  >
                                    {result.profile.startupStage}
                                  </Badge>
                                ) : result.profile.startupStage ===
                                  "Early stage" ? (
                                  <Badge
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      marginTop: "5px",
                                      backgroundColor: "#12ad2c",
                                      border: "none",
                                      fontSize: "80%",
                                    }}
                                  >
                                    {result.profile.startupStage}
                                  </Badge>
                                ) : (
                                  <Badge
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      marginTop: "5px",
                                      backgroundColor: "#0d8d22",
                                      border: "none",
                                      fontSize: "80%",
                                    }}
                                  >
                                    {result.profile.startupStage}
                                  </Badge>
                                )}
                              </>
                            )}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <CardText
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: "14px",
                          }}
                        >
                          {result.profile.overview}
                        </CardText>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      {result.industriesAndServices &&
                        result.industriesAndServices.map((industry) => {
                          return (
                            <>
                              <div
                                style={{
                                  paddingLeft: "3px",
                                  paddingRight: "3px",
                                }}
                              >
                                <Badge
                                  style={{
                                    backgroundColor: "#f5f5fa",
                                    color: "#000000",
                                    border: "none",
                                    fontSize: "80%",
                                  }}
                                >
                                  {industry.industryName}
                                </Badge>
                              </div>
                              {industry.services &&
                                industry.services.map((service) => {
                                  return (
                                    <div
                                      style={{
                                        paddingLeft: "3px",
                                        paddingRight: "3px",
                                      }}
                                    >
                                      <Badge
                                        style={{
                                          backgroundColor: "#f5f5fa",
                                          color: "#000000",
                                          border: "none",
                                          fontSize: "80%",
                                        }}
                                      >
                                        {service.serviceName}
                                      </Badge>
                                    </div>
                                  );
                                })}
                            </>
                          );
                        })}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          );
        })}
    </>
  );
}

export default SpOverviewCard;
