import React, { useState, createContext } from "react";
import { profileService } from "_services";
// Create Context Object
export const SearchPartnerContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const SearchPartnerContextPartner = props => {

  const user = profileService.userValue;
  const initialModel = {
    userPerforminSearchId: user !== null ? user.profile.userId : '',
    searchIndex: 0,
    openForPartnership: null,
    searchKeyWord: '',
    industries: null,
    countries: [],
    typeOfIndustriesAndServices: [],
    NumberOfEmployeesId: [],
    filterType: "Ascending",
    filterBy: "CompanyName",
  }
  const [searchModel, setSearchModel] = useState(initialModel);
  // useEffect(() => {
  // }, [ searchModel]);

  return (
    <SearchPartnerContext.Provider value={[searchModel, setSearchModel]}>
      {props.children}
    </SearchPartnerContext.Provider>
  );
};