import React, { useState, useContext, useRef, useCallback } from "react";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import * as Yup from "yup";
import { projectTypeService, whowearetypesService } from "_services";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import { languageService } from "_services";
import { adminService } from "_services";
import { LanguageContext } from "_context";
// import "../../../../assets/css/generalStyles.css";
// import "../../../../assets/css/overrideReactCrop.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function AddEditAffiliated({ history, match, location }) {
  const isAddMode = !match.params.id;
  const [partners, setPartners] = useState(null);
  const [loading, setLoading] = useState(true);
  const [languages, setLanguages] = useState(null);
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [initialValues, setInitialValues] = useState({
    personFName: "",
    personLName: "",
    personLinkedInUrl: "",
    userImage: "",
    personType: "",
    personOrder: "",
  });
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  function initialForm(translations) {
    var newJson = {
      companyName: null,
      userName: null,
      additionalInfo: null,
      selectedForAffiliated: false,
    };
    translations.map((x) => {
      var newKey = x.languageName;
      var newVal = "";
      newJson[newKey] = newVal;
    });
    setInitialValues(newJson);
  }

  function editInitialForm(partner) {
    var _fetchedPartner = {
      companyName: partner.companyName,
      userName: partner.userName,
      additionalInfo: partner.additionalInfo,
      selectedForAffiliated: partner.selectedForAffiliated,
    };

    setInitialValues(_fetchedPartner);
  }

  const fetchData = async () => {
    setLoading(true);
    if (!isAddMode) {
      const model = {
        _id: match.params.id,
      };
      await adminService.GetExternalUser(model).then((x) => {
        editInitialForm(location.state.state);
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    console.log(fields);
    setStatus();

    if (isAddMode) {
      const model = {
        CompanyName: fields.companyName,
        UserName: fields.userName,
        AdditionalInfo: fields.additionalInfo,
        SelectedForAffiliated: fields.selectedForAffiliated,
      };
      await adminService
        .CreateExternalUser(model)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (
            error !== null &&
            error.response !== null &&
            error.response.data !== null &&
            error.response.data.message !== undefined
          ) {
            setError(error.response.data.message);
            if (
              error.response.data.error !== null &&
              error.response.data.error.length > 0
            ) {
              for (var i = 0; i < error.response.data.error.length; i++) {
                if (error.response.data.error[i].languageName === currentLan) {
                  setError(error.response.data.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
      setSubmitting(false);
    } else {
      const model = {
        CompanyName: fields.companyName,
        UserName: fields.userName,
        AdditionalInfo: fields.additionalInfo,
        SelectedForAffiliated: fields.selectedForAffiliated,
        _id: match.params.id,
      };
      await adminService
        .UpdateExternalUser(model)
        .then(() => {
          setSubmitting(false);
          setAlert(!alert);
        })
        .catch((error) => {
          setSubmitting(false);
          if (
            error !== null &&
            error.response !== null &&
            error.response.data !== null &&
            error.response.data.message !== undefined
          ) {
            setError(error.response.data.message);
            if (
              error.response.data.error !== null &&
              error.response.data.error.length > 0
            ) {
              for (var i = 0; i < error.response.data.error.length; i++) {
                if (error.response.data.error[i].languageName === currentLan) {
                  setError(error.response.data.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
      setSubmitting(false);
    }
  }

  return (
    <div className="section">
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("addProjectType.title")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>{isAddMode ? t("addPerson.body") : t("editPerson.body")}</p>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => {
                setAlert(!alert);
                if (isAddMode) {
                  history.push(".");
                } else {
                  history.push("..");
                }
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            // validationSchema={validationSchema}
          >
            {({ setFieldValue, errors, touched, isSubmitting }) => {
              return (
                <Col md="8" style={{ margin: "auto" }}>
                  <Card className={rtl ? "text-right" : ""}>
                    <Form autoComplete="off">
                      <CardBody>
                        <CardTitle className="text-center" tag="h4">
                          {isAddMode ? t("addPerson") : t("editPerson")}
                        </CardTitle>
                        <Row>
                          <Col md="12">
                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="admin.users.companyName">
                                  {t("admin.users.companyName")}
                                </label>
                                <Field
                                  autoFocus={true}
                                  // disabled={!isAddMode}
                                  name="companyName"
                                  placeholder={t("admin.users.companyName")}
                                  className={"form-control"}
                                />
                              </FormGroup>
                            </div>

                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="admin.users.userName">
                                  {t("admin.users.userName")}
                                </label>
                                <Field
                                  // disabled={!isAddMode}
                                  name="userName"
                                  placeholder={t("admin.users.userName")}
                                  className={"form-control"}
                                />
                              </FormGroup>
                            </div>

                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="logs.additionalInfo">
                                  {t("logs.additionalInfo")}
                                </label>
                                <Field
                                  name="additionalInfo"
                                  placeholder={t("logs.additionalInfo")}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.projectLink && touched.projectLink
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {errors.projectLink && touched.projectLink ? (
                                  <ErrorMessage
                                    name="projectLink"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                ) : (
                                  <span className="isValid" component="div">
                                    <br></br>
                                  </span>
                                )}
                              </FormGroup>
                            </div>
                            <div className="form-row">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <center>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {isAddMode
                                      ? t("add.button")
                                      : t("createCompanyProfile.save")}
                                  </Button>
                                </center>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default AddEditAffiliated;
