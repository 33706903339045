import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/testimonial`;

export const testimonialService = {
  GetAllAsync,
  GetTestimonialAsync,
  AddTestimonialsAsync,
  DeleteTestimonialAsync,
  UpdateTestimonialAsync,
  GetTestimonialByLanguageAsync,
};

function GetAllAsync(model) {
  return fetchWrapper.post(`${baseUrl}/getAll`, model);
}

function GetTestimonialAsync(id) {
  return fetchWrapper.get(`${baseUrl}/GetById/${id}`);
}

function AddTestimonialsAsync(model) {
  return fetchWrapper.post(`${baseUrl}`, model);
}

function DeleteTestimonialAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function UpdateTestimonialAsync(id, model) {
  return fetchWrapper.put(`${baseUrl}/${id}`, model);
}

function GetTestimonialByLanguageAsync(id) {
  return fetchWrapper.get(`${baseUrl}/GetTestimonialByLanguage/${id}`);
}
