import {config} from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/projectType`;

export const projectTypeService = {
    GetAllProjectTypeAsync,
    GetProjectTypeByLanguageAsync
};

function GetAllProjectTypeAsync() {
    return fetchWrapper.get(`${baseUrl}/GetAllProjectType`);
}

function GetProjectTypeByLanguageAsync(lan) {
    return fetchWrapper.get(`${baseUrl}/GetProjectTypeByLanguage/${lan}`);
}


