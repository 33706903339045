import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import ProfilePage from "../Profile/CompanyProfilePage/ProfilePage";
import ProviderNavbar from "components/Navbars/ProviderNavbar";
import EditProfile from "components/Profile/EditProfile";
import DefaultFooter from "components/Footers/DefaultFooter";
import { AccountSettings } from "components/Profile/AccountSettings/Index";
import { Search } from "./Search/Index";
import { SearchProjectContextProvider } from "_context";
import { ProjectSearchListContextProvider } from "_context";
import { DirectLinksListContextProvider } from "_context";
import MyProjects from "./Search/MyProjects";
import Message from "components/Messages/messaging";
import CompanyDetails from "components/Messages/CompanyDetails";
import { useMediaQuery } from "react-responsive";
import ProviderNavbarMobileTablet from "components/Navbars/ProviderNavbarMobileTablet";
import { AccountTypes } from "_helpers";
import DirectLinkDetails from "./Search/DirectLinkDetails";
import PublishedByProfile from "./Search/PublishedByProfile";
import { DirectLinkCriteriaContextProvider } from "_context";
import { DirectSearchResultsContextProvider } from "_context";
import SearchDirectLinks from "./Search/SearchDirectLinks";
import { SurveyResponse } from "./SurveyResponse";
import { Survey } from "./Survey/Index";
// import MembershipHistoryPage from "components/Profile/CompanyProfilePage/Membership/MembershipHistoryPage";
// import ActiveMembership from "components/Profile/CompanyProfilePage/Membership/ActiveMembership";
// import { Insights } from "components/Profile/CompanyProfilePage/Insights/Index";
function Provider({ history, match }) {
  const { path } = match;
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  return (
    <>
      {isDesktopOrLaptop && <ProviderNavbar props={history} />}
      {!isDesktopOrLaptop && <ProviderNavbarMobileTablet props={history} />}
      <Switch>
        <SearchProjectContextProvider>
          <ProjectSearchListContextProvider>
            <DirectLinksListContextProvider>
              <DirectLinkCriteriaContextProvider>
                <DirectSearchResultsContextProvider>
                  <PrivateRoute
                    path={`${path}/profile-page`}
                    component={ProfilePage}
                    roles={[AccountTypes.Provider]}
                    exact
                  />
                  <PrivateRoute
                    path={`${path}/profile-page/edit`}
                    component={EditProfile}
                    roles={[AccountTypes.Provider]}
                    exact
                  />
                  <PrivateRoute
                    path={`${path}/accountSettings`}
                    component={AccountSettings}
                    roles={[AccountTypes.Provider]}
                    exact
                  />
                  <PrivateRoute
                    path={`${path}/search-project`}
                    component={Search}
                  />
                  <PrivateRoute
                    exact
                    path={`${path}/search-direct-links`}
                    component={SearchDirectLinks}
                  />
                  <PrivateRoute
                    exact
                    path={`${path}/search-direct-links/:projectID`}
                    component={DirectLinkDetails}
                  />
                  <PrivateRoute
                    exact
                    path={`${path}/search-direct-links/:projectID/:profileId`}
                    component={PublishedByProfile}
                  />
                  <PrivateRoute
                    path={`${path}/myProjects`}
                    component={MyProjects}
                  />
                  <PrivateRoute
                    exact
                    path={`${path}/messaging/:msgOpportunity?/:id?`}
                    component={Message}
                  />
                  <PrivateRoute
                    path={`${path}/msgCompany/:userId/:projectId?`}
                    component={CompanyDetails}
                    exact
                  />
                  <PrivateRoute
                    path={`${path}/survey`}
                    component={SurveyResponse}
                  />
                  {/* <PrivateRoute
                    path={`${path}/profile-page/membershipHistory`}
                    component={MembershipHistoryPage}
                    exact
                  />
                  <PrivateRoute
                    path={`${path}/profile-page/activeMembership`}
                    component={ActiveMembership}
                    exact
                  />
                  <PrivateRoute
                    path={`${path}/profile-page/Insights`}
                    component={Insights}
                  /> */}
                  <PrivateRoute path={`${path}/pending`} component={Survey} />
                </DirectSearchResultsContextProvider>
              </DirectLinkCriteriaContextProvider>
            </DirectLinksListContextProvider>
          </ProjectSearchListContextProvider>
        </SearchProjectContextProvider>
      </Switch>
    </>
  );
}

export { Provider };
