import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/budget`;

export const budgetService = {
    GetAllBudgetsAsync
};

function GetAllBudgetsAsync() {
    return fetchWrapper.get(`${baseUrl}/GetAllBudgets`);
}


