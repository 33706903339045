import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AdminHome from "./AdminHome";
import { useMediaQuery } from "react-responsive";
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminNavbarMobileTablet from "components/Navbars/AdminNavbarMobileTablet";
import { ProjectTypes } from "./ProjectTypes/Index";
import { SystemSetup } from "./SystemSetup/Index";
import { Countries } from "./Countries/Index";
import { Budgets } from "./Budgets/Index";
import { NbEmployees } from "./NbEmployees/Index";
import { Industries } from "./Industries/Index";
import { Users } from "./Users/index";
import { AccountSettings } from "./AccountSettings/Index";
import { SystemVariables } from "./SystemVariables/Index";
import { FeaturedOpportunities } from "./FeaturedOpportunities/Index";
import { ExternalOpportunities } from "./ExternalOpportunities/Index";
import { SystemJobs } from "./SystemJobs/Index";
import { AdminCMS } from "./AdminCMS/Index";
import { ServiceProviders } from "./ServiceProviders/Index";
import { Analytics } from "./Analytics/Index";
// import { Plans } from "./Plans/Index";
import { EmailLogs } from "./EmailLogs/Index";
import { Newsletter } from "./Newsletter/Index";
import { Partners } from "./Partners/Index";
import { Badges } from "./Badges/Index";
import { Survey } from "./Survey/Index";
import { Messages } from "./Messages";
import { Logs } from "./Logs";
import { SurveyTypes } from "./SurveyTypes/Index";
import { AffiliatedUsers } from "./Affiliated/Index";
// import { Transactions } from "./Transactions/Index";
function AdminPanel({ history, match }) {
  const { path } = match;
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  return (
    <div>
      {isDesktopOrLaptop && <AdminNavbar props={history} />}
      {!isDesktopOrLaptop && <AdminNavbarMobileTablet props={history} />}
      <Switch>
        <PrivateRoute path={`${path}`} component={AdminHome} exact />
        <PrivateRoute path={`${path}/project-types`} component={ProjectTypes} />
        <PrivateRoute path={`${path}/system-setup`} component={SystemSetup} />
        <PrivateRoute path={`${path}/countries`} component={Countries} />
        <PrivateRoute path={`${path}/budgets`} component={Budgets} />
        <PrivateRoute path={`${path}/nbEmployees`} component={NbEmployees} />

        <PrivateRoute path={`${path}/industries`} component={Industries} />
        <PrivateRoute path={`${path}/users`} component={Users} />
        <PrivateRoute path={`${path}/account`} component={AccountSettings} />
        <PrivateRoute path={`${path}/variables`} component={SystemVariables} />
        <PrivateRoute
          path={`${path}/featured-opportunities`}
          component={FeaturedOpportunities}
        />
        <PrivateRoute
          path={`${path}/external-opportunities`}
          component={ExternalOpportunities}
        />
        <PrivateRoute path={`${path}/system-jobs`} component={SystemJobs} />
        <PrivateRoute path={`${path}/cms`} component={AdminCMS} />
        <PrivateRoute path={`${path}/analytics`} component={Analytics} />
        <PrivateRoute
          path={`${path}/service-providers`}
          component={ServiceProviders}
        />
        {/* <PrivateRoute path={`${path}/plans`} component={Plans} /> */}
        <PrivateRoute path={`${path}/emailLogs`} component={EmailLogs} />
        <PrivateRoute path={`${path}/newsletters`} component={Newsletter} />
        <PrivateRoute path={`${path}/partners`} component={Partners} />
        <PrivateRoute path={`${path}/badges`} component={Badges} />
        <PrivateRoute path={`${path}/surveys`} component={Survey} />
        <PrivateRoute path={`${path}/surveyTypes`} component={SurveyTypes} />
        <PrivateRoute path={`${path}/messages`} component={Messages} />
        <PrivateRoute path={`${path}/logs`} component={Logs} />
        {/* <PrivateRoute path={`${path}/transactions`} component={Transactions} /> */}
        <PrivateRoute
          path={`${path}/affiliatedUsersList`}
          component={AffiliatedUsers}
        />
      </Switch>
    </div>
  );
}

export { AdminPanel };
