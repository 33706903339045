import LoggedInUserNavbar from "components/Navbars/LoggedInUserNavbar";
import React from "react";
import { Route, Switch } from "react-router-dom";
import MembershipHistoryPage from "./CompanyProfilePage/Membership/MembershipHistoryPage";
import CreateProfile from "./CreateProfile";
import EditProfile from "./EditProfile";

function Profile({ history, match }) {
  const { path } = match;
  console.log(path);
  return (
    <>
      <LoggedInUserNavbar props={history} />
      <Switch>
        <Route path={`${path}/create-profile`} component={CreateProfile} />
        <Route path={`${path}/edit-profile`} component={EditProfile} />
      </Switch>
    </>
  );
}

export { Profile };
