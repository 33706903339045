import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "_context";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import FormGroup from "reactstrap/lib/FormGroup";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { surveyService } from "_services/survey.service.js";
import { SurveyAnswerTypes } from "_helpers/SurveyAnswerTypes";
import { useReducer } from "react";
import { profileService } from "_services";
import { surveyResponseService } from "_services/surveyResponse.service";
import { adminService } from "_services";

const initState = {};

function reducer(state, action) {
  switch (action.type) {
    case "TYPE_RADIO": {
      return { ...state, [action.prop]: action.value };
    }
    default:
      return state;
  }
}

function AddSurveyResponse({ history, location, match }) {
  const { path } = match;
  const id1 = match.params.id;
  const id2 = match.params.id2;
  const id3 = match.params.id3 != null ? match.params.id3 : "";
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [survey, setSurvey] = useState(null);
  const [surveyDetails, setSurveyDetails] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [providers, setProviders] = useState(null);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  // const survey = location.state != null && location.state.state.surveys;
  const user = profileService.userValue;
  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();

    const model = {
      userId: user.profile.userId,
      surveyID: id1,
      surveyResponseID: id2,
      filledSurvey: true,
      answers: formState,
    };

    function checkProperties(obj) {
      let count = 0;
      let list = [];

      questions.forEach((question) => {
        if (question.questionType == SurveyAnswerTypes.Dropdown) {
          count += 1;

          list.push(question.questionID);
        }
      });
      console.log(questions.length);
      console.log(Object.keys(obj).length);
      if (Object.keys(obj).length < questions.length - count) {
        return false;
      } else {
        for (var key in obj) {
          console.log(key);
          if (list.includes(key) == false) {
            if (obj[key] !== null && obj[key] != "") return true;
          }
        }
      }
    }

    if (checkProperties(formState) == false) {
      setError(t("required.all"));
      setErrorAlert(!errorAlert);
    } else {
      await surveyResponseService
        .AddSurveyResponse(model)
        .then(() => {
          setSuccessAlert(!successAlert);
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    }
  }

  const fetchData = async () => {
    setLoading(true);
    const checkingModel = {
      UserID: user.profile.userId,
      SurveyID: id1,
      SurveyResponseID: id2,
    };
    await surveyResponseService
      .CheckingSurveyValidity(checkingModel)
      .then(() => {})
      .catch((error) => {
        history.push(`/index`, { fromSurveyError: error });
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });

    if (id3 != null && id3 != "" && id3 != undefined) {
      await adminService.GetOpportunityViewersAsync(id3, false).then((x) => {
        setProviders(x);
      });
      // await adminService
      //   .(id3)
      //   .then((x) => {
      //     setProviders(x);
      //   });
    }

    await surveyService.GetSurveyByLanguageName(id1, currentLan).then((x) => {
      if (x != null && x.surveys != null) {
        setSurvey(x);
        setSurveyDetails(x.surveys);
        setQuestions(x.surveys.questions);
      }
    });
    setLoading(false);
  };

  const [formState, dispatch] = useReducer(reducer, initState);
  React.useEffect(() => {
    fetchData();

    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <Modal isOpen={successAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("survey.thankYou")}</h5>
        </div>
        <div className="modal-body">
          <p>{t("surveyResponseSentSuccess")}</p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            style={
              rtl ? { margin: "auto", textAlign: "right" } : { margin: "auto" }
            }
            onClick={(e) => {
              setSuccessAlert(!successAlert);
              history.push("/seeker/search-provider");
            }}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
        <div className="modal-header text-center" style={{ margin: "0 auto" }}>
          <h5 className="modal-title">{t("error.alert")}</h5>
        </div>
        <div className="modal-body col-md-12">
          <p>{errorMessage}</p>
        </div>

        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            style={
              rtl ? { margin: "auto", textAlign: "right" } : { margin: "auto" }
            }
            onClick={(e) => setErrorAlert(!errorAlert)}
          >
            {t("close.button")}
          </Button>
        </div>
      </Modal>
      {survey != null && surveyDetails != null && (
        <Row
          style={{
            marginRight: "auto",
            marginLeft: "auto",
            display: "flex",
            justifyContent: "center",
            paddingTop: "120px",
            height: "100%",
            // backgroundColor: "#EEF5FB",
          }}
        >
          <Col className="col-12 d-flex justify-content-center">
            <Card
              style={
                rtl
                  ? {
                      width: "50%",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow: "none",
                      border: "1px solid #D9D9D9",
                      borderRadius: "10px",
                      textAlign: "right",
                    }
                  : {
                      width: "50%",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow: "none",
                      border: "1px solid #D9D9D9",
                      borderRadius: "10px",
                    }
              }
            >
              <div
                style={{
                  width: "100%",
                  height: "6px",
                  background: "#6CAEF2 0% 0% no-repeat padding-box",
                  borderRadius: "5px 5px 0px 0px",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                }}
              ></div>
              <CardBody className="pl-0 pr-0 pb-0">
                <Row>
                  <Col className="p-0">
                    <div className="form-group">
                      <p
                        name="surveyTitle"
                        style={{
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          fontSize: "24px",
                        }}
                        className={"form-control"}
                      >
                        {" "}
                        {surveyDetails.surveyTitle}{" "}
                      </p>
                    </div>
                    <div className="">
                      <p
                        name="surveyDescription"
                        style={
                          rtl
                            ? {
                                color: "#4f4f4f",
                                fontSize: "14px",
                                paddingLeft: "18px",
                                marginRight: "20px",
                              }
                            : {
                                color: "#4f4f4f",
                                fontSize: "14px",
                                paddingLeft: "18px",
                              }
                        }
                        className={""}
                      >
                        {" "}
                        {surveyDetails.surveyDescription}{" "}
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting }) => {
              return (
                <>
                  <Form className="w-100">
                    {questions.length > 0 &&
                      questions.map((question, index) => (
                        <Row className="w-100">
                          {question.questionType == SurveyAnswerTypes.YesNo && (
                            <Col
                              sx="12"
                              style={{
                                margin: "auto",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Card
                                style={
                                  rtl
                                    ? {
                                        width: "50%",
                                        background:
                                          "#FFFFFF 0% 0% no-repeat padding-box",
                                        boxShadow: "none",
                                        border: "1px solid #D9D9D9",
                                        borderRadius: "10px",
                                        textAlign: "right",
                                      }
                                    : {
                                        width: "50%",
                                        background:
                                          "#FFFFFF 0% 0% no-repeat padding-box",
                                        boxShadow: "none",
                                        border: "1px solid #D9D9D9",
                                        borderRadius: "10px",
                                      }
                                }
                              >
                                <Form autoComplete="off">
                                  <CardBody>
                                    <Row>
                                      <Col md="12">
                                        <div className="form-group">
                                          <div
                                            style={{
                                              margin: "0 auto",
                                              width: "100%",
                                              padding: "10px",
                                              marginTop: "-2%",
                                              display: "flex",
                                            }}
                                            onChange={(e) =>
                                              dispatch({
                                                type: "TYPE_RADIO",
                                                prop: question.questionID,
                                                value: e.target.value,
                                              })
                                            }
                                          >
                                            <p>{question.questionText}</p>
                                            <label
                                              style={{
                                                fontSize: "16px",
                                                color: "red",
                                                paddingLeft: "5px",
                                              }}
                                            >
                                              *
                                            </label>
                                          </div>
                                        </div>
                                        <div className="form-row">
                                          <FormGroup
                                            style={{
                                              margin: "0 auto",
                                              width: "100%",
                                              padding: "10px",
                                              marginTop: "-2%",
                                            }}
                                          >
                                            <div>
                                              <label>
                                                <Field
                                                  name={`yesNo${index}`}
                                                  type="radio"
                                                  value="yes"
                                                  style={
                                                    rtl
                                                      ? { marginLeft: "10px" }
                                                      : {
                                                          marginRight: "10px",
                                                        }
                                                  }
                                                  onClick={(e) =>
                                                    dispatch({
                                                      type: "TYPE_RADIO",
                                                      prop: question.questionID,
                                                      value: e.target.value,
                                                    })
                                                  }
                                                />
                                                {t("radio.yes")}
                                              </label>
                                            </div>
                                            <div>
                                              <label>
                                                <Field
                                                  name={`yesNo${index}`}
                                                  type="radio"
                                                  value="no"
                                                  onClick={(e) =>
                                                    dispatch({
                                                      type: "TYPE_RADIO",
                                                      prop: question.questionID,
                                                      value: e.target.value,
                                                    })
                                                  }
                                                  style={
                                                    rtl
                                                      ? { marginLeft: "10px" }
                                                      : {
                                                          marginRight: "10px",
                                                        }
                                                  }
                                                />
                                                {t("radio.no")}
                                              </label>
                                            </div>
                                          </FormGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Form>
                              </Card>
                            </Col>
                          )}
                          {question.questionType == SurveyAnswerTypes.Text && (
                            <Col
                              sx="12"
                              style={{
                                margin: "auto",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Card
                                style={
                                  rtl
                                    ? {
                                        width: "50%",
                                        background:
                                          "#FFFFFF 0% 0% no-repeat padding-box",
                                        boxShadow: "none",
                                        border: "1px solid #D9D9D9",
                                        borderRadius: "10px",
                                        textAlign: "right",
                                      }
                                    : {
                                        width: "50%",
                                        background:
                                          "#FFFFFF 0% 0% no-repeat padding-box",
                                        boxShadow: "none",
                                        border: "1px solid #D9D9D9",
                                        borderRadius: "10px",
                                      }
                                }
                              >
                                <Form autoComplete="off">
                                  <CardBody>
                                    <Row>
                                      <Col md="12">
                                        <div className="form-group">
                                          <div
                                            style={{
                                              margin: "0 auto",
                                              width: "100%",
                                              padding: "10px",
                                              marginTop: "-2%",
                                              display: "flex",
                                            }}
                                            onChange={(e) =>
                                              dispatch({
                                                type: "TYPE_RADIO",
                                                prop: question.questionID,
                                                value: e.target.value,
                                              })
                                            }
                                          >
                                            <p>{question.questionText}</p>
                                            <label
                                              style={{
                                                fontSize: "16px",
                                                color: "red",
                                                paddingLeft: "5px",
                                              }}
                                            >
                                              *
                                            </label>
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <FormGroup
                                            style={{
                                              margin: "0 auto",
                                              width: "100%",
                                              padding: "10px",
                                              marginTop: "-2%",
                                            }}
                                          >
                                            <Field
                                              autoFocus={true}
                                              name={`text${index}`}
                                              placeholder={t(
                                                "surveyYourAnswer"
                                              )}
                                              className={
                                                "form-control" +
                                                (errors.text + index &&
                                                touched.text + index
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              onChange={(e) =>
                                                dispatch({
                                                  type: "TYPE_RADIO",
                                                  prop: question.questionID,
                                                  value: e.target.value,
                                                })
                                              }
                                              style={{
                                                height: "36px",
                                                borderRadius: 0,
                                                borderTop: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                              }}
                                            />
                                            {errors.text + index &&
                                            touched.text + index ? (
                                              <ErrorMessage
                                                name="text"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            ) : (
                                              <span
                                                className="isValid"
                                                component="div"
                                              >
                                                <br></br>
                                              </span>
                                            )}
                                          </FormGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Form>
                              </Card>
                            </Col>
                          )}
                          {question.questionType ==
                            SurveyAnswerTypes.Dropdown &&
                            providers != null &&
                            providers.length > 0 && (
                              <Col
                                sx="12"
                                style={{
                                  margin: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Card
                                  style={
                                    rtl
                                      ? {
                                          width: "50%",
                                          background:
                                            "#FFFFFF 0% 0% no-repeat padding-box",
                                          boxShadow: "none",
                                          border: "1px solid #D9D9D9",
                                          borderRadius: "10px",
                                          textAlign: "right",
                                        }
                                      : {
                                          width: "50%",
                                          background:
                                            "#FFFFFF 0% 0% no-repeat padding-box",
                                          boxShadow: "none",
                                          border: "1px solid #D9D9D9",
                                          borderRadius: "10px",
                                        }
                                  }
                                >
                                  <Form autoComplete="off">
                                    <CardBody>
                                      <Row>
                                        <Col md="12">
                                          <div className="form-group">
                                            <div
                                              style={{
                                                margin: "0 auto",
                                                width: "100%",
                                                padding: "10px",
                                                marginTop: "-2%",
                                                display: "flex",
                                              }}
                                              onChange={(e) =>
                                                dispatch({
                                                  type: "TYPE_RADIO",
                                                  prop: question.questionID,
                                                  value: e.target.value,
                                                })
                                              }
                                            >
                                              <p>{question.questionText}</p>
                                              <label
                                                style={{
                                                  fontSize: "16px",
                                                  color: "red",
                                                  paddingLeft: "5px",
                                                }}
                                              >
                                                *
                                              </label>
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <FormGroup
                                              style={{
                                                margin: "0 auto",
                                                width: "100%",
                                                padding: "10px",
                                                marginTop: "-2%",
                                              }}
                                            >
                                              <Field
                                                autoFocus={true}
                                                name={`dropdown${index}`}
                                                as="select"
                                                className={"form-control"}
                                                onChange={(e) =>
                                                  dispatch({
                                                    type: "TYPE_RADIO",
                                                    prop: question.questionID,
                                                    value: e.target.value,
                                                  })
                                                }
                                              >
                                                <option
                                                  selected
                                                  defaultValue
                                                  value=""
                                                >
                                                  Select Provider
                                                </option>
                                                {providers != null &&
                                                  providers.length > 0 &&
                                                  providers.map(
                                                    (provider, index) => (
                                                      <option
                                                        value={
                                                          provider.profileID
                                                        }
                                                        key={provider.profileID}
                                                      >
                                                        {provider.profileName}
                                                      </option>
                                                    )
                                                  )}
                                              </Field>
                                            </FormGroup>
                                          </div>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Form>
                                </Card>
                              </Col>
                            )}
                          {question.questionType == SurveyAnswerTypes.MCQ && (
                            <Col
                              sx="12"
                              style={{
                                margin: "auto",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Card
                                style={
                                  rtl
                                    ? {
                                        width: "50%",
                                        background:
                                          "#FFFFFF 0% 0% no-repeat padding-box",
                                        boxShadow: "none",
                                        border: "1px solid #D9D9D9",
                                        borderRadius: "10px",
                                        textAlign: "right",
                                      }
                                    : {
                                        width: "50%",
                                        background:
                                          "#FFFFFF 0% 0% no-repeat padding-box",
                                        boxShadow: "none",
                                        border: "1px solid #D9D9D9",
                                        borderRadius: "10px",
                                      }
                                }
                              >
                                <Form autoComplete="off">
                                  <CardBody>
                                    <Row>
                                      <Col md="12">
                                        <div className="form-group">
                                          <div
                                            style={{
                                              margin: "0 auto",
                                              width: "100%",
                                              padding: "10px",
                                              marginTop: "-2%",
                                              display: "flex",
                                            }}
                                            onChange={(e) =>
                                              dispatch({
                                                type: "TYPE_RADIO",
                                                prop: question.questionID,
                                                value: e.target.value,
                                              })
                                            }
                                          >
                                            <p>{question.questionText}</p>
                                            <label
                                              style={{
                                                fontSize: "16px",
                                                color: "red",
                                                paddingLeft: "5px",
                                              }}
                                            >
                                              *
                                            </label>
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <FormGroup
                                            style={{
                                              margin: "0 auto",
                                              width: "100%",
                                              padding: "10px",
                                              marginTop: "-2%",
                                            }}
                                          >
                                            {question.answers.length > 0 &&
                                              question.answers.map(
                                                (answer, index) => (
                                                  <div>
                                                    <label>
                                                      <Field
                                                        name={`answers`}
                                                        type="radio"
                                                        value={answer.answer}
                                                        style={
                                                          rtl
                                                            ? {
                                                                marginLeft:
                                                                  "10px",
                                                              }
                                                            : {
                                                                marginRight:
                                                                  "10px",
                                                              }
                                                        }
                                                        onClick={(e) => {
                                                          dispatch({
                                                            type: "TYPE_RADIO",
                                                            prop: question.questionID,
                                                            value:
                                                              e.target.value,
                                                          });
                                                        }}
                                                      />
                                                      {answer.answer}
                                                    </label>
                                                  </div>
                                                )
                                              )}
                                          </FormGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Form>
                              </Card>
                            </Col>
                          )}
                        </Row>
                      ))}
                    <Row>
                      <Col
                        md="12"
                        className={
                          rtl
                            ? "d-flex justify-content-center text-right"
                            : "d-flex justify-content-center"
                        }
                      >
                        <div className="form-group">
                          <center>
                            <FormGroup
                              style={{
                                margin: "0",
                                width: "100%",
                                padding: "10px",
                              }}
                            >
                              <div className="float-right">
                                {/* <Button
                              color="primary"
                              type="button"
                              disabled={isSubmitting}
                              onClick={() => history.push("/index")}
                            >
                              {isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              )}
                              {t("cancel.link")}
                            </Button> */}
                                <Button
                                  color="primary"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  {isSubmitting && (
                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                  )}
                                  {t("surveySubmit")}
                                </Button>
                              </div>
                            </FormGroup>
                          </center>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Row>
      )}
      {survey == null && (
        <>
          <Col
            className="col-12 d-flex justify-content-center"
            style={{
              marginTop: "120px",
            }}
          >
            <Card
              className={rtl ? "text-right" : ""}
              style={{
                width: "50%",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "none",
                border: "1px solid #D9D9D9",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "6px",
                  background: "#6CAEF2 0% 0% no-repeat padding-box",
                  borderRadius: "5px 5px 0px 0px",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                }}
              ></div>
              <CardBody className="pl-0 pr-0 pb-0">
                <div className="">
                  <p
                    name="surveyDescription"
                    style={
                      rtl
                        ? {
                            color: "#4f4f4f",
                            fontSize: "14px",
                            paddingRight: "18px",
                          }
                        : {
                            color: "#4f4f4f",
                            fontSize: "14px",
                            paddingLeft: "18px",
                          }
                    }
                    className={""}
                  >
                    {" "}
                    {t("survey.no.found")}{" "}
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </>
      )}
    </>
  );
}

export default AddSurveyResponse;
