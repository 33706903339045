import React, { useState, useContext } from "react";
import { Button, Container, Row, Col, UncontrolledCollapse } from "reactstrap";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { profileService, spSeenService } from "_services";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import Modal from "reactstrap/lib/Modal";
import { Link } from "react-router-dom";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import CardTitle from "reactstrap/lib/CardTitle";
import CardText from "reactstrap/lib/CardText";
import SpOverviewCard from "./SpOverviewCard";
import { LanguageContext } from "_context";
function RecentlyViewed({ history, match }) {
  const user = profileService.userValue;
  const path = match;
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);

  const fetchData = async () => {
    setLoading(true);
    await spSeenService
      .GetAllSpSeenAsync(user.profile.userId, false, currentLan)
      .then((x) => {
        setInfo(x);
      });
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const [iconPills, setIconPills] = React.useState("1");

  function UpdateList(list) {
    setInfo(list);
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <Container>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div className="modal-body col-md-12">
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <div className="section">
          <Row>
            <div className=""></div>
            <Card style={{ boxShadow: "none" }}>
              <CardHeader>
                <Nav className="justify-content-center" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      className={iconPills === "1" ? "active" : ""}
                      // href="#pablo"
                      onClick={async (e) => {
                        e.preventDefault();
                        await spSeenService
                          .GetAllSpSeenAsync(
                            user.profile.userId,
                            false,
                            currentLan
                          )
                          .then((x) => {
                            setInfo(x);
                          });
                        setIconPills("1");
                      }}
                    >
                      {t("allViewedProviders")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      className={iconPills === "2" ? "active" : ""}
                      //  href="#pablo"
                      onClick={async (e) => {
                        e.preventDefault();
                        await spSeenService
                          .GetAllSpSeenAsync(
                            user.profile.userId,
                            true,
                            currentLan
                          )
                          .then((x) => {
                            setInfo(x);
                          });
                        setIconPills("2");
                      }}
                    >
                      {t("bookmarkedOnly")}
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent
                  // className="text-center"
                  activeTab={"iconPills" + iconPills}
                >
                  <TabPane tabId="iconPills1">
                    {info && info.length === 0 ? (
                      <Row>
                        <Card
                          className="text-center"
                          style={{ boxShadow: "none" }}
                        >
                          <CardBody>
                            <CardTitle tag="h4">
                              {t("recentlyViewed.empty")}
                            </CardTitle>
                            <CardText>{t("recentlyViewed.body")}</CardText>
                            <Button
                              color="primary"
                              //  href="#pablo"
                              onClick={() =>
                                path !== null && path.path === "/seeker/seenSP"
                                  ? history.push(`/seeker/search-provider`)
                                  : history.push({
                                      pathname: "/partner/search-provider",
                                      state: { fromPartner: true },
                                    })
                              }
                            >
                              {t("viewProviders")}
                            </Button>
                          </CardBody>
                        </Card>
                      </Row>
                    ) : (
                      <SpOverviewCard
                        searchResults={info}
                        updateSearchResults={UpdateList}
                        tabNumber={iconPills}
                        history={history}
                        match={match}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="iconPills2">
                    {info && info.length === 0 ? (
                      <Row>
                        <Card
                          className="text-center"
                          style={{ boxShadow: "none" }}
                        >
                          <CardBody>
                            <CardTitle tag="h4">{t("savedSPEmpty")}</CardTitle>
                            <CardText>{t("recentlyViewed.body")}</CardText>
                            <Button
                              color="primary"
                              //  href="#pablo"
                              onClick={() =>
                                path !== null && path.path === "/seeker/seenSP"
                                  ? history.push(`/seeker/search-provider`)
                                  : history.push({
                                      pathname: "/partner/search-provider",
                                      state: { fromPartner: true },
                                    })
                              }
                            >
                              {t("viewProviders")}
                            </Button>
                          </CardBody>
                        </Card>
                      </Row>
                    ) : (
                      <SpOverviewCard
                        searchResults={info}
                        updateSearchResults={UpdateList}
                        tabNumber={iconPills}
                        history={history}
                        match={match}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Row>
        </div>
      </Container>
      {/* <DefaultFooter /> */}
    </>
  );
}

export default RecentlyViewed;
