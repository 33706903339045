import React, { useState, createContext } from "react";
import { profileService } from "_services";

// Create Context Object
export const SearchProjectContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const SearchProjectContextProvider = props => {

  const user = profileService.userValue;


  const userID =
    user.isPrimary && user.loggedInUserID === user.profile?.userId
      ? user.profile.userId
      : user.profile.secondaryProfiles.find(
          (sp) => sp.userId === user.loggedInUserID
        )?.userId;

    const email =
    user.isPrimary && user.loggedInUserID === user.profile?.userId
      ? user.profile.userName
      : user.profile.secondaryProfiles.find(
          (sp) => sp.userId === user.loggedInUserID
        )?.email;
       


  const initialModel = {
    userPerforminSearchId: user !== null ? userID : '',
    userPerforminSearchEmail:email,
    searchIndex: 0,
    searchKeyWord: '',
    countries: [],
    industries: user == null ? {} : user.typeOfIndustry !== null && user.typeOfIndustry.length > 0 ? {
      typeOfIndustryId: user.typeOfIndustry[0].typeOfIndustryId,
      typeOfIndustryName: user.typeOfIndustry[0].typeOfIndustryName,
    } : {},
    typeOfIndustriesAndServices: [],
    projectTypes: [],
    budgets: [],
    closingDateFrom: null,
    closingDateTo: null,
    publicationDateFrom: null,
    publicationDateTo: null,
    filterType: "Ascending",
    filterBy: "Title",
    initialModal: true,
  }
  const [searchModel, setSearchModel] = useState(initialModel);

  return (
    <SearchProjectContext.Provider value={[searchModel, setSearchModel]}>
      {props.children}
    </SearchProjectContext.Provider>
  );
};