import React, { useState, useContext } from "react";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import { LanguageContext } from "_context";
import { roleService } from "_services";
import { languageService } from "_services";
import { useEffect } from "react";
import { surveyService } from "_services/survey.service";
import { SurveyAnswerTypes } from "_helpers/SurveyAnswerTypes";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import { v4 as uuidv4 } from "uuid";
import update from "immutability-helper";
import { useRef } from "react";
import { adminService } from "_services";
import { AccountTypes } from "_helpers";
import "assets/css/generalStyles.css";

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      overflowX: "auto",
    },
  },
};

function AddSurvey({ history, match, location }) {
  const isAddMode = !match.params.id;
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorOnChange, setErrorOnChange] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [languagesName, setLanguagesName] = useState([]);
  const [roles, setRoles] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [type, setType] = useState(null);
  const typeRef = React.useRef();
  const [questionCount, setQuestionCount] = useState(1);
  const [questions, setQuestions] = useState([]);
  const questionsRef = React.useRef([]);
  const [selectedIDMcq, setSelectedIDMcq] = useState(null);
  const [answers, setAnswers] = useState([]);
  const answersRef = React.useRef([]);
  const [data, setData] = useState([]);
  const [showQuestions, setShowQuestions] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [nextAlert, setNextAlert] = useState(false);
  const [submitAlert, setSubmitAlert] = useState(false);
  const [nextQuestion, setNextQuestion] = useState(false);
  const [roleNames, setRoleNames] = useState([]);
  const [surveyTypes, setSurveyTypes] = useState([]);
  const [selectedSurveyType, setSelectedSurveyType] = useState(null);
  const [emailTitle, setEmailTitle] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [manually, setManually] = useState(false);
  const [seeker, setSeeker] = useState(false);
  const uuid = useRef();
  const nextQuestionRef = useRef(false);
  const discardButton = useRef(false);
  const surveyAccountTypes = useRef();
  const answer1_id = useRef();
  const answer2_id = useRef();
  const answer3_id = useRef();
  const answer4_id = useRef();
  const surveyAccountTypesRef = useRef();
  const surveyTypeRef = useRef();
  const surveysRef = useRef();
  const languagesRef = useRef();
  const manuallyRef = useRef(false);
  const emailTitleRef = useRef();
  const selectedListRef = useRef([]);
  const selectedSurveyTypeRef = useRef();
  const [submit, setSubmit] = useState(false);

  // const handleTabChange = (event, newTabValue) => {
  //   setTabValue(newTabValue);
  //   setTabIndex(tabIndex + 1);
  // };

  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const id = match.params.id;
  const [initialValues, setInitialValues] = useState({});

  const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;

        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === "object") {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey);
        }
      });
      // console.log(result);
      return result;
    };

    return transformObjectToDotNotation(formikErrors);
  };

  const ScrollToFieldError = ({
    scrollBehavior = { behavior: "smooth", block: "center" },
  }) => {
    const { submitCount, isValid, errors } = useFormikContext();

    useEffect(() => {
      if (isValid) return;

      const fieldErrorNames = getFieldErrorNames(errors);
      if (fieldErrorNames.length <= 0) return;

      const element = document.querySelector(`[name='${fieldErrorNames[0]}']`);
      // console.log(element);
      if (!element) return;

      // Scroll to first known error into view
      element.scrollIntoView(scrollBehavior);
      // console.log(fieldErrorNames);
      // Formik doesn't (yet) provide a callback for a client-failed submission,
      // thus why this is implemented through a hook that listens to changes on
      // the submit count.
    }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
  };

  console.log(surveys);

  async function onChange(e) {
    const {
      target: { value },
    } = e;
    let partner = AccountTypes.Partner;
    let seeker = AccountTypes.Seeker;
    let provider = AccountTypes.Provider;
    var roleList = [];
    var roleIDList = [];
    roles.forEach((role) => {
      value.forEach((selectedRole) => {
        if (role.roleId == selectedRole) {
          roleList.push(role.description);
          roleIDList.push(role.roleId);
        }
      });
    });
    if (
      (roleList.includes(partner) && roleList.includes(provider)) ||
      (roleList.includes(seeker) && roleList.includes(provider))
    ) {
      if (manually == false) {
        setErrorOnChange(true);
        setError(t("surveyTypes.error"));
        setErrorAlert(!errorAlert);
      }
      setSelectedList(typeof value === "string" ? value.split(",") : value);
      selectedListRef.current =
        typeof value === "string" ? value.split(",") : value;
    } else {
      if (roleList.includes(partner) || roleList.includes(seeker)) {
        setSeeker(true);
      } else if (roleList.includes(provider)) {
        setSeeker(false);
      }
      setSelectedList(typeof value === "string" ? value.split(",") : value);
      selectedListRef.current =
        typeof value === "string" ? value.split(",") : value;

      const model = {
        roles: roleIDList,
      };
      await adminService
        .GetSurveyTypesAsync(model)
        .then((x) => {
          var types = x.filter((x) => x.surveyTypeCode != "ManuallySendSurvey");
          setSurveyTypes(types);
        })
        .catch((error) => {
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    }
  }

  const handleChange = (e, type, languageID) => {
    e.preventDefault();
    if (type == SurveyAnswerTypes.MCQ) {
      // setSelectedIDMcq(languageID);
      answer1_id.current = uuidv4();
      answer2_id.current = uuidv4();
      answer3_id.current = uuidv4();
      answer4_id.current = uuidv4();
    }
    setType(type);
    typeRef.current = type;
  };
  console.log("questionsRef.current", questionsRef.current);
  const handleQuestionTypeChange = (value) => {
    if (questions.length == 0) {
      const objArr = [];
      languages.forEach((element) => {
        const newObj = {
          LanguageID: element.languageID,
          QuestionType: value,
        };
        objArr.push(newObj);
      });
      questionsRef.current = objArr;
      setQuestions(objArr);
    } else {
      languages.forEach((element) => {
        const questionsIndex = questions.findIndex(
          (x) => x.LanguageID == element.languageID
        );
        questions[questionsIndex] = update(questions[questionsIndex], {
          QuestionType: {
            $set: value,
          },
        });
        const questionsRefIndex = questionsRef.current.findIndex(
          (x) => x.LanguageID == element.languageID
        );
        questionsRef.current[questionsRefIndex] = update(
          questionsRef.current[questionsIndex],
          {
            QuestionType: {
              $set: value,
            },
          }
        );
      });
    }
  };

  const handleQuestionOrderChange = (value) => {
    languages.forEach((element) => {
      const questionsIndex = questions.findIndex(
        (x) => x.LanguageID == element.languageID
      );
      questionsRef.current = update(questionsRef.current, {
        [questionsIndex]: {
          QuestionOrder: {
            $set: value,
          },
        },
      });
      setQuestions(
        update(questions, {
          [questionsIndex]: {
            QuestionOrder: {
              $set: value,
            },
          },
        })
      );
    });
  };

  const handleAnswerChange = (id) => {
    const questionsIndex = questions.findIndex((x) => x.LanguageID == id);
    questionsRef.current = update(questionsRef.current, {
      [questionsIndex]: {
        Answers: {
          $set: answersRef.current,
        },
      },
    });
    setQuestions(
      update(questions, {
        [questionsIndex]: {
          Answers: {
            $set: answersRef.current,
          },
        },
      })
    );
  };

  function initialForm(translations) {
    var newJson = {};
    translations.map((x) => {
      var newKey = x.languageName;
      var newVal = "";
      newJson[newKey] = newVal;
    });

    setInitialValues(newJson);
  }

  function editInitialForm(translations, plan) {
    var newJson = {};
    translations.map((x) => {
      var newKey = x.languageName;
      // console.log(x.languageName);
      if (plan.planDescription != null) {
        for (var i = 0; i < plan.planDescription.length; i++) {
          if (x.languageID === plan.planDescription[i].languageID) {
            var newVal = plan.planDescription[i].name;
            // console.log(newVal);
            newJson[newKey] = newVal;
            // console.log(newJson[newKey]);
            languagesName[i] = plan.planDescription[i].name;
          }
        }
        setLanguagesName(languagesName);
      }
    });

    setInitialValues(newJson);
  }

  const fetchData = async () => {
    setLoading(true);
    await languageService.GetAllLanguagesAsync().then((x) => {
      // console.log(x);
      setLanguages(x);
      const objArr = [];
      x.forEach((element) => {
        const newObj = {
          LanguageID: element.languageID,
        };
        objArr.push(newObj);
      });
      setSurveys(objArr);
      roleService.GetRoleByLanguageAsync(i18n.language).then((x) => {
        setRoles(x);
      });

      if (isAddMode) {
        initialForm(x);
      } else {
        editInitialForm(x, location.state.state);

        // console.log(location.state.state);
      }
    });
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    // console.log(roles);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  const handleDiscardAndSubmit = async () => {
    const model = {
      SurveyAccountTypes: surveyAccountTypes.current,
      Surveys: surveys,
    };

    if (isAddMode) {
      await surveyService
        .AddSurvey(model, false)
        .then(() => {
          setAlert(!alert);
        })
        .catch((error) => {
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
        });
    }
  };

  async function submitFunction() {
    const model = {
      SurveyAccountTypes: surveyAccountTypesRef.current,
      EmailTitle: emailTitle,
      SurveyType: surveyTypeRef.current,
      Surveys: surveys,
      Manually: manually,
    };
    if (isAddMode) {
      await surveyService
        .AddSurvey(model, false)
        .then(() => {
          setAlert(!alert);
        })
        .catch((error) => {
          if (error.message !== undefined) {
            setError(error.message);
            if (error.error !== null && error.error.length > 0) {
              for (var i = 0; i < error.error.length; i++) {
                if (error.error[i].languageName == currentLan) {
                  setError(error.error[i].error);
                }
              }
            }
          } else {
            setError(error);
          }
          setErrorAlert(!errorAlert);
          setIsSaving(false);
        });
    }
  }

  async function onSubmit(fields, { setStatus, setSubmitting, setFieldValue }) {
    // setSurveys([
    //   ...surveys,
    //   {
    //     LanguageID: tabValue,
    //     SurveyTitle: fields.surveyTitle,
    //     SurveyDescription: fields.surveyDescription,
    //   },
    // ]);
    // setSubmitAlert(true);
    setIsSaving(true);
    if (
      selectedList.length == 0 ||
      (selectedSurveyType == null && !manually) ||
      emailTitle == null
    ) {
      setError(t("something.wrong"));
      setErrorAlert(!errorAlert);
      setIsSaving(false);
    } else {
      surveyAccountTypesRef.current = fields.surveyAccountTypes;
      selectedSurveyTypeRef.current = selectedSurveyType;
      surveyTypeRef.current = selectedSurveyType;
      const flag = handleNextQuestion(setFieldValue, true);
      if (flag == true) {
        setSubmitAlert(!submitAlert);
      }
    }
  }

  const handleAddQuestion = (fields) => {
    var proceedNext = true;
    // setData({ ...data, SurveyAccountTypes: selectedList, Surveys: surveys });
    if (
      selectedList.length == 0 ||
      (selectedSurveyType == null && !manually) ||
      emailTitle == null
    ) {
      proceedNext = false;
      setError(t("required.all"));
      setErrorAlert(!errorAlert);
    }
    // else if (selectedList.length > 0) {
    //   surveys.forEach((survey) => {
    //     if (survey.SurveyTitle == null || survey.SurveyDescription == null) {
    //       proceedNext = false;
    //       setError(t("required.all"));
    //       setErrorAlert(!errorAlert);
    //     }
    //   });
    // }
    surveysRef.current = surveys;
    languagesRef.current = languages;
    if (
      selectedList.length > 0 &&
      (selectedSurveyType != null || manually) &&
      emailTitle != null
    ) {
      surveysRef.current.forEach((survey) => {
        const objectIndex = surveysRef.current.findIndex(
          (s) => s.LanguageID === survey.LanguageID
        );
        const langIndex = languagesRef.current.findIndex(
          (s) => s.languageID === survey.LanguageID
        );
        if (
          Object.entries(survey).length == 1 ||
          (Object.entries(survey).length == 2 && survey.SurveyTitle == "") ||
          (Object.entries(survey).length == 2 &&
            survey.SurveyDescription == "") ||
          (Object.entries(survey).length == 3 &&
            survey.SurveyTitle == "" &&
            survey.SurveyDescription == "")
        ) {
          surveysRef.current = update(surveysRef.current, {
            $splice: [[objectIndex, 1]],
          });
          languagesRef.current = update(languagesRef.current, {
            $splice: [[langIndex, 1]],
          });
        }
      });
      surveysRef.current.forEach((survey) => {
        if (survey.SurveyTitle == null || survey.SurveyDescription == null) {
          proceedNext = false;
          setError(t("required.all"));
          setErrorAlert(!errorAlert);
        }
      });
    }
    if (proceedNext == true) {
      uuid.current = uuidv4();
      setSurveys(surveysRef.current);
      setLanguages(languagesRef.current);
      setShowQuestions(true);
      console.log("next");
    }
  };

  const handleNextQuestion = (setFieldValue, flag) => {
    // setNextAlert(!nextAlert);
    // if (nextQuestion == true) {
    let proceedNext = true;
    if (questionsRef.current.length == 0) {
      proceedNext = false;
      setError(t("required.all"));
      console.log("1");
      setErrorAlert(!errorAlert);
      setIsSaving(false);
      setSubmit(false);
      return false;
    } else {
      questionsRef.current.forEach((question) => {
        if (
          question.QuestionID == null ||
          question.QuestionOrder == null ||
          question.QuestionText == null ||
          question.QuestionType == null ||
          question.QuestionOrder == "" ||
          question.QuestionText == ""
        ) {
          proceedNext = false;
          setError(t("required.all"));
          console.log("2");
          setErrorAlert(!errorAlert);
          setIsSaving(false);
        } else if (
          question.QuestionType == SurveyAnswerTypes.MCQ &&
          question.Answers == null
        ) {
          // question.Answers.forEach((answer) => {
          //   if (answer.Answer == "" || answer.Answer == null) {
          proceedNext = false;
          setError(t("required.all"));
          console.log("3");
          setErrorAlert(!errorAlert);
          setIsSaving(false);
          //   }
          // });
        } else {
          if (question.Answers != null && question.Answers.length > 0) {
            question.Answers.forEach((answer) => {
              const answerCount = question.Answers.findIndex(
                (x) => x.AnswerID == answer.AnswerID
              );
              if (answer.answer == "") {
                question.Answers = update(question.Answers, {
                  $splice: [[answerCount, 1]],
                });
              }
            });
          }
        }
      });
      setSubmit(false);

      if (proceedNext == false) {
        return false;
      } else {
        surveys.forEach((survey) => {
          questionsRef.current.forEach((question) => {
            if (question.LanguageID === survey.LanguageID) {
              const userIndex = surveys.findIndex(
                (x) => x.LanguageID === survey.LanguageID
              );
              let surveyArr = surveys;
              if (surveyArr[userIndex].Questions == undefined) {
                surveyArr[userIndex].Questions = [];
              }
              surveyArr[userIndex].Questions.push(question);
              setSurveys(surveyArr);
              // setSurveys(
              //   update(surveys, {
              //     [userIndex]: {
              //       $merge: {
              //         Questions: [question],
              //       },
              //     },
              //   })
              // );
            }
          });
        });
        // setSurveys([...surveys])
        if (flag == false) {
          uuid.current = uuidv4();
          setQuestionCount(questionCount + 1);
          questionsRef.current = [];
          discardButton.current = true;
          setQuestions([]);
          setAnswers([]);
          setType(null);
          setFieldValue("questionType", "");
          setFieldValue("questionOrder", "");
          languages.forEach((language) => {
            setFieldValue(`questionTitle${language.languageID}`, "");
            setFieldValue(`answer1${language.languageID}`, "");
            setFieldValue(`answer2${language.languageID}`, "");
            setFieldValue(`answer3${language.languageID}`, "");
            setFieldValue(`answer4${language.languageID}`, "");
          });
        }
        return true;
      }
    }
  };

  const handleBack = () => {
    questionsRef.current = surveys[0].Questions[questionCount - 1];
    setQuestionCount(questionCount - 1);
  };

  return (
    <div
      className="section"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // backgroundColor: "#EEF5FB",
        height: questions.length > 1 ? "fit-content" : "auto",
      }}
    >
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("done")}</h5>
          </div>
          <div className="modal-body">
            {isAddMode ? (
              <p>{t("surveyAddSuccess")}</p>
            ) : (
              <p>{t("surveyUpdateSuccess")}</p>
            )}
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setAlert(!alert);
                if (isAddMode) {
                  history.push(".");
                } else {
                  history.push("..");
                }
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={nextAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}></div>
          <div className="modal-body">{t("nextQuestionConfirmation")}</div>
          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setNextAlert(!nextAlert);
              }}
            >
              {t("label.no")}
            </Button>
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                handleDiscardAndSubmit();
                setNextQuestion(true);
                setNextAlert(!nextAlert);
              }}
            >
              {t("label.yes")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={submitAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}></div>
          <div className="modal-body">{t("submitQuestionConfirmation")}</div>
          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setIsSaving(false);
                setSubmitAlert(!submitAlert);
              }}
            >
              {t("label.no")}
            </Button>
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                submitFunction();
                setIsSaving(false);
                setSubmitAlert(!submitAlert);
              }}
            >
              {t("label.yes")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div className="modal-body col-md-12">
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                if (errorOnChange == true) {
                  setSelectedList([]);
                  selectedListRef.current = [];
                }
                setErrorAlert(!errorAlert);
                setIsSaving(false);
                setManually(manuallyRef.current);
                setSelectedList(selectedListRef.current);
                setSelectedSurveyType(selectedSurveyTypeRef.current);
                setEmailTitle(emailTitleRef.current);
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, errors, touched, values, setFieldValue }) => {
              return (
                <Form className={rtl ? "w-100 text-right" : "w-100"}>
                  <CardTitle className="text-center" tag="h4">
                    {showQuestions == true
                      ? t("surveyQuestion") + questionCount
                      : t("addSurvey")}
                  </CardTitle>
                  {showQuestions == false ? (
                    <>
                      <div className="form-group">
                        <FormGroup
                          className="col-md-8"
                          style={{
                            margin: "0",
                            width: "100%",
                            padding: "10px",
                            marginTop: "-2%",
                            marginBottom: "1%",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="#107FD4"
                                checked={manually}
                                onChange={() => {
                                  setManually(!manually);
                                  manuallyRef.current = !manuallyRef.current;
                                  setSelectedSurveyType(null);
                                  selectedSurveyTypeRef.current = null;
                                  setSelectedList([]);
                                  selectedListRef.current = [];
                                }}
                                name="manuallySendSurvey"
                              />
                            }
                            label={
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "SpaceGrotesk",
                                }}
                              >
                                {t("manuallySendSurvey")}
                              </span>
                            }
                          />
                        </FormGroup>
                      </div>
                      <div className="form-group">
                        <FormGroup
                          className="col-md-8"
                          style={{
                            margin: "0",
                            width: "100%",
                            padding: "10px",
                            marginTop: "-2%",
                            marginBottom: "1%",
                          }}
                        >
                          <FormControl
                            sx={{ m: 1, width: 300 }}
                            style={{ margin: 0 }}
                            className={"row"}
                          >
                            <label
                              htmlFor="rolesList"
                              style={{ fontSize: "16px" }}
                            >
                              {t("surveyAccountNames")}
                            </label>
                            <Select
                              labelId="rolesList"
                              id="demo-multiple-name"
                              multiple
                              name="surveyAccountTypeName"
                              placeholder="Roles"
                              value={selectedList}
                              onChange={(e) => {
                                setFieldValue(
                                  "surveyAccountTypes",
                                  typeof value === "string"
                                    ? e.target.value.split(",")
                                    : e.target.value
                                );
                                surveyAccountTypes.current =
                                  typeof value === "string"
                                    ? e.target.value.split(",")
                                    : e.target.value;
                                onChange(e);
                              }}
                              style={{
                                height: "36px",
                                borderRadius: 0,
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                              }}
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": {
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                },
                              }}
                              MenuProps={MenuProps}
                            >
                              {roles.map((role) => (
                                <MenuItem
                                  className="css-1km1ehz"
                                  key={role.roleId}
                                  value={role.roleId}
                                >
                                  {role.description}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </FormGroup>
                      </div>

                      {!manually && (
                        <div className="form-group">
                          <FormGroup
                            className="col-md-8"
                            style={{
                              margin: "0",
                              width: "100%",
                              padding: "10px",
                              marginTop: "-2%",
                              marginBottom: "1%",
                            }}
                          >
                            <FormControl
                              sx={{ m: 1, width: 300 }}
                              style={{ margin: 0 }}
                              className={"row"}
                            >
                              <label
                                htmlFor="rolesList"
                                style={{ fontSize: "16px" }}
                              >
                                {t("surveyTypes")}
                              </label>
                              <Select
                                labelId="surveyTypes"
                                name="surveyTypes"
                                placeholder="Roles"
                                value={selectedSurveyType}
                                onChange={(e) => {
                                  setFieldValue("surveyTypes", e.target.value);
                                  setSelectedSurveyType(e.target.value);
                                  selectedSurveyTypeRef.current =
                                    e.target.value;
                                }}
                                style={{
                                  height: "36px",
                                  width: 300,
                                  borderRadius: 0,
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                }}
                                sx={{
                                  boxShadow: "none",
                                  width: 300,
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                  },
                                }}
                                MenuProps={MenuProps}
                              >
                                {surveyTypes.map((type) => (
                                  <MenuItem
                                    className="css-1km1ehz"
                                    key={type.surveyTypeCode}
                                    value={type.surveyTypeCode}
                                  >
                                    {type.surveyTypeDescription}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </div>
                      )}
                      <div className="form-group">
                        <FormGroup
                          className="col-md-8"
                          style={{
                            margin: "0",
                            width: "100%",
                            padding: "10px",
                            marginTop: "-2%",
                            marginBottom: "1%",
                          }}
                        >
                          <FormControl
                            sx={{ m: 1, width: 300 }}
                            style={{ margin: 0 }}
                            className={"row"}
                          >
                            <label
                              htmlFor="rolesList"
                              style={{ fontSize: "16px" }}
                            >
                              {t("survey.email.title")}
                            </label>
                            <Field
                              labelId="emailTitle"
                              name="emailTitle"
                              value={emailTitle}
                              onChange={(e) => {
                                setFieldValue("emailTitle", e.target.value);
                                setEmailTitle(e.target.value);
                                emailTitleRef.current = e.target.value;
                              }}
                              style={{
                                height: "36px",
                                width: 300,
                                borderRadius: 0,
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                              }}
                              sx={{
                                boxShadow: "none",
                                width: 300,
                                ".MuiOutlinedInput-notchedOutline": {
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                },
                              }}
                            />
                          </FormControl>
                        </FormGroup>
                      </div>
                      {languages.length > 0 &&
                        surveys.length > 0 &&
                        languages.map((language) => (
                          <Row
                            key={language.languageID}
                            style={{
                              margin: "auto",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <Card
                              style={{
                                width: "100%",
                                height: "20%",
                                background:
                                  "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "none",
                                border: "1px solid #D9D9D9",
                                borderRadius: "10px",
                              }}
                            >
                              <CardBody>
                                <Form autoComplete="off">
                                  <ScrollToFieldError />

                                  <Row>
                                    <Col>
                                      <div className="row justify-content-center w-100">
                                        <span
                                          className="pl-2 pb-4"
                                          style={{
                                            fontSize: "16px",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {language.languageDescription}
                                        </span>
                                      </div>
                                      <div className="form-group">
                                        <FormGroup
                                          className="col-md-8"
                                          style={{
                                            margin: "0",
                                            width: "100%",
                                            padding: "10px",
                                            marginTop: "-2%",
                                          }}
                                        >
                                          <Field
                                            name={
                                              "surveyTitle" +
                                              language.languageID
                                            }
                                            placeholder={t("surveyTitle")}
                                            onChange={(e) => {
                                              const surveyIndex =
                                                surveys.findIndex(
                                                  (x) =>
                                                    x.LanguageID ==
                                                    language.languageID
                                                );
                                              // console.log(surveyIndex);
                                              setSurveys(
                                                update(surveys, {
                                                  [surveyIndex]: {
                                                    SurveyTitle: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                })
                                              );
                                              // setSurveys(
                                              //   update(surveys, {
                                              //     [surveyIndex]: {
                                              //       $merge: {
                                              //         Questions: [],
                                              //       },
                                              //     },
                                              //   })
                                              // );
                                            }}
                                            style={{
                                              borderTop: "none",
                                              borderLeft: "none",
                                              borderRight: "none",
                                              fontSize: "24px",
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.surveyTitle &&
                                              touched.surveyTitle
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          {errors.surveyTitle &&
                                            touched.surveyTitle && (
                                              <ErrorMessage
                                                name="surveyTitle"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            )}
                                        </FormGroup>
                                      </div>
                                      <div className="form-group">
                                        <FormGroup
                                          className="col-md-8"
                                          style={{
                                            margin: "0",
                                            width: "100%",
                                            padding: "10px",
                                            marginTop: "-2%",
                                          }}
                                        >
                                          <Field
                                            name={
                                              "surveyDescription" +
                                              language.languageID
                                            }
                                            style={{
                                              borderTop: "none",
                                              borderLeft: "none",
                                              borderRight: "none",
                                            }}
                                            onChange={(e) => {
                                              const surveyIndex =
                                                surveys.findIndex(
                                                  (x) =>
                                                    x.LanguageID ==
                                                    language.languageID
                                                );
                                              // console.log(surveyIndex);
                                              setSurveys(
                                                update(surveys, {
                                                  [surveyIndex]: {
                                                    SurveyDescription: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                })
                                              );
                                            }}
                                            placeholder={t("surveyDescription")}
                                            className={
                                              "form-control" +
                                              (errors.surveyDescription &&
                                              touched.surveyDescription
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          {errors.surveyDescription &&
                                            touched.surveyDescription && (
                                              <ErrorMessage
                                                name="surveyDescription"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            )}
                                        </FormGroup>
                                      </div>
                                    </Col>
                                  </Row>
                                </Form>
                              </CardBody>
                            </Card>
                          </Row>
                        ))}
                    </>
                  ) : (
                    <>
                      <Row className="w-100">
                        <div className="form-row col-3">
                          <FormGroup
                            style={{
                              margin: "0",
                              width: "100%",
                              padding: "10px",
                              marginTop: "-2%",
                              marginBottom: "1%",
                            }}
                          >
                            <Field
                              style={{
                                fontSize: "12px",
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                              }}
                              name={"questionType"}
                              as="select"
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  e.target.value
                                  // language.languageID
                                );
                                setFieldValue(`questionType`, e.target.value);
                                handleQuestionTypeChange(e.target.value);
                                // const questionsIndex = questions.findIndex(
                                //   (x) => x.LanguageID == language.languageID
                                // );
                                // // console.log(questionsIndex);
                                // if (questionsIndex < 0) {
                                //   setQuestions([
                                //     ...questions,
                                //     {
                                //       QuestionID: uuid.current,
                                //       QuestionType: e.target.value,
                                //       LanguageID: language.languageID,
                                //     },
                                //   ]);
                                // } else {
                                //   setQuestions(
                                //     update(questions, {
                                //       [questionsIndex]: {
                                //         QuestionType: {
                                //           $set: e.target.value,
                                //         },
                                //       },
                                //     })
                                //   );
                                // }
                                // if (e.target.value != SurveyAnswerTypes.MCQ) {
                                //   setFieldValue("answer1", "", false);
                                //   setFieldValue("answer2", "", false);
                                //   setFieldValue("answer3", "", false);
                                //   setFieldValue("answer4", "", false);
                                // }
                              }}
                              className={
                                "form-control" +
                                (errors.roleId && touched.roleId
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value="" disabled defaultValue selected>
                                {t("surveyQuestionType")}
                              </option>
                              {/* {questionTypes &&
                                                  questionTypes.map((type, i) => (
                                                    <option key={i} value={type}>
                                                      {type}
                                                    </option>
                                                  ))} */}
                              <option value={SurveyAnswerTypes.YesNo}>
                                {SurveyAnswerTypes.YesNo}
                              </option>
                              <option value={SurveyAnswerTypes.MCQ}>
                                {SurveyAnswerTypes.MCQ}
                              </option>
                              <option value={SurveyAnswerTypes.Text}>
                                {SurveyAnswerTypes.Text}
                              </option>
                              {(selectedSurveyType ==
                                "SendSurveyToSSWithInteraction" ||
                                selectedSurveyType ==
                                  "SendSurveyToSSBeforeClosingDate") && (
                                <option value={SurveyAnswerTypes.Dropdown}>
                                  {SurveyAnswerTypes.Dropdown}
                                </option>
                              )}
                            </Field>

                            {errors.questionType && touched.questionType && (
                              <ErrorMessage
                                name="questionType"
                                component="div"
                                className="invalid-feedback"
                              />
                            )}
                          </FormGroup>
                        </div>

                        <div className="form-group col-3">
                          <FormGroup
                            style={{
                              margin: "0",
                              width: "fit-content",
                              padding: "10px",
                              marginTop: "-1%",
                            }}
                          >
                            <Field
                              min="1"
                              type="number"
                              name={"questionOrder"}
                              placeholder={t("surveyQuestionOrder")}
                              disabled={typeRef.current == null ? true : false}
                              onChange={(e) => {
                                setFieldValue(`questionOrder`, e.target.value);
                                handleQuestionOrderChange(e.target.value);
                                // const questionsIndex = questions.findIndex(
                                //   (x) => x.LanguageID == language.languageID
                                // );
                                // // console.log(questionsIndex);
                                // if (questionsIndex < 0) {
                                //   setQuestions([
                                //     ...questions,
                                //     {
                                //       QuestionID: uuid.current,
                                //       QuestionOrder: e.target.value,
                                //       LanguageID: language.languageID,
                                //     },
                                //   ]);
                                // } else {
                                //   setQuestions(
                                //     update(questions, {
                                //       [questionsIndex]: {
                                //         QuestionOrder: {
                                //           $set: e.target.value,
                                //         },
                                //       },
                                //     })
                                //   );
                                // }
                              }}
                              style={{
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                              }}
                              className={
                                "form-control" +
                                (errors.questionOrder && touched.questionOrder
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            {errors.questionOrder && touched.questionOrder && (
                              <ErrorMessage
                                name="questionOrder"
                                component="div"
                                className="invalid-feedback"
                              />
                            )}
                          </FormGroup>
                        </div>
                      </Row>
                      <Row className="w-100">
                        {showQuestions == true &&
                          languages.length > 0 &&
                          languages.map((language) => (
                            <Card
                              key={language.languageID}
                              className="mb-0 mt-2"
                              style={{
                                width: "100%",
                                background:
                                  "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "none",
                                border: "1px solid #D9D9D9",
                                borderRadius: "10px",
                              }}
                            >
                              <CardBody>
                                <div className="row justify-content-center w-100">
                                  <span
                                    className="pl-2 pb-4"
                                    style={{
                                      fontSize: "16px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {language.languageDescription}
                                  </span>
                                </div>
                                <Row>
                                  <div className="form-group col-6 pl-0">
                                    <FormGroup
                                      style={{
                                        margin: "0",
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "-1%",
                                      }}
                                    >
                                      <Field
                                        min="1"
                                        type="text"
                                        name={
                                          "questionTitle" + language.languageID
                                        }
                                        style={{
                                          borderTop: "none",
                                          borderLeft: "none",
                                          borderRight: "none",
                                          width: "100%",
                                        }}
                                        disabled={
                                          typeRef.current == null ? true : false
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `questionTitle${language.languageID}`,
                                            e.target.value
                                          );
                                          const questionsIndex =
                                            questions.findIndex(
                                              (x) =>
                                                x.LanguageID ==
                                                language.languageID
                                            );
                                          // console.log(questionsIndex);
                                          if (questionsIndex < 0) {
                                            questionsRef.current = [
                                              ...questionsRef.current,
                                              {
                                                QuestionID: uuid.current,
                                                QuestionText: e.target.value,
                                                LanguageID: language.languageID,
                                              },
                                            ];
                                            setQuestions([
                                              ...questions,
                                              {
                                                QuestionID: uuid.current,
                                                QuestionText: e.target.value,
                                                LanguageID: language.languageID,
                                              },
                                            ]);
                                          } else {
                                            questionsRef.current = update(
                                              questionsRef.current,
                                              {
                                                [questionsIndex]: {
                                                  QuestionText: {
                                                    $set: e.target.value,
                                                  },
                                                  QuestionID: {
                                                    $set: uuid.current,
                                                  },
                                                },
                                              }
                                            );
                                            setQuestions(
                                              update(questions, {
                                                [questionsIndex]: {
                                                  QuestionText: {
                                                    $set: e.target.value,
                                                  },
                                                  QuestionID: {
                                                    $set: uuid.current,
                                                  },
                                                },
                                              })
                                            );
                                          }
                                        }}
                                        placeholder={t(
                                          "surveyUntitledQuestion"
                                        )}
                                        className={
                                          "form-control" +
                                          (errors.questionTitle &&
                                          touched.questionTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      {errors.questionTitle &&
                                        touched.questionTitle && (
                                          <ErrorMessage
                                            name="questionTitle"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                    </FormGroup>
                                  </div>
                                </Row>

                                {type == SurveyAnswerTypes.MCQ ? (
                                  <>
                                    <div className="form-group mt-4">
                                      <FormGroup
                                        className="col-12 col-md-6 col-lg-5 col-sm-12"
                                        style={{
                                          margin: "0",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <Field
                                          name={"answer1" + language.languageID}
                                          placeholder={t("surveyAnswer1")}
                                          className={
                                            "form-control" +
                                            (errors.answer1 && touched.answer1
                                              ? " is-invalid"
                                              : "")
                                          }
                                          style={{
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none",
                                          }}
                                          // onChange={(e) => {}}
                                          id={uuidv4()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `answer1${language.languageID}`,
                                              e.target.value
                                            );
                                            const answersIndex =
                                              answers.findIndex(
                                                (x) =>
                                                  x.AnswerID ==
                                                  answer1_id.current
                                              );
                                            if (answersIndex < 0) {
                                              setAnswers([
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer1_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 1,
                                                },
                                              ]);
                                              answersRef.current = [
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer1_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 1,
                                                },
                                              ];
                                            } else {
                                              if (
                                                answers.filter(
                                                  (x) =>
                                                    x.LanguageID !=
                                                    language.languageID
                                                ).length > 0
                                              ) {
                                                setAnswers([
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer1_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 1,
                                                  },
                                                ]);
                                                answersRef.current = [
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer1_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 1,
                                                  },
                                                ];
                                              } else
                                                setAnswers(
                                                  update(answers, {
                                                    [answersIndex]: {
                                                      Answer: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              answersRef.current = update(
                                                answers,
                                                {
                                                  [answersIndex]: {
                                                    Answer: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                            handleAnswerChange(
                                              language.languageID
                                            );
                                          }}
                                        />
                                        {errors.answer1 && touched.answer1 && (
                                          <ErrorMessage
                                            name={
                                              "answer1" + language.languageID
                                            }
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-group">
                                      <FormGroup
                                        className="col-12 col-md-6 col-lg-5 col-sm-12"
                                        style={{
                                          margin: "0",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <Field
                                          name={"answer2" + language.languageID}
                                          placeholder={t("surveyAnswer2")}
                                          className={
                                            "form-control" +
                                            (errors.answer2 && touched.answer2
                                              ? " is-invalid"
                                              : "")
                                          }
                                          style={{
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none",
                                          }}
                                          // onChange={(e) => {}}
                                          id={uuidv4()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `answer2${language.languageID}`,
                                              e.target.value
                                            );
                                            const answersIndex =
                                              answers.findIndex(
                                                (x) =>
                                                  x.AnswerID ==
                                                  answer2_id.current
                                              );
                                            if (answersIndex < 0) {
                                              setAnswers([
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer2_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 2,
                                                },
                                              ]);
                                              answersRef.current = [
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer2_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 2,
                                                },
                                              ];
                                            } else {
                                              if (
                                                answers.filter(
                                                  (x) =>
                                                    x.LanguageID !=
                                                    language.languageID
                                                ).length > 0
                                              ) {
                                                setAnswers([
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer2_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 2,
                                                  },
                                                ]);
                                                answersRef.current = [
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer2_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 2,
                                                  },
                                                ];
                                              } else
                                                setAnswers(
                                                  update(answers, {
                                                    [answersIndex]: {
                                                      Answer: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              answersRef.current = update(
                                                answers,
                                                {
                                                  [answersIndex]: {
                                                    Answer: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                            handleAnswerChange(
                                              language.languageID
                                            );
                                          }}
                                        />
                                        {errors.answer2 && touched.answer2 && (
                                          <ErrorMessage
                                            name={
                                              "answer2" + language.languageID
                                            }
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-group">
                                      <FormGroup
                                        className="col-12 col-md-6 col-lg-5 col-sm-12"
                                        style={{
                                          margin: "0",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <Field
                                          name={"answer3" + language.languageID}
                                          placeholder={t("surveyAnswer3")}
                                          className={
                                            "form-control" +
                                            (errors.answer3 && touched.answer3
                                              ? " is-invalid"
                                              : "")
                                          }
                                          style={{
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none",
                                          }}
                                          // onChange={(e) => {}}
                                          id={uuidv4()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `answer3${language.languageID}`,
                                              e.target.value
                                            );
                                            const answersIndex =
                                              answers.findIndex(
                                                (x) =>
                                                  x.AnswerID ==
                                                  answer3_id.current
                                              );
                                            if (answersIndex < 0) {
                                              setAnswers([
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer3_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 3,
                                                },
                                              ]);
                                              answersRef.current = [
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer3_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 3,
                                                },
                                              ];
                                            } else {
                                              if (
                                                answers.filter(
                                                  (x) =>
                                                    x.LanguageID !=
                                                    language.languageID
                                                ).length > 0
                                              ) {
                                                setAnswers([
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer3_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 3,
                                                  },
                                                ]);
                                                answersRef.current = [
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer3_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 3,
                                                  },
                                                ];
                                              } else
                                                setAnswers(
                                                  update(answers, {
                                                    [answersIndex]: {
                                                      Answer: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              answersRef.current = update(
                                                answers,
                                                {
                                                  [answersIndex]: {
                                                    Answer: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                            handleAnswerChange(
                                              language.languageID
                                            );
                                          }}
                                        />
                                        {errors.answer3 && touched.answer3 && (
                                          <ErrorMessage
                                            name={
                                              "answer3" + language.languageID
                                            }
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                    <div className="form-group">
                                      <FormGroup
                                        className="col-12 col-md-6 col-lg-5 col-sm-12"
                                        style={{
                                          margin: "0",
                                          width: "100%",
                                          padding: "10px",
                                          marginTop: "-2%",
                                        }}
                                      >
                                        <Field
                                          name={"answer4" + language.languageID}
                                          placeholder={t("surveyAnswer4")}
                                          className={
                                            "form-control" +
                                            (errors.answer4 && touched.answer4
                                              ? " is-invalid"
                                              : "")
                                          }
                                          style={{
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none",
                                          }}
                                          // onChange={(e) => {}}
                                          id={uuidv4()}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `answer4${language.languageID}`,
                                              e.target.value
                                            );
                                            const answersIndex =
                                              answers.findIndex(
                                                (x) =>
                                                  x.AnswerID ==
                                                  answer4_id.current
                                              );
                                            if (answersIndex < 0) {
                                              setAnswers([
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer4_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 4,
                                                },
                                              ]);
                                              answersRef.current = [
                                                ...answers,
                                                {
                                                  Answer: e.target.value,
                                                  AnswerID: answer4_id.current,
                                                  LanguageID:
                                                    language.languageID,
                                                  AnswerOrder: 4,
                                                },
                                              ];
                                            } else {
                                              if (
                                                answers.filter(
                                                  (x) =>
                                                    x.LanguageID !=
                                                    language.languageID
                                                ).length > 0
                                              ) {
                                                setAnswers([
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer4_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 4,
                                                  },
                                                ]);
                                                answersRef.current = [
                                                  {
                                                    Answer: e.target.value,
                                                    AnswerID:
                                                      answer4_id.current,
                                                    LanguageID:
                                                      language.languageID,
                                                    AnswerOrder: 4,
                                                  },
                                                ];
                                              } else
                                                setAnswers(
                                                  update(answers, {
                                                    [answersIndex]: {
                                                      Answer: {
                                                        $set: e.target.value,
                                                      },
                                                    },
                                                  })
                                                );
                                              answersRef.current = update(
                                                answers,
                                                {
                                                  [answersIndex]: {
                                                    Answer: {
                                                      $set: e.target.value,
                                                    },
                                                  },
                                                }
                                              );
                                            }
                                            handleAnswerChange(
                                              language.languageID
                                            );
                                          }}
                                        />
                                        {errors.answer4 && touched.answer4 && (
                                          <ErrorMessage
                                            name={
                                              "answer4" + language.languageID
                                            }
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        )}
                                      </FormGroup>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </CardBody>
                            </Card>
                          ))}
                      </Row>
                    </>
                  )}
                  {/* </CardBody>
                  </Card> */}
                  <div className="form-row">
                    <FormGroup
                      style={{
                        margin: "0 auto",
                        width: "100%",
                        padding: "10px",
                      }}
                    >
                      {/* // <center>
                        //   <Button
                        //     color="primary"
                        //     type="submit"
                        //     disabled={isSubmitting}
                        //   >
                        //     {isSubmitting && (
                        //       <span className="spinner-border spinner-border-sm mr-1"></span>
                        //     )}
                        //     {t("createCompanyProfile.save")}
                        //   </Button>
                        // </center> */}
                      <div className={rtl ? "float-left" : "float-right"}>
                        {/* <Button
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                            onClick={() => {
                              setQuestionCount(questionCount + 1);
                              setOldTabValue(tabValue);
                              setTabValue(languages[tabIndex + 1].languageID);
                              setTabIndex(tabIndex + 1);
                            }}
                          >
                            {isSubmitting && (
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            {t("next.button")}
                          </Button> */}
                        <Button
                          color="primary"
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => history.push(`.`)}
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          {t("cancel.link")}
                        </Button>
                        {showQuestions == false && (
                          <Button
                            color="primary"
                            type="button"
                            disabled={isSubmitting}
                            onClick={() => handleAddQuestion(values)}
                          >
                            {isSubmitting && (
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            {t("surveyAddQuestions")}
                          </Button>
                        )}
                        {showQuestions == true && (
                          <>
                            {discardButton.current == true && (
                              <Button
                                color="primary"
                                type="button"
                                disabled={isSubmitting}
                                onClick={() => setNextAlert(!nextAlert)}
                              >
                                {isSubmitting && (
                                  <span className="spinner-border spinner-border-sm mr-1"></span>
                                )}
                                {t("surveyDiscard")}
                              </Button>
                            )}
                            <Button
                              color="primary"
                              type="submit"
                              disabled={isSaving == true}
                            >
                              {isSaving == true && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              )}
                              {t("done")}
                            </Button>
                            <Button
                              color="primary"
                              type="button"
                              disabled={isSubmitting}
                              onClick={() => {
                                handleNextQuestion(setFieldValue, false);
                              }}
                            >
                              {isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              )}
                              {t("surveyNextQuestion")}
                            </Button>
                          </>
                        )}
                      </div>
                    </FormGroup>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default AddSurvey;
