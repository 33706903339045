import {config} from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/WhoWeAreTypes`;

export const whowearetypesService = {
    GetAllTypesAsync
};

function GetAllTypesAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllTypes`);
}


