import React, { useState, createContext } from "react";

// Create Context Object
export const DirectSearchResultsContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const DirectSearchResultsContextProvider = props => {

    const model = {
        totalCount:0,
        list: []
    }
  const [projectSearchList, setProjectSearchList] = useState(model);

  return (
    <DirectSearchResultsContext.Provider value={[projectSearchList, setProjectSearchList]}>
      {props.children}
    </DirectSearchResultsContext.Provider>
  );
};