import React from "react";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddProject from "./addEditProject";
import ProjectDetails from "./ProjectDetails";
import ProjectsList from "./ProjectsList";

function Project({ history, match }) {
  const { path } = match;
  return (
    <>
      <Switch>
        <PrivateRoute exact path={`${path}`} component={ProjectsList} />
        <PrivateRoute path={`${path}/add-project`} component={AddProject} />
        <PrivateRoute
          path={`${path}/edit-opportunity/:id`}
          component={AddProject}
        />
        <PrivateRoute path={`${path}/:projectID`} component={ProjectDetails} />
      </Switch>
    </>
  );
}

export { Project };
