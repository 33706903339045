import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import LanguageDropdown from "./LanguageDropdown";
import { LanguageContext, UnseenMessagesContext } from "../../_context";
import { useTranslation } from "react-i18next";
import { profileService } from "_services";
import { badgeService } from "_services/BadgeService";
import Badge from "reactstrap/lib/Badge";
import Tooltip from "@mui/material/Tooltip";

function SeekerNavbar({ props }) {
  const user = profileService.userValue;
  const [badge, setBadge] = useState(null);
  const [vettingBadge, setVettingBadge] = useState(null);
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [currentUnseenMessage, setCurrentUnseenMessage] = useContext(
    UnseenMessagesContext
  );

  React.useEffect(() => {
    if (user != null) {
      if (user.profile.badgeId != null)
        badgeService.GetBadgeByID(user.profile.badgeId).then((x) => {
          setBadge(x);
        });
      if (user.profile.vettingBadgeId != null)
        badgeService.GetBadgeByID(user.profile.vettingBadgeId).then((x) => {
          setVettingBadge(x);
        });
    }
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={"fixed-top shadow-none"}
        expand="lg"
        color="white"
        style={{
          paddingLeft: "70px",
          paddingRight: "70px",
          boxShadow: "none !important",
        }}
      >
        <div className="navbar-translate">
          <NavbarBrand
            id="navbar-brand"
            className="pl-5"
            href="/"
            style={{
              width: "193px",
              height: "48px",
            }}
          >
            {/* <img
              src={require("assets/img/Outerpond-Logo-168x42.png").default}
              alt=""
            /> */}
            <img
              src={require("assets/img/Outerpond-Logo.png").default}
              alt=""
            />
          </NavbarBrand>
          <UncontrolledTooltip target="#navbar-brand">
            Outerpond
          </UncontrolledTooltip>
          <button
            className="navbar-toggler navbar-toggler"
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
              setCollapseOpen(!collapseOpen);
            }}
            aria-expanded={collapseOpen}
            type="button"
          >
            <span className="navbar-toggler-bar top-bar"></span>
            <span className="navbar-toggler-bar middle-bar"></span>
            <span className="navbar-toggler-bar bottom-bar"></span>
          </button>
        </div>
        <Collapse className="justify-content-end" isOpen={collapseOpen} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink
                style={{ fontFamily: "SpaceGrotesk" }}
                onClick={() => {
                  // profileService.RefreshTokenAsync().then((user) => {
                  props.push("/seeker/search-provider");
                  setCurrentUnseenMessage(user.unseenMessages);

                  document.documentElement.classList.toggle("nav-open");
                  setCollapseOpen(false);
                  // });
                }}
                tag={Link}
              >
                {t("navbar.findProviders")}
              </NavLink>
            </NavItem>
            {/* <NavItem>
                <NavLink
                  onClick={() => {
                    profileService.RefreshTokenAsync().then((user) => {
                      props.push("/seeker/projects");
                      setCurrentUnseenMessage(user.unseenMessages);
                      document.documentElement.classList.toggle("nav-open");
                      setCollapseOpen(false);

                    });

                  }}
                  tag={Link}
                >
                  {t("navbar.manageOpportunities")}
                </NavLink>
              </NavItem> */}
            <UncontrolledDropdown right nav>
              <DropdownToggle
                caret
                color="default"
                href="#pablo"
                nav
                style={{ fontFamily: "SpaceGrotesk" }}
                onClick={(e) => e.preventDefault()}
              >
                {t("navbar.manageOpportunities")}
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby="navbarDropdown"
                className="dropdown-menu-right"
              >
                <DropdownItem
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    // profileService.RefreshTokenAsync().then((user) => {
                    props.push("/seeker/projects");
                    setCurrentUnseenMessage(user.unseenMessages);
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                    // });
                  }}
                >
                  {t("opportunities")}
                </DropdownItem>
                <DropdownItem
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    // profileService.RefreshTokenAsync().then((user) => {
                    props.push("/seeker/directLinks");
                    setCurrentUnseenMessage(user.unseenMessages);
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                    // });
                  }}
                >
                  {t("directLinks")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink
                style={{ fontFamily: "SpaceGrotesk" }}
                onClick={() => {
                  // profileService.RefreshTokenAsync().then((user) => {
                  props.push("/seeker/seenSP");
                  setCurrentUnseenMessage(user.unseenMessages);
                  document.documentElement.classList.toggle("nav-open");
                  setCollapseOpen(false);
                  // });
                }}
                tag={Link}
              >
                {t("provider.recentlyViewed")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ fontFamily: "SpaceGrotesk" }}
                onClick={() => {
                  // profileService.RefreshTokenAsync().then((user) => {
                  props.push(`/seeker/messaging`, {
                    fromMessaging: true,
                  });
                  setCurrentUnseenMessage(user.unseenMessages);
                  document.documentElement.classList.toggle("nav-open");
                  setCollapseOpen(false);
                  // });
                }}
                tag={Link}
              >
                {t("navbar.messages")}
                {currentUnseenMessage > 0 && (
                  <Badge
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "#0E6EB8",
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    {currentUnseenMessage}
                  </Badge>
                )}
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                style={{ fontFamily: "SpaceGrotesk" }}
                onClick={() => {
                  props.push("/plans");
                  document.documentElement.classList.toggle("nav-open");
                  setCollapseOpen(false);
                }}
                tag={Link}
              >
                {t("mainPage.plans")}
              </NavLink>
            </NavItem> */}
            <UncontrolledDropdown nav>
              <DropdownToggle
                caret
                color="default"
                href="#pablo"
                nav
                style={{ fontFamily: "SpaceGrotesk" }}
                onClick={(e) => e.preventDefault()}
              >
                <i className="now-ui-icons users_single-02"></i>
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby="navbarDropdown"
                className={rtl ? "dropdown-menu-left" : "dropdown-menu-right"}
                style={{ width: "300px" }}
              >
                <DropdownItem
                  disabled
                  tag="p"
                  className="text-center"
                  style={{
                    fontWeight: "bold",
                    fontFamily: "SpaceGrotesk",
                    justifyContent: "center",
                  }}
                >
                  {user != null ? user.profile.userName : ""}
                  <br></br>
                  {user != null ? user.profileTypeView.profileTypeName : ""}
                </DropdownItem>
                <DropdownItem disabled className="pb-0 pt-0">
                  {badge != null ? (
                    <>
                      <img width="32px" src={badge.image.filePath} />{" "}
                      <p
                        className={rtl ? "pr-2" : "pl-2"}
                        style={{ wordBreak: "break-all", whiteSpace: "normal" }}
                      >
                        {badge.name}
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </DropdownItem>
                <DropdownItem disabled className="pt-0">
                  {vettingBadge != null ? (
                    <>
                      <img width="32px" src={vettingBadge.image.filePath} />{" "}
                      <p
                        className={rtl ? "pr-2" : "pl-2"}
                        style={{ wordBreak: "break-all", whiteSpace: "normal" }}
                      >
                        {vettingBadge.name}
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    //    profileService.RefreshTokenAsync().then((user) => {
                    props.push("/seeker/profile-page");
                    setCurrentUnseenMessage(user.unseenMessages);
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                    //   });
                  }}
                >
                  {t("accountDropdown.viewProfile")}
                </DropdownItem>
                <DropdownItem
                  style={{ fontFamily: "SpaceGrotesk" }}
                  onClick={() => {
                    //   profileService.RefreshTokenAsync().then((user) => {
                    props.push("/seeker/accountSettings");
                    setCurrentUnseenMessage(user.unseenMessages);
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                    //  });
                  }}
                >
                  {t("accountDropdown.accountSettings")}
                </DropdownItem>

                <DropdownItem divider></DropdownItem>
                <DropdownItem
                  style={{ fontFamily: "SpaceGroteskBold" }}
                  onClick={() => profileService.Logout(user.profile.userId)}
                >
                  {t("logout")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <LanguageDropdown />
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
}

export default SeekerNavbar;
