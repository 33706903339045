import React, { useState, useContext } from "react";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { projectTypeService } from "_services";
import { Formik, Field, Form } from "formik";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import FormGroup from "reactstrap/lib/FormGroup";
import { LanguageContext } from "_context";
import { adminService } from "_services";
import { systemSetupService } from "_services";
import Switch from "react-bootstrap-switch";
import { processService } from "_services";

function EditJob({ history, match, location }) {
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [selectedPeriodicity, setPeriodicity] = useState("Daily");
  const [enabledJob, setEnabledJob] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const id = match.params.id;

  const [initialValues, setInitialValues] = useState({
    description: "",
    periodicity: "",
    reccurrence: 1,
    enabeled: false,
  });
  const [currentLan, setCurrentLan] = useContext(LanguageContext);

  const fetchData = async () => {
    setLoading(true);
    editInitialForm(location.state.state);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function editInitialForm(job) {
    var newJson = {
      description: job.description,
      periodicity: job.periodicity,
      reccurrence: job.reccurrence,
      enabeled: job.enabeled,
    };
    setEnabledJob(job.enabeled);
    setPeriodicity(job.periodicity);
    setInitialValues(newJson);
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    const model = {
      description: fields.description,
      periodicity: fields.periodicity,
      reccurrence: fields.reccurrence,
      enabeled: enabledJob,
    };
    await processService
      .UpdateProcessAsync(id, model)
      .then(() => {
        setSubmitting(false);
        setAlert(!alert);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });

    setSubmitting(false);
  }
  return (
    <div className="section">
      <Container>
        <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
          <div className="modal-header" style={{ margin: "0 auto" }}>
            <h5 className="modal-title">{t("done")}</h5>
          </div>
          <div className={rtl ? "modal-body text-right" : "modal-body"}>
            <p>{t("jobSuccess")}</p>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => {
                setAlert(!alert);
                history.push("..");
              }}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={{ margin: "auto" }}
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Row>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting, setFieldValue }) => {
              return (
                <Col md="8" style={{ margin: "auto" }}>
                  <Card className={rtl ? "text-right" : ""}>
                    <Form autoComplete="off">
                      <CardBody>
                        <CardTitle className="text-center" tag="h4">
                          {t("editJob")}
                        </CardTitle>
                        <Row>
                          <Col md="12">
                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("jobDescription")}
                                </label>
                                <Field
                                  disabled={true}
                                  name="description"
                                  placeholder={t("jobDescription")}
                                  className={"form-control"}
                                />
                              </FormGroup>
                            </div>
                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("jobPeriodicity")}
                                </label>
                                <Field
                                  style={{ fontSize: "14px" }}
                                  required={true}
                                  name="periodicity"
                                  as="select"
                                  placeholder={t("jobPeriodicity")}
                                  className={"form-control"}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "periodicity",
                                      e.target.value,
                                      false
                                    );
                                    setPeriodicity(e.target.value);
                                  }}
                                >
                                  <option value={"Daily"} defaultValue>
                                    {t("daily")}
                                  </option>
                                  <option value={"Weekly"}>
                                    {t("weekly")}
                                  </option>
                                  <option value={"Always"}>
                                    {t("Always")}
                                  </option>
                                </Field>
                              </FormGroup>
                            </div>
                            <div
                              className="form-group"
                              hidden={
                                selectedPeriodicity === "Daily" ||
                                selectedPeriodicity === "Always"
                              }
                            >
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("jobReccurrence")}
                                </label>
                                <Field
                                  style={{ fontSize: "14px" }}
                                  required={selectedPeriodicity === "Weekly"}
                                  name="reccurrence"
                                  as="select"
                                  placeholder={t("jobReccurrence")}
                                  className={"form-control"}
                                >
                                  <option value={1} defaultValue>
                                    {t("monday")}
                                  </option>
                                  <option value={2}>{t("tuesday")}</option>
                                  <option value={3}>{t("wednesday")}</option>
                                  <option value={4}>{t("thursday")}</option>
                                  <option value={5}>{t("friday")}</option>
                                  <option value={6}>{t("saturday")}</option>
                                  <option value={7}>{t("sunday")}</option>
                                </Field>
                              </FormGroup>
                            </div>
                            <div className="form-group">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <label htmlFor="inputPassword4">
                                  {t("enableJob")}
                                </label>
                                <Switch
                                  value={enabledJob}
                                  onChange={(e) => {
                                    setEnabledJob(!enabledJob);
                                  }}
                                  offColor=""
                                  offText={t("addProject.projectNDA.false")}
                                  onColor="brown"
                                  onText={t("addProject.projectNDA.true")}
                                ></Switch>
                              </FormGroup>
                            </div>
                            <div className="form-row">
                              <FormGroup
                                className="col-md-8"
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  padding: "10px",
                                  marginTop: "-2%",
                                }}
                              >
                                <center>
                                  <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    {t("createCompanyProfile.save")}
                                  </Button>
                                </center>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              );
            }}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

export default EditJob;
