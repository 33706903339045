import React, { useState, useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {
  numberOfEmpService,
  countryService,
  industryService,
  projectService,
  profileService,
  projectTypeService,
  budgetService,
} from "../../../_services";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  CardTitle,
  Modal,
  UncontrolledTooltip,
  Container,
} from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import { LanguageContext } from "../../../_context";
import { ProjectStatus } from "../../../_helpers";
import { Link } from "react-router-dom";

function AddProject({ history, match }) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const { id } = match.params;
  const isAddMode = !id;
  const [startDate, setStartDate] = useState(new Date());
  const user = profileService.userValue;
  const [empNB, setEmpNB] = useState(null);
  const [budget, setBudget] = useState(null);
  const [userBudget, setUserBudget] = useState(null);
  const [infoDocument, setInfoDocument] = useState(null);
  const [docInputList, setDocInputList] = useState([
    { document: null, display: true },
  ]);
  const [countryId, setCountryId] = useState(null);
  const [countries, setCountries] = useState(null);
  const [projectType, setProjectType] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [industriesForPublishTo] = useState([]);
  const [alert, setAlert] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [industryAndService, setIndustryAndService] = useState(null);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [VerificationErrorMessage, setVerificationErrorMessage] =
    useState(null);
  const [errorVerificationAlert, setErrorVerificationAlert] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [ndaText, setNdaText] = useState("");
  const [projectDescription, setProjectDescription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ndaRequired, setNdaRequired] = useState(t("label.yes"));
  const [project, setProject] = useState(null);

  const [newProjectID, setNewProjectID] = useState("");
  const [requireNDA, setRequiredNDA] = useState(false);

  const [spType, setSpType] = useState([
    {
      spTypeName: t("signUp.providerType.corporate"),
    },
    {
      spTypeName: t("signUp.providerType.startup"),
    },
  ]);
  const [publishToIndustriesAndServices, setPublishToIndustriesAndServices] =
    useState(null);
  const [publishToCountries, setPublishToCountries] = useState(null);
  const [publishToNumberOfEmployees, setPublishToNumberOfEmployees] =
    useState(null);
  const [publishToPOSType, setPublishToPOSType] = useState(null);
  const [initialValues, setInitialValues] = useState({
    projectTitle: "",
    countryID: "",
    budgetID: "",
    projectReference: "",
    projectNDA: "",
    projectTypeID: "",
  });
  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ direction: "" }, { direction: "rtl" }],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["clean"],
    ],
  };
  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "background",
  ];

  const [industriesOnly, setIndustriesOnly] = useState([]);
  const [servicesOnly, setServicesOnly] = useState([]);
  const servicesMultiselectRef = React.createRef();
  const [selectedList, setSelectedList] = useState([]);
  const [selectedIndustryList, setSelectedIndustryList] = useState([]);

  const validationSchema = Yup.object().shape({
    projectTitle: Yup.string().required(t("addProject.projectTitle.error")),
    budgetID: Yup.string().required(t("budget.required")),
    countryID: Yup.string().required(t("createCompanyProfile.country.error")),
    projectTypeID: Yup.string().required(t("opportunity.type.required")),
  });

  async function PublishOpportunity(id) {
    projectService
      .TogglePublishProjectAsync(id, true)
      .then(() => {
        // setAlert(!alert);
        // history.push(
        //   "/" + user.profileTypeView.profileTypeDescription + "/projects"
        // );
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  const fetchData = async (lan) => {
    setLoading(true);
    await numberOfEmpService.GetAllNumberOfEmployeesAsync().then((x) => {
      setEmpNB(x);
    });

    await budgetService.GetAllBudgetsAsync().then((x) => {
      setBudget(x);
    });

    await countryService.GetCountryByLanguageAsync(lan).then((x) => {
      setCountries(x);
    });

    if (isAddMode)
      await projectService.GetProjectDefaultNDAAsync(lan).then((x) => {
        setNdaText(x.message);
      });

    await industryService
      .GetIndustriesServicesByLanguageAsync(lan)
      .then(async (x) => {
        setIndustries(x);
        for (var i = 0; i < x.length; i++) {
          if (
            !industriesOnly.some(
              (industry) => industry.typeOfIndustryId === x[i].typeOfIndustryId
            )
          ) {
            industriesOnly.push({
              typeOfIndustryId: x[i].typeOfIndustryId,
              typeOfIndustryName: x[i].typeOfIndustryName,
            });
          }
          if (
            !industriesForPublishTo.some(
              (industry) => industry.typeOfIndustryId === x[i].typeOfIndustryId
            )
          ) {
            industriesForPublishTo.push({
              typeOfIndustryId: x[i].typeOfIndustryId,
              typeOfIndustryName: x[i].typeOfIndustryName,
            });
          }
        }

        if (!isAddMode) {
          await projectService
            .GetProjectByIdAsync(id, false, null, currentLan)
            .then((proj) => {
              setProject(proj);
              const values = {
                projectTitle: proj.projectTitle,
                countryID: proj.country.countryId,
                budgetID: proj.budget.id,
                projectTypeID: proj.projectTypeID,
                projectReference: proj.projectReference,
                projectNDA: proj.ndaRequired ? t("label.yes") : t("label.no"),
              };
              if (proj.budget != null) {
                setUserBudget(proj.budget.id);
              }
              setInfoDocument(proj.supportingDocuments.files);
              setProjectDescription(proj.projectDescription);
              setIndustryAndService([
                {
                  typeOfIndustryId: proj.typeOfIndustry.typeOfIndustryId,
                  typeOfIndustryName: proj.typeOfIndustry.typeOfIndustryName,
                  typeOfServiceId: proj.typeOfIndustry.typeOfServiceId,
                  typeOfServiceName: proj.typeOfIndustry.typeOfServiceName,
                },
              ]);
              const list = [proj.typeOfIndustry];
              setSelectedList(list);
              setSelectedIndustryList(list);

              const sList = [];
              x &&
                x.length > 0 &&
                x.map((industry) => {
                  if (
                    industry.typeOfIndustryId ===
                    proj.typeOfIndustry.typeOfIndustryId
                  ) {
                    sList.push(industry);
                  }
                });
              setServicesOnly(sList);

              setStartDate(new Date(proj.closingDate));
              setNdaRequired(proj.ndaRequired ? t("label.yes") : t("label.no"));
              setNdaText(proj.ndaText);
              setInitialValues(values);
              setPublishToCountries(proj.publishTo.countries);
              setPublishToIndustriesAndServices(
                proj.publishTo.typeOfIndustriesAndServices
              );
              if (proj.publishTo.spType == null) {
              } else {
                setPublishToPOSType([
                  {
                    spTypeName: proj.publishTo.spType,
                  },
                ]);
              }
              setPublishToNumberOfEmployees(proj.publishTo.numberOfEmployees);
            });
        }
        // else {
        //   setLoading(false);
        // }
      })
      .catch((error) => {
        if (
          error !== null &&
          error.response !== null &&
          error.response.data !== null &&
          error.response.data.message !== undefined
        ) {
          setError(error.response.data.message);
          if (
            error.response.data.error !== null &&
            error.response.data.error.length > 0
          ) {
            for (var i = 0; i < error.response.data.error.length; i++) {
              if (error.response.data.error[i].languageName == currentLan) {
                setError(error.response.data.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });

    await projectTypeService.GetProjectTypeByLanguageAsync(lan).then((x) => {
      setProjectType(x);
    });

    setLoading(false);
  };

  React.useEffect(() => {
    if (user.profile.companyInfoShowTo === "NDA") {
      setRequiredNDA(true);
    }
    if (user.profile.contactInfoShowTo === "NDA") {
      setRequiredNDA(true);
    }
    fetchData(currentLan);
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [currentLan]);

  function changeIndustryAndService(e) {
    setIndustryAndService(e);
  }

  function onIndustrySelectChangeAdd(e) {
    setSelectedIndustryList(e);
    //servicesMultiselectRef.current.resetSelectedValues();
    const list = [];
    if (e !== null) {
      e.map(async (industry) => {
        await list.push(industry.typeOfIndustryId);
      });
    }
    const servicesList = [];
    industries &&
      industries.map((element) => {
        if (list.includes(element.typeOfIndustryId)) {
          servicesList.push(element);
        }
      });
    setServicesOnly(servicesList);
  }

  function onIndustrySelectChangeRemove(e) {
    setSelectedIndustryList(e);
    //servicesMultiselectRef.current.resetSelectedValues();
    const list = [];
    if (e !== null) {
      e.map(async (industry) => {
        await list.push(industry.typeOfIndustryId);
      });
    }
    const newList = [];
    if (selectedList !== null) {
      selectedList.map((element) => {
        if (list.includes(element.typeOfIndustryId) === true) {
          newList.push(element);
        }
      });
      setSelectedList(newList);
    }

    const servicesList = [];
    industries &&
      industries.map((element) => {
        if (list.includes(element.typeOfIndustryId)) {
          servicesList.push(element);
        }
      });
    setServicesOnly(servicesList);
  }

  function onChange(e) {
    setSelectedList(e);
  }

  function changePublishToIndustriesAndServices(e) {
    setPublishToIndustriesAndServices(e);
  }

  function changePublishToCountries(e) {
    setPublishToCountries(e);
  }

  function changePublishToNumberOfEmployees(e) {
    setPublishToNumberOfEmployees(e);
  }

  function changePublishToPOSType(e) {
    setPublishToPOSType(e);
  }

  const handleDocInputChange = (e, index) => {
    //    const { name, value } = e.target;
    const list = [...docInputList];
    list[index]["document"] = e;
    setDocInputList(list);
  };

  const handleDocTypeInputChange = (e, index) => {
    //    const { name, value } = e.target;
    const list = [...docInputList];
    list[index]["documentType"] = e;
    setDocInputList(list);
  };

  // handle click event of the Remove button
  const handleDocRemoveClick = (index, x) => {
    const list = [...docInputList];
    list[index].display = false;
    if (index === 0) {
      document.getElementById(`documentFile${index}`).value = "";
    }

    setDocInputList(list);

    let newList = [];
    list.map((x) => {
      if (x.display === true) {
        newList.push(x);
      }
    });

    if (newList.length === 0) {
      setDocInputList([{ document: "", display: true }]);
    }
  };

  // handle click event of the Add button
  const handleDocAddClick = () => {
    setDocInputList([...docInputList, { document: "", display: true }]);
  };

  async function validateDocument() {
    //Get reference of File.
    var files = document.getElementsByName("fileDocs");

    //Check whether the file is valid Image.
    var regex = new RegExp(
      "([a-zA-Z0-9s_\\.-:]())+(.jpg|.png|.gif|.JPG|.PNG|.JPEG|.jpeg|.pdf|.PDF)$"
    );
    var i;
    for (i = 0; i < files.length; i++) {
      if (regex.test(files[i].value.toLowerCase())) {
        //Check whether HTML5 is supported.
        if (typeof files[i].files != "undefined") {
          //Initiate the FileReader object.
          // return true;
        } else {
          setError(t("logo.browserSupport"));
          setErrorAlert(!errorAlert);
          return false;
        }
      } else {
        setError(t("supportingDoc.validate"));
        setErrorAlert(!errorAlert);
        return false;
      }
    }
    return true;
  }

  async function onSubmit(fields, { setSubmitting }) {
    // if (
    //   industryAndService === null ||
    //   (industryAndService && industryAndService.length === 0)
    // ) {
    //   setError(t("required.industries"));
    //   setErrorAlert(!errorAlert);
    // }

    if (
      docInputList[0].document !== null &&
      docInputList[0].document !== "" &&
      docInputList[0].display === true
    ) {
      const isDocValid = await validateDocument();
      if (isDocValid === false) {
        return null;
      }
    }

    var regex = /(<([^>]+)>)/gi;

    if (requireNDA === true) {
      var hasText = !!ndaText.replace(regex, "").length;
      if (hasText === false) {
        setError(t("nda.required"));
        setErrorAlert(!errorAlert);
        return false;
      }
    }
    if (selectedList === null || selectedList.length === 0) {
      setError(t("required.industries"));
      setErrorAlert(!errorAlert);
      return null;
    }

    if (projectDescription == null || projectDescription == "") {
      setError(t("projectDescription.required"));
      setErrorAlert(!errorAlert);
    } else {
      const formData = new FormData();
      formData.append("profileID", user.profile.userId);
      if (fields.projectTitle !== null) {
        formData.append("projectTitle", fields.projectTitle);
      }

      if (projectDescription !== null) {
        formData.append("projectDescription", projectDescription);
      }

      if (fields.countryID !== null) {
        formData.append("countryID", fields.countryID);
      }

      if (fields.projectReference !== null) {
        formData.append("projectReference", fields.projectReference);
      }

      formData.append(
        "industryAndService.typeOfIndustryId",
        selectedList[0].typeOfIndustryId
      );
      formData.append(
        "industryAndService.typeOfIndustryName",
        selectedList[0].typeOfIndustryName
      );
      formData.append(
        "industryAndService.typeOfServiceId",
        selectedList[0].typeOfServiceId
      );
      if (userBudget != null) {
        formData.append("budgetId", userBudget);
      }
      formData.append(
        "industryAndService.typeOfServiceName",
        selectedList[0].typeOfServiceName
      );

      if (startDate !== null) {
        let finalDate = `${new Date(startDate).getDate()}/${
          monthNames[new Date(startDate).getMonth()]
        }/${new Date(startDate).getFullYear().toString().substr(-2)}`;
        formData.append("closingDate", finalDate);
      }

      if (ndaRequired !== null) {
        formData.append("nDARequired", ndaRequired);
      }

      if (ndaText !== null && ndaRequired === t("yes")) {
        formData.append("NDAText", ndaText);
      }

      if (fields.projectTypeID !== null) {
        formData.append("projectTypeID", fields.projectTypeID);
      }

      if (publishToPOSType != null) {
        if (publishToPOSType.length > 0) {
          formData.append("publishTo.spType", publishToPOSType[0].spTypeName);
        }
      }
      if (publishToCountries != null) {
        var i = 0;
        if (publishToCountries.count != 0) {
          publishToCountries.forEach((item) => {
            formData.append(
              `publishTo.countries[${i}].countryId`,
              item.countryId
            );
            formData.append(
              `publishTo.countries[${i}].description`,
              item.description
            );
            formData.append(
              `publishTo.countries[${i}].countryName`,
              item.countryName
            );
            i++;
          });
        }
      }
      if (publishToIndustriesAndServices != null) {
        if (publishToIndustriesAndServices.count != 0) {
          var j = 0;
          publishToIndustriesAndServices.forEach((item) => {
            formData.append(
              `publishTo.TypeOfIndustriesAndServices[${j}].typeOfIndustryId`,
              item.typeOfIndustryId
            );
            formData.append(
              `publishTo.TypeOfIndustriesAndServices[${j}].typeOfIndustryName`,
              item.typeOfIndustryName
            );
            formData.append(
              `publishTo.TypeOfIndustriesAndServices[${j}].typeOfServiceId`,
              item.typeOfServiceId
            );
            formData.append(
              `publishTo.TypeOfIndustriesAndServices[${j}].typeOfServiceName`,
              item.typeOfServiceName
            );
            j++;
          });
        }
      }
      if (publishToNumberOfEmployees != null) {
        if (publishToNumberOfEmployees.count != 0) {
          var k = 0;
          publishToNumberOfEmployees.forEach((item) => {
            formData.append(`publishTo.NumberOfEmployees[${k}].id`, item.id);
            formData.append(
              `publishTo.NumberOfEmployees[${k}].description`,
              item.description
            );
            k++;
          });
        }
      }
      // if (docInputList[0].document === null) {
      // } else {
      //   docInputList.forEach((item) => {
      //     if (item.display === true &&
      //       item.document !== null
      //       && item.document !== "") {
      //       formData.append("Document", item.document);
      //     }

      //   });
      // }
      docInputList.forEach((item) => {
        if (
          item.display === true &&
          item.document !== null &&
          item.document !== ""
        ) {
          formData.append("Document", item.document);
        }
      });

      if (isAddMode) {
        await projectService
          .AddProjectAsync(formData)
          .then((project) => {
            setSubmitting(false);
            setNewProjectID(project.data.projectID);
            setAlert(!alert);
          })
          .catch((error) => {
            setSubmitting(false);
            if (
              error !== null &&
              error.response !== null &&
              error.response.data !== null &&
              error.response.data.message !== undefined
            ) {
              setError(error.response.data.message);
              if (
                error.response.data.error !== null &&
                error.response.data.error.length > 0
              ) {
                for (var i = 0; i < error.response.data.error.length; i++) {
                  if (error.response.data.error[i].languageName == currentLan) {
                    setError(error.response.data.error[i].error);
                  }
                }
              }
            } else {
              setError(error);
            }
            setErrorAlert(!errorAlert);
          });
      } else {
        await projectService
          .UpdateProjectAsync(id, formData)
          .then(() => {
            setSubmitting(false);
            setEditAlert(!editAlert);
          })
          .catch((error) => {
            console.log(error.response.data);
            setSubmitting(false);
            if (
              error !== null &&
              error.response !== null &&
              error.response.data !== null &&
              error.response.data.message !== undefined
            ) {
              setError(error.response.data.message);
              if (
                error.response.data.error !== null &&
                error.response.data.error.length > 0
              ) {
                for (var i = 0; i < error.response.data.error.length; i++) {
                  if (error.response.data.error[i].languageName == currentLan) {
                    setError(error.response.data.error[i].error);
                  }
                }
              }
            } else {
              setError(error);
            }
            setErrorAlert(!errorAlert);
          });
      }
    }
  }

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  return (
    <>
      <div
        className={
          rtl ? "section section-signup text-justify" : "section section-signup"
        }
      >
        <Container style={{ marginTop: "-5%" }}>
          <Modal isOpen={alert} style={rtl ? { textAlign: "right" } : {}}>
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("addProject.success.title")}</h5>
            </div>
            <div className="modal-body">
              <p>{t("publishNowSecond")}</p>
            </div>
            <div
              className="modal-footer"
              style={
                rtl
                  ? { justifyContent: "flex-end" }
                  : { justifyContent: "flex-start" }
              }
            >
              <Button
                className="mr-2"
                color="primary"
                type="button"
                onClick={async (e) => {
                  setAlert(!alert);
                  await projectService
                    .TogglePublishProjectAsync(newProjectID, true)
                    .then(() => {
                      history.push(
                        "/" +
                          user.profileTypeView.profileTypeDescription +
                          "/projects"
                      );
                    })
                    .catch((error) => {
                      if (error.message !== undefined) {
                        setError(error.message);
                        if (error.error !== null && error.error.length > 0) {
                          for (var i = 0; i < error.error.length; i++) {
                            if (error.error[i].languageName == currentLan) {
                              setError(error.error[i].error);
                            }
                          }
                        }
                      } else {
                        setError(error);
                      }
                      setErrorAlert(!errorAlert);
                    });
                }}
              >
                {t("addProject.projectNDA.true")}
              </Button>
              <Link
                color="primary"
                type="button"
                onClick={(e) => {
                  setAlert(!alert);
                  history.push(
                    "/" +
                      user.profileTypeView.profileTypeDescription +
                      "/projects"
                  );
                }}
              >
                {t("addProject.projectNDA.false")}
              </Link>
            </div>
          </Modal>
          <Modal isOpen={editAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("editProject.success.title")}</h5>
            </div>
            <div className="modal-body">
              {project && project.projectStatus == ProjectStatus.Unpublished ? (
                <p>{t("editProject.success.body.unpublished")}</p>
              ) : (
                <p>{t("editProject.success.body.published")}</p>
              )}
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => {
                  setAlert(!alert);
                  history.push(
                    "/" +
                      user.profileTypeView.profileTypeDescription +
                      "/projects"
                  );
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{errorMessage}</p>
            </div>

            <div
              className="modal-footer"
              style={
                rtl
                  ? { margin: "0 auto" }
                  : { margin: "0 auto", textAlign: "right" }
              }
            >
              <Button
                color="danger"
                type="button"
                style={{ margin: "auto" }}
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={errorVerificationAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div className="modal-header">
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div className="modal-body">
              <p>{VerificationErrorMessage}</p>
            </div>
            <div
              className="modal-footer"
              style={
                rtl
                  ? { margin: "0 auto" }
                  : { margin: "0 auto", textAlign: "right" }
              }
            >
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => {
                  setErrorVerificationAlert(!errorVerificationAlert);
                  history.push("/");
                }}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, isSubmitting, setFieldValue }) => {
                return (
                  <div>
                    <Card style={{ marginTop: "5%" }}>
                      <CardHeader className="text-center">
                        <CardTitle className="" tag="h4">
                          {isAddMode
                            ? t("addProject.title")
                            : t("editProject.title")}
                        </CardTitle>
                      </CardHeader>
                      <Form autoComplete="off">
                        <CardBody>
                          <Container>
                            <h4>{t("addProject.detailsTitle")}</h4>
                            <hr
                              style={{
                                borderTop: "1px solid #bbb",
                                marginTop: "-1%",
                              }}
                            ></hr>
                            <div
                              className="form-row"
                              style={{ marginTop: "3%" }}
                            >
                              <FormGroup className="col-md-6">
                                <label htmlFor="projectTypeID">
                                  {t("addProject.projectType")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>
                                <Field
                                  autoFocus={true}
                                  style={
                                    rtl
                                      ? {
                                          fontSize: "14px",
                                          textAlign: "right",
                                        }
                                      : {
                                          fontSize: "14px",
                                        }
                                  }
                                  name="projectTypeID"
                                  as="select"
                                  className={
                                    "form-control" +
                                    (errors.projectTypeID &&
                                    touched.projectTypeID
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={(v) => {
                                    setFieldValue(
                                      "projectTypeID",
                                      v.target.value
                                    );
                                  }}
                                >
                                  <option
                                    value={null}
                                    defaultValue
                                    style={rtl ? { textAlign: "right" } : {}}
                                  >
                                    {t("addProject.projectType.placeholder")}
                                  </option>
                                  {projectType &&
                                    projectType.map((projectTypes) => (
                                      <option
                                        key={projectTypes.projectTypeId}
                                        value={projectTypes.projectTypeId}
                                      >
                                        {projectTypes.projectTypeName}
                                      </option>
                                    ))}
                                </Field>
                                {errors.projectTypeID &&
                                touched.projectTypeID ? (
                                  <ErrorMessage
                                    name="projectTypeID"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                ) : (
                                  <span className="isValid" component="div">
                                    <br></br>
                                  </span>
                                )}
                              </FormGroup>
                            </div>
                            <div
                              className="form-row"
                              //style={{ marginTop: "3%" }}
                            >
                              <FormGroup className="col-md-6">
                                <label htmlFor="projectTitle">
                                  {t("addProject.projectTitle")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>
                                <Field
                                  name="projectTitle"
                                  placeholder={t("addProject.projectTitle")}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.projectTitle && touched.projectTitle
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                {errors.projectTitle && touched.projectTitle ? (
                                  <ErrorMessage
                                    name="projectTitle"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                ) : (
                                  <span className="isValid" component="div">
                                    <br></br>
                                  </span>
                                )}
                              </FormGroup>
                            </div>
                            <div className="form-row">
                              <FormGroup className="col">
                                <label htmlFor="projectDescription">
                                  {t("addProject.projectDescription")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>
                                <ReactQuill
                                  theme="snow"
                                  value={projectDescription}
                                  onChange={(e) => setProjectDescription(e)}
                                  modules={modules}
                                  formats={formats}
                                />
                              </FormGroup>
                            </div>
                            <div className="form-row">
                              <FormGroup className="col-md-6">
                                <label htmlFor="industryAndService">
                                  {t("opportunityCategory")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>

                                <i
                                  id="industries"
                                  className="fa fa-question-circle"
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <UncontrolledTooltip
                                    placement="right"
                                    target="#industries"
                                    delay={0}
                                  >
                                    {t("addproject.industry.info")}
                                  </UncontrolledTooltip>
                                </i>
                                {industriesOnly && (
                                  <Multiselect
                                    selectedValues={selectedIndustryList}
                                    style={
                                      rtl
                                        ? {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },

                                            option: {
                                              textAlign: "right",
                                            },
                                          }
                                        : {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },
                                          }
                                    }
                                    options={industriesOnly}
                                    placeholder={t("opportunityCategory")}
                                    hidePlaceholder={true}
                                    displayValue="typeOfIndustryName"
                                    //   groupBy="typeOfIndustryName"
                                    showCheckbox={true}
                                    selectionLimit={1}
                                    onSelect={(e) => {
                                      onIndustrySelectChangeAdd(e);
                                    }}
                                    onRemove={(e) => {
                                      onIndustrySelectChangeRemove(e);
                                    }}
                                  />
                                )}
                              </FormGroup>
                              <FormGroup className="col-md-6">
                                <label htmlFor="industry">
                                  {t("opportunitySpecialty")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>
                                <i
                                  id="services"
                                  className="fa fa-question-circle"
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <UncontrolledTooltip
                                    placement="right"
                                    target="#services"
                                    delay={0}
                                  >
                                    {t("addproject.industry.info.second")}
                                  </UncontrolledTooltip>
                                </i>
                                {servicesOnly && (
                                  <Multiselect
                                    selectedValues={selectedList}
                                    hidePlaceholder={true}
                                    style={
                                      rtl
                                        ? {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },

                                            option: {
                                              textAlign: "right",
                                            },
                                          }
                                        : {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },
                                          }
                                    }
                                    options={servicesOnly}
                                    placeholder={t("opportunitySpecialty")}
                                    displayValue="typeOfServiceName"
                                    groupBy="typeOfIndustryName"
                                    closeOnSelect={false}
                                    showCheckbox={true}
                                    emptyRecordMsg={t("invalid.services")}
                                    //  ref={servicesMultiselectRef}
                                    selectionLimit={1}
                                    onSelect={(e) => {
                                      onChange(e);
                                    }}
                                    onRemove={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                <span className="isValid" component="div">
                                  <br></br>
                                </span>
                              </FormGroup>
                            </div>
                            <div className="form-row">
                              <FormGroup className="col-md-6">
                                <label htmlFor="countryID">
                                  {t("createCompanyProfile.country")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>
                                <Field
                                  style={
                                    rtl
                                      ? {
                                          fontSize: "14px",
                                          textAlign: "right",
                                        }
                                      : {
                                          fontSize: "14px",
                                        }
                                  }
                                  name="countryID"
                                  placeholder={t(
                                    "createCompanyProfile.country"
                                  )}
                                  onChange={(v) => {
                                    setCountryId(v.target.value);
                                    setFieldValue("countryID", v.target.value);
                                  }}
                                  as="select"
                                  className={
                                    "form-control" +
                                    (errors.countryID && touched.countryID
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <option value="" defaultValue>
                                    {t(
                                      "createCompanyProfile.country.placeholder"
                                    )}
                                  </option>
                                  {countries &&
                                    countries.map((country) => (
                                      <option
                                        key={country.countryId}
                                        value={country.countryId}
                                      >
                                        {country.countryName}
                                      </option>
                                    ))}
                                </Field>

                                {errors.countryID && touched.countryID ? (
                                  <ErrorMessage
                                    name="countryID"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                ) : (
                                  <span className="isValid" component="div">
                                    <br></br>
                                  </span>
                                )}
                              </FormGroup>
                              <FormGroup className="col-md-6">
                                <label htmlFor="budgetID">
                                  {t("addProject.budget")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>
                                <Field
                                  style={
                                    rtl
                                      ? {
                                          fontSize: "14px",
                                          textAlign: "right",
                                        }
                                      : {
                                          fontSize: "14px",
                                        }
                                  }
                                  name="budgetID"
                                  placeholder={t("addProject.budget")}
                                  onChange={(v) => {
                                    setUserBudget(v.target.value);
                                    setFieldValue("budgetID", v.target.value);
                                  }}
                                  as="select"
                                  className={
                                    "form-control" +
                                    (errors.budgetID && touched.budgetID
                                      ? " is-invalid"
                                      : "")
                                  }
                                >
                                  <option value={""} defaultValue hidden>
                                    {t("addProject.budget.placeholder")}
                                  </option>
                                  {budget &&
                                    budget.map((budget) => (
                                      <option key={budget.id} value={budget.id}>
                                        {budget.description}
                                      </option>
                                    ))}
                                </Field>
                                {errors.budgetID && touched.budgetID ? (
                                  <ErrorMessage
                                    name="budgetID"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                ) : (
                                  <span className="isValid" component="div">
                                    <br></br>
                                  </span>
                                )}
                              </FormGroup>
                            </div>
                            <div className="form-row">
                              <FormGroup className="col-md-6">
                                <label htmlFor="projectReference">
                                  {t("addProject.projectReference")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  <br></br>
                                </label>
                                <Field
                                  name="projectReference"
                                  placeholder={t("addProject.projectReference")}
                                  type="text"
                                  className={"form-control"}
                                />
                              </FormGroup>
                              <FormGroup className="col-md-6">
                                <label htmlFor="projectClosingDate">
                                  {t("addProject.projectClosingDate")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>
                                <br></br>
                                <DatePicker
                                  selected={startDate}
                                  dateFormat="MMMM d, yyyy"
                                  className="form-control"
                                  name={startDate}
                                  onChange={(date) => {
                                    setFieldValue("startDate", date);
                                    setStartDate(date);
                                    // let d = new Date(
                                    //   date - date.getTimezoneOffset()
                                    // );
                                  }}
                                />
                              </FormGroup>
                            </div>
                            <div className="form-row">
                              <FormGroup className="col-md-6">
                                <label htmlFor="projectNDA">
                                  {t("addProject.projectNDA")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "white" }}
                                  htmlFor="projectTypeID"
                                >
                                  <br></br>
                                </label>
                                <Field
                                  style={
                                    rtl
                                      ? {
                                          fontSize: "14px",
                                          textAlign: "right",
                                        }
                                      : {
                                          fontSize: "14px",
                                        }
                                  }
                                  name="projectNDA"
                                  onChange={(v) => {
                                    setNdaRequired(v.target.value);
                                    setFieldValue("projectNDA", v.target.value);
                                  }}
                                  as="select"
                                  disabled={requireNDA}
                                  className={"form-control"}
                                >
                                  <option
                                    defaultValue
                                    defaultChecked
                                    value={t("yes")}
                                  >
                                    {t("addProject.projectNDA.true")}
                                  </option>
                                  <option value={t("no")}>
                                    {t("addProject.projectNDA.false")}
                                  </option>
                                </Field>
                              </FormGroup>
                            </div>
                            {ndaRequired === t("yes") ? (
                              <div className="form-row">
                                <FormGroup className="col-md-12">
                                  <label htmlFor="NdaText">
                                    {t("addProject.projectNDAText")}
                                  </label>
                                  <label
                                    style={{ fontSize: "25px", color: "red" }}
                                    htmlFor="projectTypeID"
                                  >
                                    <br></br>
                                  </label>
                                  <ReactQuill
                                    theme="snow"
                                    value={ndaText}
                                    onChange={(e) => setNdaText(e)}
                                    modules={modules}
                                    formats={formats}
                                  />
                                </FormGroup>
                              </div>
                            ) : (
                              ""
                            )}
                            <h4>{t("addProject.projectPublishTo")}</h4>
                            <hr
                              style={{
                                borderTop: "1px solid #bbb",
                                marginTop: "-1%",
                              }}
                            ></hr>
                            <div
                              className="form-row"
                              style={{ marginTop: "3%" }}
                            >
                              <FormGroup className="col-md-6">
                                <label htmlFor="publishToIndustriesAndServices">
                                  {t(
                                    "addProject.projectPublishToInustriesAndServices"
                                  )}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  <br></br>
                                </label>
                                {industries && (
                                  <Multiselect
                                    selectedValues={
                                      publishToIndustriesAndServices
                                    }
                                    hidePlaceholder={true}
                                    style={
                                      rtl
                                        ? {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },

                                            option: {
                                              textAlign: "right",
                                            },
                                          }
                                        : {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },
                                          }
                                    }
                                    options={industriesForPublishTo}
                                    placeholder={t(
                                      "addProject.projectPublishToInustriesAndServices"
                                    )}
                                    displayValue="typeOfIndustryName"
                                    //groupBy="typeOfIndustryName"
                                    showCheckbox={true}
                                    onSelect={(e) => {
                                      changePublishToIndustriesAndServices(e);
                                    }}
                                    onRemove={(e) => {
                                      changePublishToIndustriesAndServices(e);
                                    }}
                                  />
                                )}
                              </FormGroup>
                              {/* <FormGroup
                                className="col-md-6"
                                hidden={!isAddMode}
                              >
                                <label htmlFor="publishToCountries">
                                  {t("addProject.projectPublishToCountries")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  <br></br>
                                </label>
                                {countries && (
                                  <Multiselect
                                    hidePlaceholder={true}
                                    selectedValues={publishToCountries}
                                    style={{
                                      searchBox: {
                                        borderRadius: "0px",
                                        border: "1px solid #E3E3E3",
                                      },
                                    }}
                                    options={countries}
                                    placeholder={t(
                                      "addProject.projectPublishToCountries"
                                    )}
                                    displayValue="countryName"
                                    showCheckbox={true}
                                    onSelect={(e) => {
                                      changePublishToCountries(e);
                                    }}
                                    onRemove={(e) => {
                                      changePublishToCountries(e);
                                    }}
                                  />
                                )}
                              </FormGroup> */}
                            </div>
                            <div className="form-row">
                              <FormGroup className="col-md-6">
                                <label htmlFor="publishToEmployeesNumber">
                                  {t(
                                    "addProject.projectPublishToEmployeesNumber"
                                  )}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  <br></br>
                                </label>
                                {empNB && (
                                  <Multiselect
                                    hidePlaceholder={true}
                                    selectedValues={publishToNumberOfEmployees}
                                    style={
                                      rtl
                                        ? {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },

                                            option: {
                                              textAlign: "right",
                                            },
                                          }
                                        : {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },
                                          }
                                    }
                                    options={empNB}
                                    placeholder={t(
                                      "addProject.projectPublishToEmployeesNumber"
                                    )}
                                    displayValue="description"
                                    showCheckbox={true}
                                    onSelect={(e) => {
                                      changePublishToNumberOfEmployees(e);
                                    }}
                                    onRemove={(e) => {
                                      changePublishToNumberOfEmployees(e);
                                    }}
                                  />
                                )}
                              </FormGroup>
                              <FormGroup className="col-md-6">
                                <label htmlFor="publishToPOSType">
                                  {t("addProject.projectPublishToPOSType")}
                                </label>
                                <label
                                  style={{ fontSize: "25px", color: "red" }}
                                  htmlFor="projectTypeID"
                                >
                                  <br></br>
                                </label>
                                {spType && (
                                  <Multiselect
                                    hidePlaceholder={true}
                                    selectedValues={publishToPOSType}
                                    style={
                                      rtl
                                        ? {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },

                                            option: {
                                              textAlign: "right",
                                            },
                                          }
                                        : {
                                            searchBox: {
                                              borderRadius: "0px",
                                              border: "1px solid #E3E3E3",
                                            },
                                            inputField: {
                                              width: "100%",
                                            },
                                          }
                                    }
                                    options={spType}
                                    placeholder={t(
                                      "addProject.projectPublishToPOSType"
                                    )}
                                    displayValue="spTypeName"
                                    showCheckbox={true}
                                    //singleSelect={true}
                                    selectionLimit={1}
                                    onSelect={(e) => {
                                      changePublishToPOSType(e);
                                    }}
                                    onRemove={(e) => {
                                      changePublishToPOSType(e);
                                    }}
                                  />
                                )}
                              </FormGroup>
                            </div>
                            <h4>
                              {t("createCompanyProfile.supportingDocs")}{" "}
                              <i id="docs" className="fa fa-question-circle">
                                <UncontrolledTooltip
                                  placement="right"
                                  target="#docs"
                                  delay={0}
                                >
                                  {t("projectDocs.tooltip")}
                                </UncontrolledTooltip>
                              </i>
                            </h4>
                            <hr
                              style={{
                                borderTop: "1px solid #bbb",
                                marginTop: "-1%",
                              }}
                            ></hr>
                            {infoDocument &&
                              infoDocument.map((doc, i) => {
                                return (
                                  <div className="form-row">
                                    <FormGroup className="col-md-4">
                                      <a
                                        href={doc.filePath}
                                        rel="noreferrer"
                                        target="_blank"
                                        className="form-control"
                                      >
                                        {doc.fileName}
                                      </a>
                                    </FormGroup>
                                    <Button
                                      style={{
                                        marginTop: "auto",
                                      }}
                                      className="mr10"
                                      color="danger"
                                      onClick={() => {
                                        const model = {
                                          documentName: doc.fileName,
                                        };
                                        projectService
                                          .RemoveDocumentByNameAsync(id, model)
                                          .then(() => {
                                            const newList = infoDocument.filter(
                                              (item) =>
                                                item.fileName !== doc.fileName
                                            );
                                            setInfoDocument(newList);
                                          });
                                      }}
                                    >
                                      <i
                                        class="fa fa-minus"
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  </div>
                                );
                              })}
                            {docInputList &&
                              docInputList.map((x, i) => {
                                return x.display === true ? (
                                  <div style={{ display: "flex" }}>
                                    <FormGroup className="col-md-4">
                                      <label htmlFor="documents">
                                        {t("createCompanyProfile.uploadDoc")}
                                      </label>
                                      <input
                                        id={`documentFile${i}`}
                                        name="fileDocs"
                                        type="file"
                                        accept="image/*,.pdf"
                                        className={"form-control"}
                                        onChange={(event) =>
                                          handleDocInputChange(
                                            event.target.files[0],
                                            i
                                          )
                                        }
                                      />
                                    </FormGroup>

                                    {docInputList.length > 0 && (
                                      <Button
                                        style={{
                                          height: "50%",
                                          marginTop: "29px",
                                        }}
                                        className="mr10"
                                        onClick={(e) => {
                                          handleDocRemoveClick(i, x);
                                        }}
                                        color="danger"
                                      >
                                        <i
                                          class="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    )}
                                    {/* {docInputList.length - 1 === i && (
                                        <Button
                                          onClick={handleDocAddClick}
                                          style={{
                                            height: "50%",
                                            marginTop: "29px",
                                          }}
                                          color="primary"
                                        >
                                          <i
                                            class="fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                        </Button>
                                      )} */}
                                  </div>
                                ) : null;
                              })}
                            <FormGroup className="col-md-12">
                              <Button
                                onClick={handleDocAddClick}
                                style={{
                                  height: "50%",
                                  marginTop: "0px",
                                }}
                                color="primary"
                              >
                                <i class="fa fa-plus" aria-hidden="true"></i>
                              </Button>
                            </FormGroup>
                            <div className="form-row">
                              <FormGroup className="col-md-12">
                                <label
                                  style={{
                                    fontSize: "25px",
                                    color: "red",
                                  }}
                                  htmlFor="projectTypeID"
                                >
                                  *
                                </label>
                                <label>{t("requiredFields")}</label>
                              </FormGroup>
                            </div>
                            <FormGroup className="col-md-12 text-center">
                              <Button
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}
                                onClick={() => {
                                  if (Object.keys(errors).length !== 0) {
                                    setError(t("fillMandatoryFields"));
                                    setErrorAlert(!errorAlert);
                                  }
                                }}
                              >
                                {isSubmitting && (
                                  <span className="spinner-border spinner-border-sm mr-1"></span>
                                )}
                                {t("createCompanyProfile.save")}
                              </Button>
                            </FormGroup>
                          </Container>
                        </CardBody>
                      </Form>
                    </Card>
                  </div>
                );
              }}
            </Formik>
          </>
        </Container>
      </div>
    </>
  );
}

export default AddProject;
