import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddEditBadge from "./AddEditBadge";
import AddEditSS_PO_SPBadge from "./AddEditSS_PO_SPBadge";
import AddEditVettingBadge from "./AddEditVettingBadge";
import BadgesList from "./BadgesList";

function Badges({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={BadgesList} exact />
        <PrivateRoute
          path={`${path}/edit/activity/:id`}
          component={AddEditSS_PO_SPBadge}
        />
        <PrivateRoute
          path={`${path}/edit/vetting/:id`}
          component={AddEditVettingBadge}
        />
        <PrivateRoute path={`${path}/add`} component={AddEditBadge} />
      </Switch>
    </div>
  );
}

export { Badges };
