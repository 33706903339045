import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import AddNewsletters from "./AddNewsletters";
import Newsletters from "./Newsletters";

function Newsletter({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <PrivateRoute path={`${path}`} component={Newsletters} exact />
        <PrivateRoute path={`${path}/add`} exact component={AddNewsletters} />
        <PrivateRoute path={`${path}/add/:id`} component={AddNewsletters} />
      </Switch>
    </div>
  );
}

export { Newsletter };
