import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/search`;

export const searchService = {
  SearchServiceProvidersAsync,
  SearchProjectsAsync,
  SearchDirectLinksAsync,
  SearchPartnerOrgAsync,
  SearchUsersAsync,
  SearchUsersCountAsync,
  GetAllUsersWithNoBadgesAsync,
  GetAllUsersWithNoBadgesCountAsync,
  SearchSPsAsync,
  SearchSPsCountAsync,
  SearchPartnersAsync,
  SearchPartnersCountAsync,
  SearchSPSurveysAsync,
  SearchSPSurveysCountAsync,
  SearchSsSurveysAsync,
  SearchSsSurveysCountAsync,
  SearchNewslettersAsync,
  SearchNewslettersCountAsync,
  SearchEmailLogsAsync,
  SearchEmailLogsCountAsync,
  SearchExternalProfilesAsync,
  SearchExternalProfilesCountAsync,
  SearchExternalOpportunitiesAsync,
  SearchExternalOpportunitiesCountAsync,
  SearchListedOpportunitiesAsync,
  SearchListedOpportunitiesCountAsync,
  SearchDirectLinksTableAsync,
  SearchDirectLinksTableCountAsync,
  SearchMessagesAsync,
  SearchMessagesCountAsync,
};

function SearchServiceProvidersAsync(params, language) {
  return fetchWrapper.post(
    `${baseUrl}/SearchServiceProviders/${language}`,
    params
  );
}

function SearchPartnerOrgAsync(params, language) {
  return fetchWrapper.post(`${baseUrl}/SearchPartnerOrg/${language}`, params);
}

function SearchProjectsAsync(params, language) {
  return fetchWrapper.post(`${baseUrl}/SearchProjects/${language}`, params);
}

function SearchDirectLinksAsync(params, language) {
  return fetchWrapper.post(`${baseUrl}/SearchDirectLink/${language}`, params);
}

function SearchUsersAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchUsers`, params);
}

function SearchUsersCountAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchUsersCount`, params);
}

function GetAllUsersWithNoBadgesAsync(params) {
  return fetchWrapper.post(`${baseUrl}/getAllUsersWithNoBadges`, params);
}

function GetAllUsersWithNoBadgesCountAsync(params) {
  return fetchWrapper.post(`${baseUrl}/getAllUsersWithNoBadgesCount`, params);
}

function SearchSPsAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchSPs`, params);
}
function SearchSPsCountAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchSPsCount`, params);
}

function SearchPartnersAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchPartners`, params);
}
function SearchPartnersCountAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchPartnersCount`, params);
}

function SearchSPSurveysAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchSPSurveys`, params);
}
function SearchSPSurveysCountAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchSPSurveysCount`, params);
}

function SearchSsSurveysAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchSsSurveys`, params);
}
function SearchSsSurveysCountAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchSsSurveysCount`, params);
}

function SearchNewslettersAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchNewsletters`, params);
}
function SearchNewslettersCountAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchNewslettersCount`, params);
}

function SearchEmailLogsAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchEmailLogs`, params);
}
function SearchEmailLogsCountAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchEmailLogsCount`, params);
}
function SearchExternalProfilesAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchExternalProfiles`, params);
}
function SearchExternalProfilesCountAsync(params) {
  return fetchWrapper.post(`${baseUrl}/searchExternalProfilesCount`, params);
}
function SearchExternalOpportunitiesAsync(params) {
  return fetchWrapper.post(
    `${baseUrl}/SearchExternalOpportunitiesTable`,
    params
  );
}
function SearchExternalOpportunitiesCountAsync(params) {
  return fetchWrapper.post(
    `${baseUrl}/SearchExternalOpportunitiesTableCount`,
    params
  );
}
function SearchListedOpportunitiesAsync(params) {
  return fetchWrapper.post(`${baseUrl}/SearchListedOpportunitiesTable`, params);
}
function SearchListedOpportunitiesCountAsync(params) {
  return fetchWrapper.post(
    `${baseUrl}/SearchListedOpportunitiesTableCount`,
    params
  );
}
function SearchDirectLinksTableAsync(params) {
  return fetchWrapper.post(`${baseUrl}/SearchDirectLinksTable`, params);
}
function SearchDirectLinksTableCountAsync(params) {
  return fetchWrapper.post(`${baseUrl}/SearchDirectLinksTableCount`, params);
}
function SearchMessagesAsync(params) {
  return fetchWrapper.post(`${baseUrl}/SearchMessages`, params);
}
function SearchMessagesCountAsync(params) {
  return fetchWrapper.post(`${baseUrl}/SearchMessagesCount`, params);
}
