import { config } from "../_helpers/config";
import { fetchWrapper } from "../_helpers";

const baseUrl = `${config.apiUrl}/directLink`;

export const directLinkService = {
  AddDirectLinkAsync,
  EditDirectLinkAsync,
  GetAllDirectLinksAsync,
  GetDirectLinkByIdAsync,
  GetAllUserDirectLinksAsync,
  DeleteDirectLinkAsync,
  TogglePublishDirectLinkAsync,
  ToggleFeaturedDirectLinkAsync,
  GetAllFeaturedLinkedProjectAsync,
  GetTheDirectLinkStatus,
  GetAllFeaturedLinkedProjectsCountAsync,
  ToggleAwardedProjectAsync,
  GetUserDirectLinkAnalytics,
  GetUserDirectLinkAnalyticsCount,
};

function AddDirectLinkAsync(params) {
  return fetchWrapper.post(`${baseUrl}/AddDirectLink`, params);
}

function EditDirectLinkAsync(id, params) {
  return fetchWrapper.post(`${baseUrl}/EditDirectLink/${id}`, params);
}

function GetAllDirectLinksAsync(lan) {
  return fetchWrapper.post(`${baseUrl}/GetAllDirectLinks/${lan}`);
}

function GetAllUserDirectLinksAsync(userId, lan) {
  return fetchWrapper.get(
    `${baseUrl}/GetAllUserDirectLinks/${userId}/${lan}`,
    {}
  );
}

function GetDirectLinkByIdAsync(id, lan, isAdmin) {
  return fetchWrapper.get(
    `${baseUrl}/GetDirectLinkById/${id}/${lan}/${isAdmin}`,
    {}
  );
}

function DeleteDirectLinkAsync(id) {
  return fetchWrapper.delete(`${baseUrl}/DeleteDirectLink/${id}`);
}

function TogglePublishDirectLinkAsync(id, publish) {
  return fetchWrapper.post(
    `${baseUrl}/TogglePublishDirectLink/${id}/${publish}`
  );
}

function ToggleFeaturedDirectLinkAsync(id, flag) {
  return fetchWrapper.post(`${baseUrl}/ToggleFeaturedLink/${id}/${flag}`);
}

function GetAllFeaturedLinkedProjectAsync(id, isAdmin, params, language) {
  if (id === null || id === undefined) {
    return fetchWrapper.post(
      `${baseUrl}/GetAllLinkedProject/${isAdmin}/${language}`,
      params
    );
  } else {
    return fetchWrapper.post(
      `${baseUrl}/GetAllLinkedProject/${isAdmin}/${language}/${id}`,
      params
    );
  }
}

function GetAllFeaturedLinkedProjectsCountAsync() {
  return fetchWrapper.get(`${baseUrl}/GetAllFeaturedLinkedProjectsCount`);
}

function GetTheDirectLinkStatus(projectId) {
  return fetchWrapper.get(`${baseUrl}/GetTheDirectLinkStatus/${projectId}`);
}

function ToggleAwardedProjectAsync(params) {
  return fetchWrapper.post(`${baseUrl}/ToggleAwardedProject`, params);
}

function GetUserDirectLinkAnalytics(userId) {
  return fetchWrapper.post(`${baseUrl}/GetUserDirectLinkAnalytics/${userId}`);
}

function GetUserDirectLinkAnalyticsCount(userId) {
  return fetchWrapper.get(
    `${baseUrl}/GetUserDirectLinkAnalyticsCount/${userId}`
  );
}
