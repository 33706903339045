import React from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
// reactstrap components
import { Card, CardBody, CardTitle, CardHeader, FormGroup } from "reactstrap";
import Row from "reactstrap/lib/Row";

// core components

function Documents({ props }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  return (
    <Card className={rtl ? "text-right" : ""}>
      <CardHeader className="text-center">
        <CardTitle className="" tag="h4">
          {t("createCompanyProfile.supportingDocs")}
        </CardTitle>
        <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
      </CardHeader>
      <CardBody>
        {props.supportingDocuments &&
          props.supportingDocuments.map((document) => {
            return (
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <div style={{ display: "flex" }}>
                    <label className="col-md-6" style={{ fontWeight: "bold" }}>
                      {document.documentType.documentTypeName}
                    </label>
                    <div
                      className="col-md-6"
                      style={{
                        display: "grid",
                        paddingLeft: "none",
                        paddingRight: "none",
                      }}
                    >
                      <Row>
                        {document.files &&
                          document.files.map((file) => {
                            return (
                              <a
                                className="col-md-8"
                                target="_blank"
                                rel="noreferrer"
                                href={file.filePath}
                              >
                                {file.fileName}
                              </a>
                            );
                          })}
                      </Row>
                    </div>
                  </div>
                </FormGroup>
              </div>
            );
          })}
      </CardBody>
    </Card>
  );
}

export default Documents;
