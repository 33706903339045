import CompanyInfo from "components/Profile/CompanyProfilePage/CompanyInfo";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// reactstrap components
// reactstrap components
import { Card, CardBody, CardTitle, CardHeader, FormGroup } from "reactstrap";

// core components

function CompanyDetailsSecure({ props }) {
  const { t, i18n } = useTranslation();
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    setShowInfo(props.companyShowInfo);
  }, []);
  return (
    <>
      {showInfo === true ? (
        <CompanyInfo props={props} />
      ) : (
        // <RestrictedInfoCard props={t("createCompanyProfile.company.diviver")} />
        <Card>
          <CardHeader className="text-center">
            <CardTitle className="" tag="h4">
              {t("createCompanyProfile.company.diviver")}
            </CardTitle>
            <hr
              className="btn-primary"
              style={{ size: "1", width: "20%" }}
            ></hr>
          </CardHeader>
          <CardBody>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("createCompanyProfile.companyName")}
                  </label>
                  {/* <input
                          className="form-control"
                          aria-readonly={true}
                          value="Entelligence LLC"
                        /> */}
                  <label
                    className="col-md-6"
                    //  style={{ fontWeight: "bold" }}
                  >
                    <i
                      class="fa fa-lock"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </label>
                </div>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("createCompanyProfile.managerName")}
                  </label>
                  <label
                    className="col-md-6"
                    //    style={{ fontWeight: "bold" }}
                  >
                    <i
                      class="fa fa-lock"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </label>
                  {/* <input
                          className="form-control"
                          aria-readonly={true}
                          value="Rita Saghbini"
                        /> */}
                </div>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label
                    className="col-md-6"
                    htmlFor="crNumber"
                    style={{ fontWeight: "bold" }}
                  >
                    {t("createCompanyProfile.crNumber")}
                  </label>
                  <label
                    className="col-md-6"
                    htmlFor="crNumber"
                    //style={{ fontWeight: "bold" }}
                  >
                    <i
                      class="fa fa-lock"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </label>
                  {/* <input
                          className="form-control"
                          aria-readonly={true}
                          value="CR-015"
                        /> */}
                </div>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("createCompanyProfile.activeSince")}
                  </label>
                  <label className="col-md-6">
                    {/* <i
                      class="fa fa-lock"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i> */}
                    {props.profile.activeSince}
                  </label>
                </div>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("createCompanyProfile.numberOfEmployees")}
                  </label>
                  <label className="col-md-6">
                    {props.numberOfEmployeesView &&
                      props.numberOfEmployeesView.description}
                  </label>
                </div>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("createCompanyProfile.website")}
                  </label>
                  <label className="col-md-6">
                    <i
                      class="fa fa-lock"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </label>
                </div>
              </FormGroup>
            </div>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <div style={{ display: "flex" }}>
                  <label className="col-md-6" style={{ fontWeight: "bold" }}>
                    {t("createCompanyProfile.linkedin")}
                  </label>
                  <label className="col-md-6">
                    <i
                      class="fa fa-lock"
                      aria-hidden="true"
                      style={{ color: "#0E6EB8" }}
                    ></i>
                  </label>
                </div>
              </FormGroup>
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default CompanyDetailsSecure;
