import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
// reactstrap components
import { Card, CardBody, CardTitle, CardHeader, FormGroup } from "reactstrap";

// core components

function PrivacyDetails({ props }) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  return (
    <Card className={rtl ? "text-right" : ""}>
      <CardHeader className="text-center">
        <CardTitle className="" tag="h4">
          {t("createCompanyProfile.privacyDetails")}
        </CardTitle>
        <hr className="btn-primary" style={{ size: "1", width: "20%" }}></hr>
      </CardHeader>
      <CardBody>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.showCompanyInfo")}
              </label>
              {/* <input
            className="form-control"
            aria-readonly={true}
            value="Entelligence LLC"
          /> */}
              <label className="col-md-6">
                {props.profile.companyInfoShowTo}
              </label>
            </div>
          </FormGroup>
        </div>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <div style={{ display: "flex" }}>
              <label className="col-md-6" style={{ fontWeight: "bold" }}>
                {t("createCompanyProfile.showContactInfo")}
              </label>
              <label className="col-md-6">
                {props.profile.contactInfoShowTo}
              </label>
              {/* <input
            className="form-control"
            aria-readonly={true}
            value="Rita Saghbini"
          /> */}
            </div>
          </FormGroup>
        </div>
      </CardBody>
    </Card>
  );
}

export default PrivacyDetails;
