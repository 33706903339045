import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "_helpers/PrivateRoute";
import ContactUs from "./ContactUs";

function Contact({ history, match }) {
  const { path } = match;
  return (
    <div>
      <Switch>
        <Route path={`${path}`} component={ContactUs} exact />
      </Switch>
    </div>
  );
}

export { Contact };
