import React, { useState } from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import { Paper, ThemeProvider } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { theme } from "../../../theme.js";
import Modal from "reactstrap/lib/Modal";
import { processLogService } from "_services/processLog.service.js";
import { IconButton } from "@material-ui/core";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  FileCopyIcon: forwardRef((props, ref) => (
    <FileCopyIcon {...props} ref={ref} />
  )),
};

function LogsList({ history, match, location }) {
  const { path } = match;
  const [logs, setLogs] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  var columns = [
    {
      title: t("dateStarted"),
      field: "dateStartedFormatted",
    },
    {
      title: t("dateEnded"),
      field: "dateEndedFormatted",
    },
    {
      title: t("status"),
      field: "statusFormatted",
    },
    {
      title: t("error"),
      field: "error",
    },
  ];

  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
      },
    },
  };

  let [currentPage, setCurrentPage] = useState(0);
  const userPerPage = pageSize;
  const begin = React.useRef(0);
  const end = React.useRef(userPerPage);

  const count = Math.ceil(itemCount / userPerPage);

  function next() {
    if (end.current > itemCount) return;
    setCurrentPage((currentPage) => Math.min(currentPage + 1, itemCount));
    begin.current = (currentPage + 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    begin.current = (currentPage - 1) * userPerPage;
    end.current = begin.current + userPerPage;
  }

  const fetchData = async () => {
    if (begin.current == 0) setLoading(true);
    const model = {
      emailLogsToSkip: begin.current,
      startingEmailLogs: pageSize,
    };
    await processLogService
      .GetProcessLogsByCodeAsync(location.state.state.processCode, model)
      .then((x) => {
        setLogs(x);
      });

    if (begin.current == 0) setLoading(false);
  };

  const fetchCount = async () => {
    await processLogService
      .GetProcessLogsByCodeCountAsync(location.state.state.processCode)
      .then((res) => setItemCount(res));
  };

  const handleChange = (value) => {
    setPageSize(parseInt(value));
    end.current = begin.current + parseInt(value);
  };

  React.useEffect(() => {
    fetchCount();
  }, []);

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [end.current, begin.current, pageSize]);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <div className="section">
        <Container>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div
              className={
                rtl ? "modal-body text-right col-md-12" : "modal-body col-md-12"
              }
            >
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={{ margin: "auto" }}
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <ThemeProvider theme={theme}>
            <MaterialTable
              //  style={{ backgroundColor: "#2C2C34", color: "#d9dcde" }}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              title={t("logsHistory")}
              columns={columns}
              data={logs}
              icons={tableIcons}
              options={{
                paging: false,
                actionsColumnIndex: -1,
                cellStyle: {
                  textAlign: rtl ? "right" : "left",
                },
                headerStyle: {
                  textAlign: rtl ? "right" : "left",
                },
              }}
              localization={{
                header: {
                  actions: t("actions"),
                },
                toolbar: {
                  searchPlaceholder: t("search"),
                },
              }}
            />
          </ThemeProvider>
          <div className="d-flex justify-content-end align-items-center">
            <select
              value={pageSize}
              onChange={(e) => handleChange(e.target.value)}
              style={{
                height: "36px",
                borderRadius: 0,
                width: "auto",
                fontSize: "12px",
              }}
              className={"form-control"}
            >
              <option
                key={5}
                value={5}
                style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
              >
                5
              </option>
              <option
                key={10}
                value={10}
                style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
              >
                10
              </option>
              <option
                key={15}
                value={15}
                style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
              >
                15
              </option>
              <option
                key={20}
                value={20}
                style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
              >
                20
              </option>
              <option
                key={50}
                value={50}
                style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
              >
                50
              </option>
              <option
                key={100}
                value={100}
                style={{ fontSize: "12px", fontFamily: "SpaceGrotesk" }}
              >
                100
              </option>
            </select>
            <IconButton
              aria-label="next"
              disabled={currentPage == 0 ? true : false}
            >
              {rtl ? (
                <ArrowForwardIosSharpIcon
                  onClick={() => prev()}
                  style={{
                    color: "#d9dcde",
                  }}
                />
              ) : (
                <ArrowBackIosSharpIcon
                  onClick={() => prev()}
                  style={{
                    color: "#d9dcde",
                  }}
                />
              )}
            </IconButton>
            <p className="mb-0 px-2" style={{ fontSize: "12px" }}>
              {begin.current} -{" "}
              {end.current > itemCount ? itemCount : end.current}{" "}
              {rtl ? "من" : "of"} {itemCount}
            </p>
            <IconButton
              aria-label="next"
              disabled={end.current >= itemCount ? true : false}
            >
              {rtl ? (
                <ArrowBackIosSharpIcon
                  onClick={() => next()}
                  style={{
                    color: "#d9dcde",
                  }}
                />
              ) : (
                <ArrowForwardIosSharpIcon
                  onClick={() => next()}
                  style={{
                    color: "#d9dcde",
                  }}
                />
              )}
            </IconButton>
          </div>
        </Container>
      </div>
    </>
  );
}

export default LogsList;
