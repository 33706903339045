import React, { useState, useContext } from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import "../../parsing.css";
import {
  profileService,
  projectSeenService,
  projectService,
} from "../../../_services";
import PulseLoader from "react-spinners/PulseLoader";
import { useTranslation } from "react-i18next";
import { AccountTypes } from "../../../_helpers";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import CardSubtitle from "reactstrap/lib/CardSubtitle";
import CardText from "reactstrap/lib/CardText";
import CardLink from "reactstrap/lib/CardLink";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import Modal from "reactstrap/lib/Modal";
import ReactHtmlParser from "react-html-parser";
import queryString from "query-string";
import { ProjectSearchListContext } from "_context";
import Badge from "reactstrap/lib/Badge";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import UncontrolledCollapse from "reactstrap/lib/UncontrolledCollapse";
import { LanguageContext } from "_context";
import { planService } from "_services/plan.service";
function ProjectDetails({ history, match, location }) {
  const { path } = match;
  const projectID = match.params.projectID;
  const parsed = queryString.parse(location.search);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const user = profileService.userValue;
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [closingDate, setClosingDate] = useState(new Date());

  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [planAnalytics, setPlanAnalytics] = useState(null);
  const [projectSearchList, setProjectSearchList] = useContext(
    ProjectSearchListContext
  );

  //var closingDate = new Date();
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const fetchData = async () => {
    setLoading(true);
    await projectService
      .GetProjectByIdAsync(projectID, false, user.profile.userId, currentLan)
      .then((project) => {
        setClosingDate(new Date(project.closingDate));
        setInfo(project);
      })
      .catch((err) => console.log(err));
    await profileService
      .GetProfileById(user.profile.userId, false, null, null, currentLan)
      .then((profile) => {
        planService
          .GetPlanById(profile.profile.membership.slice(-1)[0].planId)
          .then((x) => {
            console.log(x);
            // if (x != null) {
            //   setPlanAnalytics(x.analytics);
            // }
          })
          .catch((err) => console.log(err));
      });

    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  async function ToggleProjectBookmarkAsync(model) {
    await projectSeenService
      .ToggleProjectBookmarkAsync(model)
      .then((x) => {
        // history.push(`${path}/${selectedProject}`);
        handleBookmark(model.projectId, model.bookmark);
      })
      .catch((error) => {
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function handleBookmark(index, flag) {
    // let updatedList = info.map((item) => {
    //   if (item.projectView.projectID === index) {
    //     item.bookmark = flag;
    //     return item;
    //   } else {
    //     return item; // else return unmodified item
    //   }
    // });
    setInfo({ ...info, bookMarked: flag });

    // update project search list
    let searchModelList = projectSearchList.list.map((item) => {
      if (item.projectID === index) {
        item.bookMarked = flag;
        return item;
      } else {
        return item; // else return unmodified item
      }
    });
    const model = {
      totalCount: projectSearchList.totalCount,
      list: searchModelList,
    };

    setProjectSearchList(model);
  }
  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }

  // function handleToggleBookmark(index, flag) {
  //   let updatedList = info.map((item) => {
  //     if (item.projectID === index) {
  //       // if (flag) {
  //       //   return { ...item, bookMarked: "Published" };
  //       // } else {
  //       //   return { ...item, projectStatus: "New" };
  //       // }
  //       return { ...item, bookMarked: flag };
  //     }
  //     return item; // else return unmodified item
  //   });
  //   setInfo(updatedList);
  // }

  return (
    <>
      <div className="section">
        <Container>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{errorMessage}</p>
            </div>

            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          {info && (
            <Card className={rtl ? "text-right" : ""}>
              <CardBody>
                <Link
                  onClick={(e) => {
                    location.search != "" &&
                    (parsed.fromSearch === "true" ||
                      location.state.fromSearch === "true")
                      ? history.push("/provider/search-project")
                      : history.push("/provider/myProjects");
                  }}
                >
                  {t("backToList")}
                </Link>
                <Row>
                  <Col md="10">
                    <CardTitle tag="h4">
                      {info.projectTitle !== null ? info.projectTitle : ""}
                      <i
                        id="bookmark"
                        hidden={info.bookMarked === true}
                        className="now-ui-icons location_bookmark"
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          //borderColor: "#0E6EB8",
                          color: "#0E6EB8",
                        }}
                        onClick={async () => {
                          const model = {
                            projectId: info.projectID,
                            userId: user.profile.userId,
                            bookmark: true,
                          };
                          await ToggleProjectBookmarkAsync(model);
                        }}
                      >
                        <UncontrolledTooltip
                          target="bookmark"
                          placement="right"
                        >
                          {t("bookmark.click")}
                        </UncontrolledTooltip>
                      </i>

                      <i
                        id="unbookmark"
                        hidden={info.bookMarked === false}
                        className="fa fa-bookmark"
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          //borderColor: "#0E6EB8",
                          color: "#0E6EB8",
                        }}
                        onClick={async () => {
                          const model = {
                            projectId: info.projectID,
                            userId: user.profile.userId,
                            bookmark: false,
                          };
                          await ToggleProjectBookmarkAsync(model);
                        }}
                      ></i>
                      <UncontrolledTooltip
                        target="unbookmark"
                        placement="right"
                      >
                        {t("unbookmark.click")}
                      </UncontrolledTooltip>
                    </CardTitle>
                    {/* <Link
                      to={{
                              pathname: `/provider/search-project/${projectID}/${info.profileID}`,
                              search: "?showMessage=true",
                              state: { from: "search" },
                      }}  
                      // onClick={() => { 
                        // history.push({
                        //   pathname: `/provider/search-project/${projectID}/${info.profileID}`,
                          // search: "?showMessage=true",
                        // });
                      style={{ marginRight: "10px" }}
                    >
                      {t("publishedBy")}
                    </Link> */}
                    {parsed.fromSearch ? (
                      <Link
                        to={{
                          pathname: `/provider/search-project/${projectID}/${info.profileID}`,
                          search: "?showMessage=true",
                          state: { fromSearch: "true" },
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        {t("publishedBy")}
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: `/provider/search-project/${projectID}/${info.profileID}`,
                          search: "?showMessage=true",
                          state: { fromSearch: "false" },
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        {t("publishedBy")}
                      </Link>
                    )}
                    <Link
                      tag="h4"
                      style={{ fontSize: "18px" }}
                      onClick={() => {
                        history.push(`/provider/messaging/true/${projectID}`);
                      }}
                    >
                      {/* <i class="fa fa-comment fa-stack-2x"></i>{" "} */}
                      <UncontrolledTooltip target="message" placement="right">
                        {t("message.publishing.company")}
                      </UncontrolledTooltip>
                      {rtl ? (
                        <button
                          class="btn btn-primary btn-round btn-sm float-left"
                          id="message"
                        >
                          <i class="fa fa-comments"></i> {t("btnText.Connect")}
                        </button>
                      ) : (
                        <button
                          class="btn btn-primary btn-round btn-sm "
                          id="message"
                        >
                          <i class="fa fa-comments"></i> {t("btnText.Connect")}
                        </button>
                      )}
                      {/* <b
                         
                          class="fa-stack-1x calendar-text"
                          style={{
                            // paddingRight: 20,
                            fontSize: ".3em",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          Connect !
                        </b> */}
                      {/* <i
                        id="message"
                        className="fa fa-comments"
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          color: "#0E6EB8",
                        }}
                      ></i> */}
                      {/* <UncontrolledTooltip target="message" placement="right">
                        {t("message.publishing.company")}
                      </UncontrolledTooltip> */}
                    </Link>
                  </Col>
                  {/* <Col md="2">
                    {info.bookMarked === true ? (
                      <Button
                        // style={{ marginTop: "auto" }}
                        color="primary"
                        onClick={async () => {
                          const model = {
                            projectId: info.projectID,
                            userId: user.profile.userId,
                            bookmark: false,
                          };
                          await ToggleProjectBookmarkAsync(model);
                        }}
                      >
                        {t("bookmarked")}
                      </Button>
                    ) : (
                      <Button
                        // style={{ marginTop: "auto" }}
                        color="primary"
                        onClick={async () => {
                          const model = {
                            projectId: info.projectID,
                            userId: user.profile.userId,
                            bookmark: true,
                          };
                          await ToggleProjectBookmarkAsync(model);
                        }}
                      >
                        {t("bookmark")}
                      </Button>
                    )}
                  </Col> */}
                </Row>

                <div style={{ display: "flex", marginTop: "10px" }}>
                  <CardText style={{ width: "350px" }}>
                    <i
                      class="fa fa-cogs"
                      aria-hidden="true"
                      style={{
                        paddingRight: "10px",
                        paddingLeft: "10px",
                      }}
                    ></i>
                    <small style={{ fontSize: "80%" }}>
                      {t("project.type")}{" "}
                      {info.projectTypeView !== null &&
                      info.projectTypeView.projectTypeName !== null
                        ? info.projectTypeView.projectTypeName
                        : ""}
                    </small>
                  </CardText>
                  <CardText style={{ width: "350px" }}>
                    <i
                      class="fas fa-map-marker-alt"
                      aria-hidden="true"
                      style={{
                        paddingRight: "10px",
                        paddingLeft: "10px",
                      }}
                    ></i>

                    <small style={{ fontSize: "80%" }}>
                      {t("project.location")}{" "}
                    </small>
                    {info !== null &&
                    info.country !== null &&
                    info.country.countryCode !== null ? (
                      <ReactCountryFlag
                        countryCode={info.country.countryCode}
                        svg
                        style={{
                          width: "1.5em",
                          height: "1.5em",
                          marginTop: "-2px",
                          // marginRight: "10px",
                          // marginLeft: "10px",
                          borderRadius: "0px",
                        }}
                        title="US"
                      />
                    ) : null}

                    <small style={{ fontSize: "80%" }}>
                      {info !== null &&
                      info.country !== null &&
                      info.country.countryName !== null
                        ? " " + info.country.countryName
                        : ""}
                    </small>
                  </CardText>
                </div>
                <div style={{ display: "flex", marginTop: "-10px" }}>
                  <CardText
                    // style={{
                    //   marginLeft: "10%",
                    //   marginRight: "10%",
                    // }}
                    style={{ width: "350px" }}
                  >
                    <i
                      className="fa fa-upload"
                      aria-hidden="true"
                      style={{
                        paddingRight: "13px",
                        paddingLeft: "13px",
                      }}
                    ></i>
                    <small style={{ fontSize: "80%" }}>
                      {t("project.publicationDate")}{" "}
                      {`${new Date(info.publicationDateTime).getUTCDate()}/${
                        monthNames[
                          new Date(info.publicationDateTime).getUTCMonth()
                        ]
                      }/${new Date(info.publicationDateTime).getFullYear()}` !==
                      "1/January/1"
                        ? `${new Date(info.publicationDateTime).getUTCDate()}/${
                            monthNames[
                              new Date(info.publicationDateTime).getUTCMonth()
                            ]
                          }/${new Date(info.publicationDateTime).getFullYear()}`
                        : ""}
                    </small>
                  </CardText>
                  <CardText
                    style={{ width: "350px" }}
                    // style={{
                    //   marginLeft: "10%",
                    //   marginRight: "10%",
                    // }}
                  >
                    <i
                      class="fa fa-hourglass-end"
                      aria-hidden="true"
                      style={{
                        paddingRight: "12px",
                        paddingLeft: "12px",
                      }}
                    ></i>
                    <small style={{ fontSize: "80%" }}>
                      {t("project.closingDate")}{" "}
                      {closingDate !== null &&
                      `${new Date(closingDate).getUTCDate()}/${
                        monthNames[new Date(closingDate).getUTCMonth()]
                      }/${new Date(closingDate).getFullYear()}` !==
                        "1/January/1"
                        ? `${new Date(closingDate).getUTCDate()}/${
                            monthNames[new Date(closingDate).getUTCMonth()]
                          }/${new Date(closingDate).getFullYear()}`
                        : ""}
                    </small>
                  </CardText>
                </div>
                <div style={{ display: "flex", marginTop: "-10px" }}>
                  <CardText
                    // style={{
                    //   marginLeft: "10%",
                    //   marginRight: "10%",
                    // }}
                    style={{ width: "350px" }}
                  >
                    <i
                      className="fa fa-credit-card"
                      aria-hidden="true"
                      style={{
                        paddingRight: "13px",
                        paddingLeft: "13px",
                      }}
                    ></i>
                    <small style={{ fontSize: "80%" }}>
                      {t("search.budget")}{" "}
                      {info.budget && info.budget.description}
                    </small>
                  </CardText>
                </div>
                {/* {planAnalytics === true && (
                  <>
                    <div style={{ display: "flex", marginTop: "5px" }}>
                      <CardText
                        // style={{
                        //   marginLeft: "10%",
                        //   marginRight: "10%",
                        // }}
                        style={{ width: "350px" }}
                      >
                        <i
                          class="fa-solid fa-eye"
                          id="interactions"
                          aria-hidden="true"
                          style={{
                            paddingRight: "13px",
                            paddingLeft: "13px",
                            cursor: "pointer",
                            color: "#0e6eb8",
                          }}
                        ></i>
                        <small style={{ fontSize: "80%" }}>
                          {t("NbrOfUsersWhoInteracted")} {info.interactions}
                        </small>

                        <UncontrolledTooltip
                          target="interactions"
                          placement="bottom"
                        >
                          This is an exclusive feature for subscribed users.
                        </UncontrolledTooltip>
                      </CardText>
                      <CardText
                        // style={{
                        //   marginLeft: "10%",
                        //   marginRight: "10%",
                        // }}
                        style={{ width: "350px" }}
                      >
                        <i
                          class="fa-solid fa-message"
                          id="viewers"
                          aria-hidden="true"
                          style={{
                            paddingRight: "13px",
                            paddingLeft: "13px",
                            cursor: "pointer",
                            color: "#0e6eb8",
                          }}
                        ></i>
                        <small style={{ fontSize: "80%" }}>
                          {t("NbrOfUsersWhoViewed")} {info.viewers}
                        </small>

                        <UncontrolledTooltip
                          target="viewers"
                          placement="bottom"
                        >
                          {t("exclusive.features")}
                        </UncontrolledTooltip>
                      </CardText>
                    </div>
                  </>
                )} */}
                <Col style={{ marginTop: "7px" }}>
                  <Row>
                    <div
                      style={{
                        paddingLeft: "3px",
                        paddingRight: "3px",
                      }}
                    >
                      <Badge
                        style={{
                          backgroundColor: "#f5f5fa",
                          color: "#000000",
                          border: "none",
                          fontSize: "85%",
                        }}
                      >
                        {info.typeOfIndustry !== null &&
                        info.typeOfIndustry.typeOfIndustryName !== null
                          ? info.typeOfIndustry.typeOfIndustryName
                          : ""}
                      </Badge>
                    </div>
                    <div
                      style={{
                        paddingLeft: "3px",
                        paddingRight: "3px",
                      }}
                    >
                      <Badge
                        style={{
                          backgroundColor: "#f5f5fa",
                          color: "#000000",
                          border: "none",
                          fontSize: "85%",
                        }}
                      >
                        {info.typeOfIndustry !== null &&
                        info.typeOfIndustry.typeOfServiceName !== null
                          ? info.typeOfIndustry.typeOfServiceName
                          : ""}
                      </Badge>
                    </div>
                  </Row>
                </Col>
                {info &&
                  info.supportingDocuments &&
                  info.supportingDocuments.files.length != 0 && (
                    <CardSubtitle
                      className="mb-2 text-muted"
                      style={{ fontWeight: "bold", marginTop: "20px" }}
                    >
                      {t("createCompanyProfile.supportingDocs")}
                    </CardSubtitle>
                  )}
                <Row>
                  {info &&
                    info.supportingDocuments &&
                    info.supportingDocuments.files &&
                    info.supportingDocuments.files.map((file) => {
                      return (
                        <a
                          className="col-md-8"
                          target="_blank"
                          rel="noreferrer"
                          href={file.filePath}
                        >
                          {file.fileName}
                        </a>
                      );
                    })}
                </Row>
                {info.ndaRequired === true && (
                  <>
                    <Row style={{ marginTop: "20px" }} className="col-md-8">
                      <a
                        href="# "
                        id="nda"
                        className="mb-2 text-muted"
                        style={{ fontWeight: "bold" }}
                      >
                        {t("nonDisclosureAgreement")}
                      </a>
                      {info.userPerformingRequestNDAApproved === true && (
                        <i
                          class="fa fa-check-circle"
                          aria-hidden="true"
                          style={{
                            marginTop: "3px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            color: "#0E6EB8",
                          }}
                        ></i>
                      )}
                    </Row>

                    <UncontrolledCollapse
                      role="tabpanel"
                      toggler="#nda"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="parsing mb-3">
                        {ReactHtmlParser(info.ndaText)}
                      </div>
                    </UncontrolledCollapse>
                  </>
                )}

                <hr />
                {/* <CardTitle
                  // className="mb-2 text-muted"
                  // style={{ fontWeight: "bold" }}
                  tag="h5"
                >
                  {t("opportunity.description")}
                </CardTitle>
                <hr /> */}
                <h5 style={{ fontWeight: 600 }}>
                  {t("opportunity.description")}
                </h5>
                <div className="parsing">
                  {ReactHtmlParser(info.projectDescription)}
                </div>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </>
  );
}

export default ProjectDetails;
