import React, { useState, useContext } from "react";
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ImageIcon from "@material-ui/icons/Image";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import MovieIcon from "@mui/icons-material/Movie";
import { Paper, ThemeProvider } from "@material-ui/core";
import { theme } from "../../../../theme.js";
import { adminService } from "_services/admin.service.js";
import { languageService, imageService } from "_services";
import Modal from "reactstrap/lib/Modal";
import { Link, useHistory } from "react-router-dom";
import { LanguageContext } from "_context";
import ReactHtmlParser from "react-html-parser";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
};

function VideosList({ match, location }) {
  const { path } = match;
  const history = useHistory();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  const [language, setLanguage] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowDataID, setRowDataID] = useState(null);
  const [videoDownloadAlert, setVideoDownloadAlert] = useState(false);
  const [videoToDownload, setVideoToDownload] = useState("");
  var columns = [
    {
      title: t("title"),
      field: "id",
      hidden: true,
    },
    {
      title: t("title"),
      field: "videoPath",
      hidden: true,
    },
    {
      title: t("title"),
      field: "videoTitle",
    },
    {
      title: t("title"),
      field: "videoName",
      hidden: true,
    },
  ];
  const fetchData = async () => {
    setLoading(true);

    await languageService.GetAllLanguagesAsync().then(async (x) => {
      var lang = x.filter((lan) => lan.languageName === currentLan)[0];
      console.log("lang", lang);

      await adminService.GetVideosAsync().then((res) => {
        const tempList = res.map((video) => ({
          id: video._id,
          videoTitle: ReactHtmlParser(
            video.videoTitle.filter((x) => x.languageID == lang.languageID)[0]
              .name
          ),
          videoPath: video.videoPath,
          videoName: video.videoName,
        }));
        console.log("tempList", tempList);
        setVideos(tempList);
      });
    });
    setLoading(false);
  };

  function handleRemove(id) {
    const newList = videos.filter((item) => item.id !== id);
    setVideos(newList);
  }

  async function deleteSelectedItem(id) {
    await adminService
      .DeleteVideoAsync(id)
      .then(() => {
        handleRemove(id);
        setDeleteAlert(!deleteAlert);
      })
      .catch((error) => {
        setDeleteAlert(!deleteAlert);
        if (error.message !== undefined) {
          setError(error.message);
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }

  async function DownloadVideo() {
    await imageService
      .GetVideoAsync(videoToDownload)
      .then((x) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([x.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", videoToDownload);
        document.body.appendChild(link);
        link.click();
        setVideoDownloadAlert(!videoDownloadAlert);
        setVideoToDownload("");
      })
      .catch((error) => {
        setDownloading(false);
        if (
          error !== null &&
          error.response !== null &&
          error.response.data !== null &&
          error.response.data.message !== undefined
        ) {
          setError(error.response.data.message);
          if (
            error.response.data.error !== null &&
            error.response.data.error.length > 0
          ) {
            for (var i = 0; i < error.response.data.error.length; i++) {
              if (error.response.data.error[i].languageName == currentLan) {
                setError(error.response.data.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        //   setErrorAlert(!errorAlert);
      });
  }

  React.useEffect(() => {
    fetchData();
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <div className={rtl ? "section text-right" : "section"}>
        <Container>
          <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("error.alert")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{errorMessage}</p>
            </div>
            <div className="modal-footer">
              <Button
                color="danger"
                type="button"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={() => setErrorAlert(!errorAlert)}
              >
                {t("close.button")}
              </Button>
            </div>
          </Modal>
          <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
            <div
              className="modal-header text-center"
              style={{ margin: "0 auto" }}
            >
              <h5 className="modal-title">{t("delete.alert")}</h5>
            </div>
            <div className="modal-body col-md-12">
              <p>{t("delete.alert.body")}</p>
            </div>

            <div
              className="modal-footer"
              style={{ justifyContent: "flex-end" }}
            >
              <center>
                <Link
                  className="mr-2"
                  type="button"
                  style={{ margin: "auto", color: "#000000" }}
                  onClick={() => setDeleteAlert(!deleteAlert)}
                >
                  {t("cancel.link")}
                </Link>
                <Button
                  color="danger"
                  type="button"
                  className="mr-2"
                  style={
                    rtl
                      ? { margin: "auto", textAlign: "right" }
                      : { margin: "auto" }
                  }
                  onClick={() => deleteSelectedItem(rowDataID)}
                >
                  {t("delete")}
                </Button>
              </center>
            </div>
          </Modal>
          <Modal
            isOpen={videoDownloadAlert}
            style={rtl ? { textAlign: "right" } : {}}
          >
            <div className="modal-header" style={{ margin: "0 auto" }}>
              <h5 className="modal-title">{t("warning")}</h5>
            </div>
            <div className={rtl ? "modal-body text-right" : "modal-body"}>
              <p>{t("downloadVideoWarning")}</p>
            </div>
            <div
              className="modal-footer"
              style={{ justifyContent: "flex-end" }}
            >
              <center>
                <Link
                  className="mr-2"
                  type="button"
                  style={{ margin: "auto", color: "#000000" }}
                  onClick={(e) => setVideoDownloadAlert(!videoDownloadAlert)}
                >
                  {t("cancel.link")}
                </Link>
                <Button
                  color="primary"
                  type="button"
                  className="mr-2"
                  style={
                    rtl
                      ? { margin: "auto", textAlign: "right" }
                      : { margin: "auto" }
                  }
                  onClick={async (e) => await DownloadVideo()}
                >
                  {t("downloadVideo")}
                </Button>
              </center>
            </div>
          </Modal>
          <ThemeProvider theme={theme}>
            <MaterialTable
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              title={t("videosList")}
              columns={columns}
              data={videos}
              icons={tableIcons}
              options={{
                paging: false,
                actionsColumnIndex: -1,
              }}
              actions={[
                {
                  icon: () => <MovieIcon className="actionIcon" />,
                  tooltip: t("downloadVideo"),
                  onClick: (event, rowData) => {
                    setVideoToDownload(rowData.videoName);
                    setVideoDownloadAlert(!videoDownloadAlert);
                  },
                },
                {
                  icon: () => <Edit className="actionIcon" />,
                  tooltip: t("edit"),
                  onClick: (event, rowData) => {
                    history.push(`${path}/edit/${rowData.id}`);
                  },
                },
                {
                  icon: () => (
                    <Delete
                      style={{ fill: "#FF3636" }}
                      className="actionIcon"
                    />
                  ),
                  tooltip: t("delete"),
                  onClick: (event, rowData) => {
                    setRowDataID(rowData.id);
                    setDeleteAlert(!deleteAlert);
                  },
                },
                {
                  icon: () => (
                    <AddBox
                      className="actionIcon"
                      style={{
                        color: "#000000",
                      }}
                    />
                  ),
                  tooltip: t("add"),
                  isFreeAction: true,
                  onClick: () => {
                    history.push(`${path}/add`);
                  },
                },
              ]}
            />
          </ThemeProvider>
        </Container>
      </div>
    </>
  );
}

export default VideosList;
