import React, { useState, useContext } from "react";
import * as Yup from "yup";
// reactstrap components
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ListAlt from "@material-ui/icons/ListAlt";
import { Paper, ThemeProvider } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { theme } from "../../../theme.js";
import Modal from "reactstrap/lib/Modal";
import { badgeservice } from "_services/plan.service.js";
import { adminService } from "_services/admin.service.js";
import { LanguageContext } from "_context";
import { badgeService } from "_services/BadgeService.js";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DeleteOutline: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ListAlt: forwardRef((props, ref) => <ListAlt {...props} ref={ref} />),
  FileCopyIcon: forwardRef((props, ref) => (
    <FileCopyIcon {...props} ref={ref} />
  )),
};

function SS_SP_POBadgesCard({ type, history, match, location }) {
  const { path } = match;
  const badgeType = type != null ? type : location.state.type;
  const [badges, setBadges] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const rtl = i18n.dir() == "rtl";
  const [role, setRole] = useState(null);
  const [errorMessage, setError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowDataID, setRowDataID] = useState(null);
  const [currentLan, setCurrentLan] = useContext(LanguageContext);
  var columns = [
    {
      title: t("badgeName"),
      field: "id",
      hidden: true,
    },
    {
      title: t("badgeName"),
      field: "name",
      width: "5%",
    },
    {
      title: t("badgeType"),
      field: "type",
      hidden: true,
    },
    {
      title: t("badgeImage"),
      field: "Image",
      hidden: true,
    },
    {
      title: t("badgeRole"),
      field: "roleName",
    },
    {
      title: t("badgeNbOfOpportunitiesGained"),
      field: "nbOfOpportunitiesGained",
    },
    {
      title: t("badgeNbOfOpportunitiesAwarded"),
      field: "nbOfOpportunitiesAwarded",
    },
    {
      title: t("badgeNbCollaborationsInsurveys"),
      field: "nbCollaborationsInsurveys",
    },
  ];

  function handleRemove(id) {
    const newList = badges.filter((badge) => badge.id !== id);
    setBadges(newList);
  }
  async function deleteSelectedItem(id) {
    await badgeService
      .DeleteBadge(id)
      .then(() => {
        handleRemove(id);
        setDeleteAlert(!deleteAlert);
      })
      .catch((error) => {
        setDeleteAlert(!deleteAlert);
        if (error.message !== undefined) {
          setError(error.message);
          if (error.error !== null && error.error.length > 0) {
            for (var i = 0; i < error.error.length; i++) {
              if (error.error[i].languageName == currentLan) {
                setError(error.error[i].error);
              }
            }
          }
        } else {
          setError(error);
        }
        setErrorAlert(!errorAlert);
      });
  }
  const fetchData = async () => {
    setLoading(true);
    await badgeService.GetAllSsAndSpAndPoBadges().then((res) => {
      const tmpList = res.map((badge) => ({
        id: badge.id,
        name: badge.name,
        roleName: badge.type == "SP" ? "SP" : "SS & PO",
        nbOfOpportunitiesGained:
          badge.type == "SP" ? badge.nbOfOpportunitiesGained : "",
        nbOfOpportunitiesAwarded:
          badge.type == "SSorPO" ? badge.nbOfOpportunitiesAwarded : "",
        nbCollaborationsInsurveys: badge.nbCollaborationsInsurveys,
        image: badge.image,
        type: badge.type,
      }));
      setBadges(tmpList);
    });
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();

    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    document.body.style = "background-color: #ffffff";
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  if (loading) {
    return (
      <>
        <center
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            left: "50%",
            top: "100%",
          }}
        >
          <PulseLoader color="#0E6EB8" />
        </center>
      </>
    );
  }
  return (
    <>
      <Container>
        <Modal isOpen={errorAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("error.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
            }
          >
            <p>{errorMessage}</p>
          </div>

          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              style={
                rtl
                  ? { margin: "auto", textAlign: "right" }
                  : { margin: "auto" }
              }
              onClick={(e) => setErrorAlert(!errorAlert)}
            >
              {t("close.button")}
            </Button>
          </div>
        </Modal>
        <Modal isOpen={deleteAlert} style={rtl ? { textAlign: "right" } : {}}>
          <div
            className="modal-header text-center"
            style={{ margin: "0 auto" }}
          >
            <h5 className="modal-title">{t("delete.alert")}</h5>
          </div>
          <div
            className={
              rtl ? "modal-body col-md-12 text-right" : "modal-body col-md-12"
            }
          >
            <p>{t("delete.alert.body")}</p>
          </div>

          <div className="modal-footer" style={{ justifyContent: "flex-end" }}>
            <center>
              <Link
                className="mr-2"
                type="button"
                style={{ margin: "auto", color: "#000000" }}
                onClick={(e) => setDeleteAlert(!deleteAlert)}
              >
                {t("cancel.link")}
              </Link>
              <Button
                color="danger"
                type="button"
                className="mr-2"
                style={
                  rtl
                    ? { margin: "auto", textAlign: "right" }
                    : { margin: "auto" }
                }
                onClick={(e) => deleteSelectedItem(rowDataID)}
              >
                {t("delete")}
              </Button>
            </center>
          </div>
        </Modal>
        <ThemeProvider theme={theme}>
          <MaterialTable
            //  style={{ backgroundColor: "#2C2C34", color: "#d9dcde" }}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            title={t("badgesList")}
            columns={columns}
            data={badges}
            icons={tableIcons}
            options={{
              paging: false,
              actionsColumnIndex: -1,
              cellStyle: {
                textAlign: "center",
              },
              headerStyle: {
                textAlign: "center",
              },
            }}
            localization={{
              header: {
                actions: t("actions"),
              },
              toolbar: {
                searchPlaceholder: t("search"),
              },
            }}
            actions={[
              {
                icon: () => (
                  <Edit className="actionIcon" style={{ color: "#0E6EB8" }} />
                ),
                tooltip: t("edit"),
                onClick: (event, rowData) => {
                  history.push(`${path}/edit/activity/${rowData.id}`, {
                    state: rowData,
                    type: rowData.type,
                  });
                },
              },
              {
                icon: () => (
                  <Delete className="actionIcon" style={{ fill: "#FF3636" }} />
                ),
                tooltip: t("delete"),
                //isFreeAction: true,
                onClick: (event, rowData) => {
                  setRowDataID(rowData.id);
                  setDeleteAlert(!deleteAlert);
                },
              },
              {
                icon: () => (
                  <AddBox
                    className="actionIcon"
                    style={{
                      color: "#000000",
                    }}
                  />
                ),
                tooltip: t("add"),
                isFreeAction: true,
                onClick: (event, rowData) => {
                  history.push(`${path}/add?fromActivity=true`);
                },
              },
            ]}
          />
        </ThemeProvider>
      </Container>
    </>
  );
}

export default SS_SP_POBadgesCard;
